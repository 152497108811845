import { datadogRum } from '@datadog/browser-rum';
import { GA_CLIENT_ID, GA4_CLIENT_ID } from '../constants/Constants';

let enableLogging = false;

export const setEnableLogging = (val) => (enableLogging = val);

const UserTracking = {
    hasUserPerformedLogIn: false,
    setNewUserHasPerformedLogIn: function () {
        this.hasUserPerformedLogIn = true;
    },
    track: (action, data) => {
        if (enableLogging) {
            console.log(action, data || '');
        }

        datadogRum.addAction(action, data);
    },
    debugTrack: (action, data) => {
        if (enableLogging) {
            console.log(action, data || '');
            datadogRum.addAction(action, data);
        }
    },
    log: (...args) => {
        if (enableLogging) {
            console.log(...args);
        }
    },
    error: (action, data) => {
        if (enableLogging) {
            console.log('Tracking application error: ', action);
        }
        datadogRum.addError(action, data);
    },
    pageView: () => {
        const location = window.location;
        const href = location.href.replace(/\?.*/, '');
        const path = location.pathname?.replace(/\d+/g, '_ID_');
        if (window.gtag) {
            window.gtag('config', GA_CLIENT_ID, {
                page_title: document.title,
                page_location: href,
                page_path: path,
            });
            window.gtag('config', GA4_CLIENT_ID, {
                page_title: document.title,
                page_location: href,
                page_path: path,
            });
        }
    },
    sendEvent: (action, newEvent) => {
        if (window.gtag) {
            window.gtag('event', action, {
                ...newEvent,
            });
        }
    },
    outboundLink: (outboundUrl, eventProps = {}) => {
        if (window.gtag) {
            window.gtag('event', 'click', {
                event_category: 'outbound',
                event_label: outboundUrl,
                transport_type: 'beacon',
                ...eventProps,
            });
        }
    },
};

export default UserTracking;
