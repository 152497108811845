import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    widgetSettings: {
        display: 'flex',
        alignItems: 'center',
    },
    widgetContainer: {
        cursor: 'auto !important',
        width: '100%',
        height: '100%',
        paddingTop: theme.spacing(1),

        '& > .ContentBlock': {
            display: (props) => (props.cameraContainerHeight ? 'block' : 'inherit'),
            height: '100%',
            minHeight: '100%',

            '& > .MuiPaper-root': {
                paddingBottom: (props) => (props.cameraContainerHeight && !props.isLoading ? theme.spacing(2) : null),
                height: 'inherit',

                '& > div:nth-of-type(2)': {
                    marginTop: (props) => (props.cameraContainerHeight ? theme.spacing(2) : null),
                    maxHeight: (props) => (props.cameraContainerHeight ? `${props.cameraContainerHeight}px` : '100%'),
                    overflowY: (props) => (props.hasInnerScroll ? 'scroll' : null),

                    '& > div:first-child': {
                        marginBottom: (props) => (props.cameraContainerHeight ? 0 : null),
                    },
                },
            },
        },
    },
    widgetHeaderLeft: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',

        '& .MuiSvgIcon-root': {
            cursor: 'grab',
        },
    },
    list: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        overflowX: 'hidden',
        overflowY: 'hidden',
        borderRadius: '4px',
        height: '100%',
        margin: 'auto',
        minHeight: '100%',
    },
    emptyList: {
        display: 'flex',
        height: '20vh',
        border: `1px dashed ${theme.palette.colors.blue500}`,
        borderRadius: '4px',
        borderStyle: 'dashed',
        margin: theme.spacing(1, 1, 2, 1),
        width: 'auto',
    },
    isDraggingOver: {
        backgroundColor: `${
            theme.palette.type === 'light' ? theme.palette.colors.blue100 : theme.palette.colors.blue500
        }`,
        borderRadius: '4px',
    },
    widgetTitleDescription: {
        paddingLeft: theme.spacing(0.5),
    },
}));

export default useStyles;
