import React from 'react';
import { SvgIcon } from '@brivo/react-components';

export const CameraDirection = ({ width, height }) => {
    const markerSize = {
        width: width || 94,
        height: height || 87,
    };
    return (
        <SvgIcon width="94" height="87" viewBox="0 0 94 87" fill="none" style={{ ...markerSize }}>
            <path
                d="M93.9938 84.282C93.6326 66.4209 88.1801 49.0342 78.2773 34.1653C68.3745 19.2965 54.433 7.56354 38.0914 0.345592L0.179359 86.1794L93.9938 84.282Z"
                fill="url(#paint0_linear_1496_93046)"
                fillOpacity="0.7"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1496_93046"
                    x1="14.1823"
                    y1="80.5927"
                    x2="65.1937"
                    y2="44.9908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4D8AD9" />
                    <stop offset="1" stopColor="#A3C6F3" stopOpacity="0" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};
