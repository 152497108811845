import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        timelineContainer: {
            borderLeft: `2px solid ${theme.palette.brivoAliases.newTabHoverColor}`,
            paddingLeft: '20px',
            overflowY: 'hidden',
            overflowX: 'hidden',
            marginBottom: '5px',
        },
    };
});

export default styles;
