import { SvgIcon } from '@brivo/react-components';

const BannerIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 56 50" {...props}>
            <path
                d="M29.4416 38.3872H22.1368C21.7246 38.3872 21.3905 38.0531 21.3905 37.641C21.3905 37.2288 21.7246 36.8947 22.1368 36.8947H29.4416C29.8537 36.8947 30.1878 37.2288 30.1878 37.641C30.1878 38.0531 29.8537 38.3872 29.4416 38.3872Z"
                fill="#A5B4C4"
            />
            <path
                d="M29.4416 40.7312H22.1368C21.7246 40.7312 21.3905 40.3971 21.3905 39.9849C21.3905 39.5728 21.7246 39.2387 22.1368 39.2387H29.4416C29.8537 39.2387 30.1878 39.5728 30.1878 39.9849C30.1878 40.3971 29.8537 40.7312 29.4416 40.7312Z"
                fill="#A5B4C4"
            />
            <path
                d="M24.0169 41.6142C23.836 41.6142 23.6988 41.7786 23.7322 41.9563C23.9118 42.9178 24.7542 43.6458 25.7682 43.6458C26.7818 43.6458 27.6243 42.9178 27.8043 41.9563C27.8373 41.7786 27.7 41.6142 27.5192 41.6142H24.0169Z"
                fill="#A5B4C4"
            />
            <path
                d="M28.6685 35.9543H22.8032C21.7874 35.9543 20.932 35.16 20.8558 34.146C20.6072 30.8393 19.2578 28.7441 17.8294 26.5259C16.2714 24.1068 14.6604 21.6053 14.6604 17.5604C14.6604 12.0984 18.5672 7.5019 23.9505 6.6309C25.2086 6.4273 26.5345 6.44334 27.7864 6.67679C33.0164 7.65471 36.8123 12.232 36.8123 17.5604C36.8123 21.6053 35.2013 24.1068 33.6428 26.5259C32.214 28.7441 30.865 30.8393 30.6164 34.146C30.5402 35.16 29.6843 35.9543 28.6685 35.9543Z"
                fill="#5076A7"
            />
            <path
                d="M20.3693 24.4993C20.147 24.4993 19.9274 24.3965 19.7861 24.2028C15.7844 18.7065 18.7342 13.548 18.8621 13.331C19.0635 12.9879 19.5054 12.8734 19.8485 13.0753C20.1911 13.2766 20.3061 13.7174 20.1056 14.0603C19.9951 14.2507 17.5215 18.6433 20.9516 23.3543C21.186 23.676 21.1151 24.1269 20.793 24.3611C20.6651 24.4544 20.5163 24.4993 20.3693 24.4993Z"
                fill="white"
            />
            <path
                d="M4.0272 23.0272L2.96967 21.8695L4.94394 20.066L3.27266 18.2365L4.33732 17.2639L6.0086 19.0935L7.99322 17.2806L9.05075 18.4383L7.06614 20.2512L8.73742 22.0807L7.67276 23.0533L6.00148 21.2237L4.0272 23.0272Z"
                fill="#5076A7"
            />
            <ellipse cx="9.06449" cy="21.636" rx="0.505898" ry="0.505898" fill="#FAFCFD" />
            <mask id="path-8-inside-1_5022_5069" fill="white">
                <ellipse cx="8.05277" cy="29.3933" rx="0.505898" ry="0.505898" />
            </mask>
            <path
                d="M7.76848 29.3933C7.76848 29.2363 7.89576 29.1091 8.05277 29.1091V30.6894C8.76858 30.6894 9.34886 30.1092 9.34886 29.3933H7.76848ZM8.05277 29.1091C8.20978 29.1091 8.33706 29.2363 8.33706 29.3933H6.75669C6.75669 30.1092 7.33697 30.6894 8.05277 30.6894V29.1091ZM8.33706 29.3933C8.33706 29.5504 8.20978 29.6776 8.05277 29.6776V28.0973C7.33697 28.0973 6.75669 28.6775 6.75669 29.3933H8.33706ZM8.05277 29.6776C7.89576 29.6776 7.76848 29.5504 7.76848 29.3933H9.34886C9.34886 28.6775 8.76858 28.0973 8.05277 28.0973V29.6776Z"
                fill="#A5B4C4"
                mask="url(#path-8-inside-1_5022_5069)"
            />
            <path
                d="M8.48162 35.5326C8.654 35.2915 9.0342 35.4119 9.03646 35.7082C9.03747 35.8408 9.12371 35.9576 9.25008 35.9976C9.53265 36.087 9.53569 36.4858 9.25451 36.5796C9.12876 36.6215 9.04431 36.7396 9.04532 36.8721C9.04758 37.1685 8.66926 37.2946 8.49323 37.0562C8.4145 36.9495 8.27607 36.9057 8.15032 36.9476C7.86915 37.0414 7.63229 36.7206 7.80467 36.4794C7.88176 36.3716 7.88066 36.2264 7.80193 36.1198C7.6259 35.8813 7.85784 35.5569 8.14041 35.6464C8.26678 35.6864 8.40453 35.6405 8.48162 35.5326Z"
                fill="#FAFCFD"
            />
            <path
                d="M4.99232 23.1C5.12448 22.9152 5.41598 23.0074 5.41771 23.2347C5.41848 23.3363 5.4846 23.4258 5.58149 23.4565C5.79813 23.5251 5.80046 23.8308 5.58489 23.9027C5.48848 23.9348 5.42373 24.0254 5.4245 24.127C5.42624 24.3542 5.13618 24.4509 5.00122 24.2681C4.94086 24.1864 4.83472 24.1528 4.73831 24.1849C4.52274 24.2568 4.34114 24.0108 4.47331 23.8259C4.53241 23.7433 4.53156 23.6319 4.4712 23.5502C4.33624 23.3674 4.51407 23.1187 4.73071 23.1872C4.8276 23.2179 4.93321 23.1827 4.99232 23.1Z"
                fill="#A5B4C4"
            />
            <path
                d="M52.7062 26.2893L50.9405 27.2623L49.2813 24.2512L46.4909 25.7888L45.5961 24.165L48.3865 22.6274L46.7186 19.6005L48.4843 18.6275L50.1522 21.6544L52.9426 20.1168L53.8374 21.7406L51.047 23.2782L52.7062 26.2893Z"
                fill="#A5B4C4"
            />
            <ellipse cx="40.2558" cy="25.9757" rx="1.09611" ry="1.09611" fill="#FAFCFD" />
            <circle cx="44.3898" cy="22.4625" r="0.33021" fill="#FAFCFD" />
            <circle cx="42.9166" cy="31.8428" r="0.395094" stroke="#A5B4C4" stroke-width="0.727505" />
            <path
                d="M38.4235 35.5834C38.5556 35.3986 38.8471 35.4908 38.8489 35.7181C38.8496 35.8197 38.9158 35.9092 39.0126 35.9399C39.2293 36.0085 39.2316 36.3142 39.016 36.3861C38.9196 36.4182 38.8549 36.5088 38.8557 36.6104C38.8574 36.8376 38.5673 36.9343 38.4324 36.7515C38.372 36.6698 38.2659 36.6362 38.1695 36.6683C37.9539 36.7402 37.7723 36.4942 37.9045 36.3093C37.9636 36.2267 37.9627 36.1153 37.9024 36.0336C37.7674 35.8508 37.9452 35.6021 38.1619 35.6706C38.2587 35.7013 38.3644 35.6661 38.4235 35.5834Z"
                fill="#FAFCFD"
            />
        </SvgIcon>
    );
};

export default BannerIcon;
