import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ARM_LEVEL, INTRUSION_PANEL_ARMED, COMMAND_TRIGGERED_BY_USER, LOCKDOWN_ACTIONS } from '../constants/Constants';
import { useFlagClient } from '@brivo/onairplus-services';
import { DOOR_LOCKED_VIA_TIMER, DOOR_UNLOCKED_VIA_TIMER, FLOOR } from '@common/constants/Constants';

export const useTranslatedEventNames = () => {
    const { t, i18n } = useTranslation();
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios', false);

    const translateEventName = useCallback(
        (actionId, armLevel, objectName, deviceType, fallbackText) => {
            const translationKey = `centralTranslations:Security.action-message.shortName.${actionId}`;

            let translation = t(translationKey);
            const armLevelStatus = t(`Page.event-tracker.intrusion-panel-status.${armLevel}`);
            const unknownStatus = t('Page.event-tracker.intrusion-panel-status.UNKNOWN');
            const commandTranslation = t('centralTranslations:Security.action-message.shortName.command.' + actionId, {
                commandName: objectName,
            });

            if (actionId === INTRUSION_PANEL_ARMED && armLevel) {
                if (armLevel === ARM_LEVEL.AWAY || armLevel === ARM_LEVEL.STAY || armLevel === ARM_LEVEL.NIGHT) {
                    translation += ' (' + armLevelStatus + ')';
                } else {
                    translation += ' (' + unknownStatus + ')';
                }
            }

            if (actionId === COMMAND_TRIGGERED_BY_USER && objectName) {
                translation = ' ' + commandTranslation;
            }

            if (emergencyScenariosFlag && LOCKDOWN_ACTIONS.includes(actionId)) {
                translation = t(`centralTranslations:Security.action-message.shortName.emergency.${actionId}`);
            }

            if ((actionId === DOOR_UNLOCKED_VIA_TIMER || actionId === DOOR_LOCKED_VIA_TIMER) && deviceType === FLOOR) {
                translation = t(`centralTranslations:Security.action-message.shortName.floor.${actionId}`);
            }

            if (i18n.exists(`centralTranslations:Security.action-message.shortName.${actionId}`)) {
                return cleanTranslation(translation);
            } else if (i18n.exists(`Security.action-message.${actionId}`)) {
                return translation;
            } else {
                return fallbackText || t(`Security.action-message.unknown-event`, { actionId });
            }
        },
        [t, i18n, emergencyScenariosFlag]
    );

    return {
        t,
        i18n,
        translateEventName,
    };
};

export const cleanTranslation = (string) => {
    const withExistingQuotesEscaped = string.replace(/"/gm, '\\"');
    const withQuotes = '"' + withExistingQuotesEscaped + '"';
    // regex used to remove escape near : (\: makes JSON.parse confuse with key:value )
    const withColonEscapesRemoved = withQuotes && withQuotes.replace(/\\(?=:)/gm, '');
    // JSON.parse is used in order to display diacritics and not utf-8 codes
    try {
        return JSON.parse(withColonEscapesRemoved);
    } catch (e) {
        console.error('Unable to parse: ', string);
        return string;
    }
};
