import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { checkPermissions } from '@brivo/onairplus-services';

import { UserContext } from '../../user/UserProvider';
import AccessDenied from '../../../pages/AccessDenied/AccessDenied';
import {
    APP_EVENT_TRACKER_URL,
    APP_LOCKDOWN_URL,
    APP_ONAIR_GROUP_LOCKDOWN_URL,
    APP_ONAIR_LOCKDOWN_CONFIG_URL,
    APP_USERS_LISTING_URL,
} from '../../constants/Constants';
import {
    EVENT_TRACKER_CHART_PERM,
    EVENT_TRACKER_PERM,
    LOCKDOWN_CLEAR_LOCKDOWN,
    LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
    LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
    LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
    LOCKDOWN_INITIATE_LOCKDOWN,
    ONAIR_GROUP_BASED_LOCKDOWN,
    USER_VIEW_USERS,
} from '../../permissions/Permissions';
import { auth } from '../../utils/Auth';

const RedirectToNextRoute = () => {
    const { permissions } = useContext(UserContext);
    const [defaultPage, setDefaultPage] = useState();
    const [noValidPermissions, setNoValidPermissions] = useState(null);

    useEffect(() => {
        const getFirstLink = () => {
            const navLinks = document.querySelectorAll('#site-nav a');
            if (navLinks && navLinks.length > 0) {
                return navLinks[0].pathname;
            }
            return null;
        };
        if (!defaultPage) {
            let getLinkTimer;
            let permissionTimeout;
            const firstLink = getFirstLink();
            if (firstLink) {
                setDefaultPage(firstLink);
            } else {
                getLinkTimer = setInterval(() => {
                    const firstLink = getFirstLink();
                    if (firstLink) {
                        setDefaultPage(firstLink);
                    }
                }, 100);
                permissionTimeout = setTimeout(() => {
                    clearInterval(getLinkTimer);
                    setNoValidPermissions(true);
                }, 10000);
            }
            return () => {
                clearInterval(getLinkTimer);
                clearTimeout(permissionTimeout);
            };
        }
    }, [defaultPage]);

    if (auth.isAuthenticated() && auth.getIdentified()) {
        if (permissions && permissions.length > 0) {
            if (
                checkPermissions({
                    userPermissions: permissions,
                    anyOfPermissions: [EVENT_TRACKER_PERM, EVENT_TRACKER_CHART_PERM],
                })
            ) {
                return <Redirect to={APP_EVENT_TRACKER_URL} />;
            } else if (
                checkPermissions({
                    userPermissions: permissions,
                    anyOfPermissions: [USER_VIEW_USERS],
                })
            ) {
                return <Redirect to={APP_USERS_LISTING_URL} />;
            } else if (defaultPage) {
                return <Redirect to={defaultPage} />;
            } else if (
                checkPermissions({
                    userPermissions: permissions,
                    anyOfPermissions: [LOCKDOWN_CLEAR_LOCKDOWN, LOCKDOWN_INITIATE_LOCKDOWN],
                })
            ) {
                return <Redirect to={APP_LOCKDOWN_URL} />;
            } else if (
                checkPermissions({
                    userPermissions: permissions,
                    anyOfPermissions: [
                        LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
                        LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
                        LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
                    ],
                })
            ) {
                return <Redirect to={APP_ONAIR_LOCKDOWN_CONFIG_URL} />;
            } else if (
                checkPermissions({
                    userPermissions: permissions,
                    anyOfPermissions: [ONAIR_GROUP_BASED_LOCKDOWN],
                })
            ) {
                return <Redirect to={APP_ONAIR_GROUP_LOCKDOWN_URL} />;
            } else if (noValidPermissions) {
                return <AccessDenied />;
            }
        } else {
            return <AccessDenied />;
        }
    }
    return null;
};

export default RedirectToNextRoute;
