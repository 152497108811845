import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFlagClient } from '@brivo/onairplus-services';

import { INITIATE_LOCKDOWN } from '../../../constants/Constants';
import BrowserNotificationsDialog from './BrowserNotificationsDialog';
import { useTranslatedEventNames } from '../../../hooks/useTranslatedEventNames';
import { auth } from '../../../utils/Auth';

const requestNotificationsPermission = () => {
    if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification');
        return;
    } else if (Notification.permission === 'granted') {
        return Notification.permission;
    } else if (Notification.permission !== 'denied') {
        return Notification.requestPermission();
    }
};

export const isValidToShowPermissionDialog = () => {
    return 'Notification' in window && Notification.permission === 'default';
};

export const isEventTypeNotificationEvent = (eventType) => {
    return eventType === 'NOTIFICATION_EVENT';
};

const BrowserNotifications = ({ messages }) => {
    const [permissionDialog, setPermissionDialog] = useState(false);

    const { translateEventName } = useTranslatedEventNames();
    const { t } = useTranslation();

    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');

    const handleRequestPermission = () => {
        setPermissionDialog(false);
        requestNotificationsPermission();
    };

    useEffect(() => {
        const checkBrowserNotificationTriggers = async () => {
            const isAuthenticated = await auth.isAuthenticated();

            if (!isAuthenticated) {
                return;
            }

            if (isValidToShowPermissionDialog()) {
                setPermissionDialog(true);
            }
        };
        checkBrowserNotificationTriggers();
    }, []);

    useEffect(() => {
        if (!messages) {
            return;
        }
        for (let i = 0; i < messages.length; i++) {
            const event = { ...messages[i].data };
            const { eventType } = messages[i];

            if (messages[i] && isEventTypeNotificationEvent(eventType)) {
                let translatedEventTitle = translateEventName(event.securityActionId);
                if (event.securityActionId === INITIATE_LOCKDOWN) {
                    translatedEventTitle = emergencyScenariosFlag
                        ? t('Browser.Notifications.EmergencyScenario.title')
                        : t('Browser.Notifications.Lockdown.title');
                }

                if (!('Notification' in window)) {
                    console.log('This browser does not support desktop notification');
                    return;
                }
                new Notification(translatedEventTitle, { icon: '/brivo.png' });
            }
        }
    }, [messages, translateEventName, t, emergencyScenariosFlag]);

    return (
        <BrowserNotificationsDialog
            open={permissionDialog}
            onClose={() => setPermissionDialog(false)}
            requestNotificationsPermission={handleRequestPermission}
            t={t}
        />
    );
};

export default BrowserNotifications;
