import React, { PureComponent } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import {
    Grow,
    Paper,
    Popper,
    withTheme,
    MuiCheckCircleOutlineIcon,
    LoadingIndicator,
    ClickAwayListener,
    IconButton,
    Typography,
    withStyles,
} from '@brivo/react-components';

import { IAMSVC_SSO_LOGOUT_URL } from '@common/constants/Constants';
import { CopyIcon, SingleSelectField, Tooltip, ThemeContext } from '@brivo/react-components';
import styles from './styles';
import iconStyles from './iconStyles';
import { auth } from '../../../../utils/Auth';
import { withFlagContextConsumer } from '@brivo/onairplus-services';

const UserIcon = withTheme(({ initials, size, showName, theme }) => {
    const classes = iconStyles({ size, showName, theme });

    return (
        <div className={clsx('u-fx u-fx-align-center u-fx-justify-center', classes.userIconParent)}>
            <Typography variant="h4" component="p" className={classes.userIconChild}>
                {initials}
            </Typography>
        </div>
    );
});

class UserAvatar extends PureComponent {
    state = {
        open: false,
        isLoading: false,
        changePasswordSuccess: false,
        changePasswordError: null,
        needIamSvcLogout: false,
        remoteLoggedIn: false,
        tooltipMessage: '',
    };

    doAppsLogout = async () => {
        this.setState({ needIamSvcLogout: true });
    };

    doAuthLogout = () => {
        try {
            auth.logout();
        } catch (e) {
            console.log(e.message);
        }
    };

    resize = () => {
        setTimeout(() => {
            if (this.state.open) {
                this.handleToggle();
            }
        }, 0);
    };

    componentDidMount = () => {
        window.addEventListener('resize', this.resize);
        const remoteExists = !!auth.getRemoteCode();
        this.setState({ remoteLoggedIn: remoteExists });
    };

    componentWillUnmount = () => window.removeEventListener('resize', this.resize);

    handleCopy = (adminId) => {
        const { t } = this.props;
        navigator.clipboard.writeText(adminId);
        this.setState({ tooltipMessage: t('Navigation.user-menu.copied-to-clipboard') });
    };

    onHoverHandler = () => {
        const { t } = this.props;
        this.setState({ tooltipMessage: t('Navigation.user-menu.copy-to-clipboard') });
    };

    handleToggle = () => {
        this.setState((state) => ({ open: !state.open }));
    };

    handleClose = (event) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });
    };

    handleChangePassword = () => {
        const { t } = this.props;
        this.setState({ isLoading: true }, async () => {
            try {
                const result = await auth.changePassword();

                if (result) {
                    this.setState({
                        isLoading: false,
                        changePasswordSuccess: result,
                    });

                    setTimeout(() => {
                        auth.logout();
                    }, 5000);
                } else {
                    throw new Error(result);
                }
            } catch (err) {
                console.error(err);

                this.setState(
                    {
                        isLoading: false,
                        changePasswordError: t('Navigation.user-menu.change-password.message.error'),
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                changePasswordError: null,
                            });
                        }, 5000);
                    }
                );
            }
        });
    };

    renderNewUserAvatarSection = ({
        user,
        showName,
        open,
        userInfoStyle,
        newNavUserInfoStyle,
        userIconInitials,
        userIconSize,
        classes,
        t,
    }) => {
        const lighthouseWidget = document.getElementById('lighthouse');
        return user && showName ? (
            <div>
                <div className={userInfoStyle}>
                    <div className={newNavUserInfoStyle}>
                        <IconButton
                            id="user-options"
                            innerRef={(node) => {
                                this.anchorEl = node;
                            }}
                            aria-owns={open ? 'user-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                            className={classes.iconButton}
                            icon={
                                <UserIcon
                                    initials={userIconInitials}
                                    size={userIconSize}
                                    showName={showName}
                                    className="u-mr-zero"
                                />
                            }
                        />
                        <Typography noWrap component="p" className={classes.userName} variant="caption" data-private>
                            {`${user.firstName || ''} ${user.lastName || ''}`}
                        </Typography>
                    </div>
                </div>
                <div className={clsx('u-fx u-fx-justify-start u-fx-align-center', classes.helpSection)}>
                    <Typography noWrap component="p" className={classes.helpText} variant="caption" data-private>
                        {!lighthouseWidget ? (
                            <div
                                onClick={() => {
                                    window.open('https://support.brivo.com/');
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {t('Navigation.user-help.title')}
                            </div>
                        ) : (
                            t('Navigation.user-help.title')
                        )}
                    </Typography>
                </div>
            </div>
        ) : (
            <IconButton
                id="user-options"
                innerRef={(node) => {
                    this.anchorEl = node;
                }}
                aria-owns={open ? 'user-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleToggle}
                icon={<UserIcon initials={userIconInitials} size={userIconSize} showName={showName} />}
            />
        );
    };

    render() {
        const { user, showName, theme, classes, newNavigationMobile = false, t, flags } = this.props;
        const {
            open,
            isLoading,
            changePasswordSuccess,
            changePasswordError,
            needIamSvcLogout,
            remoteLoggedIn,
            tooltipMessage,
        } = this.state;
        const userIconSize = '3rem';
        const { accounts = [], accountId } = user;

        const userIconInitials = `${user && user.firstName ? user.firstName[0] : ''}${
            user && user.lastName ? user.lastName[0] : ''
        }`;
        const userInfoStyle = clsx(
            newNavigationMobile && 'u-fx-justify-space-between u-fx-align-center u-fx ',
            !newNavigationMobile && 'u-fx u-fx-justify-start u-fx-align-center'
        );

        const newNavUserInfoStyle = 'u-fx u-fx-align-center';
        const currentYear = new Date().getFullYear();

        const alternateAuthEnabled = flags['alternative-authentication'];
        const accountSwitcherEnabled = flags['account-switcher'];

        const config = {
            user,
            showName,
            open,
            userInfoStyle,
            newNavUserInfoStyle,
            userIconInitials,
            userIconSize,
            theme,
            classes,
            t,
        };

        return (
            <div className="UserAvatar" id="user-info">
                {this.renderNewUserAvatarSection(config)}
                {needIamSvcLogout ? (
                    <div className="u-fx u-fx-align-center">
                        <iframe
                            id={'iamsvc_logout_frame'}
                            title={'IAM Service logout'}
                            src={`${IAMSVC_SSO_LOGOUT_URL}?remote_access=${remoteLoggedIn}`}
                            style={{
                                overflow: 'hidden',
                                height: '0',
                                width: '0',
                                position: 'absolute',
                            }}
                            onLoad={this.doAuthLogout}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal={false}
                    style={{
                        zIndex: 1300,
                    }}
                    placement={showName ? 'bottom' : 'bottom-end'}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="user-menu-list"
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'left top',
                            }}
                        >
                            <Paper className={classes.popup}>
                                <ClickAwayListener onClickAway={this.handleClose} mouseEvent="onMouseDown">
                                    <>
                                        <div className={classes.header}>
                                            <span className={classes.text}>
                                                © 2001 - {currentYear}, {t('Navigation.user-menu.copyright')}
                                            </span>
                                            <span className={classes.text}>
                                                {t('Navigation.user-menu.version')} {import.meta.env.VITE_BUILD_TAG}
                                            </span>
                                        </div>
                                        {user && (
                                            <>
                                                <div className={classes.divider} />
                                                <div className={classes.menuItemList}>
                                                    <div className={clsx('u-fx u-fx-align-center', classes.adminID)}>
                                                        <p style={{ marginRight: 5 }}>
                                                            {t('Navigation.user-menu.adminId-label')}
                                                        </p>
                                                        <div className="u-full-width" onMouseOver={this.onHoverHandler}>
                                                            <Tooltip
                                                                title={tooltipMessage}
                                                                size="small"
                                                                placement="top-end"
                                                                children={
                                                                    <span
                                                                        id="adminId"
                                                                        className="u-full-width u-fx u-fx-align-center u-fx-justify-space-between"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => this.handleCopy(user.username)}
                                                                    >
                                                                        <div
                                                                            className={clsx(
                                                                                'u-trim',
                                                                                classes.noHover,
                                                                                classes.adminName
                                                                            )}
                                                                        >
                                                                            {user.username}
                                                                        </div>
                                                                        <div className={classes.noHover}>
                                                                            <CopyIcon />
                                                                        </div>
                                                                    </span>
                                                                }
                                                            ></Tooltip>
                                                        </div>
                                                    </div>
                                                    {accounts.length && accountSwitcherEnabled ? (
                                                        <div className="u-full-width">
                                                            <SingleSelectField
                                                                id="SwitchAccounts"
                                                                name="SwitchAccount"
                                                                selectedOption={accountId}
                                                                options={accounts}
                                                                handleChange={(event) =>
                                                                    auth.login({ accountId: event.target.value })
                                                                }
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                        <div className={classes.divider} />

                                        <ul className={classes.menuItemList}>
                                            <ThemeContext.Consumer>
                                                {(context) => (
                                                    <li
                                                        className={classes.menuItem}
                                                        onClick={() => {
                                                            context.toggleTheme();
                                                            this.handleToggle();
                                                        }}
                                                        id="theme-switch-button"
                                                    >
                                                        {t(
                                                            context.selectedTheme === 'light'
                                                                ? 'Navigation.user-menu.theme-switch.button.dark'
                                                                : 'Navigation.user-menu.theme-switch.button.light'
                                                        )}
                                                    </li>
                                                )}
                                            </ThemeContext.Consumer>
                                            {user && !remoteLoggedIn && (
                                                <li
                                                    className={
                                                        alternateAuthEnabled
                                                            ? classes.menuItemDisabled
                                                            : classes.menuItem
                                                    }
                                                    onClick={this.handleChangePassword}
                                                >
                                                    {isLoading ? (
                                                        <LoadingIndicator size="small" shouldHavePadding={false} />
                                                    ) : changePasswordSuccess ? (
                                                        <p>
                                                            <MuiCheckCircleOutlineIcon
                                                                style={{
                                                                    verticalAlign: 'bottom',
                                                                    marginRight: '0.5rem',
                                                                    color: theme.palette.green.main,
                                                                }}
                                                            />
                                                            <span
                                                                style={{
                                                                    color: theme.palette.green.main,
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {t(
                                                                    'Navigation.user-menu.change-password.message.email-sent'
                                                                )}
                                                            </span>
                                                        </p>
                                                    ) : changePasswordError ? (
                                                        <p>
                                                            <span
                                                                style={{
                                                                    color: theme.palette.error.main,
                                                                }}
                                                            >
                                                                {changePasswordError}
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        t('Navigation.user-menu.change-password.button')
                                                    )}
                                                </li>
                                            )}
                                            {!remoteLoggedIn && (
                                                <li className={classes.menuItem} onClick={this.doAppsLogout}>
                                                    {t('Navigation.user-menu.logout')}
                                                </li>
                                            )}
                                        </ul>
                                    </>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

export default withTranslation('translations')(withStyles(styles)(withTheme(withFlagContextConsumer(UserAvatar))));
