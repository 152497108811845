import React from 'react';
import styles from './styles';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@brivo/react-components';
import { Tooltip } from '@brivo/react-components';

const EventDetail = ({ icon, title, isClickable, linkTo }) => {
    const classes = styles();
    return (
        <Grid container>
            <Grid item xs={2} className={classes.detailIconContainer}>
                {icon}
            </Grid>
            <Grid item xs={10} className={classes.detailTextContainer}>
                <Tooltip title={title} size={'small'} placement={'top'}>
                    {isClickable ? (
                        <Link to={linkTo} className={classes.linkStyle}>
                            <Typography className={`${classes.detailText} ${classes.linkText}`}>{title}</Typography>
                        </Link>
                    ) : (
                        <Typography className={classes.detailText}>{title}</Typography>
                    )}
                </Tooltip>
            </Grid>
        </Grid>
    );
};
export default EventDetail;
