import { makeStyles } from '@brivo/react-components';
const useStyles = makeStyles((theme) => {
    return {
        nameInput: {
            marginTop: '16px',
        },
        input: {
            marginBottom: '24px',
            '& .MuiFormLabel-root.MuiInputLabel-root': {
                color: theme.palette.brivoAliases.newLabelColor,
                marginBottom: '8px',
            },
        },
        inputError: {
            '& .MuiInputBase-root': {
                border: `1px solid ${
                    theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300
                }`,
                borderRadius: 4,
            },
        },
        errorText: {
            '& .error-message': {
                marginTop: '.5rem',
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300}`,
                '& span': {
                    marginTop: '.1rem',
                },
            },
        },
        radio: {
            '& #RadioGroup__choose-view': {
                display: 'flex',
                flexDirection: 'row',
            },
            '& .MuiFormLabel-root': {
                marginBottom: '16px',
                marginTop: '16px',
            },
        },
        sharingOptions: {
            display: 'flex',
            alignItems: 'center',
        },
        tooltip: {
            display: 'flex',
            flexDirection: 'column',
        },
    };
});
export default useStyles;
