import { Column, Filter } from '@/pages/NewReports/common/interfaces';

export const columnConfiguration: Column = {
    source: 'users',
    id: 'full_name',
    uuid: 'user_full_name',
    alias: 'User',
};

export const dimension = {
    id: 'user_full_name',
    displayName: 'User',
    dataDefinition: {
        dataType: 'string',
    },
};
