import gql from 'graphql-tag';

export const DEVICE_STATUS_PAGE_DEVICE_STATUS_QUERY = gql`
    query DoorStatus {
        getDEVICESTATUSData {
            data {
                deviceName
                deviceId
                siteId
                reportLiveStatus
                controlPanelId
                lockState
                deviceStatus
                systemStatus
                systemStatusMessage
                messageReceivedTime
                isActive
                threatLevel
            }
            rawData {
                connectedStatus
                nextUnlockScheduleChange
                nextIgnoreScheduleChange
                unlockScheduleStatus
                ignoreScheduleStatus
                accessState
                ajarStatus
                inputStatus
                intrusionStatus
                batteryState
                batteryRemaining
                doorState
                threatLevel
                peripheralInfo {
                    type
                    networkInfo {
                        networkStatus
                    }
                }
            }
        }
    }
`;

export const DEVICE_STATUS_PAGE_PANELS_QUERY = gql`
    query Panels($pageSize: Int!, $offset: Int!) {
        getAllPanels(limit: { rows: $pageSize, offset: $offset }) {
            id
            panelName
            serialNumber
            activated
            updated
            lastContact
            firmwareVersion
            panelType
            status
        }
    }
`;

export const GET_ID_NAME_FOR_ALL_PANELS_QUERY = gql`
    query Panels($pageSize: Int!, $offset: Int!) {
        getAllPanels(limit: { rows: $pageSize, offset: $offset }) {
            id
            panelName
        }
    }
`;

export const GET_VIRTUAL_PANEL_URL_BY_SERIAL_NUMBER_QUERY = gql`
    query getVirtualPanelUrl($serialNumber: String) {
        getVirtualPanelUrl(serialNumber: $serialNumber)
    }
`;

export const GET_ALL_PANELS_PER_SITES = gql`
    query panelsPerSites($pageSize: Int!, $offset: Int!, $sitesOids: [Int]) {
        panelsPerSites(limit: { rows: $pageSize, offset: $offset }, sitesOids: $sitesOids) {
            id
            panelName
            panelId
            serialNumber
            activated
            updated
            lastContact
            firmwareVersion
            panelType
            panelTypeId
            status
        }
    }
`;

export const GET_UNASSOCIATED_PANELS = gql`
    query getUnassociatedPanels($pageSize: Int!, $offset: Int!) {
        getUnassociatedPanels(limit: { rows: $pageSize, offset: $offset }) {
            id
            panelName
            serialNumber
            activated
            updated
            lastContact
            firmwareVersion
            panelType
            status
        }
    }
`;

export const GET_DEVICE_COUNT_FOR_SITES = gql`
    query getDeviceCountsForSites($sitesOids: [Int], $includeMappedDevices: Boolean) {
        getDeviceCountsForSites(sitesOids: $sitesOids, includeMappedDevices: $includeMappedDevices) {
            cameras
            devices
            doors
            elevators
            panels
            intrusionPanels
            siteId
            totalCount
        }
    }
`;
