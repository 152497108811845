import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { unionBy } from 'lodash';
import { Waypoint } from 'react-waypoint';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
    makeStyles,
    Paper,
    EmptyStateSection,
    AddItemIcon,
    Button,
    LoadingIndicator,
    Table,
    MuiLockOpen,
    Snackbar,
} from '@brivo/react-components';
import { useFlagClient, withApi } from '@brivo/onairplus-services';

import { lockdownApi } from '@common/webApis';
import ApiHelper from '@common/helpers/Helpers';
import AccessControl from '@common/components/AccessControl';
import { usePermissions } from '@common/hooks/usePermissions';
import PageHeader from '@common/components/PageHeader/PageHeader';

import TableLegend from '@common/components/TableLegend/TableLegend';
import { ALL_SCENARIOS_FILTER_ENABLED } from '@common/constants/Constants';
import {
    DEVICES_VIEW_DEVICES,
    LOCKDOWN_CLEAR_LOCKDOWN,
    LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
    LOCKDOWN_INITIATE_LOCKDOWN,
} from '@common/permissions/Permissions';

import LockdownScenarioFiltersGroup from './LockdownScenarioFiltersGroup';
import { ViewButton } from '../../UserManagement/shared/UsersListCells';

const useStyles = makeStyles((theme) => ({
    scenarioName: {
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.brivoAliases.fieldTextHover,
        },
    },
    defaultClass: {
        color: theme.palette.colors.green500,
        fontWeight: 700,
    },

    viewButton: {
        alignSelf: 'flex-end',
    },
    centeredCircularSpinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

const getHeightFromWindowSize = () => {
    let x = window.innerHeight;

    if (window.innerHeight > 900) {
        return x / 1.8;
    } else {
        return x / 2;
    }
};

const LockdownScenariosListing = ({
    scenarios,
    isLoading,
    count,
    totalCount,
    loadNextPage,
    showEgressScenarios,
    fetchIsLockdownActive,
    activeScenarios: globalActiveScenarios,
    api,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const notifications = Snackbar();
    const flagClient = useFlagClient();
    const useNewEmergencyEndpoints = flagClient.variation('new-emergency-scenarios-endpoints', false);
    const { hasPermissions } = usePermissions();
    const userCanClickScenario = hasPermissions(DEVICES_VIEW_DEVICES);

    const userCanInitiateScenario = hasPermissions(LOCKDOWN_INITIATE_LOCKDOWN);
    const userCanClearScenario = hasPermissions(LOCKDOWN_CLEAR_LOCKDOWN);

    const [activeScenarios, setActiveScenarios] = useState(null);
    const [loadActiveScenarios, setLoadActiveScenarios] = useState(null);

    const fetchIsInternalLockdownActive = useCallback(async () => {
        if (showEgressScenarios) {
            const queryParamsAllScenarios = {
                filter: ALL_SCENARIOS_FILTER_ENABLED,
            };

            const response = await lockdownApi.getAllActiveScenarios({ queryParams: queryParamsAllScenarios });

            setActiveScenarios(response?.data);
            setLoadActiveScenarios(false);
        } else {
            const activeLockdowns = await lockdownApi.getLockdownActiveScenarios();
            const lockdownScenariosIncludingHardwiredLockdowns = await lockdownApi.getLockDownScenarios({
                queryParams: {
                    filter: 'hardware__eq:true',
                    pageSize: '100',
                },
            });

            const activeLockdownsIncludingHardwiredLockdowns =
                lockdownScenariosIncludingHardwiredLockdowns?.data?.filter(
                    (lockdownScenario) => lockdownScenario.issued
                );

            const allActiveLockdowns = unionBy(activeLockdowns?.data, activeLockdownsIncludingHardwiredLockdowns, 'id');

            setActiveScenarios(allActiveLockdowns ? allActiveLockdowns : []);
            setLoadActiveScenarios(false);
        }
    }, [showEgressScenarios]);

    useEffect(() => {
        fetchIsInternalLockdownActive();
    }, [fetchIsInternalLockdownActive, globalActiveScenarios]);

    const showTable = scenarios?.length || isLoading;
    const showEmptyState = !scenarios?.length && !isLoading;
    let columns;

    const onClickScenario = (scenario) => {
        if (showEgressScenarios) {
            history.push(`/scenarios/${scenario.id}`);
        } else {
            history.push(`/lockdown-scenarios/${scenario.id}`);
        }
    };

    const handleCreateLockdownScenario = () => {
        if (showEgressScenarios) {
            history.push(`/scenarios/create-scenario`);
        } else {
            history.push(`/lockdown-scenarios/create-lockdown-scenario`);
        }
    };

    const handleClearEmergencyScenarios = async () => {
        if (showEgressScenarios) {
            try {
                setLoadActiveScenarios(true);
                const response = await api.clearAllEmergency(useNewEmergencyEndpoints);

                if (!response || !response.message) {
                    notifications.addErrorMessage({ text: t('Page.emergency-scenarios.bottom-bar-fail-clear') });
                } else {
                    if (response.atLeastOneLockdownCleared) {
                        // refresh banner
                        fetchIsLockdownActive();

                        await fetchIsInternalLockdownActive();
                    }
                    if (response.isError) {
                        notifications.addErrorMessage({ text: response.message });
                    } else {
                        notifications.addSuccessMessage({ text: response.message });
                    }
                }
            } catch (e) {
                notifications.addErrorMessage({ text: t('Page.emergency-scenarios.bottom-bar-fail-clear') });
            }
            setLoadActiveScenarios(false);
        } else {
            try {
                setLoadActiveScenarios(true);
                const response = await api.clearAllLockdowns();

                if (!response || !response.message) {
                    notifications.addErrorMessage({ text: t('Page.lockdown.bottom-bar-fail-clear') });
                } else {
                    if (response.atLeastOneLockdownCleared) {
                        // refresh banner
                        fetchIsLockdownActive();

                        await fetchIsInternalLockdownActive();
                    }
                    notifications.addSuccessMessage({ text: response.message });
                }
            } catch (e) {
                notifications.addErrorMessage({ text: t('Page.lockdown.bottom-bar-fail-clear') });
            }
            setLoadActiveScenarios(false);
        }
    };

    const handleActivateDeactivateScenario = async (scenario, callActivateOnClick) => {
        setLoadActiveScenarios(true);
        let response;
        try {
            if (callActivateOnClick) {
                response = useNewEmergencyEndpoints
                    ? await lockdownApi.initiateLockdownNew(scenario)
                    : await lockdownApi.initiateLockdown(scenario.id);
            } else {
                response = useNewEmergencyEndpoints
                    ? await lockdownApi.clearLockdownScenarioNew(scenario)
                    : await lockdownApi.clearLockdownScenario(scenario.id);
            }
        } catch (error) {
            console.log(error);
        }
        if (!response || response.didAllFail) {
            if (callActivateOnClick) {
                notifications.addErrorMessage({ text: t('Page.emergency-scenarios.failed-activated') });
            } else {
                notifications.addErrorMessage({ text: t('Page.emergency-scenarios.failed-deactivated') });
            }
        } else {
            if (callActivateOnClick) {
                notifications.addSuccessMessage({ text: t('Page.emergency-scenarios.successfully-activated') });
            } else {
                notifications.addSuccessMessage({ text: t('Page.emergency-scenarios.successfully-deactivated') });
            }
        }
        fetchIsLockdownActive();
        await fetchIsInternalLockdownActive();
    };

    const allScenariosLoaded = scenarios?.length === count;

    if (showEgressScenarios) {
        columns = [
            {
                Header: t('Page.lockdown-scenarios.lockdown-scenarios-list.table.name'),
                id: 'name',
                Cell: (arg) =>
                    nameCell(
                        arg,
                        allScenariosLoaded,
                        loadNextPage,
                        onClickScenario,
                        classes,
                        userCanClickScenario,
                        t,
                        showEgressScenarios,
                        loadActiveScenarios
                    ),
            },
            {
                Header: t('Page.lockdown-scenarios.lockdown-scenarios-list.table.type'),
                id: 'type',
                Cell: (arg) => typeCell(arg),
            },
            {
                Header: ' ',
                id: 'activateDeactivateSection',
                Cell: (arg) =>
                    activateDeactivateScenario(
                        arg,
                        handleActivateDeactivateScenario,
                        t,
                        activeScenarios,
                        loadActiveScenarios,
                        userCanInitiateScenario,
                        userCanClearScenario
                    ),
            },
            {
                Header: ' ',
                id: 'view',
                Cell: (arg) =>
                    viewCell(
                        arg,
                        onClickScenario,
                        loadActiveScenarios,
                        t('Page.lockdown-scenarios.lockdown-scenarios-list.table.view-button'),
                        classes
                    ),
            },
        ];
    } else {
        columns = [
            {
                Header: t('Page.lockdown-scenarios.lockdown-scenarios-list.table.name'),
                id: 'name',
                Cell: (arg) =>
                    nameCell(
                        arg,
                        allScenariosLoaded,
                        loadNextPage,
                        onClickScenario,
                        classes,
                        userCanClickScenario,
                        t,
                        showEgressScenarios
                    ),
            },
            {
                Header: ' ',
                id: 'view',
                Cell: (arg) =>
                    viewCell(
                        arg,
                        onClickScenario,
                        false,
                        t('Page.lockdown-scenarios.lockdown-scenarios-list.table.view-button'),
                        classes
                    ),
            },
        ];
    }

    return (
        <>
            <PageHeader
                title={
                    showEgressScenarios
                        ? t('Page.emergency-scenarios.page-title')
                        : t('Page.lockdown-scenarios.page-title')
                }
            />
            <Paper className="u-mb-full u-pt-full u-pb-full">
                <LockdownScenarioFiltersGroup
                    headerRightSlot={
                        <div>
                            <AccessControl
                                anyOfPermissions={[LOCKDOWN_CLEAR_LOCKDOWN]}
                                requireAssignments={{ allSites: true, allLockdowns: true }}
                            >
                                <Button
                                    id="clear_emergency_scenarios"
                                    onClick={handleClearEmergencyScenarios}
                                    startIcon={<MuiLockOpen fontSize="small" className="u-mr-half" />}
                                    text={
                                        showEgressScenarios
                                            ? t('Page.emergency-scenarios.clear-all-emergency-scenarios')
                                            : t('Page.lockdown.clear-lockdowns-btn')
                                    }
                                    disabled={!activeScenarios?.length || loadActiveScenarios}
                                />
                            </AccessControl>
                            <AccessControl anyOfPermissions={[LOCKDOWN_CREATE_LOCKDOWN_SCENARIO]}>
                                <span className="u-ma-full">
                                    <Button
                                        id="create_lockdown_scenario"
                                        onClick={handleCreateLockdownScenario}
                                        startIcon={<AddItemIcon />}
                                        text={
                                            showEgressScenarios
                                                ? t(
                                                      'Page.emergency-scenarios.lockdown-scenarios-create-emergency-scenario'
                                                  )
                                                : t(
                                                      'Page.lockdown-scenarios.lockdown-scenarios-list.table.empty-state.create-scenario'
                                                  )
                                        }
                                        disabled={loadActiveScenarios}
                                    />
                                </span>
                            </AccessControl>
                        </div>
                    }
                />
                {showTable && (
                    <>
                        <Table
                            data={scenarios}
                            columns={columns}
                            tableContainerProps={{
                                'data-test-id': 'table-container',
                            }}
                            heightFunction={getHeightFromWindowSize}
                            hasClickableRows={userCanClickScenario}
                            clickToSortMessage={t('Table.column.header.sort')}
                        />
                        <LoadingIndicator show={isLoading} shouldHavePadding={false} />
                        <TableLegend
                            x={count}
                            y={totalCount}
                            label={t('Page.lockdown-scenarios.lockdown-scenarios-list.table.legend-label')}
                        />
                    </>
                )}
                {showEmptyState && (
                    <div className="u-fx u-fx-align-center u-fx-justify-center">
                        <EmptyStateSection
                            title={
                                showEgressScenarios
                                    ? t('Page.emergency-scenarios.emergency-scenarios-list.table.empty-state.title')
                                    : t('Page.lockdown-scenarios.lockdown-scenarios-list.table.empty-state.title')
                            }
                            subtitle={
                                showEgressScenarios
                                    ? t('Page.emergency-scenarios.emergency-scenarios-list.table.empty-state.subtitle')
                                    : t('Page.lockdown-scenarios.lockdown-scenarios-list.table.empty-state.subtitle')
                            }
                        />
                    </div>
                )}
            </Paper>
            {loadActiveScenarios && (
                <div className={classes.centeredCircularSpinner}>
                    <LoadingIndicator shouldHavePadding={false} />
                </div>
            )}
        </>
    );
};

export default withApi(LockdownScenariosListing, ApiHelper);

export const nameCell = (
    context,
    allScenariosLoaded,
    loadNextPage,
    onClick,
    classes,
    userCanClickScenario,
    t,
    showEgressScenario,
    loadActiveScenarios
) => {
    const {
        row: {
            index,
            original: { name },
        },
        rows,
    } = context;
    const rowsNum = rows.length;

    const onClickHandler = () => onClick(context.row.original);

    const conditionalProps = {
        className: 'u-trim',
        title: name,
    };

    if (userCanClickScenario && !loadActiveScenarios) {
        conditionalProps.onClick = onClickHandler;
        conditionalProps.className = clsx(classes.scenarioName, 'u-trim');
    }
    const formattedValue = (
        <span {...conditionalProps}>
            {name}
            {showEgressScenario && context.row.original.isDefault ? (
                <span className={classes.defaultClass}>
                    {t('Page.lockdown-scenarios.lockdown-scenarios-list.table.name.default')}
                </span>
            ) : null}
        </span>
    );

    if (!allScenariosLoaded && rows[rowsNum - 1].index === index) {
        return (
            <>
                <Waypoint key="loadMore" onEnter={loadNextPage} fireOnRapidScroll />
                {formattedValue}
            </>
        );
    }

    return formattedValue;
};

const viewCell = (context, onClick, isDisabled, label, classes) => {
    const buttonClickHandler = () => onClick(context.row.original);
    return (
        <AccessControl necessaryPermissions={[DEVICES_VIEW_DEVICES]}>
            <ViewButton
                className={classes.viewButton}
                buttonClickHandler={buttonClickHandler}
                disabled={isDisabled}
                label={label}
                id={context.row.original.id}
            />
        </AccessControl>
    );
};

const activateDeactivateScenario = (
    context,
    onClick,
    t,
    activeScenarios,
    loadActiveScenarios,
    userCanInitiateScenario,
    userCanClearScenario
) => {
    const buttonClickHandler = (callActivateOnClick) => onClick(context.row.original, callActivateOnClick);

    let text = t('Page.lockdown-scenarios.lockdown-scenarios-list.table.activate');
    let callActivateOnClick = true;
    let buttonType = 'secondary-error';
    let hasPermission = userCanInitiateScenario;
    let isActiveScenario = activeScenarios?.find((element) => element.id === context.row.original.id);

    if (isActiveScenario) {
        text = t('Page.lockdown-scenarios.lockdown-scenarios-list.table.deactivate');
        callActivateOnClick = false;
        buttonType = 'secondary-success';
        hasPermission = userCanClearScenario;
    }

    return (
        <Button
            type={buttonType}
            onClick={(event) => {
                if (buttonClickHandler) {
                    event.stopPropagation();
                    buttonClickHandler(callActivateOnClick);
                }
            }}
            text={text}
            disabled={!hasPermission || loadActiveScenarios}
            id={context.row.original.id}
        />
    );
};

const typeCell = (context) => {
    const {
        row: {
            original: { type },
        },
    } = context;

    const conditionalProps = {
        className: 'u-trim',
        title: type,
    };

    return <span {...conditionalProps}>{type}</span>;
};
