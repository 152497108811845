import React, { Suspense, lazy } from 'react';

const AccountSettingsPage = lazy(() => import('./AccountSettingsPage'));

const AccountSettingsContainer = (props) => (
    <Suspense fallback={null}>
        <AccountSettingsPage {...props} />
    </Suspense>
);

export default AccountSettingsContainer;
