import React from 'react';
import clsx from 'clsx';

import { Button, ChevronRight } from '@brivo/react-components';
import { isDoorType } from '@brivo/device-status-utils';

import { ALWAYS_ACCESS } from '../../../common/constants/Constants';
import { isTypeFloor, isTypeSwitch, isTypeValidCredential } from '../../../common/utils/Utils';

import {
    COMMANDS_PAGE_SIZE,
    FIELD_NAME,
    OUTPUT_POINT,
    SECTION_CONFIGURATION,
    SECTION_GROUPS,
    VALID_PANEL_IDS_FOR_READER_COMMANDS,
} from './commandsConstants';

export const buildReaderCommand = (values, controlPanelId) => {
    let readerCommandData = {};
    const mappedGroups = values[FIELD_NAME.groups].map((g) => g.id);

    readerCommandData.name = values[FIELD_NAME.commandName]?.trim();
    readerCommandData.inputDeviceObjectId = values[FIELD_NAME.input];
    readerCommandData.inputDeviceState = values[FIELD_NAME.inputState];
    readerCommandData.numberOfSwipes = values[FIELD_NAME.trigger];
    readerCommandData.readerCommandGroups = mappedGroups;
    readerCommandData.scheduleId = values[FIELD_NAME.schedule];
    readerCommandData.readerCommandAccessPoints = values[FIELD_NAME.accessPoints];
    readerCommandData.description = values[FIELD_NAME.commandDescription];
    readerCommandData.swipeDelay = parseInt(values[FIELD_NAME.swipeTimeout], 10);
    readerCommandData.outputDelay = parseInt(values[FIELD_NAME.delay], 10);
    readerCommandData.readerCommandOutputs = [
        {
            behavior: 3,
            behaviorArgument: 2,
            boardNumber: values[FIELD_NAME.board],
            boardTypeId: values[FIELD_NAME.boardTypeId],

            outputType: 0,
            panelObjectId: controlPanelId,
            pointAddressId: values[FIELD_NAME.relay],
        },
    ];
    return { readerCommandData };
};

export const validateReaderCommand = (readerCommand, siteId, panelId, t) => {
    let validationMessages = [];

    if (!readerCommand.name) {
        validationMessages.push({
            targetId: 'TextField__commandName',
            erroredField: `${t('Page.commands-add-reader-name-label')}`,
        });
    }

    if (!readerCommand.scheduleId) {
        validationMessages.push({
            targetId: 'SingleSelectField__select-schedule-in-scheduleId',
            erroredField: `${t('Page.commands-select-schedule-label')}`,
        });
    }

    if (!readerCommand.numberOfSwipes) {
        validationMessages.push({
            targetId: 'SingleSelectField__select-swipe-in-swipeId',
            erroredField: `${t('Page.commands-select-swipe-label')}`,
        });
    }

    if (readerCommand.swipeDelay === '' || Number.isNaN(parseInt(readerCommand.swipeDelay, 10))) {
        validationMessages.push({
            targetId: 'TextField__swipeTimeout-label',
            erroredField: `${t('Page.commands-swipe-timeout-label')}`,
        });
    }

    if (!siteId) {
        validationMessages.push({
            targetId: 'select-site-in-siteId',
            erroredField: `${t('Page.commands-select-site-label')}`,
        });
    }

    if (!panelId) {
        validationMessages.push({
            targetId: 'SingleSelectField__select-panel-in-panelId',
            erroredField: `${t('Page.commands-select-panel-label')}`,
        });
    }

    if (!readerCommand.readerCommandOutputs[0].boardNumber) {
        validationMessages.push({
            targetId: 'SingleSelectField__select-board-in-boardId',
            erroredField: `${t('Page.commands-select-board-label')}`,
        });
    }

    if (readerCommand.readerCommandAccessPoints.length === 0) {
        validationMessages.push({
            targetId: 'MultiSelectField__select-door-in-doorId',
            erroredField: `${t('Page.commands-select-access-point-label')}`,
        });
    }

    if (!readerCommand.readerCommandOutputs[0].pointAddressId) {
        validationMessages.push({
            targetId: 'SingleSelectField__select-output-in-outputId',
            erroredField: `${t('Page.commands-select-relay-label')}`,
        });
    }

    if (!readerCommand.inputDeviceObjectId) {
        validationMessages.push({
            targetId: 'SingleSelectField__select-switch-in-switchId',
            erroredField: `${t('Page.commands-select-switch-label')}`,
        });
    }

    if (readerCommand.inputDeviceState === '') {
        validationMessages.push({
            targetId: 'SingleSelectField__select-switchState-in-switchStateId',
            erroredField: `${t('Page.commands-select-input-state-label')}`,
        });
    }

    if (readerCommand.outputDelay === '' || Number.isNaN(parseInt(readerCommand.outputDelay, 10))) {
        validationMessages.push({
            targetId: 'TextField__delay-label',
            erroredField: `${t('Page.commands-select-delay-label')}`,
        });
    }

    if (readerCommand.readerCommandGroups.length === 0) {
        validationMessages.push({
            targetId: 'groups',
            erroredField: `${t('Page.user-details.profile-groups.title')}`,
        });
    }

    return { validationMessages };
};

export const filterAccessPointsByTypeAndPanelId = (acsPoints = [], controlPanelId) => {
    return acsPoints.reduce(
        (acc, ap) => {
            if (isDoorType(ap.type) && ap.controlPanelId === controlPanelId) {
                acc.doors.push(ap);
            }
            if (isTypeSwitch(ap.type) && ap.controlPanelId === controlPanelId && ap.deviceAjarEnabled === false) {
                acc.switches.push(ap);
            }

            return acc;
        },
        {
            doors: [],
            switches: [],
        }
    );
};

export const getAlwaysAccessSchedulePresentForSiteSelected = (schedules) => {
    return schedules.find((schedule) => schedule.originalName === ALWAYS_ACCESS);
};

export const getDefaultScheduleId = (schedules = []) => {
    if (schedules.length > 0) {
        const alwaysAccessSchedule = getAlwaysAccessSchedulePresentForSiteSelected(schedules);
        if (alwaysAccessSchedule) {
            return alwaysAccessSchedule.id;
        } else {
            return schedules[0].id;
        }
    }
};

export const getSiteNameFromSiteOid = (siteOid, sites) => {
    const site = sites && sites.find((site) => site.id === siteOid);
    return site && site.name;
};

export const mapBoards = (response) => {
    let boards = [response.mainBoard, ...response.doorBoards6000, ...response.doorBoards6100];
    if (response?.ioBoards?.length > 0) {
        boards.push(...response.ioBoards);
    }
    const mappedBoards = boards.map((b) => {
        return {
            id: b.boardNumber,
            name: `Board - ${b.boardNumber}`,
            ...b,
        };
    });
    return mappedBoards;
};

export const isAlwaysAccessOrUniversalSchedule = (schedule) => {
    return schedule.name === ALWAYS_ACCESS || !schedule.siteId;
};

export const filterSchedules = (schedules) =>
    schedules?.filter((schedule) => isAlwaysAccessOrUniversalSchedule(schedule));

export const filterAndMapOutputs = (boardId, boards) => {
    return boards
        .find((b) => b.id === boardId)
        ?.ioPoints.filter((p) => p.type === OUTPUT_POINT)
        .map((o) => {
            return {
                id: o.id,
                name: o.label,
            };
        });
};

export const filterAndMapPanels = (panels) => {
    const filteredPanels = filterPanelsForReaderCommands(panels);
    return mapPanels(filteredPanels);
};

export const filterPanelsForReaderCommands = (panels) => {
    return panels.filter(
        (p) => VALID_PANEL_IDS_FOR_READER_COMMANDS.includes(p.panelTypeId) && isValidVersion(p.firmwareVersion)
    );
};

// Valid version is equal or greater than 6.1.9.1
export const isValidVersion = (version) => {
    const [major, minor, build, revision] = version.split('.').map((v) => parseInt(v, 10));
    if (major > 6) return true;
    if (major === 6) {
        if (minor > 1) return true;
        if (minor === 1) {
            if (build > 9) return true;
            if (build === 9) {
                if (revision >= 1) return true;
            }
        }
    }
    return false;
};

export const mapPanels = (panels) => {
    return panels.map((p) => {
        return {
            id: p.panelId,
            name: p.panelName,
            controlPanelId: p.id,
        };
    });
};

export const scrollToTop = () => {
    const elem = document.querySelector('#page-title');
    if (elem) {
        elem.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });
    }
};

export function getSiteCell(context, sites) {
    const {
        row: {
            original: { siteIds },
        },
    } = context;

    if (siteIds.length === 0) {
        return null;
    }

    const siteId = siteIds[0];

    const site = sites.find((s) => s.id === siteId);

    if (site === undefined) {
        return null;
    }

    const siteName = site.name;

    const formattedValue = (
        <span className={clsx('u-trim')} title={`${siteName}`}>
            {siteName}
        </span>
    );

    return formattedValue;
}

export function getViewCell(context, viewHandler, t) {
    const id = context.cell.row.original.id;

    return (
        <div style={{ marginLeft: 'auto' }}>
            <Button
                type="tertiary"
                endIcon={<ChevronRight />}
                onClick={() => viewHandler(id)}
                text={t('Page.commands.button.view')}
                size="small"
            />
        </div>
    );
}

export const translateOptions = (options = [], t) => {
    return options.map((opt) => ({ ...opt, name: t(opt.template) }));
};

export function getControlPanelId(panelId, panels) {
    if (panels && panels.length && panelId) {
        return panels.find((p) => p.id === panelId)?.controlPanelId;
    }
}

export const addLabelAndIcon = (items, t) => {
    return items.map((i) => {
        if (i.id === SECTION_CONFIGURATION) {
            return { ...i, label: t('Page.commands-configuration-section-label') };
        }
        if (i.id === SECTION_GROUPS) {
            return { ...i, label: t('Page.commands-groups-section-label') };
        }
        return i;
    });
};

export const processData = ([accessPointsData, panelsData]) => {
    const filteredAccessPoints = accessPointsData.getAccessPoints?.filter((accessPoint) => {
        return !isTypeValidCredential(accessPoint.type) && !isTypeFloor(accessPoint.type);
    });
    return { accessPoints: filteredAccessPoints, panels: panelsData.panelsPerSites };
};

export function byNamePropertyAlphabetically(a, b) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
}

export const getQueryParams = ({ offset, filters } = { offset: 0, filters: {} }) => {
    const params = {
        offset,
        pageSize: COMMANDS_PAGE_SIZE,
    };

    const filterParam = [];

    if (filters.name) {
        filterParam.push(`name__eq:${filters.name}`);
    }

    if (filters.site.length > 0) {
        filterParam.push(`siteId__eq:${filters.site.map((s) => s.id).join(',')}`);
    }

    if (filterParam.length > 0) {
        params.filter = filterParam.join(';');
    }

    return params;
};
