import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            d="M18
            0H2C.896 0
            0 .898 0
            2v12c0
            1.103.896
            2 2 2h16c1.104
            0 2-.897
            2-2V2c0-1.102-.896-2-2-2zM2
            14V2h15.999l-.002 12H2z"
            transform="translate(-342 -147) translate(328 127) translate(16 24)"
        />
        <path
            d="M0 7.63c0-1.963 1.236-3.2 3.2-3.2 1.964 0 3.2 1.237 3.2 3.2H0z"
            transform="translate(-337.763 -142.846) translate(328 127) translate(16 24)"
        />
        <circle cx="3.129" cy="2" r="2" transform="translate(-337.763 -142.846) translate(328 127) translate(16 24)" />
        <path
            d="M14 9H18V11H14zM14 13H18V15H14z"
            transform="translate(-344 -151) translate(328 127) translate(16 24)"
        />
    </React.Fragment>,
    'Cards'
);
