import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon - mobile pass">
                <g id="mobile-phone-signal">
                    <g id="Line_Icons">
                        <g id="Group">
                            <path
                                id="Path"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.0845 9.16831L14.4381 10.5219L15.7898 9.16831C15.4451 8.81407 14.9651 8.60191 14.4381 8.60191C13.9091 8.60191 13.4291 8.81407 13.0845 9.16831Z"
                                fill="#7DA6DB"
                            />
                            <path
                                id="Path_2"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.3706 6.45631L11.7415 7.82623C12.4567 7.10431 13.4051 6.67999 14.4419 6.67999C15.4768 6.67999 16.4234 7.10143 17.1395 7.82047L18.5114 6.44863C17.4362 5.37055 15.9962 4.75999 14.4419 4.75999C12.9117 4.75999 11.4439 5.38591 10.3706 6.45631Z"
                                fill="#7DA6DB"
                            />
                            <path
                                id="Path_3"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.4419 0.919998C11.8806 0.919998 9.45473 1.94336 7.65857 3.74144L9.01409 5.09888C10.4512 3.65984 12.3923 2.84 14.4419 2.84C16.4915 2.84 18.4298 3.65792 19.8659 5.09312L21.2224 3.7376C19.4262 1.9424 17.0032 0.919998 14.4419 0.919998Z"
                                fill="#7DA6DB"
                            />
                            <path
                                id="Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.84001 17.24H12.52V11.48H14.44V21.08C14.44 22.1398 13.5789 23 12.52 23H4.84001C3.78113 23 2.92001 22.1398 2.92001 21.08V5.72C2.92001 4.66016 3.78113 3.8 4.84001 3.8H6.76001V5.72H4.84001V7.64H9.64001V9.56H4.84001V17.24ZM4.84001 19.16V21.08H12.52V19.16H4.84001Z"
                                fill="#485769"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>,
    'RecentActivityUsingMobilePass'
);
