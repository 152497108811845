import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFlagClient } from '@brivo/onairplus-services';
import { Menu, MenuItem, List, ListItem, ListItemText } from '@brivo/react-components';

import styles from './styles';

const options = ['en', 'testLanguage', 'es', 'fr', 'nl'];

const LanguageSwitcher = () => {
    const classes = styles();
    const flagClient = useFlagClient();
    const enableLanguageSwitcher = flagClient.variation('language-switcher', false);
    const { i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (index, option) => {
        setSelectedIndex(index);
        i18n.changeLanguage(option);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const currentLanguage = navigator.language;
        switch (currentLanguage) {
            case 'en':
                setSelectedIndex(0);
                break;
            case 'testLanguage':
                setSelectedIndex(1);
                break;
            case 'es':
                setSelectedIndex(2);
                break;
            case 'fr-CA':
                setSelectedIndex(3);
                break;
            case 'nl':
                setSelectedIndex(4);
                break;
            default:
                setSelectedIndex(0);
                break;
        }
    }, []);

    return enableLanguageSwitcher ? (
        <>
            <span className={classes.separator}>|</span>
            <div className={classes.root}>
                <List
                    component="nav"
                    aria-label="language-switcher"
                    data-testid="language-switcher-list"
                    disablePadding
                >
                    <ListItem
                        button
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        aria-label="language-switcher"
                        onClick={handleClickListItem}
                        className={classes.listItem}
                        data-testid="language-switcher-list-item-id"
                    >
                        <ListItemText primary={options[selectedIndex]} className={classes.listItemText} />
                    </ListItem>
                </List>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.menu}
                    data-testid="language-switcher-menu-id"
                >
                    {options?.map((option, index) => (
                        <MenuItem
                            key={option}
                            data-testid="language-switcher-menu-item-id"
                            selected={index === selectedIndex}
                            onClick={() => handleMenuItemClick(index, option)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </>
    ) : null;
};

export default LanguageSwitcher;
