import gql from 'graphql-tag';

export const GET_VIDEO_LAYOUTS_LIST = gql`
    query getVideoLayoutsList {
        getVideoLayoutsList {
            id
            name
            type
            sharingType
            isDefault
        }
    }
`;

export const GET_CAMERAS_BY_LAYOUT = gql`
    query getCamerasByLayout($layoutId: Int!, $rows: Int!, $offset: Int!) {
        getCamerasByLayout(layoutId: $layoutId, limit: { rows: $rows, offset: $offset }) {
            id
            layoutType
            camerasDetails {
                id
                cameraId
                cameraName
                isOnAssignedSite
                liveViewUrl
                ordering
                size
                status
                videoProviderType
                layoutType
            }
            camerasCount
        }
    }
`;

export const GET_ALL_CAMERAS = gql`
    query getAllCameras($rows: Int!, $offset: Int!, $filters: [ListFilter]) {
        getAllCameras(limit: { rows: $rows, offset: $offset }, filters: $filters) {
            id
            cameraId
            name
            site {
                id
                siteName
            }
            videoProvider {
                type
            }
        }
        getAllCamerasCount(filters: $filters)
    }
`;

export const GET_ALL_CAMERAS_PAGINATED_WITH_COUNT = gql`
    query getAllCameras($rows: Int!, $offset: Int!, $filters: [ListFilter]) {
        getDetailedCameras(limit: { rows: $rows, offset: $offset }, filters: $filters) {
            id
            cameraId
            name
            site {
                id
                siteName
            }
            videoProvider {
                type
            }
            liveViewUrl
            status
        }
        getAllCamerasCount(filters: $filters)
    }
`;
