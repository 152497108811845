import { FunctionComponent, lazy, Suspense } from 'react';

const IncidentManagementPage = lazy(() => import('./IncidentManagementPage'));

const IncidentManagementContainer: FunctionComponent = (props = {}) => {
    return (
        <Suspense fallback={null}>
            <IncidentManagementPage {...props} />
        </Suspense>
    );
};

export default IncidentManagementContainer;
