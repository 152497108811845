import useStyles from './styles';
import { Typography } from '@brivo/react-components';
import { BoxIcon } from '@brivo/react-components';

const NoVisualFound = ({ title, subtitle }) => {
    const classes = useStyles();

    return (
        <div className={classes.noVisualFoundWrapper}>
            <BoxIcon />
            <Typography className={classes.noVisualFoundTitle} variant="h6">
                {title}
            </Typography>
            <Typography className={classes.noVisualFoundSubtitle} variant="subtitle1">
                {subtitle}
            </Typography>
        </div>
    );
};
export default NoVisualFound;
