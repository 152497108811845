import React, { useEffect, useState, useRef } from 'react';
import { buildGqlFilters, initialFilters } from '../../helpers';
import { GQLProviderContext } from '../../../../../common/utils/CustomApolloProvider';
import { UserContext } from '../../../../../common/user/UserProvider';
import { EDITION_STANDARD } from '../../../../../common/constants/Constants';

const DEFAULT_PAGE_SIZE = 200;

export const useGetAllDoors = ({
    api,
    defaultValues,
    emergencyScenariosFlag = false,
    scenarioTypeId,
    selectedPanelId,
}) => {
    const gqlHelper = React.useContext(GQLProviderContext);
    const [allLoadedItems, setAllLoadedItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [allSites, setAllSites] = useState(null);
    const [totalCount, setTotalCount] = useState(defaultValues?.length);
    const [count, setCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const lastPromise = useRef();
    const isMounted = useRef(false);
    const { userInfo } = React.useContext(UserContext);
    const isStandardEdition = userInfo?.subscriptionLevel === EDITION_STANDARD;

    const loadNextPage = async () => {
        if (!isLoading && count >= offset + DEFAULT_PAGE_SIZE) {
            setIsLoading(true);
            setOffset(offset + DEFAULT_PAGE_SIZE);
        }
    };

    const handleFiltersChange = (value, selectedItems) => {
        setOffset(0);
        setFilters(value);
        if (isStandardEdition && selectedPanelId) {
            setAllLoadedItems(selectedItems.filter((door) => door.controlPanelId === selectedPanelId));
        } else {
            setAllLoadedItems(selectedItems);
        }
        setSelectedItems(selectedItems);
        setIsLoading(true);
    };

    useEffect(() => {
        function getItems() {
            const currentPromise = emergencyScenariosFlag
                ? api(null, null, 'name', 'ASC', buildGqlFilters(filters, scenarioTypeId))
                : api(DEFAULT_PAGE_SIZE, offset, 'name', 'ASC', buildGqlFilters(filters, scenarioTypeId));
            lastPromise.current = currentPromise;
            currentPromise.then((result) => {
                if (currentPromise === lastPromise.current) {
                    const { lockdownDoors: allItems, totalCount: resultTotalCount, count: resultCount } = result;

                    //   filter out selected items
                    const allUnselectedItems = allItems.filter((item) => {
                        return selectedItems?.every((selection) => {
                            return selection.id !== item.id;
                        });
                    });

                    if (isStandardEdition && selectedPanelId) {
                        setAllLoadedItems((items) =>
                            items.concat(allUnselectedItems).filter((door) => door.controlPanelId === selectedPanelId)
                        );
                    } else {
                        setAllLoadedItems((items) => items.concat(allUnselectedItems));
                    }
                    if (!isMounted.current) {
                        setTotalCount(resultTotalCount);
                        isMounted.current = true;
                    }
                    if (!isStandardEdition) {
                        setCount(resultCount);
                    }
                    setIsLoading(false);
                }
            });
        }

        getItems();
    }, [
        api,
        offset,
        filters,
        selectedItems,
        emergencyScenariosFlag,
        scenarioTypeId,
        isStandardEdition,
        selectedPanelId,
    ]);

    useEffect(() => {
        async function getSites() {
            const sites = await gqlHelper?.getSites({ includeGeocode: false });
            setAllSites(sites);
        }

        getSites();
    }, [gqlHelper]);

    useEffect(() => {
        if (isStandardEdition) {
            setCount(allLoadedItems?.length);
        }
    }, [allLoadedItems?.length, isStandardEdition]);

    return [allLoadedItems, allSites, isLoading, count, totalCount, filters, handleFiltersChange, loadNextPage];
};
