import { get } from 'lodash';

import { isDoorType } from '@brivo/device-status-utils';

import {
    STATUS_CONNECTED,
    STATUS_DISCONNECTED,
    STATUS_UNKNOWN,
    UNKNOWN,
    OFFLINE_CONTROL_DEVICES,
} from '@common/constants/Constants';
import UserTracking from '@common/utils/UserTracking';
import { isMonitoredDoor, getMonitoredDoorConnectionStatus } from '@common/utils/deviceUtils';
import { CALL_ELEVATOR_ALLOWED_EDITIONS } from './deviceConstants';

export const isOffline = (device) => {
    const deviceType = get(device, 'deviceType');

    switch (deviceType) {
        case 'PANEL': {
            return get(device, 'status') === 'DISCONNECTED';
        }
        case 'DOOR': {
            return get(device, 'status.isOffline');
        }
        case 'CAMERA': {
            return get(device, 'status') !== 'CONNECTED';
        }
        case 'SWITCH': {
            return get(device, 'status.isOffline');
        }
        default: {
            return false;
        }
    }
};

export const handleNewMessages = (messages, prevMessages, handleDeviceStatusMessageType, handleCameraMessageType) => {
    for (const element of messages) {
        const { data, eventType } = element;

        if (!data || !eventType || prevMessages === messages || eventType === 'PANEL_EVENT') {
            continue;
        }

        switch (eventType) {
            case 'DEVICE_STATUS':
                handleDeviceStatusMessageType(element);
                break;

            // NOTE: the name of the `eventType` might change
            case 'CAMERA_STATUS':
                handleCameraMessageType(element);
                break;

            default:
                break;
        }
    }
};

const isIgnoringNow = (ignoreScheduleStatus, unlockScheduleStatus, nextIgnoreScheduleChange) => {
    if (ignoreScheduleStatus === 1) {
        return true;
    }

    let noDateBasically = new Date(0);

    return nextIgnoreScheduleChange !== noDateBasically && unlockScheduleStatus !== 1;
};

const isIgnoringAndIfInUnlockSched = (rawDoorData) => {
    let isIgnoring = isIgnoringNow(
        rawDoorData.ignoreScheduleStatus,
        rawDoorData.unlockScheduleStatus,
        rawDoorData.nextIgnoreScheduleChange
    );
    let isInUnlockSchedule = rawDoorData.unlockScheduleStatus === 1;
    return { isIgnoring: isIgnoring, isInUnlockSchedule: isInUnlockSchedule };
};

export const setStatusOnAccessPoint = (accessPoints, doorStatusData) => {
    if (!Array.isArray(accessPoints) || !Array.isArray(doorStatusData)) {
        return [];
    }

    return accessPoints?.map((accessPoint) => {
        // Door types are:
        // 'Door', 'Salto Door Lock', 'Allegion Door Lock', 'Assa Abloy Door Lock', 'Offline Door Lock', 'Dormakaba Lock'
        // 1,      9,                 11,                   12,                     13                  16
        const foundDevice = doorStatusData.find((door) => accessPoint.id === door?.data?.deviceId);
        if (foundDevice) {
            if (isDoorType(accessPoint.type)) {
                if (foundDevice.rawData) {
                    return {
                        ...accessPoint,
                        status: {
                            ...foundDevice.data,
                            isOffline: foundDevice.rawData.connectedStatus === 0,
                            eggTimerData: isIgnoringAndIfInUnlockSched(foundDevice.rawData),
                            rawData: foundDevice.rawData,
                        },
                        deviceType: 'DOOR',
                    };
                }

                return {
                    ...accessPoint,
                    status: foundDevice.data,
                    deviceType: 'DOOR',
                };
            } else {
                if (foundDevice.rawData && foundDevice.rawData?.inputStatus) {
                    return {
                        ...accessPoint,
                        status: {
                            ...foundDevice.data,
                            isOffline: foundDevice.rawData.connectedStatus === 0,
                            isEngaged: foundDevice.rawData.inputStatus === 4,
                            isDisengaged: foundDevice.rawData.inputStatus === 5,
                            rawData: foundDevice.rawData,
                        },
                        deviceType: accessPoint.type.toUpperCase(),
                    };
                }
                return {
                    ...accessPoint,
                    status: foundDevice.data,
                    deviceType: accessPoint.type.toUpperCase(),
                };
            }
        }

        return {
            ...accessPoint,
            deviceType: accessPoint.type.toUpperCase(),
        };
    });
};

export const getDeviceStatus = (device) => {
    if (isMonitoredDoor(device)) {
        return getMonitoredDoorConnectionStatus(device);
    }
    const offlineDevice = isOffline(device);
    const isLockdownActive = get(device, 'status.threatLevel') > 0;
    const isOpen = get(device, 'status.deviceStatus') === 'Open';
    const isClosed = get(device, 'status.deviceStatus') === 'Closed';
    const deviceType = get(device, 'deviceType');
    const isUnknown = get(device, 'status.deviceStatus') === 'Unknown';
    const unknownDevice = deviceType === 'DOOR' && !offlineDevice && !isLockdownActive && !isOpen && !isClosed;

    if (offlineDevice) {
        return STATUS_DISCONNECTED;
    } else if (!offlineDevice && !isUnknown && !unknownDevice) {
        return STATUS_CONNECTED;
    } else {
        return STATUS_UNKNOWN;
    }
};

export const computeStatusMessage = (deviceData) => {
    let systemStatusMessage = get(deviceData, 'status.systemStatusMessage');
    if (systemStatusMessage) {
        systemStatusMessage = systemStatusMessage.replace(/ /g, '_').toUpperCase();
    }

    //Messages to ignore for now CE-870, to be removed after firmware fix
    const lowBatteryStatusMessages = [
        'LOW_BATTERY',
        'BATTERY_LEVEL_VERY_LOW',
        'BATTERY_LEVEL_LOW',
        'BATTERY_REMAINING_ERROR',
        'BATTERY_LEVEL_UNKNOWN',
    ];

    if (lowBatteryStatusMessages.includes(systemStatusMessage)) {
        return '';
    }

    return systemStatusMessage === 'NORMAL' ? '' : systemStatusMessage;
};

export function sendFiltersTrackingData(prevFilters, currFilters) {
    if (prevFilters === undefined) return; // beacuse first render should not trigger

    const modifiedFilter = Object.entries(prevFilters).find(([name, values]) => {
        if (values?.length !== currFilters[name]?.length) return true;

        for (let idx = 0; idx < values?.length; idx++) {
            if (!currFilters[name].includes(values[idx])) return true;
        }

        return false;
    });

    if (modifiedFilter) {
        let filterName = modifiedFilter[0];
        filterName = camelCaseToTitleCase(filterName);

        UserTracking.sendEvent(`${filterName} Filter Modified`, {
            event_category: 'Device Status',
            event_label: 'Filters Adjusted',
        });
    }
}

function camelCaseToTitleCase(string) {
    string = string.replace(/([A-Z])/g, ' $1');
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
}

export const mapPanels = (panels) => {
    return panels.map((p) => ({
        ...p,
        name: p.panelName,
        deviceType: 'PANEL',
    }));
};

export const fetchAccountDevicesCount = async (gqlHelper) => {
    const siteGroupedDevicesCount = await gqlHelper.getDeviceCountsForSites();
    return siteGroupedDevicesCount;
};

export const fetchUnassociatedPanels = async (gqlHelper) => {
    const response = await gqlHelper.getUnassociatedPanels();
    return mapPanels(response);
};

export const fetchVirtualPanelUrl = async (gqlHelper, serialNumber) =>
    gqlHelper.getVirtualPanelUrlBySerialNumber(serialNumber);

export const getOverviewStatusFromDevices = (offlineDevices = [], unknownDevices = [], t) => {
    const status = [];
    if (offlineDevices.length > 0) {
        status.push(
            t(`Page.device-status.per-site.header.offline`, {
                offlineDevicesLength: offlineDevices.length,
            })
        );
    }
    if (unknownDevices.length > 0) {
        status.push(
            t(`Page.device-status.per-site.header.unknown`, {
                unknownDevicesLength: unknownDevices.length,
            })
        );
    }
    return status.length === 0
        ? t('Page.device-status.per-site.header.all-online')
        : status.join(t('Page.device-status.per-site.header.and'));
};

export const getDeviceTypes = () => {
    let deviceTypes = [
        { key: 'CAMERA', label: 'Cameras' },
        { key: 'DOOR', label: 'Doors' },
        { key: 'ELEVATOR', label: 'Elevators' },
        { key: 'INTRUSION_PANEL', label: 'Intrusion Panels' },
        { key: 'PANEL', label: 'Panels' },
        { key: 'MONITORED_DOOR', label: 'Monitored Doors' },
    ];

    deviceTypes.push({ key: 'SWITCH', label: 'Switches' });

    return deviceTypes;
};

export const getDoorPosition = (deviceData, deviceStatus) => {
    const deviceInputStatus = deviceData?.status?.rawData?.inputStatus;
    const doorPositionMap = {
        4: 'open',
        5: 'closed',
    };

    const doorPosition = deviceStatus === UNKNOWN ? 'unknown' : doorPositionMap[deviceInputStatus] || 'unknown';

    return doorPosition;
};

export const getDeviceType = (device) => {
    return OFFLINE_CONTROL_DEVICES.includes(device?.type) ? 'OFFLINE_LOCK' : get(device, 'deviceType');
};

export const checkIfCallElevatorHasAllowedEdition = (edition) => {
    return CALL_ELEVATOR_ALLOWED_EDITIONS.includes(edition);
};

export const getInterfaceText = (interfaceName) => {
    switch (interfaceName) {
        case 'ppp0':
            return 'Cellular';
        case 'eth0':
            return 'Ethernet';
        case 'wlan0':
            return 'WIFI';
        default:
            return 'Unknown';
    }
};
