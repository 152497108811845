import React, { Suspense, lazy } from 'react';

const EventConfig = lazy(() => import('./EventConfig'));

const EventConfigContainer = (props) => {
    return (
        <Suspense fallback={null}>
            <EventConfig {...props} />
        </Suspense>
    );
};

export default EventConfigContainer;
