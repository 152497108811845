import React from 'react';
import { SvgIcon } from '@brivo/react-components';
import { isProduction } from '../constants/Constants';
export default function createSvgIcon(path, displayName) {
    const Component = React.memo(
        React.forwardRef((props, ref) => (
            <SvgIcon data-mui-test={`${displayName}Icon`} ref={ref} style={{ transition: 'none' }} {...props}>
                {path}
            </SvgIcon>
        ))
    );

    if (!isProduction) {
        Component.displayName = `${displayName}Icon`;
    }

    Component.muiName = SvgIcon.muiName;

    return Component;
}
