import createSvgIcon from '../../../common/utils/createSvgIcon';

const EventTrackerIcon = createSvgIcon(
    <>
        <path
            d="M0 4.95238C0 2.21726 1.59667 0 3.56627 0H70.4337C72.4033 0 74 2.21726 74 4.95238V47.0476C74 49.7827 72.4033 52 70.4337 52H3.56627C1.59667 52 0 49.7827 0 47.0476V4.95238Z"
            fill="#344559"
        />
        <path
            d="M5.3494 26.619C5.3494 25.5934 5.94815 24.7619 6.68675 24.7619H18.2771C19.0157 24.7619 19.6145 25.5934 19.6145 26.619C19.6145 27.6447 19.0157 28.4762 18.2771 28.4762H6.68675C5.94815 28.4762 5.3494 27.6447 5.3494 26.619Z"
            fill="#607185"
        />
        <path
            d="M5.3494 32.8095C5.3494 31.7839 5.94815 30.9524 6.68675 30.9524H18.2771C19.0157 30.9524 19.6145 31.7839 19.6145 32.8095C19.6145 33.8352 19.0157 34.6667 18.2771 34.6667H6.68675C5.94815 34.6667 5.3494 33.8352 5.3494 32.8095Z"
            fill="#607185"
        />
        <path
            d="M5.3494 20.4286C5.3494 19.4029 5.94815 18.5714 6.68675 18.5714H18.2771C19.0157 18.5714 19.6145 19.4029 19.6145 20.4286C19.6145 21.4542 19.0157 22.2857 18.2771 22.2857H6.68675C5.94815 22.2857 5.3494 21.4542 5.3494 20.4286Z"
            fill="#7D8FA3"
        />
        <path
            d="M5.3494 14.2381C5.3494 13.2124 5.94815 12.381 6.68675 12.381H18.2771C19.0157 12.381 19.6145 13.2124 19.6145 14.2381C19.6145 15.2638 19.0157 16.0952 18.2771 16.0952H6.68675C5.94815 16.0952 5.3494 15.2638 5.3494 14.2381Z"
            fill="#F1D856"
        />
        <path
            d="M5.3494 38.381C5.3494 37.6972 5.74857 37.1429 6.24096 37.1429H18.7229C19.2153 37.1429 19.6145 37.6972 19.6145 38.381C19.6145 39.0647 19.2153 39.619 18.7229 39.619H6.24096C5.74857 39.619 5.3494 39.0647 5.3494 38.381Z"
            fill="#607185"
        />
        <path
            d="M23.1807 26.619C23.1807 25.5934 23.7795 24.7619 24.5181 24.7619H36.1084C36.847 24.7619 37.4458 25.5934 37.4458 26.619C37.4458 27.6447 36.847 28.4762 36.1084 28.4762H24.5181C23.7795 28.4762 23.1807 27.6447 23.1807 26.619Z"
            fill="#607185"
        />
        <path
            d="M23.1807 32.8095C23.1807 31.7839 23.7795 30.9524 24.5181 30.9524H36.1084C36.847 30.9524 37.4458 31.7839 37.4458 32.8095C37.4458 33.8352 36.847 34.6667 36.1084 34.6667H24.5181C23.7795 34.6667 23.1807 33.8352 23.1807 32.8095Z"
            fill="#607185"
        />
        <path
            d="M41.0121 26.619C41.0121 25.5934 41.6108 24.7619 42.3494 24.7619H67.3133C68.0518 24.7619 68.6506 25.5934 68.6506 26.619C68.6506 27.6447 68.0518 28.4762 67.3133 28.4762H42.3494C41.6108 28.4762 41.0121 27.6447 41.0121 26.619Z"
            fill="#607185"
        />
        <path
            d="M41.0121 32.8095C41.0121 31.7839 41.6108 30.9524 42.3494 30.9524H67.3133C68.0518 30.9524 68.6506 31.7839 68.6506 32.8095C68.6506 33.8352 68.0518 34.6667 67.3133 34.6667H42.3494C41.6108 34.6667 41.0121 33.8352 41.0121 32.8095Z"
            fill="#607185"
        />
        <path
            d="M23.1807 20.4286C23.1807 19.4029 23.7795 18.5714 24.5181 18.5714H36.1084C36.847 18.5714 37.4458 19.4029 37.4458 20.4286C37.4458 21.4542 36.847 22.2857 36.1084 22.2857H24.5181C23.7795 22.2857 23.1807 21.4542 23.1807 20.4286Z"
            fill="#7D8FA3"
        />
        <path
            d="M23.1807 14.2381C23.1807 13.2124 23.7795 12.381 24.5181 12.381H36.1084C36.847 12.381 37.4458 13.2124 37.4458 14.2381C37.4458 15.2638 36.847 16.0952 36.1084 16.0952H24.5181C23.7795 16.0952 23.1807 15.2638 23.1807 14.2381Z"
            fill="#7D8FA3"
        />
        <path
            d="M41.0121 20.4286C41.0121 19.4029 41.6108 18.5714 42.3494 18.5714H67.3133C68.0518 18.5714 68.6506 19.4029 68.6506 20.4286C68.6506 21.4542 68.0518 22.2857 67.3133 22.2857H42.3494C41.6108 22.2857 41.0121 21.4542 41.0121 20.4286Z"
            fill="#7D8FA3"
        />
        <path
            d="M41.0121 14.2381C41.0121 13.2124 41.6108 12.381 42.3494 12.381H67.3133C68.0518 12.381 68.6506 13.2124 68.6506 14.2381C68.6506 15.2638 68.0518 16.0952 67.3133 16.0952H42.3494C41.6108 16.0952 41.0121 15.2638 41.0121 14.2381Z"
            fill="#7D8FA3"
        />
        <path
            d="M23.1807 38.381C23.1807 37.6972 23.5799 37.1429 24.0723 37.1429H36.5542C37.0466 37.1429 37.4458 37.6972 37.4458 38.381C37.4458 39.0647 37.0466 39.619 36.5542 39.619H24.0723C23.5799 39.619 23.1807 39.0647 23.1807 38.381Z"
            fill="#607185"
        />
        <path
            d="M41.0121 38.381C41.0121 37.6972 41.4112 37.1429 41.9036 37.1429H67.759C68.2514 37.1429 68.6506 37.6972 68.6506 38.381C68.6506 39.0647 68.2514 39.619 67.759 39.619H41.9036C41.4112 39.619 41.0121 39.0647 41.0121 38.381Z"
            fill="#607185"
        />
    </>,
    'EventTrackerWidgetIcon'
);

const EventTrackerWidgetIcon = <EventTrackerIcon style={{ height: '52px', width: '74px' }} viewBox="0 0 74 52" />;

export default EventTrackerWidgetIcon;
