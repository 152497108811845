export const columnConfiguration = {
    source: 'sites',
    id: 'site_name',
    uuid: 'site_name',
    alias: 'Site',
};

export const dimension = {
    id: 'site_name',
    displayName: 'Site',
    dataDefinition: {
        dataType: 'string',
    },
};
