import { Filter, Column, Dimension } from '@/pages/NewReports/common/interfaces';

export const columnConfiguration: Column = {
    source: 'devices',
    id: 'device_name',
    alias: 'Device Name',
    uuid: 'device_name',
};

export const dimension: Dimension = {
    id: 'device_name',
    displayName: 'Device Name',
    dataDefinition: {
        dataType: 'string',
    },
};
