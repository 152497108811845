export const VIDEO_ITEM = 'VIDEO ITEM';

export function moveCamera({ camList, sourceCamId, destinationCamId }) {
    const newList = sortCamerasByOrdering(camList);

    const fromIndex = newList.findIndex((cam) => cam.id === sourceCamId);
    const toIndex = newList.findIndex((cam) => cam.id === destinationCamId);

    const camToMove = newList.splice(fromIndex, 1)[0];
    newList.splice(toIndex, 0, camToMove);

    newList.forEach((cam, idx) => (cam.ordering = idx));

    return newList;
}

export function switchCamerasWithoutOrdering({ camList, sourceCamId, destinationCamId }) {
    const newList = sortCamerasByOrdering(camList);

    const fromIndex = newList.findIndex((cam) => cam.id === sourceCamId);
    const toIndex = newList.findIndex((cam) => cam.id === destinationCamId);

    [newList[toIndex], newList[fromIndex]] = [
        { ...newList[fromIndex], ordering: newList[toIndex].ordering },
        { ...newList[toIndex], ordering: newList[fromIndex].ordering },
    ];

    return newList;
}

export function sortCamerasByOrdering(cameras = []) {
    const newArr = [...cameras];

    return newArr.sort((camA, camB) => {
        if (camA.ordering < camB.ordering) {
            return -1;
        } else if (camA.ordering > camB.ordering) {
            return 1;
        } else {
            return 0;
        }
    });
}
