import { API_BASE_URL } from '@common/constants/Constants';
import { restClient } from '../RestClient';
import { GET_READER_COMMANDS_FOR_SWITCH } from './gqlTags';
import { WebReqApi } from '../WebReqApi';
import { API_READER_COMMANDS } from '../../constants/Constants';

class ReaderCommandsApi extends WebReqApi {
    getCommands(queryParams = {}) {
        return restClient.makeRequest({
            method: 'GET',
            url: `${API_BASE_URL}/devices/commands`,
            queryParams,
        });
    }

    getReaderCommand(id) {
        return restClient.makeRequest({
            method: 'GET',
            url: `${API_READER_COMMANDS}/${id}`,
        });
    }

    addNewReaderCommand(commandData) {
        return restClient.makeRequest({
            method: 'POST',
            url: API_READER_COMMANDS,
            body: { ...commandData },
        });
    }

    updateReaderCommand(id, data) {
        return restClient.makeRequest({
            method: 'PUT',
            url: `${API_READER_COMMANDS}/${id}`,
            body: { ...data },
        });
    }

    deleteReaderCommand(id) {
        return restClient.makeRequest({
            method: 'DELETE',
            url: `${API_READER_COMMANDS}/${id}`,
        });
    }

    adminReaderCommandEngage(readerCommandId) {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/access-points/:readerCommandId/adminReaderCommandEngage/1`,
            urlParams: {
                readerCommandId,
            },
        });
    }

    getReaderCommandsForSwitch({ switchId }) {
        return this.makeGqlQuery({
            gqlTag: GET_READER_COMMANDS_FOR_SWITCH,
            variables: {
                switchId,
            },
        });
    }
}

export const readerCommandsApi = new ReaderCommandsApi();
