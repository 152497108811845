import { useEffect, useState } from 'react';
import { Filter } from '@/pages/NewReports/common/interfaces';
import User from '../ColumnFilters/User';
import Site from '../ColumnFilters/Site';
import Device from '../ColumnFilters/Device';
import Group from '../ColumnFilters/Group';

interface ColumnFilterFactory {
    columnUuid: string;
    filter: Filter;
    disabled?: boolean;
}

const ColumnFilterFactory = ({ columnUuid, filter }: ColumnFilterFactory) => {
    const [component, setComponent] = useState<any>(null);

    // TODO: Make disabled when loading MyAnalyticsTable
    useEffect(() => {
        let generatedComponent;
        if (columnUuid === 'user_full_name') {
            generatedComponent = <User disabled={false} filter={filter} />;
        } else if (columnUuid === 'site_name') {
            generatedComponent = <Site disabled={false} filter={filter} />;
        } else if (columnUuid === 'device_name') {
            generatedComponent = <Device disabled={false} filter={filter} />;
        } else if (columnUuid === 'user_group_name') {
            generatedComponent = <Group disabled={false} filter={filter} />;
        } else {
            console.error('Component generation failed - component not supported');
        }

        setComponent(generatedComponent);
    }, [filter, columnUuid]);

    return component ?? null;
};

export default ColumnFilterFactory;
