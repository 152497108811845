import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    timestampCell: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '.75rem',
    },
    date: {
        color: theme.palette.type === 'dark' ? theme.palette.colors.grey400 : theme.palette.colors.black,
    },
}));

export default styles;
