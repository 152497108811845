export const columnsDataConfiguration = {
    full_name: {
        type: 'string',
        sortable: true,
    },
    first_name: {
        type: 'string',
        sortable: true,
    },
    middle_name: {
        type: 'string',
        sortable: true,
    },
    last_name: {
        type: 'string',
        sortable: true,
    },
    created: {
        type: 'timestamp_ntz',
        sortable: true,
    },
    deactivated: {
        type: 'timestamp_ntz',
        sortable: true,
    },
    site_name: {
        type: 'string',
        sortable: true,
    },
    device_name: {
        type: 'string',
        sortable: true,
    },
    user_last_name: {
        type: 'string',
        sortable: true,
    },
    user_first_name: {
        type: 'string',
        sortable: true,
    },
    event_time: {
        type: 'timestamp_ntz',
        sortable: true,
    },
    event_date: {
        type: 'date',
        sortable: true,
    },
    visit_count: {
        type: 'number',
        sortable: true,
    },
    time_in: {
        type: 'time',
        sortable: true,
    },
    date_in: {
        type: 'date',
        sortable: true,
    },
    expiration_date: {
        type: 'timestamp_ntz',
    },
    format: {
        type: 'string',
    },
    admin_full_name: {
        type: 'string',
    },
};

export const SORTING_DESIGNATION = 'sorting';
export const FILTERING_DESIGNATION = 'filter';
export const COLUMN_DESIGNATION = 'column';
export const METRIC_DESIGNATION = 'metric';
export const ROW_DESIGNATION = 'row';
export const STRING_DATATYPE = 'string';
export const NUMBER_DATATYPE = 'number';
export const DATE_DATATYPE = 'date';
export const TIMESTAMP_NTZ_DATATYPE = 'timestamp_ntz';
export const TIMESTAMP_DATATYPE = 'timestamp';
export const BOOLEAN_DATATYPE = 'boolean';
export const TIME_DATATYPE = 'time';

export const SORTABLE_DATA_TYPES = [
    STRING_DATATYPE,
    NUMBER_DATATYPE,
    DATE_DATATYPE,
    TIMESTAMP_NTZ_DATATYPE,
    TIMESTAMP_DATATYPE,
    BOOLEAN_DATATYPE,
];

export const DATA_EXPLORER_TABLES_KEY = 'DataExplorerTables';
