const styles = (theme) => ({
    popup: {
        boxShadow: '0px 8px 16px rgba(8, 13, 19, 0.2)',
        // backgroundColor: theme.palette.type === 'dark' ? theme.palette.colors.grey800 : null,
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.colors.grey900 : null,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
    },
    text: {
        fontSize: 14,
        lineHeight: '16px',
    },
    menuItemList: {
        padding: '8px !important',
        listStyle: 'none',
    },
    menuItem: {
        fontSize: 14,
        padding: '8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(91, 150, 213, 0.15) !important',
            borderRadius: '3px',
        },
    },
    menuItemDisabled: {
        color: `${theme.palette.colors.grey400} !important`,
        fontSize: 14,
        lineHeight: '18px',
        borderBottom: 'none !important',
        padding: '8px',
        pointerEvents: 'none',
        cursor: 'default',
        '&:hover': {
            color: `${theme.palette.colors.grey400} !important`,
            backgroundColor: 'rgba(91, 150, 213, 0.15) !important',
            borderRadius: '3px',
        },
    },
    adminID: {
        fontSize: 14,
        lineHeight: '18px',
        borderBottom: 'none !important',
        padding: '0 8px',
        whiteSpace: 'nowrap',
    },
    divider: {
        border: '1px solid #344559',
    },
    helpSection: {
        marginTop: '16px',
        height: '48px',
    },
    helpText: {
        marginLeft: '64px',
        color: theme.palette.brivoAliases.newNavUserNameColor,
        animation: 'fadeIn 3s',
        fontSize: 14,
        fontWeight: 600,
    },
    userName: {
        color: theme.palette.brivoAliases.newNavUserNameColor,
        width: '7rem',
        marginLeft: '1rem',
        fontSize: 14,
        fontWeight: 600,
    },
    noHover: {
        pointerEvents: 'none',
    },
    adminName: {
        maxWidth: 170,
        marginRight: 2,
    },
    iconButton: {
        cursor: 'pointer',
        padding: 0,
        color: 'inherit',
        width: '48px',
        height: '48px',
        '&:hover': {
            backgroundColor: 'inherit',
            borderRadius: '50%',
        },
        '& .MuiTouchRipple-root': {
            borderRadius: '50%',
        },
    },
});

export default styles;
