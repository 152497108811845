import { useTranslation } from 'react-i18next';

import {
    Paper,
    Grid,
    AddItemIcon,
    LoadingIndicator,
    Table,
    getHeightFromWindowSize,
    EmptySiteListIcon,
    EmptyStateSection,
} from '@brivo/react-components';

import { getNameCell } from '@common/utils/Utils';
import TableLegend from '@common/components/TableLegend/TableLegend';

import { ViewPropertyCell } from '../PropertyListCells';
import { PropertyListFilterSection } from '../PropertyListFilterSection/PropertyListFilterSection';

const PropertyList = ({ context }) => {
    const { t } = useTranslation();
    const tableContainerProps = {
        'data-testid': 'table-container',
    };

    const getSiteListHeight = () => {
        const height = context.sites?.length * 48;
        const maxHeight = getHeightFromWindowSize();
        return Math.min(height, maxHeight);
    };

    const columns = [
        {
            Header: t('Page.property-list.filter.name'),
            id: 'name',
            sortType: 'ialphanumeric',
            Cell: (arg) => {
                return getNameCell(arg, context.loadNextPage);
            },
        },
        {
            Header: '',
            id: 'view',
            sortType: 'ialphanumeric',
            Cell: (arg) => ViewPropertyCell(arg, false, t('Page.property-list.table.view.button'), context.onViewClick),
        },
    ];

    const showTable = (context.isLoading && context.sites?.length === 0) || context.sites?.length > 0;
    const showEmpty = !context.isLoading && !context.sites?.length && context.sites?.length === 0;
    const showEmptyStateButton = !(context.sites?.length === 0 && context.filterValues.name !== '');

    return (
        <Paper>
            <PropertyListFilterSection
                filterValues={context.filterValues}
                sites={context.sites}
                filterChangeMethods={context.filterChangeMethods}
                onCreateSiteClick={context.onCreateSiteClick}
                onTransformSiteClick={context?.onTransformSiteClick}
                titleProps={context.titleProps}
                isLoading={context.isLoading}
                clearingValues={context.clearingValues}
                setClearingValues={context.setClearingValues}
            />
            {showTable && (
                <>
                    <Table
                        data={context.sites}
                        columns={columns}
                        tableContainerProps={tableContainerProps}
                        heightFunction={getSiteListHeight}
                        clickToSortMessage={t('Table.column.header.sort')}
                    />
                    {context.isLoading && <LoadingIndicator shouldHavePadding={false} />}
                    <div className="u-pv-half">
                        <TableLegend
                            x={context.sites?.length ? context.sites.length : 0}
                            y={context.totalCount}
                            label={t('Page.property-list.table.legend-label')}
                            filtered={context.filterValues?.name}
                        />
                    </div>
                </>
            )}

            {showEmpty && (
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <EmptyStateSection
                            title={context.titleProps.emptyComponentTitle}
                            subtitle={context.titleProps.emptyComponentSubtitle}
                            buttonText={context.titleProps.buttonText}
                            onButtonClick={context.onCreateSiteClick}
                            buttonIcon={<AddItemIcon color="primary" />}
                            showButton={showEmptyStateButton}
                            icon={<EmptySiteListIcon style={{ width: 200, height: 200 }} />}
                        />
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};
export default PropertyList;
