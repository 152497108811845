import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { Grid, useMediaQuery } from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';
import { LoadingIndicator } from '@brivo/react-components';
import ApiHelper from '../../../../common/helpers/Helpers';
import EventsPanel from './components/EventsPanel';
import VideoSection from './components/VideoSection';
import TimelineSection from './components/TimelineSection';
import RecordedVideoFiltersNew from './components/Filters';
import NoVideoAvailable from './components/NoVideoAvailable';
import { startOfDay, endOfDay, startOfHour } from 'date-fns';
import styles from './styles';
import { binEventsByHour, getProviderClipRequestTimes, getTimelineMaxEndTimestamp, chooseListOrDefault } from './utils';
import { allVideoLinkableEvents } from '../../../../common/constants/Constants';
import {
    fetchAdminCamerasWithAssociatedDevices,
    filterCameraListBySites,
    getSelectedCamerasWithDevices,
    getAdminCameras,
} from './cameras';
import { getEvents } from './events';
import { GQLProviderContext } from '../../../../common/utils/CustomApolloProvider';
import { getSignedClipUrlAndType, getClipFromVideoServices } from '../../utils/clipUtils';
import EventsApi from '../../../../common/helpers/EventsApi';
import useGenericFilterGroup from '../../../../common/hooks/useGenericFilterGroup';
import { sortBy } from 'lodash';
import { useTranslatedEventNames } from '../../../../common/hooks/useTranslatedEventNames';
import clsx from 'clsx';

//declared here because it should only be assigned on page load
const currentDate = new Date();

const RecordedVideoPage = ({ api }) => {
    const classes = styles();
    const [events, setEvents] = useState(null);
    const [sites, setSites] = useState([]);
    const [allCameras, setAllCameras] = useState([]);
    const [clipStatus, setClipStatus] = useState({
        selectedClip: false,
        error: false,
        fetchingExternalClip: false,
    });
    const [selectedClipClicked, setSelectedClipClicked] = useState({
        hour: null,
        eventId: null,
        cameraId: null,
    });
    const [selectedCamera, setSelectedCamera] = useState({
        cameraId: null,
        cameraName: null,
    });

    const gqlHelper = useContext(GQLProviderContext);
    const { translateEventName } = useTranslatedEventNames();
    const eventsApi = new EventsApi(gqlHelper);

    const DEFAULT_FILTERS = {
        selectedSites: [],
        selectedEvents: [],
        selectedCameras: [],
        selectedDate: null,
        showCameraEvents: false,
    };
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    const removeSelectedClip = () => {
        setSelectedClipClicked({ hour: null, eventId: null, cameraId: null });
        setClipStatus({ selectedClip: false, error: false, fetchingExternalClip: false });
    };

    const handleRemovingFilters = () => {
        setSelectedCamera({ cameraId: null, cameraName: null });
        removeSelectedClip();
        clearAll();
    };

    const handleFiltersChange = (value) => {
        if (filterValues?.selectedCameras?.length !== 1) {
            setSelectedCamera({ cameraId: null, cameraName: null });
        }
        removeSelectedClip();
        setFilters(value);
    };

    const { filterValues, filterChangeMethods, nrFiltersChanged, clearAll, clearingValues, setClearingValues } =
        useGenericFilterGroup(DEFAULT_FILTERS, handleFiltersChange, filters);

    /**
     * handleClickTimelineProviderClip needs access to the end timestamp
     * of the timeline's currently visible range. It is not possible to get
     * it via the timeline's clickHandler, which is only assigned on mount
     * and is therefore stuck with a frozen initial end time value. So, we use a
     * ref to keep track of it only for clip requests, NOT rendering purposes.
     */
    const timelineEndTimestampRef = useRef(null);

    const timelineRef = useRef();

    const startTimestamp = Date.parse(startOfDay(filterValues.selectedDate ?? new Date()));
    const endTimestamp = getTimelineMaxEndTimestamp(filterValues.selectedDate, currentDate);

    const allVideoLinkableEventsSorted = sortBy(allVideoLinkableEvents, [
        (el) => {
            return translateEventName(el);
        },
    ]);

    const eventsByHour = useMemo(() => binEventsByHour(events), [events]);
    const defaultExpandedHour = useMemo(() => {
        if (eventsByHour) {
            const eventDay = Object.keys(eventsByHour)[0];
            const sortedHours = Object.keys(eventsByHour[eventDay]).sort((a, b) => new Date(b) - new Date(a));
            return sortedHours ? sortedHours[0] : false;
        }
    }, [eventsByHour]);

    //consider debounce
    const handleClickEventCamera = async ({ event, cameraId, cameraName, updateTimelinePositionMarker }) => {
        if (updateTimelinePositionMarker) {
            timelineRef.current?.timeline?.setCustomTime(event.occurred, 'timeline-position');
        }
        setSelectedClipClicked({
            hour: startOfHour(event.occurred).toISOString(),
            eventId: event.id,
            cameraId: cameraId,
        });
        if (selectedCamera?.cameraId !== cameraId) {
            setSelectedCamera({ cameraId: cameraId, cameraName: cameraName });
        }
        try {
            let clip = getSignedClipUrlAndType(event, cameraId);
            if (!clip?.src) {
                setClipStatus({ ...clipStatus, error: false, fetchingExternalClip: true });
                [clip] = await getClipFromVideoServices(api, cameraId, { eventId: event.id }).catch((error) => {
                    throw error;
                });

                setClipStatus({ ...clipStatus, fetchingExternalClip: false });
                if (Object.keys(clip)?.length === 0) {
                    clip = null;
                }
            }
            setClipStatus({ selectedClip: { ...(clip ?? {}), cameraName }, error: false, fetchingExternalClip: false });
        } catch (error) {
            setClipStatus({ selectedClip: { cameraName }, error: error, fetchingExternalClip: false });
        }
    };

    const handleClickTimelineProviderClip = (clipFromList, cameraName, updateTimelinePositionMarker) => {
        try {
            if (updateTimelinePositionMarker) {
                timelineRef.current?.timeline?.setCustomTime(clipFromList.startTime, 'timeline-position');
            }
            setSelectedClipClicked();

            const { startTime, endTime } = getProviderClipRequestTimes(clipFromList, timelineEndTimestampRef.current);
            setClipStatus({ ...clipStatus, error: false, fetchingExternalClip: true });
            getClipFromVideoServices(api, clipFromList.cameraId, { startTime, endTime })
                .then((result) => {
                    if (result) {
                        setClipStatus({
                            selectedClip: { ...(result[0] ?? {}), cameraName },
                            error: false,
                            fetchingExternalClip: false,
                        });
                    } else {
                        setClipStatus({ selectedClip: { cameraName }, error: false, fetchingExternalClip: false });
                    }
                })
                .catch((error) => {
                    setClipStatus({ selectedClip: { cameraName }, error: error, fetchingExternalClip: false });
                });
        } catch (error) {
            setClipStatus({ selectedClip: { cameraName }, error: error, fetchingExternalClip: false });
        }
    };

    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            (async () => {
                const adminSites = await gqlHelper.getSites({ includeGeocode: false });
                setSites(adminSites);
                const adminCameras = await getAdminCameras(gqlHelper, adminSites);
                setAllCameras(adminCameras);
            })();
        }
        return () => {
            ignore = true;
        };
    }, [gqlHelper]);

    useEffect(() => {
        let ignore = false;
        if (!ignore && allCameras?.length) {
            (async () => {
                setEvents(null);
                filterValues?.selectedCameras?.length === 1
                    ? setSelectedCamera({
                          cameraId: filterValues.selectedCameras[0].id,
                          cameraName: filterValues.selectedCameras[0].value,
                      })
                    : setSelectedCamera({ cameraId: null, cameraName: null });
                const camerasWithAssociatedDevices = await fetchAdminCamerasWithAssociatedDevices(
                    gqlHelper,
                    allCameras
                );
                const selectedCamerasWithDevices = getSelectedCamerasWithDevices(
                    filterValues?.selectedCameras,
                    camerasWithAssociatedDevices
                );

                if (!filterValues.selectedCameras?.length || selectedCamerasWithDevices?.length) {
                    getEvents({
                        eventsApi,
                        sites: filterValues.selectedSites,
                        startTime: startOfDay(filterValues.selectedDate ?? startTimestamp).toISOString(),
                        endTime: endOfDay(filterValues.selectedDate ?? endTimestamp).toISOString(),
                        showCameraEvents: true,
                        securityActionIds: chooseListOrDefault(filterValues?.selectedEvents, allVideoLinkableEvents),
                        camerasWithAssociatedDevices: chooseListOrDefault(
                            selectedCamerasWithDevices,
                            camerasWithAssociatedDevices
                        ),
                    }).then((data) => {
                        setEvents(data);
                    });
                } else {
                    setEvents([]);
                }
            })();
        }
        return () => {
            ignore = true;
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, allCameras]);

    const filteredCamerasBySite = filterCameraListBySites(filterValues.selectedSites, allCameras);

    const twoColumns = useMediaQuery('(min-width:961px)');

    return (
        <>
            <RecordedVideoFiltersNew
                sites={sites}
                events={allVideoLinkableEventsSorted}
                cameras={filterValues.selectedSites?.length ? filteredCamerasBySite : allCameras}
                filterValues={filterValues}
                filterChangeMethods={filterChangeMethods}
                nrFiltersChanged={nrFiltersChanged}
                handleRemovingFilters={handleRemovingFilters}
                clearingValues={clearingValues}
                setClearingValues={setClearingValues}
            />
            <Grid container spacing={2} className={classes.pageContentGrid}>
                <Grid
                    item
                    md={twoColumns ? 7 : 12}
                    lg={4}
                    id="event-panel-grid-item"
                    className={clsx(classes.eventPanelGrid, twoColumns ? 'two-columns' : 'one-column')}
                >
                    {events ? (
                        <EventsPanel
                            events={eventsByHour}
                            handleClickEventCamera={handleClickEventCamera}
                            selectedClipClicked={selectedClipClicked}
                            defaultExpandedHour={defaultExpandedHour}
                        />
                    ) : (
                        <LoadingIndicator />
                    )}
                </Grid>
                <Grid
                    item
                    md={twoColumns ? 5 : 12}
                    lg={8}
                    className={clsx(classes.videoSectionGrid, twoColumns ? 'two-columns' : 'one-column')}
                >
                    <Grid container direction="column">
                        <Grid item xs={12} id="video-section-grid-item">
                            {!clipStatus.error && (clipStatus.selectedClip?.src || clipStatus.fetchingExternalClip) ? (
                                <VideoSection
                                    clip={clipStatus.selectedClip}
                                    fetchingExternalClip={clipStatus.fetchingExternalClip}
                                />
                            ) : (
                                <NoVideoAvailable
                                    cameraName={clipStatus.selectedClip?.cameraName}
                                    clipError={clipStatus.error}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <TimelineSection
                                events={events}
                                minStartTime={startTimestamp}
                                maxEndTime={endTimestamp}
                                handleClickEventCamera={handleClickEventCamera}
                                handleClickTimelineProviderClip={handleClickTimelineProviderClip}
                                timelineEndTimestampRef={timelineEndTimestampRef}
                                timelineRef={timelineRef}
                                removeSelectedClip={removeSelectedClip}
                                clipStatus={clipStatus}
                                selectedEventId={selectedClipClicked ? selectedClipClicked.eventId : null}
                                selectedCamera={selectedCamera}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withApi(RecordedVideoPage, ApiHelper);
