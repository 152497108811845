import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles(() => ({
    tooltip: {
        width: '100%',
    },
    typographyWrapper: {
        maxWidth: '575px',
    },
}));

export default useStyles;
