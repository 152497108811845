import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends PureComponent {
    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate = (prevProps) => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    };

    render = () => {
        const { children } = this.props;

        return children ? children : null;
    };
}

export default withRouter(ScrollToTop);
