import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Grid, Typography } from '@brivo/react-components';
import { LoadingIndicator } from '@brivo/react-components';
import styles from './styles';

const LoadingExternalVideo = () => {
    const classes = styles();
    const { t } = useTranslation();
    return (
        <Paper className={classes.wrapper}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <LoadingIndicator thickness={5} />
                </Grid>
                <Grid item>
                    <Typography className={classes.title}>{t('Page.recorded-video.loading-video')}</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.subtitle}>{t('Page.recorded-video.video-from-provider')}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default LoadingExternalVideo;
