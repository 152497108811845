import React, { useContext } from 'react';
import { UserContext } from '../../../user/UserProvider';
import { get } from 'lodash';

const GeneralRequests = ({ user }) => {
    const userContext = useContext(UserContext);
    user = get(userContext, 'userInfo', {});

    return null;
};

export default React.memo(GeneralRequests);
