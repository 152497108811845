import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { checkPermissions, useFlagClient } from '@brivo/onairplus-services';

import { UserContext } from '../../user/UserProvider';
import { useAssignments } from '../../hooks/useAssignments';
import { useAccountFeatures } from '../../hooks/useAccountFeatures';

const AccessControl = ({
    anyOfPermissions,
    necessaryPermissions,
    requireAssignments = { allGroups: false, allSites: false, allLockdowns: false },
    requireFeatures = { lockdownEnabled: false },
    requiredFlag,
    redirectToPage,
    unauthorizedComponent: Unauthorized,
    children,
}) => {
    const flagClient = useFlagClient();
    const { isAssignedToAllGroups, isAssignedToAllSites, isAssignedToAllLockdowns } = useAssignments();
    const { isLockdownEnabled } = useAccountFeatures();
    const flagValue = requiredFlag ? flagClient.variation(requiredFlag, false) : true;

    const { permissions } = useContext(UserContext);

    if (!permissions) {
        return null;
    }

    let permitted =
        flagValue &&
        checkPermissions({
            userPermissions: permissions,
            necessaryPermissions,
            anyOfPermissions,
        });
    if (requireAssignments?.allGroups && !isAssignedToAllGroups) {
        permitted = false;
    }
    if (requireAssignments?.allSites && !isAssignedToAllSites) {
        permitted = false;
    }
    if (
        (requireAssignments?.allLockdowns && !isAssignedToAllLockdowns) ||
        (requireFeatures?.lockdownEnabled && !isLockdownEnabled)
    ) {
        permitted = false;
    }

    return permitted ? children : redirectToPage ? <Unauthorized /> : null;
};

AccessControl.propTypes = {
    redirectToPage: PropTypes.bool,
};

AccessControl.defaultProps = {
    necessaryPermissions: [],
    redirectToPage: false,
};

export default AccessControl;
