import { useEffect, useRef } from 'react';

const useOnUnmount = (callback, dependencies) => {
    const isUnmounting = useRef(false);

    useEffect(() => () => (isUnmounting.current = true), []);

    useEffect(
        () => () => {
            if (isUnmounting.current) {
                callback();
            }
        },
        [dependencies, callback]
    );
};

export default useOnUnmount;
