import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingIndicator } from '@brivo/react-components';
import { useFlagClient } from '@brivo/onairplus-services';

import LockdownScenarioDetails from './pageComponents/LockdownScenarioDetails';
import ScenarioDetails from './pageComponents/ScenarioDetails';
import LockdownNotFound from './LockdownNotFound';
import useGetLockdownData from '../hooks/useGetLockdownData';
import { lockdownApi } from '../../../common/webApis';
import { ALL_SCENARIOS_FILTER_ENABLED } from '../../../common/constants/Constants';

const LockdownScenarioPage = ({ scenarios, isLoading, reloadList, isCreateMode }) => {
    const [activeScenarios, setActiveScenarios] = useState(null);
    const { id } = useParams();
    const [scenario, assignedTriggers, assignedDoors, reloadScenario, loadingData, error] = useGetLockdownData({
        id: id,
        isCreateMode: isCreateMode,
    });

    const fetchActiveScenarios = useCallback(async () => {
        const queryParamsAllScenarios = {
            filter: ALL_SCENARIOS_FILTER_ENABLED,
        };

        const response = await lockdownApi.getAllActiveScenarios({ queryParams: queryParamsAllScenarios });

        setActiveScenarios(response?.data);
    }, []);

    useEffect(() => {
        fetchActiveScenarios();
    }, [fetchActiveScenarios]);

    const isActiveScenario = activeScenarios?.map((element) => element.id).includes(scenario?.id);

    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');

    const defaultScenario = scenarios?.filter((scenario) => scenario.isDefault)[0];

    const LoadedPage = error ? (
        <LockdownNotFound />
    ) : emergencyScenariosFlag ? (
        <ScenarioDetails
            scenario={scenario}
            isCreateMode={isCreateMode}
            isLoading={loadingData || !scenario}
            assignedTriggers={assignedTriggers}
            assignedDoors={assignedDoors}
            reloadScenario={reloadScenario}
            reloadList={reloadList}
            accountHasDefaultScenario={defaultScenario && defaultScenario.id !== scenario?.id}
            isActiveScenario={isActiveScenario}
        />
    ) : (
        <LockdownScenarioDetails
            scenario={scenario}
            isCreateMode={isCreateMode}
            isLoading={loadingData || !scenario}
            assignedTriggers={assignedTriggers}
            assignedDoors={assignedDoors}
            reloadScenario={reloadScenario}
            reloadList={reloadList}
            accountHasDefaultScenario={defaultScenario && defaultScenario.id !== scenario?.id}
            isActiveScenario={isActiveScenario}
        />
    );

    return isLoading ? <LoadingIndicator /> : LoadedPage;
};

export default LockdownScenarioPage;
