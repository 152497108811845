import gql from 'graphql-tag';
import { get, max, union } from 'lodash';
import { endOfHour } from 'date-fns';
import {
    userEvents,
    INCLUDED_SECURITY_ACTION_IDS,
    EXCLUDED_SECURITY_ACTION_IDS,
    INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS,
    ARGUMENTS_INCLUDED_SECURITY_ACTION_IDS,
    ARGUMENTS_EXCLUDED_SECURITY_ACTION_IDS,
    ARGUMENTS_INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS,
} from '../constants/Constants';
import { GET_EVENT_RANKING_CONFIG } from '../../graphql';

export default class EventsApi {
    gqlHelper = null;

    constructor(gqlHelper) {
        this.gqlHelper = gqlHelper;
    }

    setEventsRanking(config) {
        this.eventsRanking = config;

        this.allConfiguredEventTypeIds = Object.values(this.eventsRanking).reduce(
            (acc, evtTypeIds) => [...acc, ...evtTypeIds],
            []
        );
    }

    _buildSitesClause(queryVariables, sites) {
        const result = { sitesQueryArguments: '', sitesQueryFilter: '' };
        if (sites && sites.length !== 0) {
            const ids = sites?.map((site) => site.id);
            queryVariables.sites = ids;
            result.sitesQueryArguments = '$sites: [Int]';
            result.sitesQueryFilter = ', siteId: { in: $sites}';
        }
        return result;
    }

    _buildUserClause(queryVariables, user) {
        const result = { userQueryArguments: '', userQueryFilter: '' };
        if (user && user !== '') {
            queryVariables.actorName = user;
            result.userQueryArguments = '$actorName: String';
            result.userQueryFilter = ', actorName: $actorName';
        }
        return result;
    }

    _getNonInfoSelectedEvents(eventTypes) {
        let nonInfoEvents = [];
        eventTypes
            .map((type) => type.label.toLowerCase())
            .forEach((type) => (nonInfoEvents = nonInfoEvents.concat(this.eventsRanking[type])));
        return nonInfoEvents;
    }

    _containsInfoEventSeverity(severityTypes) {
        return severityTypes.some((type) => type.label.toUpperCase() === 'INFO');
    }

    _containsCriticalEventType(eventTypes) {
        return eventTypes.some((type) => type.label.toUpperCase() === 'CRITICAL');
    }

    _containsWarningEventType(eventTypes) {
        return eventTypes.some((type) => type.label.toUpperCase() === 'WARNING');
    }

    _buildEventTypesClauseWithSelectedUser(queryVariables, selectedEvents, eventSeverities) {
        const result = { eventTypesArguments: '', eventTypesFilter: '' };
        let securityActionIds = userEvents;
        if (selectedEvents && selectedEvents.length > 0) {
            securityActionIds = securityActionIds?.filter((id) => selectedEvents.includes(id));
        }
        if (eventSeverities && eventSeverities.length > 0 && eventSeverities.length < 3) {
            if (this._containsInfoEventSeverity(eventSeverities)) {
                if (!this._containsCriticalEventType(eventSeverities) && this.eventsRanking['critical']) {
                    securityActionIds = securityActionIds?.filter((id) => !this.eventsRanking['critical'].includes(id));
                }
                if (!this._containsWarningEventType(eventSeverities) && this.eventsRanking['warning']) {
                    securityActionIds = securityActionIds?.filter((id) => !this.eventsRanking['warning'].includes(id));
                }
            } else {
                let nonInfoEvents = this._getNonInfoSelectedEvents(eventSeverities);
                securityActionIds = securityActionIds?.filter((id) => nonInfoEvents.includes(id));
            }
        }

        queryVariables.securityActionIds = securityActionIds;
        result.eventTypesArguments = ARGUMENTS_INCLUDED_SECURITY_ACTION_IDS;
        result.eventTypesFilter = INCLUDED_SECURITY_ACTION_IDS;
        return result;
    }

    _buildDevicesClause(queryVariables, devices) {
        const result = { devicesQueryArguments: '', devicesQueryFilter: '' };
        if (devices && devices.length !== 0) {
            const ids = devices?.map((device) => device.id);
            queryVariables.devices = ids;
            result.devicesQueryArguments = '$devices: [Int]';
            result.devicesQueryFilter = ', objectId: { in: $devices}';
        }
        return result;
    }

    buildShowCameraEventsClause(queryVariables, showCameraEvents) {
        queryVariables.eventType = 'PANEL_EVENT';

        const result = {
            excludeEventsWithCameraArguments: '$eventType: EventType',
            excludeEventsWithCameraFilter: ', eventType: $eventType',
        };

        if (showCameraEvents) {
            result.excludeEventsWithCameraArguments = '';
            result.excludeEventsWithCameraFilter = '';
        }

        return result;
    }

    _buildExcludeEventsWithoutVideoClause(queryVariables, excludeEventsWithoutVideo) {
        const result = {
            excludeEventsWithoutVideoArguments: '',
            excludeEventsWithoutVideoFilter: '',
        };
        if (excludeEventsWithoutVideo) {
            queryVariables.eventGroup = 'VIDEO_EVENTS';
            result.excludeEventsWithoutVideoArguments = '$eventGroup: AccessEventGroup';
            result.excludeEventsWithoutVideoFilter = ', eventGroup: $eventGroup';
        }
        return result;
    }

    _buildEventTypesClauseWithSelectedEvents(queryVariables, selectedEvents) {
        const eventTypesClause = {
            eventTypesArguments: '',
            eventTypesFilter: '',
        };

        eventTypesClause.eventTypesArguments = ARGUMENTS_INCLUDED_SECURITY_ACTION_IDS;
        eventTypesClause.eventTypesFilter = INCLUDED_SECURITY_ACTION_IDS;

        if (selectedEvents && selectedEvents.length > 0) {
            queryVariables.securityActionIds = selectedEvents;
        } else {
            queryVariables.securityActionIds = this.allConfiguredEventTypeIds;
        }

        return eventTypesClause;
    }

    _buildEventTypesClauseWithSelectedSeverities(queryVariables, eventSeverities) {
        const eventTypesClause = {
            eventTypesArguments: '',
            eventTypesFilter: '',
        };

        const noSeveritiesSelected = eventSeverities.length === 0;
        const allSeveritiesSelected = eventSeverities.length === Object.keys(this.eventsRanking).length;

        if (!eventSeverities || noSeveritiesSelected || allSeveritiesSelected) {
            queryVariables.securityActionIds = this.allConfiguredEventTypeIds;

            eventTypesClause.eventTypesArguments = ARGUMENTS_INCLUDED_SECURITY_ACTION_IDS;
            eventTypesClause.eventTypesFilter = INCLUDED_SECURITY_ACTION_IDS;
            return eventTypesClause;
        }

        // When event types contains info we need to exclude the security action of other filters
        // because we need to treat unknown security action id events as info
        if (this._containsInfoEventSeverity(eventSeverities)) {
            let toExclude = [];
            const toExcludeEventTypes = Object.keys(this.eventsRanking)
                ?.map((eventRankingSeverity) => eventRankingSeverity?.toLowerCase())
                ?.filter(
                    (eventRankingSeverity) =>
                        !eventSeverities.some((severity) => severity.label?.toLowerCase() === eventRankingSeverity)
                );
            toExcludeEventTypes.forEach((type) => (toExclude = toExclude.concat(this.eventsRanking[type])));
            queryVariables.excludedSecurityActionIds = Array.from(toExclude);

            queryVariables.securityActionIds = this.allConfiguredEventTypeIds; // because we only want users to see SG1 events

            eventTypesClause.eventTypesArguments = ARGUMENTS_INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS;
            eventTypesClause.eventTypesFilter = INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS;
            return eventTypesClause;
        }

        let toInclude = this._getNonInfoSelectedEvents(eventSeverities);

        queryVariables.securityActionIds = Array.from(toInclude);
        eventTypesClause.eventTypesArguments = ARGUMENTS_INCLUDED_SECURITY_ACTION_IDS;
        eventTypesClause.eventTypesFilter = INCLUDED_SECURITY_ACTION_IDS;
        return eventTypesClause;
    }

    _buildExcludeHiddenEvents(eventTypesClause, queryVariables, hiddenEvents) {
        if (!hiddenEvents || hiddenEvents.length === 0) {
            return;
        }
        if (eventTypesClause.eventTypesFilter) {
            if (eventTypesClause.eventTypesFilter === INCLUDED_SECURITY_ACTION_IDS) {
                queryVariables.excludedSecurityActionIds = hiddenEvents;
                eventTypesClause.eventTypesArguments = ARGUMENTS_INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS;
                eventTypesClause.eventTypesFilter = INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS;
            } else if (
                eventTypesClause.eventTypesFilter === EXCLUDED_SECURITY_ACTION_IDS ||
                eventTypesClause.eventTypesFilter === INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS
            ) {
                queryVariables.excludedSecurityActionIds = union(
                    queryVariables.excludedSecurityActionIds,
                    hiddenEvents
                );
            } else {
                throw Error('The event types filter has an unexpected value.');
            }
        } else {
            // NOTE - If all works as planned, this will never execute, because we always have an "in" clause
            queryVariables.excludedSecurityActionIds = hiddenEvents;
            eventTypesClause.eventTypesArguments = ARGUMENTS_EXCLUDED_SECURITY_ACTION_IDS;
            eventTypesClause.eventTypesFilter = EXCLUDED_SECURITY_ACTION_IDS;
        }
    }

    _getTimeFrame(start, end, timeSeries) {
        if (timeSeries && timeSeries.length !== 0) {
            const maxTime = max(timeSeries);
            return { start: maxTime, end: endOfHour(maxTime) };
        }
        return { start, end };
    }

    async findAll(
        from,
        to,
        limit,
        timeSeries,
        sites,
        user,
        devices,
        showCameraEvents,
        excludeEventsWithoutVideo,
        selectedEventSeverities,
        selectedEvents,
        nextToken,
        hiddenEvents,
        signal
    ) {
        const { start, end } = this._getTimeFrame(from, to, timeSeries);
        let queryVariables = {
            from: start,
            to: end,
            limit,
            nextToken,
        };
        const { sitesQueryArguments, sitesQueryFilter } = this._buildSitesClause(queryVariables, sites);
        const { userQueryArguments, userQueryFilter } = this._buildUserClause(queryVariables, user);
        const { devicesQueryArguments, devicesQueryFilter } = this._buildDevicesClause(queryVariables, devices);
        const { excludeEventsWithCameraArguments, excludeEventsWithCameraFilter } = this.buildShowCameraEventsClause(
            queryVariables,
            showCameraEvents
        );
        const { excludeEventsWithoutVideoArguments, excludeEventsWithoutVideoFilter } =
            this._buildExcludeEventsWithoutVideoClause(queryVariables, excludeEventsWithoutVideo);

        let eventTypesClause;
        if (user) {
            eventTypesClause = this._buildEventTypesClauseWithSelectedUser(
                queryVariables,
                selectedEvents,
                selectedEventSeverities
            );
        } else {
            eventTypesClause =
                selectedEvents && selectedEvents.length > 0
                    ? this._buildEventTypesClauseWithSelectedEvents(queryVariables, selectedEvents)
                    : this._buildEventTypesClauseWithSelectedSeverities(queryVariables, selectedEventSeverities);
        }

        this._buildExcludeHiddenEvents(eventTypesClause, queryVariables, hiddenEvents);

        const { eventTypesArguments, eventTypesFilter } = eventTypesClause;

        const precachedClips = ` precachedClips {
            id
            occurred
            cameraName
            cameraId
            storedClipLocation {
                key
                bucket
                signedClipUrl
                signedPreviewImageUrl
            }
          }`;

        const anomalyData = `anomalyData {
                            id
                            occurred
                            anomalyScore
                            anomalyBreakdown {
                                personTime
                                personDoorSiteTime
                                personDoorSiteFirstTime
                                personDoorSiteIrregularUse
                            }
                        }`;

        const snapshots = ` snapshots {
            snapshotUrl
            snapshotPreviewUrl
            cameraId
            sourceEventId
            sourceEventSecurityActionId
            panelEventOccurred
        }`;

        const enrichmentMetadataQueryItems =
            precachedClips || anomalyData || snapshots
                ? `enrichmentMetadata {
                        ${anomalyData}
                        ${precachedClips}
                        ${snapshots}
                    }`
                : '';

        const query = `query listAccessEvents(
            $from: AWSDateTime
            $to: AWSDateTime
            $limit: Int = 100
            ${sitesQueryArguments}
            ${userQueryArguments}
            ${devicesQueryArguments}
            ${excludeEventsWithCameraArguments}
            ${eventTypesArguments}
            ${excludeEventsWithoutVideoArguments}
            $nextToken: String
        ) {
            listAccessEvents(
                filter: {
                    occurred: { between: [$from, $to] }
                    ${sitesQueryFilter}
                    ${userQueryFilter}
                    ${devicesQueryFilter}
                    ${excludeEventsWithCameraFilter}
                    ${eventTypesFilter}
                    ${excludeEventsWithoutVideoFilter}
                }
                nextToken: $nextToken
                limit: $limit
            ) {
                items {
                    actorId
                    actorName
                    credentialObjectId
                    eventType
                    id
                    objectId
                    objectName
                    occurred
                    securityActionId
                    detail
                    deviceType
                    siteName
                    siteId
                    userDefinedMessage
                    userTypeId
                    ${enrichmentMetadataQueryItems}
                }
                nextToken
            }
        }`;
        try {
            if (user && (!queryVariables.securityActionIds || queryVariables.securityActionIds.length === 0)) {
                return { events: [], nextToken: null };
            }
            const response = await this.gqlHelper.query({
                query: gql(query),
                variables: queryVariables,
                fetchPolicy: 'no-cache',
                context: {
                    fetchOptions: {
                        signal: signal, // abortController signal
                    },
                },
            });
            const events = get(response, 'data.listAccessEvents.items', []);

            const nextToken = get(response, 'data.listAccessEvents.nextToken', null);

            return { events, nextToken };
        } catch (e) {
            return this.gqlHelper.handleExceptions(e, { events: [], nextToken: null });
        }
    }

    async count(from, to, filters, hiddenEvents) {
        const queryVariables = { from, to };
        const { sites, user, devices, excludeEventsWithoutVideo, excludeEventsWithCamera, selectedEvents } = filters;

        const { sitesQueryArguments, sitesQueryFilter } = this._buildSitesClause(queryVariables, sites);

        const { excludeEventsWithoutVideoArguments, excludeEventsWithoutVideoFilter } =
            this._buildExcludeEventsWithoutVideoClause(queryVariables, excludeEventsWithoutVideo);

        const { excludeEventsWithCameraArguments, excludeEventsWithCameraFilter } = this.buildShowCameraEventsClause(
            queryVariables,
            excludeEventsWithCamera
        );

        const { userQueryArguments, userQueryFilter } = this._buildUserClause(queryVariables, user);
        let eventTypesClause;
        if (user) {
            eventTypesClause = this._buildEventTypesClauseWithSelectedUser(queryVariables, selectedEvents, []);
        } else {
            eventTypesClause = this._buildEventTypesClauseWithSelectedEvents(queryVariables, selectedEvents);
        }

        this._buildExcludeHiddenEvents(eventTypesClause, queryVariables, hiddenEvents);

        const { eventTypesArguments, eventTypesFilter } = eventTypesClause;
        const { devicesQueryArguments, devicesQueryFilter } = this._buildDevicesClause(queryVariables, devices);

        const query = `query accessEventCountByHours(
                $from: AWSDateTime,
                $to: AWSDateTime
                ${sitesQueryArguments}
                ${userQueryArguments}
                ${devicesQueryArguments}
                ${excludeEventsWithoutVideoArguments}
                ${excludeEventsWithCameraArguments}
                ${eventTypesArguments}
            ) {
            getAccessEventCountByHours(
                filter:
                    { occurred: { between: [$from, $to] }
                    ${sitesQueryFilter}
                    ${userQueryFilter}
                    ${devicesQueryFilter}
                    ${excludeEventsWithoutVideoFilter}
                    ${excludeEventsWithCameraFilter}
                    ${eventTypesFilter}
                }
            ) {
                total
                hours {
                    hour
                    count
                    actions {
                        securityActionId
                        count
                    }
                }
            }
        }`;

        const defaultResponse = {
            total: 0,
            hours: [],
        };
        try {
            if (user && (!queryVariables.securityActionIds || queryVariables.securityActionIds.length === 0)) {
                return defaultResponse;
            }
            const response = await this.gqlHelper.query({
                query: gql(query),
                variables: queryVariables,
                fetchPolicy: 'no-cache',
            });
            return get(response, 'data.getAccessEventCountByHours', defaultResponse);
        } catch (e) {
            return this.gqlHelper.handleExceptions(e, defaultResponse);
        }
    }

    async getEventRankingConfig() {
        const gqlResponse = await this.gqlHelper.query({
            query: GET_EVENT_RANKING_CONFIG,
            variables: {},
            fetchPolicy: 'no-cache',
        });
        let data = get(gqlResponse, 'data.getEventRankingConfig', {});
        let errors = get(gqlResponse, 'errors', null);
        if (errors || !data) {
            return this.gqlHelper.handleExceptions(errors);
        }
        return data;
    }
}
