import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';

import { useSnackbar } from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';

import ApiHelper from '../../../helpers/Helpers';
import { usePrevious } from '../../../hooks/usePrevious';
import { useTranslatedEventNames } from '../../../hooks/useTranslatedEventNames';
import SnackbarButtons from './components/SnackbarButtons';
import VideoModal from './components/VideoModal';
import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import audio from './components/doorbell.wav';
import useStyles from './styles';

const viewer = {};

const CallNotifications = ({ api, messages = [] }) => {
    const classes = useStyles();
    const { t, translateEventName } = useTranslatedEventNames();
    const localViewRef = useRef(null);
    const remoteViewRef = useRef(null);
    const prevMessages = usePrevious(messages);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [viewingVideo, setViewingVideo] = useState(false);
    const [modalMessage, setModalMessage] = useState({});
    const lockState = get(messages[0], 'data.lockState');
    const [accessPointToSnackbarKeys] = useState(new Map());

    const audioElement = useMemo(() => {
        return new Audio(audio);
    }, []);

    audioElement.loop = true;

    const handleCloseSnackbar = useCallback(
        (key) => {
            audioElement.pause();
            return closeSnackbar(key);
        },
        [audioElement, closeSnackbar]
    );

    const toggleAudioStream = useCallback(() => {
        viewer.localStream.getAudioTracks().forEach((track) => (track.enabled = !track.enabled));
    }, []);

    const answerCall = useCallback(
        async (snackbarId, message) => {
            closeSnackbar(snackbarId);
            audioElement.pause();
            const parsedDetails = JSON.parse(message.data.details);
            setModalMessage(message);
            const configs = {
                wss: parsedDetails.presignedUrl,
                ice: parsedDetails.iceServers,
            };
            startViewer({
                localView: localViewRef,
                remoteView: remoteViewRef,
                config: configs,
            });
            setViewingVideo(true);
            api.notifyBDSCallAnswered(parsedDetails.accessPointId);
        },
        [closeSnackbar, localViewRef, remoteViewRef, audioElement, api]
    );

    const unlockDoor = useCallback(
        async (snackbarId, message) => {
            audioElement.pause();
            try {
                const parsedDetails = JSON.parse(message.data.details);
                const results = await api.unlockDoor(parsedDetails.accessPointId);
                if (!results) {
                    return false;
                } else {
                    setTimeout(() => {
                        closeSnackbar(snackbarId);
                    }, 3000);
                    return true;
                }
            } catch (_) {
                return false;
            }
        },
        [closeSnackbar, api, audioElement]
    );

    const hangupCall = () => {
        stopViewer();
        setViewingVideo(false);
    };

    useEffect(() => {
        const sendNewMessage = (message) => {
            const parsedDetails = JSON.parse(message.data.details);
            const thumbnailSource = parsedDetails?.image ? parsedDetails.image : '';
            const imageThumbnailForSnackbar = thumbnailSource
                ? React.createElement(
                      'img',
                      {
                          src: `data:image/jpg;base64,${thumbnailSource}`,
                          style: { width: '180px', height: '120px' },
                          alt: 'Image From Door Station',
                      },
                      null
                  )
                : React.createElement('div', { src: '', style: { width: '10px', height: '120px' } }, null);
            const action = (snackbarId) => (
                <SnackbarButtons
                    snackbarId={snackbarId}
                    message={message}
                    onAnswerCall={answerCall}
                    onClickUnlock={unlockDoor}
                />
            );

            const snackbarKey = uuidv4();
            const isAccessPointNotifiedEarlier = accessPointToSnackbarKeys.has(parsedDetails.accessPointId);
            if (isAccessPointNotifiedEarlier) {
                accessPointToSnackbarKeys.get(parsedDetails.accessPointId).push(snackbarKey);
            } else {
                accessPointToSnackbarKeys.set(parsedDetails.accessPointId, [snackbarKey]);
            }

            enqueueSnackbar(imageThumbnailForSnackbar, {
                key: snackbarKey,
                action,
                autoHideDuration: 30000,
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                onClose: (key, reason) => {
                    if (reason === 'timeout') {
                        audioElement.pause();
                        enqueueSnackbar(t('notifications.call.snackbar.missed.title'), {
                            variant: 'error',
                            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                            autoHideDuration: 5000,
                            onClose: (key) => handleCloseSnackbar(key),
                        });
                    } else {
                        handleCloseSnackbar(key);
                    }
                },
                className: classes.newSnackbar,
            });
        };
        if (messages?.length && prevMessages !== messages) {
            //could be calls from different BDS units
            messages.forEach((message) => {
                if (message?.notificationType === 'RING') {
                    if (!document.hidden) {
                        audioElement.currentTime = 0;
                        audioElement.play();
                    } else {
                        if (!('Notification' in window)) {
                            console.log('This browser does not support desktop notification');
                            return;
                        }

                        // Check whether notification permissions have already been granted;
                        if (Notification.permission === 'granted') {
                            // Security.action-message.shortName.7900=Door Station Call Was Initiated
                            let translatedEventTitle = translateEventName(7900);
                            new Notification(translatedEventTitle, { icon: '/brivo.png' });
                        }
                    }
                    sendNewMessage(message);
                } else if (message?.notificationType === 'CALL_ANSWERED') {
                    const details = JSON.parse(message?.data?.details);
                    const isAccessPointNotifiedEarlier = accessPointToSnackbarKeys.has(details?.accessPointId);
                    if (isAccessPointNotifiedEarlier) {
                        let snackBarsByAccessPointId = accessPointToSnackbarKeys.get(details.accessPointId);
                        for (let key of snackBarsByAccessPointId) {
                            closeSnackbar(key);
                            accessPointToSnackbarKeys.set(
                                details.accessPointId,
                                snackBarsByAccessPointId.filter((item) => item !== key)
                            );
                        }
                    }
                }
            });
        }
    }, [
        messages,
        prevMessages,
        closeSnackbar,
        enqueueSnackbar,
        answerCall,
        t,
        unlockDoor,
        audioElement,
        handleCloseSnackbar,
        translateEventName,
        accessPointToSnackbarKeys,
        classes,
    ]);

    return (
        viewingVideo && (
            <VideoModal
                isOpen={viewingVideo}
                startVideo={answerCall}
                quitVideo={hangupCall}
                remoteViewRef={remoteViewRef}
                localViewRef={localViewRef}
                message={modalMessage}
                onClickUnlock={unlockDoor}
                onToggleAudio={toggleAudioStream}
                lockState={lockState}
            />
        )
    );
};

export const startViewer = async ({ localView, remoteView, config }) => {
    try {
        viewer.localView = localView.current;
        viewer.remoteView = remoteView.current;
        viewer.signalingClient = new SignalingClient({
            requestSigner: {
                getSignedURL: () => Promise.resolve(config.wss),
            },
            channelARN: 'signedUrlResolves',
            channelEndpoint: 'signedUrlResolves',
            role: 'viewer',
            region: 'signedUrlResolves',
        });

        const constraints = {
            video: false,
            audio: true,
        };
        const configuration = {
            iceServers: config.ice,
            iceTransportPolicy: 'all',
        };
        viewer.peerConnection = new RTCPeerConnection(configuration);

        viewer.signalingClient.on('open', () => {
            (async () => {
                try {
                    viewer.localStream = await navigator.mediaDevices.getUserMedia(constraints);
                    viewer.localStream
                        .getTracks()
                        .forEach((track) => viewer.peerConnection.addTrack(track, viewer.localStream));
                    localView.current.srcObject = viewer.localStream;
                } catch (e) {
                    console.error(
                        `[VIEWER] Could not find ${Object.keys(constraints).filter(
                            (k) => constraints[k]
                        )} input device.`,
                        e
                    );
                    return;
                }

                await viewer.peerConnection.setLocalDescription(
                    await viewer.peerConnection.createOffer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    })
                );
                viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
            })();
        });

        viewer.signalingClient.on('sdpAnswer', (answer) => {
            (async () => {
                // Add the SDP answer to the peer connection
                await viewer.peerConnection.setRemoteDescription(answer);
            })();
        });

        viewer.signalingClient.on('iceCandidate', (candidate) => {
            (async () => {
                // Add the ICE candidate received from the MASTER to the peer connection
                viewer.peerConnection.addIceCandidate(candidate);
            })();
        });

        viewer.signalingClient.on('close', () => {
            stopViewer();
        });

        viewer.signalingClient.on('error', (error) => {
            console.error('[VIEWER] Signaling client error:', error);
        });

        // Send any ICE candidates to the other peer
        viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            if (candidate) {
                viewer.signalingClient.sendIceCandidate(candidate);
            }
        });

        // As remote tracks are received, add them to the remote view
        viewer.peerConnection.addEventListener('track', (event) => {
            if (remoteView.current?.srcObject) {
                return;
            }
            viewer.remoteStream = event.streams[0];
            remoteView.current.srcObject = viewer.remoteStream;
        });
        viewer.signalingClient.open();
    } catch (e) {
        console.error('[VIEWER] Encountered error starting:', e);
    }
};

export const stopViewer = () => {
    try {
        if (viewer.signalingClient) {
            viewer.signalingClient.close();
            viewer.signalingClient = null;
        }

        if (viewer.peerConnection) {
            viewer.peerConnection.close();
            viewer.peerConnection = null;
        }

        if (viewer.localStream) {
            viewer.localStream.getTracks().forEach((track) => track.stop());
            viewer.localStream = null;
        }

        if (viewer.remoteStream) {
            viewer.remoteStream.getTracks().forEach((track) => track.stop());
            viewer.remoteStream = null;
        }

        if (viewer.peerConnectionStatsInterval) {
            clearInterval(viewer.peerConnectionStatsInterval);
            viewer.peerConnectionStatsInterval = null;
        }

        if (viewer.localView) {
            viewer.localView.current.srcObject = null;
        }

        if (viewer.remoteView) {
            viewer.remoteView.current.srcObject = null;
        }

        if (viewer.dataChannel) {
            viewer.dataChannel = null;
        }
    } catch (e) {
        console.error('[VIEWER] Encountered error stopping', e);
    }
};
export default withApi(CallNotifications, ApiHelper);
