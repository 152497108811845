import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    newNavText: {
        fontWeight: 600,
        paddingLeft: 24,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.brivoAliases.newNavTextColor,
    },

    expansionPanel: {
        borderTop: 'none',

        padding: 0,

        '& svg': {
            color: theme.palette.brivoAliases.newNavIconColor,
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '2.75rem',
            paddingRight: 14,
            '&:hover': {
                backgroundColor: theme.palette.brivoAliases.newNavBackgroundHoverColor,
                '& svg,p': {
                    color: theme.palette.brivoAliases.newNavIconHoverColor,
                },
            },
        },
        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
    },
}));

export default styles;
