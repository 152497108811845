import * as React from 'react';
import { createRef, useEffect, useState, useMemo } from 'react';
import { Marker, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import clsx from 'clsx';
import ReactDOMServer from 'react-dom/server';
import { isEqual } from 'lodash';

import { CAMERA, DOOR, PANEL } from '@common/constants/Constants';
import { displayDeviceCountBySeverity } from '@pages/AccountSettings/Sites/SiteSettings/IndividualSiteSetting/FloorPlans/FloorPlansUtils';

import {
    CameraMarkerDark,
    CameraMarkerLight,
    CameraDirection,
    RotateIcon,
    DoorMarkerDark,
    DoorMarkerLight,
    PanelMarkerDark,
    PanelMarkerLight,
} from '../assets/icons';
import { eventSeverity, themeType } from '../constants';
import { useStyles } from '../styles';

const MapMarker = ({
    draggable,
    onPlacementUpdate,
    isViewMode = false,
    darkTheme,
    filteredEventClass,
    setParentOpenDevice,
    canViewDeviceEvents,
    isActive,
    ...deviceDetails
}) => {
    const { deviceTop, deviceLeft, deviceRotation, deviceName, deviceType, deviceSeverity, deviceId } = deviceDetails;

    const deviceTypeToLowerCase = deviceType?.toLowerCase();
    const [position, setPosition] = useState(null);
    const context = useLeafletContext();
    const [zoomValue, setZoomValue] = useState(context?.map?._zoom || 0);
    const defaultRotation = 0;
    const isCamera = deviceTypeToLowerCase === CAMERA.toLowerCase();
    const initialRotation = isCamera ? deviceRotation ?? defaultRotation : null;
    const [rotation, setRotation] = useState(initialRotation);
    const marker = createRef();
    const map = useMap();
    const classes = useStyles();

    const mapCenter = map?.getCenter();

    const getEventCountStyleBySeverity = (displayedDeviceCount, selectedSeverity) => {
        const noEventsState = displayedDeviceCount === 0 ? ' noEvents' : '';
        switch (selectedSeverity?.id) {
            case eventSeverity.INFO.id:
                return eventSeverity.INFO.label + noEventsState;
            case eventSeverity.WARNING.id:
                return eventSeverity.WARNING.label + noEventsState;
            case eventSeverity.CRITICAL.id:
                return eventSeverity.CRITICAL.label + noEventsState;
            default:
                return darkTheme ? themeType.DARK + noEventsState : themeType.LIGHT + noEventsState;
        }
    };

    const zoomFactor = Math.pow(2, zoomValue);

    const eventsCounterIconWidth = zoomFactor * 38 + 'px';
    const eventsCounterIconHeight = zoomFactor * 38 + 'px';
    const eventsCounterIconFontSize = zoomFactor * 14 + 'px';
    const eventsCounterIconMarginTop = -zoomFactor * 10 + 'px';
    const eventsCounterIconMarginLeft = -zoomFactor * 10 + 'px';

    const cameraDirectionSize = { width: zoomFactor * 94 + 'px', height: zoomFactor * 87 + 'px' };
    const cameraTranslateX = 25 * zoomFactor;
    const cameraTranslateY = -63 * zoomFactor;

    useEffect(() => {
        if ((!deviceTop || !deviceLeft) && onPlacementUpdate) {
            //center newly added marker
            onPlacementUpdate(deviceId, {
                top: mapCenter?.lng,
                left: mapCenter?.lat,
                rotation: isCamera ? 0 : null,
            });
            setPosition(mapCenter);
        }
    }, [deviceId, deviceLeft, deviceTop, isCamera, mapCenter, onPlacementUpdate]);

    useEffect(() => {
        setRotation(deviceRotation ?? defaultRotation);
    }, [deviceRotation]);

    const mapEvents = useMapEvents({
        zoomend: () => {
            const currentZoom = mapEvents.getZoom();
            setZoomValue(currentZoom);
        },
    });

    const getDarkIcon = (markerSize) => {
        switch (deviceTypeToLowerCase) {
            case DOOR.toLowerCase():
                return (
                    <DoorMarkerDark
                        circleColor={classes.doorCircleColor}
                        pathColor={classes.doorPathColor}
                        isActive={isActive}
                        {...markerSize}
                    />
                );
            case CAMERA.toLowerCase():
                return (
                    <CameraMarkerDark
                        circleColor={classes.cameraCircleColor}
                        pathColor={classes.cameraPathColor}
                        isActive={isActive}
                        {...markerSize}
                    />
                );
            case PANEL.toLowerCase():
                return (
                    <PanelMarkerDark
                        circleColor={classes.panelCircleColor}
                        rectColor={classes.panelRectColor}
                        pathColor={classes.panelPathColor}
                        isActive={isActive}
                        {...markerSize}
                    />
                );
            default:
                return;
        }
    };

    const getLightIcon = (markerSize) => {
        switch (deviceTypeToLowerCase) {
            case DOOR.toLowerCase():
                return <DoorMarkerLight isActive={isActive} {...markerSize} />;
            case CAMERA.toLowerCase():
                return <CameraMarkerLight isActive={isActive} {...markerSize} />;
            case PANEL.toLowerCase():
                return <PanelMarkerLight isActive={isActive} {...markerSize} />;
            default:
                return;
        }
    };

    const severitySymbol = useMemo(() => {
        const severitySymbolWidth = zoomFactor * 10 + 'px';
        const severitySymbolHeight = zoomFactor * 10 + 'px';
        const severitySymbolMarginTop = -zoomFactor * 10 + 'px';
        const severitySymbolMarginLeft = -zoomFactor * 10 + 'px';

        switch (deviceSeverity) {
            case eventSeverity.CRITICAL.label:
                return (
                    <div
                        className={classes.criticalSymbol}
                        style={{
                            width: severitySymbolWidth,
                            height: severitySymbolHeight,
                            marginTop: severitySymbolMarginTop,
                            marginLeft: severitySymbolMarginLeft,
                        }}
                    />
                );
            case eventSeverity.WARNING.label:
                return (
                    <div
                        className={classes.warningSymbol}
                        style={{
                            width: severitySymbolWidth,
                            height: severitySymbolHeight,
                            marginTop: severitySymbolMarginTop,
                            marginLeft: severitySymbolMarginLeft,
                        }}
                    />
                );
            default:
                return null;
        }
    }, [classes.criticalSymbol, classes.warningSymbol, deviceSeverity, zoomFactor]);

    const eventHandlers = {
        dragend(evt) {
            const left = evt.target._latlng.lat;
            const top = evt.target._latlng.lng;
            setPosition(evt.target._latlng);
            onPlacementUpdate(deviceId, { top, left });
        },
        click(evt) {
            if (
                !isViewMode &&
                isCamera &&
                (evt?.originalEvent?.target?.className?.baseVal === 'rotate-icon' ||
                    evt?.originalEvent?.target?.className?.baseVal === 'rotate-path')
            ) {
                if (rotation === 330) {
                    setRotation(0);
                    onPlacementUpdate(deviceId, { rotation: 0 });
                } else {
                    const newRotation = rotation + 30;
                    setRotation(newRotation);
                    onPlacementUpdate(deviceId, { rotation: newRotation });
                }
            }
            if (isViewMode && canViewDeviceEvents) {
                const {
                    latlng: { lat, lng },
                } = evt;
                const currentZoom = mapEvents.getZoom();
                const horizontalDeviation = 230 / (currentZoom + 1);
                setParentOpenDevice(deviceId, displayedDeviceCount);
                map.setView([lat, lng + horizontalDeviation]);
            }
        },
    };

    const displayedDeviceCount = displayDeviceCountBySeverity(deviceDetails, filteredEventClass);

    if (position || (deviceTop && deviceLeft)) {
        const zoomFactor = Math.pow(2, zoomValue);
        const markerSize = { width: zoomFactor * 50 + 'px', height: zoomFactor * 50 + 'px' };

        let icon = getDarkIcon(markerSize);
        if (darkTheme) {
            icon = getLightIcon(markerSize);
        }

        const hasBottomTooltip = isCamera && (rotation === 0 || rotation >= 240);
        const showSeveritySymbol = !filteredEventClass?.id && displayedDeviceCount && deviceSeverity;

        return (
            <Marker
                ref={marker}
                icon={L.divIcon({
                    className: 'customIcon',
                    iconSize: null, //needed to properly display events counter icon
                    tooltipAnchor: [25 * (zoomValue + 1), hasBottomTooltip ? 50 * (zoomValue + 1) : 0],
                    html: ReactDOMServer.renderToString(
                        <>
                            {isCamera && (
                                <div
                                    className={classes.cameraDirectionWrapper}
                                    style={{
                                        transform: `translate(${cameraTranslateX}px, ${cameraTranslateY}px) rotate(${rotation}deg)`,
                                    }}
                                >
                                    <CameraDirection {...cameraDirectionSize} />
                                    {!isViewMode && (
                                        <div className={classes.rotationButton} style={{ top: '18px', right: '0px' }}>
                                            <RotateIcon />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={classes.deviceMarkerWrapper}>
                                {icon}
                                {isViewMode && canViewDeviceEvents && displayedDeviceCount > 0 ? (
                                    <div
                                        className={clsx(
                                            classes.eventsCount,
                                            'count',
                                            getEventCountStyleBySeverity(displayedDeviceCount, filteredEventClass)
                                        )}
                                        style={{
                                            width: eventsCounterIconWidth,
                                            height: eventsCounterIconHeight,
                                            fontSize: eventsCounterIconFontSize,
                                            marginTop: eventsCounterIconMarginTop,
                                            marginLeft: eventsCounterIconMarginLeft,
                                        }}
                                    >
                                        {typeof displayedDeviceCount === 'number' && displayedDeviceCount > 1000 ? (
                                            <span>
                                                1K<sup>+</sup>
                                            </span>
                                        ) : (
                                            displayedDeviceCount
                                        )}
                                    </div>
                                ) : null}
                                {showSeveritySymbol ? severitySymbol : null}
                            </div>
                        </>
                    ),
                })}
                eventHandlers={eventHandlers}
                position={position || { lng: deviceTop, lat: deviceLeft }}
                draggable={draggable}
            >
                <Tooltip
                    direction={hasBottomTooltip ? 'bottom' : 'top'}
                    key={`tooltip_${deviceTop}_${deviceLeft}_${hasBottomTooltip ? 'bottom' : 'top'}`} //needed to force rerender, because leaflet Tooltip props are treated as immutable
                >
                    <p>{deviceName}</p>
                </Tooltip>
            </Marker>
        );
    }
    return null;
};

function areEqual(prevProps, nextProps) {
    return isEqual(prevProps, nextProps);
}
export default React.memo(MapMarker, areEqual);
