import { ITheme, makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme: ITheme) => ({
    helpUsText: {
        paddingTop: '0.5rem',
        paddingBottom: '1.5rem',
    },
    spacer: {
        padding: '1rem',
    },
    noMargin: {
        marginTop: '0 !important',
    },
    timezoneDropdown: {
        '& input': {
            scrollMarginTop: (props) => `${props.scrollMarginTop}px`,
        },
        '& .MuiInputBase-root': {
            padding: '0 9px',
        },
    },
}));

export default useStyles;
