import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles(() => ({
    icon: {
        width: '48px',
        height: '48px',
        marginRight: '16px',
    },
    text: {
        fontWeight: 600,
        fontSize: '16px',
        textAlign: 'left',
    },
    buttonsContainer: {
        marginLeft: 'auto',
        display: 'flex',
        gap: '1rem',
    },
}));

export default useStyles;
