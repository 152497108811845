import { useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
    GenericDialog,
    TextFilter,
    Button,
    MultiSelectFilter,
    LoadingIndicator,
    CloseIcon,
    IconButton,
    EmptyStateSection,
    Grid,
    NoResultsListIcon,
} from '@brivo/react-components';
import { useFlagClient } from '@brivo/onairplus-services';

import TableLegend from '@common/components/TableLegend/TableLegend';
import useGenericFilterGroup from '@common/hooks/useGenericFilterGroup';

import { useStyles } from './styles';
import ItemsList from './components/ItemsList';
import AssignAllBtn from './components/AssignAllBtn';
import { TYPE_ASSIGN, TYPE_UNASSIGN, DEBOUNCE_TIMEOUT_MS, MAX_AMOUNT_OF_DOORS_AND_FLOORS } from './constants';

import { initialFilters } from '../../helpers';

const MultiSelectDialog = ({
    isOpen,
    closePopup,
    title,
    avatarIcon,
    defaultValues,
    headers,
    saveSelection,
    singleSelectButtonText,
    tableLegendLabel,
    emptySectionTitle,
    useGetAllItems,
    property,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedItems, setSelectedItems] = useState(defaultValues);
    const [changesMade, setChangesMade] = useState(false);
    const [showAssignLoading, setShowAssignLoading] = useState(false);
    const [showUnassignLoading, setShowUnassignLoading] = useState(false);

    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');

    const [allLoadedItems, allSites, isLoading, count, totalCount, filters, handleFiltersChange, loadNextPage] =
        useGetAllItems();

    const maximumLimitReached = selectedItems?.length >= MAX_AMOUNT_OF_DOORS_AND_FLOORS;
    const unassignDisabled = selectedItems?.length === 0;

    const getNumberOfAssignableDoors = () => {
        if (!selectedItems.length) {
            return MAX_AMOUNT_OF_DOORS_AND_FLOORS;
        }

        return MAX_AMOUNT_OF_DOORS_AND_FLOORS - selectedItems.length;
    };

    useEffect(() => {
        if (!isLoading && allLoadedItems?.length) {
            // faster than deep comparing two arrays of objects
            const selectedItemsIds = selectedItems
                .map((item) => item?.id)
                .sort()
                .join(',');
            const defaultValuesIds = defaultValues
                .map((item) => item?.id)
                .sort()
                .join(',');
            const hasSelectedChanged = selectedItemsIds !== defaultValuesIds;
            setChangesMade(hasSelectedChanged);
        }
    }, [selectedItems, defaultValues, isLoading, allLoadedItems.length]);

    const handleFilterItems = (value) => {
        // show selected items on top
        let filteredSelectedItems = selectedItems.filter((item) =>
            item.name.toLowerCase().includes(value.name.toLowerCase())
        );
        const sitesFilter = value.siteIds.map(({ id }) => id);
        if (sitesFilter?.length) {
            filteredSelectedItems = filteredSelectedItems.filter((item) => sitesFilter.includes(item.siteId));
        }

        handleFiltersChange(value, filteredSelectedItems);
    };

    const { filterValues, filterChangeMethods, clearingValues, setClearingValues } = useGenericFilterGroup(
        initialFilters,
        handleFilterItems
    );

    const handleSaveSelection = () => {
        closePopup();
        saveSelection(selectedItems);
    };

    const selectItem = (item) => {
        if (!maximumLimitReached) {
            const newItems = [...selectedItems, item];
            setSelectedItems(newItems);
        }
    };

    const deselectItem = (item) => {
        const newItems = selectedItems.filter((sel) => sel.id !== item.id);
        setSelectedItems(newItems);
    };

    const assignAll = () => {
        setShowAssignLoading((loading) => !loading);
        const newItems = [];
        const selectedItemsIds = selectedItems?.map((selectedItem) => selectedItem?.id);
        const currentSelectedCount = selectedItems?.length;
        let newItemsCount = 0;
        allLoadedItems.forEach((item) => {
            if (
                !selectedItemsIds?.includes(item?.id) &&
                currentSelectedCount + newItemsCount < MAX_AMOUNT_OF_DOORS_AND_FLOORS
            ) {
                newItems.push(item);
                newItemsCount += 1;
            }
        });
        setSelectedItems([...selectedItems, ...newItems]);
        setShowAssignLoading((loading) => !loading);
    };

    const unassignAll = () => {
        setShowUnassignLoading((loading) => !loading);
        const allLoadedItemsIds = allLoadedItems?.map((loadedItem) => loadedItem?.id);
        let newItems = [];
        selectedItems.forEach((item) => {
            if (!allLoadedItemsIds?.includes(item?.id)) {
                newItems.push(item);
            }
        });
        setSelectedItems(newItems);
        setShowUnassignLoading((loading) => !loading);
    };

    return (
        <GenericDialog
            open={isOpen}
            title={title}
            avatarIcon={avatarIcon}
            className={classes.dialogWrapper}
            actions={
                <div className={classes.closeDialog}>
                    <IconButton
                        onClick={closePopup}
                        id={`close-${title}-dialog`}
                        icon={<CloseIcon className={classes.menuIcon} />}
                    />
                </div>
            }
            fullWidth={true}
            maxWidth={'md'}
        >
            <div className={classes.dialogContent}>
                <Grid container spacing={2} className="u-mt-full">
                    <Grid item xs={6}>
                        <div>
                            <TextFilter
                                placeholder={t(
                                    'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.search-by-name'
                                )}
                                onValueChange={
                                    filterChangeMethods.name && debounce(filterChangeMethods.name, DEBOUNCE_TIMEOUT_MS)
                                }
                                value={filterValues.name}
                                clearingValues={clearingValues}
                                setClearingValues={setClearingValues}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <div className={classes.sitesSelector}>
                            <MultiSelectFilter
                                id="sites"
                                label={t('Page.live-video.dialog.filters.sites.label')}
                                searchPlaceholder={t(
                                    'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.search-sites'
                                )}
                                noSelectionLabel={t(
                                    'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.select-sites'
                                )}
                                fullWidth={true}
                                items={allSites}
                                mapper={(item) => ({ id: item.id, name: item.name })}
                                disabled={isLoading}
                                noResultMessage={t('Component.multi-select-filter.no-options')}
                                value={filterValues.siteIds}
                                onValueChange={
                                    filterChangeMethods.siteIds &&
                                    debounce(filterChangeMethods.siteIds, DEBOUNCE_TIMEOUT_MS)
                                }
                                showMoreText={t('Page.event-tracker-filter.show-more')}
                            />
                        </div>
                    </Grid>
                </Grid>

                {!allLoadedItems?.length && !isLoading ? (
                    <NoResultsSections totalCount={totalCount} emptySectionTitle={emptySectionTitle} t={t} />
                ) : (
                    <ItemsList
                        allItems={allLoadedItems}
                        allItemsCount={count}
                        selectItem={selectItem}
                        deselectItem={deselectItem}
                        isLoading={isLoading}
                        currentSelectedItems={selectedItems}
                        headers={headers}
                        loadNextPage={loadNextPage}
                        singleSelectButtonText={singleSelectButtonText}
                        maximumLimitReached={maximumLimitReached}
                    />
                )}
                {!isLoading && maximumLimitReached && (
                    <Grid item>
                        <p className={classes.actionError}>
                            {t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.max-number-cards-assigned'
                            )}
                        </p>
                    </Grid>
                )}
                {!isLoading && (
                    <div className={classes.tableLegendWrapper}>
                        <TableLegend
                            x={count}
                            y={totalCount}
                            label={tableLegendLabel}
                            filtered={(filters && filters.name) || (filters && !isEmpty(filters.siteIds))}
                        />
                    </div>
                )}
                {isLoading && (
                    <div className={classes.loadingWrapper}>
                        <div className={classes.loading}>
                            <LoadingIndicator show={true} shouldHavePadding={false} />
                        </div>
                    </div>
                )}
                <>
                    {emergencyScenariosFlag && property === 'lockdownDoors' && !isLoading && (
                        <AssignAllBtn
                            type={TYPE_ASSIGN}
                            assignAll={assignAll}
                            count={Math.min(allLoadedItems?.length, getNumberOfAssignableDoors())}
                            disabled={maximumLimitReached}
                            isLoading={showAssignLoading}
                        />
                    )}
                    {emergencyScenariosFlag && property === 'lockdownDoors' && !isLoading && (
                        <AssignAllBtn
                            type={TYPE_UNASSIGN}
                            unassignAll={unassignAll}
                            disabled={unassignDisabled}
                            isLoading={showUnassignLoading}
                        />
                    )}
                    <Button
                        type="primary"
                        id="save"
                        onClick={handleSaveSelection}
                        disabled={!changesMade}
                        text={t('Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.button-primary')}
                        customWidth="100%"
                        size="small"
                    />
                </>
            </div>
        </GenericDialog>
    );
};
const NoResultsSections = ({ totalCount, emptySectionTitle, t }) => {
    return totalCount > 0 ? (
        <EmptyStateSection
            icon={<NoResultsListIcon style={{ height: 100, width: 100 }} />}
            title={t('Table.no-results-found')}
            subtitle={t('Table.no-results-found.explanation')}
        />
    ) : (
        <EmptyStateSection title={emptySectionTitle} />
    );
};

export default MultiSelectDialog;
