import { Suspense, lazy, useContext, useEffect, useState, useCallback } from 'react';
import { get } from 'lodash';
import { withTheme } from '@brivo/react-components';

import { withApi } from '@brivo/onairplus-services';

import ApiHelper from '../../common/helpers/Helpers';
import EventsApi from '../../common/helpers/EventsApi';
import { UserContext } from '../../common/user/UserProvider';
import {
    DEFAULT_FILTERS,
    PANEL_EVENT,
    PAGE_SCOPE,
    ENRICHMENT_EVENT_TYPE,
    CAMERA_EVENT,
} from '../../common/constants/Constants';
import { useWebSocketMessage } from '../../common/hooks/useSocket';
import { getFiltersFromLocalStorage } from '../../common/utils/Utils';
import { GQLProviderContext } from '../../common/utils/CustomApolloProvider';

const EventTrackerPage = lazy(() => import('./EventTrackerPage'));

const WrappedComponent = ({ api, ...rest }) => {
    const gqlHelper = useContext(GQLProviderContext);
    const [webSocketEventTypes, setWebSocketEventTypes] = useState([PANEL_EVENT, ENRICHMENT_EVENT_TYPE]);
    const { message, send } = useWebSocketMessage({
        scope: PAGE_SCOPE,
        eventTypes: webSocketEventTypes,
    });
    const [initialFilters, setInitialFilters] = useState(null);
    const userContext = useContext(UserContext);
    const user = get(userContext, 'userInfo', {});

    const getFiltersFromLocalStorageCallback = useCallback(() => {
        return getFiltersFromLocalStorage('eventTracker', user.id, user.accountId) ?? DEFAULT_FILTERS;
    }, [user]);

    const handleReceiveCameraEvents = (showCameraEvents) => {
        setWebSocketEventTypes(
            showCameraEvents ? [PANEL_EVENT, ENRICHMENT_EVENT_TYPE, CAMERA_EVENT] : [PANEL_EVENT, ENRICHMENT_EVENT_TYPE]
        );
    };

    useEffect(() => {
        const filtersFromLocalStorage = getFiltersFromLocalStorageCallback();
        setInitialFilters(filtersFromLocalStorage);
        if (filtersFromLocalStorage.showCameraEvents) {
            setWebSocketEventTypes([PANEL_EVENT, ENRICHMENT_EVENT_TYPE, CAMERA_EVENT]);
        }
    }, [getFiltersFromLocalStorageCallback]);

    const eventsApi = new EventsApi(gqlHelper);

    if (!initialFilters) {
        return null;
    }

    return (
        <Suspense fallback={null}>
            <EventTrackerPage
                {...rest}
                api={api}
                gqlHelper={gqlHelper}
                message={message}
                eventsApi={eventsApi}
                user={user}
                initialFilters={initialFilters}
                handleReceiveCameraEvents={handleReceiveCameraEvents}
                send={send}
            />
        </Suspense>
    );
};

export default withTheme(withApi(WrappedComponent, ApiHelper));
