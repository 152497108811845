import gql from 'graphql-tag';

export const GET_LOCKDOWN_TRIGGERS = gql`
    query getLockdownTriggers(
        $pageSize: Int
        $offset: Int
        $sortField: String
        $direction: String
        $filters: [ListFilter]
    ) {
        getLockdownTriggers(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            totalCount
            lockdownTriggers {
                controlPanelName
                controlPanelId
                id
                name
                siteId
                siteName
            }
        }
    }
`;

export const GET_LOCKDOWN_DOORS = gql`
    query getLockdownDoors(
        $pageSize: Int
        $offset: Int
        $sortField: String
        $direction: String
        $filters: [ListFilter]
    ) {
        getLockdownDoors(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            totalCount
            lockdownDoors {
                controlPanelName
                controlPanelId
                id
                name
                siteId
                siteName
            }
        }
    }
`;

export const GET_LOCKDOWN_TRIGGERS_BY_ID = gql`
    query getTriggersByLockdownId($lockdownOid: Int!) {
        getTriggersByLockdownId(lockdownOid: $lockdownOid) {
            triggerSourceName
            triggerSourceOid
            triggerSourcePanelName
            triggerSourcePanelId
            triggerSourceSiteName
            triggerSourceSiteId
        }
    }
`;

export const UPDATE_LOCKDOWN_SCENARIO_WITH_TRIGGERS = gql`
    mutation updateLockdownScenarioWithTriggers(
        $lockdownOid: Int!
        $triggerSourceOids: [Int]
        $doorOids: [Int]
        $isDefaultLockdown: Boolean
        $lockdownName: String
    ) {
        updateLockdownScenarioWithTriggers(
            lockdownOid: $lockdownOid
            triggerSourceOids: $triggerSourceOids
            doorOids: $doorOids
            isDefaultLockdown: $isDefaultLockdown
            lockdownName: $lockdownName
        )
    }
`;

export const CREATE_LOCKDOWN_SCENARIO_WITH_TRIGGERS = gql`
    mutation createLockdownScenarioWithTriggers(
        $triggerSourceOids: [Int]
        $doorOids: [Int]!
        $isDefaultLockdown: Boolean!
        $lockdownName: String!
        $threatLevelTypeId: Int
    ) {
        createLockdownScenarioWithTriggers(
            triggerSourceOids: $triggerSourceOids
            doorOids: $doorOids
            isDefaultLockdown: $isDefaultLockdown
            lockdownName: $lockdownName
            threatLevelTypeId: $threatLevelTypeId
        ) {
            updated
            name
            issued
            isDefault
            id
            excludeGroupIds
            deviceIds
            created
        }
    }
`;

export const GET_ACCOUNT_SERVICE_STATUS = gql`
    query getAccountServiceStatus {
        getAccountServiceStatus
    }
`;

export const IS_LESS_THAN_TOTAL_DOORS = gql`
    query isLessThanTotalDoors($count: Int!) {
        isLessThanTotalDoors(count: $count)
    }
`;

export const LIST_UNSUCCESSFUL_DEVICES = gql`
    query listUnsuccessfulDevices($scenarioOids: [Int!]) {
        listUnsuccessfulDevices(scenarioOids: $scenarioOids) {
            siteName
            siteId
            objectId
            name
        }
    }
`;
