import { makeStyles } from '@brivo/react-components';
const styles = makeStyles((theme) => {
    const isDarkTheme = theme.palette.type === 'dark';
    return {
        title: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '21px',
            color: theme.palette.text.textPrimary,
            margin: '30px 0px',
        },
        subtitle: {
            fontWeight: 450,
            fontSize: '14px',
            lineHeight: '18px',
            color: isDarkTheme ? theme.palette.colors.grey300 : theme.palette.colors.grey600,
            marginBottom: '30px',
        },
        wrapper: {
            height: '428px',
            paddingTop: '100px',
        },
    };
});
export default styles;
