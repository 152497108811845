import React from 'react';
import styles from './styles';
import { Grid } from '@brivo/react-components';
import { UsersIcon, DeviceDoorIcon } from '@brivo/react-components';
import SiteIconNew from './SiteIconNew';
import EventDetail from './components/EventDetail';

const EventDetails = ({ eventId, device, site, user, userIsClickable, actorId }) => {
    const classes = styles({ userIsClickable });
    return (
        <>
            <Grid container>
                {user && (
                    <Grid item xs={12} data-testid={`event-detail-user-${eventId}`}>
                        <EventDetail
                            icon={<UsersIcon className={classes.userIcon} />}
                            title={user}
                            isClickable={userIsClickable}
                            linkTo={`/users/${actorId}`}
                        />
                    </Grid>
                )}
                {device && (
                    <Grid item xs={12} data-testid={`event-detail-device-${eventId}`}>
                        <EventDetail icon={<DeviceDoorIcon className={classes.nonClickableIcons} />} title={device} />
                    </Grid>
                )}
                {site && (
                    <Grid item xs={12} data-testid={`event-detail-site-${eventId}`}>
                        <EventDetail icon={<SiteIconNew className={classes.nonClickableIcons} />} title={site} />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
export default EventDetails;
