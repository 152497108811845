import gql from 'graphql-tag';

//this is not going to stay here i'm not actually entirely sure where it is going to go
export const GET_RECENT_ACTIVITY_CSV = gql`
    query recentActivityCSV($columnList: [columnListMapping!]!, $filters: [CSVReportFilters]) {
        recentActivityCSV(columnList: $columnList, filters: $filters)
    }
`;

export const GET_RECENT_ACTIVITY_PREVIEW = gql`
    query recentActivityPreview($columnList: [columnListMapping!]!, $filters: [CSVReportFilters]) {
        recentActivityPreview(columnList: $columnList, filters: $filters)
    }
`;

export const LIST_AVAILABLE_REPORTS = gql`
    query listAvailableReports {
        listAvailableReports
    }
`;

export const GET_PRESIGNED_URL_FOR_REPORT = gql`
    query generateReportURL($fileName: String!) {
        generateReportURL(fileName: $fileName)
    }
`;

//really do probably want to put all this init stuff in the same query
export const GET_RECENT_ACTIVITY_COLUMNS = gql`
    query getRecentActivityColumns {
        getRecentActivityColumns
    }
`;

export const GET_GROUPS = gql`
    query getGroups {
        getGroups {
            name
            id
        }
    }
`;

export const GET_SITES = gql`
    query getSites {
        getSites {
            name
            id
        }
    }
`;

export const GET_USERS_WITH_FILTERING = gql`
    query getUsersWithFiltering($filters: [ListFilter]) {
        getAllUsers(limit: { rows: 1000 }, filters: $filters) {
            users {
                firstName
                lastName
                id
            }
        }
    }
`;

export const GET_CUSTOM_FIELDS_DISTINCT_VALUE = gql`
    query getCustomFieldsDistinctValues($columnKey: String, $filters: String) {
        getCustomFieldsDistinctValues(columnKey: $columnKey, filter: $filters) {
            text_value
            date_value
            numeric_value
        }
    }
`;

export const GET_DEVICES_WITH_FILTERING = gql`
    query getDevicesWithFiltering($filters: JournalFilter) {
        getDevices(filters: $filters) {
            name
            objectId
        }
    }
`;
