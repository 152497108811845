import React from 'react';
import { makeStyles } from '@brivo/react-components';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        position: 'relative',
        border: `2px solid ${theme.palette.colors.grey800}`,
        borderRadius: '2px',
    },
}));

export const VideoBlockContainer = ({ id, children, className }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, className, 'block mejs__mediaelement u-mt-mini u-mr-mini')} id={id}>
            {children}
        </div>
    );
};
