import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { startOfDay, startOfHour } from 'date-fns';
import { isEmpty, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
    GenericDialog,
    Button,
    DialogActions,
    DateFilter,
    makeStyles,
    Grid,
    LoadingIndicator,
    Snackbar,
    InfoAlert,
} from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';

import VideoPlayer from '../VideoPlayerV2';
import DoorOverlay from '../../../LiveVideo/components/LiveVideoLayout/DoorOverlay';
import TimelineSection from '../../../RecordedVideo/components/RecordedVideoPage/components/TimelineSection';
import {
    getProviderClipRequestTimes,
    getTimelineMaxEndTimestamp,
} from '../../../RecordedVideo/components/RecordedVideoPage/utils';
import { getClipFromVideoServices, getSignedClipUrlAndType } from '../../../RecordedVideo/utils/clipUtils';
import { filterEventsForSpecificCamera } from '../../../RecordedVideo/components/RecordedVideoPage/events';
import { getEventsV2, MAX_CLIPS_COUNT_V2, MAX_EVENTS_COUNT_V2 } from '../../hooks/eventsV2';
import { ALL_CAMERAS } from '../../hooks/useLayoutStateV2';
import { downloadClip, parseCameraUrls } from '../VideoPlayerV2/utils';
import { getSeverityOfEvent } from '../../../AccountSettings/Sites/SiteSettings/IndividualSiteSetting/FloorPlans/FloorPlansUtils';
import useGenericFilterGroup from '../../../../common/hooks/useGenericFilterGroup';
import { useFormatLocale } from '../../../../common/hooks/useFormatLocale';
import ApiHelper from '../../../../common/helpers/Helpers';
import { CAMERA_EVENT, PANEL_EVENT, DEBOUNCE_TIMEOUT_MS } from '@/common/constants/Constants';

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        padding: theme.spacing(1, 0, 0, 0),
    },
    filter: {
        margin: theme.spacing(0, 0, 1),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.colors.grey950 : theme.palette.colors.grey0,
    },
    timelineContainer: {
        marginTop: theme.spacing(1),
    },
    maxExceededCount: {
        marginBottom: theme.spacing(1.5),
    },
}));

const currentDate = new Date();

function VideoModal({
    api,
    currentLayout,
    eventClasses,
    cameraToView,
    setCameraToView,
    selectedSites,
    eventsApi,
    eventsRanking,
    eventsRankingLoading,
    doorStatusData,
}) {
    const DEFAULT_FILTERS = {
        selectedSites: selectedSites,
        selectedEvents: [],
        selectedCameras: cameraToView ? [cameraToView] : [],
        selectedDate: null,
        showCameraEvents: true,
    };
    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [clipStatus, setClipStatus] = useState({
        selectedClip: {},
        error: false,
        fetchingExternalClip: false,
    });
    const [selectedClipClicked, setSelectedClipClicked] = useState({
        hour: null,
        eventId: null,
        cameraId: null,
    });
    const [events, setEvents] = useState(null);
    const { localeDateFormat, formatTime } = useFormatLocale();
    const dateFormat = localeDateFormat().toLowerCase().replace(/m/g, 'M');
    const [chosenDate, setChosenDate] = useState();
    const [clipsCount, setClipsCount] = useState(0);
    const [unifiedCurrentStart, setUnifiedCurrentStart] = useState(null);
    const [unifiedCurrentEnd, setUnifiedCurrentEnd] = useState(null);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const notifications = Snackbar();
    const downloadClipMessages = {
        success: t('Page.recorded-video.download-completed'),
        error: t('Page.recorded-video.download-error'),
    };

    useEffect(() => {
        // startOfDay here could be unncessary, but keeping in case
        const selectedDate = startOfDay(currentDate).toISOString().split('T')[0] + 'T00:00';
        setChosenDate(selectedDate);
        filterValues.selectedDate = selectedDate;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeSelectedClip = () => {
        setSelectedClipClicked({ hour: null, eventId: null, cameraId: null });
        setClipStatus({ selectedClip: {}, error: false, fetchingExternalClip: false });
    };

    const handleFiltersChange = (value) => {
        removeSelectedClip();
        setFilters(value);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filterValues, updateAll } = useGenericFilterGroup(DEFAULT_FILTERS, handleFiltersChange, filters);
    const videoModalRef = useRef(null);
    /**
     * handleClickTimelineProviderClip needs access to the end timestamp
     * of the timeline's currently visible range. It is not possible to get
     * it via the timeline's clickHandler, which is only assigned on mount
     * and is therefore stuck with a frozen initial end time value. So, we use a
     * ref to keep track of it only for clip requests, NOT rendering purposes.
     * Keeping track of start & end of view window to only fetch clips & events for that section
     */
    const timelineEndTimestampRef = useRef(null);
    const timelineRef = useRef();
    const abortControllerRef = useRef(null);

    const startTimestamp = Date.parse(startOfDay(filterValues.selectedDate ?? new Date()));
    const endTimestamp = getTimelineMaxEndTimestamp(filterValues.selectedDate, currentDate);

    const handleClickTimelineProviderClip = (clipFromList, cameraName, updateTimelinePositionMarker) => {
        try {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            if (updateTimelinePositionMarker) {
                const existingTimeMarkers = document.getElementsByClassName('vis-custom-time-marker');
                !isEmpty(existingTimeMarkers) && existingTimeMarkers[0].remove();

                timelineRef.current?.timeline?.setCustomTime(clipFromList.startTime, 'timeline-position');
            }
            setSelectedClipClicked();

            const { startTime, endTime } = getProviderClipRequestTimes(clipFromList, timelineEndTimestampRef.current);
            setClipStatus({ selectedClip: { cameraName }, error: false, fetchingExternalClip: true });
            getClipFromVideoServices(api, clipFromList.cameraId, { startTime, endTime }, abortController.signal)
                .then((result) => {
                    if (result && videoModalRef.current) {
                        setClipStatus({
                            selectedClip: { ...({ ...result[0], ...clipFromList } ?? {}), cameraName },
                            error: false,
                            fetchingExternalClip: false,
                        });
                    } else {
                        if (videoModalRef.current) {
                            setClipStatus({ selectedClip: { cameraName }, error: false, fetchingExternalClip: false });
                        }
                    }
                })
                .catch((error) => {
                    setClipStatus({ selectedClip: { cameraName }, error: error, fetchingExternalClip: false });
                });
        } catch (error) {
            setClipStatus({ selectedClip: { cameraName }, error: error, fetchingExternalClip: false });
        }
    };

    //consider debounce
    const handleClickEventCamera = async ({ event, cameraId, cameraName, updateTimelinePositionMarker }) => {
        if (updateTimelinePositionMarker) {
            const formattedDate = new Date(event.occurred);
            const time = formatTime(formattedDate);

            timelineRef.current?.timeline?.setCustomTime(event.occurred, 'timeline-position');

            // https://github.com/visjs/vis-timeline/issues/1652,
            // not included in https://github.com/brivolabs/vis-timeline
            const existingTimeMarkers = document.getElementsByClassName('vis-custom-time-marker');
            !isEmpty(existingTimeMarkers) && existingTimeMarkers[0].remove();

            timelineRef.current.timeline.setCustomTimeMarker(time, 'timeline-position');
        }

        setSelectedClipClicked({
            hour: startOfHour(event.occurred).toISOString(),
            eventId: event.id,
            cameraId: cameraId,
        });

        try {
            let clip = getSignedClipUrlAndType(event, cameraId);
            if (!clip?.src) {
                setClipStatus({ ...clipStatus, error: false, fetchingExternalClip: true });
                clip = await getClipFromVideoServices(api, cameraId, { eventId: event.id })[0].catch((error) => {
                    throw error;
                });

                if (Object.keys(clip)?.length === 0) {
                    clip = null;
                }
            }
            setClipStatus({
                selectedClip: { ...(clip ?? {}), cameraName },
                error: false,
                fetchingExternalClip: false,
            });
        } catch (error) {
            setClipStatus({ selectedClip: { cameraName }, error: error, fetchingExternalClip: false });
        }
    };

    const fetchEvents = async (start, end) => {
        try {
            const associatedDevices = cameraToView?.associated_devices;
            const startTimeParam = new Date(start).toISOString();
            const endTimeParam = new Date(end).toISOString();

            const eventsForCamera = await api.getEventsForCamera(cameraToView?.id, startTimeParam, endTimeParam);
            let eventsForDevicesAndCameras = eventsForCamera.events.filter((event) => event.eventType === CAMERA_EVENT);

            if (!isEmpty(associatedDevices)) {
                const eventsForDevices = await getEventsV2({
                    eventsApi,
                    sites: selectedSites,
                    startTime: startTimeParam,
                    endTime: endTimeParam,
                    showCameraEvents: true,
                    securityActionIds: Object.values(eventsRanking).reduce((prev, curr) => prev.concat(curr), []),
                    camerasWithAssociatedDevices: [cameraToView],
                    devices: associatedDevices.map((deviceId) => ({ id: deviceId })),
                });

                const panelEventsForDevices = eventsForDevices.filter((event) => event.eventType === PANEL_EVENT);

                eventsForDevicesAndCameras = eventsForDevicesAndCameras.concat(
                    filterEventsForSpecificCamera(panelEventsForDevices, cameraToView?.id)
                );
            }

            const eventsForDevicesAndCamerasWithSeverity = eventsForDevicesAndCameras.map((event) => ({
                ...event,
                severity: getSeverityOfEvent(
                    parseInt(event.securityActionId, 10),
                    eventsRanking.infoEvents,
                    eventsRanking.warningEvents,
                    eventsRanking.criticalEvents
                ),
            }));

            setEvents(eventsForDevicesAndCamerasWithSeverity);
        } catch (e) {
            console.error(e);
        }
    };

    const eventFetchDependencies = JSON.stringify({
        videoModalRef,
        cameraToView,
        unifiedCurrentStart,
        startTimestamp,
        unifiedCurrentEnd,
        endTimestamp,
    });

    const prevEventFetchDependenciesRef = useRef(eventFetchDependencies);

    useEffect(() => {
        if (prevEventFetchDependenciesRef.current !== eventFetchDependencies) {
            if (
                videoModalRef.current &&
                cameraToView?.id &&
                (unifiedCurrentStart || startTimestamp) &&
                (unifiedCurrentEnd || endTimestamp)
            ) {
                fetchEvents(unifiedCurrentStart || startTimestamp, unifiedCurrentEnd || endTimestamp);
            }
        }

        prevEventFetchDependenciesRef.current = eventFetchDependencies;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, cameraToView, selectedSites, eventsRanking, unifiedCurrentStart, unifiedCurrentEnd]);

    useEffect(() => {
        videoModalRef.current = true;

        return () => {
            videoModalRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLive = clipStatus?.selectedClip?.src ? false : true;

    return (
        <GenericDialog
            maxWidth="md"
            open={!!cameraToView?.id}
            title={currentLayout?.name === ALL_CAMERAS ? cameraToView.name : cameraToView.cameraName}
            onClose={() => {
                setCameraToView(null);
                filterValues.selectedDate = chosenDate;
                removeSelectedClip();
            }}
            fullWidth
        >
            <Grid container data-testid="video-modal">
                {cameraToView?.id && (
                    <>
                        <Grid item container className={classes.filter} spacing={2} alignItems="stretch">
                            <Grid item xs={3}>
                                <DateFilter
                                    id="selectedDate"
                                    label={t('Page.unified-video.video-modal.filter.date.label')}
                                    noSelectionLabel={t('Page.event-tracker.filter.date-no-selection')}
                                    invalidDateMessage={t('Component.date-filter.invalid-date-message')}
                                    clearMessage={t('Component.date-filter.clear-message')}
                                    format={dateFormat}
                                    locale={i18n.language}
                                    onValueChange={debounce((selectedDate) => {
                                        updateAll({ ...filterValues, selectedDate });
                                    }, DEBOUNCE_TIMEOUT_MS)}
                                    dateValue={filterValues.selectedDate}
                                    disableFuture={true}
                                />
                            </Grid>
                        </Grid>
                        {events?.length >= MAX_EVENTS_COUNT_V2 && (
                            <Grid item xs={12} className={classes.maxExceededCount}>
                                <InfoAlert
                                    infoMessage={t('Page.unified-video.video-modal.event.count-exceeded', {
                                        eventLimit: MAX_EVENTS_COUNT_V2,
                                    })}
                                    isInsideModal
                                />
                            </Grid>
                        )}
                        {clipsCount >= MAX_CLIPS_COUNT_V2 && (
                            <Grid item xs={12} className={classes.maxExceededCount}>
                                <InfoAlert
                                    infoMessage={t('Page.unified-video.video-modal.clips.count-exceeded', {
                                        clipsLimit: MAX_CLIPS_COUNT_V2,
                                    })}
                                    isInsideModal
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <VideoPlayer
                                cameraData={cameraToView}
                                streamUrls={parseCameraUrls(cameraToView)}
                                selectedClipClicked={selectedClipClicked}
                                clipStatus={clipStatus}
                                removeSelectedClip={removeSelectedClip}
                                doorStatusData={doorStatusData}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.timelineContainer}>
                            {eventsRankingLoading ? (
                                <Grid container className={classes.loadingContainer} justifyContent="center">
                                    <LoadingIndicator />
                                </Grid>
                            ) : (
                                <TimelineSection
                                    events={events}
                                    minStartTime={startTimestamp}
                                    maxEndTime={endTimestamp}
                                    handleClickEventCamera={handleClickEventCamera}
                                    handleClickTimelineProviderClip={handleClickTimelineProviderClip}
                                    timelineEndTimestampRef={timelineEndTimestampRef}
                                    timelineRef={timelineRef}
                                    removeSelectedClip={removeSelectedClip}
                                    selectedEventId={selectedClipClicked ? selectedClipClicked.eventId : null}
                                    selectedCamera={{
                                        cameraId: cameraToView?.id,
                                        cameraName: cameraToView?.cameraName,
                                    }}
                                    eventClasses={eventClasses}
                                    isV2
                                    isClipClicked={!!clipStatus.selectedClip.cameraName}
                                    setUnifiedCurrentStart={setUnifiedCurrentStart}
                                    setUnifiedCurrentEnd={setUnifiedCurrentEnd}
                                    setClipsCount={setClipsCount}
                                />
                            )}
                        </Grid>
                    </>
                )}
            </Grid>

            <DialogActions className={classes.dialogActions}>
                {!isLive && (
                    <Button
                        type="secondary"
                        p
                        text={t('Page.unified-video.video-modal.button.live-video')}
                        onClick={removeSelectedClip}
                    />
                )}
                {doorStatusData && (
                    <DoorOverlay
                        apiHelper={api}
                        cameraId={cameraToView.id}
                        cameraStatus={cameraToView.status}
                        doorStatusData={doorStatusData}
                        inlineLayout={true}
                    />
                )}
                <Button
                    type="primary"
                    text={t('Page.unified-video.video-modal.button.export')}
                    onClick={() => downloadClip(clipStatus.selectedClip, notifications, downloadClipMessages)}
                    disabled={isEmpty(clipStatus.selectedClip)}
                />
            </DialogActions>
        </GenericDialog>
    );
}

VideoModal.propTypes = {
    cameraToView: PropTypes.object,
    setCameraToView: PropTypes.func,
    selectedSites: PropTypes.array,
    eventsApi: PropTypes.object,
    eventsRanking: PropTypes.object.isRequired,
    eventsRankingLoading: PropTypes.bool.isRequired,
    doorStatusData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default withApi(VideoModal, ApiHelper);
