import { useRef, useState, useEffect, useCallback } from 'react';
import { isArray } from 'lodash';

const useGenericFilterGroup = (defaultFilterValues, onChange, initialFilterValues = null) => {
    if (!defaultFilterValues) {
        throw new TypeError('Initial filter values argument must be defined');
    }
    if (Object.keys(defaultFilterValues).length === 0) {
        throw new TypeError('Initial filter values argument must not be an empty object');
    }
    let myFilterValues = Object.assign({}, defaultFilterValues);

    if (initialFilterValues) {
        myFilterValues = Object.assign(myFilterValues, initialFilterValues);
    }

    const [filterValues, setFilterValues] = useState(myFilterValues);
    const [filterChangeMethods, setFilterChangeMethods] = useState({});
    const [nrFiltersChanged, setNrFiltersChanged] = useState(0);
    const [clearingValues, setClearingValues] = useState(false);

    const original = useRef(defaultFilterValues);

    const clearAll = useCallback(() => {
        setFilterValues(defaultFilterValues);
        setClearingValues(true);
    }, [defaultFilterValues]);

    const updateAll = useCallback((newValue) => {
        function getFilterFunctionsMap() {
            return Object.keys(newValue).reduce((acc, filterKey) => {
                acc[filterKey] = (value) => {
                    setFilterValues((prevState) => ({ ...prevState, [filterKey]: value }));
                };
                return acc;
            }, {});
        }

        setFilterValues(newValue);
        setFilterChangeMethods(getFilterFunctionsMap());
    }, []);

    useEffect(() => {
        function getFilterFunctionsMap() {
            return Object.keys(original.current).reduce((acc, filterKey) => {
                acc[filterKey] = (value) => {
                    setFilterValues((prevState) => ({ ...prevState, [filterKey]: value }));
                };
                return acc;
            }, {});
        }

        setFilterChangeMethods(getFilterFunctionsMap());
    }, []);

    useEffect(() => {
        function getNrFiltersChanged() {
            return Object.keys(defaultFilterValues).reduce((acc, val) => {
                if (
                    (isArray(filterValues[val]) && filterValues[val]?.length) ||
                    (!isArray(filterValues[val]) && defaultFilterValues[val] !== filterValues[val])
                ) {
                    acc++;
                }
                return acc;
            }, 0);
        }

        setNrFiltersChanged(getNrFiltersChanged());
        if (onChange) {
            onChange(filterValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    return {
        filterValues,
        filterChangeMethods,
        setFilterValues,
        nrFiltersChanged,
        clearAll,
        updateAll,
        clearingValues,
        setClearingValues,
    };
};
export default useGenericFilterGroup;
