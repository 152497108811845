import { startOfDay, endOfDay } from 'date-fns';
import { defaultEventsTypeValues } from '../../EventTracker/eventTrackerUtils';
import { debouncePromise } from '../../../common/utils/promiseUtils';
import { fetchEventsWithClips } from '../../RecordedVideo/components/RecordedVideoPage/events';

const defaultConfig = {
    warning: [],
    critical: [],
    info: [],
};

export const MAX_EVENTS_COUNT_V2 = 500;
export const MAX_CLIPS_COUNT_V2 = 500;

export const getEventsV2 = async ({
    eventsApi,
    sites,
    date,
    startTime,
    endTime,
    showCameraEvents,
    securityActionIds,
    camerasWithAssociatedDevices,
    devices = null,
}) => {
    const abortController = new AbortController();
    //TODO, note, largly copied from EventTrackerPage. We need to consider abstracting/sharing, but for now, cram it in here
    eventsApi.setEventsRanking(defaultConfig);
    let fromDate;
    let tillDate;
    if (startTime && endTime) {
        fromDate = new Date(startTime);
        tillDate = new Date(endTime);
    } else {
        fromDate = startOfDay(date ? date : new Date());
        tillDate = endOfDay(date ? date : new Date());
    }
    const currentPromise = debouncePromise((...params) => {
        let promise = eventsApi.findAll(...params);

        return { value: promise, aborter: () => abortController.abort() };
    }, 500);

    const results = await currentPromise(
        fromDate,
        tillDate,
        MAX_EVENTS_COUNT_V2, //limit
        null, //timeseries
        sites, //sites
        null, //user
        devices, // devices
        showCameraEvents,
        true, //excludeEventsWithoutVideo - BDS clips will be excluded for now
        defaultEventsTypeValues, //selectedEventSeverities
        securityActionIds, //selectedEvents
        '', //nextToken
        null, //hiddenEvents
        abortController.signal
    );

    return fetchEventsWithClips(results?.events, camerasWithAssociatedDevices, true);
};
