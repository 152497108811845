import gql from 'graphql-tag';

export const DEVICES_WITH_STATUS_QUERY = gql`
    query getAccessPoints($pageSize: Int!, $offset: Int!, $sitesOids: [Int]) {
        getAccessPoints(limit: { rows: $pageSize, offset: $offset }, sitesOids: $sitesOids) {
            accountId
            activationEnabled
            controlPanelCpNumber
            controlPanelId
            controlPanelName
            controlPanelTypeId
            deviceId
            doorAjarEnabled
            deviceAjarEnabled
            deviceAjarThreshold
            rexUnlock
            useRex
            id
            name
            reportLiveStatus
            siteId
            siteName
            twoFactorEnabled
            type
            status {
                data {
                    deviceName
                    deviceId
                    siteId
                    reportLiveStatus
                    controlPanelId
                    lockState
                    deviceStatus
                    systemStatus
                    systemStatusMessage
                    messageReceivedTime
                    isActive
                    threatLevel
                }
                rawData {
                    connectedStatus
                    nextUnlockScheduleChange
                    nextIgnoreScheduleChange
                    unlockScheduleStatus
                    ignoreScheduleStatus
                    accessState
                    ajarStatus
                    inputStatus
                    intrusionStatus
                    batteryState
                    batteryRemaining
                    doorState
                    threatLevel
                    readerStatus
                    alternateReaderStatus
                    peripheralInfo {
                        type
                        networkInfo {
                            networkStatus
                        }
                    }
                }
            }
            engageMsg
            disengageMsg
            doorStationId
        }
    }
`;

export const GET_ALL_PANELS_PER_SITES = gql`
    query panelsPerSites($pageSize: Int!, $offset: Int!, $sitesOids: [Int]) {
        panelsPerSites(limit: { rows: $pageSize, offset: $offset }, sitesOids: $sitesOids) {
            id
            panelName
            panelId
            serialNumber
            activated
            updated
            lastContact
            firmwareVersion
            panelType
            panelTypeId
            status
        }
    }
`;

export const CONFIG_BY_PANEL_QUERY = gql`
    query getConfigByPanelId($panelId: Int!) {
        getConfigByPanelId(panelId: $panelId) {
            brainId
            name
            rs485Setings {
                portNumber
                operationMode
                baudRate
                errorDetectionMethod
                brainId
                secureMode
                pdAddress
            }
            mainBoard {
                brainId
                boardNumber
                boardLocation
                boardTypeId
                ioPoints {
                    id
                    label
                    normalState
                    eol
                    inUse
                    type
                }
            }
            doorBoards6000 {
                brainId
                boardNumber
                boardLocation
                boardTypeId
                ioPoints {
                    id
                    label
                    normalState
                    eol
                    inUse
                    type
                }
            }
            doorBoards6100 {
                brainId
                boardNumber
                boardLocation
                boardTypeId
                ioPoints {
                    id
                    label
                    normalState
                    eol
                    inUse
                    type
                }
            }
            ioBoards {
                brainId
                boardNumber
                boardLocation
                boardTypeId
                ioPoints {
                    id
                    label
                    normalState
                    eol
                    inUse
                    type
                }
            }
        }
    }
`;

export const GET_DEVICE_SITE_ID = gql`
    query getAccessPointStatus($deviceOId: Int!) {
        getAccessPointStatus(deviceOId: $deviceOId) {
            siteId
        }
    }
`;

export const GET_DEVICES_FOR_JOURNAL_PAGE = gql`
    query devices($name: String!, $rows: Int = 10) {
        getDevices(limit: { offset: 0, rows: $rows }, filters: { name: $name }) {
            objectId
            name
            siteName
            siteId
        }
    }
`;

export const GET_USERS_AND_ADMINS_BY_BDS_ID = gql`
    query getUsersAndAdminsByBdsId($doorStationId: Int!, $pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getUsersAndAdminsByBdsId(
            doorStationId: $doorStationId
            limit: { rows: $pageSize, offset: $offset }
            filters: $filters
        ) {
            id
            objectId
            firstName
            lastName
            isAdmin
            isBmpUser
        }
    }
`;

export const GET_DEVICES_FOR_GROUP = gql`
    query getCurrentDevices($groupOid: Int!, $pageSize: Int!, $offset: Int!) {
        getCurrentDevices(groupOid: $groupOid, limit: { rows: $pageSize, offset: $offset }) {
            devices {
                controlPanelId
                name
                privacyModeOverride
                schedule {
                    id
                    name
                }
                siteId
                siteName
                twoFactorEnabled
                type
                id
            }
            totalCount
        }
    }
`;

export const GET_DEVICES_FOR_GROUP_WITH_FILTERING = gql`
    query getCurrentDevices($groupOid: Int!, $pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getCurrentDevices(groupOid: $groupOid, limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            devices {
                controlPanelId
                name
                privacyModeOverride
                schedule {
                    id
                    name
                }
                siteId
                siteName
                twoFactorEnabled
                type
                id
            }
            totalCount
        }
    }
`;
