import React, { useState, useEffect, useContext } from 'react';
import { Typography } from '@brivo/react-components';
import { UpwardTrendIcon, DownwardTrendIcon } from '@brivo/react-components';
import { PROMISE_CANCELLED } from '../../../../../common/constants/Constants';
import { DashboardConfigurationContext } from '../../../common/DashboardBuilderContext';
import { POSITIVE_TREND, NEGATIVE_TREND } from './constants';
import clsx from 'clsx';
import useStyles from './styles';

export function isValidTrendValue(value) {
    return typeof value === 'number' && value !== 0 && value !== null;
}

export function formatTrendValue(value) {
    if (Math.abs(value) >= 1) {
        return `${value.toFixed(0).toString()}%`;
    } else {
        return `${value.toFixed(2).toString()}%`;
    }
}

export const TrendIcon = ({ trendValue, trendDirection }) => {
    const classes = useStyles();
    let trendIcon;

    if (trendValue > 0) {
        const isGreenColor = trendDirection === POSITIVE_TREND;
        const isRedColor = trendDirection === NEGATIVE_TREND;

        trendIcon = (
            <UpwardTrendIcon
                data-testid={clsx({
                    'green-icon-color': isGreenColor,
                    'red-icon-color': isRedColor,
                })}
                className={clsx(
                    {
                        [classes.greenColor]: isGreenColor,
                        [classes.redColor]: isRedColor,
                    },
                    'upward-trend-icon'
                )}
            />
        );
    } else if (trendValue < 0) {
        const isGreenColor = trendDirection === NEGATIVE_TREND;
        const isRedColor = trendDirection === POSITIVE_TREND;

        trendIcon = (
            <DownwardTrendIcon
                data-testid={clsx({
                    'green-icon-color': isGreenColor,
                    'red-icon-color': isRedColor,
                })}
                className={clsx(
                    {
                        [classes.greenColor]: isGreenColor,
                        [classes.redColor]: isRedColor,
                    },
                    'downward-trend-icon'
                )}
            />
        );
    } else {
        trendIcon = null;
    }

    return trendIcon;
};

const KPITrend = ({ trendQuery: query, uiVariables }) => {
    const classes = useStyles();

    const [trendValue, setTrendValue] = useState(null);
    const [{ roverApiProxy, globalFiltersState }] = useContext(DashboardConfigurationContext);

    useEffect(() => {
        const { promise, cancel } = roverApiProxy.getData(query);
        promise
            ?.then((data) => {
                setTrendValue(data[0].kpiTrendValue);
            })
            .catch((e) => {
                if (e.message !== PROMISE_CANCELLED) {
                    console.error(e);
                    setTrendValue(null);
                }
            });

        return cancel;
    }, [query, roverApiProxy, globalFiltersState]);

    // trendDirection is deprecated in data, but let's rename trendGreen to trendDirection for clarity
    const { trendGreen: trendDirection, trendValueType } = uiVariables;

    return (
        isValidTrendValue(trendValue) && (
            <div className={classes.trendDiv} data-testid="kpi-trend">
                <Typography
                    className={clsx({
                        [classes.greenColor]:
                            (trendDirection === POSITIVE_TREND && trendValue > 0) ||
                            (trendDirection === NEGATIVE_TREND && trendValue < 0),
                        [classes.redColor]:
                            (trendDirection === NEGATIVE_TREND && trendValue > 0) ||
                            (trendDirection === POSITIVE_TREND && trendValue < 0),
                    })}
                    data-text-color={clsx({
                        green:
                            (trendDirection === POSITIVE_TREND && trendValue > 0) ||
                            (trendDirection === NEGATIVE_TREND && trendValue < 0),
                        red:
                            (trendDirection === NEGATIVE_TREND && trendValue > 0) ||
                            (trendDirection === POSITIVE_TREND && trendValue < 0),
                    })}
                    data-testid="metric"
                >
                    {trendValueType === 'integer' ? trendValue : formatTrendValue(trendValue)}
                </Typography>
                <TrendIcon trendValue={trendValue} trendDirection={trendDirection} />
            </div>
        )
    );
};
export default KPITrend;
