import React, { Suspense, lazy } from 'react';

const AdminManagement = lazy(() => import('./AdminManagement'));

const AdminManagementContainer = (props) => (
    <Suspense fallback={null}>
        <AdminManagement {...props} />
    </Suspense>
);

export default AdminManagementContainer;
