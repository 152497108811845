import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles(() => {
    return {
        dialogAction: {
            margin: '0px',
            padding: '0 1.5px 1.5px',
        },
        closeButton: {
            position: 'absolute',
            right: 13,
            top: 19,
        },
        formatMessage: {
            paddingLeft: '1rem',
            paddingBottom: '1rem',
            fontSize: '0.8rem',
        },
    };
});

export default useStyles;
