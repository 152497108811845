import React, { useState, useCallback } from 'react';
import { IconButton } from '@brivo/react-components';
import { Menu, MenuItem } from '@brivo/react-components';

const IconMenu = ({ icon, iconStyles, menuItems, id }) => {
    const [anchor, setAnchor] = useState(null);
    const [isClicked, setIsClicked] = useState(false);

    const handleMenuClose = () => {
        setIsClicked(false);
        setAnchor(null);
    };

    const handleIconClick = useCallback(
        (event) => {
            if (!isClicked) {
                setAnchor(event.currentTarget);
                setIsClicked(true);
            }
        },
        [isClicked]
    );

    return (
        <>
            {menuItems && menuItems.length > 0 ? (
                <div>
                    <IconButton
                        className={iconStyles ? iconStyles : null}
                        size="large"
                        icon={icon}
                        onClick={handleIconClick}
                        id={id}
                    />
                    <Menu
                        open={isClicked}
                        anchorEl={anchor}
                        onClose={handleMenuClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        getContentAnchorEl={null}
                    >
                        {menuItems.map((menuItem) => {
                            return (
                                <MenuItem
                                    disabled={menuItem.disabled}
                                    key={`menu-item-${menuItem.text}-${menuItem.id}`}
                                    onClick={() => {
                                        handleMenuClose();
                                        menuItem.handleClick();
                                    }}
                                >
                                    {menuItem.text}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            ) : null}
        </>
    );
};

export default IconMenu;
