import React from 'react';

import ErrorBoundary from '../ErrorBoundary';
import Navigation from './Navigation';

const NavigationContainer = (props) => {
    return (
        <ErrorBoundary sectionName="Navigation Menu">
            <Navigation {...props} />
        </ErrorBoundary>
    );
};

export default NavigationContainer;
