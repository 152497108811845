import { ITheme, makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme: ITheme) => ({
    wrapper: {
        marginTop: '2rem',
    },
    table: {
        marginTop: '0.5rem',
        width: '100%',
    },
    isTableLoading: {
        display: 'none',
    },
    loadingText: {
        textAlign: 'center',
        color: theme.palette.colors.grey100,
    },
    columnEditor: {
        width: '100%',
        background: theme.palette.colors.grey900,
        padding: '1rem',
        maxHeight: '800px',
        borderRadius: '0.5rem',
    },
}));

export default useStyles;
