import { useState, useMemo, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Paper,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    EmptyStateSection,
    DropdownCaretDownIcon,
} from '@brivo/react-components';
import { checkPermissions } from '@brivo/onairplus-services';

import styles from './styles';
import { shouldRenderEventsTimeline } from './util';
import EventTimeline from './components/EventTimeline';
import CategorySummary from './components/CategorySummary';

import { UserContext } from '@common/user/UserProvider';
import { USER_VIEW_USERS } from '@common/permissions/Permissions';

//TODO sticky date header for each new date

const EventsPanel = ({ events, handleClickEventCamera, selectedClipClicked, defaultExpandedHour }) => {
    const classes = styles();
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(selectedClipClicked?.hour ?? false);
    const userContext = useContext(UserContext);
    const { permissions } = userContext;

    const canViewUserPage = useMemo(
        () => checkPermissions({ userPermissions: permissions, necessaryPermissions: [USER_VIEW_USERS] }),
        [permissions]
    );

    const eventRef = useRef(null);

    useEffect(() => setExpanded(defaultExpandedHour), [defaultExpandedHour]);

    useEffect(() => {
        if (eventRef?.current) {
            eventRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }
    }, [selectedClipClicked]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Paper className={classes.eventsPanel}>
            {!events ? (
                <EmptyStateSection
                    title={t('Page.recorded-video.no-events')}
                    subtitle={t('Page.recorded-video.no-events-subtitle')}
                    hideIcon="true"
                />
            ) : (
                Object.keys(events).map((day) => {
                    return (
                        <div key={day}>
                            <Typography
                                variant="h3"
                                className={classes.dateHeader}
                                data-testid={'heading'}
                                component="p"
                            >
                                {t('Page.recorded-video.events')}
                            </Typography>
                            {Object.keys(events[day]).map((hour) => {
                                const eventsForHour = events[day][hour];
                                //sonarqube and eslint have different opinions about === here. send help.
                                //eslint-disable-next-line
                                const isBinOpen = expanded == hour;
                                return eventsForHour?.length > 0 ? (
                                    <Accordion
                                        className={classes.accordion}
                                        key={hour}
                                        expanded={isBinOpen}
                                        onChange={handleChange(hour)}
                                    >
                                        <AccordionSummary
                                            className={classes.accordionSummary}
                                            expandIcon={
                                                <DropdownCaretDownIcon className={classes.accordionExpandIcon} />
                                            }
                                        >
                                            <CategorySummary
                                                key={`${hour}${isBinOpen}`}
                                                time={new Date(hour)}
                                                eventCount={eventsForHour?.length ?? 0}
                                                isOpen={isBinOpen}
                                            />
                                        </AccordionSummary>
                                        {
                                            //Potentially will make opening slower, but will save DOM computations and make closing fast.
                                            shouldRenderEventsTimeline(expanded, hour) && (
                                                <AccordionDetails>
                                                    <EventTimeline
                                                        events={eventsForHour}
                                                        hour={hour}
                                                        handleClickEventCamera={handleClickEventCamera}
                                                        selectedClipClicked={selectedClipClicked}
                                                        canViewUserPage={canViewUserPage}
                                                        eventRef={eventRef}
                                                    />
                                                </AccordionDetails>
                                            )
                                        }
                                    </Accordion>
                                ) : null;
                            })}
                        </div>
                    );
                })
            )}
        </Paper>
    );
};

export default EventsPanel;
