import gql from 'graphql-tag';

export const GET_ADMIN_BY_ID_TAG = gql`
    query getAdminById($adminId: Int!) {
        getAdminById(id: $adminId) {
            id
            isPrimary
            adminInfo {
                firstName
                lastName
                email
                phoneNumber
                timeZone
                migrated
                showSiteTimeZone
                languageId
            }
            userTypeId
            roles {
                id
            }
            assignments {
                sites {
                    isAll
                    assignments {
                        site {
                            id
                        }
                    }
                }
                groups {
                    isAll
                    assignments {
                        group {
                            id
                        }
                    }
                }
                lockdowns {
                    isAll
                    assignments {
                        lockdown {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ADMINS_FOR_JOURNAL_PAGE_TAG = gql`
    query administrators($name: String!, $rows: Int = 10) {
        getAdministrators(limit: { offset: 0, rows: $rows }, filters: { name: $name }) {
            emailAddress
            lastName
            firstName
            objectId
        }
    }
`;
