import createSvgIcon from '../../../common/utils/createSvgIcon';

const CameraIcon = createSvgIcon(
    <>
        <path
            d="M1.47363 20.6826C1.58063 20.6362 1.6915 20.5894 1.80611 20.5422C11.6839 16.3724 32.9398 7.33819 41.608 3.43157C50.3748 -0.519466 61.873 1.78531 66.5263 3.43157V14.6308C66.5263 17.2904 64.9444 19.6952 62.5021 20.7483L44.6792 28.4335C43.706 33.7394 47.7806 38.6292 53.1749 38.6292H55.498V34.8965C55.498 31.9254 57.9066 29.5168 60.8778 29.5168C63.8174 29.5168 66.2127 31.8764 66.2569 34.8157L66.4428 47.1951C66.4887 50.2494 64.0254 52.75 60.9707 52.75C57.9482 52.75 55.498 50.2998 55.498 47.2773V43.4984H50.3745C44.2993 43.4984 39.3745 38.5736 39.3745 32.4984V30.7209L44.6792 28.4335L62.5021 20.7483C64.9444 19.6952 66.5263 17.2904 66.5263 14.6308L35.9736 27.3309C31.48 29.1988 26.5315 25.8968 26.5315 21.0304C17.483 16.1957 6.50132 18.6126 1.80611 20.5422C1.6938 20.5897 1.58296 20.6364 1.47363 20.6826Z"
            fill="#344559"
        />
        <path
            d="M5.03338 30.2124V30.2124C3.25449 30.7338 1.47363 29.4 1.47363 27.5463V20.6826M1.47363 20.6826C5.96403 18.7349 17.2622 16.0777 26.5315 21.0304M1.47363 20.6826C11.199 16.5785 32.8413 7.38261 41.608 3.43157C50.3748 -0.519466 61.873 1.78531 66.5263 3.43157M26.5315 21.0304V21.0304C26.5315 25.8968 31.48 29.1988 35.9736 27.3309L66.5263 14.6308M26.5315 21.0304L66.5263 3.43157M66.5263 14.6308V3.43157M66.5263 14.6308V14.6308C66.5263 17.2904 64.9444 19.6952 62.5021 20.7483L44.6792 28.4335M10.3381 21.5173C8.73273 23.5114 6.08036 30.0733 10.4777 34.8034C14.875 39.5335 23.6697 36.7511 24.6469 33.8295M27.6482 35.7772L39.3745 30.7209M39.3745 30.7209V32.4984C39.3745 38.5736 44.2993 43.4984 50.3745 43.4984H55.498M39.3745 30.7209L44.6792 28.4335M44.6792 28.4335V28.4335C43.706 33.7394 47.7806 38.6292 53.1749 38.6292H55.498M55.498 38.6292H56.3286C58.298 38.6292 61.0285 38.9166 61.1526 40.8821C61.1596 40.9936 61.1558 41.1038 61.1425 41.2121C60.9026 43.1668 58.298 43.4984 56.3286 43.4984H55.498M55.498 38.6292V34.8965C55.498 31.9254 57.9066 29.5168 60.8778 29.5168V29.5168C63.8174 29.5168 66.2127 31.8764 66.2569 34.8157L66.4428 47.1951C66.4887 50.2494 64.0254 52.75 60.9707 52.75V52.75C57.9482 52.75 55.498 50.2998 55.498 47.2773V43.4984"
            stroke="#344559"
            strokeWidth="2"
        />
        <circle cx="17.3982" cy="28.0167" r="5.08224" fill="#F1D856" />
    </>,
    'CameraWidgetIcon'
);

const CameraWidgetIcon = <CameraIcon style={{ height: '52px', width: '68px' }} viewBox="0 0 68 52" />;

export default CameraWidgetIcon;
