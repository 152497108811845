import React, { Suspense, lazy } from 'react';

const NotificationsPage = lazy(() => import('./NotificationsPage'));

const NotificationsContainer = (props) => (
    <Suspense fallback={null}>
        <NotificationsPage {...props} />
    </Suspense>
);

export default NotificationsContainer;
