import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600,
        padding: '24px',
        fontSize: '16px',
        color: theme.palette.brivoAliases.ContentBlockTitleColor,
    },
    subtitle: {
        fontWeight: 400,
        marginLeft: '16px',
        fontSize: '14px',
        color: theme.palette.brivoAliases.fieldTextSecondary,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '1.5rem',
        height: '4.5rem',
    },
}));

export default useStyles;
