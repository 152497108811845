import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon - pin number - duotone">
                <g id="cursor-dial">
                    <g id="Line_Icons">
                        <g id="Group">
                            <path
                                id="Combined Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2 3H6V7H2V3ZM8 3H12V7H8V3ZM6 9H2V13H6V9ZM8 9H12V13H8V9ZM6 15H2V19H6V15ZM8 15H12V19H8V15ZM18 3H14V7H18V3Z"
                                fill="#485769"
                            />
                            <path
                                id="Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 21H16C15.591 21 15.224 20.751 15.071 20.371L13.071 15.371C12.948 15.063 12.986 14.714 13.172 14.44C13.358 14.164 13.668 14 14 14H15V10C15 9.448 15.448 9 16 9H19C19.552 9 20 9.448 20 10V13.279L22.316 14.051C22.725 14.187 23 14.569 23 15V20C23 20.552 22.552 21 22 21ZM16.677 19H21V15.72L18.684 14.948C18.275 14.812 18 14.431 18 14V11H17V15C17 15.552 16.552 16 16 16H15.477L16.677 19Z"
                                fill="#7DA6DB"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>,
    'RecentActivityUsingPin'
);
