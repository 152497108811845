import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    newNavText: {
        fontSize: '0.875rem',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        paddingLeft: 24,
    },
    navItem: {
        color: theme.palette.brivoAliases.newNavTextColor,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        paddingLeft: 20,
        paddingRight: 16,
        height: '2.75rem',
        '& svg': {
            color: theme.palette.brivoAliases.newNavIconColor,
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.brivoAliases.newNavBackgroundHoverColor,

            '& svg': {
                color: theme.palette.brivoAliases.newNavIconHoverColor,
            },

            '& p': {
                color: theme.palette.brivoAliases.newNavTextHoverColor,
            },
        },
        '&:focus-visible': {
            outline: `1px solid ${theme.palette.brivoAliases.newToggleButtonSelectedColor}`,
            outlineOffset: '-2px',
        },
    },
    active: {
        backgroundColor: theme.palette.brivoAliases.newNavBackgroundHoverColor,

        boxShadow: `inset 3px 0px 0px ${theme.palette.colors.blue500}`,
        '& p': {
            color: `${theme.palette.brivoAliases.newNavTextHoverColor} !important`,
        },
    },
    nested: {
        paddingLeft: 44,

        backgroundColor: theme.palette.brivoAliases.newNavNestedBackgroundColor,
    },
}));

export default styles;
