import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.26316 5.375C3.32137 5.375 2.55789 4.64746 2.55789 3.75C2.55789 2.85254 3.32137 2.125 4.26316 2.125C5.20495 2.125 5.96842 2.85254 5.96842 3.75C5.96842 4.64746 5.20495 5.375 4.26316 5.375ZM6.65034 6.07114C7.28186 5.48128 7.67368 4.65924 7.67368 3.75C7.67368 1.95507 6.14674 0.5 4.26316 0.5C2.37958 0.5 0.852632 1.95507 0.852632 3.75C0.852632 4.65924 1.24445 5.48128 1.87597 6.07114C0.743996 6.8015 0 8.0367 0 9.4375V12.6875C0 13.1362 0.381736 13.5 0.852632 13.5C1.32353 13.5 1.70526 13.1362 1.70526 12.6875V9.4375C1.70526 8.09131 2.85047 7 4.26316 7C5.67584 7 6.82105 8.09131 6.82105 9.4375V12.6875C6.82105 13.1362 7.20279 13.5 7.67368 13.5C8.14458 13.5 8.52632 13.1362 8.52632 12.6875V9.4375C8.52632 8.0367 7.78232 6.8015 6.65034 6.07114Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7368 5.375C12.795 5.375 12.0315 4.64746 12.0315 3.75C12.0315 2.85254 12.795 2.125 13.7368 2.125C14.6786 2.125 15.4421 2.85254 15.4421 3.75C15.4421 4.64746 14.6786 5.375 13.7368 5.375ZM16.124 6.07114C16.7555 5.48128 17.1473 4.65924 17.1473 3.75C17.1473 1.95507 15.6204 0.5 13.7368 0.5C11.8532 0.5 10.3263 1.95507 10.3263 3.75C10.3263 4.65924 10.7181 5.48128 11.3496 6.07114C10.2176 6.8015 9.47363 8.0367 9.47363 9.4375V12.6875C9.47363 13.1362 9.85537 13.5 10.3263 13.5C10.7972 13.5 11.1789 13.1362 11.1789 12.6875V9.4375C11.1789 8.09131 12.3241 7 13.7368 7C15.1495 7 16.2947 8.09131 16.2947 9.4375V12.6875C16.2947 13.1362 16.6764 13.5 17.1473 13.5C17.6182 13.5 17.9999 13.1362 17.9999 12.6875V9.4375C17.9999 8.0367 17.256 6.8015 16.124 6.07114Z"
        />
    </React.Fragment>,
    'GroupIcon'
);
