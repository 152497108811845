import { ITheme, makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme: ITheme) => ({
    columnEditor: {
        marginBottom: '2rem',
    },
    filters: {
        maxWidth: '300px',
        display: 'flex',
        gap: '1rem',
    },
}));

export default useStyles;
