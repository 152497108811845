import { useEffect, useState } from 'react';
import React from 'react';
import { IconButton, LoadingIndicator, CloseIcon, MicOnIcon, MicOffIcon } from '@brivo/react-components';
import clsx from 'clsx';
import {
    CardHeader,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    Grid,
    Button,
    Typography,
} from '@brivo/react-components';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { UNLOCKED_STATE } from '../../../../../constants/Constants';

const VideoModal = ({
    isOpen,
    quitVideo,
    remoteViewRef,
    localViewRef,
    message,
    onClickUnlock,
    onToggleAudio,
    showPoster,
    videoError,
    lockState,
}) => {
    const { t } = useTranslation();
    const classes = styles();
    const [unlockButtonStyle, setUnlockButtonStyle] = React.useState(classes.btn);
    const [unlockButtonText, setUnlockButtonText] = React.useState('');
    const [loadingBar, setLoadingBar] = React.useState(<></>);
    const [videoLoaded, setVideoLoaded] = React.useState(false);
    const parsedMessage = message.data ? JSON.parse(message.data.details) : {};
    let timer;

    const handleClose = () => {
        setPhoneAnswered(true);
        setUnlockButtonStyle(classes.btn);
        setUnlockButtonText(t('notifications.call.snackbar.button.unlock'));
        setLoadingBar(<></>);
        setVideoLoaded(false);
        quitVideo();
    };
    const [phoneAnswered, setPhoneAnswered] = useState(true);
    const togglePhone = () => {
        onToggleAudio();
        setPhoneAnswered(!phoneAnswered);
    };

    const unlockDoor = async () => {
        setLoadingBar(<></>);
        setUnlockButtonStyle(classes.loading);
        const unlockDoorAttempt = await onClickUnlock('', message);
        setUnlockButtonStyle(`${classes.btn} ${classes.unlockAttempted}`);

        if (!unlockDoorAttempt) {
            setUnlockButtonText(t('notifications.call.snackbar.button.unlockFailed'));
            setLoadingBar(<div className={`${classes.loadingLine} ${classes.loadingLineFailure}`} />);
        } else {
            setUnlockButtonText(t('notifications.call.snackbar.button.unlockSuccess'));
            setLoadingBar(<div className={`${classes.loadingLine} ${classes.loadingLineSuccess}`} />);
            timer = setTimeout(() => {
                setUnlockButtonText(t('notifications.call.snackbar.button.unlock'));
                setUnlockButtonStyle(classes.btn);
                setLoadingBar(<></>);
            }, '5000');
        }
    };

    useEffect(() => {
        if (lockState === UNLOCKED_STATE) {
            setUnlockButtonText(t('notifications.call.snackbar.button.unlockSuccess'));
            setUnlockButtonStyle(`${classes.btn} ${classes.unlockAttempted}`);
            setLoadingBar(<div className={`${classes.loadingLine} ${classes.loadingLineSuccess}`} />);
            clearTimeout(timer);
        } else {
            setUnlockButtonText(t('notifications.call.snackbar.button.unlock'));
            setUnlockButtonStyle(classes.btn);
            setLoadingBar(<></>);
            clearTimeout(timer);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [lockState, classes.btn, classes.loadingLine, classes.loadingLineSuccess, classes.unlockAttempted, t, timer]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            className={classes.videoDialog}
        >
            {isOpen && (
                <DialogContent className={classes.dialogContent}>
                    <Card id="video-feed-dialog">
                        <CardHeader
                            className={clsx(classes.header, 'u-pr-half')}
                            action={
                                <IconButton
                                    onClick={handleClose}
                                    icon={
                                        <CloseIcon
                                            data-testid="close-video-feed-dialog-button"
                                            className={classes.menuIcon}
                                        />
                                    }
                                    className={classes.iconButton}
                                />
                            }
                        />
                        <CardContent className={classes.videoGrid}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-around"
                                alignItems="stretch"
                                spacing={0}
                                className={classes.videoGrid}
                            >
                                <Grid item xs={12}>
                                    <Grid item container spacing={0}>
                                        <Grid item>
                                            <video
                                                ref={localViewRef}
                                                playsInline
                                                disablePictureInPicture
                                                autoPlay
                                                className={classes.hiddenLocalVideo}
                                                muted
                                            ></video>
                                        </Grid>
                                        {showPoster ? (
                                            videoError ? (
                                                <Grid item xs={12}>
                                                    <div className={classes.videoError}>
                                                        <Typography>
                                                            {t('Page.device-status.door-modal.vide-feed.error')}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12}>
                                                    <video
                                                        ref={remoteViewRef}
                                                        playsInline
                                                        disablePictureInPicture
                                                        autoPlay
                                                        className={classes.displayedVideo}
                                                        poster={'/black-background.jpeg'}
                                                        onLoadedData={() => {
                                                            setVideoLoaded(true);
                                                        }}
                                                    ></video>
                                                    {!videoLoaded && (
                                                        <div className={classes.loadingVideo}>
                                                            <LoadingIndicator />
                                                        </div>
                                                    )}
                                                </Grid>
                                            )
                                        ) : (
                                            <Grid item xs={12}>
                                                {/* card media component used to remove scroll*/}
                                                <CardMedia
                                                    component="video"
                                                    className={classes.displayedVideo}
                                                    ref={remoteViewRef}
                                                    playsInline
                                                    disablePictureInPicture //intentionally does not work in firefox https://bugzilla.mozilla.org/show_bug.cgi?id=1611831#c5
                                                    autoPlay
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                className={classes.infoGrid}
                            >
                                <Grid item>
                                    <h4 className={classes.doorInfo}>
                                        <span className={classes.doorName}>{parsedMessage.doorName}</span>
                                        <span className={classes.timeStamp}>
                                            {format(parsedMessage.timeStamp, 'hh:mm:ss a')}
                                        </span>
                                    </h4>
                                </Grid>
                                <Grid item>
                                    {unlockButtonStyle === classes.loading ? (
                                        <Grid
                                            container
                                            className={classes.loadingContainer}
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <div className={classes.loadingIndicator}>
                                                    <LoadingIndicator shouldHavePadding={false} size={'24'} />
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.unlockMessage}>
                                                    {t('notifications.call.snackbar.unlockingMessage')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <div className={unlockButtonStyle}>
                                                <Button
                                                    type="secondary"
                                                    customWidth="100%"
                                                    onClick={unlockDoor}
                                                    disabled={
                                                        unlockButtonText ===
                                                        t('notifications.call.snackbar.button.unlockSuccess')
                                                    }
                                                    text={unlockButtonText}
                                                ></Button>
                                            </div>
                                            {loadingBar}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <IconButton
                        icon={phoneAnswered ? <MicOnIcon /> : <MicOffIcon className={classes.micOffIcon} />}
                        onClick={togglePhone}
                        className={classes.audioCallButton}
                    />
                </DialogContent>
            )}
        </Dialog>
    );
};
export default VideoModal;
