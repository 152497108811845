import { Suspense, lazy } from 'react';

const Integrations = lazy(() => import('./Integrations'));

const IntegrationsContainer = (props) => (
    <Suspense fallback={null}>
        <Integrations {...props} />
    </Suspense>
);

export default IntegrationsContainer;
