import React from 'react';
import styles from './styles';
import { useFormatLocale } from '../../hooks/useFormatLocale';

const TimeDate = ({ dateValue }) => {
    const classes = styles();
    const { formatDate, formatTime } = useFormatLocale();

    let formattedDate = new Date(dateValue);
    const time = formatTime(formattedDate);
    const date = formatDate(formattedDate);

    return (
        <div className="TimeDate">
            <div className={classes.timestampCell}>
                <span>{time}</span>
                <span className={classes.date}>{date}</span>
            </div>
        </div>
    );
};

export default TimeDate;
