import React from 'react';
import createSvgIcon from '../../../../../../../../../../../../common/utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33333 1.66675C2.8731 1.66675 2.5 2.03984 2.5 2.50008V17.5001C2.5 17.9603 2.8731 18.3334 3.33333 18.3334C3.79357 18.3334 4.16667 17.9603 4.16667 17.5001V3.33341H9.16667V10.0001V17.5001C9.16667 17.9603 9.53976 18.3334 10 18.3334C10.4602 18.3334 10.8333 17.9603 10.8333 17.5001V10.8334H15.8333V17.5001C15.8333 17.9603 16.2064 18.3334 16.6667 18.3334C17.1269 18.3334 17.5 17.9603 17.5 17.5001V10.0001C17.5 9.53984 17.1269 9.16675 16.6667 9.16675H10.8333V2.50008C10.8333 2.03984 10.4602 1.66675 10 1.66675H3.33333ZM6.25 5.00008C5.78976 5.00008 5.41667 5.37318 5.41667 5.83342V6.66675C5.41667 7.12699 5.78976 7.50008 6.25 7.50008H7.08333C7.54357 7.50008 7.91667 7.12699 7.91667 6.66675V5.83342C7.91667 5.37318 7.54357 5.00008 7.08333 5.00008H6.25ZM5.41667 10.0001C5.41667 9.53984 5.78976 9.16675 6.25 9.16675H7.08333C7.54357 9.16675 7.91667 9.53984 7.91667 10.0001V10.8334C7.91667 11.2937 7.54357 11.6667 7.08333 11.6667H6.25C5.78976 11.6667 5.41667 11.2937 5.41667 10.8334V10.0001ZM6.25 13.3334C5.78976 13.3334 5.41667 13.7065 5.41667 14.1667V15.0001C5.41667 15.4603 5.78976 15.8334 6.25 15.8334H7.08333C7.54357 15.8334 7.91667 15.4603 7.91667 15.0001V14.1667C7.91667 13.7065 7.54357 13.3334 7.08333 13.3334H6.25ZM12.9167 12.5001C12.4564 12.5001 12.0833 12.8732 12.0833 13.3334V14.1667C12.0833 14.627 12.4564 15.0001 12.9167 15.0001H13.75C14.2102 15.0001 14.5833 14.627 14.5833 14.1667V13.3334C14.5833 12.8732 14.2102 12.5001 13.75 12.5001H12.9167Z"
                fill="#CED7E2"
            />
        </svg>
    </React.Fragment>,
    'SiteIconNew'
);
