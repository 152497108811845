import createSvgIcon from '../../../common/utils/createSvgIcon';

const KPIMetricsIcon = createSvgIcon(
    <>
        <path
            fill="#29394B"
            d="M53.119 18.15c0-.54.462-.98 1.032-.98h9.801c.57 0 1.032.44 1.032.98V51.02c0 .542-.462.981-1.032.981h-9.801c-.57 0-1.032-.44-1.032-.981V18.15ZM18.556 24.528c0-.542.461-.98 1.031-.98h9.802c.57 0 1.032.438 1.032.98v26.49c0 .543-.462.982-1.032.982h-9.802c-.57 0-1.031-.44-1.031-.981v-26.49Z"
        />
        <path
            fill="#F1D856"
            d="M36.095 30.415c0-.542.462-.981 1.032-.981h9.802c.57 0 1.031.44 1.031.981V51.02c0 .542-.462.981-1.031.981h-9.802c-.57 0-1.032-.44-1.032-.981V30.415Z"
        />
        <path
            fill="#29394B"
            d="M1.016 36.302c0-.542.462-.981 1.032-.981h9.801c.57 0 1.032.439 1.032.98V51.02c0 .542-.462.981-1.032.981H2.048c-.57 0-1.032-.44-1.032-.981V36.302Z"
        />
        <path
            fill="#29394B"
            fillRule="evenodd"
            d="M65.5 5.887c0 3.251-2.772 5.887-6.19 5.887a6.343 6.343 0 0 1-4.246-1.603l-7.46 5.518c.23.616.356 1.28.356 1.971 0 3.252-2.771 5.887-6.19 5.887-3.42 0-6.19-2.635-6.19-5.887 0-.234.014-.466.042-.693l-6.314-2.315c-1.12 1.522-2.976 2.518-5.078 2.518a6.362 6.362 0 0 1-3.934-1.342l-7.77 5.748c.23.616.355 1.28.355 1.971 0 3.251-2.772 5.887-6.19 5.887C3.27 29.434.5 26.798.5 23.547c0-3.251 2.772-5.887 6.19-5.887 1.758 0 3.345.697 4.472 1.816l7.527-5.566a5.63 5.63 0 0 1-.65-2.627c0-3.251 2.772-5.887 6.191-5.887 3.42 0 6.19 2.636 6.19 5.887 0 .379-.037.749-.109 1.108l6.142 2.252c1.081-1.719 3.058-2.87 5.317-2.87 1.758 0 3.345.697 4.471 1.816l7.353-5.437a5.62 5.62 0 0 1-.475-2.265c0-3.251 2.772-5.887 6.19-5.887C62.73 0 65.5 2.636 65.5 5.887Zm-3.095 0c0 1.625-1.386 2.943-3.096 2.943-1.709 0-3.095-1.318-3.095-2.943 0-1.626 1.386-2.944 3.096-2.944 1.709 0 3.095 1.318 3.095 2.944Zm-35.08 5.396c0 1.626-1.385 2.943-3.095 2.943-1.71 0-3.095-1.317-3.095-2.943 0-1.626 1.386-2.943 3.095-2.943 1.71 0 3.095 1.317 3.095 2.943ZM6.69 26.491c1.71 0 3.096-1.318 3.096-2.944 0-1.625-1.386-2.943-3.096-2.943-1.709 0-3.095 1.318-3.095 2.943 0 1.626 1.386 2.944 3.095 2.944Zm35.08-5.887c1.71 0 3.095-1.318 3.095-2.944 0-1.625-1.386-2.943-3.095-2.943-1.71 0-3.095 1.318-3.095 2.943 0 1.626 1.385 2.944 3.095 2.944Z"
            clipRule="evenodd"
        />
    </>,
    'KPIMetricsWidgetIcon'
);

const KPIMetricsWidgetIcon = <KPIMetricsIcon style={{ height: '52px', width: '66px' }} viewBox="0 0 66 52" />;

export default KPIMetricsWidgetIcon;
