import { WebReqApi } from '../WebReqApi';
import { GET_HOLIDAYS_FOR_JOURNAL } from './gqlTags';
import { API_BASE_URL } from '@common/constants/Constants';
import { restClient } from '../RestClient';

class HolidaysApi extends WebReqApi {
    getHolidaysForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_HOLIDAYS_FOR_JOURNAL,
            variables: {
                name,
                rows,
            },
            defaultValue: [],
        });
    }

    getPagedHolidays(offset, pageSize) {
        return restClient.makeRequest({
            method: 'GET',
            url: `${API_BASE_URL}/holidays`,
            queryParams: {
                offset,
                pageSize,
            },
        });
    }

    async getAllHolidays() {
        return this.fetchAllData(this.getPagedHolidays);
    }

    fetchAllData = async (api, params) => {
        let data = [];
        let offset = 0;
        const pageSize = 100;
        let keepFetching = true;
        while (keepFetching) {
            const res = params ? await api(offset, pageSize, ...params) : await api(offset, pageSize);
            if (res.data.length === 0) {
                keepFetching = false;
            } else {
                data = [...data, ...res.data];
                offset += pageSize;
                if (data.length === res.count) {
                    keepFetching = false;
                }
            }
        }
        return data;
    };
}

export const holidaysApi = new HolidaysApi();
