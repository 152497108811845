import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    section: {
        paddingTop: '2.5rem',
        paddingBottom: '2.5rem',
        height: '8.875rem',
        textAlign: 'center',
        background:
            theme.palette.type === 'dark'
                ? 'linear-gradient(180deg, #232D4E 0%, rgba(24, 29, 45, 0) 95.27%)'
                : theme.palette.colors.grey0,

        '& svg g:last-child': {
            fill: theme.palette.brivoAliases.newNavUserNameColor,
        },
    },
    brandLogo: {
        width: '9.6875rem',
        height: '2.5rem',
    },
    appName: {
        color: theme.palette.type === 'dark' ? theme.palette.colors.blue400 : theme.palette.colors.blue800,
    },
}));

export default styles;
