import { startOfDay, endOfDay } from 'date-fns';
import { cloneDeep } from 'lodash';
import { defaultEventsTypeValues } from '../../../EventTracker/eventTrackerUtils';

import { allVideoLinkableEvents, videoClipCameraEvents } from '../../../../common/constants/Constants';

const defaultConfig = {
    warning: [],
    critical: [],
    info: [],
};

export const getEvents = async ({
    eventsApi,
    sites,
    date,
    startTime,
    endTime,
    showCameraEvents,
    securityActionIds,
    camerasWithAssociatedDevices,
}) => {
    //TODO, note, largly copied from EventTrackerPage. We need to consider abstracting/sharing, but for now, cram it in here
    eventsApi.setEventsRanking(defaultConfig);
    let fromDate;
    let tillDate;
    if (startTime && endTime) {
        fromDate = new Date(startTime);
        tillDate = new Date(endTime);
    } else {
        fromDate = startOfDay(date ? date : new Date());
        tillDate = endOfDay(date ? date : new Date());
    }
    const currentPromise = eventsApi.findAll(
        fromDate,
        tillDate,
        500, //limit
        null, //timeseries
        sites, //sites
        null, //user
        null, //devices
        showCameraEvents,
        true, //excludeEventsWithoutVideo - BDS clips will be excluded for now
        defaultEventsTypeValues, //selectedEventSeverities
        securityActionIds, //selectedEvents
        '', //nextToken
        null //hiddenEvents
    );
    //this.lastPromiseRef.current = currentPromise;

    const { events } = await currentPromise;

    return fetchEventsWithClips(events, camerasWithAssociatedDevices);
};

//adapted from event tracker
//TODO - we probably do not need the deviceCameras logic here
//make this function pure

export const fetchEventsWithClips = (events = [], camerasWithAssociatedDevices, isV2 = false) => {
    let filteredEvents = [];
    events.forEach((event) => {
        const clonedEvent = cloneDeep(event);
        if (
            !allVideoLinkableEvents.includes(clonedEvent.securityActionId) &&
            !videoClipCameraEvents.includes(clonedEvent.securityActionId)
        ) {
            return false;
        } else if (videoClipCameraEvents.includes(clonedEvent.securityActionId)) {
            clonedEvent.deviceCameras = [{ id: clonedEvent.objectId, name: clonedEvent.objectName }];
        } else if (allVideoLinkableEvents.includes(clonedEvent.securityActionId)) {
            if (camerasWithAssociatedDevices?.length) {
                const camerasWithFoundAssociatedDevice = camerasWithAssociatedDevices?.filter((camera) =>
                    camera.associated_devices.find((associated_device) => {
                        if (isV2) {
                            return clonedEvent.objectId === associated_device;
                        }

                        return clonedEvent.objectId === associated_device.device_object_id;
                    })
                );
                if (camerasWithFoundAssociatedDevice.length) {
                    //TODO - only pull the camera id, name.
                    clonedEvent.deviceCameras = camerasWithFoundAssociatedDevice.reduce(
                        (deviceCameras, camera) => [...deviceCameras, camera],
                        []
                    );
                }
            }
        }

        const { precachedClips = [] } = clonedEvent?.enrichmentMetadata ?? {};
        //for events with VRB, filter our VRB clips until we support them
        if (precachedClips?.length) {
            precachedClips.filter((clip) => clip.cameraId !== 0);
        }
        if (clonedEvent.deviceCameras?.length) {
            filteredEvents.push(clonedEvent);
        }
        return true;
    });
    return filteredEvents;
};

export const filterEventsForSpecificCamera = (events, cameraObjectId) => {
    if (cameraObjectId === null || cameraObjectId === undefined || cameraObjectId === '') {
        throw new Error(`cannot filter events without specifying camera object id. received: ${cameraObjectId}`);
    }

    return (
        events?.filter((event) => {
            return event.deviceCameras?.find((camera) => camera.id === cameraObjectId);
        }) ?? []
    );
};
