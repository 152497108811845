/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    Typography,
    GenericDialog,
    AccountInfoIcon,
    TextField,
    Button,
    IconButton,
    CloseIcon,
    DialogActions,
} from '@brivo/react-components';

import useStyles from './styles';

const ExportDialog = ({ isOpen, closeDialog, downloadReport, fileName, inputPlaceholder }) => {
    const classes = useStyles();
    const [hasUserEnteredInput, toggleHasUserEnteredInput] = useState(false);
    const [inputText, setInputText] = useState('');
    const { t } = useTranslation();
    const [downloadButtonActive, setDownloadButtonActiveStatus] = useState(!!fileName);

    const submitForm = () => {
        downloadReport(inputText);
        setDownloadButtonActiveStatus(false);
    };

    const dialogClosingActions = () => {
        if (typeof closeDialog === 'function') {
            closeDialog();
        }
    };

    const checkFilename = () => {
        const value = inputText;
        const errors = {};
        if (typeof value === 'string' && value?.length > 0) {
            setDownloadButtonActiveStatus(true);
        } else {
            setDownloadButtonActiveStatus(false);
            errors.fileName = t('Page.brivo-analytics.drilldown-table.download-dialog.error-text');
        }
        return errors;
    };

    const handleInputChange = (event) => {
        const newFileName = event.target.value;
        toggleHasUserEnteredInput(true);
        setInputText(newFileName);
    };

    useEffect(() => {
        if (hasUserEnteredInput) checkFilename();
    }, [inputText, hasUserEnteredInput]);

    return (
        <GenericDialog
            open={isOpen}
            avatarIcon={<AccountInfoIcon />}
            title={t('Page.brivo-analytics.drilldown-table.download-dialog.title')}
        >
            {/* TODO: Refactor this later to not use Formik; use and update NameInput to handle for starting with empty text values*/}
            <Formik initialValues={{ fileName }} enableReinitialize onSubmit={submitForm}>
                <Form>
                    <Grid container direction="column" spacing={2}>
                        <IconButton
                            className={classes.closeButton}
                            onClick={dialogClosingActions}
                            icon={<CloseIcon />}
                        />
                        <Grid item>
                            <TextField
                                id="fileName"
                                name="fileName"
                                fullWidth
                                label={t('Page.brivo-analytics.drilldown-table.download-dialog.file-name-label')}
                                placeholder={
                                    inputPlaceholder
                                        ? inputPlaceholder
                                        : t(
                                              'Page.brivo-analytics.drilldown-table.download-dialog.text-field-placeholder'
                                          )
                                }
                                autoFocus={true}
                                onChange={handleInputChange}
                                value={inputText}
                                error={hasUserEnteredInput && !downloadButtonActive}
                                helperText={
                                    hasUserEnteredInput &&
                                    !downloadButtonActive &&
                                    t('Page.brivo-analytics.drilldown-table.download-dialog.error-text')
                                }
                            />
                        </Grid>
                        <Typography className={classes.formatMessage}>
                            {t('Page.brivo-analytics.drilldown-table.download-dialog.file-format-label')}: CSV
                        </Typography>
                        <DialogActions classes={{ root: classes.dialogAction }}>
                            <Button
                                size="small"
                                type="secondary"
                                text={t('Page.brivo-analytics.drilldown-table.download-dialog.close-button')}
                                id="secondarybutton"
                                onClick={dialogClosingActions}
                            />
                            <Button
                                size="small"
                                text={t('Page.brivo-analytics.drilldown-table.download-dialog.download-button')}
                                id="primarybutton"
                                onClick={submitForm}
                                disabled={!downloadButtonActive}
                            />
                        </DialogActions>
                    </Grid>
                </Form>
            </Formik>
        </GenericDialog>
    );
};

export default ExportDialog;
