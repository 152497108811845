import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useFlagClient } from '@brivo/onairplus-services';
import { USER_PAGE_SIZE } from '../helpers';

const useListData = (
    initialFiltersValue,
    apiEndpoint,
    sortField,
    requestFilters,
    resultProperty,
    PAGE_SIZE,
    loadAll = false,
    executeCalls = true,
    hasPagination = false
) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(-1);
    const [totalCount, setTotalCount] = useState(-1);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState(initialFiltersValue);
    const [sortAsc, setSortAsc] = useState(true);
    const [requestedGqlFilters, setRequestedGqlFilters] = useState(null);
    const lastPromise = useRef();
    const { t } = useTranslation();
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios', false);

    const loadNextPage = () => {
        if (executeCalls && !loading && count >= offset + PAGE_SIZE) {
            setLoading(true);
            setOffset(offset + PAGE_SIZE);
        }
    };

    const loadPage = (index) => {
        if (executeCalls && !loading && (index - 1) * USER_PAGE_SIZE != offset) {
            setLoading(true);
            setOffset((index - 1) * USER_PAGE_SIZE);
        }
    };

    const reloadData = async () => {
        if (executeCalls && requestedGqlFilters) {
            setData([]);
            setCount(-1);
            setLoading(true);
            if (offset === 0) {
                const currentPromise = apiEndpoint(
                    PAGE_SIZE,
                    offset,
                    sortField,
                    sortAsc ? 'ASC' : 'DESC',
                    requestedGqlFilters,
                    emergencyScenariosFlag,
                    t
                );
                lastPromise.current = currentPromise;
                currentPromise
                    .then((result) => {
                        if (currentPromise === lastPromise.current) {
                            setData((currentData) => currentData.concat(result[resultProperty]));
                            setCount(result.count);
                            setTotalCount(result.totalCount);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.error('Error during reloadData:', error);
                    });
            } else {
                setOffset(0);
            }
        }
    };

    useEffect(() => {
        if (executeCalls && requestedGqlFilters) {
            setLoading(true);
            (async function () {
                const currentPromise = apiEndpoint(
                    PAGE_SIZE,
                    offset,
                    sortField,
                    sortAsc ? 'ASC' : 'DESC',
                    requestedGqlFilters,
                    emergencyScenariosFlag,
                    t
                );
                lastPromise.current = currentPromise;
                currentPromise
                    .then((result) => {
                        if (currentPromise === lastPromise.current) {
                            if (!hasPagination) {
                                setData((currentUsers) => {
                                    return currentUsers.concat(result?.[resultProperty]);
                                });
                                setCount(result?.count);
                                setTotalCount(result?.totalCount);
                                if (loadAll && result?.count >= offset + PAGE_SIZE) {
                                    setOffset(offset + PAGE_SIZE);
                                } else {
                                    setLoading(false);
                                }
                            } else {
                                setData(result?.[resultProperty]);
                                setCount(result?.count);
                                setTotalCount(result?.totalCount);
                                setLoading(false);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error('Error during load data:', error);
                    });
            })();
        }
    }, [
        offset,
        sortAsc,
        requestedGqlFilters,
        apiEndpoint,
        resultProperty,
        sortField,
        PAGE_SIZE,
        loadAll,
        executeCalls,
        emergencyScenariosFlag,
        t,
        hasPagination,
    ]);

    useEffect(() => {
        setRequestedGqlFilters((prevValue) => {
            if (isEqual(prevValue, requestFilters(filters))) {
                return prevValue;
            }
            setCount(-1);
            setData([]);
            setOffset(0);
            setLoading(true);
            return requestFilters(filters);
        });
    }, [requestFilters, filters]);

    const changeSortDirection = () => {
        if (executeCalls && !loading) {
            setSortAsc(!sortAsc);
            setCount(-1);
            setData([]);
            setOffset(0);
            setLoading(true);
        }
    };

    const handleFiltersRestore = () => {
        if (executeCalls) {
            setCount(-1);
            setData([]);
            setOffset(0);
            setLoading(true);
            setFilters(initialFiltersValue);
        }
    };

    const handleFiltersChange = useCallback(
        (value) => {
            if (executeCalls) {
                setFilters(value);
            }
        },
        [executeCalls]
    );

    return [
        data,
        count,
        loadNextPage,
        sortAsc,
        changeSortDirection,
        loading,
        reloadData,
        filters,
        handleFiltersChange,
        handleFiltersRestore,
        totalCount,
        loadPage,
    ];
};

export default useListData;

// Use the following snippets to wrap the promises and simulate out of order requests at filtering

// let timeouts = [0, 5000, 3000, 1000];
// let currentTimeout = 0;
//
// const delayPromise = () => {
//     if (currentTimeout > 3) {
//         currentTimeout = 0;
//     }
//     currentTimeout++;
//     return new Promise(resolve =>
//         setTimeout(resolve, timeouts[currentTimeout - 1])
//     );
// };
//
// const promiseWrapper = async p => {
//     let res = await p;
//     await delayPromise();
//     return Promise.resolve(res);
// };
