import React, { useEffect } from 'react';
import { LoadingIndicator } from '@brivo/react-components';

const SsoLogin = ({ auth, connectionKey }) => {
    useEffect(() => {
        (async () => {
            const response = await fetch(`/api/sso/${connectionKey}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const enterpriseDetails = await response.json();
            auth.login({ enterpriseDetails });
        })();
    }, [auth, connectionKey]);

    return <LoadingIndicator />;
};

export default SsoLogin;
