import {
    EDITION_DEMO,
    EDITION_PROFESSIONAL,
    EDITION_ENTERPRISE,
    EDITION_MULTIFAMILY,
} from '../../../common/constants/Constants';

export const DEVICE_STATUS = [{ key: 'CONNECTED' }, { key: 'DISCONNECTED' }, { key: 'UNKNOWN' }];

export const DEFAULT_FILTERS = {
    selectedSites: [],
    selectedDevices: [],
    selectedStatuses: [],
    selectedSingleSite: null,
};

export const SINGLE_SITE_DEFAULT_FILTERS = {
    selectedDevices: [],
    selectedStatuses: [],
    selectedSingleSite: null,
};

export const DEFAULT_ELEVATOR_SCHEDULE_TYPES = [
    {
        id: 1,
        type: 'CARD_REQUIRED',
        typeName: 'Page.device-status.door-schedule-status-type.cardRequired',
    },
    {
        id: 2,
        type: 'TWO_FACTOR',
        typeName: 'Page.device-status.door-schedule-status-type.twoFactor',
    },
];

export const DEFAULT_FLOOR_SCHEDULE_TYPE = [
    {
        id: 3,
        type: 'UNLOCKED',
        typeName: 'Page.device-status.door-schedule-status-type.unlocked',
    },
];

export const LOCK_STATE_OPTIONS = {
    Locked: 'Page.device-status.device-info.LOCKED',
    Unlocked: 'Page.device-status.device-info.UNLOCKED',
    'Status currently unknown': 'Page.device-status.device-info.STATUS_CURRENTLY_UNKNOWN',
    'N/A': 'Page.device-status.device-info.NA',
    Unknown: 'Page.device-status.device-info.UNKNOWN',
    'No status updates received yet': 'Page.device-status.device-info.NO_STATUS_UPDATES_RECEIVED_YET',
};

export const DOOR_POSITION_OPTIONS = {
    Open: 'Page.device-status.device-info.OPEN',
    Closed: 'Page.device-status.device-info.CLOSED',
    'N/A': 'Page.device-status.device-info.NA',
    Unknown: 'Page.device-status.device-info.UNKNOWN',
};

export const DOOR_STATUS_OPTIONS = {
    Unknown: 'Page.device-status.device-info.UNKNOWN',
    'Not Operating Normally': 'Page.device-status.device-info.NOT_OPERATING_NORMALLY',
    'Operating Normally': 'Page.device-status.device-info.OPERATING_NORMALLY',
    'Locked Down': 'Page.device-status.device-info.LOCKED_DOWN',
    Offline: 'Page.device-status.device-info.OFFLINE',
};

export const READER_STATUS_OPTIONS = {
    Online: 'Page.device-status.device-info.ONLINE',
    Offline: 'Page.device-status.device-info.OFFLINE',
    'N/A': 'Page.device-status.device-info.NA',
};

export const NETWORK_STATUS_OPTIONS = {
    Online: 'Page.device-status.device-info.ONLINE',
    Offline: 'Page.device-status.device-info.OFFLINE',
    'N/A': 'Page.device-status.device-info.NA',
};

export const CALL_ELEVATOR_ALLOWED_EDITIONS = [
    EDITION_DEMO,
    EDITION_PROFESSIONAL,
    EDITION_ENTERPRISE,
    EDITION_MULTIFAMILY,
];
