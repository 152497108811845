import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Snackbar } from '@brivo/react-components';

import { setStatusOnAccessPoint } from '../../../DeviceStatus/utils/deviceStatusUtils';

function cameraAccessPointReducer(state, action) {
    switch (action.type) {
        case 'setAccessPoints':
            return {
                ...state,
                accessPoints: action.value,
            };
        case 'setAccessPointsForCamera':
            return {
                ...state,
                accessPointsForCamera: action.value,
            };
        case 'loading':
            return {
                ...state,
                loading: action.value,
            };
        default:
            break;
    }
}

const initialState = {
    accessPointsForCamera: [],
    accessPoints: [],
    loading: true,
};

export const useAccessPointsData = (cameraId, api, doorStatusData) => {
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(cameraAccessPointReducer, initialState);
    const notifications = Snackbar();
    const { accessPointsForCamera, accessPoints } = state;

    useEffect(() => {
        const getAccessPoints = async () => {
            if (cameraId) {
                dispatch({ type: 'loading', value: true });
                try {
                    const result = await api.getCameraAccessPoints(cameraId);
                    dispatch({
                        type: 'setAccessPoints',
                        value: get(result, 'data', []),
                    });
                } catch (e) {
                    console.error(e);
                }
                dispatch({ type: 'loading', value: false });
            }
        };
        getAccessPoints();
    }, [cameraId, api]);

    useEffect(() => {
        const accessPointsWithStatus = setStatusOnAccessPoint(accessPoints, doorStatusData);
        dispatch({
            type: 'setAccessPointsForCamera',
            value: accessPointsWithStatus,
        });
    }, [accessPoints, doorStatusData]);

    const changeDoorLockState = (doorId, lockState) => {
        //change the status of the camera until a new doorStatus
        const newAccessPoints = accessPointsForCamera?.map((accessPoint) => {
            if (accessPoint.id === doorId) {
                accessPoint.status.lockState = lockState;
            }
            return accessPoint;
        });
        dispatch({
            type: 'setAccessPointsForCamera',
            value: newAccessPoints,
        });
    };

    const handleEnableMonitoringForDoor = async (doorId) => {
        const res = await api.enableMonitoring(doorId);

        if (res) {
            const newAccessPoints = accessPointsForCamera?.map((accessPoint) => {
                if (accessPoint.id === doorId) {
                    if (!accessPoint.status) accessPoint.status = {};
                    accessPoint.reportLiveStatus = res;
                    accessPoint.status.reportLiveStatus = res;
                    accessPoint.status.isActive = true;
                }

                return accessPoint;
            });

            dispatch({
                type: 'setAccessPointsForCamera',
                value: newAccessPoints,
            });
        }
    };

    const handleUnlockDoor = async (doorId) => {
        changeDoorLockState(doorId, 'Unlocked');
        const accessPoint = accessPointsForCamera.find((e) => e.id === doorId);
        const doorName = accessPoint ? accessPoint.name : '';
        const result = await api.unlockDoor(doorId);
        if (result) {
            notifications.addSuccessMessage({
                text: t(`Page.device-status.dialog.notification.success-message`, { doorName }),
            });
        } else {
            notifications.addErrorMessage({
                text: t(`Page.device-status.dialog.notification.error-message`, { doorName }),
            });
        }

        setTimeout(function () {
            changeDoorLockState(doorId, 'Locked');
        }, 2500);
    };

    return [accessPointsForCamera, handleEnableMonitoringForDoor, handleUnlockDoor];
};
