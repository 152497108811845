import { makeStyles } from '@brivo/react-components';

const styles = makeStyles(() => ({
    dialog: {
        position: 'absolute',
        margin: 0,
        left: 10,
        top: 10,
    },
    content: {
        width: '500px',
        padding: '20px',
        backgroundColor: 'white',
    },
    titleText: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        color: 'black',
    },
    titleSection: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '20px',
        alignItems: 'baseline',
    },
    text: {
        color: 'black',
        paddingBottom: '20px',
    },
}));

export default styles;
