import React, { useEffect, useState, useRef } from 'react';
import { buildGqlFilters, initialFilters } from '../../helpers';

import { GQLProviderContext } from '../../../../../common/utils/CustomApolloProvider';

const DEFAULT_PAGE_SIZE = 200;

export const useGetAllTriggers = ({ api, defaultValues, assignedItemsFromApi, showEgressScenarios = false }) => {
    const gqlHelper = React.useContext(GQLProviderContext);
    const [allLoadedItems, setAllLoadedItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [unassignedItemsFromApi, setUnassignedItemsFromApi] = useState([]);
    const [allSites, setAllSites] = useState(null);
    const [totalCount, setTotalCount] = useState(defaultValues?.length);
    const [count, setCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState(initialFilters);
    const lastPromise = useRef();

    const loadNextPage = async () => {
        if (!showEgressScenarios && !isLoading && count >= offset + DEFAULT_PAGE_SIZE) {
            setIsLoading(true);
            setOffset(offset + DEFAULT_PAGE_SIZE);
        }
    };

    const handleFiltersChange = (value, selectedItems) => {
        setOffset(0);
        setFilters(value);
        setSelectedItems(selectedItems);
        if (!showEgressScenarios) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        if (showEgressScenarios) {
            const currentPromise = api(null, null, 'name', 'ASC', []);
            lastPromise.current = currentPromise;
            currentPromise.then((result) => {
                if (currentPromise === lastPromise.current) {
                    const { lockdownTriggers: resultUnassignedTriggersFromApi } = result;
                    const computedTotalCount =
                        (resultUnassignedTriggersFromApi?.length ?? 0) + (assignedItemsFromApi?.length ?? 0);
                    setUnassignedItemsFromApi(resultUnassignedTriggersFromApi);
                    setTotalCount(computedTotalCount);
                    setIsLoading(false);
                }
            });
        }
    }, [api, assignedItemsFromApi.length, showEgressScenarios]);

    useEffect(() => {
        if (showEgressScenarios) {
            setCount(allLoadedItems?.length);
        }
    }, [allLoadedItems.length, showEgressScenarios]);

    useEffect(() => {
        if (!showEgressScenarios) {
            const currentPromise = api(DEFAULT_PAGE_SIZE, offset, 'name', 'ASC', buildGqlFilters(filters));
            lastPromise.current = currentPromise;

            currentPromise.then((result) => {
                if (currentPromise === lastPromise.current) {
                    const { lockdownTriggers: allItems, count: resultCount, totalCount: resultTotalCount } = result;

                    //   filter out selected items
                    const selectedItemsIds = selectedItems.map((selection) => selection.id);
                    const allUnselectedItems = allItems.filter((item) => !selectedItemsIds.includes(item.id));

                    setAllLoadedItems([...selectedItems, ...allUnselectedItems]);
                    setTotalCount(resultTotalCount);
                    setCount(resultCount);
                    setIsLoading(false);
                }
            });
        }
    }, [api, assignedItemsFromApi, filters, offset, selectedItems, showEgressScenarios, unassignedItemsFromApi]);

    useEffect(() => {
        if (showEgressScenarios) {
            // remove selected items
            const selectedItemsIds = selectedItems.map((selection) => selection.id);
            let allUnselectedItems = unassignedItemsFromApi.filter((item) => !selectedItemsIds.includes(item.id));

            // add deselected items to list
            if (assignedItemsFromApi?.length) {
                const deselectedItems = assignedItemsFromApi.filter(
                    (value) => !selectedItems?.find((selectedItem) => selectedItem?.id === value?.id)
                );
                if (deselectedItems?.length) {
                    allUnselectedItems = [...deselectedItems, ...allUnselectedItems];
                    allUnselectedItems.sort((a, b) => a?.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
                }
            }

            let filteredUnselectedItems = allUnselectedItems.filter((item) =>
                item.name?.toLowerCase().includes(filters.name.toLowerCase())
            );
            const sitesFilter = filters.siteIds.map(({ id }) => id);
            if (sitesFilter?.length) {
                filteredUnselectedItems = filteredUnselectedItems.filter((item) => sitesFilter.includes(item.siteId));
            }
            setAllLoadedItems([...selectedItems, ...filteredUnselectedItems]);
        }
    }, [
        assignedItemsFromApi,
        filters.name,
        filters.siteIds,
        selectedItems,
        showEgressScenarios,
        unassignedItemsFromApi,
    ]);

    useEffect(() => {
        async function getSites() {
            const sites = await gqlHelper?.getSites({ includeGeocode: false });
            setAllSites(sites);
        }

        getSites();
    }, [gqlHelper]);

    return [allLoadedItems, allSites, isLoading, count, totalCount, filters, handleFiltersChange, loadNextPage];
};
