export const mapEventsRanking = (eventsRanking) => {
    let infoEvents = [];
    let warningEvents = [];
    let criticalEvents = [];
    const eventClasses = {
        informational: 'Informational',
        warning: 'Warning',
        critical: 'Critical',
    };

    eventsRanking.config.forEach((event) => {
        if (event.severity === eventClasses.critical) {
            criticalEvents.push(event.code);
        } else if (event.severity === eventClasses.warning) {
            warningEvents.push(event.code);
        } else {
            infoEvents.push(event.code);
        }
    });

    return { infoEvents, warningEvents, criticalEvents };
};
