import React, { Fragment } from 'react';
import PageHeader from '../../common/components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import RecordedVideoPage from './components/RecordedVideoPage';

//component hierarchy https://brivosys.atlassian.net/wiki/spaces/~657256764/pages/3349446671/Recorded+Video+Page+Component+Hierarchy+Proposal

const RecordedVideoContainer = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <PageHeader title={t('Page.recorded-video.title')} />
            <RecordedVideoPage />
        </Fragment>
    );
};

export default RecordedVideoContainer;
