import React from 'react';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import { MuiAvatar, makeStyles } from '@brivo/react-components';
import { Tooltip } from '@brivo/react-components';

export const useStyles = makeStyles((theme) => ({
    avatar: {
        width: '2rem',
        height: '2rem',
    },
    username: {
        paddingLeft: '0.75rem',
    },
    title: {
        fontSize: '0.75rem',
        color: theme.palette.colors.grey400,
    },
    usernameWrapper: {
        display: 'inline-flex',
        alignContent: 'center',
        alignItems: 'center',
        padding: '4px',
        width: '300px',
    },
    userLink: {
        '&:hover': {
            cursor: 'pointer',
            '& $avatar': {
                boxShadow: '0 0 0 3px #739DD333',
            },
            '& $username': {
                color: theme.palette.brivoAliases.fieldTextHover,
            },
        },
    },
    avatarSuspended: theme.palette.avatarSuspended,
    usernameSuspended: {
        color: theme.palette.type === 'dark' ? theme.palette.colors.grey0 : theme.palette.colors.grey1200,
    },
}));

export const UserCell = ({
    firstName,
    lastName,
    avatar,
    suspended,
    title,
    onClickHandler,
    isLast = false,
    loadMore,
    testId,
    className = '',
    id = '',
}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.usernameWrapper, className, 'u-trim', onClickHandler && classes.userLink)}
            onClick={(event) => {
                if (onClickHandler) {
                    event.stopPropagation();
                    onClickHandler();
                }
            }}
        >
            <MuiAvatar
                src={avatar}
                alt={`${firstName} ${lastName} avatar ${id}`}
                className={clsx(classes.avatar, {
                    [classes.avatarSuspended]: suspended,
                })}
            />
            <div className={clsx(classes.username, 'u-fx u-fx-column u-trim')}>
                <Tooltip title={`${lastName}, ${firstName}`} placement="top" size="medium">
                    <span
                        data-testid={testId}
                        className={clsx(
                            {
                                [classes.usernameSuspended]: suspended,
                            },
                            'u-trim'
                        )}
                    >
                        {lastName}, {firstName}
                    </span>
                </Tooltip>

                {title ? <span className={clsx(classes.title, 'u-trim')}>{title}</span> : null}
            </div>

            {isLast && loadMore ? <Waypoint key="loadMore" onEnter={loadMore} fireOnRapidScroll /> : null}
        </div>
    );
};
