import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { setEnableLogging } from './UserTracking';
import { APPLICATION_VERSION, AWS_APPSYNC_GRAPHQL_URL, DEPLOY_ENV, WEBSOCKET_URL } from '../constants/Constants';
import { useFlagClient } from '@brivo/onairplus-services';

export default function DataDogTracking() {
    const flagClient = useFlagClient();
    const appsyncUrl = new URL(AWS_APPSYNC_GRAPHQL_URL);
    const wsUrl = new URL(WEBSOCKET_URL);
    const currentLocation = window.location;

    console.log(`Enabling datadog for ${DEPLOY_ENV}`);
    datadogRum.init({
        applicationId: 'f9c66ac1-3b2d-42b0-b0c6-4e0fd48014c9',
        clientToken: 'pub1e9c820f4e80df04737837ad8b04f4fe',
        site: 'datadoghq.com',
        service: 'Brivo Access',
        env: DEPLOY_ENV,
        version: APPLICATION_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        silentMultipleInit: true,
        allowedTracingOrigins: [appsyncUrl.origin, wsUrl.origin, currentLocation.origin],
        trackUserInteractions: true,
    });
    setEnableLogging(flagClient.variation('logging-level', 'WARN') === 'DEBUG');

    return <></>;
}
