export const eventSeverity = {
    INFO: { id: 1, label: 'informational' },
    WARNING: { id: 2, label: 'warning' },
    CRITICAL: { id: 3, label: 'critical' },
};

export const themeType = {
    DARK: 'dark',
    LIGHT: 'light',
};

export const MAX_DEVICES = 300;
