import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { useTranslatedEventNames } from '../../../common/hooks/useTranslatedEventNames';
import useGenericFilterGroup from '../../../common/hooks/useGenericFilterGroup';

import UserTracking from '../../../common/utils/UserTracking';
import { alphabetically } from '../../../common/utils/Utils';
import { DEBOUNCE_TIMEOUT_MS, PROMISE_CANCELLED } from '../../../common/constants/Constants';

import { adminsApi } from '../../../common/webApis';
import { journalApi } from '../../../common/webApis';

import { adminDataToOption, buildJournalGqlFilterFunction } from '../utils/helpers';
import { initialJournalFilterValues, JOURNAL_PAGE_SIZE, NUMBER_OF_TYPEAHEAD_OPTIONS } from '../utils/constants';
import { cancellablePromise } from '../../../common/utils/promiseUtils';
import useMounted from '../../../common/hooks/useMounted';

export const JournalContext = React.createContext();

const ContextProvider = ({ children }) => {
    const { translateEventName } = useTranslatedEventNames();
    const isMounted = useMounted();

    const [isLoadingJournalEvents, setIsLoadingJournalEvents] = useState(false);
    const [journalEvents, setJournalEvents] = useState([]);
    const [result, setResult] = useState(null);

    const [journalActionTypes, setJournalActionTypes] = useState([]);
    const [filters, setFilters] = useState(initialJournalFilterValues);

    const [isLoadingEvtActorOptions, setIsLoadingEvtActorOptions] = useState(false);
    const [eventActorOptions, setEventActorOptions] = useState([]);
    const [performedBySearchValue, setPerformedBySearchValue] = useState('');

    const [performedOnTargetType, setPerformedOnTargetType] = useState(null);

    const cancelFunctions = useRef({});
    const lastPromise = useRef();
    const listRef = useRef(null);
    const nextToken = useRef(null);
    const isMainLoadComplete = useRef(false);

    const getArgs = (filters, from, to) => [
        JOURNAL_PAGE_SIZE,
        nextToken.current,
        buildJournalGqlFilterFunction(filters, from, to),
    ];

    const handleFiltersChange = (newFiltersValue) => {
        if (JSON.stringify(newFiltersValue) === JSON.stringify(filters)) {
            return;
        }
        setFilters(newFiltersValue);
        setJournalEvents([]);
        nextToken.current = null;
        setIsLoadingJournalEvents(true);
    };

    const {
        filterValues,
        filterChangeMethods,
        nrFiltersChanged,
        updateAll,
        clearAll,
        clearingValues,
        setClearingValues,
    } = useGenericFilterGroup(initialJournalFilterValues, handleFiltersChange, filters);

    const loadNextPage = () => {
        if (isLoadingJournalEvents) return;

        if (result && result.items.length < JOURNAL_PAGE_SIZE) {
            UserTracking.log(`Found less then ${JOURNAL_PAGE_SIZE} events. No point in scrolling to load more.`);
            UserTracking.log(`Current count of loaded events: ${result.items.length}`);
            return;
        }

        setIsLoadingJournalEvents(true);
        nextToken.current = result.nextToken;
        fetchJournalEvents();
    };

    const fetchJournalIds = useCallback(() => {
        journalApi.getJournalEventTypeIds().then((res) => {
            const resIds = res.journalEventTypeIds;
            if (resIds && resIds.length) {
                const mappedJournalTypeIds = resIds.map((id) => {
                    return { id: id, name: translateEventName(id) };
                });
                setJournalActionTypes(mappedJournalTypeIds);
            }
        });
    }, [translateEventName]);

    const fetchJournalEvents = useCallback(() => {
        const now = new Date();
        const ninetyDaysBefore = new Date();
        ninetyDaysBefore.setDate(now.getDate() - 90);

        const currentPromise = journalApi.getJournalEvents(...getArgs(filters, ninetyDaysBefore, now));
        lastPromise.current = currentPromise;
        currentPromise.then((result) => {
            if (currentPromise === lastPromise.current) {
                if (result !== null) {
                    const mappedEvents = result.getJournalEvents.items.map((res) => {
                        return { ...res, eventData: JSON.parse(res.eventData) };
                    });

                    setResult(result.getJournalEvents);
                    setJournalEvents((prevEvents) => prevEvents.concat(mappedEvents));
                    setIsLoadingJournalEvents(false);
                    isMainLoadComplete.current = true;
                }
            }
        });
    }, [filters]);

    // fetch initial batch events on filters change or reset
    useEffect(() => {
        setIsLoadingJournalEvents(true);
        fetchJournalEvents();
    }, [fetchJournalEvents, filters]);

    useEffect(() => {
        fetchJournalIds();
    }, [fetchJournalIds]);

    useEffect(() => {
        listRef.current && listRef.current.resetAfterIndex(0);
    }, [journalEvents]);

    const updateListRef = (ref) => {
        listRef.current = ref;
    };

    const handlePerformedBySearchValueChange = (searchVal) => {
        const isEmptyValue = searchVal.trim() === '';

        if (isEmptyValue) {
            filterValues.eventActor && filterChangeMethods.eventActor(null);

            setPerformedBySearchValue(searchVal);
            setEventActorOptions([]);
            return;
        }

        setPerformedBySearchValue(searchVal);
        setIsLoadingEvtActorOptions(true);
        debouncedGetEventActor(searchVal);
    };

    useEffect(() => {
        const functions = cancelFunctions.current;

        return () => {
            Object.values(functions).forEach((fn) => fn());
        };
    }, []);

    const getActorAdmins = useCallback(
        (nameSearch) => {
            // the component can unmount between setting the debounce timeout and calling this function
            if (!isMounted.current) return;

            if (cancelFunctions.current.cancelFetchAdmins) {
                cancelFunctions.current.cancelFetchAdmins();
            }

            const { promise: fetchAdmins, cancel: cancelFetchAdmins } = cancellablePromise(
                adminsApi.getAdminsForJournal({ name: nameSearch, rows: NUMBER_OF_TYPEAHEAD_OPTIONS })
            );

            fetchAdmins
                .then((res) => {
                    setIsLoadingEvtActorOptions(false);

                    const isRequestSuccessful = Boolean(res);
                    if (!isRequestSuccessful) {
                        return setEventActorOptions([]);
                    }

                    const admins = res.getAdministrators;
                    const options = admins.map(adminDataToOption).sort(alphabetically((opt) => opt.text));
                    return setEventActorOptions(options);
                })
                .catch((e) => {
                    if (e.message === PROMISE_CANCELLED) {
                        return;
                    }

                    setIsLoadingEvtActorOptions(false);
                });

            cancelFunctions.current.cancelFetchAdmins = cancelFetchAdmins;
        },
        [isMounted]
    );

    const debouncedGetEventActor = useMemo(() => debounce(getActorAdmins, DEBOUNCE_TIMEOUT_MS), [getActorAdmins]);

    const handleRemovingFilters = () => {
        clearAll();
        setJournalEvents([]);
        setFilters(initialJournalFilterValues);
        setPerformedBySearchValue('');
        setPerformedOnTargetType(null);
        nextToken.current = null;
    };

    const handleChangePerformedOnType = (val) => {
        const isEmptyValue = Array.isArray(val) && val.length === 0;

        if (isEmptyValue) {
            setPerformedOnTargetType(null);
        } else {
            setPerformedOnTargetType(val);
        }
        filterValues.eventTarget && filterChangeMethods.eventTarget(null);
    };

    const contextValues = {
        filterChangeMethods,
        filters,
        filterValues,
        updateAll,
        clearingValues,
        setClearingValues,
        handlers: {
            handleChangePerformedOnType,
            handlePerformedBySearchValueChange,
            handleRemovingFilters,
        },
        loadNextPage,
        state: {
            journalEvents,
            eventActorOptions,
            isMainLoading: !isMainLoadComplete.current && isLoadingJournalEvents,
            isLoadingEvtActorOptions,
            isLoadingJournalEvents,
            journalActionTypes,
            nrFiltersChanged,
            performedBySearchValue,
            performedOnTargetType,
        },
        updateListRef,
    };
    return <JournalContext.Provider value={contextValues}>{children}</JournalContext.Provider>;
};

export const JournalContextProvider = ContextProvider;

export function useJournalData() {
    return useContext(JournalContext);
}
