export class RestError extends Error {
    constructor(message, options = { apiResponse: null, status: null }) {
        super(message);

        const { apiResponse, status } = options;

        if (apiResponse !== null) this.apiResponse = apiResponse;
        if (status !== null) this.status = status;
    }

    setApiResponse(apiResponse) {
        this.apiResponse = apiResponse;
        return this;
    }
}
