import { makeStyles } from '@brivo/react-components';
import floorPlanBackgroundDark from '../FloorPlanContainer/assets/images/floor_plan_background_dark.png';
import floorPlanBackgroundLight from '../FloorPlanContainer/assets/images/floor_plan_background_light.png';

export const useStyles = makeStyles((theme) => {
    const grey1200 = theme.palette.colors.grey1200;
    const grey0 = theme.palette.colors.grey0;

    const containerBackgroundImage =
        theme.palette.type === 'dark'
            ? `linear-gradient(to top, ${grey1200} 20%,rgb(0 0 0 / 0%) 60%, rgb(0 0 0 / 0%) 100%), url(${floorPlanBackgroundDark})`
            : `linear-gradient(to top, ${grey0} 20%,rgb(255 255 255 / 0%) 90%, rgb(255 255 255 / 0%) 100%), url(${floorPlanBackgroundLight})`;

    const textColor = theme.palette.type === 'dark' ? grey0 : grey1200;

    return {
        container: {
            backgroundImage: containerBackgroundImage,
            backgroundSize: 'cover',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            height: '100% !important',
        },

        wrapper: {
            width: '100%',
            position: 'absolute',
            bottom: '15%',
        },
        text: {
            color: textColor,
            fontWeight: 600,
        },
        title: {
            fontSize: '3em',
        },
        subtitle: {
            fontSize: '1.5em',
        },
    };
});
