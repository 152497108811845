import { API_BASE_URL } from '@common/constants/Constants';

import { WebReqApi } from '../WebReqApi';
import { GET_ADMINS_FOR_JOURNAL_PAGE_TAG, GET_ADMIN_BY_ID_TAG } from './gqlTags';

class AdminsApi extends WebReqApi {
    createAdmin(
        body = {
            firstName: null,
            lastName: null,
            email: null,
            emailConfirmed: null,
            timezone: null,
            phoneNumber: null,
            roles: null,
            assignments: null,
            showSiteTimeZone: null,
            languageId: null,
        }
    ) {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/ext/createAdmin`,
            body,
        });
    }

    getAdmin(adminId) {
        return this.makeGqlQuery({
            gqlTag: GET_ADMIN_BY_ID_TAG,
            variables: {
                adminId,
            },
            defaultValue: {},
        });
    }

    getAdminsForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_ADMINS_FOR_JOURNAL_PAGE_TAG,
            variables: {
                name,
                rows,
            },
            defaultValue: [],
        });
    }
}

export const adminsApi = new AdminsApi();
