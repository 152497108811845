import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@brivo/react-components';

import { ContentBlock, TextField, CheckboxField, TriggerIcon } from '@brivo/react-components';
import { useFlagClient } from '@brivo/onairplus-services';

import { useStyles } from './styles';
import { useGetAllDoors } from '../formComponents/MultiSelectDialog/useGetAllDoors';
import { useGetAllTriggers } from '../formComponents/MultiSelectDialog/useGetAllTriggers';
import MultiChipDisplayFieldWrapper from '../formComponents/MultiChipDisplayFieldWrapper/MultiChipDisplayFieldWrapper';
import { isEmpty } from 'lodash';
import { getAllDoors, getAllTriggers } from '../../../../common/webApis/lockdown/helpers';
import DoorIcon from '../../../DeviceStatus/components/DeviceStatusPanel/DeviceCard/Icons/DoorIcon';
import { LOCKDOWN_EDIT_LOCKDOWN_SCENARIO } from '../../../../common/permissions/Permissions';
import { usePermissions } from '../../../../common/hooks/usePermissions';

const LockdownScenarioForm = ({
    values,
    setFieldValue,
    accountHasDefaultScenario,
    errors,
    setErrors,
    assignedTriggers,
    isCreateMode,
    isActiveScenario,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { selectedTriggers, isDefaultScenario, selectedDoors } = values;
    const { hasPermissions } = usePermissions();

    const canEditLockdown = hasPermissions(LOCKDOWN_EDIT_LOCKDOWN_SCENARIO);

    const isDisabled = (!canEditLockdown && !isCreateMode) || isActiveScenario;

    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');
    const useGetAllDoorsResults = () =>
        useGetAllDoors({ api: getAllDoors, defaultValues: selectedDoors, emergencyScenariosFlag });
    const useGetAllTriggersResults = () =>
        useGetAllTriggers({
            api: getAllTriggers,
            defaultValues: selectedTriggers,
            assignedItemsFromApi: assignedTriggers, //initial triggers from API
            showEgressScenarios: false,
        });

    const handleTriggerValueChange = (values) => {
        setFieldValue('selectedTriggers', values);
    };

    const handleDoorsValueChange = (values) => {
        setFieldValue('selectedDoors', values);

        if (isEmpty(values)) {
            setErrors({
                ...errors,
                selectedDoors: t('Field.validation.required'),
            });
        } else {
            setErrors({ ...errors, selectedDoors: null });
        }
    };

    const handleCheckDefaultScenario = () => {
        setFieldValue('isDefaultScenario', !isDefaultScenario);
    };

    return (
        <ContentBlock
            title={t('Page.lockdown-scenarios.lockdown-scenario-details.page.section.title')}
            successMessage={t('Component.Save.save-success')}
            failureMessage={t('Component.Save.save-fail')}
        >
            <div className={classes.container}>
                <div className={clsx(classes.input, errors.nameInput && classes.inputError)}>
                    <TextField
                        labelClassName={classes.label}
                        type="text"
                        name="name"
                        label={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.name')}
                        id="name"
                        maxLength={32}
                        placeholder={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.name.placeholder')}
                        isDisabled={isDisabled}
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    {errors.nameInput && (
                        <Typography variant="caption" className={clsx(classes.errorText)}>
                            {errors.nameInput}
                        </Typography>
                    )}
                </div>
                <div className="u-mt-full">
                    <Typography variant="subtitle2" className={classes.label}>
                        {t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers')}
                    </Typography>
                    <MultiChipDisplayFieldWrapper
                        key={`allTriggers`}
                        id="allTriggers"
                        noSelectionLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers.no-selection'
                        )}
                        value={selectedTriggers}
                        onValueChange={handleTriggerValueChange}
                        title={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers.title'
                        )}
                        emptySectionTitle={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.empty-state-triggers'
                        )}
                        headers={[
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column1'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column2'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column3'
                            ),
                        ]}
                        useGetAllItems={useGetAllTriggersResults}
                        tableLegendLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.table-legend.triggers'
                        )}
                        property={'lockdownTriggers'}
                        avatarIcon={<TriggerIcon />}
                        disabled={isDisabled}
                    />
                </div>
                <div className={'u-mt-full'}>
                    <Typography variant="subtitle2" className={classes.label}>
                        {t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors')}
                    </Typography>

                    <MultiChipDisplayFieldWrapper
                        key={`allDoors`}
                        id="allDoors"
                        noSelectionLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.no-selection'
                        )}
                        value={selectedDoors}
                        onValueChange={handleDoorsValueChange}
                        error={!!errors.selectedDoors}
                        helperText={errors.selectedDoors}
                        title={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.title')}
                        emptySectionTitle={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.empty-state-doors'
                        )}
                        headers={[
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column1'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column2'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column3'
                            ),
                        ]}
                        useGetAllItems={useGetAllDoorsResults}
                        tableLegendLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.table-legend.doors'
                        )}
                        property={'lockdownDoors'}
                        avatarIcon={<DoorIcon />}
                        disabled={isDisabled}
                    />
                </div>
                <div className={clsx('u-mt-double', classes.checkboxWrapper)}>
                    {accountHasDefaultScenario ? (
                        <Typography variant="caption" className={clsx(classes.label, 'u-mb-half')}>
                            {t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.default-scenario-exists')}
                        </Typography>
                    ) : (
                        <CheckboxField
                            id={'default'}
                            label={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.default-scenario')}
                            checked={isDefaultScenario}
                            onChange={handleCheckDefaultScenario}
                            disabled={isDisabled}
                        />
                    )}
                </div>
            </div>
        </ContentBlock>
    );
};

export default LockdownScenarioForm;
