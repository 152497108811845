export const USER_PAGE_SIZE = 20;
export const GROUP_PAGE_SIZE = 1000;

export const initialUsersFiltersValue = {
    searchName: '',
    filteredUserStatus: '',
    selectedGroups: [],
};

export const initialGroupsFiltersValue = {
    searchName: '',
};

export const initialBadgeTemplatesFiltersValue = {
    searchName: '',
};

export const initialFaceprintConsentsFiltersValue = {
    searchName: '',
    consentStatus: '',
};

export const getBuildUsersGqlFilterFunction = (customFields) => (userFilters) => {
    let localFilters = [];
    localFilters.push({
        field: 'name',
        type: 'field',
        value: userFilters.searchName.toLowerCase(),
    });
    if (userFilters.filteredUserStatus.key) {
        localFilters.push({
            field: 'status',
            type: 'field',
            value: userFilters.filteredUserStatus.key.toLowerCase(),
        });
    }
    if (userFilters.selectedGroups && userFilters.selectedGroups.length > 0) {
        localFilters.push({
            field: 'group',
            type: 'field',
            value: userFilters.selectedGroups?.map((group) => group.id).join(','),
        });
    }

    if (customFields) {
        customFields.forEach(({ id }) => {
            if (userFilters[id]) {
                localFilters.push({
                    field: `${id}`,
                    type: 'custom',
                    value: userFilters[id],
                });
            }
        });
    }

    return localFilters;
};

export const buildGroupsGqlFilter = function (groupFilters) {
    let localFilters = [];
    localFilters.push({
        field: 'name',
        type: 'field',
        value: groupFilters.searchName.toLowerCase(),
    });

    return localFilters;
};

export const buildFaceprintConsentsGqlFilter = function (consentFilters) {
    let localFilters = [];
    localFilters.push(
        {
            field: 'name',
            type: 'field',
            value: consentFilters.searchName.toLowerCase(),
        },
        {
            field: 'status',
            type: 'field',
            value: consentFilters.consentStatus.toUpperCase(),
        }
    );

    return localFilters;
};

export const toTitleCase = function (str) {
    return str
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
        .join(' ');
};

export function composeCardCollisionCsvRow(userName, cardData) {
    return `${userName},"${cardData.credentialFormat.description}","${cardData.encodedCredential}"\n`;
}

export function composeLicensePlateCollisionCsvRow(userName, licensePlateCollisionData) {
    return `${userName},"License Plate","${licensePlateCollisionData?.licensePlates?.[0]?.value}"\n`;
}

export function composePinCollisionCsvRow(userName, pinCollisionData) {
    return `${userName},"Pin Code","${pinCollisionData?.pin}"\n`;
}
