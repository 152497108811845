import { WebReqApi } from '../WebReqApi';
import { API_BASE_URL } from '@common/constants/Constants';
import {
    CREATE_LOCKDOWN_SCENARIO_WITH_TRIGGERS,
    GET_ACCOUNT_SERVICE_STATUS,
    GET_LOCKDOWN_DOORS,
    GET_LOCKDOWN_TRIGGERS,
    GET_LOCKDOWN_TRIGGERS_BY_ID,
    IS_LESS_THAN_TOTAL_DOORS,
    LIST_UNSUCCESSFUL_DEVICES,
    UPDATE_LOCKDOWN_SCENARIO_WITH_TRIGGERS,
} from './gqlTags';

// TODO -> add more endpoints

class LockdownApi extends WebReqApi {
    getLockdownActiveScenarios() {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/lockdown-scenarios/activeScenarios`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
        });
    }

    getAllActiveScenarios(params) {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/lockdown-scenarios/activeScenarios`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            queryParams: params?.queryParams,
        });
    }

    getLockDownScenarios(params) {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/lockdown-scenarios`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            queryParams: params?.queryParams,
        });
    }

    clearLockdownScenario(id) {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/lockdown-scenarios/:id/clear`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            urlParams: {
                id,
            },
        });
    }

    clearLockdownScenarioNew(scenario) {
        const { id, threatLevelTypeId } = scenario;
        let threatType = '';
        if (threatLevelTypeId === 2) {
            threatType = 'lockdown';
        }
        if (threatLevelTypeId === 3) {
            threatType = 'egress';
        }
        const url = `${API_BASE_URL}/emergency-scenarios/${threatType}/:id/clear`;

        return this.makeRestRequest({
            method: 'POST',
            url,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            urlParams: {
                id,
            },
        });
    }

    initiateLockdown = (id) => {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/lockdown-scenarios/:id/initiate`,
            additionalHeaders: {
                'Accept-version': '2.0',
                'Cache-Control': 'no-store',
            },
            urlParams: {
                id,
            },
        });
    };

    initiateLockdownNew = (scenario) => {
        const { id, threatLevelTypeId } = scenario;
        let threatType = '';
        if (threatLevelTypeId === 2) {
            threatType = 'lockdown';
        }
        if (threatLevelTypeId === 3) {
            threatType = 'egress';
        }
        const url = `${API_BASE_URL}/emergency-scenarios/${threatType}/:id/initiate`;

        return this.makeRestRequest({
            method: 'POST',
            url,
            additionalHeaders: {
                'Accept-version': '2.0',
                'Cache-Control': 'no-store',
            },
            urlParams: {
                id,
            },
        });
    };

    initiateFullLockdown = () => {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/lockdown-scenarios/initiate`,
            additionalHeaders: {
                'Accept-version': '2.0',
                'Cache-Control': 'no-store',
            },
        });
    };

    initiateFullLockdownNew = () => {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/emergency-scenarios/default-lockdown/initiate`,
            additionalHeaders: {
                'Accept-version': '2.0',
                'Cache-Control': 'no-store',
            },
        });
    };

    getLockdownTriggers({ offset = 0, pageSize = 20, sortField = 'name', direction = 'ASC', filters = [] }) {
        return this.makeGqlQuery({
            gqlTag: GET_LOCKDOWN_TRIGGERS,
            variables: {
                pageSize,
                offset,
                sortField,
                direction,
                filters,
            },
            defaultValue: [],
        });
    }

    getLockdownDoors({ offset = 0, pageSize, sortField = 'name', direction = 'ASC', filters = [] }) {
        return this.makeGqlQuery({
            gqlTag: GET_LOCKDOWN_DOORS,
            variables: {
                pageSize,
                offset,
                sortField,
                direction,
                filters,
            },
            defaultValue: [],
        });
    }

    getTriggersByLockdownId(lockdownOid) {
        return this.makeGqlQuery({
            gqlTag: GET_LOCKDOWN_TRIGGERS_BY_ID,
            variables: {
                lockdownOid,
            },
            defaultValue: [],
        });
    }

    updateLockdownScenarioWithTriggers({ lockdownOid, triggerSourceOids, doorOids, isDefaultLockdown, lockdownName }) {
        return this.makeGqlMutation({
            gqlTag: UPDATE_LOCKDOWN_SCENARIO_WITH_TRIGGERS,
            variables: {
                lockdownOid,
                triggerSourceOids,
                doorOids,
                isDefaultLockdown,
                lockdownName,
            },
            defaultValue: [],
        });
    }

    deleteLockdownScenario(id) {
        return this.makeRestRequest({
            method: 'DELETE',
            url: `${API_BASE_URL}/lockdown-scenarios/:id`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            urlParams: {
                id,
            },
        });
    }

    getLockdownScenarioById(id) {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/lockdown-scenarios/:id`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            urlParams: {
                id,
            },
        });
    }

    createLockdownScenarioWithTriggers({
        triggerSourceOids,
        doorOids,
        isDefaultLockdown,
        lockdownName,
        threatLevelTypeId,
    }) {
        return this.makeGqlMutation({
            gqlTag: CREATE_LOCKDOWN_SCENARIO_WITH_TRIGGERS,
            variables: {
                triggerSourceOids,
                doorOids,
                isDefaultLockdown,
                lockdownName,
                threatLevelTypeId,
            },
            defaultValue: {},
        });
    }

    getAccountServiceStatus() {
        return this.makeGqlQuery({
            gqlTag: GET_ACCOUNT_SERVICE_STATUS,
            defaultValue: {},
        });
    }

    isLessThanTotalDoors(count) {
        return this.makeGqlQuery({
            gqlTag: IS_LESS_THAN_TOTAL_DOORS,
            variables: { count },
            defaultValue: {},
        });
    }

    listUnsuccessfulDevices(scenarioOids) {
        return this.makeGqlQuery({
            gqlTag: LIST_UNSUCCESSFUL_DEVICES,
            variables: { scenarioOids },
            defaultValue: {},
        });
    }
}

export const lockdownApi = new LockdownApi();
