import gql from 'graphql-tag';

export const GET_SITES_TAG = gql`
    query getSites {
        getSites {
            id
            name
            description
            address {
                id
                streetAddress1
                streetAddress2
                city
                state
                country
                postalCode
                timeZone
            }
            geolocation {
                geometry {
                    coordinates
                }
                center
                relevance
                accuracy
                validAddress
            }
        }
    }
`;

export const GET_SITES_TAG_NO_GEOCODE = gql`
    query getSites {
        getSites {
            id
            name
            description
            address {
                id
                streetAddress1
                streetAddress2
                city
                state
                country
                postalCode
                timeZone
            }
        }
    }
`;

export const GET_SITES_FOR_FLOORPLANS = gql`
    query getSites {
        getSites {
            id
        }
    }
`;

export const GET_SITES_BY_ID = gql`
    query sites($id: Int!) {
        getSites(filters: { id: $id }) {
            id
            name
            address {
                id
                streetAddress1
                streetAddress2
                city
                state
                country
                postalCode
                timeZone
            }
        }
        getAncestorsForSite(siteOid: $id) {
            id
            name
            parentObjectId
        }
    }
`;

export const GET_SITES_BY_NAME = gql`
    query sites($name: String!, $rows: Int = 10, $offset: Int = 0) {
        getSites(limit: { offset: $offset, rows: $rows }, filters: { name: $name }) {
            id
            name
        }
    }
`;

export const GET_PAGINATED_PROPERTIES_BY_NAME = gql`
    query sites($siteType: String!, $name: String!, $hasNoChildren: Boolean, $rows: Int, $offset: Int) {
        getPaginatedProperties(
            limit: { offset: $offset, rows: $rows }
            filters: { name: $name, siteType: $siteType, hasNoChildren: $hasNoChildren }
        ) {
            totalCount
            properties {
                id
                name
            }
        }
    }
`;

export const GET_PAGINATED_PROPERTIES_BY_PARENT = gql`
    query propertiesByParent(
        $siteType: String!
        $name: String!
        $parentId: Int = 0
        $rows: Int = 10
        $offset: Int = 0
    ) {
        getPaginatedProperties(
            limit: { offset: $offset, rows: $rows }
            filters: { name: $name, parentId: $parentId, siteType: $siteType }
        ) {
            totalCount
            properties {
                id
                name
            }
        }
    }
`;

export const SET_SITE_PARENT_ID_TAG = gql`
    mutation setSiteParentId($id: Int, $parentId: Int) {
        setSiteParentId(id: $id, parentId: $parentId) {
            id
            parentId
        }
    }
`;
