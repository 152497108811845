import { format } from 'date-fns';

export const kpiFormatter = (number, language) => {
    if (number === null || number === undefined) return ''; // TODO Handling null from rover API
    return number.toLocaleString(language);
};

export const isEmpty = (obj) => {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return true;
};

export const formatDateToTimeString = (date) => {
    return format(new Date(date), 'h:mm aa');
};

export const formatDurationInMinutes = (duration) => {
    return `${Math.floor(duration / 60)}:${(duration % 60).toString().padStart(2, '0')}`;
};

export const checkNullEntry = (entry) =>
    entry === null || entry === undefined || entry?.toString().toLowerCase() === 'null';

export const formatNullAsEmptyString = (entry) => {
    if (checkNullEntry(entry)) return '';
    else return entry;
};

export const formatNullAsDash = (entry) => {
    if (checkNullEntry(entry)) return '-';
    else return entry;
};

export const formatDateToDateString = (date) => {
    return format(new Date(date), 'MMM-dd-y');
};

export const convertWidth = (width) => {
    if (Number.isFinite(width)) {
        return {
            xl: Math.max(1, Math.min(12, Math.floor(width))),
            lg: Math.max(1, Math.min(12, Math.floor((width * 4) / 3))),
            md: Math.max(1, Math.min(12, Math.floor(width * 2))),
            sm: Math.max(1, Math.min(12, Math.floor(width * 4))),
            xs: Math.max(1, Math.min(12, Math.floor(width * 4))),
        };
    } else
        return {
            xl: 3,
            lg: 4,
            md: 6,
            sm: 12,
            xs: 12,
        };
};
