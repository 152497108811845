import { Suspense, lazy, useContext, useEffect, useState, useCallback } from 'react';
import { get } from 'lodash';
import { LoadingIndicator, withTheme } from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';
import ApiHelper from '../../../common/helpers/Helpers';
import EventsApi from '../../../common/helpers/EventsApi';
import { UserContext } from '../../../common/user/UserProvider';
import { useWebSocketMessage } from '../../../common/hooks/useSocket';
import { getFiltersFromLocalStorage } from '../../../common/utils/Utils';
import { GQLProviderContext } from '../../../common/utils/CustomApolloProvider';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import {
    DEFAULT_FILTERS,
    PANEL_EVENT,
    PAGE_SCOPE,
    ENRICHMENT_EVENT_TYPE,
    CAMERA_EVENT,
} from '../../../common/constants/Constants';

const EventTrackerPage = lazy(() => import('./EventTrackerPage'));

const WrappedComponent = ({ api, ...rest }) => {
    const gqlHelper = useContext(GQLProviderContext);
    const [webSocketEventTypes, setWebSocketEventTypes] = useState([PANEL_EVENT, ENRICHMENT_EVENT_TYPE]);
    const { message, send } = useWebSocketMessage({
        scope: PAGE_SCOPE,
        eventTypes: webSocketEventTypes,
    });
    const [initialFilters, setInitialFilters] = useState(null);
    const userContext = useContext(UserContext);
    const user = get(userContext, 'userInfo', {});

    const {
        sites,
        selectedSites,
        isWidgetDrawerOpen,
        handleMainDrawerClose,
        isEventFilterDrawerOpen,
        handleEventFilterDrawerToggle,
    } = rest;

    const getFiltersFromLocalStorageCallback = useCallback(() => {
        return getFiltersFromLocalStorage('dashboard-eventTracker', user.id, user.accountId) ?? DEFAULT_FILTERS;
    }, [user]);

    const handleReceiveCameraEvents = (showCameraEvents) => {
        setWebSocketEventTypes(
            showCameraEvents ? [PANEL_EVENT, ENRICHMENT_EVENT_TYPE, CAMERA_EVENT] : [PANEL_EVENT, ENRICHMENT_EVENT_TYPE]
        );
    };

    useEffect(() => {
        setInitialFilters({ ...getFiltersFromLocalStorageCallback() });
    }, [getFiltersFromLocalStorageCallback]);

    const eventsApi = new EventsApi(gqlHelper);

    if (!initialFilters) {
        return <LoadingIndicator />;
    }

    return (
        <Suspense fallback={<LoadingIndicator />}>
            <ErrorBoundary sectionName="Event Tracker">
                <EventTrackerPage
                    {...rest}
                    api={api}
                    gqlHelper={gqlHelper}
                    message={message}
                    eventsApi={eventsApi}
                    user={user}
                    initialFilters={initialFilters}
                    handleReceiveCameraEvents={handleReceiveCameraEvents}
                    send={send}
                    sites={sites}
                    isWidgetDrawerOpen={isWidgetDrawerOpen}
                    handleMainDrawerClose={handleMainDrawerClose}
                    isEventFilterDrawerOpen={isEventFilterDrawerOpen}
                    handleEventFilterDrawerToggle={handleEventFilterDrawerToggle}
                    globalSitesSelected={selectedSites}
                />
            </ErrorBoundary>
        </Suspense>
    );
};

export default withTheme(withApi(WrappedComponent, ApiHelper));
