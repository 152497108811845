import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
const useFetchData = (
    initialFiltersValue,
    apiEndpoint,
    requestFilters,
    processData,
    PAGE_SIZE,
    executeCalls = true
) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [queryParams, setQueryParams] = useState({ filters: initialFiltersValue, offset: 0 });
    const [clearingValues, setClearingValues] = useState(false);
    const lastPromise = useRef();
    const totalCount = useRef(-1);
    const isMounted = useRef(true);

    const getFilterFunctionsMap = useCallback(() => {
        return Object.keys(initialFiltersValue).reduce((acc, filterKey) => {
            acc[filterKey] = (value) => {
                setQueryParams((prevState) => ({
                    ...prevState,
                    filters: { ...prevState.filters, [filterKey]: value },
                    offset: 0,
                }));
                totalCount.current = -1;
            };
            return acc;
        }, {});
    }, [initialFiltersValue]);

    const filterChangeMethods = useMemo(() => getFilterFunctionsMap(), [getFilterFunctionsMap]);

    const fetchData = useCallback(async () => {
        if (executeCalls) {
            setLoading(true);
            const builtFilters = requestFilters(queryParams.filters);
            const currentPromise = apiEndpoint(PAGE_SIZE, queryParams.offset, builtFilters);
            lastPromise.current = currentPromise;

            try {
                const result = await currentPromise;
                if (currentPromise === lastPromise.current) {
                    const { newData, newTotalCount } = processData(result);
                    setData((currentData) => {
                        return queryParams.offset === 0 ? newData : [...currentData, ...newData];
                    });
                    totalCount.current = newTotalCount;
                }
            } catch (error) {
                console.error('Error during fetchData:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [PAGE_SIZE, apiEndpoint, executeCalls, queryParams, processData, requestFilters]);

    useEffect(() => {
        if (isMounted) {
            fetchData();
        }
        return () => {
            isMounted.current = false;
        };
    }, [fetchData]);

    const loadNextPage = useCallback(() => {
        if (!loading && data.length < totalCount.current) {
            setQueryParams((prevState) => ({
                ...prevState,
                offset: prevState.offset + PAGE_SIZE,
            }));
        }
    }, [PAGE_SIZE, data.length, loading]);

    const handleFiltersRestore = useCallback(() => {
        setQueryParams({ filters: initialFiltersValue, offset: 0 });
        setClearingValues(true);
    }, [initialFiltersValue]);

    const updateData = useCallback((newData) => {
        setData(newData);
    }, []);

    return {
        data,
        count: data.length,
        totalCount: totalCount.current,
        loadNextPage,
        loading,
        updateData,
        filters: queryParams.filters,
        filterChangeMethods,
        handleFiltersRestore,
        clearingValues,
        setClearingValues,
    };
};

export default useFetchData;
