import React from 'react';
import { SvgIcon } from '@brivo/react-components';

const DoorIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M16.2466 29.1911L15.9139 28.2481L15.9138 28.2481L16.2466 29.1911ZM23.6752 26.5701L23.3424 25.627L23.3424 25.627L23.6752 26.5701ZM23.6752 5.42993L24.0079 4.48691L23.6752 5.42993ZM16.2466 2.80886L15.9138 3.75188L16.2466 2.80886ZM16.9048 17.2481C16.9048 17.8004 17.3525 18.2481 17.9048 18.2481C18.457 18.2481 18.9048 17.8004 18.9048 17.2481H16.9048ZM18.9048 14.7519C18.9048 14.1996 18.457 13.7519 17.9048 13.7519C17.3525 13.7519 16.9048 14.1996 16.9048 14.7519H18.9048ZM16.5793 30.1342L24.0079 27.5131L23.3424 25.627L15.9139 28.2481L16.5793 30.1342ZM26.3333 24.214V7.78604H24.3333V24.214H26.3333ZM24.0079 4.48691L16.5793 1.86584L15.9138 3.75188L23.3424 6.37295L24.0079 4.48691ZM11.9524 5.16497V6.01499H13.9524V5.16497H11.9524ZM12.9524 5.01499H10.4762V7.01499H12.9524V5.01499ZM7 8.51124V23.4888H9V8.51124H7ZM11.9524 6.01499V25.985H13.9524V6.01499H11.9524ZM11.9524 25.985V26.835H13.9524V25.985H11.9524ZM10.4762 26.985H12.9524V24.985H10.4762V26.985ZM18.9048 17.2481V14.7519H16.9048V17.2481H18.9048ZM7 23.4888C7 25.4121 8.54875 26.985 10.4762 26.985V24.985C9.6685 24.985 9 24.3227 9 23.4888H7ZM26.3333 7.78604C26.3333 6.30541 25.4048 4.97979 24.0079 4.48691L23.3424 6.37295C23.9318 6.58091 24.3333 7.14513 24.3333 7.78604H26.3333ZM24.0079 27.5131C25.4048 27.0202 26.3333 25.6946 26.3333 24.214H24.3333C24.3333 24.8549 23.9318 25.4191 23.3424 25.627L24.0079 27.5131ZM15.9138 28.2481C14.9655 28.5827 13.9524 27.8764 13.9524 26.835H11.9524C11.9524 29.2327 14.3077 30.9357 16.5793 30.1342L15.9138 28.2481ZM10.4762 5.01499C8.54875 5.01499 7 6.58794 7 8.51124H9C9 7.67727 9.66851 7.01499 10.4762 7.01499V5.01499ZM16.5793 1.86584C14.3077 1.06434 11.9524 2.76726 11.9524 5.16497H13.9524C13.9524 4.12361 14.9655 3.41728 15.9138 3.75188L16.5793 1.86584Z"
                fill={props?.fill || '#7DA6DB'}
            />
        </SvgIcon>
    );
};

export default DoorIcon;
