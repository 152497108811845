import { useContext, useEffect } from 'react';
import qs from 'qs';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import SsoLoggedOut from '@common/components/SsoLoggedOut';
import { useFlagClient } from '@brivo/onairplus-services';
import {
    APP_GATEWAY_DETAILS_URL,
    APP_PROPERTIES_URL,
    APP_PROPERTY_DETAILS_URL,
    APP_SSO_LOGGED_OUT_URL,
    APP_SSO_LOGIN_URL,
} from '@common/constants/Constants';

import ScrollToTop from './common/components/ScrollToTop';
import UserTracking from './common/utils/UserTracking';
import { history } from './redux/Store';
import {
    APP_ACCESS_DENIED_URL,
    APP_ACCOUNT_SETTINGS_URL,
    APP_ADD_GROUP_URL,
    APP_ADD_USER_URL,
    APP_ADMIN_MANAGEMENT_URL,
    APP_BADGING_URL,
    APP_BASENAME,
    APP_BRIVO_ANALYTICS_COMPANY_OVERVIEW_URL,
    APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL,
    APP_BRIVO_ANALYTICS_MY_ASSISTANT_URL,
    APP_BRIVO_SMARTHOME,
    APP_CALLBACK_URL,
    APP_COMMAND_URL,
    APP_CONFIGURED_SITE_DETAILS_URL,
    APP_CONFIGURED_SITES_URL,
    APP_CONSENT_URL,
    APP_CREDENTIAL_FACEPRINTS_URL,
    APP_CREDENTIAL_MANAGEMENT_URL,
    APP_CREDENTIAL_UNKNOWN_CARDS_DETAILS_URL,
    APP_CREDENTIAL_UNKNOWN_CARDS_URL,
    APP_CUSTOM_FIELDS_CREATE_URL,
    APP_CUSTOM_FIELDS_DETAILS_URL,
    APP_CUSTOM_FIELDS_URL,
    APP_DEVICE_STATUS_URL,
    APP_EVENT_CLASSIFICATION_URL,
    APP_EVENT_TRACKER_URL,
    APP_FACEPRINT_CONSENTS_URL,
    APP_FLOOR_PLAN_URL,
    APP_FLOOR_PLANS_URL,
    APP_GLOBAL_VIEW_URL,
    APP_GROUP_DETAILS_URL,
    APP_GROUPS_LISTING_URL,
    APP_HOME_URL,
    APP_INTEGRATIONS,
    APP_JOURNAL_URL,
    APP_LIVE_VIDEO_CUSTOM_LAYOUT,
    APP_LIVE_VIDEO_VIEW_ALL_CAMERAS,
    APP_LOCKDOWN_SCENARIOS_URL,
    APP_LOCKDOWN_URL,
    APP_LOGIN_URL,
    APP_LOGOUT_URL,
    APP_NOTIFICATIONS_URL,
    APP_OCCUPANCY_DASHBOARD_URL,
    APP_OCCUPANCY_MANAGEMENT_URL,
    APP_ONAIR_ACCOUNT_SETTINGS_URL,
    APP_ONAIR_ACTIVITY_REPORTS_URL,
    APP_ONAIR_CUSTOM_FIELDS_URL,
    APP_ONAIR_GROUP_LOCKDOWN_URL,
    APP_ONAIR_HOLIDAYS_URL,
    APP_ONAIR_IN_OUT_REPORTS_URL,
    APP_ONAIR_LOCKDOWN_CONFIG_URL,
    APP_ONAIR_NOTIFICATIONS_VIEW_URL,
    APP_ONAIR_REPORT_CONFIGURATIONS_URL,
    APP_ONAIR_REPORT_JOBS_URL,
    APP_ONAIR_REPORT_SCHEDULES_URL,
    APP_ONAIR_UNKNOWN_CARDS_URL,
    APP_ONAIR_USER_BY_ID_URL,
    APP_ONAIR_USER_REPORTS_URL,
    APP_OPTOUT_URL,
    APP_PRIVACY_POLICY_URL,
    APP_RECORDED_VIDEO_URL,
    APP_RECORDED_VIDEO_URL_EVENTS,
    APP_RECORDED_VIDEO_URL_TIME,
    APP_ROOT_URL,
    APP_SCENARIOS_URL,
    APP_SCHEDULES_URL,
    APP_SHARED_ACCESS_URL,
    APP_SITE_SETTINGS_URL,
    APP_SNAPSHOT_LOG_URL,
    APP_UNIFIED_DASHBOARD_URL,
    APP_UNIFIED_VIDEO_INTEGRATIONS_URL,
    APP_UNIFIED_VIDEO_URL,
    APP_NEW_REPORTS_URL,
    APP_USER_DETAILS_URL,
    APP_USERS_LISTING_URL,
    APP_VISITOR_URL,
    APP_VISITORS_URL,
    APP_INCIDENT_MANAGEMENT_URL,
    APP_INCIDENT_MANAGEMENT_DETAILS_URL,
    MOBILE_DEEP_LINK_URL,
    MOBILE_SSO_LOGIN_URL,
    ONAIR_CLASSIC_ACCOUNT_SETTINGS_URL,
    ONAIR_CLASSIC_ACTIVITY_REPORT_URL,
    ONAIR_CLASSIC_CUSTOM_FIELDS_URL,
    ONAIR_CLASSIC_GROUP_LOCKDOWN_URL,
    ONAIR_CLASSIC_HOLIDAYS_URL,
    ONAIR_CLASSIC_IN_OUT_REPORT_URL,
    ONAIR_CLASSIC_LOCKDOWN_CONFIG_URL,
    ONAIR_CLASSIC_NOTIFICATIONS_VIEW_URL,
    ONAIR_CLASSIC_REPORT_CONFIGURATIONS_URL,
    ONAIR_CLASSIC_REPORT_JOBS_URL,
    ONAIR_CLASSIC_REPORT_SCHEDULES_URL,
    ONAIR_CLASSIC_UNKNOWN_CARDS_URL,
    ONAIR_CLASSIC_USER_BY_ID_URL,
    ONAIR_CLASSIC_USER_REPORT_URL,
    redirect,
} from './common/constants/Constants';
import {
    ACCEPT_INVITATION,
    ADMIN_VIEW_ADMINISTRATORS,
    BADGING_VIEW_TEMPLATE,
    COMMANDS_VIEW_COMMANDS,
    CONFIG_CUSTOM_FIELDS,
    CONFIG_HOLIDAYS,
    CONFIG_NOTIFICATIONS,
    CREATE_FLOOR_PLANS,
    CREDENTIAL_VIEW_CREDENTIALS,
    CREDENTIAL_VIEW_UNKNOWN_CARDS,
    DELETE_FLOOR_PLANS,
    DEVICES_VIEW_DEVICES,
    EVENT_TRACKER_CHART_PERM,
    EVENT_TRACKER_PERM,
    EVT_CLASSIFICATION_VIEW,
    GLOBAL_VIEW_PERM,
    GROUPS_CREATE_GROUP,
    GROUPS_VIEW_GROUPS,
    JOURNAL_PERM,
    LINK_ACCOUNTS,
    LINK_GROUP,
    LOCKDOWN_CLEAR_LOCKDOWN,
    LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
    LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
    LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
    LOCKDOWN_INITIATE_LOCKDOWN,
    MANAGE_ACCOUNT_SETTINGS_UPDATE,
    MANAGE_ACCOUNT_SETTINGS_VIEW,
    MANAGE_APPLICATIONS,
    NOTIFICATIONS_CREATE,
    NOTIFICATIONS_DELETE,
    NOTIFICATIONS_UPDATE,
    NOTIFICATIONS_VIEW,
    OCCUPANCY_TRACKER_CONFIGURE,
    OCCUPANCY_TRACKER_VIEW,
    ONAIR_GROUP_BASED_LOCKDOWN,
    REPORTS_PERM,
    ROLE_VIEW_ROLES,
    SCHEDULES_READ_SCHEDULES,
    SNAPSHOT_LOG_VIEW,
    UPDATE_FLOOR_PLANS,
    USER_CREATE_USER,
    USER_EDIT_USER_PROFILES,
    USER_VIEW_USERS,
    VIDEO_VIEW_LIVE_VIDEO_ALL,
    VIDEO_VIEW_LIVE_VIDEO_CUSTOM,
    VIDEO_VIEW_RECORDED_VIDEO,
    VIEW_FLOOR_PLANS,
    VIEW_VISITOR,
} from './common/permissions/Permissions';

import AuthenticatedRoute from './common/components/AuthenticatedRoute';
import NoAccess from './common/components/NoAccess/NoAccess';
import AccessDeniedContainer from './pages/AccessDenied/AccessDeniedContainer';
import DeviceStatusPageContainer from './pages/DeviceStatus/DeviceStatusPageContainer';
import EventConfigContainer from './pages/EventConfig/EventConfigContainer';
import EventTrackerContainer from './pages/EventTracker/EventTrackerContainer';
import HomeContainer from './pages/Home/HomeContainer';
import LiveVideoContainer from './pages/LiveVideo/LiveVideoContainer';
import RecordedVideoContainer from './pages/RecordedVideo';
import UnifiedVideoContainer from './pages/UnifiedVideo/UnifiedVideoContainer';
import LockdownContainer from './pages/Lockdown/LockdownContainer';
import NotFoundContainer from './pages/NotFound/NotFoundContainer';
import SnapshotLogContainer from './pages/SnapshotLog/SnapshotLogContainer';
import OnairPageContainer from './pages/OnairSSO/OnairPageContainer';
import CustomFieldsContainer from './pages/CustomFields/CustomFieldsContainer';
import { SsoLogin } from './pages/SsoLogin';
import AdminManagementContainer from './pages/AdminManagement/AdminManagementContainer';
import UserManagementContainer from './pages/UserManagement/UserManagementContainer';
import CredentialManagementContainer from './pages/CredentialManagement/CredentialManagementContainer';
import OccupancyManagementContainer from './pages/OccupancyManagement/OccupancyManagementContainer';
import RedirectToNextRoute from './common/components/RedirectToNextRoute/RedirectToNextRoute';
import NotificationsContainer from './pages/Notifications/NotificationsContainer';
import AccountSettingsContainer from './pages/AccountSettings/AccountSettingsContainer';
import { useTranslation } from 'react-i18next';
import BrivoSmartHome from './pages/BrivoSmartHome/BrivoSmartHome';
import ReaderCommandContainer from './pages/Commands/ReaderCommandContainer';
import IntegrationsContainer from './pages/Integrations/IntegrationsContainer';
import JournalContainer from './pages/Journal/JournalContainer';
import BrivoAnalyticsContainer from './pages/BrivoAnalytics/BrivoAnalyticsContainer';
import NewReports from './pages/NewReports';
import SchedulesContainer from './pages/Schedules/SchedulesContainer';
import FloorPlanContainer from './pages/FloorPlan/FloorPlanContainer';
import VisitorContainer from './pages/Visitor/VisitorContainer';
import ConsentContainer from './pages/Consent/ConsentContainer';
import LockdownScenariosContainer from './pages/LockdownScenarios/LockdownScenariosContainer';
import PrivacyPolicyContainer from './pages/PrivacyPolicy/PrivacyPolicyContainer';
import VistorsPageContainer from './pages/Visitors/VisitorsPageContainer';
import PropertyPageContainer from '@pages/AccountSettings/PropertyPageContainer';
import FloorPlanNavigation from './pages/FloorPlan/FloorPlanNavigation';
import UnifiedDashboardContainer from './pages/UnifiedDashboard/UnifiedDashboardContainer';
import IncidentManagementContainer from './pages/IncidentManagement/IncidentManagementContainer';

import { overwriteHelpdocsStyles, updateCloseIconStyles, updateOpenIconStyles } from './common/utils/Utils';
import { UserContext } from './common/user/UserProvider';
import { auth } from './common/utils/Auth';
import GatewayDetailsContainer from '@pages/GatewayManagement/GatewayDetailsContainer';

const ApplicationRoutes = () => {
    const { t } = useTranslation();
    const flagClient = useFlagClient();
    const userContext = useContext(UserContext);
    let isFaceIdEnabled = userContext?.userInfo?.isFaceIdEnabled;
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');

    const checkFloorPlanRouteHasHelpIcon = (pathname) => {
        const firstRoutePattern = new RegExp(`^${APP_BASENAME}/floor-plans/\\d+|create-floor-plan$`);
        const secondRoutePattern = new RegExp(`^${APP_BASENAME}/floor-plans$`);

        return firstRoutePattern.test(pathname) && !secondRoutePattern.test(pathname);
    };

    useEffect(() => {
        if (
            !window.hdlh &&
            !checkFloorPlanRouteHasHelpIcon(window.location.pathname) &&
            !window.location.pathname.match(/.*\/visitor\/.+/)
        ) {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.defer = true;
            script.src = 'https://lighthouse.helpdocs.io/load?t=' + new Date().getTime();
            window.hdlh = {
                onReady: overwriteHelpdocsStyles,
                onShow: updateOpenIconStyles,
                onHide: updateCloseIconStyles,
                widget_key: 'q4v7amrff9bvepwl6148',
                launcher_button_image: '/Help-bubble.svg',
                position: 'bottom left',
            };

            script.async = true;

            document.head.appendChild(script);
        }
    }, []);

    return (
        <ConnectedRouter history={history}>
            <ScrollToTop>
                <Switch>
                    <Route
                        exact
                        path={APP_LOGOUT_URL}
                        render={() => {
                            UserTracking.track('Logout URL was hit');
                            UserTracking.pageView();

                            auth.logout();

                            return <></>;
                        }}
                    />
                    <Route
                        path={APP_SSO_LOGIN_URL}
                        render={({ match }) => {
                            UserTracking.pageView();
                            return <SsoLogin auth={auth} connectionKey={match.params.connectionKey} />;
                        }}
                    />
                    <Route
                        path={MOBILE_SSO_LOGIN_URL}
                        render={({ match }) => {
                            UserTracking.pageView();
                            redirect(`${MOBILE_DEEP_LINK_URL}?connection=${match.params.connectionKey}`);
                            return null;
                        }}
                    />
                    <Route
                        exact
                        path={APP_SSO_LOGGED_OUT_URL}
                        render={({ location }) => {
                            const { search } = location;
                            const parsed = qs.parse(search.substring(1));
                            UserTracking.pageView();
                            return (
                                <SsoLoggedOut
                                    loginUrl={`${window.location.origin}${APP_SSO_LOGIN_URL.replace(
                                        ':connectionKey',
                                        parsed.connectionKey
                                    )}`}
                                />
                            );
                        }}
                    />
                    <Route
                        exact
                        path={APP_CALLBACK_URL}
                        render={(props) => {
                            auth.handleAuthentication(props);
                            return <></>;
                        }}
                    />

                    <Route exact path={APP_VISITOR_URL}>
                        <VisitorContainer />
                    </Route>

                    <Route exact path={APP_CONSENT_URL}>
                        <ConsentContainer />
                    </Route>

                    <Route exact path={APP_OPTOUT_URL}>
                        <ConsentContainer optout={true} />
                    </Route>

                    <Route exact path={APP_PRIVACY_POLICY_URL}>
                        <PrivacyPolicyContainer />
                    </Route>

                    <AuthenticatedRoute
                        exact
                        path={APP_GLOBAL_VIEW_URL}
                        component={HomeContainer}
                        anyOfPermissions={[GLOBAL_VIEW_PERM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.global-view.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={[APP_ROOT_URL, APP_HOME_URL, APP_LOGIN_URL]}
                        component={RedirectToNextRoute}
                        unauthorizedComponent={NoAccess}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_EVENT_TRACKER_URL}
                        component={EventTrackerContainer}
                        anyOfPermissions={[EVENT_TRACKER_PERM, EVENT_TRACKER_CHART_PERM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.event-tracker.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_SNAPSHOT_LOG_URL}
                        component={SnapshotLogContainer}
                        anyOfPermissions={[SNAPSHOT_LOG_VIEW]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.snapshot-log.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_DEVICE_STATUS_URL}
                        component={DeviceStatusPageContainer}
                        anyOfPermissions={[DEVICES_VIEW_DEVICES]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.device-status.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_LIVE_VIDEO_CUSTOM_LAYOUT}
                        component={LiveVideoContainer}
                        anyOfPermissions={[VIDEO_VIEW_LIVE_VIDEO_CUSTOM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.live-video.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_LIVE_VIDEO_VIEW_ALL_CAMERAS}
                        component={LiveVideoContainer}
                        anyOfPermissions={[VIDEO_VIEW_LIVE_VIDEO_ALL]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.live-video.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_RECORDED_VIDEO_URL}
                        component={RecordedVideoContainer}
                        anyOfPermissions={[VIDEO_VIEW_RECORDED_VIDEO]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.recorded-video.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_RECORDED_VIDEO_URL_EVENTS}
                        component={RecordedVideoContainer}
                        anyOfPermissions={[VIDEO_VIEW_RECORDED_VIDEO]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.recorded-video.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_RECORDED_VIDEO_URL_TIME}
                        component={RecordedVideoContainer}
                        anyOfPermissions={[VIDEO_VIEW_RECORDED_VIDEO]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.recorded-video.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_UNIFIED_VIDEO_URL}
                        requiredFlag="unified-video"
                        component={UnifiedVideoContainer}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.live-video.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_EVENT_CLASSIFICATION_URL}
                        component={EventConfigContainer}
                        anyOfPermissions={[EVT_CLASSIFICATION_VIEW]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.event-classification.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_LOCKDOWN_URL}
                        component={LockdownContainer}
                        anyOfPermissions={[LOCKDOWN_CLEAR_LOCKDOWN, LOCKDOWN_INITIATE_LOCKDOWN]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.lockdown.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_GROUP_LOCKDOWN_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-group-lockdown',
                            title: t('Authenticated.route.group-lockdown.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_GROUP_LOCKDOWN_URL,
                        }}
                        anyOfPermissions={[ONAIR_GROUP_BASED_LOCKDOWN]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.group-lockdown.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_UNKNOWN_CARDS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-unknowncards',
                            title: t('Authenticated.route.unknown-cards.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_UNKNOWN_CARDS_URL,
                        }}
                        anyOfPermissions={[CREDENTIAL_VIEW_UNKNOWN_CARDS]}
                        requiredFlag={'onair-unknown-cards-functionality'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.unknown-cards.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_HOLIDAYS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-holidays',
                            title: t('Authenticated.route.holidays.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_HOLIDAYS_URL,
                        }}
                        anyOfPermissions={[CONFIG_HOLIDAYS]}
                        requiredFlag={'onair-holidays-functionality'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.holidays.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_ACCOUNT_SETTINGS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-onair-account-settings',
                            title: t('Authenticated.route.onair.account-settings.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_ACCOUNT_SETTINGS_URL,
                        }}
                        anyOfPermissions={[MANAGE_APPLICATIONS]}
                        requiredFlag={'onair-account-settings-functionality'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.onair.account-settings.pageProps.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_NOTIFICATIONS_URL}
                        component={NotificationsContainer}
                        anyOfPermissions={[
                            NOTIFICATIONS_VIEW,
                            NOTIFICATIONS_CREATE,
                            NOTIFICATIONS_UPDATE,
                            NOTIFICATIONS_DELETE,
                        ]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.access.notifications.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_COMMAND_URL}
                        component={ReaderCommandContainer}
                        anyOfPermissions={[COMMANDS_VIEW_COMMANDS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.access.commands-configuration.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_ACCOUNT_SETTINGS_URL}
                        component={AccountSettingsContainer}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE]}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.account-settings.navigation.page-title')}
                    />
                    <AuthenticatedRoute
                        path={APP_SITE_SETTINGS_URL}
                        component={AccountSettingsContainer}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE]}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.site-settings.navigation.page-title')}
                    />
                    <AuthenticatedRoute
                        path={APP_PROPERTIES_URL}
                        component={PropertyPageContainer}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE]}
                        requiredFlag={'ba-site-nesting-nested-sites'}
                        unauthorizedComponent={NoAccess}
                        title={'Properties'}
                    />
                    <AuthenticatedRoute
                        exact
                        displayNavigationBar={false}
                        path={APP_FLOOR_PLAN_URL}
                        component={FloorPlanContainer}
                        anyOfPermissions={[
                            VIEW_FLOOR_PLANS,
                            CREATE_FLOOR_PLANS,
                            UPDATE_FLOOR_PLANS,
                            DELETE_FLOOR_PLANS,
                        ]}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.floor-plans.page-title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_FLOOR_PLANS_URL}
                        component={FloorPlanNavigation}
                        requiredFlag={'floor-plans-usability'}
                        anyOfPermissions={[VIEW_FLOOR_PLANS]}
                        title={'Floor plans'}
                        unauthorizedComponent={NoAccess}
                    />
                    <AuthenticatedRoute
                        path={APP_PROPERTY_DETAILS_URL}
                        component={AccountSettingsContainer}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE]}
                        requiredFlag={'ba-site-nesting-nested-sites'}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.property-details.navigation.page-title')}
                    />
                    <AuthenticatedRoute
                        path={APP_GATEWAY_DETAILS_URL}
                        component={GatewayDetailsContainer}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW]}
                        requiredFlag={'gateways-in-access'}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.gateway-details.navigation.page-title')}
                    />
                    <AuthenticatedRoute
                        path={APP_SHARED_ACCESS_URL}
                        component={AccountSettingsContainer}
                        anyOfPermissions={[LINK_ACCOUNTS, ACCEPT_INVITATION, LINK_GROUP]}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.shared-access.page-title')}
                    />
                    <AuthenticatedRoute
                        path={APP_UNIFIED_VIDEO_INTEGRATIONS_URL}
                        component={AccountSettingsContainer}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE]}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.unified-video-integrations.navigation.page-title')}
                    />
                    <AuthenticatedRoute
                        path={APP_ONAIR_NOTIFICATIONS_VIEW_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-notifications',
                            title: t('Authenticated.route.access.notification-rules.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_NOTIFICATIONS_VIEW_URL,
                        }}
                        anyOfPermissions={[CONFIG_NOTIFICATIONS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.access.notification-rules.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_INTEGRATIONS}
                        component={IntegrationsContainer}
                        necessaryPermissions={[MANAGE_ACCOUNT_SETTINGS_VIEW]}
                        anyOfPermissions={[MANAGE_ACCOUNT_SETTINGS_UPDATE, MANAGE_APPLICATIONS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Page.integrations.title.Integrations')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_CUSTOM_FIELDS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'custom-fields',
                            title: t('Authenticated.route.custom-fields.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_CUSTOM_FIELDS_URL,
                        }}
                        anyOfPermissions={[CONFIG_CUSTOM_FIELDS]}
                        requiredFlag={'onair-custom-fields-functionality'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.custom-fields.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_CUSTOM_FIELDS_URL}
                        component={CustomFieldsContainer}
                        anyOfPermissions={[CONFIG_CUSTOM_FIELDS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.custom-fields.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_CUSTOM_FIELDS_DETAILS_URL}
                        component={CustomFieldsContainer}
                        anyOfPermissions={[CONFIG_CUSTOM_FIELDS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.custom-fields-details.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_CUSTOM_FIELDS_CREATE_URL}
                        component={CustomFieldsContainer}
                        anyOfPermissions={[CONFIG_CUSTOM_FIELDS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.custom-fields-details.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_USER_BY_ID_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'user-by-id',
                            title: t('Authenticated.route.user-by-id.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_USER_BY_ID_URL,
                        }}
                        anyOfPermissions={[USER_EDIT_USER_PROFILES]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.user-by-id.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_LOCKDOWN_CONFIG_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-lockdown-configuration',
                            title: t('Authenticated.route.lockdown-configuration.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_LOCKDOWN_CONFIG_URL,
                        }}
                        anyOfPermissions={[
                            LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
                            LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
                            LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
                        ]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.lockdown-configuration.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_REPORT_JOBS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-report-jobs',
                            title: t('Authenticated.route.report-jobs.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_REPORT_JOBS_URL,
                        }}
                        anyOfPermissions={[REPORTS_PERM]}
                        requiredFlag={'onair-reports'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.report-jobs.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_REPORT_SCHEDULES_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-report-schedules',
                            title: t('Authenticated.route.report-schedules.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_REPORT_SCHEDULES_URL,
                        }}
                        anyOfPermissions={[REPORTS_PERM]}
                        requiredFlag={'onair-reports'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.report-schedules.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_NEW_REPORTS_URL}
                        component={NewReports}
                        requiredFlag="new-reporting"
                        anyOfPermissions={[REPORTS_PERM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.new-reports.pageProps.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_REPORT_CONFIGURATIONS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-report-configs',
                            title: t('Authenticated.route.report-configs.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_REPORT_CONFIGURATIONS_URL,
                        }}
                        anyOfPermissions={[REPORTS_PERM]}
                        requiredFlag={'onair-reports'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.report-configs.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_USER_REPORTS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-report-users',
                            title: t('Authenticated.route.report-user-reports'),
                            onairPath: ONAIR_CLASSIC_USER_REPORT_URL,
                        }}
                        anyOfPermissions={[REPORTS_PERM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.report-user-reports')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_ACTIVITY_REPORTS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-report-activity',
                            title: t('Authenticated.route.report-activity-reports'),
                            onairPath: ONAIR_CLASSIC_ACTIVITY_REPORT_URL,
                        }}
                        anyOfPermissions={[REPORTS_PERM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.report-activity-reports')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ONAIR_IN_OUT_REPORTS_URL}
                        component={OnairPageContainer}
                        pageProps={{
                            id: 'page-report-inout',
                            title: t('Authenticated.route.report-inout.pageProps.title'),
                            onairPath: ONAIR_CLASSIC_IN_OUT_REPORT_URL,
                        }}
                        anyOfPermissions={[REPORTS_PERM]}
                        requiredFlag={'onair-reports'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.report-inout.title')}
                    />
                    <Route path={APP_ACCESS_DENIED_URL} component={AccessDeniedContainer} />
                    <AuthenticatedRoute
                        exact
                        path={APP_USERS_LISTING_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[USER_VIEW_USERS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.users-listing.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_GROUPS_LISTING_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[GROUPS_VIEW_GROUPS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.groups-listing.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ADD_USER_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[USER_CREATE_USER]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.create-user.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_USER_DETAILS_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[USER_VIEW_USERS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.user-details.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_ADD_GROUP_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[GROUPS_CREATE_GROUP]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.create-group.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_GROUP_DETAILS_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[GROUPS_VIEW_GROUPS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.group-details.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_ADMIN_MANAGEMENT_URL}
                        component={AdminManagementContainer}
                        anyOfPermissions={[ADMIN_VIEW_ADMINISTRATORS, ROLE_VIEW_ROLES]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.administrators.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_CREDENTIAL_MANAGEMENT_URL}
                        component={CredentialManagementContainer}
                        anyOfPermissions={[CREDENTIAL_VIEW_CREDENTIALS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.credential-management.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_CREDENTIAL_UNKNOWN_CARDS_URL}
                        component={CredentialManagementContainer}
                        anyOfPermissions={[CREDENTIAL_VIEW_UNKNOWN_CARDS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.credential-management.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_CREDENTIAL_UNKNOWN_CARDS_DETAILS_URL}
                        component={CredentialManagementContainer}
                        anyOfPermissions={[CREDENTIAL_VIEW_UNKNOWN_CARDS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.credential-management.title')}
                    />
                    <AuthenticatedRoute
                        path={isFaceIdEnabled ? APP_CREDENTIAL_FACEPRINTS_URL : APP_ACCESS_DENIED_URL}
                        component={CredentialManagementContainer}
                        anyOfPermissions={[CREDENTIAL_VIEW_CREDENTIALS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.credential-management.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_BADGING_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[BADGING_VIEW_TEMPLATE]}
                        requiredFlag="badging-functionality"
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.badging.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_OCCUPANCY_MANAGEMENT_URL}
                        component={OccupancyManagementContainer}
                        anyOfPermissions={[OCCUPANCY_TRACKER_VIEW]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.occupancy-dashboard.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_OCCUPANCY_DASHBOARD_URL}
                        component={OccupancyManagementContainer}
                        anyOfPermissions={[OCCUPANCY_TRACKER_VIEW]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.occupancy-dashboard.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_CONFIGURED_SITES_URL}
                        component={OccupancyManagementContainer}
                        anyOfPermissions={[OCCUPANCY_TRACKER_CONFIGURE]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.configured-sites.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_CONFIGURED_SITE_DETAILS_URL}
                        component={OccupancyManagementContainer}
                        anyOfPermissions={[OCCUPANCY_TRACKER_CONFIGURE]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.configured-sites.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_BRIVO_SMARTHOME}
                        component={BrivoSmartHome}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.brivo-smarthome.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_JOURNAL_URL}
                        component={JournalContainer}
                        anyOfPermissions={[JOURNAL_PERM]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.journal.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_SCHEDULES_URL}
                        component={SchedulesContainer}
                        anyOfPermissions={[SCHEDULES_READ_SCHEDULES]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.schedules.title')}
                    />
                    <AuthenticatedRoute
                        path={isFaceIdEnabled ? APP_FACEPRINT_CONSENTS_URL : APP_ACCESS_DENIED_URL}
                        component={UserManagementContainer}
                        anyOfPermissions={[CREDENTIAL_VIEW_CREDENTIALS]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.credential-management.title')}
                    />
                    <AuthenticatedRoute
                        path={emergencyScenariosFlag ? APP_SCENARIOS_URL : APP_LOCKDOWN_SCENARIOS_URL}
                        component={LockdownScenariosContainer}
                        requiredFlag={'emergency-scenarios'}
                        unauthorizedComponent={NoAccess}
                        title={
                            emergencyScenariosFlag
                                ? t('Authenticated.route.emergency-scenarios.title')
                                : t('Authenticated.route.lockdown-scenarios.title')
                        }
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_BRIVO_ANALYTICS_COMPANY_OVERVIEW_URL}
                        component={BrivoAnalyticsContainer}
                        requiredFlag={'enable-dynamic-reporting'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.brivo-analytics-company-overview.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_UNIFIED_DASHBOARD_URL}
                        component={UnifiedDashboardContainer}
                        requiredFlag="unified-dashboard"
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.unified-dashboard.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_INCIDENT_MANAGEMENT_URL}
                        component={IncidentManagementContainer}
                        requiredFlag="incident-management"
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.incident-management.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_INCIDENT_MANAGEMENT_DETAILS_URL}
                        component={IncidentManagementContainer}
                        requiredFlag="incident-management"
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.incident-management.title')}
                    />
                    <AuthenticatedRoute
                        path={APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL}
                        component={BrivoAnalyticsContainer}
                        requiredFlag="enable-dynamic-reporting"
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.brivo-analytics-my-analytics.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_BRIVO_ANALYTICS_MY_ASSISTANT_URL}
                        component={BrivoAnalyticsContainer}
                        requiredFlag={'enable-brivo-analytics-assistant'}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.brivo-analytics-my-assistant.title')}
                    />
                    <AuthenticatedRoute
                        exact
                        path={APP_VISITORS_URL}
                        component={VistorsPageContainer}
                        requiredFlag={'visitor-experience'}
                        anyOfPermissions={[VIEW_VISITOR]}
                        unauthorizedComponent={NoAccess}
                        title={t('Authenticated.route.visitors.title')}
                    />
                    <AuthenticatedRoute component={NotFoundContainer} />
                </Switch>
            </ScrollToTop>
        </ConnectedRouter>
    );
};

export default ApplicationRoutes;
