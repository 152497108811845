import React from 'react';
import { ToggleButtons } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import { ZOOM_LEVELS, ZOOM_LEVELS_V2 } from '../../timelineConstants';
import styles from './styles';

const TimelineZoomButtons = ({ currentZoomLevel, handleSetZoomLevel, isV2 = false }) => {
    const classes = styles();
    const { t } = useTranslation();

    const zoomLevels = isV2 ? ZOOM_LEVELS_V2 : ZOOM_LEVELS;

    return (
        <ToggleButtons
            options={Object.keys(zoomLevels).map((zoomLevel) => {
                return {
                    label: t(`Page.recorded-video.timeline-zoom-${zoomLevel}`),
                    value: zoomLevels[zoomLevel],
                };
            })}
            selectedOption={currentZoomLevel}
            handleChange={handleSetZoomLevel}
            id="change-zoom-level"
            customButtonStyles={classes.customToggleButtons}
        />
    );
};
export default TimelineZoomButtons;
