import { useEffect, useMemo, useCallback } from 'react';
import { SITE_LIST_PAGE_SIZE } from '../propertyUtils/SiteSettingsConstants';
import { sitesApi } from '@common/webApis';
import useFetchData from '@common/hooks/useFetchData';

const usePropertyListFiltering = (parentId, shouldExecute) => {
    const initialSiteListFilters = useMemo(() => {
        return { name: '', parentId: parentId, siteType: 'property' };
    }, [parentId]);

    const fetchSites = useCallback(async (pageSize, offset, filters) => {
        return sitesApi.getSitesWithParentFiltering({
            rows: pageSize,
            offset: offset,
            filters: filters,
        });
    }, []);

    const requestFilters = useCallback((filterValue) => {
        return filterValue;
    }, []);

    const processData = useCallback((data) => {
        return {
            newData: data.getPaginatedProperties.properties,
            newTotalCount: data.getPaginatedProperties.totalCount,
        };
    }, []);

    const {
        data,
        totalCount,
        filters,
        filterChangeMethods,
        loadNextPage,
        handleFiltersRestore,
        loading,
        clearingValues,
        setClearingValues,
    } = useFetchData(
        initialSiteListFilters,
        fetchSites,
        requestFilters,
        processData,
        SITE_LIST_PAGE_SIZE,
        shouldExecute
    );

    useEffect(() => {
        handleFiltersRestore();
    }, [parentId, handleFiltersRestore]);
    return {
        totalCount,
        filters,
        sites: data,
        isLoading: loading,
        loadNextPage,
        reloadList: handleFiltersRestore,
        filterChangeMethods,
        clearingValues,
        setClearingValues,
    };
};

export default usePropertyListFiltering;
