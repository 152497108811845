import React from 'react';

import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path d="M5.5 3.25C4.25736 3.25 3.25 4.25736 3.25 5.5V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V5.5C4.75 5.08579 5.08579 4.75 5.5 4.75H8C8.41421 4.75 8.75 4.41421 8.75 4C8.75 3.58579 8.41421 3.25 8 3.25H5.5Z" />
        <path d="M16 3.25C15.5858 3.25 15.25 3.58579 15.25 4C15.25 4.41421 15.5858 4.75 16 4.75H18.5C18.9142 4.75 19.25 5.08579 19.25 5.5V8C19.25 8.41421 19.5858 8.75 20 8.75C20.4142 8.75 20.75 8.41421 20.75 8V5.5C20.75 4.25736 19.7426 3.25 18.5 3.25H16Z" />
        <path d="M4.75 16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V18.5C3.25 19.7426 4.25736 20.75 5.5 20.75H8C8.41421 20.75 8.75 20.4142 8.75 20C8.75 19.5858 8.41421 19.25 8 19.25H5.5C5.08579 19.25 4.75 18.9142 4.75 18.5V16Z" />
        <path d="M20.75 16C20.75 15.5858 20.4142 15.25 20 15.25C19.5858 15.25 19.25 15.5858 19.25 16V18.5C19.25 18.9142 18.9142 19.25 18.5 19.25H16C15.5858 19.25 15.25 19.5858 15.25 20C15.25 20.4142 15.5858 20.75 16 20.75H18.5C19.7426 20.75 20.75 19.7426 20.75 18.5V16Z" />
        <path d="M8 9.79926V8.70074C8 8.30307 8.34461 8 8.75 8C9.15541 8 9.5 8.32198 9.5 8.70074V9.79926C9.5 10.178 9.15539 10.5 8.75 10.5C8.34459 10.5 8 10.178 8 9.79926Z" />
        <path d="M12.1177 9.41165C12.4426 9.47664 12.6533 9.79273 12.5883 10.1177L12.3076 11.5213C12.2312 11.9033 12.4199 12.2891 12.7683 12.4633C13.0647 12.6115 13.1848 12.9719 13.0367 13.2683C12.8885 13.5647 12.5281 13.6848 12.2317 13.5367C11.3993 13.1204 10.9484 12.1986 11.1309 11.286L11.4117 9.88233C11.4766 9.55739 11.7927 9.34666 12.1177 9.41165Z" />
        <path d="M8.97206 14.7149C9.12951 14.4233 9.49351 14.3146 9.78509 14.4721C10.184 14.6875 10.9126 14.9611 11.7379 15.0402C12.5593 15.1191 13.4254 15.0015 14.1643 14.5027C14.439 14.3173 14.8119 14.3897 14.9973 14.6643C15.1827 14.939 15.1103 15.3119 14.8357 15.4973C13.7968 16.1985 12.6259 16.3309 11.6233 16.2348C10.6244 16.1389 9.74194 15.8125 9.21491 15.5279C8.92334 15.3705 8.81461 15.0065 8.97206 14.7149Z" />
        <path d="M16 9.79926V8.70074C16 8.32198 15.6554 8 15.25 8C14.8446 8 14.5 8.32196 14.5 8.70074V9.79926C14.5 10.178 14.8446 10.5 15.25 10.5C15.6554 10.5 16 10.178 16 9.79926Z" />
    </React.Fragment>,
    'Faceprint'
);
