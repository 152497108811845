import { addSeconds } from 'date-fns';
import { ZOOM_LEVELS, TIME_TRAVEL_DIRECTIONS, ZOOM_LEVELS_V2 } from '../../timelineConstants';
import { checkArgsTypes } from '../../../../utils';

export const isTimeInRange = (time, start, end) => {
    checkArgsTypes([time, start, end], ['time', 'start', 'end'], 'number');
    return start <= time && time <= end;
};

export const getDefaultZoomLevel = (minTimelineStart, maxTimelineEnd, isV2 = false) => {
    checkArgsTypes([minTimelineStart, maxTimelineEnd], ['minTimelineStart', 'maxTimelineEnd'], 'number');
    if (isV2) {
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS_V2.HOURS_24) {
            return ZOOM_LEVELS_V2.HOURS_24;
        }
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS_V2.HOURS_8) {
            return ZOOM_LEVELS_V2.HOURS_8;
        }
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS_V2.HOURS_2) {
            return ZOOM_LEVELS_V2.HOURS_2;
        }
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS_V2.MINUTES_10) {
            return ZOOM_LEVELS_V2.MINUTES_10;
        }

        return ZOOM_LEVELS_V2.MINUTES_5;
    } else {
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS.HOURS_8) {
            return ZOOM_LEVELS.HOURS_8;
        }
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS.HOURS_1) {
            return ZOOM_LEVELS.HOURS_1;
        }
        if (maxTimelineEnd - minTimelineStart >= ZOOM_LEVELS.MINUTES_10) {
            return ZOOM_LEVELS.MINUTES_10;
        }

        return ZOOM_LEVELS.MINUTES_1;
    }
};

//TODO see about abstracting the switch statements

export const getNewTimelineStart = (
    currentStartTime,
    currentEndTime,
    { timelineMinStart, timelineMaxEnd, zoomLevel, direction }
) => {
    if (direction === TIME_TRAVEL_DIRECTIONS.FUTURE) {
        return Math.min(timelineMaxEnd - zoomLevel, currentEndTime - zoomLevel / 2);
    } else if (direction === TIME_TRAVEL_DIRECTIONS.PAST) {
        return Math.max(timelineMinStart, currentStartTime - zoomLevel / 2);
    }
};

export const getNewStartOnZoomOut = (timelineMinStart, currentEnd, zoomLevel) => {
    return Math.max(timelineMinStart, currentEnd - zoomLevel);
};

export const getNewStartOnZoomIn = ({ timelineMaxEnd, timelineMinStart, selectedTime, zoomLevel }) => {
    checkArgsTypes(
        [timelineMaxEnd, timelineMinStart, selectedTime, zoomLevel],
        ['timelineMaxEnd', 'timelineMinStart', 'selectedTime', 'zoomLevel'],
        'number'
    );
    return Math.min(timelineMaxEnd - zoomLevel, Math.max(timelineMinStart, selectedTime - zoomLevel / 2));
};

export const getCurrentEndTime = (timelineMaxEnd, currentStart, zoomLevel) => {
    return Math.min(timelineMaxEnd, currentStart + zoomLevel);
};

const getEventBlockEndTime = (eventOccurred, zoomLevel) => {
    //need events to have different width so they are visible
    switch (zoomLevel) {
        case ZOOM_LEVELS.HOURS_8:
            return addSeconds(Date.parse(eventOccurred), 300);
        case ZOOM_LEVELS.HOURS_1:
            return addSeconds(Date.parse(eventOccurred), 30);
        case ZOOM_LEVELS.MINUTES_10:
            return addSeconds(Date.parse(eventOccurred), 10);
        case ZOOM_LEVELS.MINUTES_1:
            return addSeconds(Date.parse(eventOccurred), 1);
        default:
            return addSeconds(Date.parse(eventOccurred), 10);
    }
};

/**
 * The functions formatEventDataforTimeline and formatClipsForTimeline
 * both store clip or event info in the id. This is so we don't need to do a linear
 * search by id for the original item when when it's clicked.
 */

export const formatEventDataForTimeline = (events, zoomLevel, isV2 = false) => {
    return (
        events?.map((event) => ({
            id: JSON.stringify({ type: 'event', event }),
            group: 1,
            start: Date.parse(event.occurred),
            end: Date.parse(getEventBlockEndTime(event.occurred, zoomLevel)),
            className: isV2 ? `event ${event.severity}` : 'event',
        })) ?? []
    );
};

export const formatClipsForTimeline = (clipsFromListingObject, { timelineMinStart, timelineMaxEnd }) => {
    if (!timelineMinStart || !timelineMaxEnd) {
        throw new Error('cannot format clips for timeline without timelineMinStart, timelineMaxEnd');
    }
    const clipArray = [];
    clipsFromListingObject &&
        Object.keys(clipsFromListingObject).forEach((camera) => {
            clipsFromListingObject[camera].forEach((clip) => {
                const start = Math.max(clip.startTime, timelineMinStart);
                const end = Math.min(clip.endTime ?? Date.parse(new Date()), timelineMaxEnd);
                clipArray.push({
                    start,
                    end,
                    id: JSON.stringify({ type: 'videoClip', cameraId: clip.cameraId, startTime: start, endTime: end }),
                    group: 1,
                    className: 'clip',
                });
            });
        });
    return clipArray;
};

export const getVisibleEventCount = (events, currentStart, currentEnd) => {
    checkArgsTypes([currentStart, currentEnd], ['currentStart', 'currentEnd'], 'number');
    return events?.filter((event) => isTimeInRange(Date.parse(event.occurred), currentStart, currentEnd))?.length ?? 0;
};

export const formatTimelineMinorLabels = (date, zoomLevel, language) => {
    if (zoomLevel === ZOOM_LEVELS.MINUTES_1) {
        return new Date(date).toLocaleString(language, { second: 'numeric' });
    } else {
        return new Date(date).toLocaleString(language, { hour: 'numeric', minute: 'numeric' });
    }
};

export const formatTimelineMajorLabels = (date, zoomLevel, language) => {
    if (zoomLevel === ZOOM_LEVELS.MINUTES_1) {
        return new Date(date).toLocaleString(language, {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
    } else {
        return new Date(date).toLocaleString(language, { month: 'long', day: 'numeric' });
    }
};

export const getEventById = (events, selectedId) => {
    return events?.filter((event) => event.id === selectedId)[0] ?? null;
};

//see vis timeline docs https://visjs.github.io/vis-timeline/docs/timeline/
export const getTimelineOptions = ({ minStart, timelineStart, maxEnd, timelineEnd, language, zoomLevel }) => {
    if (!minStart || !timelineStart || !maxEnd || !timelineEnd || !language || !zoomLevel) {
        throw new Error(
            'timeline config missing one of: minStart, timelineStart, maxEnd, timelineEnd, language, zoomLevel'
        );
    }

    return {
        stack: false,
        zoomable: false,
        maxHeight: 39,
        verticalScroll: false,
        min: minStart,
        max: maxEnd,
        start: timelineStart,
        end: timelineEnd,
        showCurrentTime: false,
        maxMinorChars: 8,
        format: {
            minorLabels: (date) => {
                return formatTimelineMinorLabels(date, zoomLevel, language);
            },
            majorLabels: (date) => {
                return formatTimelineMajorLabels(date, zoomLevel, language);
            },
        },
    };
};
