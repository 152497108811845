import { makeStyles } from '@brivo/react-components';

export const useStyles = makeStyles((theme) => ({
    red: {
        '--red-stop-color': theme.palette.colors.red500,
    },
    green: {
        '--green-stop-color': theme.palette.colors.green500,
    },
}));
