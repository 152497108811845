import React from 'react';
import TimelineItem from './components/TimelineItem';
import styles from './styles';
const EventTimeline = ({ events, hour, canViewUserPage, handleClickEventCamera, selectedClipClicked, eventRef }) => {
    const classes = styles();
    return (
        <div className={classes.timelineContainer} data-testid={`event-timeline-${hour}`}>
            {events.map((event) => {
                return (
                    <TimelineItem
                        key={event.id}
                        event={event}
                        canViewUserPage={canViewUserPage}
                        handleClickEventCamera={handleClickEventCamera}
                        selectedClipClicked={selectedClipClicked}
                        eventRef={eventRef}
                    />
                );
            })}
        </div>
    );
};
export default EventTimeline;
