import { useState, useEffect } from 'react';
import { dashboardsApi } from '../../common/webApis/myAnalytics/dashboardsApi';

const useDashboardData = () => {
    const [dashboards] = useState(null);

    const getDashboards = async () => {
        const configurations = [];
        try {
            const response = await dashboardsApi.getAnalyticsDashboards();

            response.getAnalyticsDashboardsForAdmin.map((data) => {
                configurations.push({
                    ...JSON.parse(data.configurations),
                    key: data.dashboardNameKey,
                    name: data.dashboardName,
                    sharingType: data.sharingType,
                    isDefault: data.isDefault,
                    ownerAdminId: data.ownerAdminId,
                });
            });

            return [configurations, getDashboards];
        } catch (e) {
            console.error(e.message);
            // We need erro handling
        }
    };

    useEffect(() => {
        getDashboards();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [dashboards, getDashboards];
};

export default useDashboardData;
