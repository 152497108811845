import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon - card - duotone">
                <g id="icon - card">
                    <g id="Line_Icons">
                        <g id="Group">
                            <path
                                id="Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4 4H20C21.104 4 22 4.898 22 6V18C22 19.103 21.104 20 20 20H4C2.896 20 2 19.103 2 18V6C2 4.898 2.896 4 4 4ZM4 6V18H19.997L19.999 6H4Z"
                                fill="#485769"
                            />
                        </g>
                    </g>
                    <g id="Group 2">
                        <path
                            id="Path"
                            d="M6.23671 15.7846C6.23671 13.8209 7.47298 12.5846 9.43671 12.5846C11.4004 12.5846 12.6367 13.8209 12.6367 15.7846H6.23671Z"
                            fill="#485769"
                        />
                        <circle id="Oval" cx="9.36612" cy="10.1538" r="2" fill="#485769" />
                    </g>
                    <rect id="Rectangle" x="14" y="9" width="4" height="2" fill="#7DA6DB" />
                    <rect id="Rectangle_2" x="14" y="13" width="4" height="2" fill="#7DA6DB" />
                </g>
            </g>
        </svg>
    </React.Fragment>,
    'RecentActivityUsingCard'
);
