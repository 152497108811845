import { PROMISE_CANCELLED } from '../constants/Constants';

export const cancellablePromise = (promise) => {
    const isCancelled = { value: false };
    const wrappedPromise = new Promise((res, rej) => {
        promise
            .then((d) => {
                return isCancelled.value ? rej(new Error(PROMISE_CANCELLED)) : res(d);
            })
            .catch((e) => {
                rej(isCancelled.value ? new Error(PROMISE_CANCELLED) : e);
            });
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            isCancelled.value = true;
        },
    };
};

export const debouncePromise = (fn, ms = 0) => {
    const aborters = [];
    const pending = [];
    const timeouts = [];

    const promiseCanceler = () => {
        const currentTimeouts = [...timeouts];
        timeouts.length = 0;
        currentTimeouts.forEach((t) => {
            clearTimeout(t);
        });
        const currentAborters = [...aborters];
        aborters.length = 0;
        currentAborters.forEach((aborter) => {
            if (typeof aborter === 'function') aborter();
        });
        // Cancel Previous Promises;
        const currentPending = [...pending];
        pending.length = 0;
        currentPending.forEach(({ reject }) => {
            return reject(new Error(PROMISE_CANCELLED));
        });
    };
    return (...args) => {
        const debouncingPromise = new Promise((res, rej) => {
            promiseCanceler();
            const timeoutId = setTimeout(() => {
                const currentAborters = [...aborters];
                aborters.length = 0;
                currentAborters.forEach((aborter) => {
                    if (typeof aborter === 'function') aborter();
                });
                const { value, aborter } = fn.apply(this, args);
                Promise.resolve(value).then(
                    (data) => {
                        return res(data);
                    },
                    (error) => {
                        return rej(error);
                    }
                );
                aborters.push(aborter);
            }, ms);
            timeouts.push(timeoutId);
            pending.push({ resolve: res, reject: rej });
        });
        // Provide a way to cancel the promise without scheduling a new one
        debouncingPromise.cancel = promiseCanceler;
        return debouncingPromise;
    };
};

// Simple fetch implementation for promise debouncing
export const cancelableFetcher = (url) => {
    const controller = new AbortController();
    const signal = controller.signal;
    return {
        value: fetch(url, { signal }),
        aborter: () => {
            controller.abort();
        },
    };
};
