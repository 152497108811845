import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    noVisualFoundWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    noVisualFoundTitle: {
        fontWeight: '600',
        fontSize: '18px',
    },
    noVisualFoundSubtitle: {
        fontWeight: '400',
        fontSize: '14px',
        color: theme.palette.colors.grey300,
    },
}));

export default useStyles;
