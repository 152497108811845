import React, { Suspense, useContext } from 'react';
import { get } from 'lodash';
import UserAvatar from './UserAvatar';

import { UserContext } from '../../../user/UserProvider';

const UserAvatarContainer = (props) => {
    const userContext = useContext(UserContext);
    const newUser = get(userContext, 'userInfo', {});

    return (
        <Suspense fallback={null}>
            <UserAvatar {...props} user={newUser} />
        </Suspense>
    );
};

export default UserAvatarContainer;
