import React, { useMemo, useState } from 'react';
import usePropertyListFiltering from '../hooks/usePropertyListFiltering';
import PropertyList from './PropertyList/PropertyList';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TransformSiteModal from '@pages/AccountSettings/Sites/Properties/PropertyListWrapper/TransformSiteModal/TransformSiteModal';

const PropertyListContainer = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [transformModalOpened, setTransformModalOpened] = useState(false);

    const {
        sites,
        filters,
        totalCount,
        loadNextPage,
        isLoading,
        filterChangeMethods,
        clearingValues,
        setClearingValues,
        reloadList,
    } = usePropertyListFiltering(0, true);

    const titleProps = useMemo(() => {
        return {
            title: t('Page.property-list-section.title'),
            subtitle: t('Page.property-list.table.subtitle'),
            emptyComponentTitle: t('Page.property-list.empty.title'),
            emptyComponentSubtitle: t('Page.property-list.empty.subtitle'),
            buttonText: t('Page.property-list.create-button'),
            filterPlaceholder: t('Page.property-list.filter.placeholder'),
            filterTitle: t('Page.property-list.table.title'),
        };
    }, [t]);

    const onCreateSiteClick = () => {
        history.push(`/properties/create-property`, { parentId: 0 });
    };

    const onViewClick = (id) => {
        history.push(`/properties/${id}`);
    };

    const onTransformSiteClick = () => {
        setTransformModalOpened(true);
    };

    const closeModal = () => {
        setTransformModalOpened(false);
    };

    const context = {
        sites,
        totalCount,
        isLoading,
        filterValues: filters,
        titleProps,
        redirectId: 0,
        filterChangeMethods,
        loadNextPage: loadNextPage,
        onCreateSiteClick,
        onTransformSiteClick,
        onViewClick,
        clearingValues,
        setClearingValues,
    };

    return (
        <>
            <PropertyList context={context} />
            <TransformSiteModal open={transformModalOpened} closeModal={closeModal} reloadList={reloadList} />
        </>
    );
};
export default PropertyListContainer;
