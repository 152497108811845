import { useRef } from 'react';

export function useRunOnce(cb) {
    const hasRun = useRef(false);

    if (!hasRun.current) {
        hasRun.current = true;
        cb();
    }
}
