import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        downloadIconPreview: {
            color: theme.palette.colors.grey25, //note, value hardcoded to grey25, as this text displays over the video preview image, it cannot change with light mode
        },
        downloadIconSansPreview: {
            color: theme.palette.brivoAliases.primaryTextColor,
        },
    };
});
export default styles;
