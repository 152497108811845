import * as Yup from 'yup';
import axios from 'axios';
import { CAMERA, DOOR, PANEL, EDITION_DEMO, EDITION_ENTERPRISE } from '@common/constants/Constants';
import { eventSeverity } from './constants';

export const FloorPlanDetailsSchema = (t) => {
    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .min(2, t('Page.floor-plans.validation.name-short'))
            .max(30, t('Page.floor-plans.validation.name-long'))
            .required(t('Page.floor-plans.validation.name-required')),
        filename: Yup.string().required(t('Page.floor-plans.validation.image-required')),
    });
};

export const getDeviceType = (deviceType) => {
    return deviceType === 'DEVICE' ? DOOR : deviceType === 'CAMERA' ? CAMERA : PANEL;
};

export const mapFloorPlanDevices = (devices, devicesCount) => {
    return devices.map((device) => {
        const {
            count = 0,
            informationalCount = 0,
            warningCount = 0,
            criticalCount = 0,
            severity = '',
        } = devicesCount?.find((deviceCount) => deviceCount.deviceId === device.deviceId) ?? {};

        return {
            deviceId: device.deviceId,
            count,
            informationalCount,
            warningCount,
            criticalCount,
            severity,
            top: device.top,
            left: device.left,
            rotation: device.rotation,
            name: device.deviceName,
            deviceType: getDeviceType(device?.deviceType),
        };
    });
};

export const mapFloorPlanDetails = (floorPlanResponse, devices) => {
    return {
        floorPlanId: floorPlanResponse.floorPlanId,
        floorPlanName: floorPlanResponse.name,
        floorPlanImage: floorPlanResponse.filename + '.' + floorPlanResponse.contentType,
        devices: devices,
        floorPlanImageURL: floorPlanResponse.floorPlanImageURL,
    };
};

export const extractFloorPlanDevices = (floorPlanResponse) => {
    const devices = floorPlanResponse?.getFloorPlanById?.devices?.map((device) => ({
        id: device.deviceId,
        top: device.top,
        left: device.left,
        rotation: device.rotation,
        name: device.deviceName,
        deviceType: getDeviceType(device?.deviceType),
    }));
    return devices;
};

export const uploadFileToUrl = (url, fileObject) => {
    return axios({
        method: 'PUT',
        url: url,
        headers: {
            'Content-Type': fileObject.type,
        },
        data: fileObject,
    });
};

export const getEventClassArrayBySeverityId = (id, infoEvents, warningEvents, criticalEvents) => {
    switch (id) {
        case eventSeverity.INFO.id:
            return infoEvents;
        case eventSeverity.WARNING.id:
            return warningEvents;
        case eventSeverity.CRITICAL.id:
            return criticalEvents;
        default:
            return [...infoEvents, ...warningEvents, ...criticalEvents];
    }
};

export const isPanelEvent = (event) =>
    event?.data?.objectId && event?.data?.objectName && event?.eventType === 'PANEL_EVENT';

export const removeDuplicateSites = ([...floorPlans]) => {
    let newList = [];
    floorPlans.forEach((plan) => {
        if (!newList.find((el) => el.siteId === plan.siteId)) {
            newList.push(plan);
        }
    }, []);
    return newList;
};

export const checkEditionForFloorPlans = (edition) => {
    const floorPlansEditions = [EDITION_DEMO, EDITION_ENTERPRISE];
    return floorPlansEditions.includes(edition);
};
