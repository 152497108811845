import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { isProduction } from '../constants/Constants';

const HOTJAR_ID = '3679550';
const HOTJAR_VERSION = 6; // Current version is '6'

export default function HotjarTracking() {
    useEffect(() => {
        if (isProduction) {
            hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);
        }
    }, []);

    return <></>;
}
