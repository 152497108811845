import React, { Suspense, lazy } from 'react';
const LiveVideoTabs = lazy(() => import('./LiveVideoTabs'));

const LiveVideoContainer = (props) => {
    return (
        <Suspense fallback={null}>
            <LiveVideoTabs {...props} />
        </Suspense>
    );
};

export default LiveVideoContainer;
