import { WebReqApi } from '../WebReqApi';
import {
    API_ROVER_DATA_V2_URL,
    API_ROVER_REPORT_V2_URL,
    API_ROVER_COLUMNS_V2_URL,
    API_ROVER_METADATA_V2_URL,
    API_ROVER_ASYNC_DATA_V2_URL,
    API_ROVER_ASYNC_RESULT_V2_URL,
    API_ROVER_ASYNC_REPORT_V2_URL,
    API_ROVER_ASYNC_REPORT_RESULT_V2_URL,
    API_ROVER_ASYNC_UNIQUE_VALUES_V2_URL,
    API_ROVER_ASYNC_UNIQUE_VALUES_RESULT_V2_URL,
    PROMISE_CANCELLED,
} from '../../constants/Constants';
import { GET_SITES_FOR_OCCUPANCY } from './gqlTags';
import { ROVER_API_ASYNC_PROBING_INTERVAL } from './constants';
import { debouncePromise } from '../../utils/promiseUtils';
import { v4 as uuidv4 } from 'uuid';
import i18n, { t } from 'i18next';
import { isEmpty } from 'lodash';

function updateResultsWithTranslatableColumns(results, uuids) {
    results.forEach((item) => {
        uuids.forEach((uuid) => {
            //eslint-disable-next-line
            if (item.hasOwnProperty(uuid)) {
                item[uuid] = t(item[uuid]);
            }
        });
    });
    return results;
}

function updateUniqueValuesWithTranslatableColumns(results) {
    results.forEach((item) => {
        item.unique_values.forEach((value) => {
            if (typeof value.value === 'string' && i18n.exists(value.value)) {
                value.value = t(value.value);
            }
        });
    });
    return results;
}

function extractUUIDsForTranslatableColumns(query) {
    return query.columns.filter((column) => column.id && column.id.startsWith('i18_')).map((column) => column.uuid);
}

function createQueryMap(initialRequests, initialResponses, rowIndexStart, rowIndexEnd) {
    const queryList = [];

    initialResponses.forEach((response, response_idx) => {
        const mainQueryId = response.queryId;

        if (initialRequests[response_idx]?.query) {
            const queryArray = response.resultQueryIds.map((item, idx) => {
                const queryChunk = Array.isArray(initialRequests[response_idx].query)
                    ? initialRequests[response_idx].query[idx]
                    : initialRequests[response_idx].query;
                return {
                    snowflakeQueryId: item.snowflakeQueryId,
                    query: queryChunk,
                    i18_uuids: extractUUIDsForTranslatableColumns(queryChunk),
                };
            });
            const pivotConfigurations = initialRequests[response_idx]?.pivotConfigurations;
            queryList.push({
                queryId: mainQueryId,
                resultQueryIds: queryArray,
                ...(rowIndexStart && { rowIndexStart: rowIndexStart }),
                ...(rowIndexEnd && { rowIndexEnd: rowIndexEnd }),
                ...(!isEmpty(pivotConfigurations) && { pivotConfigurations: pivotConfigurations }),
            });
        } else {
            throw new Error(`Missing corresponding query for response index ${response_idx}`, response);
        }
    });
    return queryList;
}

function createQueryMapForUniqueValues(initialRequests, initialResponses, rowIndexStart, rowIndexEnd) {
    const newResponse = initialResponses.map((response) => {
        return {
            queryId: response.queryId,
            columnQueryIds: Object.keys(response.columnQueryIds)
                .map((key) => {
                    return {
                        [key]: {
                            ...response.columnQueryIds[key],
                            ...(rowIndexStart && { rowIndexStart: rowIndexStart }),
                            ...(rowIndexEnd && { rowIndexEnd: rowIndexEnd }),
                            query: initialRequests, //this probably needs to change at some point
                            uuid: key,
                            i18n_uuids: [], //TODO
                        },
                    };
                })
                .reduce((acc, curr) => {
                    let key = Object.keys(curr)[0];
                    acc[key] = curr[key];
                    return acc;
                }, {}),
        };
    }); //awful awful awful

    return newResponse;
}

class RoverApi extends WebReqApi {
    // 'query' is the JS object that describes query to be sent
    // 'expiration' is a unix timestamp that defines the absolute point of time before which the results are still valid
    // default expiration is Date.now() + 1 minute
    async getData(query) {
        const uuids = extractUUIDsForTranslatableColumns(query.query);
        Object.assign(query, {
            queryId: `${uuidv4()}`,
        });
        return this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_DATA_V2_URL,
            body: [query],
            additionalHeaders: {
                Accept: 'application/json',
            },
        }).then((data) => {
            const responseResults = data[0].results;
            updateResultsWithTranslatableColumns(responseResults, uuids);
            return responseResults;
        });
    }

    asyncReportResultGetter(queryList, resolve, reject, timeoutContainer) {
        this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_ASYNC_REPORT_RESULT_V2_URL,
            body: queryList,
            additionalHeaders: {
                Accept: 'application/json',
            },
        })
            .then((result) => {
                const firstResult = result[0];
                if (firstResult?.url) {
                    resolve(firstResult);
                } else {
                    timeoutContainer.timeout = setTimeout(() => {
                        this.asyncReportResultGetter(queryList, resolve, reject, timeoutContainer);
                    });
                }
            })
            .catch((err) => {
                reject(err);
            });
    }

    asyncUniqueValuesResultGetter(queryList, resolve, reject, timeoutContainer, initialResponse) {
        this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_ASYNC_UNIQUE_VALUES_RESULT_V2_URL,
            body: queryList,
            additionalHeaders: {
                Accept: 'application/json',
            },
        })
            .then((result) => {
                const firstResult = result[0];
                if (firstResult?.results) {
                    updateUniqueValuesWithTranslatableColumns(firstResult.results);

                    resolve([firstResult, initialResponse]);
                } else {
                    timeoutContainer.timeout = setTimeout(() => {
                        this.asyncUniqueValuesResultGetter(
                            queryList,
                            resolve,
                            reject,
                            timeoutContainer,
                            initialResponse
                        );
                    });
                }
            })
            .catch((err) => {
                reject(err);
            });
    }

    asyncQueryResultGetter(queryList, resolve, reject, timeoutContainer) {
        this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_ASYNC_RESULT_V2_URL,
            body: queryList,
            additionalHeaders: {
                Accept: 'application/json',
            },
        })
            .then((result) => {
                const firstResult = result[0];
                if (firstResult?.queryId && Array.isArray(firstResult?.results)) {
                    firstResult.resultQueryIds = queryList[0].resultQueryIds;
                    firstResult.queryId = queryList[0].queryId;
                    const uinion_i18_uuids = firstResult.resultQueryIds.reduce((i18_uuids, querySet) => {
                        if (querySet.i18_uuids?.length > 0) {
                            for (const uuid of querySet.i18_uuids) {
                                i18_uuids.add(uuid);
                            }
                        }
                        return i18_uuids;
                    }, new Set());
                    if (uinion_i18_uuids?.size > 0) {
                        updateResultsWithTranslatableColumns(firstResult.results, uinion_i18_uuids);
                    }
                    resolve(firstResult);
                } else {
                    timeoutContainer.timeout = setTimeout(() => {
                        this.asyncQueryResultGetter(queryList, resolve, reject, timeoutContainer);
                    }, ROVER_API_ASYNC_PROBING_INTERVAL);
                }
            })
            .catch((err) => {
                reject(err);
            });
    }

    fetchMoreDataAsync_V2(queryList) {
        const cancelerContainer = {
            canceler: null,
        };
        const timeoutContainer = {
            timeout: null,
        };

        const promise = new Promise((resolve, reject) => {
            this.asyncQueryResultGetter(queryList, resolve, reject, timeoutContainer);
            const cancelerFunc = () => {
                reject(new Error(PROMISE_CANCELLED));
            };
            cancelerContainer.canceler = cancelerFunc;
        });

        const cancel = () => {
            clearTimeout(timeoutContainer.timeout);
            if (typeof cancelerContainer.canceler === 'function') {
                cancelerContainer.canceler();
            }
        };

        return { promise, cancel };
    }

    getDataAsync_V2(query, rowIndexStart = 0, rowIndexEnd = 0) {
        const cancelerContainer = {
            canceler: null,
            canceled: false,
        };
        const timeoutContainer = {
            timeout: null,
        };

        const promise = new Promise((resolve, reject) => {
            Object.assign(query, {
                queryId: `${uuidv4()}`,
            });

            this.makeRestRequest({
                method: 'POST',
                url: API_ROVER_ASYNC_DATA_V2_URL,
                body: [query],
                additionalHeaders: {
                    Accept: 'application/json',
                },
            }).then((initialResponse) => {
                if (cancelerContainer.canceled) {
                    reject(new Error(PROMISE_CANCELLED));
                } else if (initialResponse?.length > 0) {
                    const queryList = createQueryMap([query], initialResponse, rowIndexStart, rowIndexEnd);

                    timeoutContainer.timeout = setTimeout(() => {
                        this.asyncQueryResultGetter(queryList, resolve, reject, timeoutContainer);
                    }, ROVER_API_ASYNC_PROBING_INTERVAL);

                    const cancelerFunc = () => {
                        reject(new Error(PROMISE_CANCELLED));
                    };
                    cancelerContainer.canceler = cancelerFunc;
                } else {
                    reject(new Error('Failed to get initial response'));
                }
            });
        });

        const cancel = () => {
            clearTimeout(timeoutContainer.timeout);
            cancelerContainer.canceled = true;
            if (typeof cancelerContainer.canceler === 'function') {
                cancelerContainer.canceler();
            }
        };

        return { promise, cancel };
    }

    async getData_V2(query) {
        const uuids = extractUUIDsForTranslatableColumns(query.query);
        Object.assign(query, {
            queryId: `${uuidv4()}`,
        });
        return this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_DATA_V2_URL,
            body: [query],
            additionalHeaders: {
                Accept: 'application/json',
            },
        }).then((data) => {
            const responseData = data[0];
            updateResultsWithTranslatableColumns(responseData.results, uuids);
            return responseData;
        });
    }

    async getMetadata_V2(query) {
        Object.assign(query, {
            queryId: `${uuidv4()}`,
        });
        return this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_METADATA_V2_URL,
            body: [query],
            additionalHeaders: {
                Accept: 'application/json',
            },
        }).then((data) => {
            const responseData = data[0];
            return responseData;
        });
    }

    async getSites() {
        return this.makeGqlQuery({
            gqlTag: GET_SITES_FOR_OCCUPANCY,
            defaultValue: [],
        });
    }

    async getReport(query) {
        const currentLanguage = i18n.language || 'en';
        Object.assign(query, {
            queryId: `${uuidv4()}`,
            i18language: currentLanguage,
        });
        return this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_REPORT_V2_URL,
            body: [query],
            additionalHeaders: {
                Accept: 'application/json',
            },
        }).then((data) => {
            if (data?.length > 0) {
                return data[0];
            }
        });
    }

    getReportAsync_V2(query) {
        const currentLanguage = i18n.language || 'en';

        const cancelerContainer = {
            canceler: null,
            canceled: false,
        };
        const timeoutContainer = {
            timeout: null,
        };

        const promise = new Promise((resolve, reject) => {
            Object.assign(query, {
                queryId: `${uuidv4()}`,
                i18language: currentLanguage,
            });

            this.makeRestRequest({
                method: 'POST',
                url: API_ROVER_ASYNC_REPORT_V2_URL,
                body: [query],
                additionalHeaders: {
                    Accept: 'application/json',
                },
            })
                .then((initialResponse) => {
                    if (cancelerContainer.canceled) {
                        reject(new Error(PROMISE_CANCELLED));
                    } else if (initialResponse?.length > 0) {
                        const queryList = createQueryMap([query], initialResponse);
                        timeoutContainer.timeout = setTimeout(() => {
                            this.asyncReportResultGetter(queryList, resolve, reject, timeoutContainer);
                        }, ROVER_API_ASYNC_PROBING_INTERVAL);

                        const cancelerFunc = () => {
                            reject(new Error(PROMISE_CANCELLED));
                        };
                        cancelerContainer.canceler = cancelerFunc;
                    } else {
                        reject(new Error('Failed to get initial response'));
                    }
                })
                .catch((err) => {
                    throw err;
                });
        });

        const cancel = () => {
            clearTimeout(timeoutContainer.timeout);
            cancelerContainer.canceled = true;
            if (typeof cancelerContainer.canceler === 'function') {
                cancelerContainer.canceler();
            }
        };

        return { promise, cancel };
    }

    async getReport_V2(query) {
        const currentLanguage = i18n.language || 'en';
        Object.assign(query, {
            queryId: `${uuidv4()}`,
            i18language: currentLanguage,
        });
        return this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_REPORT_V2_URL,
            body: [query],
            additionalHeaders: {
                Accept: 'application/json',
            },
        }).then((data) => {
            if (data?.length > 0) {
                return data[0];
            }
        });
    }

    async getColumns() {
        return this.makeRestRequest({
            method: 'POST',
            url: API_ROVER_COLUMNS_V2_URL,
            body: {},
            additionalHeaders: {
                Accept: 'application/json',
            },
        });
    }

    getUniqueValuesAsyncDebounce_V2 = debouncePromise(
        (query, rowIndexStart, rowIndexEnd) => this._getUniqueValuesAsync_V2(query, rowIndexStart, rowIndexEnd),
        500
    );

    getUniqueValuesResultsAsyncDebounce_V2 = debouncePromise(
        (query, initialResponse, rowIndexStart, rowIndexEnd) =>
            this._getUniqueValuesResultsAsync_V2(query, initialResponse, rowIndexStart, rowIndexEnd),
        500
    );

    _getUniqueValuesAsync_V2(query, rowIndexStart, rowIndexEnd) {
        const abortController = new AbortController();

        const timeoutContainer = {
            timeout: null,
        };

        const promise = new Promise((resolve, reject) => {
            Object.assign(query, {
                queryId: `${uuidv4()}`,
            });

            this.makeRestRequest({
                method: 'POST',
                url: API_ROVER_ASYNC_UNIQUE_VALUES_V2_URL,
                body: [query],
                additionalHeaders: {
                    Accept: 'application/json',
                },
            })
                .then((initialResponse) => {
                    if (initialResponse?.length > 0) {
                        const queryList = createQueryMapForUniqueValues(
                            query,
                            initialResponse,
                            rowIndexStart,
                            rowIndexEnd
                        );

                        timeoutContainer.timeout = setTimeout(() => {
                            this.asyncUniqueValuesResultGetter(
                                queryList,
                                resolve,
                                reject,
                                timeoutContainer,
                                initialResponse
                            );
                        }, ROVER_API_ASYNC_PROBING_INTERVAL);
                    } else {
                        reject(new Error('Failed to get initial response'));
                    }
                })
                .catch((err) => {
                    throw err;
                });
        });

        return {
            value: promise,
            aborter: () => {
                abortController.abort();
            },
        };
    }

    getUniqueValuesAsync_V2(query, rowIndexStart = 0, rowIndexEnd = 0) {
        const cancelerContainer = {
            canceler: null,
            canceled: false,
        };
        const timeoutContainer = {
            timeout: null,
        };

        const promise = new Promise((resolve, reject) => {
            Object.assign(query, {
                queryId: `${uuidv4()}`,
            });

            this.makeRestRequest({
                method: 'POST',
                url: API_ROVER_ASYNC_UNIQUE_VALUES_V2_URL,
                body: [query],
                additionalHeaders: {
                    Accept: 'application/json',
                },
            })
                .then((initialResponse) => {
                    if (cancelerContainer.canceled) {
                        reject(new Error(PROMISE_CANCELLED));
                    } else if (initialResponse?.length > 0) {
                        const queryList = createQueryMapForUniqueValues(
                            query,
                            initialResponse,
                            rowIndexStart,
                            rowIndexEnd
                        );
                        timeoutContainer.timeout = setTimeout(() => {
                            this.asyncUniqueValuesResultGetter(
                                queryList,
                                resolve,
                                reject,
                                timeoutContainer,
                                initialResponse
                            );
                        }, ROVER_API_ASYNC_PROBING_INTERVAL);

                        const cancelerFunc = () => {
                            reject(new Error(PROMISE_CANCELLED));
                        };
                        cancelerContainer.canceler = cancelerFunc;
                    } else {
                        reject(new Error('Failed to get initial response'));
                    }
                })
                .catch((err) => {
                    throw err;
                });
        });

        const cancel = () => {
            clearTimeout(timeoutContainer.timeout);
            cancelerContainer.canceled = true;
            if (typeof cancelerContainer.canceler === 'function') {
                cancelerContainer.canceler();
            }
        };

        return { promise, cancel };
    }

    getRoverQuery(inputs) {
        if (typeof inputs === 'string') {
            inputs = [
                {
                    prompt: inputs,
                },
            ];
        }
        return fetch('https://assistant-dev.rnd.brivo.com/convpredict', {
            method: 'POST',
            body: JSON.stringify({
                input: inputs,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.status === 200 ? response.json() : response.text();
        });
    }

    reportMyAssistantActionQuery(record) {
        return fetch('https://nlrs.rnd.brivo.com/api/v1/storePrompt', {
            method: 'POST',
            body: JSON.stringify(record),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        });
    }

    updateMyAssistantActionQuery(record) {
        return fetch('https://nlrs.rnd.brivo.com/api/v1/updatePrompt', {
            method: 'POST',
            body: JSON.stringify(record),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        });
    }

    getUniqueValuesResultsAsync_V2(query, initialResponse, rowIndexStart = 0, rowIndexEnd = 0) {
        const cancelerContainer = {
            canceler: null,
            canceled: false,
        };
        const timeoutContainer = {
            timeout: null,
        };

        const promise = new Promise((resolve, reject) => {
            const queryList = createQueryMapForUniqueValues(query, initialResponse, rowIndexStart, rowIndexEnd);

            this.asyncUniqueValuesResultGetter(queryList, resolve, reject, timeoutContainer);

            const cancelerFunc = () => {
                reject(new Error(PROMISE_CANCELLED));
            };
            cancelerContainer.canceler = cancelerFunc;
        });

        const cancel = () => {
            clearTimeout(timeoutContainer.timeout);
            cancelerContainer.canceled = true;
            if (typeof cancelerContainer.canceler === 'function') {
                cancelerContainer.canceler();
            }
        };

        return { promise, cancel };
    }

    _getUniqueValuesResultsAsync_V2(query, initialResponse, rowIndexStart, rowIndexEnd) {
        const abortController = new AbortController();

        const timeoutContainer = {
            timeout: null,
        };
        const promise = new Promise((resolve, reject) => {
            const queryList = createQueryMapForUniqueValues(query, initialResponse, rowIndexStart, rowIndexEnd);

            this.asyncUniqueValuesResultGetter(queryList, resolve, reject, timeoutContainer);
        });

        return {
            value: promise,
            aborter: () => {
                abortController.abort();
            },
        };
    }
}

export const roverApi = new RoverApi();
