import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    const filterActiveBorderColor = theme.palette.brivoAliases.filterActiveBorderColor;

    return {
        eventInfoContainer: {
            marginBottom: '10px',
        },
        eventTime: {
            height: '18px',
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.brivoAliases.primaryTextColor,
            float: 'left',
        },
        eventName: {
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            color: theme.palette.brivoAliases.primaryTextColor,
            maxWidth: 200,
            paddingLeft: '10px',
            float: 'left',
        },
        videoThumbnail: {
            height: '95px',
            width: '128px',
            cursor: 'pointer',
            border: '3px solid transparent',
            borderRadius: '3px',
            '&.Selected': { border: `3px solid ${filterActiveBorderColor}` },
            '&.SingleCamera': { marginLeft: '30px', height: '95px', width: '138px' },
        },
        timelineGroupSpacer: {
            height: '30px',
        },
    };
});

export default styles;
