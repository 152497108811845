import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    menuItems: {
        '&:hover $starIcon': {
            visibility: 'visible',
        },
    },
    starIcon: {
        visibility: 'hidden',
        justifyContent: 'flex-end',
        minWidth: 'unset', // reduce container width

        '& .MuiSvgIcon-root': {
            fill: 'none',
            stroke: theme.palette.type === 'dark' ? theme.palette.colors.grey0 : theme.palette.colors.grey900,
        },
    },
    defaultStyle: {
        visibility: 'visible',
        cursor: 'not-allowed',

        '& .MuiSvgIcon-root': {
            fill: theme.palette.type === 'dark' ? theme.palette.colors.blue400 : theme.palette.colors.blue700,
            stroke: theme.palette.type === 'dark' ? theme.palette.colors.blue400 : theme.palette.colors.blue700,
        },
    },
    createViewIcon: {
        position: 'sticky',
        bottom: '0px',
        padding: theme.spacing(0.5, 0.8),
    },
}));

export default useStyles;
