import { useEffect, useState } from 'react';
import useStyles from './styles';
import ApiHelper from '@/common/helpers/Helpers';
import { withApi } from '@brivo/onairplus-services';
import { Box } from '@brivo/react-components';
import { Query, Filter, Column } from '@/pages/NewReports/common/interfaces';
import ColumnFilterFactory from '../ColumnFilterFactory.tsx';
import { useTranslation } from 'react-i18next';

interface ColumnEditorProps {
    disabled: boolean;
    query: Query;
}

const ColumnFilters = ({ query, disabled }: ColumnEditorProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [factoryMapping, setFactoryMapping] = useState<any>(null);

    /**
     * Hook that creates a mapping of column.uuid -> filter
     * It is under contract that column.uuid is equal to filter.uuid
     */
    useEffect(() => {
        const factoryMappingData = new Map();

        // Set to null as a placeholder
        query.query.columns.forEach((column: Column) => {
            factoryMappingData.set(column.uuid, null);
        });

        query.query.filters.forEach((filter: Filter) => {
            factoryMappingData.set(filter?.value?.uuid, filter);
        });

        setFactoryMapping(factoryMappingData);
    }, [query]);

    return (
        <>
            <h4 className={classes.columnEditor}>{t('Page.new-reports.column-editor')}</h4>
            <Box className={classes.filters}>
                {factoryMapping &&
                    Array.from(factoryMapping, ([columnUuid, filter]) => (
                        <ColumnFilterFactory columnUuid={columnUuid} filter={filter} disabled={disabled} />
                    ))}
            </Box>
        </>
    );
};
export default withApi(ColumnFilters, ApiHelper);
