import { WebReqApi } from '@common/webApis/WebReqApi';
import {
    CHECK_GROUP_LINKING,
    GET_PROPERTY_COMMON_GROUPS,
    GET_PROPERTY_COMMON_ONLINE_GROUP,
    GET_PROPERTY_MAIN_GROUP,
} from '@common/webApis/properties/gqlTags';

const API_PROPERTIES_URL = '/api/properties';

class PropertiesApi extends WebReqApi {
    createProperty(values) {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_PROPERTIES_URL}`,
            body: {
                siteName: values.name,
                timeZone: values.address.timeZone,
                address: {
                    streetAddress1: values.address.streetAddress1,
                    streetAddress2: values.address.streetAddress2,
                    city: values.address.city,
                    state: values.address.state,
                    postalCode: values.address.postalCode,
                    country: values.address.country,
                },
            },
        });
    }

    deleteProperty(propertyId) {
        return this.makeRestRequest({
            method: 'DELETE',
            url: `${API_PROPERTIES_URL}/${propertyId}`,
            body: null,
        });
    }

    transformProperty(siteId) {
        const url = `${API_PROPERTIES_URL}/${siteId}/transform`;
        return this.makeRestRequest({
            method: 'PUT',
            url: url,
            body: null,
        });
    }

    getPropertyLinkedCommonGroups(siteOid) {
        return this.makeGqlQuery({
            gqlTag: GET_PROPERTY_COMMON_GROUPS,
            variables: {
                siteOid,
            },
            defaultValue: { commonOnline: null, commonOffline: null },
        });
    }

    getPropertyLinkedMainGroup(siteOid) {
        return this.makeGqlQuery({
            gqlTag: GET_PROPERTY_MAIN_GROUP,
            variables: {
                siteOid,
            },
            defaultValue: { mainOnline: null },
        });
    }

    getPropertyLinkedCommonOnlineGroup(siteOid) {
        return this.makeGqlQuery({
            gqlTag: GET_PROPERTY_COMMON_ONLINE_GROUP,
            variables: {
                siteOid,
            },
            defaultValue: { commonOnline: null },
        });
    }

    checkGroupLink(groupOid) {
        return this.makeGqlQuery({
            gqlTag: CHECK_GROUP_LINKING,
            variables: {
                groupOid,
            },
        });
    }
}

export const propertiesApi = new PropertiesApi();
