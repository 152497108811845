import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        previewImageCameraName: {
            fontWeight: 400,
            fontSize: '12px',
            marginLeft: '4px',
            fontFamily: 'IBM Plex Sans',
            maxWidth: 95,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        previewImageCameraNamePreview: {
            color: theme.palette.colors.grey25, //note, value hardcoded to grey25, as this text displays over the video preview image, it cannot change with light mode
        },

        previewImageCameraNameSansPreview: {
            color: theme.palette.brivoAliases.primaryTextColor,
        },
    };
});
export default styles;
