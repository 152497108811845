import { useMemo } from 'react';
import { endOfDay } from 'date-fns';

import { useAssignments } from '../../../common/hooks/useAssignments';
import {
    ADMIN_VIEW_ADMINISTRATORS,
    DEVICES_VIEW_DEVICES,
    GROUPS_VIEW_GROUPS,
    CONFIG_SCHEDULES,
    CONFIG_HOLIDAYS,
    USER_VIEW_USERS,
} from '../../../common/permissions/Permissions';
import {
    adminsApi,
    devicesApi,
    groupsApi,
    holidaysApi,
    schedulesApi,
    sitesApi,
    usersApi,
} from '../../../common/webApis';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { validateObject } from '../../../common/utils/Utils';

import { EVENT_TARGET } from './constants';

function getDayOfYear(date) {
    let start = new Date(date.getFullYear(), 0, 0);
    let diff = date - start;
    let oneDay = 1000 * 60 * 60 * 24;
    let day = Math.floor(diff / oneDay);
    return day;
}

export const buildJournalGqlFilterFunction = (filters, from, to) => {
    let gqlFilters = { from: from, to: to };

    if (filters.securityActionIds && filters.securityActionIds.length > 0) {
        gqlFilters.securityActionIds = filters.securityActionIds?.map((action) => action.id);
    }

    if (filters?.eventActor?.data?.objectId) {
        gqlFilters.actorId = filters.eventActor.data.objectId;
    }

    if (filters?.eventTarget?.data?.objectId) {
        gqlFilters.eventTargetOids = [filters.eventTarget.data.objectId];
    }

    if (filters?.selectedDate) {
        if (filters?.selectedDate?.toString() !== 'Invalid Date') {
            if (getDayOfYear(filters.selectedDate) !== getDayOfYear(to)) {
                gqlFilters.to = endOfDay(filters.selectedDate);
            }
        }
    }

    return gqlFilters;
};

export function adminDataToOption(admin) {
    validateObject(admin, { truthyKeys: ['objectId'] });

    return {
        id: admin.objectId,
        text: ((admin.firstName || '') + ' ' + (admin.lastName || '')).trim(),
        subtext: admin.emailAddress || '',
        data: admin,
    };
}

export function userDataToOption(user) {
    validateObject(user, { truthyKeys: ['objectId'] });

    return {
        id: user.objectId,
        text: user.name || '',
        data: {
            ...user,
        },
    };
}

export function deviceDataToOption(device) {
    validateObject(device, { truthyKeys: ['objectId'] });

    return {
        id: device.objectId,
        text: device.name || '',
        subtext: device.siteName || '',
        data: device,
    };
}

export function groupDataToOption(group) {
    validateObject(group, { truthyKeys: ['id'] });

    return {
        id: group.id,
        text: group.name || '',
        data: {
            ...group,
            objectId: group.id, // "object_id" db column is named "id" in the requests' response (backend)
        },
    };
}

export function siteDataToOption(site) {
    validateObject(site, { truthyKeys: ['id'] });

    return {
        id: site.id,
        text: site.name || '',
        data: {
            ...site,
            objectId: site.id, // "object_id" db column is named "id" in the requests' response (backend)
        },
    };
}

export function scheduleDataToOption(schedule) {
    validateObject(schedule, { truthyKeys: ['id'] });

    return {
        id: schedule.id,
        text: schedule.name || '',
        data: {
            ...schedule,
            objectId: schedule.id, // "schedule_id" db column contains the object id value  (backend)
        },
    };
}

export function holidayDataToOption(holiday) {
    validateObject(holiday, { truthyKeys: ['id'] });

    return {
        id: holiday.id,
        text: holiday.name || '',
        data: {
            ...holiday,
            objectId: holiday.id, // "holiday_id" db column contains the object id value  (backend)
        },
    };
}

export function requestEventTargets(targetType, requestOptions) {
    if (targetType === EVENT_TARGET.Administrator) {
        return adminsApi.getAdminsForJournal(requestOptions);
    }
    if (targetType === EVENT_TARGET.User) {
        return usersApi.getUsersForJournal(requestOptions);
    }
    if (targetType === EVENT_TARGET.Device) {
        return devicesApi.getDevicesForJournal(requestOptions);
    }
    if (targetType === EVENT_TARGET.Group) {
        return groupsApi.getGroupsForJournal(requestOptions);
    }
    if (targetType === EVENT_TARGET.Site) {
        return sitesApi.getSitesForJournal(requestOptions);
    }
    if (targetType === EVENT_TARGET.Schedule) {
        return schedulesApi.getSchedulesForJournal(requestOptions);
    }
    if (targetType === EVENT_TARGET.Holiday) {
        return holidaysApi.getHolidaysForJournal(requestOptions);
    }

    throw new Error(`Invalid targetType: ${targetType}`);
}

export function useTargetTypeOptions() {
    const { hasSiteAssignments, hasGroupAssignments } = useAssignments();
    const { hasPermissions } = usePermissions();

    const targetTypeOptions = useMemo(() => {
        const opts = [];

        if (hasPermissions(ADMIN_VIEW_ADMINISTRATORS)) {
            opts.push(EVENT_TARGET.Administrator);
        }

        if (hasPermissions(USER_VIEW_USERS)) {
            opts.push(EVENT_TARGET.User);
        }

        if (hasPermissions(DEVICES_VIEW_DEVICES)) {
            opts.push(EVENT_TARGET.Device);
        }

        if (hasPermissions(GROUPS_VIEW_GROUPS) && hasGroupAssignments) {
            opts.push(EVENT_TARGET.Group);
        }

        if (hasSiteAssignments) {
            opts.push(EVENT_TARGET.Site);
        }

        if (hasPermissions(CONFIG_SCHEDULES)) {
            opts.push(EVENT_TARGET.Schedule);
        }

        if (hasPermissions(CONFIG_HOLIDAYS)) {
            opts.push(EVENT_TARGET.Holiday);
        }

        return opts;
    }, [hasGroupAssignments, hasPermissions, hasSiteAssignments]);

    return { targetTypeOptions };
}

export function extractTargetOptions(response, targetType) {
    if (targetType === EVENT_TARGET.Administrator) {
        return response.getAdministrators.map(adminDataToOption);
    }

    if (targetType === EVENT_TARGET.User) {
        return response.getUsers.map(userDataToOption);
    }

    if (targetType === EVENT_TARGET.Device) {
        return response.getDevices.map(deviceDataToOption);
    }

    if (targetType === EVENT_TARGET.Group) {
        return response.getAllGroups.groups.map(groupDataToOption);
    }

    if (targetType === EVENT_TARGET.Site) {
        return response.getSites.map(siteDataToOption);
    }

    if (targetType === EVENT_TARGET.Schedule) {
        return response.getSchedules.map(scheduleDataToOption);
    }

    if (targetType === EVENT_TARGET.Holiday) {
        return response.getHolidays.map(holidayDataToOption);
    }

    throw new Error(`Invalid targetType: ${targetType}`);
}
