import gql from 'graphql-tag';

export const GET_SITES_FOR_OCCUPANCY = gql`
    query getSites {
        getSites {
            name
            id
        }
    }
`;
