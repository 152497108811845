const SecurityActionMessages = {
    0: 'Unprocessable Event',
    100: 'Generic Read',
    101: 'Generic Write',
    102: 'Generic Append',
    103: 'Activate Devices',
    104: 'Run Report',
    105: 'Edit Report Configuration',
    106: 'Manage Report Permissions',
    107: 'Run Report Configuration',
    108: 'Schedule Report Configuration',
    500: 'Grant Permission',
    501: 'Revoke Permission',
    1000: 'Create Account',
    1002: 'Modify Account',
    1003: 'Remove Account',
    1004: 'Create Schedule',
    1005: 'Modify Schedule',
    1006: 'Remove Schedule',
    1007: 'Add Credentials',
    1008: 'Remove Credentials',
    1009: 'Create a Notification Rule',
    1010: 'Modify Notif Rule',
    1011: 'Remove a Notification Rule',
    1012: 'Create Holiday',
    1013: 'Modify Holiday',
    1014: 'Remove Holiday',
    1015: 'Remove Account Logs',
    1016: 'Account Activated',
    1101: 'Account Setting Updated',
    2001: 'Create Device',
    2002: 'Modify Device',
    2002.1: 'Edited Door',
    2002.2: 'Edited Aux Device',
    2003: 'Remove Device',
    2004: 'Open the Device (Door)',
    2005: 'Lock Keypad',
    2006: 'Auto Lock Unlock',
    2007: 'Create Control Panel',
    2008: 'Modify Control Panel',
    2009: 'Remove Control Panel',
    2010: 'Modify Brain',
    2011: 'Associate Elevator',
    2012: 'Antipassback Changed',
    2030: 'Create Lockdown Scenario',
    2031: 'Modify Lockdown Scenario',
    2032: 'Delete Lockdown Scenario',
    2033: 'Initiate Lockdown',
    2034: 'Clear Lockdown',
    2101: 'Modify API Admin Permissions',
    2102: 'Create New API Application',
    2103: 'Delete API Application',
    2104: 'Remove API Application Access',
    2105: 'Ban API Application',
    2106: 'Un-Ban API Application',
    2110: 'Download API Application Key',
    2500: 'Link Site with Intrusion Account',
    2501: 'Unlink Site with Intrusion Account',
    2502: 'Arm Intrusion Panel',
    2503: 'Disarm Intrusion Panel',
    2504: 'Edit Intrusion Panel',
    2505: 'Add Intrusion Panel',
    2506: 'Remove Intrusion Panel',
    2600: 'Link Site w/ Offline Lock Site',
    2601: 'Unlink site w/ Offline Lock site',
    2602: 'Add Offline Lock to Site',
    2603: 'Delete Offline Lock from Site',
    2604: 'Link Offline Lock Provider Account',
    2605: 'Unlink Offline Lock Provider Account',
    2606: 'Delete Offline Site',
    2700: 'Add Personnel Control Configuration',
    2701: 'Modify Personnel Control Configuration',
    2702: 'Delete Personnel Control Configuration',
    2703: 'Activate Personnel Control Configuration',
    2704: 'Deactivate Personnel Control Configuration',
    3001: 'Create User',
    3001.1: 'Created User',
    '3001.10': 'Created Administrator',
    3002: 'Modify User',
    3002.1: 'Edited User',
    '3002.10': 'Edited Administrator',
    3003: 'Remove User',
    3003.1: 'Deleted User',
    '3003.10': 'Deleted Administrator',
    3004: 'Assign Credential',
    3004.1: 'Assigned Credential to User',
    3005: 'User Login',
    3006: 'Manage Admin',
    3007: 'Modify User Status',
    3008: 'User Group Lock Down',
    3009: 'User Group Unlock Down',
    3010: 'Add Custom Field',
    3011: 'Update Custom Field',
    3012: 'Remove Custom Field',
    3013: 'Create Report',
    3014: 'Update Report',
    3015: 'Delete Report',
    3016: 'Revoke User Credential',
    3016.1: 'Revoked Credential from User',
    3017: 'User Locked Out',
    3018: 'Password Reset',
    3019: 'Administrator Settings Updated',
    3020: 'User Activated',
    3021: 'User Deactivated',
    3022: 'User Updated Security Question',
    3023: 'Create New Mobile Invitation',
    3024: 'Cancel Mobile Invitation',
    3030: 'Create Permission Template',
    3031: 'Update Permission Template',
    3032: 'Delete Permission Template',
    3033: 'Change Credential from Date',
    3034: 'Change Credential to Date',
    3035: 'Add Group Custom Field',
    3036: 'Update Group Custom Field',
    3037: 'Remove Group Custom Field',
    4001: 'Create Group',
    4002: 'Modify Group',
    4003: 'Remove Group',
    4004: 'Add Member to Group',
    4005: 'Remove Member from Group',
    4006: 'Update Group Antipassback',
    4007: 'Remove Member from All Groups',
    4101: 'SITE CREATED',
    4102: 'SITE MODIFY',
    4103: 'SITE DELETE',
    4104: 'DEVICE ADDED TO SITE',
    5001: 'Door Open Too Long',
    5002: 'Door Unlocked via Schedule',
    5003: 'Door Locked via Schedule',
    5004: 'Door Unlocked via Keypad',
    5005: 'Door Locked via Keypad',
    5006: 'Invalid Credential Threshold Passed',
    5007: 'Exit Switch Pressed',
    5008: 'Unauthorized Door Open',
    5009: 'Door Open',
    5010: 'Door Closed',
    5011: 'Door Ajar Resolved',
    5012: 'Failed Access Unknown Cred',
    5013: 'Failed Access Unassigned Card',
    5014: 'Failed Access Deleted User',
    5015: 'Failed Access Old Credential',
    5016: 'Failed Access Out of Schedule',
    5017: 'Failed Access User Not Enabled',
    5018: 'Failed Access Unauthorized Door',
    5019: 'Auxiliary Relay Activated',
    5020: 'Auxiliary Relay Deactivated',
    5021: 'Control Panel Switch to Battery',
    5022: 'Control Panel AC Power Loss',
    5023: 'Control Panel Battery Level Percent',
    5024: 'Control Panel Unit Opened',
    5025: 'Control Panel (Brain): Unit Closed',
    5026: 'Control Panel Disk Space Low',
    5027: 'Control Panel Memory Low',
    5028: 'Hardware Interface Disconnected',
    5029: 'HW Interface Reconnected',
    5030: 'PIC Reset (MASTER)',
    5031: 'PIC Reset (DOOR)',
    5032: 'Disk Space Low Cleared',
    5033: 'Memory Low Cleared',
    5034: 'Undownloaded Credential',
    5035: 'Panel Startup',
    5036: 'Unconfigured Device',
    5037: 'Device Engaged',
    5038: 'Device Disengaged',
    5039: 'Device Wire Cut',
    5040: 'Device Wire Cut Cleared',
    5041: 'Board Communication Failed',
    5042: 'Board Communication Regained',
    5043: 'Board Battery Failure Set',
    5044: 'Board Battery Failure Cleared',
    5045: 'Board Tamper Set',
    5046: 'Board Tamper Cleared',
    5047: 'Board Chip Reset',
    5048: 'Wire Short',
    5049: 'Wire Short Cleared',
    5050: 'Schedule Unlock Overriden',
    5051: 'Schedule Unlock Override Ended',
    5052: 'Can Bus Unconfigured Error',
    5053: 'Can Bus Collision Error',
    5054: 'Panel Information',
    5055: 'Schedule Activated by Enabling Group',
    5056: 'Invalid 2nd Factor: Unknown Card',
    5057: 'Invalid 2nd Factor: Unknown PIN',
    5058: 'Invalid 2nd Factor: Invalid Card',
    5059: 'Invalid 2nd Factor: Two Cards',
    5060: 'Invalid 2nd Factor: Two PINs',
    5061: '2nd Factor Not Given',
    5062: 'Invalid Data ',
    5063: 'Antipassback Failure',
    5064: 'Administrator Pulse Output',
    5065: 'Failed Access: User Deactivated',
    5066: 'Camera Connect',
    5067: 'Camera Disconnect',
    5068: 'Camera Motion',
    5069: 'Failed Access: Invalid Credential Type (Card Required)',
    5070: 'Invalid Credential Type (Card Required) Unknown User',
    5071: 'Failed Access Panel Comm Fail',
    5072: 'No Permission Threat Access',
    5073: 'Offline Access',
    5074: 'Offline Access: Unknown Cred',
    5077: 'Local Verification: Access Granted',
    5078: 'Local Verification: Unknown Cred',
    5080: 'Failed Access: Proximity',
    5081: 'Unauthorized Access Detected',
    5082: 'Execute Command',
    5083: 'Schedule Deactivated',
    5084: 'Schedule Activated Command Sent to Panel',
    5085: 'Schedule Deactivated Command Sent to Panel',
    5090: 'Door Override Removed',
    5091: 'Door Override Set',
    5092: 'Door Override Set',
    5100: 'Command Triggered by User',
    5202: 'Salto Router Hello',
    5203: 'Salto Door Lock Rex',
    5204: 'Salto Door Open Metallic Key',
    5205: 'Salto Door Open PPD',
    5206: 'Salto Door Open Code',
    5207: 'Salto Door Lock Intrusion Alarm',
    5208: 'Salto Lock Intrusion Alarm Clear',
    5209: 'Salto Lock Reject Low Battery',
    5210: 'Salto Door Lock Low Battery',
    5211: 'Communication Lost Node',
    5212: 'Communication Restored Node',
    5213: 'Salto Router Connection Dropped',
    5214: 'Fail Enter Salto Router Offline',
    5215: 'Salto Router Added to Control Panel',
    5216: 'Salto Router Updated on Control Panel',
    5217: 'Salto Router Removed from Control Panel',
    5218: 'IPAC Pulse Output',
    5219: 'IPAC Call Called',
    5220: 'IPAC Call Connected',
    5221: 'IPAC Call Terminated',
    5222: 'Create IPAC Device',
    5223: 'Modify IPAC Device',
    5224: 'Delete IPAC Device',
    5225: 'Create Directory',
    5226: 'Modify Directory',
    5227: 'Delete Directory',
    5228: 'Create Resident',
    5229: 'Modify Resident',
    5230: 'Delete Resident',
    5231: 'Door Lock Privacy Override',
    '5231.journal': 'Privacy Mode Override',
    5232: 'Failed Entrance due to Privacy Mode',
    5233: 'Add Wireless Router',
    5234: 'Update Wireless Router',
    5235: 'Remove Wireless Router',
    5236: 'Wireless Door Lock Critical Battery',
    5300: 'Panel Communication Failure',
    5400: 'Panel Firmware Upgrade',
    5500: 'Camera Motion In Region Detected',
    5501: 'Camera License Plate Read',
    5502: 'Camera Loiter Detection',
    5503: 'Camera Tamper Detection',
    5504: 'Camera Object Line Crossing',
    5505: 'Camera Object Intrusion',
    5506: 'Second factor a known but bad credential',
    5507: 'Both factors known, but both are the same credential type',
    6001: 'Add DVR',
    6002: 'Update DVR',
    6003: 'Delete DVR',
    6101: 'Add Camera',
    6102: 'Update Camera',
    6103: 'Delete Camera',
    6104: 'Register Camera',
    6105: 'Unregister Camera',
    6106: 'Enable Capture',
    6107: 'Disable Capture',
    6108: 'Update Camera Subscription Model',
    6109: 'Reset Camera',
    6110: 'Restart Camera',
    6111: 'Add Video Provider',
    6112: 'Update Video Provider',
    6113: 'Delete Video Provider',
    6114: 'Video Configuration Updated',
    6115: 'Cameras Imported',
    6200: 'CSR OVR Viewed OVR Camera',
    6201: 'Panels Swapped by CSR',
    6202: 'Panel Deregistered by CSR',
    6501: 'RUN AS TOKEN GRANTED',
    6502: 'SERVICE TOKEN GRANTED',
    6503: 'SERVICE TOKEN DELETED',
    7001: 'Add Global Badge Template',
    7002: 'Edit Global Badge Template',
    7101: 'Add Localized Badge Template',
    7102: 'Edit Localized Badge Template',
    7201: 'Add Template Field',
    7202: 'Edit Template Field',
    7301: 'Add Localized Item',
    7302: 'Edit Localized Item',
    7303: 'Delete Localized Item',
    7401: 'Create Badge Template',
    7402: 'Edit Badge Template',
    7403: 'Delete Badge Template',
    7404: 'Print Badge Template',
    7501: 'Create Report Configuration',
    7502: 'Edit Report Configuration',
    7503: 'Delete Report Configuration',
    7504: 'Create Report Schedule',
    7505: 'Edit Report Schedule',
    7506: 'Delete Report Schedule',
    7507: 'Create Report Job',
    7508: 'Cancel Report Job',
    7509: 'Delete Report Job',
    7510: 'Report Job Complete',
    7600: 'Console Group Created',
    7601: 'Console Group Modified',
    7602: 'Console Group Deleted',
    7700: 'Enable Two-Factor Auth',
    7701: 'Disable Two-Factor Auth',
    7702: 'Setup Two-Factor Auth',
    7703: 'Edit Two-Factor Auth',
    7704: 'Reset Two-Factor Auth',
    7705: 'Create Event Subscription',
    7706: 'Modify Event Subscription',
    7707: 'Delete Event Subscription',
    7708: 'Gateway Disconnected',
    7709: 'Gateway Reconnected',
    7710: 'Wireless Lock Disconnected',
    7711: 'Wireless Lock Reconnected',
    7720: 'Start Peakface',
    7721: 'Completed Peakface',
    7722: 'Failed Peakface',
    7723: 'No Peakface Frame Detected',
    7724: 'Peakface Not Run',
    7900: 'Door Station Call Was Initiated',
    7901: 'Door Station User-Device Association Added',
    7902: 'Door Station User-Device Association Edited',
    7903: 'Door Station User-Device Association Deleted',
    7904: 'Door Station Call Was Not Initiated',
    7905: 'Door Station Call Was Answered',
    7906: 'Door Station Call Was Unanswered',
    7907: 'Door Station Call Failed',
    8000: 'Add Brivo Visitor',
    8001: 'Edit Brivo Visitor',
    8002: 'Deactivate Brivo Visitor',
    8003: 'Admin Sent Ignore Schedule Request',
    8004: 'Admin Sent Follow Schedule Request',
    8020: 'Occupancy Tracking Activated',
    8021: 'Occupancy Tracking Updated',
    8022: 'Occupancy Tracking Deactivated',
    8057: 'User photo removed',
    door: 'Door',
    floor: 'Floor',
};

export default SecurityActionMessages;
