import { makeStyles } from '@brivo/react-components';

export const useStyles = makeStyles((theme) => ({
    error: {
        border: `1px solid ${theme.palette.colors.red400}`,
        borderRadius: 3,
    },
    errorText: {
        '& .error-message': {
            marginTop: '.5rem',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300}`,

            '& span': {
                marginTop: '.1rem',
            },
        },
        color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300}`,
    },
    avatar: {
        backgroundColor: theme.palette.brivoAliases.avatarBackgroundColor,

        '& .MuiSvgIcon-root': {
            '& path': {
                fill: theme.palette.brivoAliases.avatarTextColor,
            },
        },
    },
}));
