import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path d="M7.682 15.366c-2.117 0-3.84-1.723-3.84-3.84s1.723-3.84 3.84-3.84 3.84 1.723 3.84 3.84-1.722 3.84-3.84 3.84zm0-5.76c-1.06 0-1.92.86-1.92 1.92 0 1.059.86 1.92 1.92 1.92s1.92-.861 1.92-1.92c0-1.06-.86-1.92-1.92-1.92zM15.362 23.046H.002v-.96c0-3.392 3.157-5.76 7.68-5.76 4.522 0 7.68 2.368 7.68 5.76v.96zm-13.287-1.92H13.29c-.575-1.736-2.72-2.88-5.608-2.88-2.888 0-5.034 1.144-5.608 2.88zM17.282 12.504l-.188-.037c-1.927-.385-5.572-2.082-5.572-5.741V.006h11.52v6.72c0 3.66-3.645 5.356-5.572 5.74l-.188.038zm-3.84-10.578v4.8c0 2.638 3.049 3.606 3.842 3.814.81-.208 3.838-1.162 3.838-3.814v-4.8h-7.68z" />
        <path d="M20.162 4.806L18.242 4.806 18.242 2.886 16.322 2.886 16.322 4.806 14.402 4.806 14.402 6.726 16.322 6.726 16.322 8.646 18.242 8.646 18.242 6.726 20.162 6.726z" />
    </React.Fragment>,
    'Administrators'
);
