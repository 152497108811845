import { useTranslation } from 'react-i18next';
import { WarningEventLineIcon, EmptyStateSection } from '@brivo/react-components';

const NoVideoAvailable = ({ cameraName, clipError }) => {
    const { t } = useTranslation();
    const subtitleMessage = cameraName
        ? t('Page.recorded-video.camera-name', { cameraName })
        : t('Page.recorded-video.select-a-clip');

    const detailsMessage = clipError?.message ? t(clipError.message) : null;

    return (
        <EmptyStateSection
            title={
                clipError?.message ? t('Page.recorded-video.video-failed') : t('Page.recorded-video.no-video-available')
            }
            subtitle={subtitleMessage}
            details={detailsMessage}
            icon={
                clipError?.message && (
                    <WarningEventLineIcon style={{ height: 100, width: 100 }} data-testid="WarningEventLineIcon" />
                )
            }
        />
    );
};

export default NoVideoAvailable;
