import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        previewFooterContainer: {
            display: 'flex',
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '105px',
        },
        previewImageGradient: {
            background: 'linear-gradient(184.69deg, rgba(25, 35, 47, 0) 7.78%, rgba(25, 35, 47, 0.74) 72.03%)',
        },

        previewImage: {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: '90px',
            maxHeight: '100px',
            display: 'flex',
            direction: 'column',
            alignItems: 'flex-end',
            backgroundImage: ({ imgSrc }) => `url(${imgSrc})`,
        },
        previewSubstituteCard: {
            minHeight: '90px',
            background: theme.palette.brivoAliases.newCardBackgroundColor,
        },
        previewSubstitute: {
            minHeight: '60px',
            width: '128px',
            display: 'flex',
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        warningIcon: {
            fill: theme.palette.colors.grey400,
        },
    };
});

export default styles;
