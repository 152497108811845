import { useMemo } from 'react';
import { Grid, Typography } from '@brivo/react-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const DraggableSection = ({ type, direction, sectionWidgets, width, getSection, isEditViewEnabled }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const emptySubItems = !sectionWidgets.length;

    const sectionWidgetsMemo = useMemo(() => {
        return sectionWidgets.map((item, index) => (
            <Grid item container xs={width} key={item.widgetId} alignItems="stretch">
                <Draggable
                    key={item.widgetId}
                    draggableId={item.widgetId}
                    index={index}
                    isDragDisabled={!isEditViewEnabled}
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ width: '100%', height: '100%', minHeight: '100%' }}
                        >
                            {item.widgetType
                                ? getSection(item.widgetType, item.filters, index, width, isEditViewEnabled)
                                : null}
                        </div>
                    )}
                </Draggable>
            </Grid>
        ));
    }, [sectionWidgets, width, getSection, isEditViewEnabled]);

    return (
        <Droppable
            droppableId={type.toString()}
            type="droppableSubItem"
            direction={direction}
            style={{ overflow: 'hidden' }}
        >
            {(provided, snapshot) => (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${emptySubItems ? classes.emptyList : classes.list} ${
                        snapshot.isDraggingOver ? classes.isDraggingOver : ''
                    }`}
                    alignItems="stretch"
                >
                    {sectionWidgetsMemo}
                    {emptySubItems && (
                        <Grid item container xs={12} justifyContent="center" alignItems="center">
                            <Typography variant="body1" color="textSecondary">
                                {t('Page.unified-dashboard.add-widgets.empty-state.drag')}
                            </Typography>
                        </Grid>
                    )}
                    {provided.placeholder}
                </Grid>
            )}
        </Droppable>
    );
};

export default DraggableSection;
