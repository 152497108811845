import { WebReqApi } from '../WebReqApi';
import { GET_INTEGRATIONS_INFO, GET_INTEGRATIONS_INFO_BY_NAME, SAVE_INTEGRATION_STATE } from './gqlTags';

const API_APPLICATIONS_URL = '/api/applications';

class IntegrationsApi extends WebReqApi {
    getIntegrationsInfo = async () => {
        return this.makeGqlQuery({
            gqlTag: GET_INTEGRATIONS_INFO,
        });
    };

    getIntegrationsInfoByName = async (name) => {
        return this.makeGqlQuery({
            gqlTag: GET_INTEGRATIONS_INFO_BY_NAME,
            variables: {
                name,
            },
        });
    };

    saveIntegrationInfoState = async (name, enabled) => {
        return this.makeGqlMutation({
            gqlTag: SAVE_INTEGRATION_STATE,
            variables: {
                name,
                state: enabled,
            },
        });
    };

    getApplications = async () => {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_APPLICATIONS_URL}`,
        });
    };

    getApplicationInfo = async (id) => {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_APPLICATIONS_URL}/${id}`,
        });
    };

    deleteApplication = async (id) => {
        return this.makeRestRequest({
            method: 'DELETE',
            url: `${API_APPLICATIONS_URL}/${id}`,
        });
    };
}

export const integrationApi = new IntegrationsApi();
