import 'lazysizes';
import 'regenerator-runtime/runtime';
import 'lazysizes/plugins/bgset/ls.bgset.min.js';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { CssBaseline, SnackbarProvider } from '@brivo/react-components';
// TODO -> use this LDProvider import instead of the async, and pass it a client instance?
// NOTE -> this provider does not seem to use an initialized instance

import { CustomThemeProvider } from '@brivo/react-components';
import { AuthContext, FlagContextProvider, FlagClient } from '@brivo/onairplus-services';

import './index.css';
import i18n from './i18n';
import { store } from './redux/Store';
import ApplicationRoutes from './routes';
import * as serviceWorker from './serviceWorker';

import { auth } from '@common/utils/Auth';
import UserProvider from '@common/user/UserProvider';
import HotjarTracking from '@common/utils/HotjarTracking';
import DataDogTracking from '@common/utils/DataDogTracking';
import { WebSocketProvider } from '@common/hooks/useSocket';
import ErrorBoundary from '@common/components/ErrorBoundary';
import { LAUNCH_DARKLY_CLIENT_ID } from '@common/constants/Constants';
import CustomApolloProvider from '@common/utils/CustomApolloProvider';
import GlobalNotifications from '@common/components/GlobalNotifications';

// This is temporary
// Will be removed when we will provide a way from the UI to change language
// Suits the demo purpose
window.i18n = i18n;
window.changeLanguage = (name) => {
    i18n.changeLanguage(name);
};

// Add the browser warning by default if it's not set
const lsBrowserWarning = localStorage.getItem('isBrowserWarningVisible');

if (!lsBrowserWarning) {
    localStorage.setItem('isBrowserWarningVisible', 'true');
}

// TODO -> don't need an authProvider context if auth is a singleton

const Root = () => {
    return (
        <CustomThemeProvider>
            <SnackbarProvider>
                <CssBaseline />
                <Provider store={store}>
                    <AuthContext.Provider value={auth}>
                        <CustomApolloProvider auth={auth}>
                            <UserProvider>
                                <WebSocketProvider>
                                    <GlobalNotifications />
                                    <ApplicationRoutes />
                                </WebSocketProvider>
                            </UserProvider>
                        </CustomApolloProvider>
                    </AuthContext.Provider>
                </Provider>
            </SnackbarProvider>
        </CustomThemeProvider>
    );
};

(async () => {
    /* TODO -> NOTE
        both using await ldClient.waitForInitialization(); AND ldClient.on('ready', () => resolve()); seem to
        yeild similar results, however, the time to process is vastly different (200ms vs 70ms)
        This requires further investigation.
    */

    const flagClient = new FlagClient(
        LAUNCH_DARKLY_CLIENT_ID,
        {
            kind: 'user',
            key: 'initializing',
        },
        {
            sendEventsOnlyForVariation: true,
        }
    );
    await flagClient.waitForInitialization();

    auth.setFlagClient(flagClient);
    // TODO -> IDEA -> have a gqlClient.setFlagClient()
    await auth.initialize();

    render(
        <FlagContextProvider client={flagClient}>
            <I18nextProvider i18n={i18n}>
                <ErrorBoundary sectionName="Application">
                    <DataDogTracking />
                    <HotjarTracking />
                    <Root />
                </ErrorBoundary>
            </I18nextProvider>
        </FlagContextProvider>,
        document.getElementById('content-mount')
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
