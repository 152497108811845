import * as sitesTypes from '../../types/Sites.types';
import * as apiActions from '../../actions/Api.actions';
import { API_SITES_URL } from '../../../common/constants/Constants';

export const sitesMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        switch (action.type) {
            case sitesTypes.FETCH_SITES:
                dispatch(
                    apiActions.apiRequest(null, 'GET', API_SITES_URL, action.payload.meta.token, sitesTypes.FETCH_SITES)
                );
                break;

            default:
                break;
        }
    };
