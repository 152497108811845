import gql from 'graphql-tag';

export const LIST_ADMINS_WITH_LIMIT_QUERY = gql`
    query getAdminsWithLimit(
        $pageSize: Int!
        $offset: Int!
        $filters: AdminFilter
        $sortField: String
        $direction: String
    ) {
        getAdminsWithLimit(
            limit: { rows: $pageSize, offset: $offset }
            filters: $filters
            sort: { field: $sortField, direction: $direction }
        ) {
            count
            total
            capStatus
            limit
            administrators {
                id
                onairObjectId
                isPrimary
                lastLogin
                customAssignment
                adminInfo {
                    firstName
                    lastName
                    email
                    created
                    migrated
                }
                roles {
                    id
                    name
                }
            }
        }
    }
`;
