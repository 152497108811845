import gql from 'graphql-tag';

export const GET_USERS_FOR_JOURNAL_PAGE_TAG = gql`
    query users($name: String!, $rows: Int = 10) {
        getUsers(limit: { offset: 0, rows: $rows }, filters: { name: $name }) {
            objectId
            name
        }
    }
`;

export const GET_CONSENT_LISTING = gql`
    query getConsentListing(
        $pageSize: Int!
        $offset: Int!
        $sortField: String
        $direction: String
        $filters: [ListFilter]
    ) {
        getConsentListing(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            totalCount
            consents {
                id
                firstName
                middleName
                lastName
                consentStatus
                userObjectId
                avatar
            }
        }
    }
`;

export const REVOKE_CONSENT_INVITE_BY_USERID = gql`
    mutation revokeConsentInviteByUserId($userId: Int!) {
        revokeConsentInviteByUserId(userId: $userId) {
            uuid
        }
    }
`;

export const GET_BADGE_PHOTO_QUALITY = gql`
    query getBadgePhotoQuality($userId: Int!) {
        getBadgePhotoQuality(userId: $userId) {
            success
            quality
            acceptability
        }
    }
`;
