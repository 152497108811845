const VALID_EVENT_NAMES = ['ready'];

export class EventCallbacks {
    eventListeners = {};

    constructor() {
        VALID_EVENT_NAMES.forEach((name) => (this.eventListeners[name] = []));
    }

    on(event, callback) {
        validateEvent(event);

        this.eventListeners[event].push(callback);
    }

    callListenersFor(event) {
        validateEvent(event);

        const listeners = this.eventListeners[event];
        if (!Array.isArray(listeners)) return;

        while (listeners.length > 0) {
            const callback = listeners.shift();
            callback();
        }
    }
}

function validateEvent(event) {
    if (typeof event !== 'string') throw new Error(`Invalid event type ${typeof event}. Expected "string".`);
    if (!VALID_EVENT_NAMES.includes(event)) throw new Error(`"${event}" is not a valid event.`);
}
