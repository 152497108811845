import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 -1.76132e-07 13.9706 -3.93402e-07 9C-6.10673e-07 4.02944 4.02944 6.10673e-07 9 3.93402e-07C13.9706 1.76132e-07 18 4.02944 18 9Z"
                fill="#A5B4C4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 4C18 8.97056 13.9706 13 9 13C4.02944 13 -1.76132e-07 8.97056 -3.93402e-07 4C-4.56209e-07 2.56316 0.336706 1.20496 0.93552 7.45912e-07C2.40822 2.96344 5.46628 5 9 5C12.5337 5 15.5918 2.96344 17.0645 4.08929e-08C17.6633 1.20496 18 2.56316 18 4Z"
                fill="white"
                fillOpacity="0.3"
            />
        </svg>
    </React.Fragment>,
    'MobilePass'
);
