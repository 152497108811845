import React, { Suspense, lazy } from 'react';

const NotFound = lazy(() => import('./NotFound'));

const NotFoundContainer = (props) => (
    <Suspense fallback={null}>
        <NotFound {...props} />
    </Suspense>
);

export default NotFoundContainer;
