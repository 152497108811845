import { makeStyles } from '@brivo/react-components';
const styles = makeStyles((theme) => {
    return {
        detailIconContainer: {
            display: 'flex',
            maxWidth: '16px',
            marginRight: '12px',
            marginBottom: '8px',
            flexDirection: 'column',
            alignItems: 'center',
        },
        detailTextContainer: {
            display: 'flex',
        },
        detailText: {
            alignSelf: 'center',
            maxWidth: '300px',
            marginBottom: '8px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontFamily: 'IBM Plex Sans',
            fontWeight: 400,
            fontSize: '12px',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey200 : theme.palette.colors.grey600,
        },
        linkStyle: {
            textDecoration: 'none',
        },
        linkText: {
            color: theme.palette.colors.blue400,
            fontSize: '14px',
            fontWeight: 600,
        },
    };
});
export default styles;
