import { useEffect, useState, useContext, useCallback } from 'react';
import useRoverQueries from '../../common/useRoverQueries';
import { convertQueryToCteWithAggregation } from '../PivotTable/utils';
import { DashboardConfigurationContext } from '../../common/DashboardBuilderContext';
import { PROMISE_CANCELLED } from '../../../../common/constants/Constants';
import { PAGINATION_ROW_COUNT } from '../../../../common/webApis/rover/constants';
import { formatData } from './utils';
import { useTranslation } from 'react-i18next';

export const useAnalyticsTableSetup = (query, dimensions, summary, queryModificationCallback, isTableOpen) => {
    const [{ configurationState, roverApiProxy, globalFiltersState }] = useContext(DashboardConfigurationContext);
    const [initialQuery, updateInitialQuery] = useRoverQueries(query);
    const [localQuery, setLocalQuery] = useState();
    const [localDimensions, setLocalDimensions] = useState(dimensions ?? []);
    const [data, setData] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [summaryData, setSummaryData] = useState(null);
    const [rowIndexStart, setRowIndexStart] = useState(0);
    const [rowIndexEnd, setRowIndexEnd] = useState(PAGINATION_ROW_COUNT);
    const [hasMore, setHasMore] = useState(false);
    const [shouldLoadMore, setShouldLoadMore] = useState(false);
    const [queryIds, setQueryIds] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const dataFormatter = localDimensions.filter((dim) => dim.formatter);

    const refreshData = (query, dimensions, isTableOpen) => {
        if (query && query?.query?.columns?.length > 0 && isTableOpen) {
            const [transformedQuery, transfromedDimensions] = convertQueryToCteWithAggregation(
                query,
                dimensions,
                null,
                t,
                true
            );
            setLocalQuery(transformedQuery);
            setLocalDimensions(transfromedDimensions);
            if (typeof queryModificationCallback === 'function') {
                queryModificationCallback(transformedQuery, transfromedDimensions);
            }
            setLoading(true);
            const { promise: dataPromise, cancel: dataCancel } = roverApiProxy.getDataAsync_V2(
                transformedQuery,
                0,
                PAGINATION_ROW_COUNT
            );
            dataPromise
                ?.then((result) => {
                    const data = result?.results;
                    const metadata = result?.metadata;
                    setData(formatData(data, dataFormatter));
                    setMetadata(metadata);
                    if (metadata.totalRowCount[0] > PAGINATION_ROW_COUNT) {
                        setRowIndexStart(PAGINATION_ROW_COUNT);
                        setRowIndexEnd(Math.min(2 * PAGINATION_ROW_COUNT, metadata.totalRowCount[0]));
                        setHasMore(true);
                        setQueryIds({
                            queryId: result.queryId,
                            resultQueryIds: result.resultQueryIds,
                        });
                    } else {
                        setHasMore(false);
                    }
                })
                .then(() => {
                    setLoading(false);
                    setErrors({ ...errors, dataRetrieval: false });
                })
                .catch((e) => {
                    if (e.message !== PROMISE_CANCELLED) {
                        console.error(e);
                        setData([]);
                        setLoading(false);
                        setErrors({ ...errors, dataRetrieval: true });
                    }
                });
            if (summary) {
                const { promise: summaryPromise, cancel: summaryCancel } = roverApiProxy.getDataAsync_V2(summary.query);
                summaryPromise
                    ?.then((result) => {
                        const summaryData = result?.results;
                        setSummaryData(summaryData[0].count); //TODO: don't hardcode accessor
                    })
                    .catch((e) => {
                        if (e.message !== PROMISE_CANCELLED) {
                            console.error(e);
                            setSummaryData([]);
                        }
                    });
                return () => {
                    dataCancel();
                    summaryCancel();
                };
            }
            return dataCancel;
        }
        setErrors({});
        setLoading(false);
        setIsLoading(false);
    };

    const dataLoadingCallback = useCallback(() => {
        const { promise: dataPromise, cancel: dataCancel } = roverApiProxy.fetchMoreDataAsync_V2([
            {
                ...queryIds,
                rowIndexStart: rowIndexStart,
                rowIndexEnd: rowIndexEnd,
            },
        ]);

        dataPromise
            ?.then((result) => {
                const data = result?.results;
                const metadata = result?.metadata;
                const incomingDataMaxIndex = Math.min(metadata.rowIndexEnd, metadata.totalRowCount[0]);
                setShouldLoadMore(false);
                setData((currentData) => {
                    if (
                        !currentData ||
                        (incomingDataMaxIndex > currentData.length && metadata.rowIndexStart === currentData.length)
                    ) {
                        return [...currentData, ...formatData(data, dataFormatter)];
                    } else {
                        // we are trying to load data twice or things are coming to us out of sequence
                        console.warn(
                            `Incompatible data chunk received, having data of size ${currentData.length} we received chunk that is supposed to carry items indexed from ${metadata.rowIndexStart} to ${incomingDataMaxIndex}`
                        );
                        return currentData;
                    }
                });
                if (metadata.rowIndexEnd >= metadata.totalRowCount[0]) {
                    setHasMore(false);
                } else {
                    setRowIndexStart((prev) => prev + PAGINATION_ROW_COUNT);
                    setRowIndexEnd((prev) => Math.min(prev + PAGINATION_ROW_COUNT, metadata.totalRowCount[0]));
                    setHasMore(true);
                }
            })
            .catch((e) => {
                if (e.message !== PROMISE_CANCELLED) {
                    setShouldLoadMore(false);
                    setHasMore(true);
                    console.error(e);
                }
            });

        return dataCancel;
    }, [queryIds, rowIndexStart, rowIndexEnd, setHasMore, setShouldLoadMore, dataFormatter, roverApiProxy]);

    useEffect(() => {
        if (shouldLoadMore) {
            setHasMore(false);
        }
        if (shouldLoadMore && !hasMore) {
            return dataLoadingCallback();
        }
    }, [shouldLoadMore, hasMore, setHasMore, dataLoadingCallback]);

    const loadMoreData = useCallback(() => {
        if (hasMore && !shouldLoadMore) {
            setShouldLoadMore(true);
        }
    }, [hasMore, shouldLoadMore]);

    /* eslint-disable */

    useEffect(() => {
        return refreshData(initialQuery, dimensions, isTableOpen);
    }, [
        JSON.stringify(initialQuery),
        JSON.stringify(configurationState),
        JSON.stringify(dimensions),
        JSON.stringify(globalFiltersState),
    ]);
    /* eslint-enable */

    useEffect(() => {
        updateInitialQuery(query);
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        dimensions: localDimensions,
        query: localQuery,
        loadMoreData: loadMoreData,
        loading: loading,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        errors: errors,
        setErrors: setErrors,
        hasMore: hasMore,
        data: data,
        setData: setData,
        metadata: metadata,
        summaryData: summaryData,
        loadTable: refreshData,
    };
};
