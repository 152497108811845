import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Typography } from '@brivo/react-components';
import {
    CheckboxField,
    ContentBlock,
    ErrorGreyIcon,
    TextField,
    SingleSelectField,
    AutocompleteSelectField,
    Tooltip,
    TriggerIcon,
    PageInfoIcon,
} from '@brivo/react-components';

import { useStyles } from './styles';
import { useGetAllDoors } from '../formComponents/MultiSelectDialog/useGetAllDoors';
import { useGetAllTriggers } from '../formComponents/MultiSelectDialog/useGetAllTriggers';
import MultiChipDisplayFieldWrapper from '../formComponents/MultiChipDisplayFieldWrapper/MultiChipDisplayFieldWrapper';
import DoorIcon from '../../../DeviceStatus/components/DeviceStatusPanel/DeviceCard/Icons/DoorIcon';
import { getAllDoors, getAllTriggers } from '../../../../common/webApis/lockdown/helpers';
import { EGRESS_SCENARIO, LOCKDOWN_SCENARIO } from '../../../../common/constants/Constants';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { LOCKDOWN_EDIT_LOCKDOWN_SCENARIO } from '../../../../common/permissions/Permissions';

const ScenarioForm = ({
    values,
    setFieldValue,
    accountHasDefaultScenario,
    errors,
    touched = {},
    setErrors,
    isCreateMode,
    assignedTriggers,
    isActiveScenario,
    isConformingStandardEdition,
    disabledTriggerSection,
    emergencyScenariosFlag,
    scrollMarginTop,
    formIsReset,
}) => {
    const [filteredTriggersForSE, setFilteredTriggersForSE] = useState([]);
    const [allTriggers, setAllTriggers] = useState([]);
    const [disabledTriggersForSE, setDisabledTriggersForSE] = useState(false);

    const { t } = useTranslation();
    const classes = useStyles();
    const { selectedTriggers, isDefaultScenario, selectedDoors, scenarioType } = values;

    useEffect(() => {
        if (isConformingStandardEdition) {
            getAllTriggers(null, null, 'name', 'ASC', [])
                .then((response) => {
                    const allLoadedTriggers = [...assignedTriggers, ...(response?.lockdownTriggers || [])];
                    setAllTriggers(allLoadedTriggers);
                    filterTriggersBySelectedDoors(selectedDoors, allLoadedTriggers);
                })
                .catch((error) => console.error(error));
        }
        //eslint-disable-next-line
    }, [isConformingStandardEdition]);

    const useGetAllDoorsResults = () =>
        useGetAllDoors({
            api: getAllDoors,
            defaultValues: selectedDoors,
            emergencyScenariosFlag,
            scenarioTypeId: scenarioType,
            selectedPanelId: isConformingStandardEdition && selectedTriggers?.[0]?.controlPanelId,
        });

    const useGetAllTriggersResults = () =>
        useGetAllTriggers({
            api: getAllTriggers,
            defaultValues: selectedTriggers,
            assignedItemsFromApi: assignedTriggers, //initial triggers from API
            showEgressScenarios: true,
        });

    const { hasPermissions } = usePermissions();

    const canEditLockdown = hasPermissions(LOCKDOWN_EDIT_LOCKDOWN_SCENARIO);

    const isDisabled = (!canEditLockdown && !isCreateMode) || isActiveScenario;

    const handleTriggersValueChange = (values) => {
        setFieldValue('selectedTriggers', values);
    };

    const handleTriggerValueChange = (_, value) => {
        if (value !== null) {
            setFieldValue('selectedTriggers', [value]);
        } else {
            setFieldValue('selectedTriggers', []);
        }
    };

    const filterTriggersBySelectedDoors = (selectedDoors, allTriggers) => {
        const uniquePanelIds = new Set(selectedDoors?.map((door) => door.controlPanelId));
        if (uniquePanelIds.size > 1) {
            setDisabledTriggersForSE(true);
        } else {
            setDisabledTriggersForSE(false);

            let displayedTriggers = allTriggers;
            if (uniquePanelIds.size === 1) {
                displayedTriggers = allTriggers.filter((trigger) => trigger.controlPanelId === [...uniquePanelIds][0]);
            }
            setFilteredTriggersForSE(displayedTriggers);
        }
    };

    useEffect(() => {
        const uniquePanelIds = new Set(selectedDoors?.map((door) => door.controlPanelId));

        if (formIsReset && uniquePanelIds.size > 1) {
            setDisabledTriggersForSE(true);
        }
    }, [formIsReset, selectedDoors]);

    const handleDoorsValueChange = (values) => {
        if (isConformingStandardEdition) {
            filterTriggersBySelectedDoors(values, allTriggers);
        }
        setFieldValue('selectedDoors', values);
        if (isEmpty(values)) {
            setErrors({
                ...errors,
                selectedDoors: t('Field.validation.required'),
            });
        } else {
            setErrors({ ...errors, selectedDoors: null });
        }
    };

    const handleCheckDefaultScenario = () => {
        setFieldValue('isDefaultScenario', !isDefaultScenario);
    };

    const scenarioTypeChangeHandler = (event) => {
        setFieldValue('scenarioType', event.target.value);
        // if the value in scenario type field changes
        if (scenarioType !== event.target.value) {
            // reset doors and triggers
            setFieldValue('selectedDoors', []);
            setFieldValue('selectedTriggers', []);
        }

        if (event.target.value) {
            setErrors({ ...errors, scenarioType: null });
        } else {
            setErrors({
                ...errors,
                scenarioType: t('Field.validation.required'),
            });
        }
    };

    const scenarioTypes = [
        { id: LOCKDOWN_SCENARIO, name: t('Page.emergency-scenario-create.page.form.scenarioType.lockdown') },
        { id: EGRESS_SCENARIO, name: t('Page.emergency-scenario-create.page.form.scenarioType.egress') },
    ];

    const getScenarioName = (scenarioType) => {
        return scenarioTypes.find((scenario) => scenario.id === scenarioType)?.name || '';
    };

    return (
        <ContentBlock
            title={t('Page.emergency-scenarios.emergency-scenario-details.page.section.title')}
            successMessage={t('Component.Save.save-success')}
            failureMessage={t('Component.Save.save-fail')}
        >
            <div className={clsx(classes.input, 'u-mt-full')}>
                <TextField
                    labelClassName={classes.label}
                    type="text"
                    name="name"
                    label={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.name')}
                    id="name"
                    maxLength={32}
                    placeholder={t('Page.lockdown-scenarios.emergency-scenario-details.page.form.name.placeholder')}
                    isDisabled={isDisabled}
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    required={true}
                    scrollMarginTop={scrollMarginTop}
                    error={touched.name && Boolean(errors.nameInput)}
                    helperText={touched.name && errors.nameInput}
                />
            </div>
            <div id="emergency-scenarios-details-wrapper" className={clsx(classes.scenarioTypeDropdown, 'u-mt-full')}>
                <SingleSelectField
                    label={t('Page.emergency-scenario-create.page.form.scenarioType.label')}
                    name="scenarioType"
                    selectedOption={getScenarioName(scenarioType)}
                    options={scenarioTypes}
                    handleChange={scenarioTypeChangeHandler}
                    className={classes.scenarioTypeText}
                    placeholder={t('Page.emergency-scenario-create.page.form.scenarioType.placeholder')}
                    isDisabled={!isCreateMode}
                    id="select-emergency-scenario-type"
                    error={touched.scenarioType && Boolean(errors.scenarioType)}
                    helperText={touched.scenarioType && errors.scenarioType}
                    required={true}
                />
            </div>
            <div className={disabledTriggerSection ? clsx(classes.disabledPointer, 'u-mt-full') : 'u-mt-full'}>
                <div style={{ display: 'flex' }}>
                    {
                        <span className={classes.triggersLabelContainer}>
                            <Typography
                                variant="subtitle2"
                                className={disabledTriggerSection ? classes.disabledLabel : classes.label}
                            >
                                {!isConformingStandardEdition
                                    ? t(
                                          'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers'
                                      )
                                    : t(
                                          'Page.lockdown-scenarios.lockdown-scenario-details.page.form.singleselect.trigger'
                                      )}
                            </Typography>
                            {!disabledTriggerSection && (
                                <Tooltip
                                    size="small"
                                    subtitle={t(
                                        'Page.lockdown-scenarios.lockdown-scenario-details.page.form.trigger.tooltip'
                                    )}
                                >
                                    <PageInfoIcon className={classes.triggersTooltip} />
                                </Tooltip>
                            )}
                        </span>
                    }
                    {disabledTriggerSection ? (
                        <Tooltip
                            title={
                                !isConformingStandardEdition
                                    ? t(
                                          'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers.disabledTooltip'
                                      )
                                    : t(
                                          'Page.lockdown-scenarios.lockdown-scenario-details.page.form.singleselect.trigger.disabledTooltip'
                                      )
                            }
                            size={'medium'}
                            placement={'top'}
                        >
                            <ErrorGreyIcon />
                        </Tooltip>
                    ) : null}
                </div>
                {!isConformingStandardEdition ? (
                    <MultiChipDisplayFieldWrapper
                        key={`allTriggers`}
                        id="allTriggers"
                        noSelectionLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers.no-selection'
                        )}
                        value={selectedTriggers}
                        onValueChange={handleTriggersValueChange}
                        title={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.triggers.title'
                        )}
                        emptySectionTitle={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.empty-state-triggers'
                        )}
                        headers={[
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column1'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column2'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column3'
                            ),
                        ]}
                        useGetAllItems={useGetAllTriggersResults}
                        tableLegendLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.table-legend.triggers'
                        )}
                        property={'lockdownTriggers'}
                        avatarIcon={<TriggerIcon />}
                        disabled={isDisabled}
                        disabledTriggerSection={disabledTriggerSection}
                    />
                ) : (
                    <AutocompleteSelectField
                        name="lockdownTriggers"
                        selectedOption={selectedTriggers?.[0]}
                        options={filteredTriggersForSE}
                        handleChange={handleTriggerValueChange}
                        className={classes.input}
                        placeholder={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.singleselect.trigger.no-selection'
                        )}
                        isDisabled={disabledTriggersForSE || disabledTriggerSection || isDisabled}
                        id="select-trigger"
                        error={touched.selectedTriggers && Boolean(errors.selectedTriggers)}
                        helperText={touched.selectedTriggers && errors.selectedTriggers}
                        disableClearable={false}
                        required={false}
                    />
                )}
            </div>
            {values.scenarioType && (
                <div className={'u-mt-full'}>
                    <Typography variant="subtitle2" className={classes.label}>
                        {t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors')} *
                    </Typography>
                    <MultiChipDisplayFieldWrapper
                        key={`allDoors`}
                        id="allDoors"
                        noSelectionLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.no-selection'
                        )}
                        value={selectedDoors}
                        onValueChange={handleDoorsValueChange}
                        error={!!errors.selectedDoors}
                        helperText={errors.selectedDoors}
                        title={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.title')}
                        emptySectionTitle={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.empty-state-doors'
                        )}
                        headers={[
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column1'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column2'
                            ),
                            t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column3'
                            ),
                        ]}
                        useGetAllItems={useGetAllDoorsResults}
                        tableLegendLabel={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.table-legend.doors'
                        )}
                        property={'lockdownDoors'}
                        avatarIcon={<DoorIcon />}
                        disabled={isDisabled}
                        scenarioTypeId={scenarioType}
                        scrollMarginTop={scrollMarginTop}
                    />
                </div>
            )}
            {values.scenarioType === LOCKDOWN_SCENARIO && (
                <div className={clsx('u-mt-double', classes.checkboxWrapper)}>
                    {accountHasDefaultScenario ? (
                        <Typography variant="caption" className={clsx(classes.label, 'u-mb-half')}>
                            {t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.default-scenario-exists')}
                        </Typography>
                    ) : (
                        <CheckboxField
                            id={'default'}
                            label={t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.default-scenario')}
                            checked={isDefaultScenario}
                            onChange={handleCheckDefaultScenario}
                            disabled={isDisabled}
                        />
                    )}
                </div>
            )}
        </ContentBlock>
    );
};

export default ScenarioForm;
