import React from 'react';
import clsx from 'clsx';
import { VideoPlayer } from '@brivo/video-player-react';
import '@brivo/video-player-react/dist/index.css';
import LoadingExternalVideo from './components/LoadingExternalVideo';
import styles from './styles';

const VideoSection = ({ clip, fetchingExternalClip, customClassName = '' }) => {
    const classes = styles();
    const keyDate = Date.now();
    return (
        <div className={clsx(classes.videoSection, customClassName)}>
            {fetchingExternalClip ? (
                <LoadingExternalVideo />
            ) : (
                <VideoPlayer
                    key={`${clip.src}-${keyDate}`} //used to force video player state reset when clicking a new (or same) clip
                    id={clip.src}
                    source={{ src: clip.src, type: clip.type }}
                    controls={true}
                    options={{
                        stretching: 'responsive',
                        //startLanguage: 'en',
                        alwaysShowControls: true,
                        hideVideoControlsOnLoad: true,
                        hd: true,
                        hdDisabled: true,
                    }}
                    autoplay={true}
                    //loading={loading}
                    //onLoaded={(player) => {
                    //    player.setCurrentTime(initSeekPosition.current);
                    //}}
                    //onComplete={(player) => {
                    // handleNextCLip(player);
                    //}}
                    onError={(err) => console.log(err)}
                />
            )}
        </div>
    );
};
export default VideoSection;
