import React from 'react';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { Tooltip, WarningEventLineIcon } from '@brivo/react-components';
import { Grid } from '@brivo/react-components';
import clsx from 'clsx';

import CameraName from './components/CameraName';
import DownloadClip from './components/DownloadClip';

const PreviewImage = ({ event, cameraId, cameraName, api, imgSrc, customFooterClass }) => {
    const classes = styles({ imgSrc });
    const { t } = useTranslation();
    return (
        <>
            <Grid container spacing={0} className={imgSrc ? classes.previewImage : classes.previewSubstituteCard}>
                {!imgSrc ? (
                    <Grid item className={classes.previewSubstitute}>
                        <Tooltip title={t('Page.recorded-video.clip-may-not-exist')} placement={'right'}>
                            <WarningEventLineIcon className={classes.warningIcon} />
                        </Tooltip>
                    </Grid>
                ) : null}
                <Grid
                    container
                    className={clsx(
                        imgSrc
                            ? `${classes.previewFooterContainer} ${classes.previewImageGradient}`
                            : classes.previewFooterContainer,
                        customFooterClass || ''
                    )}
                >
                    <Grid item xs={11}>
                        <CameraName cameraId={cameraId} cameraName={cameraName} imgSrc={imgSrc} />
                    </Grid>
                    <Grid item xs={1}>
                        <DownloadClip event={event} cameraId={cameraId} api={api} imgSrc={imgSrc} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PreviewImage;
