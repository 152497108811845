import React, { useState } from 'react';
import { MuiAvatar, Typography } from '@brivo/react-components';
import { useStyles } from './styles';
import clsx from 'clsx';
import MultipleChipDisplayField from '../MultipleChipDisplayField/MultipleChipDisplayField';
import MultiSelectDialog from '../MultiSelectDialog/MultiSelectDialog';

const MultiChipDisplayFieldWrapper = ({
    title,
    error,
    helperText,
    className,
    value,
    headers,
    onValueChange,
    gqlHelper,
    loading,
    tableLegendLabel,
    emptySectionTitle,
    avatarIcon,
    useGetAllItems,
    property,
    disabled,
    disabledTriggerSection,
    scrollMarginTop,
    ...props
}) => {
    const classes = useStyles();
    const [isDialogOpen, setOpenDialog] = useState(false);

    const closePopup = () => {
        setOpenDialog(false);
    };
    const openPopup = () => {
        setOpenDialog(true);
    };

    return (
        <>
            <MultipleChipDisplayField
                {...props}
                className={clsx(className, error && classes.error)}
                onAddItemClick={!disabled && !disabledTriggerSection ? openPopup : undefined}
                value={value}
                onValueChange={onValueChange}
                loading={loading}
                disabled={disabled || disabledTriggerSection}
                scrollMarginTop={scrollMarginTop}
            />
            {error && (
                <Typography variant="caption" className={clsx(classes.errorText)}>
                    {helperText}
                </Typography>
            )}
            {isDialogOpen && (
                <MultiSelectDialog
                    defaultValues={value}
                    isOpen={isDialogOpen}
                    closePopup={closePopup}
                    title={title}
                    avatarIcon={
                        <MuiAvatar className={classes.avatar} alt={title}>
                            {avatarIcon}
                        </MuiAvatar>
                    }
                    headers={headers}
                    saveSelection={onValueChange}
                    gqlHelper={gqlHelper}
                    pageLoading={loading}
                    tableLegendLabel={tableLegendLabel}
                    useGetAllItems={useGetAllItems}
                    emptySectionTitle={emptySectionTitle}
                    property={property}
                />
            )}
        </>
    );
};
export default MultiChipDisplayFieldWrapper;
