export const ZOOM_LEVELS = {
    HOURS_8: 28800000,
    HOURS_1: 3600000,
    MINUTES_10: 600000,
    MINUTES_1: 60000,
};

export const ZOOM_LEVELS_V2 = {
    HOURS_8: 28800000,
    HOURS_2: 7200000,
    MINUTES_10: 600000,
    MINUTES_5: 300000,
};

export const TIME_TRAVEL_DIRECTIONS = {
    FUTURE: 'FUTURE',
    PAST: 'PAST',
};
