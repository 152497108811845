import React from 'react';
import {
    Grid,
    Button,
    Typography,
    IconButton,
    LoadingIndicator,
    MuiClearIcon,
    useSnackbar,
} from '@brivo/react-components';

import styles from './styles';
import { useTranslation } from 'react-i18next';

const CallNotificationSnackbarButtons = ({ snackbarId, message, onAnswerCall, onClickUnlock }) => {
    const { t } = useTranslation();
    const classes = styles();
    const alertMessage = t('notifications.call.snackbar.alert.title.subtitle');
    const parsedDetails = JSON.parse(message.data.details);
    const doorName = parsedDetails?.doorName ? parsedDetails.doorName : '';
    const timeStamp = parsedDetails?.timeStamp
        ? new Date(parsedDetails.timeStamp).toLocaleTimeString()
        : new Date().toLocaleTimeString();
    const [unlockButtonStyle, setUnlockButtonStyle] = React.useState(classes.btn);
    const [unlockButtonText, setUnlockButtonText] = React.useState(t('notifications.call.snackbar.button.unlock'));
    const [loadingBar, setLoadingBar] = React.useState(<></>);
    const { closeSnackbar } = useSnackbar();

    const unlockDoor = async (snackbarId, message) => {
        setLoadingBar(<></>);
        setUnlockButtonStyle(classes.loading);
        const unlockDoorAttempt = await onClickUnlock(snackbarId, message);
        setUnlockButtonStyle(`${classes.btn} ${classes.unlockAttempted}`);
        if (!unlockDoorAttempt) {
            setUnlockButtonText(t('notifications.call.snackbar.button.unlockFailed'));
            setLoadingBar(<div className={`${classes.loadingLine} ${classes.loadingLineFailure}`} />);
        } else {
            setUnlockButtonText(t('notifications.call.snackbar.button.unlockSuccess'));
            setLoadingBar(<div className={`${classes.loadingLine} ${classes.loadingLineSuccess}`} />);
        }
    };

    return (
        <div className={classes.buttonContainer}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                <Grid item xs={11} className={classes.titleGridElement}>
                    <Typography variant="h3" className={classes.snackbarTitle} noWrap>
                        {alertMessage}
                    </Typography>
                </Grid>
                <Grid item xs={1} className={classes.titleGridElement}>
                    <IconButton
                        className={classes.iconButton}
                        icon={<MuiClearIcon style={{ fill: '#CED7E2' }} />} //className doesn't work for some reason
                        onClick={() => closeSnackbar(snackbarId)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.subtitleSpacer}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography className={classes.doorInfo}>{doorName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.timeInfo}>{timeStamp}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {unlockButtonStyle == classes.loading ? (
                        <Grid container className={classes.loadingContainer} direction="row" spacing={1}>
                            <Grid item>
                                <div className={classes.loadingIndicator}>
                                    <LoadingIndicator shouldHavePadding={false} size={'24'} />
                                </div>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.unlockMessage}>
                                    {t('notifications.call.snackbar.unlockingMessage')}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <div className={unlockButtonStyle}>
                                <Button
                                    customWidth="100%"
                                    onClick={() => unlockDoor(snackbarId, message)}
                                    type="secondary"
                                    disabled={unlockButtonText == t('notifications.call.snackbar.button.unlockSuccess')}
                                    text={unlockButtonText}
                                ></Button>
                            </div>
                            {loadingBar}
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttonSpacer} />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.btn}>
                        <Button
                            customWidth="100%"
                            type="secondary"
                            onClick={() => onAnswerCall(snackbarId, message)}
                            text={t('notifications.call.snackbar.button.answer')}
                        ></Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default CallNotificationSnackbarButtons;
