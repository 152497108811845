import React, { Suspense, lazy, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { CommandsContextProvider } from './context/commandsContext';
import { INITIAL_VALUES } from './utils/commandsConstants';
import { cloneDeep } from 'lodash';

const ReaderCommandPage = lazy(() => import('./ReaderCommandPage'));

const ReaderCommandContainer = (props) => {
    const { t } = useTranslation();
    const validateReaderCommandFormik = (values) => {
        let errors = {};

        if (!values.commandName) {
            errors.commandName = t('Field.validation.required');
        }
        if (!values.scheduleId) {
            errors.scheduleId = t('Field.validation.required');
        }
        if (!values.trigger) {
            errors.trigger = t('Field.validation.required');
        }
        if (!values.site) {
            errors.site = t('Field.validation.required');
            errors.siteSearch = t('Field.validation.required');
        }
        if (!values.panel) {
            errors.panel = t('Field.validation.required');
        }
        if (values.swipeTimeout === '' || Number.isNaN(parseInt(values.swipeTimeout, 10))) {
            errors.swipeTimeout = t('Field.validation.required');
        }
        if (!values.board) {
            errors.board = t('Field.validation.required');
        }
        if (values.accessPoints.length === 0) {
            errors.accessPoints = t('Field.validation.required');
        }
        if (!values.relay) {
            errors.relay = t('Field.validation.required');
        }
        if (!values.input) {
            errors.input = t('Field.validation.required');
        }
        if (!values.inputState) {
            errors.inputState = t('Field.validation.required');
        }
        if (values.delay === '' || Number.isNaN(parseInt(values.delay, 10))) {
            errors.delay = t('Field.validation.required');
        }
        if (values.groups.length === 0) {
            errors.groups = t('Field.validation.required');
        }

        return errors;
    };

    const [initialValues, setInitialValues] = useState(cloneDeep(INITIAL_VALUES));
    return (
        <Suspense fallback={null}>
            <Formik initialValues={initialValues} enableReinitialize validate={validateReaderCommandFormik}>
                <CommandsContextProvider setInitialValues={setInitialValues}>
                    <ReaderCommandPage {...props} />
                </CommandsContextProvider>
            </Formik>
        </Suspense>
    );
};

export default ReaderCommandContainer;
