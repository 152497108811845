import { COLUMN_DESIGNATION, DATASET_ID_PREFIX, METRIC_DESIGNATION, ROW_DESIGNATION, TOTAL_LABEL } from './constants';
import { cloneDeep, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const nullConversion = (value) => {
    if (value === null) {
        return 'null';
    }
    return value;
};

const fillHeadersAndTotals = (row_record, headers, dims, metrics) => {
    let current_record = headers;
    let metrics_dict = {};
    for (const metric of metrics) {
        const id = metric.id;
        metrics_dict[id] = row_record[id];
        current_record['totals'][id] += row_record[id];
    }
    for (const dim of dims) {
        const id = nullConversion(row_record[dim['id']]);

        if (current_record.headers && current_record.headers[id]) {
            current_record = current_record.headers[id];
            for (const [key, value] of Object.entries(metrics_dict)) {
                current_record.totals[key] += value;
            }
        } else {
            current_record.headers[id] = {
                totals: {
                    ...metrics_dict,
                },
                headers: {},
            };
            if (dim.sort) {
                current_record.sort = dim.sort;
            }
            current_record.dataType = dim.dataDefinition?.dataType ?? 'string';
            current_record = current_record.headers[id];
        }
    }
};

const discoverHeadersAndComputeTotals = (data, columns, rows, metrics) => {
    const row_headers = {
        totals: Object.fromEntries(metrics.map((metric) => [metric.id, 0])),
        headers: {},
    };
    const col_headers = {
        totals: Object.fromEntries(metrics.map((metric) => [metric.id, 0])),
        headers: {},
    };

    for (const record of data) {
        fillHeadersAndTotals(record, row_headers, rows, metrics);
        fillHeadersAndTotals(record, col_headers, columns, metrics);
    }
    return [row_headers, col_headers];
};

const typeComparators = {
    string: (aValue, bValue) => aValue.localeCompare(bValue),
    timestamp_ntz: (aValue, bValue) => aValue.localeCompare(bValue),
    timestamp: (aValue, bValue) => aValue.localeCompare(bValue),
    number: (aValue, bValue) => aValue - bValue,
    integer: (aValue, bValue) => aValue - bValue,
    date: (aValue, bValue) => new Date(bValue) - new Date(aValue),
};

const addAutoincrementIndexToHeaders = (headers, counter = 0, increment = 1, includeTotals = false) => {
    let local_counter = counter;
    headers.index = local_counter;
    const headersArray = Object.entries(headers.headers);
    if (headers.sort && headers.sort === 'desc') {
        const comparator = typeComparators[headers.dataType];
        headersArray.sort((itemA, itemB) => {
            return comparator(itemA[0], itemB[0]);
        });
    } else if (headers.sort && headers.sort === 'asc') {
        const comparator = typeComparators[headers.dataType];
        headersArray.sort((itemA, itemB) => {
            return comparator(itemB[0], itemA[0]);
        });
    }
    for (const [, v] of headersArray) {
        if (isEmpty(v.headers)) {
            v.index = local_counter;
            v.span = increment;
            local_counter += increment;
        } else {
            const new_counter = addAutoincrementIndexToHeaders(v, local_counter, increment, false);
            v.span = new_counter - local_counter;
            local_counter = new_counter;
        }
    }
    if (includeTotals) {
        local_counter += increment;
    }
    return local_counter;
};

const determine_index = (data, dims, hierarchy, parent_key = '') => {
    let found_idx = 0;
    let current_level_description = hierarchy;
    let current_key = parent_key;
    for (const dim of dims) {
        const value = nullConversion(data[dim.id]);
        const level = current_level_description.headers[value];
        found_idx = level.index;
        current_level_description = level;
        current_key = `${current_key}-${value}`;
    }
    return [found_idx, current_key];
};

const fillOutColumnHeaders = (pivot_data, headers, level, skip_columns, parent_key = '') => {
    for (const [key, value] of Object.entries(headers.headers)) {
        const index = value.index;
        const current_key = `${parent_key}-${key}`;
        pivot_data[level].splice(index + skip_columns, 1, {
            value: key,
            dataType: headers.dataType,
            columnSpan: value.span,
            key: `column-header${current_key}`,
        });
        if (!isEmpty(value.headers)) {
            fillOutColumnHeaders(pivot_data, value, level + 1, skip_columns, current_key);
        }
    }
};

const fillOutRowHeaders = (pivot_data, headers, level, skip_rows, parent_key = '') => {
    for (const [key, value] of Object.entries(headers.headers)) {
        const index = value.index;
        const current_key = `${parent_key}-${key}`;
        pivot_data[index + skip_rows].splice(level, 1, {
            value: key,
            dataType: headers.dataType,
            rowSpan: value.span,
            key: `row-header${current_key}`,
        });
        if (!isEmpty(value.headers)) {
            fillOutRowHeaders(pivot_data, value, level + 1, skip_rows, current_key);
        }
    }
};

const fillOutColumnTotals = (pivot_data, headers, skip_columns, metrics, parent_key = '') => {
    for (const [key, value] of Object.entries(headers.headers)) {
        const current_key = `${parent_key}-${key}`;
        if (!isEmpty(value.headers)) {
            fillOutColumnTotals(pivot_data, value, skip_columns, metrics, current_key);
        } else {
            const index = value.index;
            metrics.forEach((dim, idx) => {
                const id = dim.id;
                const level = dim.level;
                const total_value = value.totals[id] ?? 0;
                pivot_data[pivot_data.length - 1].splice(skip_columns + index + idx, 1, {
                    value: total_value,
                    dataType: 'integer',
                    key: `column-total${current_key}-${id}-${level}`,
                });
            });
        }
    }
};

const fillOutRowTotals = (pivot_data, headers, skip_rows, metrics, parent_key = '') => {
    for (const [key, value] of Object.entries(headers.headers)) {
        const current_key = `${parent_key}-${key}`;
        if (!isEmpty(value.headers)) {
            fillOutRowTotals(pivot_data, value, skip_rows, metrics, current_key);
        } else {
            const index = value.index;
            const num_metrics = metrics.length;
            metrics.forEach((dim, idx) => {
                const id = dim.id;
                const level = dim.level;
                const total_value = value.totals[id] ?? 0;
                pivot_data[index + skip_rows].splice(idx - num_metrics, 1, {
                    value: total_value,
                    dataType: 'integer',
                    key: `row-total${current_key}-${id}-${level}`,
                });
            });
        }
    }
};

const fillOutPivotTable = (
    data,
    pivotData,
    col_headers,
    row_headers,
    data_row_count,
    data_column_count,
    rows,
    columns,
    metrics,
    rowTotal,
    columnTotal
) => {
    const row_st = columns.length;
    const col_st = rows.length;
    const rowKeys = new Array(data_row_count).fill('');
    const columnKeys = new Array(data_column_count).fill('');
    const totalCellCount = data_row_count * data_column_count;
    for (const record of data) {
        const [col_idx, col_key] = determine_index(record, columns, col_headers);
        const [row_idx, row_key] = determine_index(record, rows, row_headers);
        rowKeys[row_st + row_idx] = row_key;
        metrics.forEach((metric, metric_idx) => {
            columnKeys[col_st + col_idx + metric_idx] = `${col_key}-${metric.id}-${metric.level}`;
            pivotData[row_st + row_idx][col_st + col_idx + metric_idx] = {
                value: record[metric.id],
                dataType: 'number',
                key: `row${row_key}-column${col_key}-${metric.id}-${metric.level}`,
            };
        });
    }

    pivotData.forEach((row, row_idx) => {
        if (row_idx >= columns?.length ?? 0) {
            row.forEach((cell, col_idx) => {
                if (col_idx >= (rows?.length ?? 0) && cell === null) {
                    pivotData[row_idx][col_idx] = {
                        value: 0,
                        dataType: 'number',
                        key: `row${rowKeys[row_idx]}-column${columnKeys[col_idx]}`,
                    };
                }
            });
        }
    });

    if (rowTotal === true && columnTotal === true) {
        const num_metrics = metrics.length;
        metrics.forEach((metric, metric_idx) => {
            pivotData[pivotData.length - 1].splice(metric_idx - num_metrics, 1, {
                value: col_headers['totals'][metric.id],
                dataType: 'number',
                key: `row-total-column-total-${metric.id}-${metric.level}`,
            });
        });
    }

    return {
        pivotData,
        rowKeys,
        columnKeys,
        totalCellCount,
    };
};

export const getPivot = (data, dimensions, rowTotal = true, columnTotal = true) => {
    const metrics = dimensions?.filter((dim) => dim.designation === METRIC_DESIGNATION);
    const columns = dimensions
        ?.filter((dim) => dim.designation === COLUMN_DESIGNATION)
        ?.sort((a, b) => b.level - a.level);
    const rows = dimensions?.filter((dim) => dim.designation === ROW_DESIGNATION)?.sort((a, b) => b.level - a.level);
    const [row_headers, col_headers] = discoverHeadersAndComputeTotals(data, columns, rows, metrics);
    let data_row_count = addAutoincrementIndexToHeaders(row_headers, 0, 1, columnTotal) + (columns?.length ?? 0);
    let data_column_count =
        addAutoincrementIndexToHeaders(col_headers, 0, metrics.length, rowTotal) + (rows?.length ?? 0);

    const pivotData = new Array(data_row_count).fill(null);
    for (let i = 0; i < data_row_count; i++) {
        pivotData[i] = new Array(data_column_count).fill(null);
    }

    fillOutColumnHeaders(pivotData, col_headers, 0, rows.length);
    if (columnTotal === true) {
        pivotData[data_row_count - 1][0] = {
            value: TOTAL_LABEL,
            dataType: 'string',
            columnSpan: rows.length,
            key: 'row-header-totals',
        };
        fillOutColumnTotals(pivotData, col_headers, rows.length, metrics);
    }

    fillOutRowHeaders(pivotData, row_headers, 0, columns.length);
    if (rowTotal === true) {
        pivotData[0].splice(data_column_count - metrics.length, 1, {
            value: TOTAL_LABEL,
            dataType: 'string',
            rowSpan: columns.length,
            columnSpan: metrics.length,
            key: 'column-header-totals',
        });
        fillOutRowTotals(pivotData, row_headers, columns.length, metrics);
    }

    return fillOutPivotTable(
        data,
        pivotData,
        col_headers,
        row_headers,
        data_row_count,
        data_column_count,
        rows,
        columns,
        metrics,
        rowTotal,
        columnTotal
    );
};

const deepPropSubstitute = (object, propName, substitutions) => {
    if (Array.isArray(object)) {
        object.forEach((item) => deepPropSubstitute(item, propName, substitutions));
    } else if (typeof object === 'object') {
        Object.keys(object).forEach((prop) => {
            if (prop === propName) {
                const value = object[prop];
                if (typeof value === 'string' && substitutions[value]) object[prop] = substitutions[value];
            } else {
                deepPropSubstitute(object[prop], propName, substitutions);
            }
        });
    }
};

const deepSourceSubstitute = (object, fromSourceName, toSourceName, propId, fieldName) => {
    if (Array.isArray(object)) {
        object.forEach((item) => deepSourceSubstitute(item, fromSourceName, toSourceName, propId, fieldName));
    } else if (typeof object === 'object') {
        Object.keys(object).forEach((prop) => {
            if (prop === 'source' && object.source === fromSourceName) {
                object.source = toSourceName;
                object[propId] = fieldName;
            } else {
                deepSourceSubstitute(object[prop], fromSourceName, toSourceName, propId, fieldName);
            }
        });
    }
};

const formDatasetColumns = (currentQuery, metadata, newDimensions, newDataset, UUIDDatasetSubstitutions) => {
    currentQuery.columns.forEach((column, idx) => {
        const uuid = column.uuid;
        const i18Prefix = column.id?.startsWith('i18_') ? 'i18_' : '';
        const foundMetadata = metadata?.column_types?.find((md_item) => md_item.uuid === uuid);
        const foundDimension = newDimensions.find((dim_item) => dim_item.id === uuid);
        const newDatasetColumn = {
            id: `${i18Prefix}${DATASET_ID_PREFIX}_${idx}`,
            type: foundMetadata?.column_type || foundDimension?.dataDefinition?.dataType || 'string',
            selectable: true,
            expression: {
                source: 'table',
                id: `${i18Prefix}${DATASET_ID_PREFIX}_${idx}`,
            },
        };

        UUIDDatasetSubstitutions[uuid] = `${i18Prefix}${DATASET_ID_PREFIX}_${idx}`;

        newDataset.columns[idx] = newDatasetColumn;
    });
};

const formNewDimensions = (currentQuery, newQuery, newDimensions, UUIDDatasetSubstitutions, t) => {
    newDimensions.forEach((dim, idx) => {
        const newUUID = dim.uuid ?? uuidv4();
        const foundColumn = currentQuery.columns.find((col) => col.uuid === dim.id);
        if (!foundColumn) {
            return;
        }
        const newQueryColumn = Object.fromEntries(
            Object.entries(foundColumn)
                .filter((elem) => elem[0] === 'alias' || elem[0] === 'uuid')
                .map((elem) => {
                    elem[1] = `${elem[1]}_${idx}`;
                    return elem;
                })
        );
        if (dim.displayName) {
            newQueryColumn.alias = dim.displayName;
        } else if (dim.displayNameKey) {
            newQueryColumn.alias = t(dim.displayNameKey);
        }
        const newQueryUUID = UUIDDatasetSubstitutions[dim.id];
        if (dim.transformationExpression && typeof dim.transformationExpression === 'object') {
            const expression = cloneDeep(dim.transformationExpression);
            deepSourceSubstitute(expression, 'query', DATASET_ID_PREFIX, 'id', newQueryUUID);
            Object.assign(newQueryColumn, expression);
        } else {
            Object.assign(newQueryColumn, {
                source: DATASET_ID_PREFIX,
                id: newQueryUUID,
            });
        }
        newQueryColumn.uuid = newUUID;
        dim.uuid = newUUID;
        dim.id = newUUID;
        if (dim.transformationFilters?.length > 0) {
            newQuery.filters = [...newQuery.filters, ...dim.transformationFilters];
        }
        if (dim.sort?.direction) {
            newQuery.sort.push({
                source: 'columns',
                uuid: newUUID,
                direction: dim.sort.direction,
            });
        }
        newQuery.columns.push(newQueryColumn);
    });
};

export const isPlainDimension = (dim) => {
    return (
        dim.transformationExpression === null ||
        dim.transformationExpression === undefined ||
        dim.transformationExpression?.source === 'query'
    );
};

export const convertQueryToCteWithAggregation = (query, oldDimensions, metadata, t, forceTransform = false) => {
    if (
        oldDimensions?.length > 0 &&
        query?.query?.columns?.length > 0 &&
        (forceTransform ||
            oldDimensions.some(
                (dim) =>
                    dim.transformationExpression &&
                    typeof dim.transformationExpression === 'object' &&
                    !isPlainDimension(dim)
            ))
    ) {
        const newDimensions = cloneDeep(oldDimensions);
        const updatedQuery = cloneDeep(query);
        const currentDatasets = updatedQuery.datasets ?? [];
        const currentQuery = updatedQuery.query;
        const newQuery = {
            columns: [],
            filters: [],
            sort: [],
        };

        const newDataset = {
            id: DATASET_ID_PREFIX,
            selectable: true,
            columns: [],
            source: {
                query: currentQuery,
            },
        };

        const UUIDDatasetSubstitutions = {};

        formDatasetColumns(currentQuery, metadata, newDimensions, newDataset, UUIDDatasetSubstitutions);

        formNewDimensions(currentQuery, newQuery, newDimensions, UUIDDatasetSubstitutions, t);

        if (newQuery.filters.length === 0) {
            delete newQuery.filters;
        }
        if (newQuery.sort.length === 0) {
            delete newQuery.sort;
        }
        deepPropSubstitute(currentQuery, 'uuid', UUIDDatasetSubstitutions);
        currentDatasets.push(newDataset);
        updatedQuery.datasets = currentDatasets;
        updatedQuery.query = newQuery;
        return [updatedQuery, newDimensions];
    } else if (oldDimensions?.length > 0 && oldDimensions.some((dim) => dim.transformationFilters?.length > 0)) {
        const updatedQuery = cloneDeep(query);
        if (!Array.isArray(updatedQuery.query.filters)) {
            updatedQuery.query.filters = [];
        }
        oldDimensions.forEach((dim) => {
            if (dim.transformationFilters?.length > 0) {
                updatedQuery.query.filters = [...updatedQuery.query.filters, ...dim.transformationFilters];
            }
        });
        return [updatedQuery, oldDimensions];
    }
    return [query, oldDimensions];
};
