import React from 'react';
import { Page, grey } from '@brivo/react-components';

const SsoLoggedOut = ({ loginUrl }) => (
    <Page>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(40vh - 2rem)',
                color: grey[500],
            }}
        >
            <p
                style={{
                    fontSize: '1.5rem',
                }}
            >
                You have been logged out. To log back in go to{' '}
                <a
                    style={{
                        color: 'dodgerblue',
                    }}
                    href={loginUrl}
                >
                    {loginUrl}
                </a>
            </p>
        </div>
    </Page>
);

export default SsoLoggedOut;
