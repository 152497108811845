import React, { Suspense, lazy } from 'react';

const HomeDashboard = lazy(() => import('./HomeDashboard'));

const HomeContainer = (props) => (
    <Suspense fallback={null}>
        <HomeDashboard {...props} />
    </Suspense>
);

export default HomeContainer;
