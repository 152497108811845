import React, { useState, useContext, useRef } from 'react';
import { get } from 'lodash';
import UserTracking from '../utils/UserTracking';
import { GQLProviderContext } from '../../common/utils/CustomApolloProvider';
import ApiHelper from '../helpers/Helpers';
import { useFlagClient } from '@brivo/onairplus-services';
import { auth } from '../utils/Auth';
import { RESTRICT_THIRD_PARTY_USER_DATA } from '../constants/Constants';

import { detect } from 'detect-browser';

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
    const gqlHelper = useContext(GQLProviderContext);
    const apiHelper = useRef(new ApiHelper(auth));
    const flagClient = useFlagClient();

    const [user, setUser] = useState({});
    const [ctxStorage, setCtxStorage] = useState({});

    const fetchWhoami = async () => {
        UserTracking.track('Fetch Logged in user details');
        UserTracking.log('Fetching logged in user details');
        const data = await gqlHelper.whoami();
        const whoami = data?.whoami;
        const { permissions = {}, adminInfo = {}, account = {}, accessibleAccounts = [] } = whoami;
        const userPerms = permissions?.map((perm) => get(perm, 'name'));
        const userInfo = {
            id: whoami ? whoami.onairObjectId : null,
            userId: whoami ? whoami.id : null,
            isPrimaryAdmin: whoami ? whoami.isPrimary : false,
            accountId: account.accountId,
            accounts: accessibleAccounts.sort((a, b) => a.name.localeCompare(b.name)),
            email: adminInfo.email,
            firstName: adminInfo.firstName,
            lastName: adminInfo.lastName,
            middleName: adminInfo.middleName,
            username: adminInfo.username,
            timezone: adminInfo.timeZone,
            showSiteTimeZone: adminInfo.showSiteTimeZone,
            accountFeatures: account.accountFeatures,
            dealerName: account.dealerName,
            subscriptionLevel: account.subscriptionLevel,
            dealerAccountId: account.dealerAccountId,
            smartHomeMigrated: false,
            hdStreamLimitValue: account.hdStreamLimitValue,
            nfcCredentialEnabled: account.nfcCredentialEnabled,
            brivoAllegionMobilePass: account.brivoAllegionMobilePass,
            blockOnair: account.blockOnair,
            suppressEvents: account.suppressEvents,
            accountName: account.accountName,
            isFaceIdEnabled: account?.facialIdValue,
            isMultifamilySupportEnabled: account?.multifamilySupportEnabled,
        };

        if (whoami && whoami.adminInfo) {
            if (!auth.getIdentified()) {
                UserTracking.track('LaunchDarkly Identification');
                await flagClient.identify(
                    {
                        kind: 'user',
                        key: auth.getSubject(),
                        email: userInfo.email,
                        subscription: auth.getSubscriptionLevel(),
                        accountId: userInfo.accountId,
                        username: userInfo.username,
                        userId: userInfo.id,
                        client: 'brivo-access',
                        browser: JSON.stringify(detect()),
                        _meta: {
                            privateAttributes: RESTRICT_THIRD_PARTY_USER_DATA ? ['email', 'username'] : [],
                        },
                    },
                    auth.getSubject()
                );
                auth.setIdentified(true);
            }
            auth.setUser(whoami);

            userInfo.smartHomeMigrated =
                (await flagClient.variation('smart-home-access', false)) &&
                (await apiHelper.current.getSmartHomeIsMigrated(adminInfo.email));

            setUser({
                userInfo,
                permissions: userPerms,
                assignments: whoami.assignments,
            });
        } else if (auth.isAltAuthOn()) {
            console.warn('Unable to retrieve administrator information; logging out.');
            auth.logout();
        }
    };

    const updateUser = async () => {
        if (!auth.getIdentified() && (await auth.isAuthenticated())) {
            return fetchWhoami();
        }
    };

    return (
        <UserContext.Provider value={{ ...user, updateUser, setUser, ctxStorage, setCtxStorage }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
