import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Typography } from '@brivo/react-components';
import { PROMISE_CANCELLED } from '../../../../common/constants/Constants';
import useStyles from '../../../BrivoAnalytics/components/KPI/KPITrend/styles';
import { formatTrendValue, isValidTrendValue, TrendIcon } from '../../../BrivoAnalytics/components/KPI/KPITrend';
import { NEGATIVE_TREND, POSITIVE_TREND } from '../../../BrivoAnalytics/components/KPI/KPITrend/constants';

const KPITrend = ({ trendQuery: query, uiVariables, roverApiProxy }) => {
    const classes = useStyles();

    const [trendValue, setTrendValue] = useState(null);

    useEffect(() => {
        const { promise, cancel } = roverApiProxy.getData(query);
        promise
            ?.then((data) => {
                setTrendValue(data[0].kpiTrendValue);
            })
            .catch((e) => {
                if (e.message !== PROMISE_CANCELLED) {
                    console.error(e);
                    setTrendValue(null);
                }
            });

        return cancel;
    }, [query, roverApiProxy]);

    // trendDirection is deprecated in data, but let's rename trendGreen to trendDirection for clarity
    const { trendGreen: trendDirection, trendValueType } = uiVariables;

    return (
        isValidTrendValue(trendValue) && (
            <div className={classes.trendDiv} data-testid="kpi-trend">
                <Typography
                    className={clsx({
                        [classes.greenColor]:
                            (trendDirection === POSITIVE_TREND && trendValue > 0) ||
                            (trendDirection === NEGATIVE_TREND && trendValue < 0),
                        [classes.redColor]:
                            (trendDirection === NEGATIVE_TREND && trendValue > 0) ||
                            (trendDirection === POSITIVE_TREND && trendValue < 0),
                    })}
                    data-text-color={clsx({
                        green:
                            (trendDirection === POSITIVE_TREND && trendValue > 0) ||
                            (trendDirection === NEGATIVE_TREND && trendValue < 0),
                        red:
                            (trendDirection === NEGATIVE_TREND && trendValue > 0) ||
                            (trendDirection === POSITIVE_TREND && trendValue < 0),
                    })}
                    data-testid="metric"
                >
                    {trendValueType === 'integer' ? trendValue : formatTrendValue(trendValue)}
                </Typography>
                <TrendIcon trendValue={trendValue} trendDirection={trendDirection} />
            </div>
        )
    );
};
export default KPITrend;
