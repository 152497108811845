import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Grid, Typography } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    Button,
    GenericDialog,
    Avatar,
    DialogActions,
    TextField,
    SingleSelectField,
    RadioGroup,
    ErrorIconLine,
    Tooltip,
    PageInfoIcon,
    SaveActionBar,
    Snackbar,
} from '@brivo/react-components';

import { dashboardsApi } from '../../../../../common/webApis/myAnalytics/dashboardsApi';
import DownloadIcon from '../../../../../common/components/Icons/DownloadIcon';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './styles';
import { DashboardConfigurationContext } from '../../../common/DashboardBuilderContext';
import { KPI_DETAILS } from '../../KPI/KPITile/constants';
const SaveView = ({
    dashboardId,
    currentConfig,
    updateDashboardConfig,
    setDashboardConfig,
    pageHeader,
    showSaveButtonBar,
    setShowSaveButtonBar,
    metricPosition,
    isDataLoading,
    visualConfig,
    handleSubmitChanges,
    setHasErrors,
    openDialog,
    setOpenDialog,
    isKpiTable,
    discardCallback,
    disabled,
    text,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { addSuccessMessage } = Snackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [dashboardName, setDashboardName] = useState('');
    const [isSaveDialogOpen, toggleSaveDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    //eslint-disable-next-line
    const [{ removeDashboardConfigurationById }, configurationState, reloadDashboards] =
        useContext(DashboardConfigurationContext);

    useEffect(() => {
        toggleSaveDialog(openDialog);
    }, [openDialog]);

    const handleChangeDashboardName = (e) => {
        setDashboardName(e.target.value);
        if (e.target.value && errors.nameInput) {
            setErrors({ ...errors, nameInput: false });
        }
    };
    const setNotification = useCallback(
        (type) => {
            switch (type) {
                case 'save':
                    addSuccessMessage({ text: t('Page.brivo-analytics.save-dialog.success') });
                    break;
                case 'copy':
                default:
                    addSuccessMessage({ text: t('Page.brivo-analytics.save-copy-dialog.success') });
                    break;
            }
        },
        [addSuccessMessage, t]
    );
    const cancelDialog = useCallback(() => {
        if (setOpenDialog) {
            setOpenDialog(false);
        } else {
            toggleSaveDialog(false);
        }
        setErrors({ ...errors, nameInput: false });
    }, [setOpenDialog, toggleSaveDialog, setErrors, errors]);

    const cleanConfig = (config) => {
        delete config.header;
        delete config.key;
        delete config.sharingType;

        if (config.name === KPI_DETAILS) {
            config.visuals[0].isDrilldown = false;
        }

        return config;
    };

    const handleSaveConfiguration = useCallback(
        async (updatedConfig, dashboardId, sharingType, type) => {
            setIsSubmitting(true);
            handleSubmitChanges(true);
            if (setShowSaveButtonBar) {
                setShowSaveButtonBar(false);
            }
            cancelDialog();
            const cleanedConfig = cleanConfig(cloneDeep(updatedConfig));

            try {
                const trimmedDashboardName = dashboardName.trim();

                const response = await dashboardsApi.saveAnalyticsDashboard(
                    dashboardId,
                    trimmedDashboardName ? trimmedDashboardName : updatedConfig.name,
                    JSON.stringify(cleanedConfig),
                    sharingType || updatedConfig.sharingType
                );
                if (response.saveAnalyticsDashboardsForAdmin) {
                    history.push({ pathname: pageHeader?.button.url, state: { dashboardId } });
                    await reloadDashboards();
                    removeDashboardConfigurationById(dashboardId);
                    setNotification(type);
                    setIsSubmitting(false);
                } else {
                    setIsSubmitting(false);
                    handleSubmitChanges(false);
                    setHasErrors(true);
                }
            } catch (e) {
                console.error(e.message);
                setHasErrors(true);
                setIsSubmitting(false);
                handleSubmitChanges(false);
            }
        },
        [
            setIsSubmitting,
            setNotification,
            setShowSaveButtonBar,
            history,
            pageHeader,
            handleSubmitChanges,
            cancelDialog,
            dashboardName,
            reloadDashboards,
            setHasErrors,
            removeDashboardConfigurationById,
        ]
    );

    const handleSubmit = useCallback(
        (type) => {
            const updatedConfig = updateDashboardConfig(currentConfig, metricPosition, {
                ...visualConfig,
                edited: true,
            });

            setDashboardConfig(updatedConfig);
            switch (type) {
                case 'save':
                    handleSaveConfiguration(updatedConfig, dashboardId, null, type);
                    break;
                case 'copy':
                default:
                    setIsSubmitting(false);
                    toggleSaveDialog(true);
                    break;
            }
        },
        [
            handleSaveConfiguration,
            dashboardId,
            setIsSubmitting,
            currentConfig,
            metricPosition,
            setDashboardConfig,
            updateDashboardConfig,
            visualConfig,
        ]
    );
    const handleSaveCopy = () => {
        const trimmedDashboardName = dashboardName.trim();
        if (!trimmedDashboardName) {
            setErrors({
                ...errors,
                nameInput: createErrorMessage(t('Page.brivo-analytics.save-copy-dialog.input-error.name')),
            });
        } else {
            const uuid = uuidv4();
            if (isKpiTable) {
                handleSaveConfiguration(currentConfig, uuid, 'PRIVATE', 'copy');
            } else {
                const updatedConfig = updateDashboardConfig(currentConfig, metricPosition, {
                    ...visualConfig,
                    edited: true,
                });
                const newViewConfig = { ...updatedConfig, visuals: [{ ...visualConfig, edited: true }] };
                handleSaveConfiguration(newViewConfig, uuid, 'PRIVATE', 'copy');
            }
        }
    };
    const handleDiscardChanges = () => {
        setIsSubmitting(false);
        setShowSaveButtonBar(false);
        discardCallback();
    };

    const actionBarProps = useMemo(() => {
        return {
            text: '',
            primaryActionText: t('Page.brivo-analytics.save-dialog.primary-action.text'),
            primaryActionId: 'save-changes-btn',
            secondaryActionText: t('Page.brivo-analytics.save-copy-dialog.secondary-action.text'),
            tertiaryActionText: t('Page.brivo-analytics.save-dialog.secondary-action.text'),
        };
    }, [t]);
    const createErrorMessage = (message) => {
        return (
            <span className="error-message">
                <ErrorIconLine />
                <span>{message}</span>
            </span>
        );
    };
    return (
        <>
            <Grid container direction="column">
                <Grid item xs={12}>
                    <SaveActionBar
                        show={showSaveButtonBar}
                        text={text || actionBarProps.text}
                        disablePrimary={disabled}
                        disableSecondary={disabled}
                        isLoading={isDataLoading || isSubmitting}
                        primaryActionText={actionBarProps.primaryActionText}
                        primaryActionClickHandler={() => handleSubmit('save')}
                        primaryActionId={actionBarProps.primaryActionId}
                        secondaryActionText={actionBarProps.secondaryActionText}
                        secondaryActionClickHandler={() => handleSubmit('copy')}
                        showPrimaryIcon={false}
                        tertiaryActionText={actionBarProps.tertiaryActionText}
                        tertiaryActionClickHandler={handleDiscardChanges}
                    />
                </Grid>
            </Grid>
            <GenericDialog
                id={'save-dashboard-dialog'}
                open={isSaveDialogOpen}
                title={t('Page.brivo-analytics.save-dialog.new.title')}
                avatarIcon={
                    <Avatar size={'small'}>
                        <DownloadIcon />
                    </Avatar>
                }
                onClose={cancelDialog}
                actions={
                    <DialogActions>
                        <Button
                            type="secondary"
                            onClick={cancelDialog}
                            text={t('Page.brivo-analytics.save-dialog.button.cancel')}
                            id={'secondary-button'}
                        />
                        <Button
                            onClick={() => handleSaveCopy()}
                            text={t('Page.brivo-analytics.save-dialog.button.save')}
                            id={'primary-button'}
                        />
                    </DialogActions>
                }
            >
                <>
                    <div className={classes.radio}>
                        <RadioGroup
                            id="choose-view"
                            size="medium"
                            groupLabel={t('Page.brivo-analytics.save-copy-dialog.choose-view')}
                            selectedOption="new"
                            options={[
                                {
                                    label: t('Page.brivo-analytics.save-copy-dialog.choose-view.new'),
                                    value: 'new',
                                },
                                {
                                    label: t('Page.brivo-analytics.save-copy-dialog.choose-view.existing'),
                                    value: 'existing',
                                    disabled: true,
                                },
                            ]}
                        />
                    </div>
                    <div className={clsx(classes.input, classes.nameInput, errors.nameInput && classes.inputError)}>
                        <TextField
                            type="text"
                            name="name"
                            label={t('Page.brivo-analytics.save-dialog.input-name')}
                            id="name"
                            maxLength={255}
                            placeholder={t('Page.brivo-analytics.save-dialog.input-name.placeholder')}
                            value={dashboardName}
                            onChange={handleChangeDashboardName}
                        />
                        {!!errors.nameInput && (
                            <Typography variant="caption" className={clsx(classes.errorText)}>
                                {errors.nameInput}
                            </Typography>
                        )}
                    </div>
                    <div className={clsx(classes.input)}>
                        <SingleSelectField
                            label={
                                <div className={classes.sharingOptions}>
                                    {t('Page.brivo-analytics.save-dialog.sharing-options')}
                                    <Tooltip
                                        size="small"
                                        subtitle={
                                            <div className={classes.tooltip}>
                                                <div>
                                                    <span className="u-bold">
                                                        {t(
                                                            'Page.brivo-analytics.save-dialog.sharing-options.tooltip.private'
                                                        )}
                                                    </span>
                                                    {t(
                                                        'Page.brivo-analytics.save-dialog.sharing-options.tooltip.private.explanation'
                                                    )}
                                                </div>
                                                <div>
                                                    <span className="u-bold">
                                                        {t(
                                                            'Page.brivo-analytics.save-dialog.sharing-options.tooltip.public'
                                                        )}
                                                    </span>
                                                    {t(
                                                        'Page.brivo-analytics.save-dialog.sharing-options.tooltip.public.explanation'
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    >
                                        <PageInfoIcon className={'u-ml-half'} />
                                    </Tooltip>
                                </div>
                            }
                            selectedOption={'PRIVATE'}
                            options={[
                                { id: 'PRIVATE', name: t('Page.brivo-analytics.save-dialog.sharing-options.private') },
                                {
                                    id: 'PUBLIC',
                                    name: t('Page.brivo-analytics.save-dialog.sharing-options.public'),
                                    disabled: true,
                                },
                            ]}
                            handleChange={() => {}}
                            id="sharing"
                            isDisabled={false}
                        />
                    </div>
                </>
            </GenericDialog>
        </>
    );
};
export default SaveView;
