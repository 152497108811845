import { API_BASE_URL } from '@common/constants/Constants';

import { WebReqApi } from '../WebReqApi';

import { GET_GROUPS_FOR_JOURNAL_PAGE, GET_SHARED_GROUPS_BY_MANAGER, GET_SHARED_GROUPS_TO_OCCUPANT } from './gqlTags';
import { getAccessPointNameFilter, getAccessPointTypeFilter } from '@common/utils/Utils';

const API_GROUPS_URL = '/api/groups';

class GroupsApi extends WebReqApi {
    getSharedGroupsToOccupant({ accountId }) {
        return this.makeGqlQuery({
            gqlTag: GET_SHARED_GROUPS_TO_OCCUPANT,
            variables: {
                accountId,
            },
        });
    }

    getSharedGroupsByMananger({ accountId }) {
        return this.makeGqlQuery({
            gqlTag: GET_SHARED_GROUPS_BY_MANAGER,
            variables: {
                accountId,
            },
        });
    }

    addGroupsLinking(grObjIds = [], propertyManagerGroupId) {
        const commonOptions = {
            method: 'POST',
            url: `${API_BASE_URL}/groups/linking`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
        };

        const individualOptions = grObjIds.map((grOid) => ({
            body: {
                propertyManagerGroupId,
                occupantGroupId: grOid,
            },
        }));

        return this.makeParallelRestRequests(commonOptions, individualOptions);
    }

    removeGroupsLinking(grObjIds = [], propertyManagerGroupId) {
        const commonOptions = {
            method: 'DELETE',
            url: `${API_BASE_URL}/groups/linking`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
        };

        const individualOptions = grObjIds.map((grOid) => ({
            body: {
                propertyManagerGroupId,
                occupantGroupId: grOid,
            },
        }));

        return this.makeParallelRestRequests(commonOptions, individualOptions);
    }

    addSharedGroups(grObjIds = [], accountId) {
        const commonOptions = {
            method: 'POST',
            url: `${API_BASE_URL}/groups/linking/permission`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
        };

        const individualOptions = grObjIds.map((grOid) => ({
            body: { propertyManagerGroupId: grOid, occupantAccountIdAllowed: accountId },
        }));

        return this.makeParallelRestRequests(commonOptions, individualOptions);
    }

    removeSharedGroups(grObjIds = [], accountId) {
        const commonOptions = {
            method: 'DELETE',
            url: `${API_BASE_URL}/groups/linking/permission`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
        };

        const individualOptions = grObjIds.map((grOid) => ({
            body: {
                propertyManagerGroupId: grOid,
                occupantAccountIdAllowed: accountId,
            },
        }));

        return this.makeParallelRestRequests(commonOptions, individualOptions);
    }

    getGroupsForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_GROUPS_FOR_JOURNAL_PAGE,
            variables: {
                name,
                rows,
            },
            defaultValue: { groups: [] },
        });
    }

    getPagedGroupAccessPoints = (offset, pageSize, id, isOffline = false) => {
        return this.makeRestRequest({
            url: `${API_GROUPS_URL}/${id}/access/access-points`,
            queryParams: {
                offset: offset,
                pageSize: pageSize,
                filter: getAccessPointTypeFilter(isOffline),
            },
        });
    };

    addDeviceToGroup = async (accessPoint, groupId, scheduleId) => {
        const { id, privacyModeOverride } = accessPoint;

        if (!groupId) {
            throw new Error('groupId is required');
        }

        if (!scheduleId) {
            throw new Error('scheduleId is required');
        }

        const updateObj = {
            schedule: {
                id: scheduleId,
            },
            ...(privacyModeOverride !== null && { privacyModeOverride: privacyModeOverride }),
        };

        return await this.makeRestRequest({
            url: `${API_GROUPS_URL}/${groupId}/access/access-points/${id}`,
            method: 'PUT',
            body: updateObj,
        });
    };

    removeDeviceFromGroup = async (accessPoint, groupId) => {
        const { id } = accessPoint;

        if (!groupId) {
            throw new Error('groupId is required');
        }

        return await this.makeRestRequest({
            url: `${API_GROUPS_URL}/${groupId}/access/access-points/${id}`,
            method: 'DELETE',
        });
    };

    getPagedGroupAccessPointWithFiltering = async (groupId, name) => {
        return await this.makeRestRequest({
            url: `${API_GROUPS_URL}/${groupId}/access/access-points`,
            queryParams: {
                filter: getAccessPointNameFilter(name),
            },
        });
    };
}

export const groupsApi = new GroupsApi();
