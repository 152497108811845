import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Link, makeStyles, withTheme, Snackbar } from '@brivo/react-components';
import Banner from 'react-js-banner';

import AccessControl from '../../AccessControl';
import { DEVICES_VIEW_DEVICES } from '../../../permissions/Permissions';
import { ErrorDialog, LoadingIndicator, LockdwonRoundedIcon } from '@brivo/react-components';
import DoorIcon from '../../../../pages/DeviceStatus/components/DeviceStatusPanel/DeviceCard/Icons/DoorIcon';
import ItemsList from './ItemsList';
import { lockdownApi } from '../../../webApis';
import TableLegend from '../../TableLegend/TableLegend';

const TO_MANY_DOORS = 'Admin has access to more than 300 doors and floors';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.error.main,
        width: '18px !important',
        height: '18px !important',
        marginRight: '4px',
    },
    text: {
        whiteSpace: 'pre-wrap',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.type === 'dark' ? '#F5827A' : theme.palette.error.main,
    },
}));

const LockdownBanner = ({ theme, activeScenarios }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { addErrorMessage } = Snackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [unsuccessfulDevices, setUnsuccessfulDevices] = useState(false);
    const [areDevicesLoading, setLoadingDevices] = useState(false);
    const [shouldShowDevices, setShouldShowDevices] = useState(false);
    const [error, setError] = useState(false);

    const bannerCss = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.type === 'dark' ? '#41373E' : '#FCDAD7',
        borderBottom: ` 1px solid #F15349`,
        color: theme.palette.type === 'dark' ? '#F5827A' : theme.palette.error.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'normal',
        fontSize: '14px',
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        marginBottom: 0,
        height: '56px',
    };

    const isLockdownActive = activeScenarios?.length;

    const getUnsuccessfulDevices = async (ids) => {
        return lockdownApi.listUnsuccessfulDevices(ids);
    };

    useEffect(() => {
        if (isLockdownActive) {
            setLoadingDevices(true);
            const ids = activeScenarios.map((scenario) => scenario.id);
            setTimeout(() => {
                // delaying retrieval of devices in order to give them time to update after a scenario activation
                getUnsuccessfulDevices(ids)
                    .then((response) => {
                        const devices = response.listUnsuccessfulDevices;

                        if (response.errors) {
                            if (response.errors[0].message === TO_MANY_DOORS) {
                                setError('Page.lockdown.top-banner.devices.more-than-300-doors');
                            } else {
                                setError('Page.lockdown.top-banner.devices.error');
                            }
                        }
                        setUnsuccessfulDevices(devices);
                    })
                    .catch((e) => {
                        console.error(e);
                        setError('Page.lockdown.top-banner.devices.error');
                        setLoadingDevices(false);
                    });
            }, 2000);
        }
    }, [isLockdownActive, activeScenarios, t]);

    useEffect(() => {
        if (unsuccessfulDevices) {
            setShouldShowDevices(unsuccessfulDevices.length > 0);
            setLoadingDevices(false);
        }
    }, [unsuccessfulDevices]);

    useEffect(() => {
        if (error) {
            addErrorMessage({ text: t(error) });
            setError(false);
        }
    }, [error, addErrorMessage, t]);

    const openDoorsModal = async () => {
        setLoadingDevices(true);
        const ids = activeScenarios.map((scenario) => scenario.id);
        try {
            const response = await getUnsuccessfulDevices(ids);
            const devices = response.listUnsuccessfulDevices;
            if (response.errors) {
                setError('Page.lockdown.top-banner.devices.error');
            }
            setUnsuccessfulDevices(devices);
            setIsOpen(true);
        } catch (e) {
            console.error(e);
            setError('Page.lockdown.top-banner.devices.error');
            setLoadingDevices(false);
        }
    };

    if (isLockdownActive) {
        const bannerMessage =
            activeScenarios.length === 1 ? (
                shouldShowDevices ? (
                    <Trans t={t} i18nKey="Page.lockdown.top-banner">
                        emergency scenario is active, but some devices are <strong>NOT</strong> affected.
                    </Trans>
                ) : (
                    t('Page.lockdown.top-banner.no-devices')
                )
            ) : shouldShowDevices ? (
                <Trans t={t} i18nKey="Page.lockdown.top-banner.plural">
                    emergency scenarios are active, but some devices are <strong>NOT</strong> affected.
                </Trans>
            ) : (
                t('Page.lockdown.top-banner.plural.no-devices')
            );
        return (
            <>
                <div className="LockdownBanner">
                    <Banner
                        title={
                            <div id="lockdown-scenario-name" className={classes.text}>
                                {<LockdwonRoundedIcon className={classes.icon} />}
                                {activeScenarios?.map((scenario, index) => (
                                    <Fragment key={scenario.id}>
                                        {<strong>{getScenarioName(scenario)}</strong>}
                                        {getSeparator(index, activeScenarios.length)}
                                    </Fragment>
                                ))}
                                &nbsp;
                                {bannerMessage}
                                &nbsp;
                                {areDevicesLoading ? (
                                    <LoadingIndicator size="small" padding="6px" color="inherit" />
                                ) : shouldShowDevices ? (
                                    <>
                                        <AccessControl anyOfPermissions={[DEVICES_VIEW_DEVICES]}>
                                            <Link className={classes.link} onClick={openDoorsModal}>
                                                {t('Page.lockdown.top-banner-review')}
                                            </Link>
                                        </AccessControl>
                                    </>
                                ) : null}
                            </div>
                        }
                        css={bannerCss}
                    />
                </div>
                <ErrorDialog
                    icon={() => <DoorIcon fill={theme.palette.error.main} />}
                    title={t('Page.lockdown.top-banner.dialog.title')}
                    open={isOpen}
                    noButtons={true}
                    onClose={() => setIsOpen(false)}
                    fullWidth={true}
                    maxWidth={'md'}
                    children={
                        <>
                            <ItemsList allItems={unsuccessfulDevices} />

                            <TableLegend
                                x={unsuccessfulDevices.length}
                                y={unsuccessfulDevices.length}
                                label={t('Page.lockdown.top-banner.dialog.legend')}
                            />
                        </>
                    }
                />
            </>
        );
    } else {
        return null;
    }
};

export const getScenarioName = (scenario) => {
    const isFullLockdown = !scenario.name && get(scenario, 'description', '').includes('all the doors');

    return isFullLockdown ? 'Full Lockdown' : scenario.name;
};

export const getSeparator = (index, length) =>
    index === length - 2 && length === 2 ? ' and ' : index === length - 2 ? ', and ' : index === length - 1 ? '' : ', ';

export default withTheme(LockdownBanner);
