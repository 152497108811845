import { isNil } from 'lodash';
import { ONLINE, WARNING, UNKNOWN, STATUS_UNKNOWN, STATUS_CONNECTED } from '@common/constants/Constants';

const monitoredDoorInputStatus = {
    DOOR_OPEN: 4,
    DOOR_CLOSED: 5,
};

// type MonitoredDoor = {
//     name: string;
//     deviceAjarEnabled: boolean;
//     type: string;
//     status: {
//         deviceStatus?: string;
//         rawData: {
//             connectedStatus: 0 | 1;
//             ajarStatus: 0 | 1;
//             inputStatus: number; // 4 means door open, 5 means door closed
//         };
//     };
// };

export function isMonitoredDoor(device) {
    return device?.type === 'Switch' && device?.deviceAjarEnabled === true;
}

export function getMonitoredDoorStatus(deviceData) {
    const rawData = deviceData?.status?.rawData;

    const panelIsDisconnected = deviceData?.status?.deviceStatus === UNKNOWN;

    const isMissingStatusData =
        rawData?.connectedStatus !== 1 || isNil(rawData?.ajarStatus) || isNil(rawData?.inputStatus);

    if (isMissingStatusData || !isValidMonitoredDoorInputStatusValue(rawData?.inputStatus) || panelIsDisconnected) {
        return UNKNOWN;
    }

    const isAjar = rawData?.ajarStatus === 1;
    if (isAjar) {
        return WARNING;
    }

    return ONLINE;
}

function isValidMonitoredDoorInputStatusValue(v) {
    return v === monitoredDoorInputStatus.DOOR_OPEN || v === monitoredDoorInputStatus.DOOR_CLOSED;
}

export function getMonitoredDoorConnectionStatus(deviceData) {
    const deviceStatus = getMonitoredDoorStatus(deviceData);

    if (deviceStatus === ONLINE || deviceStatus === WARNING) {
        return STATUS_CONNECTED;
    }

    return STATUS_UNKNOWN;
}
