import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path d="M1 0H17C17.552 0 18 0.448 18 1V10H16V2H2V18H5V17C5 14.795 6.794 13 9 13C9 13 9.97137 13 10.7736 13.8088L9.82789 15.3415C9.45614 15 9 15 9 15C7.897 15 7 15.898 7 17L6.999 19.001C6.999 19.554 6.551 20 5.999 20H1C0.448 20 0 19.553 0 19V1C0 0.449 0.448 0 1 0Z" />
        <path d="M5 7H7C7.552 7 8 6.553 8 6V5C8 4.449 7.552 4 7 4H5C4.448 4 4 4.449 4 5V6C4 6.552 4.447 7 5 7Z" />
        <path d="M11 7H13C13.552 7 14 6.553 14 6V5C14 4.449 13.552 4 13 4H11C10.448 4 10 4.449 10 5V6C10 6.552 10.447 7 11 7Z" />
        <path d="M5 12H7C7.552 12 8 11.553 8 11V10C8 9.449 7.552 9 7 9H5C4.448 9 4 9.449 4 10V11C4 11.552 4.447 12 5 12Z" />
        <path d="M11 12H13C13.552 12 14 11.553 14 11V10C14 9.449 13.552 9 13 9H11C10.448 9 10 9.449 10 10V11C10 11.552 10.447 12 11 12Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 20C15.895 20 15 19.104 15 18C15 16.896 15.895 16 17 16C18.104 16 19 16.896 19 18C19 19.104 18.104 20 17 20ZM23.002 19V17H21.9C21.77 16.362 21.515 15.771 21.167 15.246L21.951 14.462L20.537 13.048L19.752 13.833C19.229 13.485 18.638 13.231 18.001 13.101V12H16.001V13.101C15.364 13.231 14.772 13.485 14.248 13.833L13.465 13.05L12.051 14.464L12.834 15.247C12.486 15.771 12.232 16.363 12.102 17H11V19H12.102C12.232 19.637 12.486 20.229 12.834 20.753L12.051 21.539L13.466 22.951L14.249 22.167C14.773 22.515 15.365 22.769 16.002 22.899V24.001H18.002V22.899C18.639 22.769 19.23 22.514 19.755 22.167L20.539 22.949L21.952 21.534L21.168 20.751C21.516 20.228 21.77 19.637 21.9 19H23.002Z"
        />
    </React.Fragment>,
    'SiteSettings'
);
