import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    const markerBorderColor = theme.palette.brivoAliases.tooltipBorder;
    const markerBgColor = theme.palette.brivoAliases.tooltipBackground;
    const markerTextColor = theme.palette.brivoAliases.tooltipTitle;

    return {
        timelineWithOverflow: {
            flexGrow: 1,
            '& .vis-timeline': {
                '& .vis-item.event': {
                    '&.smallWidth': {
                        width: '5px !important', // otherwise, `10 min` view option doesn't show marker separation if they're side by side
                    },

                    '&.informational': {
                        background: theme.palette.severityIcon.background.informational,
                    },
                    '&.warning': {
                        background: theme.palette.severityIcon.background.warning,
                    },
                    '&.critical': {
                        background: theme.palette.severityIcon.background.critical,
                    },
                },

                '& .vis-foreground': {
                    '& .vis-minor.vis-text': {
                        paddingLeft: theme.spacing(0.425), // furthest out it can go without getting in the depths
                    },
                },

                '& .vis-background': {
                    overflowY: 'visible',
                    overflowX: 'visible',
                },

                '& .timeline-position': {
                    background: 'none',

                    '& > div:first-child': {
                        width: '2px !important',
                        left: '0 !important',
                        backgroundColor: theme.palette.colors.blue400,
                    },
                },

                '& .vis-custom-time': {
                    height: '155px',
                    '& > .vis-custom-time-marker': {
                        width: '100px',
                        top: '65px',
                        left: '-50px',
                        whiteSpace: 'unset',
                        textOverflow: 'ellipsis',
                        textAlign: 'center',
                        overflow: 'hidden',
                        border: `1px solid ${markerBorderColor}`,
                        backgroundColor: markerBgColor,
                        color: markerTextColor,
                        padding: 0,
                    },
                },

                '& .vis-panel': {
                    border: 'none',
                },
            },
        },
        unselectedMarker: {
            '& .vis-timeline': {
                '& .vis-custom-time': {
                    '&.timeline-position': {
                        '& > div:first-child': {
                            backgroundColor: theme.palette.text.disabled,
                        },

                        '&::after': {
                            background: theme.palette.text.disabled,
                        },
                    },
                },
            },
        },
        timelineContainerMargin: {
            paddingTop: theme.spacing(1),
        },
        timeline: {
            width: '100%',
        },
        moveInTimeArrows: {
            cursor: 'pointer',
            paddingTop: 9,
            width: '20px',
            height: '40px',
        },
    };
});

export default styles;
