import { makeStyles } from '@brivo/react-components';

const styles = makeStyles(() => ({
    snackbar: {
        width: '400px',
        display: 'block',
    },
}));

export default styles;
