import gql from 'graphql-tag';

export const GET_ANALYTICS_DASHBOARDS = gql`
    query getAnalyticsDashboardsForAdmin {
        getAnalyticsDashboardsForAdmin {
            dashboardId
            dashboardName
            dashboardNameKey
            created
            updated
            accountId
            ownerAdminId
            configurations
            sharingType
            isDefault
        }
    }
`;

export const SAVE_ANALYTICS_DASHBOARD = gql`
    mutation saveAnalyticsDashboardsForAdmin($analyticsDashboard: AnalyticsDashboardInput!) {
        saveAnalyticsDashboardsForAdmin(analyticsDashboard: $analyticsDashboard)
    }
`;

export const SAVE_DASHBOARD_AS_DEFAULT = gql`
    mutation setDefaultAnalyticsDashboardForAdmin($dashboardId: String!) {
        setDefaultAnalyticsDashboardForAdmin(dashboardId: $dashboardId)
    }
`;

export const DELETE_PRIVATE_VIEW_FOR_ADMIN = gql`
    mutation deletePrivateAnalyticsViewForAdmin($dashboardId: String!) {
        deletePrivateAnalyticsViewForAdmin(dashboardId: $dashboardId)
    }
`;

export const RESET_PUBLIC_VIEW_TO_DEFAULT = gql`
    mutation resetToCuratedAnalyticsView($dashboardId: String!) {
        resetToCuratedAnalyticsView(dashboardId: $dashboardId)
    }
`;
