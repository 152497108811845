import React from 'react';
import { Typography } from '@brivo/react-components';
import useStyles from './styles';
import clsx from 'clsx';

const PropertyHeader = ({ title, subtitle }) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Typography component="span" className={clsx(classes.title, 'u-trim')}>
                {title}
                {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
            </Typography>
        </div>
    );
};

export default PropertyHeader;
