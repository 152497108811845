import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@brivo/react-components';
import clsx from 'clsx';

const useStyles = makeStyles({
    root: {
        marginBottom: '2.5rem',
        paddingRight: '1.75rem',
    },
});

const PageHeader = ({ title, children }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, 'u-fx u-fx-justify-space-between')}>
            <Typography
                color="textSecondary"
                id="page-title"
                className="u-trim"
                title={title}
                variant="h4"
                component="p"
            >
                {title}
            </Typography>
            <div className="u-fx u-fx-align-center">{children}</div>
        </div>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
};

export default memo(PageHeader);
