import { useEffect, useState, useRef, useCallback } from 'react';
import { isEqual } from 'lodash';

import { readerCommandsApi } from '../../../common/webApis/readerCommands/readerCommandsApi';
import { usePrevious } from '../../../common/hooks/usePrevious';

import { COMMANDS_PAGE_SIZE } from '../utils/commandsConstants';
import { getQueryParams } from '../utils/commandsUtils';
import { useRunOnce } from '../../../common/hooks/useRunOnce';

const useReaderCommands = (filters, nrFiltersChanged) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);

    const lastPromise = useRef();
    const offset = useRef(0);
    const filteredCommandsCount = useRef(0);

    const prevFilters = usePrevious(filters);

    const loadNextPage = () => {
        const allCommandsAreLoaded = totalCount <= offset.current + COMMANDS_PAGE_SIZE;
        const allFilteredCommandsAreLoaded = filteredCommandsCount.current < offset.current + COMMANDS_PAGE_SIZE;
        if (nrFiltersChanged && allFilteredCommandsAreLoaded) {
            return;
        }
        if (loading || allCommandsAreLoaded) {
            return;
        }
        setLoading(true);
        offset.current += COMMANDS_PAGE_SIZE;
        fetchCommands({ offset: offset.current }).then(() => setLoading(false));
    };

    const fetchCommands = useCallback(
        async ({ offset } = { offset: 0 }) => {
            const currentPromise = readerCommandsApi.getCommands(getQueryParams({ offset, filters }));
            lastPromise.current = currentPromise;
            const result = await currentPromise;

            if (currentPromise === lastPromise.current) {
                setData((currentData) => currentData.concat(result.data));
            }

            return result.count;
        },
        [filters]
    );

    const fetchCount = useCallback(async () => {
        const result = await readerCommandsApi.getCommands({ pageSize: 0 });
        setTotalCount(result.count);
    }, []);

    const reloadData = useCallback(() => {
        setData([]);
        setTotalCount(-1);
        setLoading(true);
        offset.current = 0;
        if (nrFiltersChanged === 0) {
            fetchCommands({ offset: 0 }).then((count) => {
                setTotalCount(count);
                setLoading(false);
            });
        } else {
            Promise.all([fetchCommands({ offset: 0 }), fetchCount()]).then(() => {
                setLoading(false);
            });
        }
    }, [fetchCommands, fetchCount, nrFiltersChanged]);

    useRunOnce(() => {
        setLoading(true);
        fetchCommands({ offset: 0 }).then((count) => {
            setTotalCount(count);
            setLoading(false);
        });
    });

    // should trigger ONLY when filters change
    useEffect(() => {
        const isFilterChanged = prevFilters !== undefined && !isEqual(filters, prevFilters);

        if (!isFilterChanged) {
            return;
        }

        offset.current = 0;
        setData([]);
        setLoading(true);
        fetchCommands({ offset: 0 }).then((res) => {
            setLoading(false);
            filteredCommandsCount.current = res;
        });
    }, [fetchCommands, filters, prevFilters]);

    const deleteCommand = async (id) => {
        const result = await readerCommandsApi.deleteReaderCommand(id).catch(console.warn);
        reloadData();
        return result;
    };

    const getCommand = useCallback(async (id) => {
        const command = await readerCommandsApi.getReaderCommand(id).catch(console.warn);
        return command;
    }, []);

    return {
        totalCount,
        data,
        deleteCommand,
        getCommand,
        loading,
        loadNextPage,
        reloadData,
    };
};

export default useReaderCommands;
