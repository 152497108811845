import { SvgIcon } from '@brivo/react-components';

const BannerIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 56 50" {...props}>
            <path
                d="M29.1058 38.408H21.7518C21.3369 38.408 21.0005 38.0716 21.0005 37.6567C21.0005 37.2418 21.3369 36.9054 21.7518 36.9054H29.1058C29.5207 36.9054 29.8571 37.2418 29.8571 37.6567C29.8571 38.0716 29.5207 38.408 29.1058 38.408Z"
                fill="#5076A7"
            />
            <path
                d="M29.1058 40.7677H21.7518C21.3369 40.7677 21.0005 40.4313 21.0005 40.0164C21.0005 39.6015 21.3369 39.2651 21.7518 39.2651H29.1058C29.5207 39.2651 29.8571 39.6015 29.8571 40.0164C29.8571 40.4313 29.5207 40.7677 29.1058 40.7677Z"
                fill="#5076A7"
            />
            <path
                d="M23.6447 41.6569C23.4626 41.6569 23.3245 41.8224 23.3581 42.0013C23.5389 42.9693 24.387 43.7021 25.4079 43.7021C26.4282 43.7021 27.2764 42.9693 27.4576 42.0013C27.4908 41.8224 27.3527 41.6569 27.1706 41.6569H23.6447Z"
                fill="#5076A7"
            />
            <path
                d="M28.3276 35.9589H22.4228C21.4001 35.9589 20.539 35.1592 20.4623 34.1384C20.212 30.8094 18.8534 28.7001 17.4155 26.4669C15.847 24.0316 14.2251 21.5133 14.2251 17.4411C14.2251 11.9422 18.1582 7.31481 23.5777 6.43795C24.8444 6.23297 26.1792 6.24912 27.4395 6.48415C32.7048 7.46866 36.5262 12.0768 36.5262 17.4411C36.5262 21.5133 34.9043 24.0316 33.3354 26.4669C31.897 28.7001 30.5388 30.8094 30.2885 34.1384C30.2119 35.1592 29.3502 35.9589 28.3276 35.9589Z"
                fill="#F1D856"
            />
            <path
                d="M19.9725 24.4266C19.7487 24.4266 19.5276 24.3231 19.3854 24.1282C15.3568 18.5948 18.3264 13.4015 18.4552 13.1831C18.6579 12.8377 19.1028 12.7225 19.4482 12.9256C19.7931 13.1284 19.9088 13.5721 19.707 13.9173C19.5958 14.109 17.1056 18.5312 20.5588 23.2739C20.7947 23.5978 20.7234 24.0518 20.3991 24.2875C20.2704 24.3814 20.1205 24.4266 19.9725 24.4266Z"
                fill="white"
            />
            <path
                d="M4.0272 23.0272L2.96967 21.8695L4.94394 20.066L3.27266 18.2365L4.33732 17.2639L6.0086 19.0935L7.99322 17.2806L9.05075 18.4383L7.06614 20.2512L8.73742 22.0807L7.67276 23.0533L6.00148 21.2237L4.0272 23.0272Z"
                fill="#5076A7"
            />
            <ellipse cx="9.06449" cy="21.636" rx="0.505898" ry="0.505898" fill="#FAFCFD" />
            <mask id="path-8-inside-1_4705_6366" fill="white">
                <ellipse cx="8.05277" cy="29.3933" rx="0.505898" ry="0.505898" />
            </mask>
            <path
                d="M7.76848 29.3933C7.76848 29.2363 7.89576 29.1091 8.05277 29.1091V30.6894C8.76858 30.6894 9.34886 30.1092 9.34886 29.3933H7.76848ZM8.05277 29.1091C8.20978 29.1091 8.33706 29.2363 8.33706 29.3933H6.75669C6.75669 30.1092 7.33697 30.6894 8.05277 30.6894V29.1091ZM8.33706 29.3933C8.33706 29.5504 8.20978 29.6776 8.05277 29.6776V28.0973C7.33697 28.0973 6.75669 28.6775 6.75669 29.3933H8.33706ZM8.05277 29.6776C7.89576 29.6776 7.76848 29.5504 7.76848 29.3933H9.34886C9.34886 28.6775 8.76858 28.0973 8.05277 28.0973V29.6776Z"
                fill="#A5B4C4"
                mask="url(#path-8-inside-1_4705_6366)"
            />
            <path
                d="M8.48162 35.5326C8.654 35.2915 9.0342 35.4119 9.03646 35.7082C9.03747 35.8408 9.12371 35.9576 9.25008 35.9976C9.53265 36.087 9.53569 36.4858 9.25451 36.5796C9.12876 36.6215 9.04431 36.7396 9.04532 36.8721C9.04758 37.1685 8.66926 37.2946 8.49323 37.0562C8.4145 36.9495 8.27607 36.9057 8.15032 36.9476C7.86915 37.0414 7.63229 36.7206 7.80467 36.4794C7.88176 36.3716 7.88066 36.2264 7.80193 36.1198C7.6259 35.8813 7.85784 35.5569 8.14041 35.6464C8.26678 35.6864 8.40453 35.6405 8.48162 35.5326Z"
                fill="#FAFCFD"
            />
            <path
                d="M4.99232 23.1C5.12448 22.9152 5.41598 23.0074 5.41771 23.2347C5.41848 23.3363 5.4846 23.4258 5.58149 23.4565C5.79813 23.5251 5.80046 23.8308 5.58489 23.9027C5.48848 23.9348 5.42373 24.0254 5.4245 24.127C5.42624 24.3542 5.13618 24.4509 5.00122 24.2681C4.94086 24.1864 4.83472 24.1528 4.73831 24.1849C4.52274 24.2568 4.34114 24.0108 4.47331 23.8259C4.53241 23.7433 4.53156 23.6319 4.4712 23.5502C4.33624 23.3674 4.51407 23.1187 4.73071 23.1872C4.8276 23.2179 4.93321 23.1827 4.99232 23.1Z"
                fill="#A5B4C4"
            />
            <path
                d="M52.7062 26.2893L50.9405 27.2623L49.2813 24.2512L46.4909 25.7888L45.5961 24.165L48.3865 22.6274L46.7186 19.6005L48.4843 18.6275L50.1522 21.6544L52.9426 20.1168L53.8374 21.7406L51.047 23.2782L52.7062 26.2893Z"
                fill="#A5B4C4"
            />
            <ellipse cx="40.2558" cy="25.9757" rx="1.09611" ry="1.09611" fill="#FAFCFD" />
            <circle cx="44.3898" cy="22.4625" r="0.33021" fill="#FAFCFD" />
            <circle cx="42.9166" cy="31.8428" r="0.395094" stroke="#A5B4C4" strokeWidth="0.727505" />
            <path
                d="M38.4235 35.5834C38.5556 35.3986 38.8471 35.4908 38.8489 35.7181C38.8496 35.8197 38.9158 35.9092 39.0126 35.9399C39.2293 36.0085 39.2316 36.3142 39.016 36.3861C38.9196 36.4182 38.8549 36.5088 38.8557 36.6104C38.8574 36.8376 38.5673 36.9343 38.4324 36.7515C38.372 36.6698 38.2659 36.6362 38.1695 36.6683C37.9539 36.7402 37.7723 36.4942 37.9045 36.3093C37.9636 36.2267 37.9627 36.1153 37.9024 36.0336C37.7674 35.8508 37.9452 35.6021 38.1619 35.6706C38.2587 35.7013 38.3644 35.6661 38.4235 35.5834Z"
                fill="#FAFCFD"
            />
        </SvgIcon>
    );
};

export default BannerIcon;
