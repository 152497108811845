import { makeStyles } from '@brivo/react-components';
import { drawerWidth, smallerDrawerWidth } from './UnifiedDashboardPage';

export default makeStyles((theme) => ({
    loadingIndicator: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    widgetAddButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(1),
    },
    widgetEditButton: {
        marginRight: theme.spacing(1),
    },
    widgetTitleDescription: {
        paddingLeft: theme.spacing(0.5),
    },
    widgetHeaderLeft: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',

        '& .MuiSvgIcon-root': {
            cursor: 'grab',
        },
    },
    drawerWithScroll: {
        padding: theme.spacing(2, 2, 7, 2),
        overflow: 'hidden',
        overflowY: 'scroll',
    },
    drawerPadding: {
        padding: theme.spacing(2),
    },
    drawerHeadingTitle: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    drawerHeadingButton: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 'auto',
    },
    drawerSearch: {
        paddingBottom: theme.spacing(1),
    },
    drawerWidgetContainer: {
        margin: 0,
    },
    subDrawerContainer: {
        '& > .MuiGrid-item': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    drawerWidget: {
        textAlign: 'center',
        paddingBottom: theme.spacing(0.5),
    },
    drawerWidgetIcon: {
        width: '100%',
        height: '80px',
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.brivoAliases.blockListBackground,
    },
    drawerWidgetLabel: {
        paddingTop: theme.spacing(0.5),
    },
    mainDrawerActionsContainer: {
        position: 'absolute',
        bottom: 0,
        background: 'inherit',
    },
    mainDrawerCancelButton: {
        paddingRight: theme.spacing(1),
    },
    titleTooltip: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginBottom: theme.spacing(2),
    },
    leftSideHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px',

        '& div.PageHeaderMenu': {
            zIndex: 0,
        },
    },
    siteFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,

        '& .Button': {
            marginLeft: theme.spacing(2),
        },
    },
    drawerPaper: {
        width: `${drawerWidth}px`,
        backgroundColor: theme.palette.brivoAliases.blockBackground,
        height: '100%',
    },
    smallerDrawerPaper: {
        width: `${smallerDrawerWidth}px`,
        height: '100%',
    },
    emptyStateSection: {
        height: '20vh',
        minHeight: '100%',

        '& div#EmptyStateSection': {
            height: '100%',
            minHeight: '100%',

            // target emptyListIcon from @brivo/react-components
            '& > div:first-child > div:first-child > div:first-child': {
                paddingBottom: 0,
            },

            '& .u-fx-justify-center': {
                height: '100%',
                backgroundColor: 'unset',
                boxShadow: 'none',
            },
        },
    },
    createNewDashboardButton: {
        marginLeft: theme.spacing(1.5),
    },
    buttonHeader: {
        marginBottom: theme.spacing(1),
    },
}));
