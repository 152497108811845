import { API_BASE_URL, API_SITES_URL } from '@common/constants/Constants';

import { WebReqApi } from '../WebReqApi';
import {
    GET_CONSENT_LISTING,
    GET_USERS_FOR_JOURNAL_PAGE_TAG,
    REVOKE_CONSENT_INVITE_BY_USERID,
    GET_BADGE_PHOTO_QUALITY,
} from './gqlTags';

class UsersApi extends WebReqApi {
    getCurrentUser() {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/administrators/whoami`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
        });
    }

    revokeConsentInviteByUserId(userId) {
        return this.makeGqlMutation({
            gqlTag: REVOKE_CONSENT_INVITE_BY_USERID,
            variables: {
                userId,
            },
        });
    }

    // TODO -> test
    removeUserCredential(userId, credentialId) {
        return this.makeRestRequest({
            method: 'DELETE',
            url: `${API_BASE_URL}/users/:userId/credentials/:credentialId`,
            urlParams: {
                userId,
                credentialId,
            },
        });
    }

    // TODO -> test
    bulkRemoveUserCredentials(credentials) {
        // WARNING! Promise.allSettled never throws.
        // To find any rejections we need to check the resolved array
        return Promise.allSettled(credentials.map((c) => this.removeUserCredential(c.user.id, c.id)));
    }

    getUsersForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_USERS_FOR_JOURNAL_PAGE_TAG,
            variables: {
                name,
                rows,
            },
            defaultValue: [],
        });
    }

    getConsentListing({ pageSize = 50, offset = 0, sortField = 'last_name', direction = 'ASC', filters = [] }) {
        return this.makeGqlQuery({
            gqlTag: GET_CONSENT_LISTING,
            variables: {
                pageSize,
                offset,
                sortField,
                direction,
                filters,
            },
            defaultValue: { count: 0, consents: [] },
        });
    }

    createUserDigitalInvitation = ({
        userId,
        nfcEnabled = null,
        sendInvitationEmail = true,
        email,
        language,
        isAllegionEnabled = false,
    }) => {
        const queryParams = {
            sendInvitationEmail,
        };

        if (nfcEnabled !== null) {
            queryParams.nfcEnabled = nfcEnabled;
        }

        if (language) {
            queryParams.language = language;
        }

        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/users/:userId/credentials/digital-invitations`,
            urlParams: {
                userId,
            },
            queryParams,
            body: {
                referenceId: email,
                allegionEnabled: isAllegionEnabled,
            },
        });
    };

    updateUserCredential = (userId, credentialId) => {
        return this.makeRestRequest({
            method: 'PUT',
            url: `${API_BASE_URL}/users/${userId}/credentials/${credentialId}`,
            body: { effectiveFrom: '', effectiveTo: '' },
        });
    };

    getBadgePhotoQuality = (userId) => {
        return this.makeGqlQuery({
            gqlTag: GET_BADGE_PHOTO_QUALITY,
            variables: {
                userId,
            },
            defaultValue: { quality: 0, acceptability: 0 },
        });
    };
    addUsersToHierarchy = async (siteId, usersToAdd) => {
        try {
            return await this.makeRestRequest({
                method: 'PUT',
                url: `${API_SITES_URL}/${siteId}/users-add`,
                body: { usersToBeAdded: usersToAdd },
            });
        } catch (e) {
            throw Error('Failed to assign hierarchy permissions ', e);
        }
    };

    removeUsersFromHierarchy = async (siteId, usersToRemove) => {
        try {
            return await this.makeRestRequest({
                method: 'PUT',
                url: `${API_SITES_URL}/${siteId}/users-remove`,
                body: { usersToBeRemoved: usersToRemove },
            });
        } catch (e) {
            throw Error('Failed to remove hierarchy permissions ', e);
        }
    };
}

export const usersApi = new UsersApi();
