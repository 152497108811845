import React from 'react';

export const RotateIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" className="rotate-icon">
            <path
                className="rotate-path"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8564 3.85538C9.04029 3.85538 6.74559 5.20202 5.44934 6.49827C4.14661 7.801 2.85645 10.047 2.85645 12.8554C2.85645 15.6715 4.20308 17.9662 5.49934 19.2625C6.80207 20.5652 9.04806 21.8554 11.8564 21.8554C12.4087 21.8554 12.8564 21.4077 12.8564 20.8554C12.8564 20.3031 12.4087 19.8554 11.8564 19.8554C9.66483 19.8554 7.90702 18.8417 6.91355 17.8483C5.91361 16.8483 4.85645 15.0392 4.85645 12.8554C4.85645 10.6638 5.87008 8.90595 6.86355 7.91248C7.8635 6.91254 9.6726 5.85538 11.8564 5.85538C14.0481 5.85538 15.8059 6.86902 16.7993 7.86248C17.616 8.67915 18.4708 10.0355 18.7568 11.695L17.9811 11.0745C17.5499 10.7295 16.9206 10.7994 16.5756 11.2307C16.2306 11.6619 16.3005 12.2912 16.7318 12.6362L19.2318 14.6362C19.6296 14.9546 20.2032 14.9228 20.5636 14.5625L22.5636 12.5625C22.9541 12.172 22.9541 11.5388 22.5636 11.1483C22.173 10.7577 21.5399 10.7577 21.1493 11.1483L20.7579 11.5397C20.4248 9.32731 19.3014 7.53616 18.2136 6.44827C16.9108 5.14554 14.6648 3.85538 11.8564 3.85538Z"
                fill="black"
            />
        </svg>
    );
};
