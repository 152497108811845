import React, { useState } from 'react';
import { get } from 'lodash';
import {
    IconButton,
    LockedIcon,
    makeStyles,
    Switch,
    withStyles,
    withTheme,
    Button,
    LockOpenIcon,
} from '@brivo/react-components';
import { useTranslation } from 'react-i18next';

import { DEVICES_UNLOCK_DOORS } from '../../../../common/permissions/Permissions';
import AccessControl from '../../../../common/components/AccessControl';
import { useAssignments } from '../../../../common/hooks/useAssignments';

const GreenSwitch = withStyles((theme) => ({
    root: {
        height: '15px',
        width: '40px',
        borderRadius: '5px',
        padding: 0,
    },
    switchBase: {
        padding: 2,
        color: '#FFFFFF',
        '&$checked': {
            color: '#FFFFFF',
        },
        '&$checked + $track': {
            backgroundColor: '#5F9B43',
            border: '1px solid #5F9B43',
        },
    },
    thumb: {
        width: '15px',
        height: '11px',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
    },
    checked: {},
    track: {
        border: '1px solid #232D39',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.deviceStatusCard.offline.text : theme.palette.colors.grey900,
    },
}))(Switch);

const useStyles = makeStyles({
    disabledButton: {
        opacity: 0.4,
    },
});

const DoorActionFromCamera = ({
    accessPoint,
    openConfirmationDialog,
    handleEnableMonitoringForDoor,
    theme,
    inlineLayout = false,
}) => {
    const deviceId = get(accessPoint, 'id');
    const deviceName = get(accessPoint, 'name');
    const canBeActivated = get(accessPoint, 'activationEnabled');
    const isMonitored = get(accessPoint, 'reportLiveStatus');
    const isOffline = get(accessPoint, 'status.isOffline');
    const isUnlocked = get(accessPoint, 'status.lockState') === 'Unlocked';
    const { isAssignedToSite } = useAssignments();
    const isDoorFromAnAssignedSite = accessPoint && accessPoint.siteId ? isAssignedToSite(accessPoint.siteId) : false;
    const canUnlockDoor = !isOffline && !isUnlocked && isDoorFromAnAssignedSite;
    const classes = useStyles();
    const { t } = useTranslation();
    const [monitoringStatus, setMonitoringStatus] = useState(false);

    const onSwitchChange = (event) => {
        setMonitoringStatus(event.target.checked);
        handleEnableMonitoringForDoor(deviceId);
    };

    return !isMonitored ? (
        <GreenSwitch
            checked={monitoringStatus}
            onChange={onSwitchChange}
            inputProps={{
                'data-testid': 'enable-monitoring-switch',
            }}
            theme={theme}
        />
    ) : (
        canBeActivated && (
            <AccessControl anyOfPermissions={[DEVICES_UNLOCK_DOORS]}>
                {inlineLayout ? (
                    <Button
                        type="secondary"
                        text={t('Page.unified-video.video-modal.button.unlock')}
                        id={`${deviceId}-button-unlock-door`}
                        onClick={() => canUnlockDoor && openConfirmationDialog(deviceId, deviceName)}
                        startIcon={isUnlocked ? <LockOpenIcon /> : <LockedIcon />}
                        disabled={!canUnlockDoor}
                    />
                ) : (
                    <IconButton
                        className={!canUnlockDoor && classes.disabledButton}
                        id={`${deviceId}-button-unlock-door`}
                        type={canUnlockDoor ? 'primary' : 'secondary'}
                        size="small"
                        onClick={() => canUnlockDoor && openConfirmationDialog(deviceId, deviceName)}
                        icon={isUnlocked ? <LockOpenIcon /> : <LockedIcon />}
                        disabled={!canUnlockDoor}
                    />
                )}
            </AccessControl>
        )
    );
};

export default withTheme(DoorActionFromCamera);
