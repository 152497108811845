import { useTranslation } from 'react-i18next';

import { FiltersContainer } from '@brivo/react-components';

const LockdownScenarioFiltersGroup = ({ headerRightSlot }) => {
    const { t } = useTranslation();

    return (
        <FiltersContainer
            showMore={{
                enabled: false,
                showMoreTitle: t('Component.filters-container.show-more-title'),
                showLessTitle: t('Component.filters-container.show-less-title'),
            }}
            topRight={headerRightSlot}
        ></FiltersContainer>
    );
};
export default LockdownScenarioFiltersGroup;
