import { Box, ITheme, withTheme } from '@brivo/react-components';
import useStyles from './styles';
import { Campaign } from '../../interfaces';

interface FeaturePreviewImageProps {
    theme: ITheme;
    featurePreview: Campaign;
}

const FeaturePreviewImage = ({ theme, featurePreview }: FeaturePreviewImageProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.imgContainer}>
            <img
                className={classes.imgStyle}
                alt="feature preview"
                src={
                    theme.palette.type === 'dark'
                        ? featurePreview.campaign_image_dark_mode
                        : featurePreview.campaign_image_light_mode
                }
            />
        </Box>
    );
};

export default withTheme(FeaturePreviewImage);
