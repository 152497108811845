import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@brivo/react-components';
import { AddIcon, FilterChip } from '@brivo/react-components';
import * as _ from 'lodash';

import styles from './styles';

const ChipSize = ({ length, limit, showMoreText = '', className, handleClickOnMore }) => {
    return length ? (
        <Typography onClick={handleClickOnMore} className={className}>
            {length > limit ? `+${length - limit} ${showMoreText}` : ''}
        </Typography>
    ) : null;
};

const MultipleChipDisplayField = ({
    id,
    label = '',
    value,
    onValueChange,
    fullWidth,
    disabled,
    className,
    clearingValues,
    setClearingValues,
    onAddItemClick,
    maxRows = 5,
    noSelectionLabel,
    scrollMarginTop,
}) => {
    const classes = styles({ scrollMarginTop });
    const { t } = useTranslation();

    const showMoreText = t('Page.lockdown-scenarios.lockdown-scenario-details.page.multi-chip-display.show-more');
    const wrapperRef = useRef(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [displayedChips, setDisplayedChips] = useState([]);
    const [limit, setLimit] = useState(value?.length);
    const [elRefs, setElRefs] = useState([]);
    const arrLength = selectedItems.length;

    useEffect(() => {
        setDisplayedChips([...selectedItems].splice(0, limit));
    }, [selectedItems, limit]);

    useEffect(() => {
        setElRefs((elRefs) =>
            Array(arrLength)
                .fill()
                .map((_, i) => elRefs[i] || createRef())
        );
    }, [arrLength]);

    useEffect(() => {
        if (clearingValues && setClearingValues) {
            setSelectedItems([]);
            setClearingValues(false);
        }
    }, [clearingValues, setClearingValues]);

    useEffect(() => {
        if (value) {
            setSelectedItems(value);
        }
    }, [value]);

    const handleChipDelete = (event, chip) => {
        event.stopPropagation();
        const filteredChips = selectedItems?.filter((selectedItem) => selectedItem.id !== chip.id);
        setSelectedItems(filteredChips);
        onValueChange(filteredChips);
    };

    const getChipLimit = useCallback(
        (itemRef) => {
            // show five rows of chips
            let nrItems = value?.length;
            let limitSet = false;

            const someRefsAreNull = _.some(itemRef, (item) => _.some(_.pick(item, 'current'), _.isNull));

            if (itemRef.length === value?.length && itemRef[0]?.current) {
                let wrapperWidth = wrapperRef?.current?.clientWidth;
                let rows = 0;
                let itemsWidth = 0;

                if (!someRefsAreNull) {
                    itemRef.forEach((item, index) => {
                        itemsWidth += item.current?.clientWidth + itemRef[index + 1]?.current?.clientWidth;

                        if (itemsWidth > wrapperWidth) {
                            rows++;
                            itemsWidth = 0;
                        }
                        if (rows > maxRows && !limitSet) {
                            nrItems = index - 1;
                            limitSet = true;
                        }
                    });
                } else {
                    nrItems = _.findIndex(itemRef, (item) => !item.current) || maxRows;
                    limitSet = true;
                }
            }
            return nrItems;
        },
        [maxRows, value]
    );

    useEffect(() => {
        setLimit(getChipLimit(_.cloneDeep(elRefs)));
    }, [getChipLimit, elRefs]);

    return (
        <div className="MultiChipDisplay" onClick={onAddItemClick} data-testid={`${id}-multiChipDisplay`}>
            <div
                id={`${id}-wrapper`}
                className={clsx(classes.root, className, fullWidth ? classes.fullWidth : classes.maxWidth)}
            >
                {disabled && <div className={classes.disabledOverlay}></div>}

                <div
                    className={clsx(classes.wrapper, selectedItems?.length ? 'has-value' : '')}
                    data-testid={`${id}-wrapper`}
                    tabIndex={0}
                    role="listbox"
                >
                    {label && (
                        <label id={`${id}-wrapper-label`} className={clsx(classes.label)}>
                            {label}
                        </label>
                    )}
                    <div className={classes.selectedItemsWrapper}>
                        <div
                            id={`${id}-wrapper-no-selection-label`}
                            className={clsx(classes.chipWrapper, {
                                [classes.activeFilters]: selectedItems?.length,
                            })}
                            ref={wrapperRef}
                        >
                            {!displayedChips?.length ? (
                                <Typography variant="caption" className={clsx(classes.placeholder, 'u-ml-half')}>
                                    {noSelectionLabel}
                                </Typography>
                            ) : (
                                displayedChips?.map((chip, index) => (
                                    <span ref={elRefs[index]} key={`filter-chip-wrapper-${chip.id}`}>
                                        <FilterChip
                                            key={`filter-chip-${chip.id}`}
                                            id={chip.id}
                                            label={chip.name}
                                            handleRemove={(event) => handleChipDelete(event, chip)}
                                        />
                                    </span>
                                ))
                            )}
                            <ChipSize
                                className={classes.chipText}
                                length={selectedItems?.length}
                                limit={limit}
                                showMoreText={showMoreText}
                                handleClickOnMore={onAddItemClick}
                            />
                        </div>
                        <AddIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultipleChipDisplayField;
