import gql from 'graphql-tag';

export const ACCOUNT_CONFIGURATION_TAG = gql`
    query accountConfiguartion($siteType: String) {
        accountSettings {
            accountDataRetentionDays
            accountNumber
            accountName
            dealerName
            enablePersonnelControl
            edition
            hdStreamLimitValue
            isRemoteAccessEnabled
            inactiveCredentialExpirationDays
            isMfaEnabled
            isAllowMobileTransferEnabled
            enableMultifamilySupport
            emergencyCommValue
            facialIdValue
            maxAccountDataRetentionDays
            userGroupLimitValue
        }
        getPersonnelControl {
            checkpointDevices {
                id
                name
                siteName
            }
            excludedGroups {
                id
                name
            }
            personnelControlJobEnabled
        }
        getBMPLogo {
            imageBase64
            imageFilename
        }
        getSelfScreeningForAllSites {
            screenOption
            siteId
        }
        getTrustedNetwork(filters: { siteType: $siteType }) {
            id
            proximity {
                wifi {
                    ssid
                }
            }
            siteName
        }
        getCreateUserAsSuspended {
            initiallyDeactivated
        }
        getCompanyInfo {
            companyName
            street1
            street2
            city
            state
            country
            zip
            phoneNumber
            phoneExtension
            fax
            industry {
                id
                name
            }
        }
        getIndustriesList {
            id
            name
        }
    }
`;

export const ACCOUNT_CONFIGURATION_WITH_REMOTE_ACCESS_TAG = gql`
    query accountConfiguartionWithRemoteAccess {
        accountSettings {
            accountNumber
            accountName
            dealerName
            enablePersonnelControl
            edition
            hdStreamLimitValue
            isRemoteAccessEnabled
        }
        getPersonnelControl {
            checkpointDevices {
                id
                name
                siteName
            }
            excludedGroups {
                id
                name
            }
            personnelControlJobEnabled
        }
        getBMPLogo {
            imageBase64
            imageFilename
        }
        getSelfScreeningForAllSites {
            screenOption
            siteId
        }
        getTrustedNetwork {
            id
            proximity {
                wifi {
                    ssid
                }
            }
            siteName
        }
        getCreateUserAsSuspended {
            initiallyDeactivated
        }
    }
`;

export const ADD_PERSONNEL_CONTROL_CHECKPOINT_DEVICE_TAG = gql`
    mutation addPersonnelControlCheckpointDevice($checkpointDeviceId: Int!) {
        addPersonnelControlCheckpointDevice(checkpointDeviceId: $checkpointDeviceId)
    }
`;

export const DELETE_BMP_LOGO_TAG = gql`
    mutation deleteBMPLogo {
        deleteBMPLogo
    }
`;

export const GET_INCOMING_INVITATIONS = gql`
    query getIncomingInvitations {
        getIncomingInvitations {
            accountId
            accountName
            statusId
            statusName
        }
    }
`;

export const GET_OUTGOING_INVITATIONS = gql`
    query getOutgoingInvitations {
        getOutgoingInvitations {
            accountId
            accountName
            statusId
            statusName
        }
    }
`;

export const GET_HW_SCENARIOS = gql`
    query getHardwiredScenarios {
        getHardwiredScenarios {
            hardwiredLockdownConfigurationName
            hardwiredLockdownConfigurationName
        }
    }
`;

export const MIGRATE_HW_SCENARIO = gql`
    mutation migrateHwScenario {
        migrateHwScenario
    }
`;
