import { isEmpty, negate } from 'lodash';

export const DEFAULT_SITE_NAME = 'Imported Cameras';

export const isNotEmpty = negate(isEmpty);

// need to do this way with Promise handling since ApiHelper returns a promise
export const abortableCall = (route, param, signal) => {
    if (signal.aborted) {
        return console.error('call was aborted');
    }

    return new Promise((resolve, reject) => {
        const abortCallHandler = () => {
            console.error('call aborted');
        };

        const call = param ? route(param) : route();

        call.then((results) => resolve(results))
            .catch((error) => reject(error))
            .finally(() => {
                signal.removeEventListener('abort', abortCallHandler);
            });

        signal.addEventListener('abort', abortCallHandler);
    });
};

export const tableInitialState = {
    sortBy: [
        {
            asc: true,
            id: 'name',
        },
    ],
};
