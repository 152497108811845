import React from 'react';
import { Tooltip } from '@brivo/react-components';
import styles from './styles';
const CameraName = ({ cameraName, cameraId, imgSrc }) => {
    const classes = styles();
    return (
        <Tooltip title={cameraName} id={`preview-image-tooltip-${cameraId}`} size="small" placement={'top-end'}>
            <div
                className={
                    imgSrc
                        ? [classes.previewImageCameraName, classes.previewImageCameraNamePreview]
                        : [classes.previewImageCameraName, classes.previewImageCameraNameSansPreview]
                }
                data-testid={`preview-image-camera-name-${cameraId}`}
            >
                {cameraName}
            </div>
        </Tooltip>
    );
};
export default CameraName;
