import React from 'react';
import ViewPropertyButton from '../components/ViewPropertyButton/ViewPropertyButton';

export const ViewPropertyCell = (context, isDisabled, label, onViewClick) => {
    const {
        row: {
            original: { id },
        },
    } = context;

    const handleClick = () => {
        onViewClick(id);
    };

    return <ViewPropertyButton disabled={isDisabled} label={label} handleClick={handleClick} id={id} />;
};
