import { lazy, Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePermissions } from '@common/hooks/usePermissions';
import {
    DEVICES_VIEW_DEVICES,
    EVENT_TRACKER_CHART_PERM,
    EVENT_TRACKER_PERM,
    GLOBAL_VIEW_PERM,
    SNAPSHOT_LOG_VIEW,
    CREATE_FLOOR_PLANS,
    DELETE_FLOOR_PLANS,
    UPDATE_FLOOR_PLANS,
    VIEW_FLOOR_PLANS,
} from '@common/permissions/Permissions';
import NoAccess from '../../../common/components/NoAccess/NoAccess';
import MapMarker from './FloorPlanDndZone/MapMarker';
import { UserContext } from '../../../common/user/UserProvider';
import { checkEditionForFloorPlans } from './utils';

const FloorPlanView = lazy(() => import('./FloorPlanView'));
const FloorPlanCreate = lazy(() => import('./FloorPlanCreate'));
const FloorPlanEdit = lazy(() => import('./FloorPlanEdit'));

const FloorPlan = (props) => {
    const { site_id, id } = useParams();
    const siteId = parseInt(site_id, 10);
    const isCreateMode = id === 'create-floor-plan';
    let isView = new URLSearchParams(window.location.search).get('view') !== null;
    const { hasPermissions } = usePermissions();
    const canViewDevices = hasPermissions(DEVICES_VIEW_DEVICES);
    const canUpdateFloorPlans = hasPermissions(UPDATE_FLOOR_PLANS);
    let canDeleteFloorPlans = hasPermissions(DELETE_FLOOR_PLANS);
    const canCreateFloorPlans = hasPermissions(CREATE_FLOOR_PLANS);
    const canViewFloorPlans = hasPermissions(VIEW_FLOOR_PLANS);
    const canViewDeviceEvents =
        hasPermissions(EVENT_TRACKER_PERM) ||
        hasPermissions(GLOBAL_VIEW_PERM) ||
        hasPermissions(EVENT_TRACKER_CHART_PERM) ||
        hasPermissions(SNAPSHOT_LOG_VIEW);
    const [items, setItems] = useState([]);
    const [placed, setPlaced] = useState({ items: [] });
    const [isDarkThemeTouched, setIsDarkThemeTouched] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const persistedDarkTheme = localStorage.getItem(`floorplan_darktheme_${site_id}_${id}`)
        ? !!JSON.parse(localStorage.getItem(`floorplan_darktheme_${site_id}_${id}`))
        : false;
    const [darkTheme, setDarkTheme] = useState(persistedDarkTheme);
    const { userInfo } = useContext(UserContext);
    const isDemoOrEnterpriseEdition = checkEditionForFloorPlans(userInfo?.subscriptionLevel);
    const [fileFormatError, setFileFormatError] = useState(false);
    const [fileToDisplay, setFileToDisplay] = useState(null);
    const [fileObject, setFileObject] = useState(null);

    const handleFileObjectChange = useCallback(
        (file) => {
            const extension = file?.name?.split('.')?.pop()?.toLowerCase();
            if (extension === 'png' || extension === 'jpeg' || extension === 'jpg' || file === null) {
                setFileFormatError(false);
            } else {
                setFileFormatError(true);
            }
            setIsTouched(true);
            setFileObject(file);
            if (file) {
                setFileToDisplay(URL.createObjectURL(file));
            } else {
                setFileToDisplay(null);
            }
        },
        [setIsTouched]
    );

    const updateItemPlacement = useCallback((items, placed) => {
        setItems([...items]);
        setPlaced({ ...placed });
        setIsTouched(true);
    }, []);

    const onItemPlacement = useCallback(
        (itemId, deviceProperties) => {
            let placedIndex = placed.items.findIndex((elem) => elem.id === itemId);

            if (placedIndex === -1) {
                let currentItem = items.find((elem) => elem.id === itemId);
                placed.items.unshift({ ...currentItem, ...deviceProperties });
                let itemLoc = items.findIndex((elem) => elem.id === itemId);
                items.splice(itemLoc, 1);
            } else {
                placed.items[placedIndex] = { ...placed.items[placedIndex], ...deviceProperties };
            }

            updateItemPlacement(items, placed);
        },
        [placed, updateItemPlacement, items]
    );

    const onItemRemove = useCallback(
        (item) => {
            let placedIndex = placed.items.findIndex((elem) => elem.id === item.id);

            if (placedIndex !== -1) {
                placed.items.splice(
                    placed.items.findIndex((elem) => elem.id === item.id),
                    1
                );
                delete item.top;
                delete item.left;
                items.unshift(item);

                updateItemPlacement(items, placed);
            }
        },
        [items, placed, updateItemPlacement]
    );

    const markers = useMemo(() => {
        return placed?.items?.map((item) => {
            const device = { ...item };
            const deviceDetails = {
                deviceTop: device?.top,
                deviceLeft: device?.left,
                deviceRotation: device?.rotation,
                deviceName: device?.name,
                deviceType: device?.deviceType,
                deviceId: device?.id,
            };

            return canViewDevices ? (
                <MapMarker
                    {...deviceDetails}
                    draggable={canUpdateFloorPlans || (canCreateFloorPlans && isCreateMode)}
                    onPlacementUpdate={onItemPlacement}
                    key={`marker_${deviceDetails.deviceId}_${deviceDetails.deviceTop}_${deviceDetails.deviceLeft}`}
                    darkTheme={darkTheme}
                />
            ) : null;
        });
    }, [
        placed.items,
        canViewDevices,
        onItemPlacement,
        darkTheme,
        canUpdateFloorPlans,
        canCreateFloorPlans,
        isCreateMode,
    ]);

    if (!isDemoOrEnterpriseEdition) {
        return <NoAccess />;
    }
    const handleDarkThemeToggle = (setSubmitting) => {
        const persistedDarkTheme = localStorage.getItem(`floorplan_darktheme_${site_id}_${id}`)
            ? !!JSON.parse(localStorage.getItem(`floorplan_darktheme_${site_id}_${id}`))
            : false;
        setDarkTheme(!darkTheme);
        setSubmitting(true);

        if (!darkTheme !== persistedDarkTheme) {
            setIsDarkThemeTouched(true);
            setSubmitting(false);
        } else {
            setIsDarkThemeTouched(false);
            setSubmitting(false);
        }
    };

    return (
        <Suspense fallback={null}>
            {isCreateMode ? (
                canCreateFloorPlans ? (
                    <FloorPlanCreate
                        {...props}
                        siteId={site_id}
                        canViewDevices={canViewDevices}
                        darkTheme={darkTheme}
                        setDarkTheme={setDarkTheme}
                        placed={placed}
                        setPlaced={setPlaced}
                        setItems={setItems}
                        isTouched={isTouched}
                        setIsTouched={setIsTouched}
                        onItemPlacement={onItemPlacement}
                        onItemRemove={onItemRemove}
                        markers={markers}
                        handleDarkThemeToggle={handleDarkThemeToggle}
                        handleFileObjectChange={handleFileObjectChange}
                        fileFormatError={fileFormatError}
                        setFileFormatError={setFileFormatError}
                        fileToDisplay={fileToDisplay}
                        fileObject={fileObject}
                    />
                ) : (
                    <NoAccess />
                )
            ) : isView ? (
                canViewFloorPlans ? (
                    <FloorPlanView
                        {...props}
                        floorPlanId={id}
                        siteId={siteId}
                        canViewDevices={canViewDevices}
                        canViewDeviceEvents={canViewDeviceEvents}
                        darkTheme={darkTheme}
                        setDarkTheme={setDarkTheme}
                        isView={isView}
                    />
                ) : (
                    <NoAccess />
                )
            ) : canUpdateFloorPlans || canDeleteFloorPlans ? (
                <FloorPlanEdit
                    {...props}
                    floorPlanId={id}
                    siteId={site_id}
                    canViewDevices={canViewDevices}
                    darkTheme={darkTheme}
                    setDarkTheme={setDarkTheme}
                    placed={placed}
                    setPlaced={setPlaced}
                    setItems={setItems}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                    isDarkThemeTouched={isDarkThemeTouched}
                    setIsDarkThemeTouched={setIsDarkThemeTouched}
                    onItemPlacement={onItemPlacement}
                    onItemRemove={onItemRemove}
                    markers={markers}
                    handleDarkThemeToggle={handleDarkThemeToggle}
                    handleFileObjectChange={handleFileObjectChange}
                    fileFormatError={fileFormatError}
                    setFileFormatError={setFileFormatError}
                    fileToDisplay={fileToDisplay}
                    setFileToDisplay={setFileToDisplay}
                    fileObject={fileObject}
                />
            ) : (
                <NoAccess />
            )}
        </Suspense>
    );
};

export default FloorPlan;
