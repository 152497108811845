import React from 'react';
import { Dialog, DialogContent, Typography, Button, MuiCloseIcon } from '@brivo/react-components';

import styles from './styles';

const BrowserNotificationsDialog = ({ open, onClose, requestNotificationsPermission, t }) => {
    const classes = styles();

    return (
        <Dialog classes={{ paper: classes.dialog }} open={open} onClose={onClose}>
            <DialogContent className={classes.content}>
                <section className={classes.titleSection}>
                    <Typography className={classes.titleText}>{t('Browser.Notifications.Dialog.title')}</Typography>
                    <Button
                        id="browser-notif-hide-btn"
                        type="tertiary"
                        startIcon={<MuiCloseIcon />}
                        onClick={onClose}
                        text={t('Browser.Notifications.Dialog.hide-btn')}
                    />
                </section>
                <Typography className={classes.text}>{t('Browser.Notifications.Dialog.text')}</Typography>
                <Button
                    id="browser-notif-enable-btn"
                    type="primary"
                    size="small"
                    onClick={requestNotificationsPermission}
                    text={t('Browser.Notifications.Dialog.enable-btn')}
                />
            </DialogContent>
        </Dialog>
    );
};

export default BrowserNotificationsDialog;
