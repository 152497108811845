import React, { Suspense, lazy } from 'react';

const VisitorPage = lazy(() => import('./VisitorPage'));

const VisitorContainer = (props) => (
    <Suspense fallback={null}>
        <VisitorPage {...props} />
    </Suspense>
);

export default VisitorContainer;
