// Granular permissions
export const GLOBAL_VIEW_PERM = 'GLOBAL_VIEW';
export const EVENT_TRACKER_PERM = 'EVT_TRACKER_TABLE';
export const EVENT_TRACKER_CHART_PERM = 'EVT_TRACKER_GRAPH';
export const JOURNAL_PERM = 'JOURNAL';
export const REPORTS_PERM = 'REPORTS';
export const DATA_EXPLORER_PERM = 'DATA_EXPLORER';
export const ONAIR_CLASSIC_PERM = 'ONAIR_CLASSIC';
export const DATA_RETENTION_MODIFY_SETTINGS = 'DATA_RETENTION_MODIFY_SETTINGS';

export const ADMIN_VIEW_ADMINISTRATORS = 'ADMIN_VIEW_ADMINISTRATORS';
export const ADMIN_CREATE_ADMIN = 'ADMIN_CREATE_ADMIN';
export const ADMIN_EDIT_ADMIN_PROFILES = 'ADMIN_EDIT_ADMIN_PROFILES';
export const ADMIN_EDIT_ADMIN_ROLES = 'ADMIN_EDIT_ADMIN_ROLES';
export const ADMIN_EDIT_ADMIN_ASSIGNMENTS = 'ADMIN_EDIT_ADMIN_ASSIGNMENTS';
export const ADMIN_DELETE_ADMIN = 'ADMIN_DELETE_ADMIN';

export const ROLE_VIEW_ROLES = 'ROLE_VIEW_ROLES';
export const ROLE_CREATE_ROLE = 'ROLE_CREATE_ROLE';
export const ROLE_EDIT_ROLE = 'ROLE_EDIT_ROLE';
export const ROLE_DELETE_ROLE = 'ROLE_DELETE_ROLE';

export const CREDENTIAL_VIEW_CREDENTIALS = 'CREDENTIAL_VIEW_CREDENTIALS';
export const CREDENTIAL_ADD_CREDENTIALS = 'CREDENTIAL_ADD_CREDENTIALS';
export const CREDENTIAL_DELETE_CREDENTIALS = 'CREDENTIAL_DELETE_CREDENTIALS';
export const CREDENTIAL_VIEW_UNKNOWN_CARDS = 'CREDENTIAL_VIEW_UNKNOWN_CARDS';
export const DEVICES_VIEW_DEVICES = 'DEVICES_VIEW_DEVICES';
export const DEVICES_UNLOCK_DOORS = 'DEVICES_UNLOCK_DOORS';
export const DEVICES_OVERRIDE_DOORS = 'DEVICES_OVERRIDE_DOORS';
export const DEVICES_SET_SCHEDULE = 'DEVICES_SET_SCHEDULE';
export const SET_INTRUSION = 'SET_INTRUSION';

export const GROUPS_VIEW_GROUPS = 'GROUPS_VIEW_GROUPS';
export const GROUPS_CREATE_GROUP = 'GROUPS_CREATE_GROUP';
export const GROUPS_EDIT_GROUP_DETAILS = 'GROUPS_EDIT_GROUP_DETAILS';
export const GROUPS_EDIT_GROUP_PERMISSIONS = 'GROUPS_EDIT_GROUP_PERMISSIONS';
export const GROUPS_DELETE_GROUP = 'GROUPS_DELETE_GROUP';

export const USER_VIEW_USERS = 'USER_VIEW_USERS';
export const USER_CREATE_USER = 'USER_CREATE_USER';
export const USER_EDIT_USER_PROFILES = 'USER_EDIT_USER_PROFILES';
export const USER_ASSIGN_CREDENTIALS = 'USER_ASSIGN_USER_CREDENTIALS';
export const USER_EDIT_USER_GROUPS = 'USER_EDIT_USER_GROUPS';
export const USER_DELETE_USER = 'USER_DELETE_USER';

export const VIDEO_VIEW_LIVE_VIDEO_CUSTOM = 'VIDEO_VIEW_LIVE_VIDEO_CUSTOM';
export const VIDEO_VIEW_LIVE_VIDEO_ALL = 'VIDEO_VIEW_LIVE_VIDEO_ALL';
export const VIDEO_CREATE_CUSTOM_LAYOUT = 'VIDEO_CREATE_CUSTOM_LAYOUT';
export const VIDEO_EDIT_CUSTOM_LAYOUT = 'VIDEO_EDIT_CUSTOM_LAYOUT';
export const VIDEO_DELETE_CUSTOM_LAYOUT = 'VIDEO_DELETE_CUSTOM_LAYOUT';
export const VIDEO_VIEW_RECORDED_VIDEO = 'VIDEO_VIEW_RECORDED_VIDEO';

export const BADGING_CREATE_TEMPLATE = 'BADGING_CREATE_TEMPLATE';
export const BADGING_DELETE_TEMPLATE = 'BADGING_DELETE_TEMPLATE';
export const BADGING_EDIT_TEMPLATE = 'BADGING_EDIT_TEMPLATE';
export const BADGING_VIEW_TEMPLATE = 'BADGING_VIEW_TEMPLATE';
export const BADGING_PRINT = 'BADGING_PRINT';

export const NOTIFICATIONS_CREATE = 'NOTIFICATIONS_CREATE';
export const NOTIFICATIONS_DELETE = 'NOTIFICATIONS_DELETE';
export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
export const NOTIFICATIONS_VIEW = 'NOTIFICATIONS_VIEW';

export const MANAGE_ACCOUNT_SETTINGS_VIEW = 'MANAGE_ACCOUNT_SETTINGS_VIEW';
export const MANAGE_ACCOUNT_SETTINGS_UPDATE = 'MANAGE_ACCOUNT_SETTINGS_UPDATE';
export const MANAGE_APPLICATIONS = 'MANAGE_APPLICATIONS';

export const COMMANDS_VIEW_COMMANDS = 'VIEW_COMMANDS';
export const COMMANDS_CREATE_COMMANDS = 'CREATE_COMMANDS';
export const COMMANDS_UPDATE_COMMANDS = 'UPDATE_COMMANDS';
export const COMMANDS_DELETE_COMMANDS = 'DELETE_COMMANDS';

export const EVT_CLASSIFICATION_VIEW = 'EVT_CLASSIFICATION_VIEW';
export const EVT_CLASSIFICATION_EDIT = 'EVT_CLASSIFICATION_EDIT';

export const SNAPSHOT_LOG_VIEW = 'SNAPSHOT_LOG_VIEW';
export const OCCUPANCY_TRACKER_VIEW = 'OCCUPANCY_TRACKER_VIEW';
export const OCCUPANCY_TRACKER_CONFIGURE = 'OCCUPANCY_TRACKER_CONFIGURE';

export const CONFIG_SCHEDULES = 'CONFIG_SCHEDULES';
export const CONFIG_HOLIDAYS = 'CONFIG_HOLIDAYS';
export const CONFIG_NOTIFICATIONS = 'CONFIG_NOTIFICATIONS';
export const CONFIG_CUSTOM_FIELDS = 'CONFIG_CUSTOM_FIELDS';

export const INSTALLATION = 'INSTALLATION';

export const SCHEDULES_READ_SCHEDULES = 'SCHEDULES_READ_SCHEDULES';
export const SCHEDULES_WRITE_SCHEDULES = 'SCHEDULES_WRITE_SCHEDULES';

export const EVT_TRACKER_TABLE = 'EVT_TRACKER_TABLE';

// Shared access permissions
export const LINK_ACCOUNTS = 'LINK_ACCOUNTS';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const LINK_GROUP = 'LINK_GROUP';

export const LOCKDOWN_CREATE_LOCKDOWN_SCENARIO = 'LOCKDOWN_CREATE_LOCKDOWN_SCENARIO';
export const LOCKDOWN_EDIT_LOCKDOWN_SCENARIO = 'LOCKDOWN_EDIT_LOCKDOWN_SCENARIO';
export const LOCKDOWN_DELETE_LOCKDOWN_SCENARIO = 'LOCKDOWN_DELETE_LOCKDOWN_SCENARIO';
export const LOCKDOWN_INITIATE_LOCKDOWN = 'LOCKDOWN_INITIATE_LOCKDOWN';
export const LOCKDOWN_CLEAR_LOCKDOWN = 'LOCKDOWN_CLEAR_LOCKDOWN';
export const ONAIR_GROUP_BASED_LOCKDOWN = 'ONAIR_GROUP_BASED_LOCKDOWN';
export const BRIVO_WORKSPACE = 'BRIVO_WORKSPACE';

//Floor Plans permissions
export const CREATE_FLOOR_PLANS = 'CREATE_FLOOR_PLANS';
export const VIEW_FLOOR_PLANS = 'VIEW_FLOOR_PLANS';
export const UPDATE_FLOOR_PLANS = 'UPDATE_FLOOR_PLANS';
export const DELETE_FLOOR_PLANS = 'DELETE_FLOOR_PLANS';

//Vistor Experience permissions
export const CREATE_VISITOR = 'CREATE_VISITOR';
export const VIEW_VISITOR = 'VIEW_VISITOR';
export const UPDATE_VISITOR = 'UPDATE_VISITOR';
export const DELETE_VISITOR = 'DELETE_VISITOR';

//Call Elevator permissions
export const CALL_ELEVATOR = 'CALL_ELEVATOR';
