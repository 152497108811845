import { Suspense, useEffect } from 'react';
import { useAssignments } from '../../../common/hooks/useAssignments';
import { Typography, Button, LoadingIndicator, Grid } from '@brivo/react-components';
import { useHistory, useLocation } from 'react-router';
import { APP_SITE_SETTINGS_URL } from '../../../common/constants/Constants';
import useFloorPlansNavigation from '../FloorPlanContainer/hooks/useFloorPlansNavigation';
import { useStyles } from './styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const FloorPlanNavigation = () => {
    const { hasSiteAssignments } = useAssignments();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const { t } = useTranslation();

    const { isFloorPlanLoading, handleFloorPlanClick, isError, shouldPreventFloorPlanRedirect } =
        useFloorPlansNavigation(history);

    useEffect(() => {
        if (location?.state?.from !== 'redirect' && shouldPreventFloorPlanRedirect) {
            handleFloorPlanClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.from, shouldPreventFloorPlanRedirect]);

    const getFloorPlanExceptionMessage = () => {
        if (!isFloorPlanLoading && location?.state) {
            if (!hasSiteAssignments) {
                return (
                    <>
                        <Grid item>
                            <Typography className={clsx(classes.title, classes.text)}>
                                {t('Page.floor-plans.exception.no-site-permission.title.message')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={clsx(classes.subtitle, classes.text)}>
                                {t('Page.floor-plans.exception.no-site-permission.subtitle.message')}
                            </Typography>
                        </Grid>
                    </>
                );
            }

            return (
                <>
                    <Grid item>
                        <Typography className={clsx(classes.title, classes.text)}>
                            {t('Page.floor-plans.exception.no-floor-plan-created.title.message')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={clsx(classes.subtitle, classes.text)}>
                            {t('Page.floor-plans.exception.no-floor-plan-created.subtitle.message')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            type="primary"
                            onClick={() => history.push(APP_SITE_SETTINGS_URL)}
                            text={t('Page.floor-plans.exception.no-floor-plan-created.text.button')}
                        />
                    </Grid>
                </>
            );
        } else if (isFloorPlanLoading && !location?.state) {
            return <LoadingIndicator padding="0px" color="inherit" />;
        }
        if (isError) {
            return (
                <>
                    <Grid item>
                        <Typography className={clsx(classes.title, classes.text)}>
                            {t('Page.floor-plans.exception.error.title.message')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={clsx(classes.subtitle, classes.text)}>
                            {t('Page.floor-plans.exception.error.subtitle.message')}
                        </Typography>
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid item>
                        <Typography className={clsx(classes.title, classes.text)}>
                            {t('Page.floor-plans.exception.new-tab-opened.title.message')}
                        </Typography>
                    </Grid>
                </>
            );
        }
    };

    return (
        <Suspense fallback={null}>
            <div className={`${classes.container}`}>
                <Grid
                    container
                    className={classes.wrapper}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    {getFloorPlanExceptionMessage()}
                </Grid>
            </div>
        </Suspense>
    );
};

export default FloorPlanNavigation;
