import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';

import { checkPermissions } from '@brivo/onairplus-services';
import { Snackbar } from '@brivo/react-components';

import { UserContext } from '../../../common/user/UserProvider';
import {
    COMMANDS_CREATE_COMMANDS,
    COMMANDS_UPDATE_COMMANDS,
    COMMANDS_VIEW_COMMANDS,
    DEVICES_VIEW_DEVICES,
    GROUPS_VIEW_GROUPS,
} from '../../../common/permissions/Permissions';
import { APP_COMMAND_URL, APP_COMMAND_CREATE_URL } from '../../../common/constants/Constants';
import { mapSchedules } from '@common/utils/Utils';

import { cancellablePromise } from '../../../common/utils/promiseUtils';
import useReaderCommands from '../hooks/useReaderCommands';
import { FIELD_ID_TO_NAME_MAP, FIELD_NAME, INITIAL_VALUES } from '../utils/commandsConstants';
import {
    buildReaderCommand,
    filterAccessPointsByTypeAndPanelId,
    filterAndMapOutputs,
    filterSchedules,
    getControlPanelId,
    mapBoards,
    filterAndMapPanels,
    validateReaderCommand,
    processData,
    byNamePropertyAlphabetically,
} from '../utils/commandsUtils';
import { PROMISE_CANCELLED } from '../../../common/constants/Constants';

import { sitesApi } from '../../../common/webApis/sites/sitesApi';
import { schedulesApi } from '../../../common/webApis/schedules/schedulesApi';
import { readerCommandsApi } from '../../../common/webApis/readerCommands/readerCommandsApi';
import { devicesApi } from '../../../common/webApis/devices/devicesApi';
import useGenericFilterGroup from '../../../common/hooks/useGenericFilterGroup';

const DEFAULT_COMMANDS_FILTER_VALUES = {
    name: '',
    site: [],
};

export const CommandsContext = React.createContext();

const ContextProvider = ({ children, setInitialValues }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const notifications = Snackbar();

    const { setFieldValue, isSubmitting, dirty, resetForm, values, handleChange, setTouched } = useFormikContext();
    const { panel } = values;

    const {
        filterValues,
        filterChangeMethods,
        nrFiltersChanged,
        clearAll: clearFilters,
        clearingValues,
        setClearingValues,
    } = useGenericFilterGroup(DEFAULT_COMMANDS_FILTER_VALUES);

    const {
        data,
        totalCount,
        loadNextPage,
        loading: isCommandsLoading,
        reloadData,
        deleteCommand,
        getCommand,
    } = useReaderCommands(filterValues, nrFiltersChanged);

    const commandId = useRef(null);
    const cancelFunctions = useRef({});
    const isOnCreate = history.location.pathname === APP_COMMAND_CREATE_URL;
    const isOnListing = history.location.pathname === APP_COMMAND_URL;
    const isOnViewOrEdit = !isOnCreate && !isOnListing;

    const { permissions } = useContext(UserContext);

    const hasCreateReaderCommandsPermission = checkPermissions({
        userPermissions: permissions,
        necessaryPermissions: [COMMANDS_CREATE_COMMANDS, GROUPS_VIEW_GROUPS, DEVICES_VIEW_DEVICES],
    });
    const hasViewReaderCommandsPermission = checkPermissions({
        userPermissions: permissions,
        necessaryPermissions: [COMMANDS_VIEW_COMMANDS, GROUPS_VIEW_GROUPS, DEVICES_VIEW_DEVICES],
    });
    const hasEditReaderCommandsPermission = checkPermissions({
        userPermissions: permissions,
        necessaryPermissions: [COMMANDS_UPDATE_COMMANDS, GROUPS_VIEW_GROUPS, DEVICES_VIEW_DEVICES],
    });

    if (isOnCreate && !hasCreateReaderCommandsPermission && hasViewReaderCommandsPermission) {
        history.push(APP_COMMAND_URL);
    }

    const [sites, setSites] = useState([]);
    const [isSitesLoading, setIsSitesLoading] = useState(false);
    const [isAcsPointsLoading, setIsAcsPointsLoading] = useState(false);
    const [isPanelsLoading, setIsPanelsLoading] = useState(false);
    const [isPanelConfigLoading, setIsPanelConfigLoading] = useState(false);
    const [isSchedulesLoading, setIsSchedulesLoading] = useState(false);
    const [boards, setBoards] = useState([]);
    const [outputs, setOutputs] = useState([]);
    const [doors, setDoors] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [switches, setSwitches] = useState([]);
    const [saveStatus, setSaveStatus] = useState(null);
    const [errorMessages, setErrorMessages] = useState([]);
    const [panels, setPanels] = useState([]);
    const [accessPoints, setAccessPoints] = useState([]);
    const [isLoadingCommand, setIsLoadingCommand] = useState(false);
    const [isFaultyCommandId, setIsFaultyCommandId] = useState(false);
    const [confirmationDialogData, setConfirmationDialogData] = useState({
        open: false,
    });
    const [allowReconfig, setAllowReconfig] = useState(false);

    const getSites = useCallback(async () => {
        setIsSitesLoading(true);
        sitesApi
            .getSites({ includeGeocode: false })
            .then((res) => {
                setSites(res.getSites);
                setIsSitesLoading(false);
            })
            .catch((e) => console.error(e));
    }, []);

    const handleSubmit = async () => {
        try {
            setTouched({
                commandName: true,
                commandDescription: true,
                scheduleId: true,
                trigger: true,
                swipeTimeout: true,
                site: true,
                siteSearch: true,
                panel: true,
                board: true,
                accessPoints: true,
                relay: true,
                input: true,
                inputState: true,
                behavior: true,
                delay: true,
                groups: true,
                boardTypeId: true,
            });
            setSaveStatus({ showValidationMessage: false });
            const controlPanelId = getControlPanelId(panel, panels);
            const { readerCommandData } = buildReaderCommand(values, controlPanelId);
            const siteId = values[FIELD_NAME.site];
            const panelId = values[FIELD_NAME.panel];
            const { validationMessages } = validateReaderCommand(readerCommandData, siteId, panelId, t);

            if (validationMessages.length > 0) {
                setSaveStatus({ showValidationMessage: true });
                setErrorMessages(validationMessages);
                return;
            }

            let result;
            if (isOnCreate) {
                result = await readerCommandsApi.addNewReaderCommand(readerCommandData);
                if (!result) throw new Error('Create failed.');

                if (hasEditReaderCommandsPermission) {
                    navigateToCommand(result.id);
                } else {
                    history.push(APP_COMMAND_URL);
                }

                notifications.addSuccessMessage({ text: t('Component.Save.save-success') });
                reloadData();
            } else {
                // implictly on edit
                result = await readerCommandsApi.updateReaderCommand(commandId.current, readerCommandData);
                if (!result) throw new Error('Update failed.');

                setInitialValues(values);
                notifications.addSuccessMessage({ text: t('Component.Save.save-success') });
                reloadData();
            }

            if (!result) {
                setSaveStatus({ showError: true });
                resetForm();
            }
        } catch (error) {
            setSaveStatus({ showError: true });
            console.error('Error on submit', error);
        }
    };

    const fetchSchedules = useCallback(async () => {
        setIsSchedulesLoading(true);
        return schedulesApi
            .getAllSchedules()
            .then((schedulesRes) => {
                if (!schedulesRes) {
                    setSchedules(null);
                } else {
                    const filteredSchedules = filterSchedules(schedulesRes);

                    const schedules = mapSchedules(filteredSchedules, t).sort(byNamePropertyAlphabetically);

                    setSchedules(schedules);
                }
                setIsSchedulesLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setSaveStatus({ loadMessage: true });
                setErrorMessages([t('Page.commands.schedules-api-failed')]);
                setSchedules(null);
                setIsSchedulesLoading(false);
            });
    }, [t]);

    const fetchDevicesAndPanels = useCallback(
        (id) => {
            return Promise.all([devicesApi.fetchDevices(id), devicesApi.fetchPanelsForSite([id])])
                .then(processData)
                .catch((e) => {
                    if (e.message === PROMISE_CANCELLED) {
                        return;
                    }
                    console.error(e);
                    setSaveStatus({ loadMessage: true });
                    setErrorMessages([t('Page.commands.panels-accessPoints-api-failed')]);
                });
        },
        [t]
    );

    const viewCommand = useCallback(
        async (id) => {
            try {
                // state can be dirty from previous loads / inputs
                setInitialValues(INITIAL_VALUES);
                setSaveStatus(null);
                setErrorMessages([]);
                setIsFaultyCommandId(false);
                setAllowReconfig(false);

                commandId.current = id;
                setIsLoadingCommand(true);

                const commandValues = cloneDeep(INITIAL_VALUES);

                const command = await getCommand(id);
                if (!command) {
                    setIsLoadingCommand(false);
                    setIsFaultyCommandId(true);
                    return;
                }
                commandValues[FIELD_NAME.commandName] = command.name;
                commandValues[FIELD_NAME.commandDescription] = command.description;
                commandValues[FIELD_NAME.schedule] = command.scheduleId;
                commandValues[FIELD_NAME.trigger] = command.numberOfSwipes;
                commandValues[FIELD_NAME.swipeTimeout] = command.swipeDelay;
                commandValues[FIELD_NAME.groups] = command.readerCommandGroups;

                await fetchSchedules();

                let siteId;
                let panel;

                const hasDoors = command.readerCommandAccessPoints.length > 0;
                const hasPanels = command.readerCommandOutputs.length > 0;

                if (!hasDoors || !hasPanels) {
                    setAllowReconfig(true);
                    setInitialValues(commandValues);
                    setIsLoadingCommand(false);
                    return;
                }

                const firstAccessPointOid = command.readerCommandAccessPoints[0].id;

                const doorData = await devicesApi.getDeviceSiteId(firstAccessPointOid);
                siteId = doorData.getAccessPointStatus.siteId;

                const { accessPoints, panels } = await fetchDevicesAndPanels(siteId);
                setAccessPoints(accessPoints);

                const mappedPanels = filterAndMapPanels(panels);
                setPanels(mappedPanels);

                const controlPanelId = command.readerCommandOutputs[0].panelObjectId;
                panel = mappedPanels.find((p) => p.controlPanelId === controlPanelId)?.id;

                const { doors, switches } = filterAccessPointsByTypeAndPanelId(accessPoints, controlPanelId);

                if (!switches.find((s) => s.id === command.inputDeviceObjectId)) {
                    setAllowReconfig(true);
                    setInitialValues(commandValues);
                    setIsLoadingCommand(false);
                    return;
                }

                setDoors(doors);
                setSwitches(switches);

                const boardsResponse = await devicesApi.fetchPanelConfig(panel);
                const mappedBoards = mapBoards(boardsResponse.getConfigByPanelId);
                setBoards(mappedBoards);

                const outputs = filterAndMapOutputs(command.readerCommandOutputs[0].boardNumber, mappedBoards);
                setOutputs(outputs);

                commandValues[FIELD_NAME.site] = siteId;
                commandValues[FIELD_NAME.panel] = panel;
                commandValues[FIELD_NAME.board] = command.readerCommandOutputs[0].boardNumber;
                commandValues[FIELD_NAME.boardTypeId] = command.readerCommandOutputs[0].boardTypeId;
                commandValues[FIELD_NAME.accessPoints] = command.readerCommandAccessPoints.map((ap) => ap.id);
                commandValues[FIELD_NAME.relay] = command.readerCommandOutputs[0].pointAddressId;
                commandValues[FIELD_NAME.input] = command.inputDeviceObjectId;
                commandValues[FIELD_NAME.inputState] = command.inputDeviceState;
                commandValues[FIELD_NAME.behavior] = command.readerCommandOutputs[0].behavior;
                commandValues[FIELD_NAME.delay] = command.outputDelay;

                setInitialValues(commandValues);
                setIsLoadingCommand(false);
            } catch (error) {
                console.error(error);
                setIsLoadingCommand(false);
                setIsFaultyCommandId(true);
            }
        },
        [fetchDevicesAndPanels, fetchSchedules, getCommand, setInitialValues]
    );

    const closeConfirmationDialog = () => {
        setConfirmationDialogData({ open: false });
    };

    const deleteCommandById = async (id) => {
        setConfirmationDialogData({
            ...confirmationDialogData,
            primaryDisabled: true,
        });

        try {
            await deleteCommand(id);

            history.push(APP_COMMAND_URL);
            notifications.addSuccessMessage({
                text: t('Page.commands.details.dialog.delete.notification.success', {
                    name: values[FIELD_NAME.commandName],
                }),
            });
        } catch (error) {
            notifications.addErrorMessage({
                text: t('Page.commands.details.dialog.delete.notification.fail', {
                    name: values[FIELD_NAME.commandName],
                }),
            });
            closeConfirmationDialog();
        }
    };

    const handleDelete = () => {
        setConfirmationDialogData({
            open: true,
            text: (
                <span>
                    {t('Page.commands.details.dialog.delete.message', {
                        name: values[FIELD_NAME.commandName],
                    })}
                </span>
            ),
            handleYes: () => deleteCommandById(commandId.current),
            delete: true,
            primaryDisabled: false,
        });
    };

    useEffect(() => {
        getSites({ includeGeocode: false });
    }, [getSites]);

    const navigateToCreateCommand = () => {
        history.push(APP_COMMAND_CREATE_URL);
        setInitialValues(cloneDeep(INITIAL_VALUES));
        resetForm();
        setSaveStatus(null);
        setErrorMessages([]);

        fetchSchedules();
    };

    const updateDoorsAndSwitchesStatesByControlPanelId = useCallback(
        (controlPanelId) => {
            const { doors, switches } = filterAccessPointsByTypeAndPanelId(accessPoints, controlPanelId);
            setDoors(doors);
            setSwitches(switches);
        },
        [accessPoints]
    );

    const updatePanelsAndAccessPointsStatesBySite = useCallback(
        (site) => {
            setIsAcsPointsLoading(true);
            setIsPanelsLoading(true);

            if (cancelFunctions.current.accessPointsAndPanels) {
                cancelFunctions.current.accessPointsAndPanels();
            }

            const { promise: fetchData, cancel: cancelFetch } = cancellablePromise(fetchDevicesAndPanels(site));

            fetchData
                .then(({ accessPoints, panels }) => {
                    const validPanels = filterAndMapPanels(panels);

                    setAccessPoints(accessPoints);
                    setPanels(validPanels);

                    setIsAcsPointsLoading(false);
                    setIsPanelsLoading(false);
                })
                .catch((e) => {
                    if (e.message === PROMISE_CANCELLED) {
                        return;
                    }

                    setIsAcsPointsLoading(false);
                    setIsPanelsLoading(false);
                });

            cancelFunctions.current.accessPointsAndPanels = cancelFetch;
        },
        [fetchDevicesAndPanels]
    );

    const updateBoardsStateByPanelId = useCallback(
        (id) => {
            setIsPanelConfigLoading(true);

            if (cancelFunctions.current.panelConfig) {
                cancelFunctions.current.panelConfig();
            }

            const { promise: fetchData, cancel: cancelFetch } = cancellablePromise(devicesApi.fetchPanelConfig(id));

            fetchData
                .then((res) => {
                    const mappedBoards = mapBoards(res.getConfigByPanelId);

                    setSaveStatus(null);
                    setErrorMessages([]);
                    setBoards(mappedBoards);
                    setIsPanelConfigLoading(false);
                })
                .catch((e) => {
                    if (e.message === PROMISE_CANCELLED) {
                        return;
                    }
                    console.error(e);
                    setSaveStatus({ loadMessage: true });
                    setErrorMessages([t('Page.commands.panelConfig-api-failed')]);
                    setIsPanelConfigLoading(false);
                });

            cancelFunctions.current.panelConfig = cancelFetch;
        },
        [t]
    );

    useEffect(() => {
        if (isOnCreate) fetchSchedules();
    }, [fetchSchedules, isOnCreate]);

    const updateOutputsByBoard = useCallback(
        (board) => {
            const outputPoints = filterAndMapOutputs(board, boards);

            setOutputs(outputPoints);
        },
        [boards]
    );

    const handleSingleSelectChange = (fieldName) => (e, field) => {
        const val = field.props.value;

        if (fieldName === FIELD_NAME.trigger) {
            if (val === 1) setFieldValue(FIELD_NAME.swipeTimeout, 0);
        }

        if (fieldName === FIELD_NAME.site) {
            updatePanelsAndAccessPointsStatesBySite(val);
        }

        if (fieldName === FIELD_NAME.panel) {
            updateBoardsStateByPanelId(val);

            const controlPanelId = getControlPanelId(val, panels);
            if (!controlPanelId || !accessPoints.length) return;

            updateDoorsAndSwitchesStatesByControlPanelId(controlPanelId);
        }

        if (fieldName === FIELD_NAME.board) {
            const board = boards.find((board) => board.id === val);
            if (board) {
                setFieldValue(FIELD_NAME.boardTypeId, board.boardTypeId);
            }
            updateOutputsByBoard(val);
        }

        setFieldValue(fieldName, val);
        resetConditionalFields(fieldName);
    };

    const handleMultipleSelectChange = (event) => {
        const {
            target: { value },
        } = event;
        setFieldValue(FIELD_NAME.accessPoints, value);
    };

    const handleInputChange = (event) => {
        event.persist();
        if (parseInt(event.target.value) >= 0 || event.target.value === '') {
            setFieldValue(event.target.id, event.target.value);
        }
    };

    const resetConditionalFields = (fieldName) => {
        switch (fieldName) {
            case FIELD_NAME.site:
                resetFieldsById([6, 7, 8, 9, 10]);
                break;
            case FIELD_NAME.panel:
                resetFieldsById([7, 8, 9, 10]);
                break;
            case FIELD_NAME.board:
                resetFieldsById([9]);
                break;
            default:
                break;
        }
    };

    const resetFieldsById = (ids = []) => {
        ids.forEach((id) => {
            const fieldName = FIELD_ID_TO_NAME_MAP[id];
            const value = cloneDeep(INITIAL_VALUES[fieldName]);

            setFieldValue(fieldName, value);
        });
    };

    const navigateToCommand = (id) => {
        history.push(`${APP_COMMAND_URL}/${id}`);
    };

    const contextValues = {
        allowReconfig,
        boards,
        closeConfirmationDialog,
        confirmationDialogData,
        totalCount,
        data,
        dirty,
        doors,
        errorMessages,
        filter: {
            filterChangeMethods,
            filterValues,
            clearFilters,
            nrFiltersChanged,
            clearingValues,
            setClearingValues,
        },
        handleDelete,
        handleInputChange,
        handleMultipleSelectChange,
        handleSingleSelectChange,
        handleSubmit,
        hasEditReaderCommandsPermission,
        history,
        isAcsPointsLoading,
        isCommandsLoading,
        isFaultyCommandId,
        isLoadingCommand,
        isOnCreate,
        isOnListing,
        isOnViewOrEdit,
        isPanelConfigLoading,
        isPanelsLoading,
        isSchedulesLoading,
        isSitesLoading,
        isSubmitting,
        loadNextPage,
        navigateToCommand,
        navigateToCreateCommand,
        outputs,
        panels,
        resetForm,
        saveStatus,
        schedules,
        setErrorMessages,
        setSaveStatus,
        sites,
        switches,
        values,
        viewCommand,
        handleChange,
    };
    return <CommandsContext.Provider value={contextValues}>{children}</CommandsContext.Provider>;
};

export const CommandsContextProvider = ContextProvider;

export function useCommandsContext() {
    const context = useContext(CommandsContext);

    return context;
}
