import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import * as _ from 'lodash';
import { Grid } from '@brivo/react-components';
import clsx from 'clsx';

import { ErrorAlert, SaveActionBar } from '@brivo/react-components';

import { calculateScrollMarginTop } from '@common/constants/Constants';
import ScenarioForm from './ScenarioForm';
import { useStyles } from './styles';

const ScenarioDetailsContent = ({
    saveStatus,
    setSaveStatus,
    values,
    setFieldValue,
    accountHasDefaultScenario,
    dirty,
    isCreateMode,
    isSubmitting,
    submitForm,
    resetForm,
    setErrors,
    errors,
    touched,
    assignedTriggers,
    isActiveScenario,
    emergencyScenariosFlag,
    hasCreateAdminPermission,
    isActiveAccountService,
    isStandardEdition,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [formIsReset, setFormIsReset] = useState(false);

    const hasAllDoorsFromTriggerPanel = !!values?.selectedDoors?.every(
        (door) => door.controlPanelId === values?.selectedTriggers?.[0]?.controlPanelId
    );

    //A standard edition scenario might not conform to standard edition requirements if the customer has downgraded their subscription level
    const conformsToStandardRequirements =
        !values?.selectedTriggers ||
        values?.selectedTriggers?.length === 0 ||
        (values?.selectedTriggers?.length === 1 && hasAllDoorsFromTriggerPanel);

    const actionBarProps = useMemo(() => {
        return isCreateMode
            ? {
                  primaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.create.primary-action'
                  ),
                  secondaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.create.secondary-action'
                  ),
                  primaryActionId: 'create-scenario-btn',
              }
            : {
                  text:
                      !isStandardEdition || conformsToStandardRequirements
                          ? t('Page.emergency-scenarios.emergency-scenario-details.bottom-bar.edit.text')
                          : t(
                                'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.edit.primary-action-disabled'
                            ),
                  primaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.edit.primary-action'
                  ),
                  secondaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.edit.secondary-action'
                  ),
                  primaryActionId: 'save-changes-btn',
              };
    }, [isCreateMode, t, isStandardEdition, conformsToStandardRequirements]);

    const handleReset = () => {
        resetForm();
        setFormIsReset(true);
        if (setSaveStatus) {
            setSaveStatus({
                showSuccess: false,
                showError: false,
                errorMsg: '',
            });
        }

        if (setErrors) {
            setErrors({});
        }
    };

    const onFormChange = (event) => {
        if (event.target.name === 'name') {
            if (event.target.value) {
                setErrors({ ...errors, nameInput: null });
            } else {
                setErrors({
                    ...errors,
                    nameInput: t('Field.validation.required'),
                });
            }
        }
    };

    const findFieldsWithErrors = (errors) => {
        const fieldsWithErrors = [];

        if (errors.nameInput) {
            fieldsWithErrors.push({
                targetId: 'TextField__name',
                erroredField: `${t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.name')}`,
            });
        }
        if (errors.scenarioType) {
            fieldsWithErrors.push({
                targetId: 'SingleSelectField__select-emergency-scenario-type',
                erroredField: `${t('Page.emergency-scenario-create.page.form.scenarioType.label')}`,
            });
        }

        if (errors.selectedDoors && values.scenarioType) {
            fieldsWithErrors.push({
                targetId: 'allDoors-wrapper',
                erroredField: `${t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors')}`,
            });
        }

        return fieldsWithErrors;
    };

    const detailedErrors = findFieldsWithErrors(errors);
    const scrollMarginTop = calculateScrollMarginTop(detailedErrors);

    return (
        <Grid item xs={12}>
            {saveStatus?.showError ? (
                <Grid item xs={12} className={clsx(classes.errorAlert, 'u-mb-full')}>
                    <ErrorAlert
                        errorMsg={saveStatus.errorMsg}
                        secondaryErrorMsg={saveStatus.secondaryErrorMessage}
                        detailedErrors={findFieldsWithErrors(errors)}
                    />
                </Grid>
            ) : null}
            {!_.values(errors).every(_.isEmpty) && isSubmitting && (
                <Grid item xs={12} className="u-mb-full">
                    <ErrorAlert
                        errorMsg={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.save-error.general-message.explanation'
                        )}
                    />
                </Grid>
            )}
            <Form onChange={onFormChange}>
                <ScenarioForm
                    values={values}
                    setFieldValue={setFieldValue}
                    accountHasDefaultScenario={accountHasDefaultScenario}
                    errors={errors}
                    touched={touched}
                    setErrors={setErrors}
                    isCreateMode={isCreateMode}
                    isConformingStandardEdition={isStandardEdition && conformsToStandardRequirements}
                    assignedTriggers={assignedTriggers}
                    isActiveScenario={isActiveScenario}
                    disabledTriggerSection={
                        !isActiveAccountService && !hasCreateAdminPermission && emergencyScenariosFlag
                    }
                    emergencyScenariosFlag={emergencyScenariosFlag}
                    scrollMarginTop={scrollMarginTop}
                    formIsReset={formIsReset}
                />
                <SaveActionBar
                    show={dirty}
                    text={actionBarProps.text}
                    disablePrimary={isSubmitting || (isStandardEdition && !conformsToStandardRequirements)}
                    primaryActionText={actionBarProps.primaryActionText}
                    primaryActionClickHandler={submitForm}
                    primaryActionId={actionBarProps.primaryActionId}
                    secondaryActionText={actionBarProps.secondaryActionText}
                    secondaryActionClickHandler={handleReset}
                    showPrimaryIcon={!isCreateMode}
                />
            </Form>
        </Grid>
    );
};

export default ScenarioDetailsContent;
