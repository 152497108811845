import { lockdownApi } from './lockdownApi';

export const getAllTriggers = async (pageSize, offset, sortField, direction, filters) => {
    const response = await lockdownApi.getLockdownTriggers({ pageSize, offset, sortField, direction, filters });
    return response.getLockdownTriggers;
};

export const getAllDoors = async (pageSize, offset, sortField, direction, filters) => {
    const response = await lockdownApi.getLockdownDoors({ pageSize, offset, sortField, direction, filters });
    return response.getLockdownDoors;
};
