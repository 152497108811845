import gql from 'graphql-tag';

export const CREATE_BULK_CARDS = gql`
    mutation createBulkCards($card: CredentialInput!) {
        createBulkCards(card: $card) {
            id
            credentialFormat {
                id
            }
            referenceId
            encodedCredential
            fieldValues {
                id
                value
                name
            }
            error
        }
    }
`;

export const SET_LICENSE_PLATE_CARDS = gql`
    mutation setLicensePlateCards($userId: Int!, $licensePlates: [LicensePlate]!) {
        setLicensePlateCards(userId: $userId, licensePlates: $licensePlates) {
            id
            error
            fieldValues {
                id
                value
                name
            }
            referenceId
            size
            effectiveFromDate
            effectiveToDate
            encodedCredential
            credentialFormat {
                id
                name
            }
            accountId
        }
    }
`;

export const SET_EE_LPR_LICENSE_PLATE_CARDS = gql`
    mutation setEELPRLicensePlateCards($userId: Int!, $licensePlates: [LicensePlate]!) {
        setEELPRLicensePlateCards(userId: $userId, licensePlates: $licensePlates) {
            id
            error
            fieldValues {
                id
                value
                name
            }
            referenceId
            size
            effectiveFromDate
            effectiveToDate
            encodedCredential
            credentialFormat {
                id
                name
            }
            accountId
        }
    }
`;

export const SET_FACIAL_ID_CARD = gql`
    mutation setFacialIdCredential($userId: Int!, $facialId: FacialId!, $consentId: Int!) {
        setFacialIdCredential(userId: $userId, facialId: $facialId, consentId: $consentId) {
            id
            error
            referenceId
            size
            effectiveFromDate
            effectiveToDate
            encodedCredential
            credentialFormat {
                id
                name
            }
            accountId
        }
    }
`;

export const GET_ALL_CARDS_QUERY = gql`
    query AllCards($pageSize: Int!, $offset: Int!, $sortField: String, $direction: String, $filters: [ListFilter]) {
        getAllCards(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            totalCount
            cards {
                accountId
                credentialFormat {
                    id
                    name
                    description
                }
                encodedCredential
                fieldValues {
                    id
                    name
                    value
                }
                id
                referenceId
                size
                user {
                    title
                    lastName
                    id
                    firstName
                    avatar
                }
                isOffline
            }
        }
    }
`;

export const GET_MOBILE_PASS_LIST_QUERY = gql`
    query MobilePassList(
        $pageSize: Int!
        $offset: Int!
        $sortField: String
        $direction: String
        $filters: [ListFilter]
    ) {
        getMobilePassList(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            totalCount
            cards {
                user {
                    title
                    lastName
                    id
                    firstName
                    avatar
                }
                invitationRedeemed
                invitationExpirationDate
                id
                email
                credentialExpires
                nfcEnabled
                nfcStatus
                accountId
                allegionUuid
                cardNumber
            }
        }
    }
`;

export const GET_FACEPRINTS_LIST_QUERY = gql`
    query FaceprintList(
        $pageSize: Int!
        $offset: Int!
        $sortField: String
        $direction: String
        $filters: [ListFilter]
    ) {
        getUserFaceIdCredentials(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            totalCount
            count
            faceprints {
                user {
                    title
                    lastName
                    id
                    firstName
                    avatar
                }
                id
                status
            }
        }
    }
`;

export const GET_CREDENTIAL_INFO = gql`
    query getCredentialInfo($accessCredentialId: Int!) {
        getCredentialInfo(accessCredentialId: $accessCredentialId) {
            type
            referenceId
        }
    }
`;

export const SEND_CONSENT = gql`
    mutation sendConsent($userId: Int!, $email: String!, $language: String) {
        sendConsent(userId: $userId, email: $email, language: $language) {
            uuid
        }
    }
`;
