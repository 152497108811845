import { Typography } from '@brivo/react-components';
import { useState, useEffect, useRef, forwardRef } from 'react';
import useStyles from './styles';

const TrimmedText = forwardRef(({ content, ...props }, ref) => {
    const typographyRef = useRef(null);
    const [isWrapped, setIsWrapped] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const handleWindowResize = () => {
            const typographyElement = typographyRef.current;

            if (typographyElement) {
                if (typographyElement.scrollWidth > typographyElement.clientWidth) {
                    setIsWrapped(true);
                } else {
                    setIsWrapped(false);
                }
            }
        };
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [content]);

    return (
        <div ref={ref} style={{ maxWidth: '100%' }}>
            <Typography
                className={classes.typographyWrapper}
                noWrap
                {...props}
                ref={typographyRef}
                title={isWrapped ? content : ''}
            >
                {content}
            </Typography>
        </div>
    );
});

export default TrimmedText;
