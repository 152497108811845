import qs from 'qs';
import {
    ALL_SCENARIOS_FILTER_ENABLED,
    API_BASE_URL,
    APP_ACCESS_DENIED_URL,
    EXCEPTION_EVENTS_KEY,
    IAMSVC_DOMAIN,
    INTEGRATIONS_URL,
    MAPBOX_TOKEN,
    PRECACHED_CLIP_SECURITY_ACTION_ID,
    REQUEST_OPTIONS,
} from '../constants/Constants';
import { lockdownApi } from '@common/webApis';
import { endOfDay, startOfDay } from 'date-fns';
import { find, get, isArray, partition, some, throttle, unionBy } from 'lodash';
import { redirect } from '@common/constants/Constants';
import { t } from 'i18next';

export const MAX_PAGE_SIZE = 100;

export const PANEL_COMMANDS = ['FOLLOW_SCHEDULE', 'IGNORE_SCHEDULE'];
const API_SCHEDULES_URL = '/api/schedules';
const VIDEO_CALL_BASE_URL = '/video-services/video-call';

export default class ApiHelper {
    constructor(auth) {
        this.auth = auth;
        this.basePath = API_BASE_URL;
    }

    getRequestOptions = async () => ({
        ...REQUEST_OPTIONS,
        headers: {
            ...REQUEST_OPTIONS.headers,
            Authorization: `Bearer ${await this.auth.getAccessToken()}`,
        },
    });

    callApi = (
        url,
        method = 'GET',
        body = null,
        credentials = 'include',
        additionalHeaders = {},
        returnErrorMessage = null,
        signal = null
    ) =>
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve, reject) => {
            let requestOptions = {
                ...(await this.getRequestOptions()),
                method,
                credentials,
                signal,
            };
            if (body) {
                requestOptions.body = JSON.stringify(body);
            }

            // Needed because the mapbox api has "Access-Control-Allow-Origin" set to '*'
            if (credentials !== 'include') {
                requestOptions = { ...requestOptions, withCredentials: false };
            }
            if (additionalHeaders) {
                requestOptions.headers = {
                    ...requestOptions.headers,
                    ...additionalHeaders,
                };
            }

            const res = await fetch(url, requestOptions);
            if (!returnErrorMessage) {
                if (!this.rejectIfNecessary(res, reject)) {
                    return;
                }
            }

            try {
                let response = await this.parseJSON(res);
                if (returnErrorMessage) {
                    response = { ...response, apiCallStatus: res.status };
                }
                return resolve(response);
            } catch (error) {
                this.rejectIfNecessary(error, reject);
            }
        }).catch(() => console.error(`Call to ${url} failed`));

    getLockdownActiveScenarios = throttle(
        () => {
            return this.callApi(
                `${this.basePath}/lockdown-scenarios/activeScenarios`,
                undefined,
                undefined,
                undefined,
                { 'Cache-Control': 'no-store' }
            );
        },
        500,
        { trailing: false }
    );

    parseJSON = async (response) => {
        return await response.text().then(function (text) {
            return text ? JSON.parse(text) : {};
        });
    };

    loopRequest = (arr, requestFn, fnArg) => {
        if (!arr) arr = [];
        let result = [];

        for (const element of arr) {
            let item = element;
            result.push(
                requestFn(!fnArg ? item : item[fnArg])
                    .then((res) => {
                        if (res) {
                            return {
                                ...item,
                                ...res,
                            };
                        }
                        // return original copy
                        return {
                            ...item,
                        };
                    })
                    // return errors to promise
                    .catch((err) => {
                        return {
                            ...item,
                            error: err.message,
                        };
                    })
            );
        }

        return Promise.all(result)
            .then((x) => x.filter((x) => x))
            .catch((err) => err);
    };

    loopRequestCountByHour = (arr, requestFn, fnArg1, fnArg2) => {
        if (!arr) arr = [];
        let result = [];

        for (const element of arr) {
            let item = element;
            result.push(
                requestFn(item[fnArg1], item[fnArg2])
                    .then((res) => {
                        return res;
                    })
                    // return errors to promise
                    .catch((err) => {
                        return {
                            ...item,
                            error: err.message,
                        };
                    })
            );
        }

        return Promise.all(result)
            .then((x) => x.filter((x) => x || x === 0))
            .catch((err) => err);
    };

    getCurrentUser = async () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const res = await fetch(`${this.basePath}/administrators/whoami`, await this.getRequestOptions());

            if (res.status === 404) {
                console.log(
                    'The whoami endpoint has returned a 404 Not Found response. This user may not be an Onair user.'
                );
            }

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            let data = await res.json();

            return resolve(data);
        }).catch(() => console.error(`Call to get current user failed`));
    };

    getSmartHomeIsMigrated = async (email) => {
        // Unlike other methods, we simply want to return false if this api call fails and not redirect
        try {
            const res = await fetch(
                `${IAMSVC_DOMAIN}/api/smarthome/migration/status?email=${encodeURIComponent(email)}`,
                await this.getRequestOptions()
            );

            if (res.ok) {
                let data = await res.json();

                return data.migrated ?? false;
            }

            return false;
        } catch {
            console.error('Unable to retrieve SmartHome status');
            return false;
        }
    };

    getIntegrationActivationLink = async (siteId, accountId) => {
        // Unlike other methods, we simply want to return false if this api call fails and not redirect
        try {
            const res = await this.callApi(`${INTEGRATIONS_URL}/v1/mindbody/activation`, 'POST', {
                siteId: parseInt(siteId),
                accountId,
            });
            return res?.ActivationLink ?? false;
        } catch (e) {
            console.error('Unable to retrieve Mindbody activation link');
            return false;
        }
    };

    notifyBDSCallAnswered = async (accessPointId) => {
        try {
            const res = await this.callApi(
                `${this.basePath}${VIDEO_CALL_BASE_URL}/notifyAboutBDSCallAnswered?accessPointId=${accessPointId}&device=ACCESS`,
                'POST'
            );
            return res;
        } catch (e) {
            console.error('Unable to call video-services notifyAboutBDSCallAnswered lambda function');
        }
    };

    getMemberships = async (siteId) => {
        try {
            const res = await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/memberships/${encodeURIComponent(siteId)}`,
                'GET',
                null,
                'include',
                null,
                true
            );
            if (res.apiCallStatus === 200 || res.apiCallStatus === 204) {
                return res?.Memberships ?? false;
            }
            if (res.apiCallStatus === 400) {
                return Promise.reject('Unable to retrieve Mindbody Memberships');
            }
            if (res.apiCallStatus === 401) {
                return Promise.reject('Incorrect Authentication Credentials!');
            }
            if (res.apiCallStatus === 500) {
                return Promise.reject('Server Error!');
            }
            return Promise.reject('Unknown Error!');
        } catch (e) {
            console.error('Unable to retrieve Mindbody Memberships');
            return false;
        }
    };

    getMindbodyAccount = async (accountId) => {
        try {
            const res = await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/integrations/accounts/${encodeURIComponent(accountId)}`,
                'GET'
            );
            return res;
        } catch (e) {
            console.error('Unable to retrieve Mindbody Account');
            return false;
        }
    };

    getMindbodyHealth = async (accountId) => {
        try {
            const res = await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/integrations/accounts/${encodeURIComponent(
                    accountId
                )}/health/mindbody`,
                'GET',
                null,
                'include',
                null,
                true
            );

            if (res.apiCallStatus === 200 || res.apiCallStatus === 204) {
                return res;
            }
            if (res.apiCallStatus === 400) {
                return Promise.reject('No integrated account');
            }
            if (res.apiCallStatus === 401) {
                return Promise.reject('No Auth Provided');
            }
            if (res.apiCallStatus === 403) {
                return Promise.reject('Permission Denied');
            }
            if (res.apiCallStatus === 500) {
                return Promise.reject('Server Error!');
            }
            return Promise.reject('Unknown Error!');
        } catch (e) {
            //Ignoring error when saving MindBody
        }
    };

    getBrivoHealth = async (accountId) => {
        try {
            const res = await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/integrations/accounts/${encodeURIComponent(accountId)}/health/brivo`,
                'GET',
                null,
                'include',
                null,
                true
            );

            if (res.apiCallStatus === 200 || res.apiCallStatus === 204) {
                return false;
            }
            if (res.apiCallStatus === 400) {
                return Promise.reject('No integrated account');
            }
            if (res.apiCallStatus === 401) {
                return Promise.reject('No Auth Provided');
            }
            if (res.apiCallStatus === 403) {
                return Promise.reject('Permission Denied');
            }
            if (res.apiCallStatus === 500) {
                return Promise.reject('Server Error!');
            }
            return Promise.reject('Unknown Error!');
        } catch (e) {
            //Ignoring error when saving mindbody
        }
    };

    accountActivation = async (siteId, accountId) => {
        try {
            return await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/activation `,
                'POST',
                {
                    siteId: parseInt(siteId),
                    accountId: parseInt(accountId),
                },
                'include'
            );
        } catch (e) {
            console.error('Unable to activate Account');
            return false;
        }
    };

    deleteAccount = async (accountId) => {
        try {
            return await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/integrations/accounts/${accountId}`,
                'DELETE',
                'include'
            );
        } catch (e) {
            console.error('Unable to unlink Account');
            return false;
        }
    };

    saveSecrets = async (accountId, config) => {
        try {
            const res = await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/integrations/accounts/${encodeURIComponent(accountId)}/config`,
                'POST',
                config,
                'include',
                null,
                true
            );
            if (res.apiCallStatus === 400) {
                return Promise.reject('Incorrect Brivo Credentials!');
            }
            if (res.apiCallStatus === 401) {
                return Promise.reject('Incorrect Authentication Credentials!');
            }
            if (res.apiCallStatus === 500) {
                return Promise.reject('Server Error!');
            }
            return res;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    saveMemberships = async (accountId, memberships) => {
        // Example!!
        // {
        //     "memberships": [
        //         {
        //             "id": "Your new membership",
        //             "accessGroups": ["group 1", "group 2", "group 3"]
        //         },
        //         {
        //             "id": "Other membership",
        //             "accessGroups": ["group 7"]
        //         }
        //     ]
        // }

        try {
            return await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/integrations/accounts/${encodeURIComponent(
                    accountId
                )}/mappings/memberships`,
                'POST',
                memberships
            );
        } catch (e) {
            console.error('Unable to save memberships');
            return false;
        }
    };

    syncClients = async (accountId) => {
        try {
            return await this.callApi(
                `${INTEGRATIONS_URL}/v1/mindbody/sync/accounts/${encodeURIComponent(accountId)}`,
                'POST'
            );
        } catch (e) {
            console.error('Unable to sync clients from Mindbody');
            return false;
        }
    };

    getUserById = async (userId) => {
        return this.callApi(`${this.basePath}/users/${userId}`);
    };

    getAccount = async () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const res = await fetch(`${this.basePath}/accounts`, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const response = await res.json();
            let { data } = response;

            if (data && typeof data === 'object') {
                return resolve(data);
            } else {
                return reject(new Error(data));
            }
        }).catch(() => console.error(`Call to get account failed`));
    };

    getSites = async () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const res = await fetch(`${this.basePath}/sites`, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const response = await res.json();

            return resolve(response.data);
        }).catch(() => console.error(`Call to get sites failed`));
    };

    getSite = async (siteId) => {
        return this.callApi(`${this.basePath}/sites/${siteId}`);
    };

    getDeviceCameras = async (accessPointId) => {
        return this.callApi(`${this.basePath}/access-points/${accessPointId}/cameras`);
    };

    getCamerasList = async (accountId, signal, additionalHeaders = {}, returnErrorMessage = null) => {
        return this.callApi(
            `${this.basePath}/video-services/camera-list/cameras/${accountId}?calledFromImport=true`,
            'GET',
            null,
            'include',
            additionalHeaders,
            returnErrorMessage,
            signal
        );
    };

    getCamerasListV2 = async (signal = null, additionalHeaders = {}, returnErrorMessage = null) => {
        return this.callApi(
            `${this.basePath}/video-services/camera-list/v2/cameras`,
            'GET',
            null,
            'include',
            additionalHeaders,
            returnErrorMessage,
            signal
        );
    };

    getCameraThumbnail = async (cameraId, signal) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const res = await fetch(`${this.basePath}/video-services/video-thumbnail/${cameraId}`, {
                ...(await this.getRequestOptions()),
                signal,
            });

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const response = await res.text();

            return resolve(response);
        }).catch((error) => {
            console.error(`Call to get camera thumbnail failed`);
            return Promise.reject(error);
        });
    };

    saveCamera = async (cameras, signal = null, additionalHeaders = {}, returnErrorMessage = null) => {
        // cameras = [{ externalId, externalName, videoProviderTypeId }]
        return this.callApi(
            `${this.basePath}/video-services/video-crud/v2/cameras`,
            'POST',
            cameras,
            'include',
            additionalHeaders,
            returnErrorMessage,
            signal
        );
    };

    importCameras = async (
        accountId,
        adminUserId,
        signal = null,
        body = null,
        additionalHeaders = {},
        returnErrorMessage = null
    ) => {
        return this.callApi(
            `${this.basePath}/video-services/video-import/importCameras?accountId=${accountId}&adminUserId=${adminUserId}`,
            'GET',
            body,
            'include',
            additionalHeaders,
            returnErrorMessage,
            signal
        );
    };

    getCameraAccessPoints = async (cameraId) => {
        return this.callApi(`${this.basePath}/video-services/video-crud/cameras/${cameraId}/access-points`);
    };

    getDevices = async (siteId) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/sites/${siteId}/access-points`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data.data);
        }).catch(() => console.error(`Call to get Devices failed`));
    };

    getEventsForCamera = async (cameraId, startTime, endTime) => {
        return this.callApi(
            `${this.basePath}/video-services/video-events/camera/${cameraId}?startTime=${startTime}&endTime=${endTime}`
        );
    };

    /**
     * example response structure:
     *
     * [
     *    {
     *      code: 5006,
     *      description: 'Event description 1',
     *      severity: 'warning'
     *    },
     *    {
     *      code: 5007,
     *      description: 'Event Description 2',
     *      severity: 'critical'
     *    },
     *    {
     *      code: 5008,
     *      description: 'Event Description 3'
     *    }
     * ]
     *
     * @returns {Promise<*>}
     */
    getAllExceptionEventTypes = async () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/ext/events/exceptions/describe`;

            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data);
        }).catch(() => console.error(`Call to get Exception types failed`));
    };

    getAccessEventsPaginated = async (from, pageSize = 20, to = null) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const occurredTo = to ? ';occurred__gt:' + encodeURIComponent(to) : '';
            const url = `${this.basePath}/events/access?pageSize=${pageSize}${
                from ? '&filter=occurred__lt:' + encodeURIComponent(from) + occurredTo : ''
            }`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data.data);
        }).catch(() => console.error(`Call to get Access Events failed`));
    };

    getSelectedAccessEvents = async (from, to, offset) => {
        const url = `${this.basePath}/events/access?filter=occurred__gt:${encodeURIComponent(
            from.toISOString()
        )};occurred__lt:${encodeURIComponent(to.toISOString())}?&pageSize=${MAX_PAGE_SIZE}${
            offset ? '&offset=' + encodeURIComponent(offset) : ''
        }`;

        let returnValue = [];
        const result = await this.callApi(url);

        returnValue = returnValue.concat(result.data);

        if (result.data.length >= MAX_PAGE_SIZE) {
            const offset = result.data[result.data.length - 1].occurred;
            const additionalData = await this.getSelectedAccessEvents(from, to, offset);

            returnValue = returnValue.concat(additionalData);
        }

        return returnValue;
    };

    getVideoEventsCount = async (date) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/events/counts/access?filter=${encodeURIComponent(
                `occurred__gt:` +
                    startOfDay(date).toISOString() +
                    `;occurred__lt:` +
                    date.toISOString() +
                    `;event_type__eq:video_linkable`
            )}`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data.count);
        }).catch(() => console.error(`Call to get video event counts failed`));
    };

    getAccessEventsCount = async (fromDate, toDate) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/events/counts/access?filter=occurred__gt:${encodeURIComponent(
                fromDate.toISOString()
            )};occurred__lt:${encodeURIComponent(toDate.toISOString())}`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data.count);
        }).catch(() => console.error(`Call to get access event counts failed`));
    };

    getExceptionEventsCount = async (fromDate, toDate, site, securityActions) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/events/counts/access?filter=${encodeURIComponent(
                `occurred__gt:` +
                    fromDate.toISOString() +
                    `;occurred__lt:` +
                    toDate.toISOString() +
                    `;event_type__eq:exception`
            )}${site ? encodeURIComponent(';site__eq:' + site) : ''}${
                securityActions ? encodeURIComponent(';security_action__eq:' + securityActions.join()) : ''
            }
            `;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data.count);
        }).catch(() => console.error(`Call to get exception event counts failed`));
    };

    getExceptionEventsByEvents = async (fromDate, toDate, eventIds, siteId, securityActions, offset = 0) => {
        if (eventIds.length === 0) {
            return 0;
        }
        let returnValue = 0;
        const url = `${this.basePath}/events/access?filter=${encodeURIComponent(
            `occurred__gt:` +
                fromDate.toISOString() +
                `;occurred__lt:` +
                toDate.toISOString() +
                `;event_type__eq:exception`
        )}${siteId ? encodeURIComponent(';site__eq:' + siteId) : ''}${
            securityActions ? encodeURIComponent(';security_action__eq:' + securityActions.join()) : ''
        }&pageSize=${MAX_PAGE_SIZE}${offset ? '&offset=' + offset : ''}`;
        const result = await this.callApi(url);
        returnValue += result?.data?.filter((event) => eventIds.includes(event.uuid)).length;
        if (result.data.length === MAX_PAGE_SIZE) {
            const additionalData = await this.getExceptionEventsByEvents(
                fromDate,
                toDate,
                eventIds?.filter((id) => result.data?.map((event) => event.uuid).includes(id)),
                siteId,
                securityActions,
                offset + MAX_PAGE_SIZE
            );
            returnValue += additionalData;
        }
        return returnValue;
    };

    getAccessPointsForSite = async (siteId) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/sites/${siteId}/access-points`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data.data);
        }).catch(() => console.error(`Call to get site access points failed`));
    };

    getAllAccessPoints = async () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const maxPageSize = MAX_PAGE_SIZE;
            const url = `${this.basePath}/access-points?pageSize=${maxPageSize}`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            if (data.count > maxPageSize) {
                let offset = maxPageSize;
                while (offset < data.count) {
                    const offsetUrl = `${url}&offset=${offset}`;
                    const result = await fetch(offsetUrl, await this.getRequestOptions());
                    const pageData = await result.json();
                    data.data = data.data.concat(pageData.data);
                    offset += maxPageSize;
                }
            }

            return resolve(data.data);
        }).catch(() => console.error(`Call to get all access points failed`));
    };

    getAccessEventsForDay = async (date) => {
        const params = { date };
        return new Promise((resolve, reject) => {
            this.fetchAccessEvents(params, [], true, resolve, reject);
        }).catch(() => console.error(`Call to get access events for day failed`));
    };

    getAccessEventsSinceDate = async (date) => {
        const params = { date };
        return new Promise((resolve, reject) => {
            this.fetchAccessEvents(params, [], false, resolve, reject);
        }).catch(() => console.error(`Call to get access events since date failed`));
    };

    getExceptionEventsSinceDate = async (date) => {
        const params = { date };
        return new Promise((resolve, reject) => {
            this.fetchAccessEvents(params, [], false, resolve, reject, EXCEPTION_EVENTS_KEY);
        }).catch(() => console.error(`Call to get exception events since date failed`));
    };

    getCriticalExceptionsEventsSinceDate = async (date, securityActions, site) => {
        const params = { date, securityActions, site };
        return new Promise((resolve, reject) => {
            this.fetchAccessEvents(params, [], false, resolve, reject, EXCEPTION_EVENTS_KEY);
        }).catch(() => console.error(`Call to get critical exception events since date failed`));
    };

    getCriticalExceptionsEventsForDay = async (date, securityActions, site) => {
        const params = { date, securityActions, site };
        return new Promise((resolve, reject) => {
            this.fetchAccessEvents(params, [], true, resolve, reject, EXCEPTION_EVENTS_KEY);
        }).catch(() => console.error(`Call to get critical exception events for day failed`));
    };

    getCameras = async (pageSize, offset) => {
        return await this.callApi(`${this.basePath}/cameras?pageSize=${pageSize}${offset ? '&offset=' + offset : ''}`);
    };

    getAllCameras = async () => {
        const pageSize = MAX_PAGE_SIZE;
        let result = await this.getCameras(pageSize);
        let resultData = result.data;

        const count = result.count;
        const pageNr = Math.ceil(count / pageSize);
        if (count > pageSize) {
            for (let i = 2; i <= pageNr; i++) {
                let offset = pageSize * (i - 1);
                result = await this.getCameras(pageSize, offset);
                resultData = resultData.concat(result.data);
            }
        }
        return resultData;
    };

    getCameraById = async (cameraId) => {
        // showStatus makes rest calls to third parties, is slow. Avoid in loops
        return this.callApi(`${this.basePath}/cameras/${cameraId}?showStatus=true`);
    };

    getCameraByIdSansStatus = async (cameraId) => {
        return this.callApi(`${this.basePath}/cameras/${cameraId}?showStatus=false`);
    };

    getCameraEventsForDate = async (date) => {
        const params = { date };

        return new Promise((resolve, reject) => {
            this.fetchAccessEvents(params, [], false, resolve, reject, 'camera');
        }).catch(() => console.error(`Call to get camera events  for date failed`));
    };

    getAccessToken = async () => {
        return this.auth.getAccessToken();
    };

    getLiveViewUrlInfo = async (cameraId) => {
        return this.callApi(`${this.basePath}/video-services/video-live/cameras/${cameraId}`);
    };

    getVideoClipByEventId = async (
        eventId,
        cameraId,
        signal = null,
        additionalHeaders = {},
        returnErrorMessage = null
    ) => {
        return this.callApi(
            `${this.basePath}/video-services/clips/cameras/${cameraId}?eventId=${eventId}`,
            'GET',
            null,
            'include',
            additionalHeaders,
            returnErrorMessage,
            signal
        );
    };

    getVideoClipByStartTimeEndTime = async (
        cameraId,
        startTime,
        endTime,
        signal = null,
        additionalHeaders = {},
        returnErrorMessage = null
    ) => {
        return this.callApi(
            `${this.basePath}/video-services/clips/cameras/${cameraId}/time?startTime=${startTime}&endTime=${endTime}`,
            'GET',
            null,
            'include',
            additionalHeaders,
            returnErrorMessage,
            signal
        );
    };

    getClipList = async (cameraObjectIds, startTime, endTime) => {
        const requestBody = { cameraObjectIds }; //do not stringify it, callApi will do that
        return this.callApi(
            `${this.basePath}/video-services/clips/list?startTime=${startTime}&endTime=${endTime}`,
            'POST',
            requestBody
        );
    };

    getS3FlvConversionUrl = async (s3flvUrl, cameraId) => {
        return this.callApi(
            `${this.basePath}/video-services/clips/cameras/${cameraId}/getS3FlvConversionUrl/${s3flvUrl}`
        );
    };

    getEventClipsByEventId = async (event, cameraId) => {
        let params = this.getPresignedParams(event, cameraId);
        return this.callApi(`${this.basePath}/video-services/clips/events/${event.id}?${params}`);
    };

    fetchAccessEvents = async (params, data, isWholeDay, resolve, reject, eventType) => {
        const pageSize = MAX_PAGE_SIZE;

        const from = isWholeDay ? startOfDay(params.date).toISOString() : params.date.toISOString();
        const to = isWholeDay ? endOfDay(params.date).toISOString() : null;

        const url = `${this.basePath}/events/access?pageSize=${pageSize}${
            params.offset ? '&offset=' + params.offset : ''
        }&filter=${encodeURIComponent('occurred__gt:' + from)}${to ? encodeURIComponent(';occurred__lt:' + to) : ''}${
            eventType ? encodeURIComponent(';event_type__eq:' + eventType) : ''
        }${params.securityActions ? encodeURIComponent(';security_action__eq:' + params.securityActions.join()) : ''}${
            params.site ? encodeURIComponent(';site__eq:' + params.site) : ''
        }`;

        const req = await fetch(url, await this.getRequestOptions());

        if (!this.rejectIfNecessary(req, reject)) {
            return;
        }

        const res = await req.json();

        data = data.concat(res.data);

        if (res.data.length < pageSize) {
            return resolve(data);
        } else {
            params.offset = res.data[res.data.length - 1].occurred;
            return this.fetchAccessEvents(params, data, isWholeDay, resolve, reject, eventType);
        }
    };

    getPeakfaceImageByEventId = async (accountId, eventId) => {
        return new Promise((resolve, reject) => {
            try {
                let tries = 0;
                const getPeakFaceImage = async () => {
                    tries++;
                    const peakfaceImages = await this.callApi(
                        `${this.basePath}/peakface/${accountId}/events/${eventId}`
                    );
                    const data = peakfaceImages.data;

                    if (
                        (data &&
                            data.length &&
                            !data.some((peakfaceImage) => peakfaceImage.status === 'PEAKFACE_STARTED')) ||
                        tries >= 5
                    ) {
                        return resolve(peakfaceImages);
                    } else {
                        setTimeout(getPeakFaceImage, 1000);
                    }
                };
                getPeakFaceImage();
            } catch (error) {
                return reject(error);
            }
        });
    };

    getPeakfaceImages = async (accountId, filters, limit) => {
        if (filters && typeof filters !== 'object') {
            throw new Error('Typeof "filters" must be object');
        }
        if (filters) {
            filters = Object.keys(filters)
                ?.map((k) => `${k}=${filters[k]}`)
                .join(';');
        }
        const queryParamConnector = !filters ? '?' : '&';
        return this.callApi(
            `${this.basePath}/peakface/${accountId}/images${filters ? '?filters=' + encodeURIComponent(filters) : ''}${
                limit ? queryParamConnector + 'limit=' + limit : ''
            }`
        );
    };

    getPeakfaceEvents = async (from, to, offset, pageSize) => {
        pageSize = pageSize || MAX_PAGE_SIZE;

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/events/access?pageSize=${pageSize}${
                offset ? '&offset=' + offset : ''
            }&filter=${encodeURIComponent('occurred__gt:' + from.toISOString())}${
                to ? encodeURIComponent(';occurred__lt:' + to.toISOString()) : ''
            }${encodeURIComponent(`;event_type__eq:peakface_events`)}`;
            const res = await fetch(url, await this.getRequestOptions());

            if (!this.rejectIfNecessary(res, reject)) {
                return;
            }

            const data = await res.json();

            return resolve(data);
        }).catch(() => console.error(`Call to get snapshot events failed`));
    };

    getAllPeakfaceEvents = async (from, to) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const pageSize = MAX_PAGE_SIZE;
                let data = [];

                let res = await this.getPeakfaceEvents(from, to, 0, pageSize);
                data = data.concat(res.data);

                while (res.data.length >= pageSize) {
                    let offset = res.data[res.data.length - 1].occurred;
                    res = await this.getPeakfaceEvents(from, to, offset, pageSize);
                    data = data.concat(res.data);
                }

                resolve(data);
            } catch (e) {
                console.error('@[Helpers] getAllPeakfaceEvents', e);
                reject(e);
            }
        });
    };

    getDoorStatusConfig = async () => {
        return this.getConfig({ type: 'doorStatus' });
    };

    getEventSummaryConfig = async () => {
        return this.getConfig({ type: 'eventSummary' });
    };

    getEventRankingConfig = async () => {
        return this.getConfig({ type: 'eventRanking' });
    };

    getDoorRankingConfig = async (siteId) => {
        return this.getConfig({ type: 'doorRanking', siteId });
    };

    getEventStatusConfig = async () => {
        return this.getConfig({ type: 'eventStatus' });
    };

    getConfig = async (data) => {
        const result = await this.callApi(
            `${this.basePath}/dashboard/config${qs.stringify(data, {
                addQueryPrefix: true,
                strictNullHandling: true,
            })}`
        );

        if (isArray(result) && result.length > 0) {
            return result[0];
        } else {
            return result;
        }
    };

    putEventRankingConfig = async (config) => {
        return this.putConfig('eventRanking', config);
    };

    putDoorRankingConfig = async (data) => {
        return this.putConfig('doorRanking', data);
    };

    putConfig = async (type, data) => {
        if (type) {
            return this.callApi(`${this.basePath}/dashboard/config?type=${type}`, 'PUT', data);
        }
    };

    getEventNotesForDates = async (fromDateTime, toDateTime) => {
        return this.callApi(`${this.basePath}/dashboard/event?fromDate=${fromDateTime}&toDate=${toDateTime}`);
    };

    postEventNotes = async (note) => {
        return this.callApi(`${this.basePath}/dashboard/event`, 'POST', note);
    };

    unlockDoor = async (accessPointId) => {
        return this.callApi(`${this.basePath}/access-points/${accessPointId}/activate`, 'POST');
    };

    callElevator = async (accessPointId, pulseTime, targetDevices) => {
        return this.callApi(`${this.basePath}/access-points/${accessPointId}/activateElevator`, 'POST', {
            pulseTime: parseInt(pulseTime),
            targetDevices,
        });
    };

    getPanels = async () => {
        const pageSize = MAX_PAGE_SIZE;
        const initialRes = await this.callApi(`${this.basePath}/control-panels?pageSize=${pageSize}`);
        const count = get(initialRes, 'count', 0);
        let arr = [initialRes];
        for (let i = 2; i <= Math.ceil(count / pageSize); ++i) {
            arr.push(this.callApi(`${this.basePath}/control-panels?pageSize=${pageSize}&offset=${(i - 1) * pageSize}`));
        }
        const res = await Promise.all(arr);
        return res.reduce((prev, curr) => [...(prev || []), ...curr.data], []);
    };

    enableMonitoring = async (accessPointId) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const url = `${this.basePath}/access-points/${accessPointId}/report-live-status`;

            let requestOptions = {
                ...(await this.getRequestOptions()),
                method: 'PUT',
                body: JSON.stringify({
                    reportLiveStatus: true,
                }),
            };

            try {
                const res = await fetch(url, requestOptions);
                // a bad request returns status 200 and the html website
                if (res.status === 204) {
                    resolve(true);
                } else {
                    reject();
                }
            } catch (error) {
                // Do not react if we are unable to enable monitoring on a device
                console.error('Unable to enable monitoring', error);
                return null;
            }
        }).catch(() => console.error(`Call to enable live monitoring failed`));
    };

    getAddressCoordinates = (address) => {
        return this.callApi(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                address
            )}.json?access_token=${MAPBOX_TOKEN}`,
            'GET',
            null,
            'omit'
        );
    };

    getLockdownScenarios = () => {
        return this.callApi(`${this.basePath}/lockdown-scenarios?pageSize=100`, undefined, undefined, undefined, {
            'Cache-Control': 'no-store',
        });
    };

    getLockDownScenariosIncludingHardwiredLockdowns = () => {
        return this.callApi(
            `${this.basePath}/lockdown-scenarios?filter=hardware__eq:true&pageSize=100`,
            undefined,
            undefined,
            undefined,
            { 'Cache-Control': 'no-store' }
        );
    };

    initiateLockdown = (scenarioId) => {
        return this.callApi(`${this.basePath}/lockdown-scenarios/${scenarioId}/initiate`, 'POST', null, 'include', {
            'Accept-version': '2.0',
            'Cache-Control': 'no-store',
        });
    };

    initiateFullLockdown = () => {
        return this.callApi(`${this.basePath}/lockdown-scenarios/initiate`, 'POST', null, 'include', {
            'Accept-version': '2.0',
            'Cache-Control': 'no-store',
        });
    };

    clearLockdownScenario = (scenarioId) => {
        return this.callApi(
            `${this.basePath}/lockdown-scenarios/${scenarioId}/clear`,
            'POST',
            undefined,
            undefined,
            undefined,
            { 'Cache-Control': 'no-store' }
        );
    };

    clearLockdownScenarioNew = (scenario) => {
        console.log('test 1');
        const { id, threatLevelTypeId } = scenario;
        let threatType = '';
        if (threatLevelTypeId === 2) {
            threatType = 'lockdown';
        }
        if (threatLevelTypeId === 3) {
            threatType = 'egress';
        }
        const url = `${API_BASE_URL}/emergency-scenarios/${threatType}/${id}/clear`;
        return this.callApi(url, 'POST', undefined, undefined, undefined, { 'Cache-Control': 'no-store' });
    };

    clearAllEmergencyScenarios = () => {
        return this.callApi(`${API_BASE_URL}/emergency-scenarios/clear-all`, 'POST', undefined, undefined, undefined, {
            'Cache-Control': 'no-store',
        });
    };

    clearAccountDefaultLockdown = () => {
        return this.callApi(`${this.basePath}/lockdown-scenarios/clear`, 'POST', undefined, undefined, undefined, {
            'Cache-Control': 'no-store',
        });
    };

    clearAccountDefaultLockdownNew = () => {
        return this.callApi(
            `${this.basePath}/emergency-scenarios/default-lockdown/clear`,
            'POST',
            undefined,
            undefined,
            undefined,
            {
                'Cache-Control': 'no-store',
            }
        );
    };

    changeDeviceState = (accesspointId, state) => {
        if (!PANEL_COMMANDS.includes(state)) {
            console.error('Invalid State Requested for changeDeviceState');
            return Promise.reject();
        }

        let data = {
            controlPanelCommandName: state,
        };

        return this.callApi(`${this.basePath}/schedules/change-state/${accesspointId}`, 'POST', data);
    };

    changeDeviceStateV2 = (accesspointId, endTimeMode, behavior, endTime) => {
        let data = {
            deviceObjectId: accesspointId,
            endTimeMode: endTimeMode,
            behavior: behavior === 1 ? 'LOCK' : 'UNLOCK',
            endTime: endTime,
        };

        return this.callApi(`${this.basePath}/schedules/change-state/${accesspointId}`, 'POST', data, 'include', {
            'Accept-version': '2.0',
        });
    };

    deleteDeviceStateV2 = (accesspointId) => {
        return this.callApi(`${this.basePath}/schedules/change-state/${accesspointId}`, 'DELETE', null, 'include', {
            'Accept-version': '2.0',
        });
    };

    getAccessPointStatusForSchedule = (accessPointId) => {
        return this.callApi(`${this.basePath}/device-status/${accessPointId}`, 'GET', null, 'include', {
            'Accept-version': '2.0',
        });
    };

    getAllSchedules = async () => {
        const pageSize = 100;
        let result = await this.getPagedSchedule(pageSize, 0);
        let resultData = result.data;

        const count = result.count;
        const pageNr = Math.ceil(count / pageSize);
        if (count > pageSize) {
            for (let i = 2; i <= pageNr; i++) {
                let offset = pageSize * (i - 1);
                result = await this.getPagedSchedule(pageSize, offset);
                resultData = resultData.concat(result.data);
            }
        }
        return resultData;
    };

    getPagedSchedule = async (pageSize, offset) =>
        this.callApi(`${API_SCHEDULES_URL}?offset=${offset}&pageSize=${pageSize}`, undefined, undefined, undefined, {
            'Cache-Control': 'no-store',
        });

    thereAreActiveLockdownScenarios = (activeLockdownsResponse) => {
        return activeLockdownsResponse?.length;
    };

    findDefaultActiveLockdownsForThisAccount = (activeLockdowns) => {
        const lockdownGroups = partition(
            activeLockdowns,
            (activeLockdown) => activeLockdown.isDefault && activeLockdown.id === activeLockdown.accountId
        );

        const defaultActiveLockdownsForAccount = lockdownGroups[0];
        const activeLockdownsThatAreNotDefaultOrNotForThisAccount = lockdownGroups[1];

        return { defaultActiveLockdownsForAccount, activeLockdownsThatAreNotDefaultOrNotForThisAccount };
    };

    getClearedLockdownsNew = async (activeLockdowns) => {
        const { defaultActiveLockdownsForAccount, activeLockdownsThatAreNotDefaultOrNotForThisAccount } =
            this.findDefaultActiveLockdownsForThisAccount(activeLockdowns);

        let clearedLockdowns = [];

        if (defaultActiveLockdownsForAccount.length) {
            const response = await this.clearAccountDefaultLockdownNew();
            clearedLockdowns.push(response);
        }

        if (activeLockdownsThatAreNotDefaultOrNotForThisAccount.length) {
            const response = await this.loopRequest(
                activeLockdownsThatAreNotDefaultOrNotForThisAccount,
                this.clearLockdownScenarioNew
            );
            clearedLockdowns.push(...response);
        }

        return clearedLockdowns;
    };

    getClearedLockdowns = async (activeLockdowns) => {
        const { defaultActiveLockdownsForAccount, activeLockdownsThatAreNotDefaultOrNotForThisAccount } =
            this.findDefaultActiveLockdownsForThisAccount(activeLockdowns);

        let clearedLockdowns = [];

        if (defaultActiveLockdownsForAccount.length) {
            const response = await this.clearAccountDefaultLockdown();
            clearedLockdowns.push(response);
        }

        if (activeLockdownsThatAreNotDefaultOrNotForThisAccount.length) {
            const response = await this.loopRequest(
                activeLockdownsThatAreNotDefaultOrNotForThisAccount,
                this.clearLockdownScenario,
                'id'
            );
            clearedLockdowns.push(...response);
        }

        return clearedLockdowns;
    };

    getFailedLockdownNames = (failedLockdowns, activeLockdowns) => {
        return failedLockdowns?.map((failedLockdown) => {
            if (failedLockdown.scenarioOid === 0) {
                return 'Full Lockdown';
            } else {
                const lockdown = find(activeLockdowns, (activeLockdown) => {
                    return activeLockdown.id === failedLockdown.scenarioOid;
                });

                return get(lockdown, 'name', '');
            }
        });
    };

    updateResponseBasedOnClearedLockdowns = async (response, activeLockdowns) => {
        const clearedLockdowns = await this.getClearedLockdowns(activeLockdowns);

        if (clearedLockdowns.length === 0) {
            response.message = t('Page.lockdown.bottom-bar-fail-clear');
        } else if (some(clearedLockdowns, ['didAllFail', true])) {
            const failedLockdowns = clearedLockdowns?.filter((clearedLockdown) => clearedLockdown.didAllFail);

            response.atLeastOneLockdownCleared = failedLockdowns.length !== clearedLockdowns.length;
            response.message = t('Page.lockdown.bottom-bar-could-not-clear', {
                field: this.getFailedLockdownNames(failedLockdowns, activeLockdowns).join('","'),
            });
        } else {
            response.atLeastOneLockdownCleared = true;
            response.message = t('Page.lockdown.bottom-bar-successful-clear');
        }
    };

    updateResponseBasedOnClearedScenarios = async (response, activeScenarios, useNewEmergencyEndpoints) => {
        if (useNewEmergencyEndpoints) {
            const clearAllEmergencyScenariosResponse = await this.clearAllEmergencyScenarios();
            const failedScenarios = clearAllEmergencyScenariosResponse.failedScenarios;
            if (
                clearAllEmergencyScenariosResponse.apiCallStatus !== 200 ||
                failedScenarios.length === activeScenarios.length
            ) {
                response.message = t('Page.emergency-scenarios.bottom-bar-fail-clear');
                response.isError = true;
            } else {
                response.atLeastOneLockdownCleared = true;
                if (failedScenarios.length === 0) {
                    response.message = t('Page.emergency-scenarios.bottom-bar-successful-clear');
                    response.isError = false;
                } else {
                    response.message = t('Page.emergency-scenarios.bottom-bar-could-not-clear', {
                        field: this.getFailedLockdownNames(failedScenarios, activeScenarios).join('","'),
                    });
                    response.isError = true;
                }
            }
        } else {
            const clearedScenarios = await this.getClearedLockdownsNew(activeScenarios);
            if (clearedScenarios.length === 0) {
                response.message = t('Page.emergency-scenarios.bottom-bar-fail-clear');
            } else if (some(clearedScenarios, ['didAllFail', true])) {
                const failedScenarios = clearedScenarios?.filter((clearedScenario) => clearedScenario.didAllFail);
                response.atLeastOneLockdownCleared = failedScenarios.length !== clearedScenarios.length;
                response.message = t('Page.emergency-scenarios.bottom-bar-could-not-clear', {
                    field: this.getFailedLockdownNames(failedScenarios, activeScenarios).join('","'),
                });
            } else {
                response.atLeastOneLockdownCleared = true;
                response.message = t('Page.emergency-scenarios.bottom-bar-successful-clear');
            }
        }
    };

    clearAllLockdowns = async () => {
        const response = { atLeastOneLockdownCleared: false, message: '' };

        try {
            const activeLockdownsResponse = await this.getLockdownActiveScenarios();
            const hardwiredLockdownsResponse = await this.getLockDownScenariosIncludingHardwiredLockdowns();
            const activeLockdownsIncludingHardwiredLockdowns = hardwiredLockdownsResponse.data?.filter(
                (lockdownScenario) => lockdownScenario.issued
            );

            const allActiveLockdowns = unionBy(
                activeLockdownsResponse.data,
                activeLockdownsIncludingHardwiredLockdowns,
                'id'
            );

            if (!this.thereAreActiveLockdownScenarios(allActiveLockdowns)) {
                response.message = t('Page.lockdown.bottom-bar-no-active-lockdowns-to-clear');
            } else {
                await this.updateResponseBasedOnClearedLockdowns(response, allActiveLockdowns);
            }
        } catch (e) {
            console.error(`Failed to clear all lockdowns`, e);

            response.message = t('Page.lockdown.bottom-bar-fail-clear');
        }

        return response;
    };

    clearAllEmergency = async (useNewEmergencyEndpoints) => {
        const responseObj = { atLeastOneLockdownCleared: false, message: '', isError: false };

        try {
            const queryParamsAllScenarios = {
                filter: ALL_SCENARIOS_FILTER_ENABLED,
            };

            const response = await lockdownApi.getAllActiveScenarios({ queryParams: queryParamsAllScenarios });

            const allActiveScenarios = unionBy(response?.data, 'id');

            if (!allActiveScenarios?.length) {
                responseObj.message = t('Page.emergency-scenarios.bottom-bar-no-active-emergency-scenarios-to-clear');
            } else {
                await this.updateResponseBasedOnClearedScenarios(
                    responseObj,
                    allActiveScenarios,
                    useNewEmergencyEndpoints
                );
            }
        } catch (e) {
            console.error(`Failed to clear all emergency scenarios`, e);

            responseObj.message = t('Page.lockdown.bottom-bar-fail-clear');
            responseObj.isError = true;
        }

        return responseObj;
    };

    rejectIfNecessary = (res, reject) => {
        const { status } = res;

        if (!res.ok) {
            if (status === 403) {
                redirect(APP_ACCESS_DENIED_URL);
            }
            reject(new Error(res.statusText));
            return false;
        }
        return true;
    };

    getPresignedParams(event, cameraId) {
        const precachedClip = event.enrichmentMetadata?.precachedClips?.find((clip) => clip.cameraId === cameraId);
        //can be different depending on whether event is new incoming or from gql listAccessEvents
        const precachedClipKey = precachedClip?.storedClipLocation?.key || precachedClip?.storedClipKey;
        const secActionId = `securityActionId=${PRECACHED_CLIP_SECURITY_ACTION_ID}`;
        return secActionId + (precachedClipKey ? `&s3Key=${precachedClipKey}` : '');
    }
}
