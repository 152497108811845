import React, { Suspense, lazy } from 'react';

const ConsentPage = lazy(() => import('./ConsentPage'));

const ConsentContainer = (props) => (
    <Suspense fallback={null}>
        <ConsentPage {...props} />
    </Suspense>
);

export default ConsentContainer;
