import { useState, useEffect } from 'react';

import { TextFilter } from '@brivo/react-components';
import { useQueryEditor } from '@/pages/NewReports/components/TableEditor/QueryEditorContext';
import { useTranslation } from 'react-i18next';
import { columnConfiguration } from './constants';
import { Filter } from '@/pages/NewReports/common/interfaces';
import { createFilterConfiguration } from '@/pages/NewReports/common/utils';

interface UserProps {
    disabled: boolean;
    filter: Filter;
}
// TODO: Discuss prepopulating columns and dimensions as it is User Activity Report
const User = ({ filter, disabled }: UserProps) => {
    const [text, setText] = useState<string>('');
    const [initialFilterApplied, setInitialFilterApplied] = useState(false);
    const QueryEditor: any = useQueryEditor();
    const { t } = useTranslation();

    useEffect(() => {
        if (filter) {
            const filterValue = (filter.test_values as any)[0].value;
            setText(filterValue);
            setInitialFilterApplied(true);
        }
    }, [filter]);

    const handleTextFilterChange = (event: any) => {
        const newText = event;

        const userTestValues = [
            {
                source: 'constant',
                type: 'string',
                value: newText,
            },
        ];

        const newFilter = createFilterConfiguration(columnConfiguration, userTestValues);

        if (newText === '') {
            QueryEditor.removeFilter(newFilter);
        } else {
            QueryEditor.addOrUpdateFilter(newFilter);
        }

        setText(newText);
    };

    return (
        <TextFilter
            key={`${initialFilterApplied}`}
            disabled={disabled}
            onValueChange={handleTextFilterChange}
            value={text}
            label={t('Page.new-reports.column-filter.user')}
            placeholder={t('Page.new-reports.column-filter.user.no-selection-label')}
            clearingValues={true}
        />
    );
};

export default User;
