export const MILLISECOND_PER_DAY = 24 * 3600 * 1000;
export const SEVEN_DAYS_IN_MS = 7 * MILLISECOND_PER_DAY;

export const VISUAL_TYPES = {
    sectionHeader: 'sectionHeader',
};

export const ALL_KPI_METRICS = [
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.unique-users',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.unique-users',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count_distinct',
                        alias: 'User Count',
                        uuid: 'count',
                        over: {
                            source: 'events',
                            id: 'actor_object_id',
                        },
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        right_value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                        left_value: {
                            source: 'constant',
                            type: 'integer',
                            value: 2004,
                        },
                    },
                ],
                sort: [
                    {
                        source: 'events',
                        id: 'occurred',
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'event_time',
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        alias: 'Site',
                        uuid: 'site_name',
                        source: 'sites',
                        id: 'site_name',
                    },
                    {
                        alias: 'First Name',
                        source: 'users',
                        id: 'first_name',
                        uuid: 'user_first_name',
                    },
                    {
                        alias: 'Last Name',
                        source: 'users',
                        id: 'last_name',
                        uuid: 'user_last_name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        right_value: {
                            source: 'constant',
                            type: 'integer',
                            value: 1,
                        },
                        left_value: {
                            source: 'window',
                            type: 'row_number',
                            over: {
                                source: 'window',
                                type: 'over',
                                partition: [
                                    {
                                        source: 'events',
                                        id: 'actor_object_id',
                                    },
                                ],
                                order: [
                                    {
                                        source: 'events',
                                        id: 'occurred',
                                    },
                                ],
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        right_value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                        left_value: {
                            source: 'constant',
                            type: 'integer',
                            value: 2004,
                        },
                    },
                ],
                sort: [
                    {
                        source: 'events',
                        id: 'occurred',
                    },
                ],
            },
        },
        chartletQuery: {
            query: {
                columns: [
                    {
                        source: 'transform',
                        type: 'extract_date_part',
                        granularity: {
                            source: 'constant',
                            value: {
                                source: 'ui_variables',
                                id: 'trendGranularity',
                            },
                            type: 'string',
                        },
                        alias: 'Date',
                        uuid: 'date',
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        source: 'aggregation',
                        type: 'count_distinct',
                        alias: 'User Count',
                        uuid: 'user_count',
                        over: {
                            source: 'events',
                            id: 'actor_object_id',
                        },
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'events',
                            id: 'occurred',
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_truncate',
                            granularity: {
                                source: 'constant',
                                value: {
                                    source: 'ui_variables',
                                    id: 'trendGranularity',
                                },
                                type: 'string',
                            },
                            value: {
                                source: 'transform',
                                type: 'date_add',
                                granularity: {
                                    source: 'constant',
                                    value: {
                                        source: 'ui_variables',
                                        id: 'trendGranularity',
                                    },
                                    type: 'string',
                                },
                                shift: {
                                    source: 'constant',
                                    value: {
                                        source: 'ui_variables',
                                        id: 'trendLookback',
                                    },
                                    type: 'integer',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        right_value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                        left_value: {
                            source: 'constant',
                            type: 'integer',
                            value: 2004,
                        },
                    },
                ],
                sort: [
                    {
                        source: 'columns',
                        alias: 'Date',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGranularity: 'month',
                trendLookback: -3,
                trendGreen: 'pos',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count_distinct',
                                    over: {
                                        source: 'events',
                                        id: 'actor_object_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    right_value: {
                                        source: 'events',
                                        id: 'event_type_id',
                                    },
                                    left_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 2004,
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.suspended-users',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.suspended-users',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'users',
                            id: 'first_name',
                        },
                        alias: 'Suspended User Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'not_null',
                        value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'deactivated',
                        alias: 'Date Suspended',
                        uuid: 'suspension_date',
                    },
                    {
                        source: 'users',
                        id: 'first_name',
                        alias: 'First Name',
                        uuid: 'first_name',
                    },
                    {
                        source: 'users',
                        id: 'last_name',
                        alias: 'Last Name',
                        uuid: 'last_name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'not_null',
                        value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
                trendValueType: 'integer',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'users',
                            id: 'first_name',
                        },
                        alias: 'Suspended User Count',
                        uuid: 'kpiTrendValue',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'not_null',
                        value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                    {
                        source: 'operation',
                        type: 'greater_equal',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'users',
                                    id: 'deactivated',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: 'month',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            value: {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'not_equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'users',
                                    id: 'deactivated',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.expired-credentials',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.expired-credentials',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'constant',
                            value: 1,
                            type: 'integer',
                        },
                        alias: 'Expired Credentials Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'credentials',
                        id: 'is_expired',
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Expiration Date',
                        uuid: 'expiration_date',
                        source: 'credentials',
                        id: 'expiration_date',
                    },
                    {
                        alias: 'Facility Code',
                        uuid: 'facility_code',
                        source: 'credentials',
                        id: 'facility_code',
                    },
                    {
                        alias: 'Format',
                        uuid: 'format',
                        source: 'credential_types',
                        id: 'credential_type',
                    },
                    {
                        alias: 'Last Used',
                        uuid: 'last_used_date',
                        source: 'credential_usage',
                        id: 'last_used',
                    },
                ],
                filters: [
                    {
                        source: 'credentials',
                        id: 'is_expired',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
                trendValueType: 'integer',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'credentials',
                            id: 'expiration_date',
                        },
                        alias: 'Expired Credentials Count',
                        uuid: 'kpiTrendValue',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater_equal',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'credentials',
                                    id: 'expiration_date',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: 'month',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            value: {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'not_equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'credentials',
                                    id: 'expiration_date',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.unassigned-credentials',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.unassigned-credentials',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'constant',
                            value: 1,
                            type: 'integer',
                        },
                        alias: 'Not Assigned Credentials Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'credentials',
                        id: 'not_assigned',
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Card Number',
                        uuid: 'card_number',
                        source: 'credentials',
                        id: 'card_number',
                    },
                    {
                        alias: 'Facility Code',
                        uuid: 'facility_code',
                        source: 'credentials',
                        id: 'facility_code',
                    },
                    {
                        alias: 'Format',
                        uuid: 'format',
                        source: 'credential_types',
                        id: 'credential_type',
                    },
                ],
                filters: [
                    {
                        source: 'credentials',
                        id: 'not_assigned',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
                trendValueType: 'integer',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'credentials',
                            id: 'not_assigned',
                        },
                        alias: 'Not Assigned Credentials Count',
                        uuid: 'kpiTrendValue',
                    },
                ],
                filters: [
                    {
                        source: 'credentials',
                        id: 'not_assigned',
                    },
                    {
                        source: 'operation',
                        type: 'greater_equal',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'credentials',
                                    id: 'created',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: 'month',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            value: {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'not_equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'credentials',
                                    id: 'created',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.total-events',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.total-events',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'events',
                            id: 'event_id',
                        },
                        alias: 'Total Event Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'event_time',
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        alias: 'Site',
                        uuid: 'event_site_name',
                        source: 'events',
                        id: 'event_site_name',
                    },
                    {
                        alias: 'Event Name',
                        source: 'event_types',
                        id: 'i18_event_type',
                        uuid: 'event_name',
                    },
                    {
                        alias: 'Device',
                        uuid: 'device_name',
                        source: 'events',
                        id: 'object_name',
                    },
                    {
                        alias: 'Name',
                        source: 'events',
                        uuid: 'event_user_name',
                        id: 'event_user_name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'events',
                        id: 'occurred',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'events',
                                        id: 'event_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.failed-access',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.failed-access',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'events',
                            id: 'event_id',
                        },
                        alias: 'Failed Access Event Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'include',
                        value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                        test_values: [
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5065',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5056',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5012',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5013',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5014',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5015',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5016',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5017',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5018',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5057',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5058',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5059',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5060',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5061',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5062',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5063',
                            },
                        ],
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'event_time',
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        alias: 'Site',
                        uuid: 'site_name',
                        source: 'events',
                        id: 'event_site_name',
                    },
                    {
                        alias: 'Event Name',
                        source: 'event_types',
                        id: 'i18_event_type',
                        uuid: 'event_name',
                    },
                    {
                        alias: 'Name',
                        source: 'events',
                        id: 'event_user_name',
                        uuid: 'user_full_name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'include',
                        value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                        test_values: [
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5065',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5056',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5012',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5013',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5014',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5015',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5016',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5017',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5018',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5057',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5058',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5059',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5060',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5061',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5062',
                            },
                            {
                                source: 'constant',
                                type: 'integer',
                                value: '5063',
                            },
                        ],
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'events',
                                        id: 'event_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                                {
                                    source: 'operation',
                                    type: 'include',
                                    value: {
                                        source: 'events',
                                        id: 'event_type_id',
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5065',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5056',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5012',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5013',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5014',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5015',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5016',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5017',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5018',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5057',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5058',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5059',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5060',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5061',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5062',
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: '5063',
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.critical-events',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.critical-events',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'events',
                            id: 'event_id',
                        },
                        alias: 'Event Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'security_action_group_actions',
                            id: 'security_action_group_id',
                        },
                        right_value: {
                            source: 'constant',
                            value: '2',
                            type: 'integer',
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'event_time',
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        alias: 'Site',
                        uuid: 'event_site_name',
                        source: 'events',
                        id: 'event_site_name',
                    },
                    {
                        alias: 'Event Name',
                        source: 'event_types',
                        id: 'i18_event_type',
                        uuid: 'event_name',
                    },
                    {
                        alias: 'Device',
                        uuid: 'device_name',
                        source: 'events',
                        id: 'object_name',
                    },
                    {
                        alias: 'Name',
                        source: 'events',
                        uuid: 'event_user_name',
                        id: 'event_user_name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'security_action_group_actions',
                            id: 'security_action_group_id',
                        },
                        right_value: {
                            source: 'constant',
                            value: 2,
                            type: 'integer',
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'events',
                                        id: 'event_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    left_value: {
                                        source: 'security_action_group_actions',
                                        id: 'security_action_group_id',
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: '2',
                                        type: 'integer',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.anomalies',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.anomalies',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'security_event_enhancement',
                            id: 'security_event_enhancement_id',
                        },
                        alias: 'Count Anomalies',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'security_event_enhancement',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'event_time',
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        alias: 'Site',
                        uuid: 'site_name',
                        source: 'events',
                        id: 'event_site_name',
                    },
                    {
                        alias: 'Event Name',
                        source: 'event_types',
                        id: 'i18_event_type',
                        uuid: 'event_name',
                    },
                    {
                        alias: 'Device',
                        uuid: 'device_name',
                        source: 'events',
                        id: 'object_name',
                    },
                    {
                        alias: 'First Name',
                        source: 'events',
                        id: 'event_user_name',
                        uuid: 'user_full_name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'not_null',
                        value: {
                            source: 'security_event_enhancement',
                            id: 'security_event_enhancement_id',
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'security_event_enhancement',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'neg',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'security_event_enhancement',
                                        id: 'security_event_enhancement_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'security_event_enhancement',
                                            id: 'occurred',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'security_event_enhancement',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'security_event_enhancement',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'security_event_enhancement',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.users-created',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.users-created',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'users',
                            id: 'first_name',
                        },
                        alias: 'User Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'users',
                                    id: 'created',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'created',
                        source: 'transform',
                        type: 'extract_time',
                        value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                    {
                        alias: 'First Name',
                        uuid: 'first_name',
                        source: 'users',
                        id: 'first_name',
                    },
                    {
                        alias: 'Last Name',
                        uuid: 'last_name',
                        source: 'users',
                        id: 'last_name',
                    },
                    {
                        alias: 'User Created Date',
                        uuid: 'user_created_date',
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'users',
                            id: 'created',
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_truncate',
                            granularity: {
                                source: 'constant',
                                value: 'month',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'users',
                        id: 'created',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'pos',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'users',
                                        id: 'user_object_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'users',
                                            id: 'created',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'users',
                                                id: 'created',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'users',
                                                id: 'created',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'users',
                                                id: 'created',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.users-deleted',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.users-deleted',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'events',
                            id: 'event_id',
                        },
                        alias: 'Deleted User Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'constant',
                            value: 3003,
                            type: 'integer',
                        },
                        right_value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                    },
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'occurred_time',
                        source: 'transform',
                        type: 'extract_time',
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        alias: 'Event Name',
                        source: 'event_types',
                        id: 'i18_event_type',
                        uuid: 'event_name',
                    },
                    {
                        source: 'events',
                        uuid: 'deleted_user_full_name',
                        id: 'object_name',
                        alias: 'Deleted User Name',
                    },
                    {
                        alias: 'User Deleted Date',
                        uuid: 'occurred_date',
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    {
                        source: 'admins',
                        uuid: 'admin_full_name',
                        id: 'full_name',
                        alias: 'Deleted By',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'constant',
                            value: 3003,
                            type: 'integer',
                        },
                        right_value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                    },
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'events',
                            id: 'occurred',
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_truncate',
                            granularity: {
                                source: 'constant',
                                value: 'month',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'events',
                        id: 'occurred',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'pos',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'events',
                                        id: 'event_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    left_value: {
                                        source: 'constant',
                                        value: 3003,
                                        type: 'integer',
                                    },
                                    right_value: {
                                        source: 'events',
                                        id: 'event_type_id',
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        height: 1,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.badges-printed',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.badges-printed',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'badge_print_job_item',
                            id: 'user_id',
                        },
                        alias: 'Badges Printed',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'equals',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'badge_print_job_item',
                                    id: 'time',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        alias: 'Event Time',
                        uuid: 'print_time',
                        source: 'transform',
                        type: 'extract_time',
                        value: {
                            source: 'badge_print_job_item',
                            id: 'time',
                        },
                    },
                    {
                        source: 'users',
                        id: 'first_name',
                        uuid: 'user_first_name',
                        alias: 'User First Name',
                    },
                    {
                        source: 'users',
                        id: 'last_name',
                        uuid: 'user_last_name',
                        alias: 'User Last Name',
                    },
                    {
                        alias: 'Event Date',
                        uuid: 'print_date',
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'badge_print_job_item',
                            id: 'time',
                        },
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'badge_print_job_item',
                                id: 'time',
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'date_add',
                                granularity: {
                                    source: 'constant',
                                    value: 'month',
                                    type: 'string',
                                },
                                shift: {
                                    source: 'constant',
                                    value: -1,
                                    type: 'integer',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'badge_print_job_item',
                        id: 'time',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'pos',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'number',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts_augmented',
                                                id: 'current_count',
                                            },
                                        },
                                        denominator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'operation',
                                                        type: 'subtract',
                                                        left_value: {
                                                            source: 'aggregation',
                                                            type: 'sum',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'daily_count_augmented',
                                                            },
                                                        },
                                                        right_value: {
                                                            source: 'aggregation',
                                                            type: 'max',
                                                            over: {
                                                                source: 'daily_counts_augmented',
                                                                id: 'current_count',
                                                            },
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'timezone_conversion',
                                                                timezone: {
                                                                    source: 'system',
                                                                    id: 'admin_timezone',
                                                                },
                                                                value: {
                                                                    source: 'database',
                                                                    id: 'current_timestamp',
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'number',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                        },
                        {
                            id: 'event_date',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count',
                                    over: {
                                        source: 'badge_print_job_item',
                                        id: 'user_id',
                                    },
                                },
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'badge_print_job_item',
                                            id: 'time',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                                {
                                    alias: 'Current Day Indicator',
                                    uuid: 'current_day_indicator',
                                    source: 'operation',
                                    type: 'not_equals',
                                    left_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'badge_print_job_item',
                                                id: 'time',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        uuid: 'event_date',
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater_equal',
                                    left_value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'badge_print_job_item',
                                                id: 'time',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: 'month',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'badge_print_job_item',
                                                id: 'time',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'daily_count_augmented',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'daily_count_augmented',
                            },
                        },
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'current_day_indicator',
                            type: 'boolean',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_day_indicator',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'number',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'daily_count_augmented',
                                    source: 'daily_counts',
                                    id: 'daily_count',
                                },
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'current_day_indicator',
                                    source: 'daily_counts',
                                    id: 'current_day_indicator',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'aggregation',
                                            type: 'max',
                                            over: {
                                                source: 'daily_counts',
                                                id: 'event_date',
                                            },
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'window',
                                        type: 'last_value',
                                        over: {
                                            source: 'window',
                                            type: 'over',
                                            partition: [
                                                {
                                                    source: 'null',
                                                },
                                            ],
                                            order: [
                                                {
                                                    source: 'daily_counts',
                                                    id: 'event_date',
                                                },
                                            ],
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'daily_count',
                                        },
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    uuid: 'current_count',
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
    {
        width: 3,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.total-employees',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.total-employees',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'users',
                            id: 'first_name',
                        },
                        alias: 'User Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'or',
                        left_value: {
                            source: 'operation',
                            type: 'greater',
                            right_value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                            left_value: {
                                source: 'users',
                                id: 'deactivated',
                            },
                        },
                        right_value: {
                            source: 'operation',
                            type: 'is_null',
                            value: {
                                source: 'users',
                                id: 'deactivated',
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'first_name',
                        alias: 'First Name',
                        uuid: 'first_name',
                    },
                    {
                        source: 'users',
                        id: 'last_name',
                        alias: 'Last Name',
                        uuid: 'last_name',
                    },
                    {
                        source: 'users',
                        id: 'created',
                        alias: 'Date Created',
                        uuid: 'created',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'or',
                        left_value: {
                            source: 'operation',
                            type: 'greater',
                            right_value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                            left_value: {
                                source: 'users',
                                id: 'deactivated',
                            },
                        },
                        right_value: {
                            source: 'operation',
                            type: 'is_null',
                            value: {
                                source: 'users',
                                id: 'deactivated',
                            },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'users',
                        id: 'first_name',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'pos',
                trendValueType: 'integer',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'users',
                            id: 'first_name',
                        },
                        uuid: 'kpiTrendValue',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'or',
                        left_value: {
                            source: 'operation',
                            type: 'greater',
                            right_value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                            left_value: {
                                source: 'users',
                                id: 'deactivated',
                            },
                        },
                        right_value: {
                            source: 'operation',
                            type: 'is_null',
                            value: {
                                source: 'users',
                                id: 'deactivated',
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'greater',
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            granularity: {
                                source: 'constant',
                                value: 'month',
                                type: 'string',
                            },
                            value: {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                },
                            },
                        },
                        left_value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                ],
            },
        },
    },
    {
        width: 3,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.employee-growth-rate',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.employee-growth-rate',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: {
                            source: 'users',
                            id: 'first_name',
                        },
                        alias: 'User Count',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'users',
                            id: 'created',
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_truncate',
                            granularity: {
                                source: 'constant',
                                value: 'year',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'first_name',
                        alias: 'First Name',
                        uuid: 'first_name',
                    },
                    {
                        source: 'users',
                        id: 'last_name',
                        alias: 'Last Name',
                        uuid: 'last_name',
                    },
                    {
                        source: 'users',
                        id: 'created',
                        alias: 'Created',
                        uuid: 'created',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'users',
                            id: 'created',
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_truncate',
                            granularity: {
                                source: 'constant',
                                value: 'year',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'users',
                        id: 'first_name',
                    },
                ],
            },
        },
        details: {
            dimensions: [
                {
                    id: 'first_name',
                    dataDefinition: {
                        dataType: 'string',
                    },
                    displayName: 'First Name',
                    sortable: true,
                },
                {
                    id: 'last_name',
                    dataDefinition: {
                        dataType: 'string',
                    },
                    displayName: 'Last Name',
                },
                {
                    id: 'created',
                    dataDefinition: {
                        dataType: 'timestamp_ntz',
                    },
                    displayName: 'Created',
                },
            ],
        },
    },
    {
        width: 3,
        type: 'kpiMetric',
        headerKey: 'Page.brivo-analytics.header.average-daily-attendance',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.average-daily-attendance',
        dimensions: [
            {
                id: 'count',
                dataDefinition: {
                    dataType: 'number',
                },
            },
        ],
        query: {
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'event_date',
                            type: 'timestamp_ntz',
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                            selectable: true,
                        },
                        {
                            id: 'visit_count',
                            type: 'integer',
                            expression: {
                                source: 'table',
                                id: 'visit_count',
                            },
                            selectable: true,
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                    },
                                },
                                {
                                    uuid: 'visit_count',
                                    source: 'aggregation',
                                    type: 'count_distinct',
                                    over: {
                                        source: 'events',
                                        id: 'actor_object_id',
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater',
                                    left_value: {
                                        source: 'columns',
                                        uuid: 'event_date',
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -1,
                                        },
                                        granularity: {
                                            source: 'constant',
                                            value: 'month',
                                            type: 'string',
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'include',
                                    value: {
                                        source: 'events',
                                        id: 'event_type_id',
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 2004,
                                        },
                                    ],
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            ],
            query: {
                columns: [
                    {
                        source: 'operation',
                        type: 'round',
                        alias: 'Attendance',
                        uuid: 'count',
                        value: {
                            source: 'operation',
                            type: 'divide',
                            numerator: {
                                source: 'aggregation',
                                type: 'sum',
                                over: {
                                    source: 'daily_counts',
                                    id: 'visit_count',
                                },
                            },
                            denominator: {
                                source: 'operation',
                                type: 'days_between',
                                start_date: {
                                    source: 'transform',
                                    type: 'date_add',
                                    granularity: 'month',
                                    shift: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: -1,
                                    },
                                    value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                        },
                                    },
                                },
                                end_date: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                },
                                include_weekends: false,
                            },
                        },
                    },
                ],
            },
        },
        detailsQuery: {
            query: {
                columns: [
                    {
                        uuid: 'event_date',
                        alias: 'Event Date',
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'system',
                                id: 'admin_timezone',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    {
                        uuid: 'visit_count',
                        alias: 'Attendance',
                        source: 'aggregation',
                        type: 'count_distinct',
                        over: {
                            source: 'events',
                            id: 'actor_object_id',
                        },
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'columns',
                            uuid: 'event_date',
                        },
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            granularity: {
                                source: 'constant',
                                value: 'month',
                                type: 'string',
                            },
                            value: {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'include',
                        value: {
                            source: 'events',
                            id: 'event_type_id',
                        },
                        test_values: [
                            {
                                source: 'constant',
                                type: 'integer',
                                value: 2004,
                            },
                        ],
                    },
                ],
                sort: [
                    {
                        source: 'columns',
                        uuid: 'event_date',
                    },
                ],
            },
        },
        uiVariables: {
            values: {
                trendGreen: 'pos',
            },
        },
        trendQuery: {
            query: {
                columns: [
                    {
                        uuid: 'kpiTrendValue',
                        source: 'operation',
                        type: 'round',
                        precision: {
                            source: 'constant',
                            value: 2,
                            type: 'number',
                        },
                        value: {
                            source: 'operation',
                            type: 'multiply',
                            arguments: [
                                {
                                    source: 'constant',
                                    value: 100,
                                    type: 'integer',
                                },
                                {
                                    source: 'operation',
                                    type: 'subtract',
                                    left_value: {
                                        source: 'operation',
                                        type: 'divide',
                                        numerator: {
                                            source: 'transform',
                                            type: 'coalesce',
                                            arguments: [
                                                {
                                                    source: 'operation',
                                                    type: 'divide',
                                                    numerator: {
                                                        source: 'aggregation',
                                                        type: 'sum',
                                                        over: {
                                                            source: 'daily_counts_augmented',
                                                            id: 'current_count',
                                                        },
                                                    },
                                                    denominator: {
                                                        source: 'operation',
                                                        type: 'days_between',
                                                        start_date: {
                                                            source: 'transform',
                                                            type: 'date_add',
                                                            granularity: 'month',
                                                            shift: {
                                                                source: 'constant',
                                                                type: 'integer',
                                                                value: -1,
                                                            },
                                                            value: {
                                                                source: 'transform',
                                                                type: 'extract_date',
                                                                value: {
                                                                    source: 'transform',
                                                                    type: 'timezone_conversion',
                                                                    timezone: {
                                                                        source: 'system',
                                                                        id: 'admin_timezone',
                                                                    },
                                                                    value: {
                                                                        source: 'database',
                                                                        id: 'current_timestamp',
                                                                    },
                                                                },
                                                            },
                                                        },
                                                        end_date: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                        include_weekends: false,
                                                    },
                                                },
                                                {
                                                    source: 'constant',
                                                    type: 'integer',
                                                    value: 0,
                                                },
                                            ],
                                        },
                                        denominator: {
                                            source: 'operation',
                                            type: 'divide',
                                            numerator: {
                                                source: 'aggregation',
                                                type: 'sum',
                                                over: {
                                                    source: 'daily_counts_augmented',
                                                    id: 'previous_month_counts',
                                                },
                                            },
                                            denominator: {
                                                source: 'operation',
                                                type: 'days_between',
                                                start_date: {
                                                    source: 'transform',
                                                    type: 'date_add',
                                                    granularity: 'month',
                                                    shift: {
                                                        source: 'constant',
                                                        type: 'integer',
                                                        value: -2,
                                                    },
                                                    value: {
                                                        source: 'transform',
                                                        type: 'extract_date',
                                                        value: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                    },
                                                },
                                                end_date: {
                                                    source: 'transform',
                                                    type: 'date_add',
                                                    granularity: 'month',
                                                    shift: {
                                                        source: 'constant',
                                                        type: 'integer',
                                                        value: -1,
                                                    },
                                                    value: {
                                                        source: 'transform',
                                                        type: 'extract_date',
                                                        value: {
                                                            source: 'transform',
                                                            type: 'timezone_conversion',
                                                            timezone: {
                                                                source: 'system',
                                                                id: 'admin_timezone',
                                                            },
                                                            value: {
                                                                source: 'database',
                                                                id: 'current_timestamp',
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    right_value: {
                                        source: 'constant',
                                        value: 1,
                                        type: 'integer',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
            datasets: [
                {
                    id: 'daily_counts',
                    selectable: true,
                    columns: [
                        {
                            id: 'event_date',
                            type: 'date',
                            expression: {
                                source: 'table',
                                id: 'event_date',
                            },
                            selectable: true,
                        },
                        {
                            id: 'daily_count',
                            type: 'integer',
                            expression: {
                                source: 'table',
                                id: 'daily_count',
                            },
                            selectable: true,
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'event_date',
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                    },
                                },
                                {
                                    uuid: 'daily_count',
                                    source: 'aggregation',
                                    type: 'count_distinct',
                                    over: {
                                        source: 'events',
                                        id: 'actor_object_id',
                                    },
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'greater',
                                    left_value: {
                                        source: 'columns',
                                        uuid: 'event_date',
                                    },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -2,
                                        },
                                        granularity: {
                                            source: 'constant',
                                            value: 'month',
                                            type: 'string',
                                        },
                                        value: {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                timezone: {
                                                    source: 'system',
                                                    id: 'admin_timezone',
                                                },
                                                value: {
                                                    source: 'database',
                                                    id: 'current_timestamp',
                                                },
                                            },
                                        },
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'include',
                                    value: {
                                        source: 'events',
                                        id: 'event_type_id',
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 2004,
                                        },
                                    ],
                                },
                                {
                                    source: 'operation',
                                    type: 'not_include',
                                    value: {
                                        source: 'transform',
                                        type: 'extract_weekday_num',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            value: {
                                                source: 'events',
                                                id: 'occurred',
                                            },
                                            timezone: {
                                                source: 'system',
                                                id: 'admin_timezone',
                                            },
                                        },
                                    },
                                    test_values: [
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 6,
                                        },
                                        {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 7,
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'daily_counts_augmented',
                    selectable: true,
                    columns: [
                        {
                            id: 'event_date_augmented',
                            type: 'date',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_date_augmented',
                            },
                        },
                        {
                            id: 'previous_month_counts',
                            type: 'integer',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'previous_month_counts',
                            },
                        },
                        {
                            id: 'current_count',
                            type: 'integer',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'current_count',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    uuid: 'event_date_augmented',
                                    source: 'daily_counts',
                                    id: 'event_date',
                                },
                                {
                                    uuid: 'previous_month_counts',
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'between',
                                        lower_bound: {
                                            source: 'transform',
                                            type: 'date_add',
                                            shift: {
                                                source: 'constant',
                                                type: 'integer',
                                                value: -2,
                                            },
                                            granularity: {
                                                source: 'constant',
                                                value: 'month',
                                                type: 'string',
                                            },
                                            value: {
                                                source: 'transform',
                                                type: 'extract_date',
                                                value: {
                                                    source: 'transform',
                                                    type: 'timezone_conversion',
                                                    timezone: {
                                                        source: 'system',
                                                        id: 'admin_timezone',
                                                    },
                                                    value: {
                                                        source: 'database',
                                                        id: 'current_timestamp',
                                                    },
                                                },
                                            },
                                        },
                                        upper_bound: {
                                            source: 'transform',
                                            type: 'date_add',
                                            shift: {
                                                source: 'constant',
                                                type: 'integer',
                                                value: -1,
                                            },
                                            granularity: {
                                                source: 'constant',
                                                value: 'month',
                                                type: 'string',
                                            },
                                            value: {
                                                source: 'transform',
                                                type: 'extract_date',
                                                value: {
                                                    source: 'transform',
                                                    type: 'timezone_conversion',
                                                    timezone: {
                                                        source: 'system',
                                                        id: 'admin_timezone',
                                                    },
                                                    value: {
                                                        source: 'database',
                                                        id: 'current_timestamp',
                                                    },
                                                },
                                            },
                                        },
                                        value: {
                                            source: 'daily_counts',
                                            id: 'event_date',
                                        },
                                    },
                                    pass_value: {
                                        source: 'daily_counts',
                                        id: 'daily_count',
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                },
                                {
                                    uuid: 'current_count',
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'greater',
                                        left_value: {
                                            source: 'daily_counts',
                                            id: 'event_date',
                                        },
                                        right_value: {
                                            source: 'transform',
                                            type: 'date_add',
                                            shift: {
                                                source: 'constant',
                                                type: 'integer',
                                                value: -1,
                                            },
                                            granularity: {
                                                source: 'constant',
                                                value: 'month',
                                                type: 'string',
                                            },
                                            value: {
                                                source: 'transform',
                                                type: 'extract_date',
                                                value: {
                                                    source: 'transform',
                                                    type: 'timezone_conversion',
                                                    timezone: {
                                                        source: 'system',
                                                        id: 'admin_timezone',
                                                    },
                                                    value: {
                                                        source: 'database',
                                                        id: 'current_timestamp',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    pass_value: {
                                        source: 'daily_counts',
                                        id: 'daily_count',
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                },
                            ],
                        },
                    },
                },
            ],
        },
    },
];

export const ALL_DASHBOARD_TABLES = [
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.average-attendance-by-day-of-week',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.average-attendance-by-day-of-week',
        dimensions: [
            {
                id: 'event_date',
                uuid: 'weekday_num',
                dataDefinition: { dataType: 'string' },
                transformationExpression: {
                    source: 'transform',
                    type: 'extract_weekday_num',
                    value: { source: 'query', id: 'event_date' },
                    uuid: 'weekday_num',
                    alias: 'Weekday Number',
                },
                sort: { direction: 'ASC' },
                designation: 'sorting',
                displayName: 'Weekday Number',
            },
            {
                id: 'event_date',
                uuid: 'weekday',
                dataDefinition: { dataType: 'string' },
                transformationExpression: {
                    source: 'transform',
                    type: 'extract_weekday_short',
                    value: { source: 'query', id: 'event_date' },
                    uuid: 'weekday',
                    alias: 'Day of Week',
                },
                designation: 'column',
                displayName: 'Day of Week',
            },
            {
                id: 'visit_count',
                uuid: 'attendance',
                dataDefinition: { dataType: 'number' },
                transformationExpression: {
                    source: 'operation',
                    type: 'round',
                    value: {
                        source: 'aggregation',
                        type: 'average',
                        over: { source: 'query', id: 'visit_count' },
                    },
                    alias: 'Attendance',
                    uuid: 'attendance',
                },
                designation: 'column',
                displayName: 'Attendance',
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        uuid: 'event_date',
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'system',
                                id: 'admin_timezone',
                            },
                            value: { source: 'events', id: 'occurred' },
                        },
                        alias: 'Event Date',
                    },
                    {
                        uuid: 'visit_count',
                        source: 'aggregation',
                        type: 'count_distinct',
                        over: { source: 'events', id: 'actor_object_id' },
                        alias: 'Event Count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: { source: 'columns', uuid: 'event_date' },
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -90,
                            },
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            value: {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'system',
                                        id: 'admin_timezone',
                                    },
                                    value: { source: 'database', id: 'current_timestamp' },
                                },
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'include',
                        value: { source: 'events', id: 'event_type_id' },
                        test_values: [{ source: 'constant', type: 'integer', value: 2004 }],
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.active-administrators-this-week',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.active-administrators-this-week',
        dimensions: [
            {
                id: 'admin_full_name',
                dataDefinition: { dataType: 'string' },
                displayName: 'User',
            },
            {
                id: 'count',
                dataDefinition: { dataType: 'integer' },
                displayName: 'Actions Performed',
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        alias: 'User',
                        uuid: 'admin_full_name',
                        source: 'admins',
                        id: 'full_name',
                    },
                    {
                        source: 'aggregation',
                        type: 'count',
                        over: { source: 'events', id: 'event_id' },
                        alias: 'Actions Performed',
                        uuid: 'count',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater_equal',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: { source: 'events', id: 'occurred' },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'date_add',
                                granularity: {
                                    source: 'constant',
                                    value: 'day',
                                    type: 'string',
                                },
                                shift: {
                                    source: 'constant',
                                    value: -7,
                                    type: 'integer',
                                },
                                value: { source: 'database', id: 'current_timestamp' },
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.users-and-groups',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.users-and-groups',
        dimensions: [
            {
                id: 'user_full_name',
                displayName: 'User',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'user_group_name',
                displayName: 'Group',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'user_group_created',
                displayName: 'Group Created',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'user_enabled',
                displayName: 'User Enabled',
                dataDefinition: { dataType: 'boolean' },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'full_name',
                        uuid: 'user_full_name',
                        alias: 'User',
                    },
                    {
                        source: 'user_groups',
                        id: 'user_group_name',
                        uuid: 'user_group_name',
                        alias: 'Group',
                    },
                    {
                        source: 'user_groups',
                        id: 'created',
                        uuid: 'user_group_created',
                        alias: 'Group Created',
                    },
                    {
                        source: 'operation',
                        type: 'is_null',
                        value: { source: 'users', id: 'deactivated' },
                        uuid: 'user_enabled',
                        alias: 'User Enabled',
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.doors-and-sites',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.doors-and-sites',
        dimensions: [
            {
                id: 'site_name',
                displayName: 'Site',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'device_name',
                displayName: 'Device',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'panel_name',
                displayName: 'Panel',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'panel_serial_number',
                displayName: 'Panel Serial Number',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_city',
                displayName: 'City',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_state',
                displayName: 'State',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_zip',
                displayName: 'Zip Code',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_country',
                displayName: 'Country',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_latitude',
                displayName: 'Latitude',
                dataDefinition: { dataType: 'number' },
            },
            {
                id: 'site_address_longitude',
                displayName: 'Longitude',
                dataDefinition: { dataType: 'number' },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'sites',
                        id: 'site_name',
                        uuid: 'site_name',
                        alias: 'Site',
                    },
                    {
                        source: 'devices',
                        id: 'device_name',
                        uuid: 'device_name',
                        alias: 'Device',
                    },
                    {
                        source: 'panels',
                        id: 'panel_name',
                        uuid: 'panel_name',
                        alias: 'Panel',
                    },
                    {
                        source: 'panels',
                        id: 'serial_number',
                        uuid: 'panel_serial_number',
                        alias: 'Panel Serial Number',
                    },
                    {
                        source: 'site_address',
                        id: 'city',
                        uuid: 'site_address_city',
                        alias: 'City',
                    },
                    {
                        source: 'site_address',
                        id: 'state',
                        uuid: 'site_address_state',
                        alias: 'State',
                    },
                    {
                        source: 'site_address',
                        id: 'zipcode',
                        uuid: 'site_address_zip',
                        alias: 'Zip Code',
                    },
                    {
                        source: 'site_address',
                        id: 'country',
                        uuid: 'site_address_country',
                        alias: 'Country',
                    },
                    {
                        source: 'site_address_geolocation',
                        id: 'latitude',
                        uuid: 'site_address_latitude',
                        alias: 'Latitude',
                    },
                    {
                        source: 'site_address_geolocation',
                        id: 'longitude',
                        uuid: 'site_address_longitude',
                        alias: 'Longitude',
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.administrators-and-roles',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.administrators-and-roles',
        dimensions: [
            {
                id: 'admin_name',
                displayName: 'Name',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'admin_email',
                displayName: 'Email',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'admin_timezone',
                displayName: 'Timezone',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'admin_craeted',
                displayName: 'Created',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'admin_last_login_time',
                displayName: 'Last Login Time',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'admin_role',
                displayName: 'Role',
                dataDefinition: { dataType: 'string' },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'admins',
                        id: 'full_name',
                        uuid: 'admin_name',
                        alias: 'Name',
                    },
                    {
                        source: 'transform',
                        type: 'coalesce',
                        arguments: [
                            { source: 'admin_email', id: 'email' },
                            { source: 'admin_identity', id: 'email' },
                        ],
                        uuid: 'admin_email',
                        alias: 'Email',
                    },
                    {
                        source: 'admins',
                        id: 'timezone',
                        uuid: 'admin_timezone',
                        alias: 'Timezone',
                    },
                    {
                        source: 'admins',
                        id: 'created',
                        uuid: 'admin_craeted',
                        alias: 'Created',
                    },
                    {
                        source: 'transform',
                        type: 'coalesce',
                        arguments: [
                            { source: 'admins', id: 'last_login_time' },
                            { source: 'app_user', id: 'last_login_time' },
                        ],
                        uuid: 'admin_last_login_time',
                        alias: 'Last Login Time',
                    },
                    {
                        source: 'admin_roles',
                        id: 'role_name',
                        uuid: 'admin_role',
                        alias: 'Role',
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.credentials',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.credentials',
        dimensions: [
            {
                id: 'user_full_name',
                displayName: 'User',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'credential_type',
                displayName: 'Credential Type',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'credential_card_number',
                displayName: 'Card Number',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'credential_enablement_date',
                displayName: 'Enabled On',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'credential_expiration_date',
                displayName: 'Expires After',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'last_used_date',
                displayName: 'Last Used Date',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'credential_valid',
                displayName: 'Credential Valid',
                dataDefinition: { dataType: 'boolean' },
            },
            {
                id: 'user_enabled',
                displayName: 'User Enabled',
                dataDefinition: { dataType: 'boolean' },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'full_name',
                        uuid: 'user_full_name',
                        alias: 'User',
                    },
                    {
                        source: 'credential_types',
                        id: 'credential_type',
                        uuid: 'credential_type',
                        alias: 'Credential Type',
                    },
                    {
                        source: 'credentials',
                        id: 'card_number',
                        uuid: 'credential_card_number',
                        alias: 'Card Number',
                    },
                    {
                        source: 'credentials',
                        id: 'enablement_date',
                        uuid: 'credential_enablement_date',
                        alias: 'Enabled On',
                    },
                    {
                        source: 'credentials',
                        id: 'expiration_date',
                        uuid: 'credential_expiration_date',
                        alias: 'Expires After',
                    },
                    {
                        source: 'credential_usage',
                        id: 'last_used',
                        uuid: 'last_used_date',
                        alias: 'Last Used Date',
                    },
                    {
                        source: 'operation',
                        type: 'not',
                        value: { source: 'credentials', id: 'is_expired' },
                        uuid: 'credential_valid',
                        alias: 'Credential Valid',
                    },
                    {
                        source: 'operation',
                        type: 'or',
                        left_value: {
                            source: 'operation',
                            type: 'greater',
                            left_value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                            right_value: { source: 'users', id: 'deactivated' },
                        },
                        right_value: {
                            source: 'operation',
                            type: 'is_null',
                            value: { source: 'users', id: 'deactivated' },
                        },
                        uuid: 'user_enabled',
                        alias: 'User Enabled',
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.activity',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.activity',
        dimensions: [
            {
                id: 'user_full_name',
                displayName: 'User',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'device_name',
                displayName: 'Door',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_name',
                displayName: 'Site',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_city',
                displayName: 'City',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_state',
                displayName: 'State',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_zip',
                displayName: 'Zip Code',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_country',
                displayName: 'Country',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'site_address_latitude',
                displayName: 'Latitude',
                dataDefinition: { dataType: 'number' },
            },
            {
                id: 'site_address_longitude',
                displayName: 'Longitude',
                dataDefinition: { dataType: 'number' },
            },
            {
                id: 'event_type_name',
                displayName: 'Event Name',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'event_type',
                displayName: 'Event Type',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'event_time_utc',
                displayName: 'Event Time (UTC)',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'event_time_local',
                displayName: 'Event Time (Local)',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'credential_card_number',
                displayName: 'Card Number',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'credential_type',
                displayName: 'Credential Type',
                dataDefinition: { dataType: 'string' },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'full_name',
                        uuid: 'user_full_name',
                        alias: 'User',
                    },
                    {
                        source: 'devices',
                        id: 'device_name',
                        uuid: 'device_name',
                        alias: 'Door',
                    },
                    {
                        source: 'sites',
                        id: 'site_name',
                        uuid: 'site_name',
                        alias: 'Site',
                    },
                    {
                        source: 'event_site_address',
                        id: 'city',
                        uuid: 'site_address_city',
                        alias: 'City',
                    },
                    {
                        source: 'event_site_address',
                        id: 'state',
                        uuid: 'site_address_state',
                        alias: 'State',
                    },
                    {
                        source: 'event_site_address',
                        id: 'zipcode',
                        uuid: 'site_address_zip',
                        alias: 'Zip Code',
                    },
                    {
                        source: 'event_site_address',
                        id: 'country',
                        uuid: 'site_address_country',
                        alias: 'Country',
                    },
                    {
                        source: 'event_site_address_geolocation',
                        id: 'latitude',
                        uuid: 'site_address_latitude',
                        alias: 'Latitude',
                    },
                    {
                        source: 'event_site_address_geolocation',
                        id: 'longitude',
                        uuid: 'site_address_longitude',
                        alias: 'Longitude',
                    },
                    {
                        source: 'event_types',
                        id: 'i18_event_type',
                        uuid: 'event_type_name',
                        alias: 'Event Name',
                    },
                    {
                        source: 'events',
                        id: 'event_type',
                        uuid: 'event_type',
                        alias: 'Event Type',
                    },
                    {
                        source: 'events',
                        id: 'occurred',
                        uuid: 'event_time_utc',
                        alias: 'Event Time (UTC)',
                    },
                    {
                        alias: 'Event Time (Local)',
                        uuid: 'event_time_local',
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: { source: 'panels', id: 'timezone' },
                        value: { source: 'events', id: 'occurred' },
                        external_reference_id: 'event_time_local',
                    },
                    {
                        source: 'event_credentials',
                        id: 'card_number',
                        uuid: 'credential_card_number',
                        alias: 'Card Number',
                    },
                    {
                        source: 'event_credential_types',
                        id: 'credential_type',
                        uuid: 'credential_type',
                        alias: 'Credential Type',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'include',
                        value: { source: 'events', id: 'event_type' },
                        test_values: [
                            {
                                source: 'constant',
                                type: 'string',
                                value: 'PANEL_EVENT',
                            },
                            {
                                source: 'constant',
                                type: 'string',
                                value: 'CAMERA_EVENT',
                            },
                        ],
                    },
                    {
                        source: 'operation',
                        type: 'greater',
                        left_value: { source: 'events', id: 'occurred' },
                        right_value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                type: 'string',
                                value: 'month',
                            },
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -3,
                            },
                            value: { source: 'database', id: 'current_timestamp' },
                        },
                    },
                ],
                sort: [
                    {
                        source: 'columns',
                        uuid: 'event_time_utc',
                        direction: 'DESC',
                    },
                ],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'Page.brivo-analytics.header.occupancy-and-usage',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.occupancy-and-usage',
        dimensions: [
            {
                id: 'event_time_utc',
                displayName: 'Event Time (UTC)',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'site_name',
                displayName: 'Site',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'device_name',
                displayName: 'Door',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'user_full_name',
                displayName: 'User',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'event_time_local',
                displayName: 'Event Time (Local)',
                dataDefinition: { dataType: 'timestamp_ntz' },
            },
            {
                id: 'timezone',
                displayName: 'Timezone',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'normal_hours',
                displayName: 'Normal Hours',
                dataDefinition: { dataType: 'boolean' },
            },
            {
                id: 'occupancy_timeout',
                displayName: 'Occupancy Timeout',
                dataDefinition: { dataType: 'number' },
            },
            {
                id: 'event_order',
                displayName: 'Event Order',
                dataDefinition: { dataType: 'number' },
            },
            {
                id: 'event_order_reversed',
                displayName: 'Event Order Reversed',
                dataDefinition: { dataType: 'number' },
            },
            {
                id: 'event_type',
                displayName: 'Event Type',
                dataDefinition: { dataType: 'string' },
            },
            {
                id: 'first_event',
                displayName: 'First Event',
                dataDefinition: { dataType: 'boolean' },
            },
            {
                id: 'last_event',
                displayName: 'Last Event',
                dataDefinition: { dataType: 'boolean' },
            },
            {
                id: 'occupancy',
                displayName: 'Occupancy',
                dataDefinition: { dataType: 'number' },
            },
        ],
        query: {
            queryId: '1',
            datasets: [
                {
                    id: 'occupancy_event_data',
                    selectable: true,
                    columns: [
                        {
                            id: 'event_time_utc',
                            type: 'timestamp_ntz',
                            selectable: true,
                            expression: { source: 'table', id: 'event_time_utc' },
                        },
                        {
                            id: 'site_object_id',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'site_object_id' },
                        },
                        {
                            id: 'site_name',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'site_name' },
                        },
                        {
                            id: 'device_name',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'device_name' },
                        },
                        {
                            id: 'user_full_name',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'user_full_name' },
                        },
                        {
                            id: 'event_time_local',
                            type: 'timestamp_ntz',
                            selectable: true,
                            expression: { source: 'table', id: 'event_time_local' },
                        },
                        {
                            id: 'timezone',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'timezone' },
                        },
                        {
                            id: 'normal_hours',
                            type: 'boolean',
                            selectable: true,
                            expression: { source: 'table', id: 'normal_hours' },
                        },
                        {
                            id: 'occupancy_timeout',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'occupancy_timeout' },
                        },
                        {
                            id: 'event_order',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'event_order' },
                        },
                        {
                            id: 'event_order_reversed',
                            type: 'integer',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_order_reversed',
                            },
                        },
                    ],
                    source: {
                        query: {
                            columns: [
                                {
                                    alias: 'Event Time (UTC)',
                                    uuid: 'event_time_utc',
                                    source: 'events',
                                    id: 'occurred',
                                    external_reference_id: 'event_time_utc',
                                },
                                {
                                    alias: 'Site Id',
                                    uuid: 'site_object_id',
                                    source: 'sites',
                                    id: 'site_object_id',
                                    external_reference_id: 'site_object_id',
                                },
                                {
                                    alias: 'Site',
                                    uuid: 'site_name',
                                    source: 'sites',
                                    id: 'site_name',
                                    external_reference_id: 'site_name',
                                },
                                {
                                    alias: 'Door',
                                    uuid: 'device_name',
                                    source: 'devices',
                                    id: 'device_name',
                                    external_reference_id: 'device_name',
                                },
                                {
                                    alias: 'User',
                                    uuid: 'user_full_name',
                                    source: 'users',
                                    id: 'full_name',
                                    external_reference_id: 'user_full_name',
                                },
                                {
                                    alias: 'Event Time (Local)',
                                    uuid: 'event_time_local',
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: { source: 'panels', id: 'timezone' },
                                    value: { source: 'events', id: 'occurred' },
                                    external_reference_id: 'event_time_local',
                                },
                                {
                                    alias: 'Timezone',
                                    uuid: 'timezone',
                                    source: 'panels',
                                    id: 'timezone',
                                    external_reference_id: 'timezone',
                                },
                                {
                                    alias: 'Normal Hours',
                                    uuid: 'normal_hours',
                                    source: 'operation',
                                    type: 'between',
                                    lower_bound: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 8,
                                    },
                                    upper_bound: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 17,
                                    },
                                    value: {
                                        source: 'transform',
                                        type: 'extract_hour',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            timezone: { source: 'panels', id: 'timezone' },
                                            value: { source: 'events', id: 'occurred' },
                                        },
                                    },
                                    external_reference_id: 'normal_hours',
                                },
                                {
                                    alias: 'Occupancy Timeout',
                                    uuid: 'occupancy_timeout',
                                    source: 'transform',
                                    type: 'coalesce',
                                    arguments: [
                                        {
                                            source: 'occupancy_configuration',
                                            id: 'occupant_timeout',
                                        },
                                        { source: 'constant', type: 'integer', value: 4 },
                                    ],
                                    external_reference_id: 'occupancy_timeout',
                                },
                                {
                                    alias: 'Event Order',
                                    uuid: 'event_order',
                                    source: 'window',
                                    type: 'row_number',
                                    over: {
                                        source: 'window',
                                        type: 'over',
                                        partition: [
                                            {
                                                source: 'transform',
                                                type: 'extract_date',
                                                value: {
                                                    source: 'transform',
                                                    type: 'timezone_conversion',
                                                    timezone: {
                                                        source: 'panels',
                                                        id: 'timezone',
                                                    },
                                                    value: { source: 'events', id: 'occurred' },
                                                },
                                            },
                                            { source: 'events', id: 'actor_object_id' },
                                            { source: 'events', id: 'site_object_id' },
                                        ],
                                        order: [
                                            {
                                                source: 'events',
                                                id: 'occurred',
                                                direction: 'ASC',
                                            },
                                        ],
                                    },
                                    external_reference_id: 'event_order',
                                },
                                {
                                    alias: 'Event Order Reversed',
                                    uuid: 'event_order_reversed',
                                    source: 'window',
                                    type: 'row_number',
                                    over: {
                                        source: 'window',
                                        type: 'over',
                                        partition: [
                                            {
                                                source: 'transform',
                                                type: 'extract_date',
                                                value: {
                                                    source: 'transform',
                                                    type: 'timezone_conversion',
                                                    timezone: {
                                                        source: 'panels',
                                                        id: 'timezone',
                                                    },
                                                    value: { source: 'events', id: 'occurred' },
                                                },
                                            },
                                            { source: 'events', id: 'actor_object_id' },
                                            { source: 'events', id: 'site_object_id' },
                                        ],
                                        order: [
                                            {
                                                source: 'events',
                                                id: 'occurred',
                                                direction: 'DESC',
                                            },
                                        ],
                                    },
                                    external_reference_id: 'event_order_reversed',
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    left_value: { source: 'events', id: 'event_type_id' },
                                    right_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 2004,
                                    },
                                },
                                {
                                    source: 'operation',
                                    type: 'greater',
                                    left_value: { source: 'events', id: 'occurred' },
                                    right_value: {
                                        source: 'transform',
                                        type: 'date_add',
                                        granularity: {
                                            source: 'constant',
                                            type: 'string',
                                            value: 'month',
                                        },
                                        shift: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: -3,
                                        },
                                        value: {
                                            source: 'database',
                                            id: 'current_timestamp',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                {
                    id: 'augmented_occupancy_event_data',
                    selectable: true,
                    columns: [
                        {
                            id: 'event_time_utc',
                            type: 'timestamp_ntz',
                            selectable: true,
                            expression: { source: 'table', id: 'event_time_utc' },
                        },
                        {
                            id: 'site_object_id',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'site_object_id' },
                        },
                        {
                            id: 'site_name',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'site_name' },
                        },
                        {
                            id: 'device_name',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'device_name' },
                        },
                        {
                            id: 'user_full_name',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'user_full_name' },
                        },
                        {
                            id: 'event_time_local',
                            type: 'timestamp_ntz',
                            selectable: true,
                            expression: { source: 'table', id: 'event_time_local' },
                        },
                        {
                            id: 'timezone',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'timezone' },
                        },
                        {
                            id: 'normal_hours',
                            type: 'boolean',
                            selectable: true,
                            expression: { source: 'table', id: 'normal_hours' },
                        },
                        {
                            id: 'occupancy_timeout',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'occupancy_timeout' },
                        },
                        {
                            id: 'event_order',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'event_order' },
                        },
                        {
                            id: 'event_order_reversed',
                            type: 'integer',
                            selectable: true,
                            expression: {
                                source: 'table',
                                id: 'event_order_reversed',
                            },
                        },
                        {
                            id: 'event_type',
                            type: 'string',
                            selectable: true,
                            expression: { source: 'table', id: 'event_type' },
                        },
                        {
                            id: 'first_event',
                            type: 'boolean',
                            selectable: true,
                            expression: { source: 'table', id: 'first_event' },
                        },
                        {
                            id: 'last_event',
                            type: 'boolean',
                            selectable: true,
                            expression: { source: 'table', id: 'last_event' },
                        },
                        {
                            id: 'occupancy_factor',
                            type: 'integer',
                            selectable: true,
                            expression: { source: 'table', id: 'occupancy_factor' },
                        },
                    ],
                    source: {
                        query: [
                            {
                                columns: [
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'event_time_utc',
                                        alias: 'Event Time (UTC)',
                                        uuid: 'event_time_utc',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'site_object_id',
                                        alias: 'Site Id',
                                        uuid: 'site_object_id',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'site_name',
                                        alias: 'Site',
                                        uuid: 'site_name',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'device_name',
                                        alias: 'Door',
                                        uuid: 'device_name',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'user_full_name',
                                        alias: 'User',
                                        uuid: 'user_full_name',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'event_time_local',
                                        alias: 'Event Time (Local)',
                                        uuid: 'event_time_local',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'timezone',
                                        alias: 'Timezone',
                                        uuid: 'timezone',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'normal_hours',
                                        alias: 'Normal Hours',
                                        uuid: 'normal_hours',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'occupancy_timeout',
                                        alias: 'Occupancy Timeout',
                                        uuid: 'occupancy_timeout',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'event_order',
                                        alias: 'Event Order',
                                        uuid: 'event_order',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'event_order_reversed',
                                        alias: 'Event Order Reversed',
                                        uuid: 'event_order_reversed',
                                    },
                                    {
                                        source: 'constant',
                                        type: 'string',
                                        value: 'Open',
                                        alias: 'Event Type',
                                        uuid: 'event_type',
                                    },
                                    {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'occupancy_event_data',
                                            id: 'event_order',
                                        },
                                        right_value: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 1,
                                        },
                                        alias: 'First Event',
                                        uuid: 'first_event',
                                    },
                                    {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'occupancy_event_data',
                                            id: 'event_order_reversed',
                                        },
                                        right_value: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 1,
                                        },
                                        alias: 'Last Event',
                                        uuid: 'last_event',
                                    },
                                    {
                                        source: 'operation',
                                        type: 'iff',
                                        condition: {
                                            source: 'operation',
                                            type: 'equals',
                                            left_value: {
                                                source: 'occupancy_event_data',
                                                id: 'event_order',
                                            },
                                            right_value: {
                                                source: 'constant',
                                                type: 'integer',
                                                value: 1,
                                            },
                                        },
                                        pass_value: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 1,
                                        },
                                        fail_value: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 0,
                                        },
                                        alias: 'occupancy_factor',
                                        uuid: 'occupancy_factor',
                                    },
                                ],
                            },
                            {
                                columns: [
                                    {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        timezone: {
                                            source: 'constant',
                                            type: 'string',
                                            value: 'UTC',
                                        },
                                        from_timezone: {
                                            source: 'occupancy_event_data',
                                            id: 'timezone',
                                        },
                                        value: {
                                            source: 'operation',
                                            type: 'least',
                                            arguments: [
                                                {
                                                    source: 'transform',
                                                    type: 'date_add',
                                                    granularity: {
                                                        source: 'constant',
                                                        type: 'string',
                                                        value: 'minute',
                                                    },
                                                    shift: {
                                                        source: 'constant',
                                                        type: 'integer',
                                                        value: 1439,
                                                    },
                                                    value: {
                                                        source: 'transform',
                                                        type: 'date_truncate',
                                                        granularity: {
                                                            source: 'constant',
                                                            type: 'string',
                                                            value: 'day',
                                                        },
                                                        value: {
                                                            source: 'occupancy_event_data',
                                                            id: 'event_time_local',
                                                        },
                                                    },
                                                },
                                                {
                                                    source: 'transform',
                                                    type: 'date_add',
                                                    granularity: {
                                                        source: 'constant',
                                                        type: 'string',
                                                        value: 'hour',
                                                    },
                                                    shift: {
                                                        source: 'occupancy_event_data',
                                                        id: 'occupancy_timeout',
                                                    },
                                                    value: {
                                                        source: 'occupancy_event_data',
                                                        id: 'event_time_local',
                                                    },
                                                },
                                            ],
                                        },
                                        alias: 'Event Time (UTC)',
                                        uuid: 'event_time_utc',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'site_object_id',
                                        alias: 'Site Id',
                                        uuid: 'site_object_id',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'site_name',
                                        alias: 'Site',
                                        uuid: 'site_name',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'device_name',
                                        alias: 'Door',
                                        uuid: 'device_name',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'user_full_name',
                                        alias: 'User',
                                        uuid: 'user_full_name',
                                    },
                                    {
                                        source: 'operation',
                                        type: 'least',
                                        arguments: [
                                            {
                                                source: 'transform',
                                                type: 'date_add',
                                                granularity: {
                                                    source: 'constant',
                                                    type: 'string',
                                                    value: 'minute',
                                                },
                                                shift: {
                                                    source: 'constant',
                                                    type: 'integer',
                                                    value: 1439,
                                                },
                                                value: {
                                                    source: 'transform',
                                                    type: 'date_truncate',
                                                    granularity: {
                                                        source: 'constant',
                                                        type: 'string',
                                                        value: 'day',
                                                    },
                                                    value: {
                                                        source: 'occupancy_event_data',
                                                        id: 'event_time_local',
                                                    },
                                                },
                                            },
                                            {
                                                source: 'transform',
                                                type: 'date_add',
                                                granularity: {
                                                    source: 'constant',
                                                    type: 'string',
                                                    value: 'hour',
                                                },
                                                shift: {
                                                    source: 'occupancy_event_data',
                                                    id: 'occupancy_timeout',
                                                },
                                                value: {
                                                    source: 'occupancy_event_data',
                                                    id: 'event_time_local',
                                                },
                                            },
                                        ],
                                        alias: 'Event Time (Local)',
                                        uuid: 'event_time_local',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'timezone',
                                        alias: 'Timezone',
                                        uuid: 'timezone',
                                    },
                                    {
                                        source: 'null',
                                        alias: 'Normal Hours',
                                        uuid: 'normal_hours',
                                    },
                                    {
                                        source: 'occupancy_event_data',
                                        id: 'occupancy_timeout',
                                        alias: 'Occupancy Timeout',
                                        uuid: 'occupancy_timeout',
                                    },
                                    {
                                        source: 'null',
                                        alias: 'Event Order',
                                        uuid: 'event_order',
                                    },
                                    {
                                        source: 'null',
                                        alias: 'Event Order Reversed',
                                        uuid: 'event_order_reversed',
                                    },
                                    {
                                        source: 'constant',
                                        type: 'string',
                                        value: 'Tentative Departure',
                                        alias: 'Event Type',
                                        uuid: 'event_type',
                                    },
                                    {
                                        source: 'null',
                                        alias: 'First Event',
                                        uuid: 'first_event',
                                    },
                                    {
                                        source: 'null',
                                        alias: 'Last Event',
                                        uuid: 'last_event',
                                    },
                                    {
                                        source: 'constant',
                                        type: 'integer',
                                        value: -1,
                                        alias: 'occupancy_factor',
                                        uuid: 'occupancy_factor',
                                    },
                                ],
                                filters: [
                                    {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'occupancy_event_data',
                                            id: 'event_order_reversed',
                                        },
                                        right_value: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 1,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            query: {
                columns: [
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'event_time_utc',
                        alias: 'Event Time (UTC)',
                        uuid: 'event_time_utc',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'site_name',
                        alias: 'Site',
                        uuid: 'site_name',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'device_name',
                        alias: 'Door',
                        uuid: 'device_name',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'user_full_name',
                        alias: 'User',
                        uuid: 'user_full_name',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'event_time_local',
                        alias: 'Event Time (Local)',
                        uuid: 'event_time_local',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'timezone',
                        alias: 'Timezone',
                        uuid: 'timezone',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'normal_hours',
                        alias: 'Normal Hours',
                        uuid: 'normal_hours',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'occupancy_timeout',
                        alias: 'Occupancy Timeout',
                        uuid: 'occupancy_timeout',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'event_order',
                        alias: 'Event Order',
                        uuid: 'event_order',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'event_order_reversed',
                        alias: 'Event Order Reversed',
                        uuid: 'event_order_reversed',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'event_type',
                        alias: 'Event Type',
                        uuid: 'event_type',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'first_event',
                        alias: 'First Event',
                        uuid: 'first_event',
                    },
                    {
                        source: 'augmented_occupancy_event_data',
                        id: 'last_event',
                        alias: 'Last Event',
                        uuid: 'last_event',
                    },
                    {
                        source: 'window',
                        type: 'sum',
                        over: {
                            source: 'window',
                            type: 'over',
                            partition: [
                                {
                                    source: 'augmented_occupancy_event_data',
                                    id: 'site_object_id',
                                },
                                {
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'augmented_occupancy_event_data',
                                        id: 'event_time_local',
                                    },
                                },
                            ],
                            order: [
                                {
                                    source: 'augmented_occupancy_event_data',
                                    id: 'event_time_utc',
                                },
                            ],
                            frame: ['-Inf', 0],
                        },
                        value: {
                            source: 'augmented_occupancy_event_data',
                            id: 'occupancy_factor',
                        },
                        alias: 'Occupancy',
                        uuid: 'occupancy',
                    },
                ],
                sort: [
                    {
                        source: 'columns',
                        uuid: 'event_time_utc',
                        direction: 'DESC',
                    },
                ],
            },
        },
    },
];

export const ALL_DASHBOARD_PIVOT_TABLES = [
    {
        width: 12,
        height: 1,
        type: 'pivotTable',
        header: 'Administrator Actions by Day',
        dimensions: [
            {
                uuid: 'd7f6340d-f271-4487-8c46-61ec2c043429',
                id: 'timestamp_id',
                displayName: 'Timestamp (UTC)',
                designation: 'column',
                level: 0,
                dataDefinition: {
                    dataType: 'date',
                },
                sort: 'desc',
            },
            {
                uuid: 'a5929060-3ca3-4338-a0e0-6e8db73a16cf',
                id: 'event_name_id',
                displayName: 'Event Name',
                designation: 'metric',
                level: 0,
                dataDefinition: {
                    dataType: 'string',
                },
                transformationExpression: {
                    alias: 'Event Name',
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'query',
                        id: 'event_name_id',
                    },
                },
            },
            {
                uuid: '4ea432b9-50a8-472d-9fc6-dfcdd6a97838',
                id: 'event_name_id',
                displayName: 'Event Name',
                designation: 'row',
                level: 0,
                dataDefinition: {
                    dataType: 'string',
                },
            },
            {
                uuid: '55b25295-0c9c-40b2-9b93-880433298a86',
                id: 'administrator_id',
                displayName: 'Administrator',
                designation: 'row',
                level: 1,
                dataDefinition: {
                    dataType: 'string',
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'admins',
                        id: 'full_name',
                        alias: 'Administrator',
                        uuid: 'administrator_id',
                    },
                    {
                        source: 'transform',
                        alias: 'Timestamp (UTC)',
                        uuid: 'timestamp_id',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                            timezone: {
                                source: 'system',
                                id: 'admin_timezone',
                            },
                        },
                        type: 'extract_date',
                    },
                    {
                        source: 'event_types',
                        id: 'i18_event_type',
                        alias: 'Event Name',
                        uuid: 'event_name_id',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'greater_equal',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'columns',
                                    uuid: 'timestamp_id',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'date_add',
                                granularity: {
                                    source: 'constant',
                                    value: 'day',
                                    type: 'string',
                                },
                                shift: {
                                    source: 'constant',
                                    value: -7,
                                    type: 'integer',
                                },
                                value: {
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'database',
                                            id: 'current_timestamp',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                            },
                        },
                    },
                ],
                sort: [],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'pivotTable',
        header: 'Daily Active Users By Site',
        dimensions: [
            {
                id: 'site',
                level: 0,
                displayName: 'Site',
                designation: 'column',
                dataDefinition: {
                    dataType: 'string',
                },
            },
            {
                id: 'user_unique_id',
                displayName: 'User Count',
                designation: 'metric',
                level: 0,
                dataDefinition: {
                    dataType: 'string',
                },
                transformationExpression: {
                    alias: 'User Last Name',
                    source: 'aggregation',
                    type: 'count_distinct',
                    over: {
                        source: 'query',
                        id: 'user_unique_id',
                    },
                },
            },
            {
                id: 'timestamp_id',
                displayName: 'Timestamp (UTC)',
                designation: 'row',
                level: 0,
                dataDefinition: {
                    dataType: 'date',
                },
                sort: 'desc',
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'sites',
                        id: 'site_name',
                        uuid: 'site',
                        alias: 'Site',
                    },
                    {
                        source: 'events',
                        id: 'actor_object_id',
                        uuid: 'user_unique_id',
                        alias: 'User ID',
                    },
                    {
                        source: 'users',
                        id: 'full_name',
                        alias: 'User',
                    },
                    {
                        source: 'transform',
                        alias: 'Timestamp (UTC)',
                        uuid: 'timestamp_id',
                        value: {
                            source: 'transform',
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'system',
                                id: 'admin_timezone',
                            },
                        },
                        type: 'extract_date',
                    },
                    {
                        source: 'event_types',
                        id: 'i18_event_type',
                        alias: 'Event Name',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'include',
                        value: {
                            source: 'event_types',
                            id: 'event_type_id',
                        },
                        test_values: [
                            {
                                source: 'constant',
                                type: 'integer',
                                value: 2004,
                            },
                        ],
                    },
                    {
                        source: 'operation',
                        type: 'greater_equal',
                        left_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                value: {
                                    source: 'columns',
                                    uuid: 'timestamp_id',
                                },
                                timezone: {
                                    source: 'system',
                                    id: 'admin_timezone',
                                },
                            },
                        },
                        right_value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'date_add',
                                granularity: {
                                    source: 'constant',
                                    value: 'month',
                                    type: 'string',
                                },
                                shift: {
                                    source: 'constant',
                                    value: -1,
                                    type: 'integer',
                                },
                                value: {
                                    source: 'transform',
                                    type: 'extract_date',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        value: {
                                            source: 'database',
                                            id: 'current_timestamp',
                                        },
                                        timezone: {
                                            source: 'system',
                                            id: 'admin_timezone',
                                        },
                                    },
                                },
                            },
                        },
                    },
                ],
                sort: [],
            },
        },
    },
    {
        width: 12,
        height: 1,
        type: 'pivotTable',
        header: 'Who Has Access',
        headerKey: 'Page.brivo-analytics.header.who-has-access',
        headerTooltipKey: 'Page.brivo-analytics.header-tooltip.who-has-access',
        dimensions: [
            {
                id: 'user_id',
                level: 0,
                displayName: 'User',
                designation: 'row',
                dataDefinition: {
                    dataType: 'string',
                },
            },
            {
                id: 'door_id',
                level: 0,
                displayName: 'Door',
                designation: 'column',
                dataDefinition: {
                    dataType: 'string',
                },
            },
            {
                id: 'door_id',
                displayName: 'User',
                designation: 'metric',
                level: 0,
                dataDefinition: {
                    dataType: 'string',
                },
                transformationExpression: {
                    alias: 'User',
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'query',
                        id: 'door_id',
                    },
                },
            },
        ],
        query: {
            query: {
                columns: [
                    {
                        source: 'users',
                        id: 'full_name',
                        uuid: 'user_id',
                        alias: 'User',
                        all: true,
                    },
                    {
                        source: 'devices',
                        id: 'device_name',
                        uuid: 'door_id',
                        alias: 'Door',
                    },
                ],
                filters: [
                    {
                        source: 'operation',
                        type: 'or',
                        left_value: {
                            source: 'operation',
                            type: 'include',
                            value: {
                                source: 'device_types',
                                id: 'device_type_id',
                            },
                            test_values: [
                                {
                                    source: 'constant',
                                    type: 'integer',
                                    value: 1,
                                },
                            ],
                        },
                        right_value: {
                            source: 'operation',
                            type: 'is_null',
                            value: {
                                source: 'device_types',
                                id: 'device_type_id',
                            },
                        },
                    },
                    {
                        source: 'operation',
                        type: 'or',
                        left_value: {
                            source: 'operation',
                            type: 'include',
                            value: {
                                source: 'object_permission',
                                id: 'security_action_id',
                            },
                            test_values: [
                                {
                                    source: 'constant',
                                    type: 'integer',
                                    value: 2004,
                                },
                            ],
                        },
                        right_value: {
                            source: 'operation',
                            type: 'is_null',
                            value: {
                                source: 'object_permission',
                                id: 'security_action_id',
                            },
                        },
                    },
                ],
            },
        },
    },
];
