import { Column, Filter } from './interfaces';

export const transformLabelToId = (label: string): string => {
    return label?.toLowerCase().replaceAll(' ', '-');
};

export const createFilterConfiguration = (column: Column, testValues: any): Filter => {
    const filter = {
        source: 'operation',
        type: 'include',
        value: {
            source: 'columns',
            uuid: column.uuid,
        },
        test_values: testValues,
    };
    return filter;
};
