import React from 'react';
import { makeStyles } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip } from '@brivo/react-components';

const useStyles = makeStyles(() => ({
    content: {
        margin: '15px 0',
    },
    table: {
        height: '180px !important',
        overflow: 'hidden',
    },
    itemName: {
        maxWidth: 165,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const ItemsList = ({ allItems }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const createCell = (className, content) => {
        const item = <span className={className}>{content}</span>;
        return content.length > 20 ? (
            <Tooltip title={content} placement="top" size="medium">
                {item}
            </Tooltip>
        ) : (
            item
        );
    };
    const columns = [
        {
            Header: t('Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column1'),
            id: 'name',
            Cell: (context) => {
                return createCell(classes.itemName, context.row.original.name);
            },
        },
        {
            Header: t('Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.triggers.header.column3'),
            id: 'site',
            Cell: (context) => {
                return createCell(classes.itemName, context.row.original.siteName);
            },
        },
    ];

    return (
        <div className={classes.content}>
            <Table
                data={allItems}
                columns={columns}
                tableContainerProps={{
                    'data-test-id': 'table-container',
                    className: classes.table,
                }}
                heightFunction={() => {
                    return 180;
                }}
                hasClickableRows={false}
                clickToSortMessage={t('Table.column.header.sort')}
            />
        </div>
    );
};

export default ItemsList;
