import { useEffect, useState } from 'react';
import { useFlagClient } from '@brivo/onairplus-services';
import { lockdownApi } from '../../../common/webApis';
import { getAllDoors } from '../../../common/webApis/lockdown/helpers';

const DEFAULT_PAGE_SIZE = 100;

const useGetLockdownData = ({ id, isCreateMode }) => {
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios', false);
    const [offset, setOffset] = useState(0);
    const [scenario, setScenario] = useState(null);
    const [assignedTriggers, setAssignedTriggers] = useState(null);
    const [assignedDoors, setAssignedDoors] = useState([]);
    const [loadingData, setLoading] = useState(true);
    const [areDoorsLoading, setDoorsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [count, setDoorsCount] = useState(0);

    const reloadScenario = async (id) => {
        setScenario(null);
        setLoading(true);
        setOffset(0);
        setDoorsLoading(false);
        setDoorsCount(0);
        setError(false);

        setAssignedDoors([]);
        setAssignedTriggers(null);

        const scenario = await lockdownApi.getLockdownScenarioById(id);
        setScenario(scenario);
        const response = await lockdownApi.getTriggersByLockdownId(id);
        setAssignedTriggers(response.getTriggersByLockdownId);
    };

    useEffect(() => {
        async function getScenarioById() {
            try {
                const scenario = await lockdownApi.getLockdownScenarioById(id);
                setScenario(scenario);
            } catch (err) {
                setError(true);
                setLoading(false);
            }
        }

        async function getTriggersByLockdownId() {
            try {
                const response = await lockdownApi.getTriggersByLockdownId(id);
                setAssignedTriggers(response.getTriggersByLockdownId);
            } catch (err) {
                setError(true);
                setLoading(false);
            }
        }

        if (emergencyScenariosFlag) {
            setScenario(null);
            setError(false);

            if (isCreateMode) {
                setScenario({
                    name: '',
                    isDefault: false,
                });

                setAssignedDoors([]);
                setAssignedTriggers([]);
                setLoading(false);
            } else {
                setLoading(true);

                if (id) {
                    getScenarioById();
                    getTriggersByLockdownId();
                }
            }
        }
    }, [id, emergencyScenariosFlag, isCreateMode]);

    useEffect(() => {
        const areDoorsLoaded = assignedDoors.length || !!scenario?.isNotValidScenarioWarning;
        if (assignedTriggers && areDoorsLoaded && !isCreateMode) {
            setLoading(false);
        }
    }, [assignedTriggers, assignedDoors, isCreateMode, scenario]);

    useEffect(() => {
        async function getDoorsByIds(deviceIds) {
            const response = await getAllDoors(DEFAULT_PAGE_SIZE, offset, 'name', 'ASC', [
                { field: 'id', type: 'field', value: deviceIds.toString() },
                { field: 'scenarioTypeId', type: 'field', value: scenario?.threatLevelTypeId?.toString() },
            ]);
            setAssignedDoors((doors) => doors.concat(response.lockdownDoors));
            setOffset((offset) => offset + DEFAULT_PAGE_SIZE);
            setDoorsLoading(false);
            setDoorsCount(response.count);
            setLoading(false);
        }
        if (loadingData && !areDoorsLoading && scenario?.deviceIds?.length && count >= offset) {
            setDoorsLoading(true);
            getDoorsByIds(scenario.deviceIds);
        }
    }, [offset, areDoorsLoading, count, scenario, loadingData]);

    return [scenario, assignedTriggers, assignedDoors, reloadScenario, loadingData, error];
};
export default useGetLockdownData;
