import { makeStyles } from '@brivo/react-components';

export default makeStyles((theme) => ({
    landingMessageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
    },
    personalisedMessage: {
        fontSize: '24px',
        lineHeight: '31.2px',
        color: theme.palette.brivoAliases.inputTextColor,
        margin: theme.spacing(2, 0),
        textAlign: 'center',
    },
    errorBoundary: {
        '& .u-fx.u-fx-column.u-fx-align-center.u-fx-justify-center': {
            margin: 'auto',
        },
    },
    cameraLayoutList: {
        '& #CameraLayoutFilter-select': {
            width: '100%',
        },
    },
}));
