import React, { Suspense, lazy } from 'react';

const GatewayDetailsPage = lazy(() => import('./GatewayDetailsPage'));

const GatewayDetailsContainer = (props) => (
    <Suspense fallback={null}>
        <GatewayDetailsPage {...props} />
    </Suspense>
);

export default GatewayDetailsContainer;
