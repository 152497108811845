import React, { Suspense, lazy } from 'react';

const CredentialManagement = lazy(() => import('./CredentialManagement'));

const CredentialManagementContainer = (props) => (
    <Suspense fallback={null}>
        <CredentialManagement {...props} />
    </Suspense>
);

export default CredentialManagementContainer;
