import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../common/components/PageHeader/PageHeader';

import {
    ArrowBack,
    Button,
    LoadingIndicator,
    ErrorDialog,
    LockdwonRoundedIcon,
    MuiAvatar,
    makeStyles,
    Snackbar,
    SideNav,
} from '@brivo/react-components';
import { checkPermissions } from '@brivo/onairplus-services';

import { UserContext } from '@common/user/UserProvider';

import { getDifferences, getErrorMessages, triggersMapper } from '../helpers';

import { LOCKDOWN_DELETE_LOCKDOWN_SCENARIO } from '../../../../common/permissions/Permissions';
import * as _ from 'lodash';
import { lockdownApi } from '../../../../common/webApis';
import LockdownScenarioDetailsContent from './LockdownScenarioDetailsContent';
import useOnUnmount from '../../hooks/useOnUnmount';

const LockdownScenarioDetails = ({
    scenario,
    isCreateMode,
    isLoading,
    assignedTriggers,
    assignedDoors,
    reloadScenario,
    reloadList,
    accountHasDefaultScenario,
    isActiveScenario,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const notifications = Snackbar();
    const { permissions } = useContext(UserContext);

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({
        showSuccess: false,
        showError: false,
        errorMsg: '',
    });
    const isMounted = useRef(false);
    const [confirmationDialogData, setConfirmationDialogData] = useState({
        open: false,
    });
    const reloadLockdownsListCallback = useCallback(reloadList, [reloadList]);

    const formattedTriggers = assignedTriggers?.map((item) => triggersMapper(item));

    const initialValues = {
        id: scenario?.id,
        name: scenario?.name || '',
        isDefaultScenario: scenario?.isDefault,
        selectedTriggers: formattedTriggers,
        selectedDoors: assignedDoors,
    };

    useEffect(() => {
        if (isMounted.current && _.values(errors).every(_.isEmpty)) {
            setSaveStatus({ showSuccess: false, showError: false, errorMsg: '' });
        } else {
            isMounted.current = true;
        }
    }, [errors]);

    useOnUnmount(() => {
        if (reloadLockdownsListCallback) {
            reloadLockdownsListCallback();
        }
    }, [reloadLockdownsListCallback]);

    const createScenario = async ({ triggerIds, doorIds, isDefault, name }, setSubmitting) => {
        let result;

        try {
            result = await lockdownApi.createLockdownScenarioWithTriggers({
                triggerSourceOids: triggerIds,
                doorOids: doorIds,
                isDefaultLockdown: isDefault,
                lockdownName: name,
            });

            if (result.createLockdownScenarioWithTriggers) {
                setSubmitting(false);
                setSaveStatus({
                    showSuccess: true,
                    showError: false,
                    errorMsg: '',
                });

                history.push({
                    pathname: `/lockdown-scenarios/${result.createLockdownScenarioWithTriggers.id}`,
                });
            }
        } catch (error) {
            console.warn('Error on create', error);
            setSaveStatus({
                showSuccess: false,
                showError: true,
                errorMsg: t('Page.lockdown-scenarios.lockdown-scenario-details.create-error.explanation'),
            });

            setSubmitting(false);
        }
    };

    const saveScenario = async ({ id, triggerIds, doorIds, isDefault, name }, setSubmitting, resetForm) => {
        let result;
        try {
            result = await lockdownApi.updateLockdownScenarioWithTriggers({
                lockdownOid: id,
                triggerSourceOids: triggerIds,
                doorOids: doorIds,
                isDefaultLockdown: isDefault,
                lockdownName: name,
            });

            if (result.updateLockdownScenarioWithTriggers) {
                setSubmitting(false);
                resetForm();
                reloadScenario(id);
                setSaveStatus({ showSuccess: true, showError: false, errorMsg: '', chipText: '' });
            } else {
                setSaveStatus({
                    showSuccess: false,
                    showError: true,
                    errorMsg: t('Page.lockdown-scenarios.lockdown-scenario-details.save-error.explanation'),
                });
                setSubmitting(false);
            }
        } catch (error) {
            console.warn('Error on submit', error);
            setSaveStatus({
                showSuccess: false,
                showError: true,
                errorMsg: t('Page.lockdown-scenarios.lockdown-scenario-details.save-error.explanation'),
            });
            setSubmitting(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        setSaveStatus({
            showSuccess: false,
            showError: false,
            errorMsg: '',
        });

        const changes = getDifferences(values, initialValues);
        const errorMessages = getErrorMessages(values, t);

        if (!_.isEmpty(errorMessages)) {
            setErrors(errorMessages);
            setSaveStatus({
                showSuccess: false,
                showError: true,
                errorMsg: t('Page.lockdown-scenarios.lockdown-scenario-details.save-error.general-message.explanation'),
            });
            return;
        }

        if (isCreateMode) {
            const triggerIds = values.selectedTriggers?.map((trigger) => trigger.id);
            const doorIds = values.selectedDoors?.map((door) => door.id);
            const isDefault = values.isDefaultScenario;
            const name = values.name;
            await createScenario({ triggerIds, doorIds, isDefault, name }, setSubmitting, resetForm);
        } else {
            const id = values.id;
            const triggerIds = changes.selectedTriggers?.map((trigger) => trigger.id);
            const doorIds = changes.selectedDoors?.map((door) => door.id);
            const isDefault = changes.isDefaultScenario;
            const name = changes.name;
            await saveScenario({ id, triggerIds, doorIds, isDefault, name }, setSubmitting, resetForm);
        }
    };

    const handleGoBack = () => {
        history.push(`/lockdown-scenarios`);
        reloadList();
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialogData({ open: false });
    };

    const handleDeleteLockdownScenario = () => {
        setConfirmationDialogData({
            title: t('Page.lockdown-scenarios.lockdown-scenario-details.dialog.delete.title'),
            btnText: t('Page.lockdown-scenarios.lockdown-scenario-details.dialog.delete.button'),
            open: true,
            text: t('Page.lockdown-scenarios.lockdown-scenario-details.dialog.delete.text', {
                scenarioName: scenario?.name,
            }),
            handleYes: deleteScenario,
            primaryDisabled: false,
        });
        setSaveStatus({
            showSuccess: false,
            showError: false,
            errorMsg: '',
        });
    };

    const deleteScenario = async () => {
        try {
            await lockdownApi.deleteLockdownScenario(scenario.id);
            history.push(`/lockdown-scenarios`);
            notifications.addSuccessMessage({
                text: t('Page.lockdown-scenarios.lockdown-scenario-details.delete-success.message', {
                    scenarioName: scenario?.name,
                }),
            });

            if (reloadList) {
                reloadList();
            }
        } catch (e) {
            closeConfirmationDialog();
            setSaveStatus({
                showSuccess: false,
                showError: true,
                errorMsg: t('Page.lockdown-scenarios.lockdown-scenario-details.delete-error.message'),
            });
        }
    };

    const actionItems = [
        {
            permissions: [LOCKDOWN_DELETE_LOCKDOWN_SCENARIO],
            buttonId: 'lockdown-scenario-delete',
            buttonTitle: t('Page.lockdown-scenarios.lockdown-scenario-details.page.sideNav.action.delete-scenario'),
            onClick: handleDeleteLockdownScenario,
            color: 'secundary',
        },
    ].filter((actionItem) =>
        checkPermissions({
            userPermissions: permissions,
            anyOfPermissions: actionItem.permissions,
        })
    );

    const showDeleteButton = !isCreateMode && !isActiveScenario;

    return (
        <>
            <div className="u-mb-half u-pt-full u-pb-full">
                <Button
                    id="back-to-lockdown-scenarios"
                    size="small"
                    type="tertiary"
                    onClick={handleGoBack}
                    startIcon={<ArrowBack />}
                    text={t('Page.lockdown-scenarios.lockdown-scenario-details.page.back')}
                />
            </div>
            <PageHeader title={t('Page.lockdown-scenarios.lockdown-scenario-details.page.title')} />
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ isSubmitting, dirty, submitForm, resetForm, setFieldValue, values, touched, setTouched }) => (
                        <>
                            {isCreateMode ? (
                                <LockdownScenarioDetailsContent
                                    saveStatus={saveStatus}
                                    scenario={scenario}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    accountHasDefaultScenario={accountHasDefaultScenario}
                                    dirty={dirty}
                                    isCreateMode={isCreateMode}
                                    isSubmitting={isSubmitting}
                                    submitForm={submitForm}
                                    resetForm={resetForm}
                                    setSaveStatus={setSaveStatus}
                                    errors={errors}
                                    setErrors={setErrors}
                                    touched={touched}
                                    setTouched={setTouched}
                                    initialValues={initialValues}
                                    assignedTriggers={formattedTriggers}
                                    isActiveScenario={isActiveScenario}
                                />
                            ) : (
                                <SideNav
                                    avatar={<LockdownAvatar />}
                                    headerTitle={t(
                                        'Page.lockdown-scenarios.lockdown-scenario-details.page.sideNav.title'
                                    )}
                                    headerBody={values.name}
                                    actionItems={showDeleteButton && actionItems}
                                >
                                    <LockdownScenarioDetailsContent
                                        saveStatus={saveStatus}
                                        setSaveStatus={setSaveStatus}
                                        scenario={scenario}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        accountHasDefaultScenario={accountHasDefaultScenario}
                                        dirty={dirty}
                                        isCreateMode={isCreateMode}
                                        isSubmitting={isSubmitting}
                                        submitForm={submitForm}
                                        resetForm={resetForm}
                                        setErrors={setErrors}
                                        errors={errors}
                                        initialValues={initialValues}
                                        assignedTriggers={formattedTriggers}
                                        isActiveScenario={isActiveScenario}
                                    />
                                </SideNav>
                            )}

                            <ErrorDialog
                                title={confirmationDialogData.title}
                                text={confirmationDialogData.text}
                                open={confirmationDialogData.open}
                                primaryDisabled={confirmationDialogData.primaryDisabled}
                                primaryIcon={
                                    confirmationDialogData.primaryDisabled ? (
                                        <LoadingIndicator size="small" shouldHavePadding={false} />
                                    ) : null
                                }
                                primaryActionText={confirmationDialogData.btnText}
                                onPrimaryClick={confirmationDialogData.handleYes}
                                secondaryActionText={t('Page.custom-field-details.sidebar.dialog.secondary-text')}
                                onSecondaryClick={closeConfirmationDialog}
                                onClose={closeConfirmationDialog}
                            />
                        </>
                    )}
                </Formik>
            )}
        </>
    );
};

export default LockdownScenarioDetails;

const useStyles = makeStyles((theme) => ({
    avatar: {
        color: theme.palette.brivoAliases.avatarTextColor,
        backgroundColor: theme.palette.brivoAliases.avatarBackgroundColor,
    },
}));

const LockdownAvatar = () => {
    const classes = useStyles();
    return (
        <MuiAvatar className={classes.avatar} alt={`Lockdown Scenario`}>
            <LockdwonRoundedIcon className={classes.icon} />
        </MuiAvatar>
    );
};
