import { ALL_CAMERAS } from '../../hooks/useLayoutStateV2';

const cmType = 'EAGLE_EYE_CAMERA_MANAGER';
export const eenV3Type = 'EAGLE_EYE_V3';

export const fetchCookie = async (eenV3Url, authToken) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
        credentials: 'include',
    };

    const sessionUrl = `https:${
        eenV3Url.replace(/(https:\/\/)([^.]*)(.*)/, '$1api$3').split(':')[1]
    }/api/v3.0/media/session`;

    try {
        const cookieUrlResponse = await fetch(sessionUrl, requestOptions);
        const { url } = await cookieUrlResponse.json();
        if (url) {
            return fetch(url, requestOptions);
        }
    } catch (error) {
        console.error('cookie error', error);
        return false;
    }
};

export const parseCameraUrls = (cameraData, name) => {
    if (name === ALL_CAMERAS) {
        cameraData.videoProviderType = cameraData.videoProvider?.type;
    }

    if (cameraData.videoProviderType?.includes(cmType)) {
        return parseCameraManagerUrls(cameraData.liveViewUrl);
    } else {
        return parseEagleEyeUrls(cameraData.liveViewUrl);
    }
};

export const parseCameraManagerUrls = (liveViewUrl) => {
    let cameraManagerVideoObject = {};
    if (liveViewUrl.length) {
        cameraManagerVideoObject = {
            previewStreamUrl: liveViewUrl,
            hdStreamUrl: liveViewUrl,
        };
    }
    return cameraManagerVideoObject;
};

const parseEagleEyeUrls = (liveViewUrl) => {
    let eagleEyeVideoObject = {};
    if (liveViewUrl?.length > 0) {
        // try catch since `liveViewUrl` comes back as a string sometimes
        try {
            const liveViewObject = JSON.parse(liveViewUrl);
            const splitHDUrl = liveViewObject.hd?.split('&access_token=');
            eagleEyeVideoObject = {
                previewStreamUrl: liveViewObject.preview?.split('&access_token')[0],
                hdStreamUrl: splitHDUrl.length && splitHDUrl[0],
                authToken: splitHDUrl.length && splitHDUrl[1],
            };
        } catch (error) {
            console.error(error);
            const splitUrl = liveViewUrl?.split('&access_token=');
            eagleEyeVideoObject = {
                previewStreamUrl: liveViewUrl?.split('&access_token')[0],
                hdStreamUrl: splitUrl.length && splitUrl[0],
                authToken: splitUrl.length && splitUrl[1],
            };
        }
    }

    return eagleEyeVideoObject;
};

export const downloadClip = async (clip, notifications, messages) => {
    if (clip?.src) {
        let tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        if (clip?.src?.indexOf('blob:') === -1) {
            const blob = await fetch(clip.src).then((r) => r.blob());
            const objectURL = URL.createObjectURL(blob);
            tempLink.href = objectURL;
        } else {
            tempLink.href = clip.src;
        }
        tempLink.setAttribute('download', `${clip.cameraName} - ${Date(clip.startTime)}.mp4`);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        notifications.addSuccessMessage({ text: messages.success });
    } else {
        notifications.addErrorMessage({ text: messages.error });
    }
};
