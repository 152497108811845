import gql from 'graphql-tag';

export const GET_JOURNAL_EVENTS = gql`
    query getJournalEvents(
        $limit: Int = 100
        $nextToken: String
        $securityActionIds: [Int]
        $actorId: Int
        $eventTargetOids: [Int]
        $from: AWSDateTime
        $to: AWSDateTime
    ) {
        getJournalEvents(
            nextToken: $nextToken
            limit: $limit
            filter: {
                securityActionIds: $securityActionIds
                actorId: $actorId
                eventTargetOids: $eventTargetOids
                occurred: { between: [$from, $to] }
            }
        ) {
            items {
                eventData
                securityActionId
                occurred
                actorName
                actorEmail
            }
            nextToken
        }
    }
`;

export const GET_JOURNAL_EVENT_TYPE_IDS = gql`
    query journalEventTypeIds {
        journalEventTypeIds
    }
`;
