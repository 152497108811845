import React, { Suspense, lazy, useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { withApi, useFlagClient } from '@brivo/onairplus-services';
import { LoadingIndicator } from '@brivo/react-components';

import ApiHelper from '../../common/helpers/Helpers';
import { fetchSites } from '../../common/utils/Utils';
import { fetchAccountDevicesCount } from './utils/deviceStatusUtils';
import PageHeader from '../../common/components/PageHeader/PageHeader';
import { GQLProviderContext } from '../../common/utils/CustomApolloProvider';

const DeviceStatusPage = lazy(() => import('./DeviceStatusPage'));
const DeviceStatusPageSingleSite = lazy(() => import('./DeviceStatusPageSingleSite'));
const DeviceStatusPageNoAccordions = lazy(() => import('./DeviceStatusPageNoAccordions'));

const DeviceStatusPageContainer = ({ ...rest }) => {
    const flagClient = useFlagClient();
    const { t } = useTranslation();

    const gqlHelper = useContext(GQLProviderContext);

    const [sites, setSites] = useState(null);
    const [accountDevicesCount, setAccountDevicesCount] = useState(null);

    const deviceLimit = flagClient.variation('device-limit-for-status-page', 0);
    const showSingleSiteDevicesPage = flagClient.variation('enable-device-status-page-single-site');
    const deviceCountAboveEnvLimit = deviceLimit !== 0 && accountDevicesCount > deviceLimit;

    const getDevicesCount = useCallback(async () => {
        const siteGroupedResult = await fetchAccountDevicesCount(gqlHelper);
        const totalArray = siteGroupedResult.map((r) => r.totalCount);
        const accountDevicesCount =
            totalArray.length && totalArray.reduce((previousValue, currentValue) => previousValue + currentValue);
        setAccountDevicesCount(accountDevicesCount);
    }, [gqlHelper]);

    useEffect(() => {
        fetchSites(gqlHelper, setSites, { includeGeocode: false });
        getDevicesCount();
    }, [getDevicesCount, gqlHelper, setSites]);

    return (
        <>
            <PageHeader title={t('Page.device-status.page-title')} />

            {sites && accountDevicesCount !== null ? (
                <Suspense fallback={<LoadingIndicator />}>
                    {deviceCountAboveEnvLimit ? (
                        showSingleSiteDevicesPage ? (
                            <DeviceStatusPageSingleSite {...rest} sites={sites} gqlHelper={gqlHelper} />
                        ) : (
                            <DeviceStatusPageNoAccordions {...rest} sites={sites} gqlHelper={gqlHelper} />
                        )
                    ) : (
                        <DeviceStatusPage {...rest} sites={sites} gqlHelper={gqlHelper} />
                    )}
                </Suspense>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};

export default withApi(DeviceStatusPageContainer, ApiHelper);
