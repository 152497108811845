import { makeStyles } from '@brivo/react-components';
const styles = makeStyles((theme) => {
    const getIconColor = (theme) => {
        return theme.palette.type === 'dark' ? theme.palette.colors.grey200 : theme.palette.colors.grey400;
    };
    return {
        nonClickableIcons: {
            '& path': { fill: getIconColor(theme) },
            height: '16px !important',
            width: '16px !important',
        },
        userIcon: {
            fill: ({ userIsClickable }) => (userIsClickable ? theme.palette.colors.blue400 : getIconColor(theme)),
        },
    };
});
export default styles;
