import { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import {
    AdministratorIcon,
    BrivoAnalyticsIcon,
    BrivoWorkspaceIcon,
    CardBankIcon,
    ConfigurationIcon,
    DashboardIcon,
    DevicesIcon,
    EventsIcon,
    FloorPlansIcon,
    GlobalViewIcon,
    LoadingIndicator,
    OccupancyTrackingIcon,
    OnairIcon,
    ProductSmartHomeIcon,
    ReportsIcon,
    UsersIcon,
    VideoIcon,
    WarningEventLineIcon,
} from '@brivo/react-components';
import { useFlagClient } from '@brivo/onairplus-services';

import {
    APP_ACCOUNT_SETTINGS_URL,
    APP_ADMIN_MANAGEMENT_ADMINS_URL,
    APP_ADMIN_MANAGEMENT_ROLES_URL,
    APP_BADGING_URL,
    APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL,
    APP_BRIVO_ANALYTICS_MY_ASSISTANT_URL,
    APP_BRIVO_SMARTHOME,
    APP_COMMAND_URL,
    APP_CONFIGURED_SITES_URL,
    APP_CREDENTIAL_CARDS_URL,
    APP_CREDENTIAL_FACEPRINTS_URL,
    APP_CREDENTIAL_MOBILEPASS_URL,
    APP_CREDENTIAL_UNKNOWN_CARDS_URL,
    APP_CUSTOM_FIELDS_URL,
    APP_DEVICE_STATUS_URL,
    APP_EVENT_CLASSIFICATION_URL,
    APP_EVENT_TRACKER_URL,
    APP_FACEPRINT_CONSENTS_URL,
    APP_FLOOR_PLAN_URL,
    APP_FLOOR_PLANS_URL,
    APP_GLOBAL_VIEW_URL,
    APP_GROUPS_LISTING_URL,
    APP_INTEGRATIONS,
    APP_JOURNAL_URL,
    APP_LIVE_VIDEO_CUSTOM_LAYOUT,
    APP_LIVE_VIDEO_VIEW_ALL_CAMERAS,
    APP_LOCKDOWN_SCENARIOS_URL,
    APP_LOCKDOWN_URL,
    APP_NOTIFICATIONS_URL,
    APP_OCCUPANCY_DASHBOARD_URL,
    APP_ONAIR_ACCOUNT_SETTINGS_URL,
    APP_ONAIR_ACTIVITY_REPORTS_URL,
    APP_ONAIR_CUSTOM_FIELDS_URL,
    APP_ONAIR_GROUP_LOCKDOWN_URL,
    APP_ONAIR_HOLIDAYS_URL,
    APP_ONAIR_IN_OUT_REPORTS_URL,
    APP_ONAIR_LOCKDOWN_CONFIG_URL,
    APP_ONAIR_REPORT_CONFIGURATIONS_URL,
    APP_ONAIR_REPORT_JOBS_URL,
    APP_ONAIR_REPORT_SCHEDULES_URL,
    APP_ONAIR_UNKNOWN_CARDS_URL,
    APP_ONAIR_USER_REPORTS_URL,
    APP_NEW_REPORTS_URL,
    APP_RECORDED_VIDEO_URL,
    APP_SCENARIOS_URL,
    APP_SCHEDULES_URL,
    APP_SHARED_ACCESS_URL,
    APP_SITE_SETTINGS_URL,
    APP_SNAPSHOT_LOG_URL,
    APP_UNIFIED_DASHBOARD_URL,
    APP_UNIFIED_VIDEO_INTEGRATIONS_URL,
    APP_UNIFIED_VIDEO_URL,
    APP_USERS_LISTING_URL,
    APP_VISITORS_URL,
    BRAND_LOGO_URL,
    BRIVO_WORKSPACE_URL,
    EDITION_DEMO,
    EDITION_ENTERPRISE,
    EDITION_MULTIFAMILY,
    EDITION_PROFESSIONAL,
    EDITION_STANDARD,
    ONAIR_CLASSIC_ALT_LOGIN,
    ONAIR_CLASSIC_ENTERPRISE_SSO_BASE_URL,
    ONAIR_CLASSIC_SSO_DEFAULT_LOGIN_URL,
    APP_INCIDENT_MANAGEMENT_URL,
} from '../../constants/Constants';
import {
    ACCEPT_INVITATION,
    ADMIN_VIEW_ADMINISTRATORS,
    BADGING_VIEW_TEMPLATE,
    BRIVO_WORKSPACE,
    COMMANDS_VIEW_COMMANDS,
    CONFIG_CUSTOM_FIELDS,
    CONFIG_HOLIDAYS,
    CONFIG_NOTIFICATIONS,
    CREDENTIAL_VIEW_CREDENTIALS,
    CREDENTIAL_VIEW_UNKNOWN_CARDS,
    DATA_EXPLORER_PERM,
    DEVICES_VIEW_DEVICES,
    EVENT_TRACKER_CHART_PERM,
    EVENT_TRACKER_PERM,
    EVT_CLASSIFICATION_VIEW,
    GLOBAL_VIEW_PERM,
    GROUPS_VIEW_GROUPS,
    JOURNAL_PERM,
    LINK_ACCOUNTS,
    LOCKDOWN_CLEAR_LOCKDOWN,
    LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
    LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
    LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
    LOCKDOWN_INITIATE_LOCKDOWN,
    MANAGE_ACCOUNT_SETTINGS_UPDATE,
    MANAGE_ACCOUNT_SETTINGS_VIEW,
    MANAGE_APPLICATIONS,
    NOTIFICATIONS_VIEW,
    OCCUPANCY_TRACKER_CONFIGURE,
    OCCUPANCY_TRACKER_VIEW,
    ONAIR_CLASSIC_PERM,
    ONAIR_GROUP_BASED_LOCKDOWN,
    REPORTS_PERM,
    ROLE_VIEW_ROLES,
    SCHEDULES_READ_SCHEDULES,
    SNAPSHOT_LOG_VIEW,
    USER_VIEW_USERS,
    VIDEO_VIEW_LIVE_VIDEO_ALL,
    VIDEO_VIEW_LIVE_VIDEO_CUSTOM,
    VIDEO_VIEW_RECORDED_VIDEO,
    VIEW_FLOOR_PLANS,
    VIEW_VISITOR,
} from '../../permissions/Permissions';

import NewNavigation from './NewNavigation';
import { UserContext } from '../../user/UserProvider';
import UserTracking from '../../utils/UserTracking';
import { auth } from '../../utils/Auth';
import { useAssignments } from '../../hooks/useAssignments';
import ActivateEmergencyScenarios from './NewNavigation/Components/NavigationBottomSection/components/ActivateEmergencyScenarios';
import { APP_PROPERTIES_URL } from '@common/constants/Constants';
import useFloorPlansNavigation from '@pages/FloorPlan/FloorPlanContainer/hooks/useFloorPlansNavigation';
import { checkEditionForFloorPlans } from '@pages/FloorPlan/FloorPlanContainer/utils';
import { ALL_CAMERAS } from '@pages/UnifiedVideo/hooks/useLayoutStateV2';

function updatePermissionAndVisibilityForFeaturePreview(subscriptionLevel, featureFlag, originalValue, newValue) {
    if (featureFlag === false) {
        return originalValue;
    }

    let returnValue;

    switch (subscriptionLevel) {
        case EDITION_DEMO:
            returnValue = originalValue;
            break;
        case EDITION_STANDARD:
            returnValue = newValue;
            break;
        case EDITION_PROFESSIONAL:
            returnValue = newValue;
            break;
        case EDITION_ENTERPRISE:
            returnValue = originalValue;
            break;
        case EDITION_MULTIFAMILY:
            returnValue = originalValue;
            break;
        default:
            returnValue = originalValue;
    }

    return returnValue;
}

const NavigationWrapper = ({
    history,
    closeNavigation,
    navigationExpanded,
    isLockdownActive,
    fetchIsLockdownActive,
    canPageToggleNavigation,
    ...rest
}) => {
    const flagClient = useFlagClient();
    const userContext = useContext(UserContext);
    let isFaceIdEnabled = userContext?.userInfo?.isFaceIdEnabled;

    const isSupportAdmin = !!auth.getRemoteCode();
    const location = useLocation();
    const { isFloorPlanLoading, handleFloorPlanClick, cancelFloorPlansFetch, preventFloorPlanRedirect } =
        useFloorPlansNavigation(history);

    const dealerName = userContext?.userInfo?.dealerName;
    const blockOnair = userContext?.userInfo?.blockOnair;
    const { isAssignedToAllLockdowns, isAssignedToAllSites } = useAssignments();

    const [isActivateESDialogOpen, toggleActivateES] = useState(false);

    if (dealerName) {
        localStorage.setItem('dealerName', encodeURIComponent(dealerName));
    }
    const showBrandLogo = flagClient.variation('enable-brand-logo', false);
    const logoUrl =
        showBrandLogo && dealerName && dealerName !== '' ? BRAND_LOGO_URL + encodeURIComponent(dealerName) : '';

    const storedSsoKey = localStorage.getItem('enterpriseConnectionKey');

    let onairSsoUrl = storedSsoKey
        ? `${ONAIR_CLASSIC_ENTERPRISE_SSO_BASE_URL}/${storedSsoKey}?accountId=${auth.getAccountId()}`
        : `${ONAIR_CLASSIC_SSO_DEFAULT_LOGIN_URL}?accountId=${auth.getAccountId()}`;

    if (auth.isAltAuthOn() && auth.altAuthTokenExists()) {
        onairSsoUrl = `${ONAIR_CLASSIC_ALT_LOGIN}?code=${auth.getAltToken()}`;
    }
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');
    const campaignsFlag = flagClient?.variation('enterprise-feature-preview', false);

    const newReportsFlag = flagClient?.variation('new-reporting', false);

    const hasUnifiedVideoFlag = flagClient?.variation('unified-video');
    const hasIncidentManagementFlag = flagClient?.variation('incident-management');

    const handleClick = (url) => {
        if (window.innerWidth <= 500) {
            closeNavigation();
        }
        history.push(url);
    };

    const isDemoOrEnterpriseEdition = checkEditionForFloorPlans(userContext?.userInfo?.subscriptionLevel);

    useEffect(() => {
        cancelFloorPlansFetch && cancelFloorPlansFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleActivateLockdownClick = (url) => {
        if (emergencyScenariosFlag) {
            toggleActivateES(true);
        } else {
            if (window.innerWidth <= 500) {
                closeNavigation();
            }
            history.push(url);
        }
    };

    const [hiddenNavItemsUrls, setHiddenNavItemsUrls] = useState([]);

    const { t } = useTranslation();
    const newFeatures = flagClient.variation('new-feature-indicator').newFeatures;
    const smartHomeMigrated =
        flagClient.variation('smart-home-access', false) && !!userContext?.userInfo?.smartHomeMigrated;
    const multifamilySupportEnabled = get(userContext, 'userInfo.isMultifamilySupportEnabled', false);
    const lockdownEnabled = get(userContext, 'userInfo.accountFeatures.lockdownEnabled', false);
    const groupLockdownComponent = !lockdownEnabled && {
        id: 'group-lockdown',
        category: 'features',
        onClick: () => handleClick(APP_ONAIR_GROUP_LOCKDOWN_URL),
        url: APP_ONAIR_GROUP_LOCKDOWN_URL,
        isVisible: true,
        isNew: newFeatures.includes('Group Lockdown'),
        anyOfPermissions: [ONAIR_GROUP_BASED_LOCKDOWN],
    };

    useEffect(() => {
        if (rest.hiddenNavItemsUrls) {
            setHiddenNavItemsUrls(rest.hiddenNavItemsUrls);
        }
    }, [rest.hiddenNavItemsUrls]);

    /*
     * Check if we need to modify floor plan nav item functionality
     * Current use case: if set to true, then we need to prevent the redirect to actual floor plan behavior
     */
    useEffect(() => {
        if (campaignsFlag) {
            preventFloorPlanRedirect(rest.modifyFloorPlanNavItem);
        }
    }, [rest.modifyFloorPlanNavItem, preventFloorPlanRedirect, campaignsFlag]);

    const newNavConfig = {
        user: {
            permissions: userContext && userContext.permissions,
        },
        canPageToggleNavigation: canPageToggleNavigation,
        expanded: navigationExpanded,
        onClose: closeNavigation,
        items: [
            groupLockdownComponent,
            {
                id: 'lockdown',
                category: 'features',
                onClick: () => handleActivateLockdownClick(APP_LOCKDOWN_URL),
                url: APP_LOCKDOWN_URL,
                anyOfPermissions: [LOCKDOWN_INITIATE_LOCKDOWN, LOCKDOWN_CLEAR_LOCKDOWN],
                isVisible: true,
                isNew: newFeatures.includes('Initiate Lockdown'),
            },
            {
                id: 'link-lockdown-configurations',
                category: 'features',
                onClick: () => handleClick(APP_ONAIR_LOCKDOWN_CONFIG_URL),
                url: APP_ONAIR_LOCKDOWN_CONFIG_URL,
                anyOfPermissions: [
                    LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
                    LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
                    LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
                ],
                isVisible: true,
                isNew: newFeatures.includes('onair-lockdown-configuration-functionality'), //No feature in IAM table
            },
            {
                name: t('Navigation.unified-dashboard.page-title'),
                id: 'unified-dashboard',
                category: 'features',
                icon: <DashboardIcon />,
                onClick: () => handleClick(APP_UNIFIED_DASHBOARD_URL),
                url: APP_UNIFIED_DASHBOARD_URL,
                anyOfPermissions: [VIDEO_VIEW_LIVE_VIDEO_CUSTOM, VIDEO_VIEW_LIVE_VIDEO_ALL, VIDEO_VIEW_RECORDED_VIDEO],
                isVisible: flagClient.variation('unified-dashboard', false),
                isNew: newFeatures.includes('Unified Dashboard'),
            },
            {
                name: t('Navigation.global-view.page-title'),
                id: 'global-view',
                category: 'features',
                icon: <GlobalViewIcon />,
                onClick: () => handleClick(APP_GLOBAL_VIEW_URL),
                url: APP_GLOBAL_VIEW_URL,
                anyOfPermissions: [GLOBAL_VIEW_PERM],
                isNew: newFeatures.includes('Global View'),
            },
            {
                name: t('Navigation.floor-plans.page-title'),
                id: 'floor-plans',
                category: 'features',
                icon: !isFloorPlanLoading ? (
                    <FloorPlansIcon />
                ) : (
                    <LoadingIndicator size="small" padding="4px" color="inherit" />
                ),
                onClick:
                    rest.modifyFloorPlanNavItem && campaignsFlag
                        ? () => handleClick(APP_FLOOR_PLANS_URL)
                        : () => handleFloorPlanClick(),
                url: rest.modifyFloorPlanNavItem && campaignsFlag ? APP_FLOOR_PLANS_URL : APP_FLOOR_PLAN_URL,
                anyOfPermissions: updatePermissionAndVisibilityForFeaturePreview(
                    userContext?.userInfo?.subscriptionLevel,
                    campaignsFlag,
                    [VIEW_FLOOR_PLANS],
                    []
                ),
                isVisible: updatePermissionAndVisibilityForFeaturePreview(
                    userContext?.userInfo?.subscriptionLevel,
                    campaignsFlag,
                    flagClient.variation('floor-plans-usability', false) && isDemoOrEnterpriseEdition,
                    !hiddenNavItemsUrls.includes(APP_FLOOR_PLANS_URL)
                ),
            },
            {
                name: t('Navigation.data-explorer-v2.page-title'),
                category: 'features',
                id: 'brivo-analytics',
                icon: <BrivoAnalyticsIcon />,
                children: [
                    {
                        name: t('Page.my-analytics.navigation.page-title'),
                        id: 'my-analytics',
                        onClick: () => handleClick(APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL),
                        url: APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL,
                        isNew: newFeatures.includes('Dynamic Reporting'),
                        anyOfPermissions: updatePermissionAndVisibilityForFeaturePreview(
                            userContext?.userInfo?.subscriptionLevel,
                            campaignsFlag,
                            [DATA_EXPLORER_PERM],
                            []
                        ),
                        isVisible: updatePermissionAndVisibilityForFeaturePreview(
                            userContext?.userInfo?.subscriptionLevel,
                            campaignsFlag,
                            flagClient.variation('enable-dynamic-reporting', false),
                            !hiddenNavItemsUrls.includes(APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL)
                        ),
                    },
                    {
                        name: t('Page.my-assistant.navigation.page-title'),
                        id: 'my-assistant',
                        onClick: () => handleClick(APP_BRIVO_ANALYTICS_MY_ASSISTANT_URL),
                        url: APP_BRIVO_ANALYTICS_MY_ASSISTANT_URL,
                        isVisible: flagClient.variation('enable-brivo-analytics-assistant', false),
                        anyOfPermissions: [DATA_EXPLORER_PERM],
                        isNew: newFeatures.includes('Dynamic Reporting'),
                    },
                ],
            },
            {
                name: t('Navigation.events-and-activity.page-title'),
                category: 'features',
                id: 'events-and-activity',
                icon: <EventsIcon />,
                children: [
                    {
                        name: t('Navigation.event-tracker.page-title'),
                        id: 'event-tracker',
                        onClick: () => handleClick(APP_EVENT_TRACKER_URL),
                        url: APP_EVENT_TRACKER_URL,
                        anyOfPermissions: [EVENT_TRACKER_PERM, EVENT_TRACKER_CHART_PERM],
                        isNew: newFeatures.includes('Event Tracker'),
                    },
                    {
                        name: t('Navigation.link-journal.page-title'),
                        id: 'link-journal',
                        onClick: () => handleClick(APP_JOURNAL_URL),
                        url: APP_JOURNAL_URL,
                        anyOfPermissions: [JOURNAL_PERM],
                        isNew: newFeatures.includes('Journal'),
                    },
                    {
                        name: t('Navigation.snapshot-log.page-title'),
                        id: 'snapshot-log',
                        onClick: () => handleClick(APP_SNAPSHOT_LOG_URL),
                        url: APP_SNAPSHOT_LOG_URL,
                        isVisible: !!userContext?.userInfo?.accountFeatures?.snapshotEnabled,
                        isNew: newFeatures.includes('Snapshot Log'),
                        anyOfPermissions: [SNAPSHOT_LOG_VIEW],
                    },
                    {
                        name: t('Navigation.event-classification.page-title'),
                        id: 'event-classification',
                        onClick: () => handleClick(APP_EVENT_CLASSIFICATION_URL),
                        anyOfPermissions: [EVT_CLASSIFICATION_VIEW],
                        url: APP_EVENT_CLASSIFICATION_URL,
                        isNew: newFeatures.includes('Event Classification'),
                    },
                ],
            },
            hasIncidentManagementFlag && {
                name: t('Navigation.incident-management.page-title'),
                id: 'incident-management',
                category: 'features',
                icon: <WarningEventLineIcon />,
                onClick: () => handleClick(APP_INCIDENT_MANAGEMENT_URL),
                url: APP_INCIDENT_MANAGEMENT_URL,
                // anyOfPermissions: [],
                isNew: newFeatures.includes('Incident Management'),
            },
            {
                name: t('Navigation.device-status.page-title'),
                id: 'device-status',
                category: 'features',
                icon: <DevicesIcon />,
                onClick: () => handleClick(APP_DEVICE_STATUS_URL),
                url: APP_DEVICE_STATUS_URL,
                anyOfPermissions: [DEVICES_VIEW_DEVICES],
                isNew: newFeatures.includes('Device Status'),
            },
            hasUnifiedVideoFlag
                ? {
                      name: t('Navigation.video.page-title'),
                      id: 'video',
                      category: 'features',
                      icon: <VideoIcon />,
                      onClick: () => handleClick(APP_UNIFIED_VIDEO_URL),
                      url: APP_UNIFIED_VIDEO_URL,
                      isVisible: flagClient.variation('unified-video', false),
                      anyOfPermissions: [
                          VIDEO_VIEW_LIVE_VIDEO_CUSTOM,
                          VIDEO_VIEW_LIVE_VIDEO_ALL,
                          VIDEO_VIEW_RECORDED_VIDEO,
                      ],
                  }
                : {
                      name: t('Navigation.video.page-title'),
                      id: 'video',
                      category: 'features',
                      icon: <VideoIcon />,
                      children: [
                          {
                              name: t('Page.video.custom-layout.title'),
                              id: 'custom-layouts',
                              onClick: () => handleClick(APP_LIVE_VIDEO_CUSTOM_LAYOUT),
                              url: APP_LIVE_VIDEO_CUSTOM_LAYOUT,
                              anyOfPermissions: [VIDEO_VIEW_LIVE_VIDEO_CUSTOM],
                              isNew: newFeatures.includes('Custom Layouts'),
                          },
                          {
                              name: t('Page.video.all-cameras.title'),
                              id: 'all-cameras',
                              onClick: () => handleClick(APP_LIVE_VIDEO_VIEW_ALL_CAMERAS),
                              url: APP_LIVE_VIDEO_VIEW_ALL_CAMERAS,
                              anyOfPermissions: [VIDEO_VIEW_LIVE_VIDEO_ALL],
                              isNew: newFeatures.includes(ALL_CAMERAS),
                          },
                          {
                              name: t('Navigation.recorded-video.page-title'),
                              id: 'recorded-video',
                              onClick: () => handleClick(APP_RECORDED_VIDEO_URL),
                              url: APP_RECORDED_VIDEO_URL,
                              anyOfPermissions: [VIDEO_VIEW_RECORDED_VIDEO],
                              isNew: newFeatures.includes('Recorded Video'),
                          },
                      ],
                  },
            {
                name: t('Navigation.reports.page-title'),
                id: 'reports',
                category: 'features',
                icon: <ReportsIcon />,
                children: [
                    {
                        name: t('Navigation.onair-report-jobs.page-title'),
                        id: 'onair-report-jobs',
                        onClick: () => handleClick(APP_ONAIR_REPORT_JOBS_URL),
                        url: APP_ONAIR_REPORT_JOBS_URL,
                        isVisible: flagClient.variation('onair-reports', false),
                        anyOfPermissions: [REPORTS_PERM],
                        isNew: newFeatures.includes('Classic Reports'),
                    },
                    {
                        name: t('Navigation.onair-report-schedules.page-title'),
                        id: 'onair-report-schedules',
                        onClick: () => handleClick(APP_ONAIR_REPORT_SCHEDULES_URL),
                        url: APP_ONAIR_REPORT_SCHEDULES_URL,
                        isVisible: flagClient.variation('onair-reports', false),
                        anyOfPermissions: [REPORTS_PERM],
                        isNew: newFeatures.includes('Classic Reports'),
                    },
                    {
                        name: t('Navigation.onair-report-configurations.page-title'),
                        id: 'onair-report-configurations',
                        onClick: () => handleClick(APP_ONAIR_REPORT_CONFIGURATIONS_URL),
                        url: APP_ONAIR_REPORT_CONFIGURATIONS_URL,
                        isVisible: flagClient.variation('onair-reports', false),
                        anyOfPermissions: [REPORTS_PERM],
                        isNew: newFeatures.includes('Classic Reports'),
                    },
                    {
                        name: t('Navigation.onair-in-out-reports.page-title'),
                        id: 'onair-in-out-reports',
                        onClick: () => handleClick(APP_ONAIR_IN_OUT_REPORTS_URL),
                        url: APP_ONAIR_IN_OUT_REPORTS_URL,
                        isVisible: flagClient.variation('onair-reports', false),
                        anyOfPermissions: [REPORTS_PERM],
                        isNew: newFeatures.includes('Classic Reports'),
                    },
                    {
                        name: t('Authenticated.route.report-user-reports'),
                        id: 'onair-user-reports',
                        onClick: () => handleClick(APP_ONAIR_USER_REPORTS_URL),
                        url: APP_ONAIR_USER_REPORTS_URL,
                        isVisible: true,
                        anyOfPermissions: [REPORTS_PERM],
                        isNew: newFeatures.includes('Classic Reports'),
                    },
                    {
                        name: t('Authenticated.route.report-activity-reports'),
                        id: 'onair-activity-reports',
                        onClick: () => handleClick(APP_ONAIR_ACTIVITY_REPORTS_URL),
                        url: APP_ONAIR_ACTIVITY_REPORTS_URL,
                        anyOfPermissions: [REPORTS_PERM],
                        isNew: newFeatures.includes('Classic Reports'),
                    },
                ],
            },
            {
                name: t('Navigation.new-reports.page-title'),
                id: 'reports',
                category: 'features',
                icon: <ReportsIcon />,
                onClick: () => handleClick(APP_NEW_REPORTS_URL),
                url: APP_NEW_REPORTS_URL,
                anyOfPermissions: [REPORTS_PERM],
                isVisible: newReportsFlag,
            },
            {
                name: t('Navigation.menu-users.page-title'),
                id: 'menu-users',
                category: 'features',
                icon: <UsersIcon />,
                children: [
                    {
                        name: t('Navigation.new-users.page-title'),
                        id: 'new-users',
                        onClick: () => handleClick(APP_USERS_LISTING_URL),
                        url: APP_USERS_LISTING_URL,
                        anyOfPermissions: [USER_VIEW_USERS],
                        isNew: newFeatures.includes('users-listing-screen'), //No feature in IAM table
                    },
                    {
                        name: t('Navigation.visitors.page-title'),
                        id: 'visitor-experience',
                        icon: <UsersIcon />,
                        onClick: () => handleClick(APP_VISITORS_URL),
                        url: APP_VISITORS_URL,
                        anyOfPermissions: [VIEW_VISITOR],
                        isVisible: flagClient.variation('visitor-experience', false),
                    },
                    {
                        name: t('Navigation.new-groups.page-title'),
                        id: 'new-groups',
                        onClick: () => handleClick(APP_GROUPS_LISTING_URL),
                        url: APP_GROUPS_LISTING_URL,
                        anyOfPermissions: [GROUPS_VIEW_GROUPS],
                        isNew: newFeatures.includes('group-listing-screen'), //No feature in IAM table
                    },
                    {
                        name: t('Navigation.badge-templates.page-title'),
                        id: 'badge-templates',
                        onClick: () => handleClick(APP_BADGING_URL),
                        url: APP_BADGING_URL,
                        anyOfPermissions: [BADGING_VIEW_TEMPLATE],
                        isVisible: flagClient.variation('badging-functionality', false),
                    },
                    {
                        name: t('Navigation.faceprint-consents.page-title'),
                        id: 'faceprint-consents',
                        onClick: () => handleClick(APP_FACEPRINT_CONSENTS_URL),
                        url: APP_FACEPRINT_CONSENTS_URL,
                        anyOfPermissions: [USER_VIEW_USERS],
                        isVisible: isFaceIdEnabled,
                    },
                ],
            },

            {
                name: t('Navigation.menu-credentials.page-title'),
                id: 'menu-credentials',
                category: 'features',
                icon: <CardBankIcon />,
                children: [
                    {
                        name: t('Navigation.new-cards.page-title'),
                        id: 'new-cards',
                        onClick: () => handleClick(APP_CREDENTIAL_CARDS_URL),
                        url: APP_CREDENTIAL_CARDS_URL,
                        anyOfPermissions: [CREDENTIAL_VIEW_CREDENTIALS],
                        isNew: newFeatures.includes('cards-listing-screen'), //No feature in IAM table
                    },
                    {
                        name: t('Navigation.new-mobilepass.page-title'),
                        id: 'new-mobilepass',
                        onClick: () => handleClick(APP_CREDENTIAL_MOBILEPASS_URL),
                        url: APP_CREDENTIAL_MOBILEPASS_URL,
                        anyOfPermissions: [CREDENTIAL_VIEW_CREDENTIALS],
                        isNew: newFeatures.includes('mobile-pass-listing-screen'), //No feature in IAM table
                    },
                    {
                        name: t('Navigation.link-unknown-cards.page-title'),
                        id: 'link-unknown-cards',
                        onClick: () => handleClick(APP_ONAIR_UNKNOWN_CARDS_URL),
                        url: APP_ONAIR_UNKNOWN_CARDS_URL,
                        anyOfPermissions: [CREDENTIAL_VIEW_UNKNOWN_CARDS],
                        isVisible: flagClient.variation('onair-unknown-cards-functionality', false),
                        isNew: newFeatures.includes('Unknown Cards'),
                    },
                    {
                        name: t('Navigation.new-unknown-cards.page-title'),
                        id: 'new-unknown-cards',
                        onClick: () => handleClick(APP_CREDENTIAL_UNKNOWN_CARDS_URL),
                        url: APP_CREDENTIAL_UNKNOWN_CARDS_URL,
                        anyOfPermissions: [CREDENTIAL_VIEW_UNKNOWN_CARDS],
                        isNew: newFeatures.includes('unknown-cards-listing'),
                    },
                    {
                        name: t('Navigation.faceprints.page-title'),
                        id: 'faceprint-credentials',
                        onClick: () => handleClick(APP_CREDENTIAL_FACEPRINTS_URL),
                        url: APP_CREDENTIAL_FACEPRINTS_URL,
                        anyOfPermissions: [CREDENTIAL_VIEW_CREDENTIALS],
                        isVisible: isFaceIdEnabled,
                        isNew: newFeatures.includes('faceprint-credentials-listing'),
                    },
                ],
            },
            {
                name: t('Navigation.menu-occupancy-management.page-title'),
                id: 'occupancy-management',
                category: 'features',
                icon: <OccupancyTrackingIcon />,
                children: [
                    {
                        name: t('Navigation.occupancy-dashboard.page-title'),
                        id: 'occupancy-dashboard',
                        onClick: () => handleClick(APP_OCCUPANCY_DASHBOARD_URL),
                        url: APP_OCCUPANCY_DASHBOARD_URL,
                        anyOfPermissions: [OCCUPANCY_TRACKER_VIEW],
                        isNew: newFeatures.includes('Occupancy Management'),
                    },
                    {
                        name: t('Navigation.configured-sites.page-title'),
                        id: 'configured-sites',
                        onClick: () => handleClick(APP_CONFIGURED_SITES_URL),
                        url: APP_CONFIGURED_SITES_URL,
                        anyOfPermissions: [OCCUPANCY_TRACKER_CONFIGURE],
                        isNew: newFeatures.includes('Occupancy Management'),
                    },
                ],
            },
            {
                name: t('Navigation.menu-administrators.page-title'),
                id: 'menu-administrators',
                category: 'features',
                icon: <AdministratorIcon />,
                children: [
                    {
                        name: t('Navigation.administrators.page-title'),
                        id: 'administrators',
                        onClick: () => handleClick(APP_ADMIN_MANAGEMENT_ADMINS_URL),
                        url: APP_ADMIN_MANAGEMENT_ADMINS_URL,
                        necessaryPermissions: [ADMIN_VIEW_ADMINISTRATORS],

                        isNew: newFeatures.includes('Administrators'),
                    },
                    {
                        name: t('Navigation.roles.page-title'),
                        id: 'roles',
                        onClick: () => handleClick(APP_ADMIN_MANAGEMENT_ROLES_URL),
                        url: APP_ADMIN_MANAGEMENT_ROLES_URL,
                        necessaryPermissions: [ROLE_VIEW_ROLES],

                        isNew: newFeatures.includes('Administrators'),
                    },
                ],
            },
            {
                name: t('Navigation.menu-configuration.page-title'),
                id: 'menu-configuration',
                category: 'features',
                icon: <ConfigurationIcon />,
                children: [
                    {
                        name: t('Page.account-settings.navigation.page-title'),
                        id: 'account-settings',
                        onClick: () => handleClick(APP_ACCOUNT_SETTINGS_URL),
                        url: APP_ACCOUNT_SETTINGS_URL,
                        anyOfPermissions: [MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE],
                        isNew: newFeatures.includes('Account Settings'),
                    },
                    {
                        name: t('Page.site-settings.navigation.page-title'),
                        id: 'site-settings',
                        onClick: () => handleClick(APP_SITE_SETTINGS_URL),
                        url: APP_SITE_SETTINGS_URL,
                        anyOfPermissions: [MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE],
                        isNew: newFeatures.includes('Site Settings'),
                    },
                    {
                        name: t('Page.shared-access.page-title'),
                        id: 'shared-access',
                        onClick: () => handleClick(APP_SHARED_ACCESS_URL),
                        url: APP_SHARED_ACCESS_URL,
                        anyOfPermissions: [LINK_ACCOUNTS, ACCEPT_INVITATION],
                        isNew: newFeatures.includes('Shared Access'),
                    },
                    {
                        name: t('Page.unified-video-integrations.navigation.page-title'),
                        id: 'unified-video-integrations',
                        onClick: () => handleClick(APP_UNIFIED_VIDEO_INTEGRATIONS_URL),
                        url: APP_UNIFIED_VIDEO_INTEGRATIONS_URL,
                        anyOfPermissions: [MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE],
                    },
                    {
                        name: t('Page.property.navigation.title'),
                        id: 'properties',
                        onClick: () => handleClick(APP_PROPERTIES_URL),
                        url: APP_PROPERTIES_URL,
                        anyOfPermissions: [MANAGE_ACCOUNT_SETTINGS_VIEW, MANAGE_ACCOUNT_SETTINGS_UPDATE],
                        isNew: newFeatures.includes('Properties'),
                        isVisible:
                            flagClient.variation('ba-site-nesting-nested-sites', false) && multifamilySupportEnabled,
                    },
                    {
                        name: t('Page.api-applications.navigation.page-title'),
                        id: 'api-applicatons',
                        onClick: () => handleClick(APP_ONAIR_ACCOUNT_SETTINGS_URL),
                        url: APP_ONAIR_ACCOUNT_SETTINGS_URL,
                        anyOfPermissions: [MANAGE_APPLICATIONS],
                        isVisible: flagClient.variation('onair-account-settings-functionality', false),
                        isNew: newFeatures.includes('Applications Management'),
                    },
                    {
                        name: t('Navigation.link-holidays.page-title'),
                        id: 'link-holidays',
                        onClick: () => handleClick(APP_ONAIR_HOLIDAYS_URL),
                        url: APP_ONAIR_HOLIDAYS_URL,
                        anyOfPermissions: [CONFIG_HOLIDAYS],
                        isVisible: flagClient.variation('onair-holidays-functionality', false),
                        isNew: newFeatures.includes('Holidays'),
                    },
                    {
                        name: t('Navigation.link-integrations.page-title'),
                        id: 'integrations-list',
                        onClick: () => handleClick(APP_INTEGRATIONS),
                        url: APP_INTEGRATIONS,
                        necessaryPermissions: [MANAGE_ACCOUNT_SETTINGS_VIEW],
                        anyOfPermissions: [MANAGE_ACCOUNT_SETTINGS_UPDATE, MANAGE_APPLICATIONS],
                        isNew: newFeatures.includes('Integrations'),
                    },
                    {
                        name: t('Navigation.link-notification-rules.page-title'),
                        id: 'link-notification-rules',
                        onClick: () => handleClick(APP_NOTIFICATIONS_URL),
                        url: APP_NOTIFICATIONS_URL,
                        necessaryPermissions: {
                            oldPermissions: [CONFIG_NOTIFICATIONS],
                            newPermissions: [NOTIFICATIONS_VIEW],
                        },
                        isNew: newFeatures.includes('Notifications'),
                    },
                    {
                        name: t('Navigation.link-commands-configuration.page-title'),
                        id: 'link-commands-configuration',
                        onClick: () => handleClick(APP_COMMAND_URL),
                        url: APP_COMMAND_URL,
                        anyOfPermissions: [COMMANDS_VIEW_COMMANDS],
                        isNew: newFeatures.includes('Commands'),
                    },
                    {
                        name: t('Navigation.custom-fields.page-title'),
                        id: 'custom-fields',
                        onClick: () => handleClick(APP_ONAIR_CUSTOM_FIELDS_URL),
                        url: APP_ONAIR_CUSTOM_FIELDS_URL,
                        anyOfPermissions: [CONFIG_CUSTOM_FIELDS],
                        isVisible: flagClient.variation('onair-custom-fields-functionality', false),
                        isNew: newFeatures.includes('Custom Fields'),
                    },
                    {
                        name: t('Navigation.custom-fields.page-title'),
                        id: 'new-custom-fields',
                        onClick: () => handleClick(APP_CUSTOM_FIELDS_URL),
                        url: APP_CUSTOM_FIELDS_URL,
                        anyOfPermissions: [CONFIG_CUSTOM_FIELDS],
                        isNew: newFeatures.includes('Custom Fields'),
                    },
                    {
                        name: t('Navigation.link-schedules.page-title'),
                        id: 'new-schedules',
                        onClick: () => handleClick(APP_SCHEDULES_URL),
                        url: APP_SCHEDULES_URL,
                        anyOfPermissions: [SCHEDULES_READ_SCHEDULES],
                        isNew: newFeatures.includes('Schedules'),
                    },
                    {
                        name: emergencyScenariosFlag
                            ? t('Navigation.menu-emergency-scenarios.page-title')
                            : t('Navigation.menu-lockdown-scenarios.page-title'),
                        id: 'menu-lockdown-scenarios',
                        onClick: () =>
                            handleClick(emergencyScenariosFlag ? APP_SCENARIOS_URL : APP_LOCKDOWN_SCENARIOS_URL),
                        url: emergencyScenariosFlag ? APP_SCENARIOS_URL : APP_LOCKDOWN_SCENARIOS_URL,
                        isVisible:
                            flagClient.variation('emergency-scenarios', false) &&
                            !!isAssignedToAllLockdowns &&
                            !!isAssignedToAllSites &&
                            !!lockdownEnabled,
                        isNew: newFeatures.includes('Lockdown Scenarios'),
                    },
                ],
            },
            {
                name: t('Navigation.brivo-workspace.page-title'),
                id: 'brivo-workspace',
                category: 'features',
                icon: <BrivoWorkspaceIcon />,
                link: true,
                newTab: true,
                onClick: () => {
                    return UserTracking.sendEvent(`User open Brivo Workplace from Access`, {
                        event_category: 'Brivo Workplace from access',
                        event_label: `User open Brivo Workplace from Access`,
                    });
                },
                url: BRIVO_WORKSPACE_URL,
                necessaryPermissions: [BRIVO_WORKSPACE],
                isVisible: flagClient.variation('enable-brivo-workspace', false),
                isNew: newFeatures.includes('Brivo Workspace'),
            },
            {
                name: t('Navigation.onair-classic.page-title'),
                id: 'onair-classic',
                category: 'features',
                icon: <OnairIcon />,
                link: true,
                newTab: true,
                onClick: () => {
                    return UserTracking.sendEvent(`User open OnAir from Access`, {
                        event_category: 'OnAir login from access',
                        event_label: `User open OnAir from Access`,
                    });
                },
                url: onairSsoUrl,
                anyOfPermissions: [ONAIR_CLASSIC_PERM],
                isVisible: isSupportAdmin ? false : flagClient.variation('onair-classic', false) && !blockOnair,
                isNew: newFeatures.includes('Onair Classic'),
            },
            {
                name: t('Navigation.brivo-smarthome.page-title'),
                id: 'brivo-smarthome',
                category: 'features',
                icon: <ProductSmartHomeIcon />,
                children: [
                    {
                        name: t('Navigation.smarthome-properties.page-title'),
                        id: 'smarthome-properties',
                        onClick: () => handleClick(APP_BRIVO_SMARTHOME + '/properties'),
                        isVisible: smartHomeMigrated,
                        isNew: newFeatures.includes('Brivo Smart Home'),
                        url: APP_BRIVO_SMARTHOME + '/properties',
                    },
                    {
                        name: t('Navigation.smarthome-accesses.page-title'),
                        id: 'smarthome-accesses',
                        onClick: () => handleClick(APP_BRIVO_SMARTHOME + '/accesses'),
                        isVisible: smartHomeMigrated,
                        isNew: newFeatures.includes('Brivo Smart Home'),
                        url: APP_BRIVO_SMARTHOME + '/accesses',
                    },
                    {
                        name: t('Navigation.smarthome-logs.page-title'),
                        id: 'smarthome-logs',
                        onClick: () => handleClick(APP_BRIVO_SMARTHOME + '/logs'),
                        isVisible: smartHomeMigrated,
                        isNew: newFeatures.includes('Brivo Smart Home'),
                        url: APP_BRIVO_SMARTHOME + '/logs',
                    },
                    // {
                    //     name: t('Navigation.smarthome-integration.page-title'),
                    //     id: 'smarthome-integration',
                    //     onClick: () => handleClick(APP_BRIVO_SMARTHOME + '/integration'),
                    //     isVisible: smartHomeMigrated,
                    //     isNew: newFeatures.includes('Brivo Smart Home'),
                    //     url: APP_BRIVO_SMARTHOME + '/integration',
                    // },
                    {
                        name: t('Navigation.smarthome-profile.page-title'),
                        id: 'smarthome-profile',
                        onClick: () => handleClick(APP_BRIVO_SMARTHOME + '/profile'),
                        isVisible: smartHomeMigrated,
                        isNew: newFeatures.includes('Brivo Smart Home'),
                        url: APP_BRIVO_SMARTHOME + '/profile',
                    },
                ],
            },
        ],
    };

    return (
        <>
            <NewNavigation
                config={newNavConfig}
                isLockdownActive={isLockdownActive}
                lockdownEnabled={lockdownEnabled}
                {...rest}
                logoUrl={logoUrl}
                showNewActivationPopup={emergencyScenariosFlag}
            />

            {isActivateESDialogOpen && (
                <ActivateEmergencyScenarios
                    toggleActivateES={toggleActivateES}
                    isActivateESDialogOpen={isActivateESDialogOpen}
                    fetchIsLockdownActive={fetchIsLockdownActive}
                />
            )}
        </>
    );
};

export default withRouter(NavigationWrapper);
