import { ITheme, makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme: ITheme) => {
    return {
        imgContainer: {
            width: 'calc(100% + 2.5rem)',
            height: 'calc(100% + 2.5rem)',
            position: 'absolute',
            left: '-2.5rem',
            top: '-2.5rem',
            background: `linear-gradient(0deg, ${theme.palette.colors.grey1200} 35.06%, rgba(16, 23, 31, 0.00) 100%)`,
        },
        imgStyle: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    };
});

export default useStyles;
