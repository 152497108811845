import { useContext } from 'react';
import { UserContext } from '../user/UserProvider';

export function useAccountFeatures() {
    const user = useContext(UserContext);

    const accountFeatures = user?.userInfo?.accountFeatures;

    const isLockdownEnabled = accountFeatures?.lockdownEnabled;

    return {
        isLockdownEnabled,
    };
}
