import { useState, useCallback, useEffect, useContext } from 'react';
import { MultiSelectFilter } from '@brivo/react-components';
import { useQueryEditor } from '@/pages/NewReports/components/TableEditor/QueryEditorContext';
import { GQLProviderContext } from '@/common/utils/CustomApolloProvider';
import { fetchSites } from '@/common/utils/Utils';
import { useTranslation } from 'react-i18next';
import { columnConfiguration, dimension } from './constants';
import { Filter } from '@/pages/NewReports/common/interfaces';
import ApiHelper from '@/common/helpers/Helpers';
import { withApi } from '@brivo/onairplus-services';
import { createFilterConfiguration } from '@/pages/NewReports/common/utils';

interface Site {
    [key: string]: string;
}

interface SiteProps {
    disabled: boolean;
    filter: Filter;
}

const Site = ({ disabled, filter }: SiteProps) => {
    const [selectedSites, setSelectedSites] = useState([]);
    const gqlHelper = useContext(GQLProviderContext);
    const [sites, setSites] = useState([]);
    const QueryEditor: any = useQueryEditor();
    const { t } = useTranslation();

    useEffect(() => {
        fetchSites(gqlHelper, setSites, { includeGeocode: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filter) {
            let selectedFilteredSites: any = filter.test_values?.map((test_value: any) =>
                sites.find((someSite: any) => someSite.name === test_value.value)
            );
            selectedFilteredSites = selectedFilteredSites?.filter((filteredSite) => filteredSite !== undefined);
            setSelectedSites(selectedFilteredSites);
        }
    }, [filter, sites]);

    const handleMultiSelectFilterChange = (event: any) => {
        const newSites = event;
        const formattedSites = newSites.map((newSite: Site) => ({
            source: 'constant',
            type: 'string',
            value: newSite.name,
        }));

        const newFilter = createFilterConfiguration(columnConfiguration, formattedSites);

        setSelectedSites(newSites);
        if (newSites.length === 0) {
            QueryEditor.removeFilter(newFilter);
        } else {
            QueryEditor.addOrUpdateFilter(newFilter);
        }
    };

    const siteDropdownMapper = useCallback((item) => ({ id: item.id, name: item.siteName }), []);

    return (
        <MultiSelectFilter
            disabled={disabled}
            id="sites"
            label={t('Page.new-reports.column-filter.sites')}
            noSelectionLabel={t('Page.new-reports.column-filter.sites.no-selection-label')}
            items={sites}
            mapper={siteDropdownMapper}
            value={selectedSites}
            onValueChange={handleMultiSelectFilterChange}
            noResultMessage={t('Page.new-reports.column-filter.no-options')}
            clearingValues={true}
            showMoreText={t('Page.new-reports.column-filter.show-more')}
        />
    );
};

export default withApi(Site, ApiHelper);
