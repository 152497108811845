import { makeStyles } from '@brivo/react-components';

const iconStyles = makeStyles((theme) => {
    return {
        userIconParent: {
            textTransform: 'uppercase',
            borderRadius: '50%',
            width: (props) => props.size,
            height: (props) => props.size,
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.colors.grey800 : theme.palette.colors.grey50,
        },
        userIconChild: {
            fontWeight: 'bold',
            fontSize: (props) => (props.showName ? '1.25rem' : '1.5rem'),
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : theme.palette.colors.grey600,
        },
    };
});

export default iconStyles;
