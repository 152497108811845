import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    addSectionButton: {
        margin: 'auto',
        marginTop: '1.125rem',
    },
    divider: {
        width: '400px',
        height: '1px',
        background: theme.palette.type === 'dark' ? theme.palette.colors.grey700 : theme.palette.colors.grey200,
        marginTop: theme.spacing(1.5),
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginTop: '2.5rem',
    },
    bottomOfGridElement: {
        height: '3rem',
        width: '100%',
    },
    removeSectionButton: {
        marginLeft: 'auto',
    },
    sectionTitleButton: {
        textTransform: 'none',
        '&.MuiButton-text': {
            padding: '0',
        },
        '&.MuiButton-root': {
            padding: '0 10px',

            '& .MuiButton-label': {
                justifyContent: 'start',
            },
        },
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.5em',
        marginBottom: '0.1em',
        '& #Button__section-title-button': {
            backgroundColor: 'transparent !important',
            padding: '0',
        },
    },
    addSectionTitle: {
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    sectionTitleMargin: {
        marginLeft: theme.spacing(1),
    },
    sectionName: {
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
