import { Page, SearchAltIcon } from '@brivo/react-components';
import { auth } from '../../common/utils/Auth';

const AccessDenied = () => {
    setTimeout(() => {
        auth.logout();
    }, 5000);

    return (
        <Page>
            <div
                className="u-fx u-fx-column u-fx-align-center u-fx-justify-center u-grey"
                style={{ height: 'calc(40vh - 2rem)' }}
            >
                <p className="u-mb-full">
                    <SearchAltIcon style={{ height: 48, width: 48 }} />
                </p>
                <p className="u-large-text">
                    You are not authorized to use this application. You will be logged out in 5 seconds.
                </p>
                <p className="u-italic u-grey u-mt-double">If you believe you have received this message in error,</p>
                <p className="u-italic u-grey">please contact your system administrator.</p>
            </div>
        </Page>
    );
};

export default AccessDenied;
