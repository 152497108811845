import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import VisTimeline from '@brivo/react-vis-timeline-2';
import { withTheme, KeyboardArrowLeftIcon, KeyboardArrowRightIcon, IconButton, Grid } from '@brivo/react-components';
import { formatClipsForTimeline, formatEventDataForTimeline, getTimelineOptions, isTimeInRange } from './timelineUtils';
import styles from './styles';

const TIMELINE_ITEM_EVENT = 'event';
const TIMELINE_ITEM_CLIP = 'videoClip';

/*
 * "These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)"
 * https://stackoverflow.com/questions/46835825/conditional-css-in-create-react-app
 */
const DarkThemeTimeline = React.lazy(() => import('./components/DarkThemeTimeline'));
const LightThemeTimeline = React.lazy(() => import('./components/LightThemeTimeline'));

const Timeline = ({
    events,
    clips,
    clipsForTimelineV2,
    eventsForTimelineV2,
    selectedCamera,
    selectedEventTime,
    minStartTime,
    maxEndTime,
    timelineStart,
    timelineEnd,
    goForward,
    goBack,
    setCurrentStart,
    zoomLevel,
    theme,
    removeSelectedClip,
    handleClickEventCamera,
    handleClickTimelineProviderClip,
    timelineEndTimestampRef,
    timelineRef,
    isV2 = false,
    isClipClicked = false,
}) => {
    //TODO center around selected clip
    const classes = styles();
    const { i18n } = useTranslation();

    const timelineItems = useMemo(
        () => [...clipsForTimelineV2, ...eventsForTimelineV2],
        [clipsForTimelineV2, eventsForTimelineV2]
    );

    const rangechangedHandler = ({ start, byUser }) => {
        if (byUser) {
            /*
                byUser = manual drag. we don't want to set timeline start twice if
                the range changed due to clicking
            */
            setCurrentStart(Date.parse(start));
        }
    };

    // v1 RecordedVideo page shows no clips until selected from events list
    const clipsForTimeline = useMemo(
        () =>
            clips
                ? formatClipsForTimeline(clips, {
                      timelineMinStart: minStartTime,
                      timelineMaxEnd: maxEndTime,
                  })
                : [],
        [clips, maxEndTime, minStartTime]
    );
    // don't add `isV2` to `formatEventDataForTimeline` here,
    // since `eventsForTimeline` defined here is only used for v1
    const eventsForTimeline = formatEventDataForTimeline(events, zoomLevel); //use callback?

    const seeTheFuture = timelineEnd !== maxEndTime;

    const timelineConfig = getTimelineOptions({
        minStart: minStartTime,
        maxEnd: maxEndTime,
        timelineStart,
        timelineEnd,
        language: i18n.language,
        zoomLevel: zoomLevel,
    });

    const timechangedHandler = ({ time }) => {
        const newSelectedTimeMillis = Date.parse(time);
        const updateTimelinePositionMarker = false; //it gets updated automatically while user drags
        let foundMatchingItem = false;

        timelineRef.current.timeline.getVisibleItems().forEach((itemId) => {
            const itemObject = JSON.parse(itemId);

            if (itemObject.type === TIMELINE_ITEM_CLIP) {
                if (isTimeInRange(newSelectedTimeMillis, itemObject.startTime, itemObject.endTime)) {
                    foundMatchingItem = true;

                    return handleClickTimelineProviderClip(
                        {
                            startTime: newSelectedTimeMillis,
                            endTime: itemObject.endTime,
                            cameraId: itemObject.cameraId,
                        },
                        selectedCamera.cameraName,
                        updateTimelinePositionMarker
                    );
                }
            } else if (itemObject.type === TIMELINE_ITEM_EVENT) {
                const { event } = itemObject;

                if (newSelectedTimeMillis === Date.parse(event.occurred)) {
                    foundMatchingItem = true;

                    return handleClickEventCamera({
                        event,
                        cameraId: selectedCamera.cameraId,
                        cameraName: selectedCamera.cameraName,
                        updateTimelinePositionMarker,
                    });
                }
            }
        });
        if (!foundMatchingItem) {
            removeSelectedClip();
        }
    };

    //BE CAREFUL what you try to reference here. this only gets assigned on mount see lines 101-115 https://github.com/bonespike/react-vis-timeline/blob/master/src/timeline.tsx
    const clickHandler = (event) => {
        const { what, time } = event;
        let timeClickedMillis = Date.parse(time);
        let clipStartToRequest;

        // cannot be checking if it's background,
        // since that will mess up the drag check
        // differentiate between dragging time container & actual event bar
        // sometimes the vis timeline will define the actual event bar drag as "what: null",
        // while other times it will be "what: custom-time"
        if (isV2 && what && what !== 'item' && what !== 'axis' && !isEmpty(clipsForTimeline)) {
            let clipToJump;
            const { start, end } = timelineRef.current.timeline.getWindow();
            const startInMS = new Date(start).getTime();
            const endInMS = new Date(end).getTime();
            const firstClip = clipsForTimeline[0];
            const lastClip = clipsForTimeline[clipsForTimeline.length - 1];

            // snap to first clip if user clicks before start of first clip
            if (timeClickedMillis < firstClip.start) {
                timeClickedMillis = firstClip.start;
                clipToJump = firstClip;

                // initiates snap to event
                if (!isTimeInRange(timeClickedMillis, startInMS, endInMS)) {
                    setCurrentStart(timeClickedMillis);
                }
            }
            // snap to last clip clip if user clicks before start of last clip clip
            else if (timeClickedMillis > lastClip?.end) {
                timeClickedMillis = lastClip.start;
                clipToJump = lastClip;
                // initiates snap to event
                if (!isTimeInRange(timeClickedMillis, startInMS, endInMS)) {
                    setCurrentStart(timeClickedMillis);
                }
            }
            // snap to closest clip if user clicks in between clips
            else {
                for (let i = 1; i < clipsForTimeline.length; i++) {
                    const prev = clipsForTimeline[i - 1];
                    const curr = clipsForTimeline[i];

                    if (prev.end < timeClickedMillis && curr.start > timeClickedMillis) {
                        if (curr.start - timeClickedMillis < timeClickedMillis - prev.end) {
                            clipToJump = curr;
                            timeClickedMillis = curr.start;
                        } else {
                            clipToJump = prev;
                            timeClickedMillis = prev.start;
                        }

                        if (!isTimeInRange(timeClickedMillis, startInMS, endInMS)) {
                            setCurrentStart(timeClickedMillis);
                        }

                        break;
                    }
                }
            }

            const updateTimelinePositionMarker = true;

            // skip call if user doesn't adjust marker
            if (clipToJump) {
                if (isTimeInRange(timeClickedMillis, clipToJump?.start, clipToJump?.end)) {
                    clipStartToRequest = timeClickedMillis;
                } else {
                    //clip is small, use the clip's start time
                    clipStartToRequest = clipToJump.start;
                }

                const timelineItemToJump = timelineItems.find((item) => item.start === clipStartToRequest);

                if (timelineItemToJump.className.includes('event')) {
                    return handleClickEventCamera({
                        event: timelineItemToJump,
                        cameraId: selectedCamera.cameraId,
                        cameraName: selectedCamera.cameraName,
                        updateTimelinePositionMarker,
                    });
                } else {
                    return handleClickTimelineProviderClip(
                        { startTime: clipStartToRequest, endTime: clipToJump.end, cameraId: selectedCamera.cameraId },
                        selectedCamera.cameraName,
                        updateTimelinePositionMarker
                    );
                }
            }
        }

        if (what === 'item') {
            const itemObject = JSON.parse(event.item);
            const updateTimelinePositionMarker = true;

            if (itemObject.type === TIMELINE_ITEM_CLIP) {
                if (isTimeInRange(timeClickedMillis, itemObject.startTime, itemObject.endTime)) {
                    clipStartToRequest = timeClickedMillis;
                } else {
                    //clip is small, use the clip's start time
                    clipStartToRequest = itemObject.startTime;
                }

                return handleClickTimelineProviderClip(
                    { startTime: clipStartToRequest, endTime: itemObject.endTime, cameraId: itemObject.cameraId },
                    selectedCamera.cameraName,
                    updateTimelinePositionMarker
                );
            }
            if (itemObject.type === TIMELINE_ITEM_EVENT) {
                const { event } = itemObject;

                return handleClickEventCamera({
                    event,
                    cameraId: selectedCamera.cameraId,
                    cameraName: selectedCamera.cameraName,
                    updateTimelinePositionMarker,
                });
            }
        }
        // if user clicks on background, remove clip (keeps existing v1 functionality)
        // if the user clicks on the axis, remove selected clip since user can drag with background in v2
        if ((what === 'background' && !isV2) || (what === 'axis' && isV2)) {
            timelineRef.current.timeline.setCustomTime(time, 'timeline-position');
            //not ideal to set it every time, but we can only reference whatever clipStatus was on mount.
            removeSelectedClip();
        }
    };

    useEffect(() => {
        if (timelineRef.current.timeline) {
            if (isV2) {
                timelineRef.current.timeline.setItems(timelineItems);
                timelineRef.current.timeline.on('timechange', () => {
                    // remove time marker on drag, consistent with v1 not making calls on drag,
                    // since `what` !== 'background' (above) because `what` is most likely 'null'
                    const existingTimeMarkers = document.getElementsByClassName('vis-custom-time-marker');
                    !isEmpty(existingTimeMarkers) && existingTimeMarkers[0].remove();
                });
            } else {
                timelineRef.current.timeline.setItems([...clipsForTimeline, ...eventsForTimeline]);
            }
            timelineRef.current.timeline.setGroups([{ id: 1 }]);
        }
    }, [clipsForTimeline, eventsForTimeline, isV2, timelineItems, timelineRef]);

    useEffect(() => {
        if (timelineRef.current.timeline) {
            try {
                timelineRef.current.timeline.getCustomTime('timeline-position');
            } catch (e) {
                if (selectedEventTime) {
                    timelineRef.current.timeline.addCustomTime(selectedEventTime, 'timeline-position');
                } else {
                    if (isV2) {
                        timelineRef.current.timeline.addCustomTime(timelineStart, 'timeline-position');
                    } else {
                        timelineRef.current.timeline.addCustomTime(minStartTime, 'timeline-position');
                    }
                }
            }
        }
    }, [selectedEventTime, minStartTime, timelineRef, isV2, timelineStart]);

    useEffect(() => {
        timelineEndTimestampRef.current = timelineEnd;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timelineEnd]);

    return (
        <Grid container justifyContent="center" className={clsx({ [classes.timelineContainerMargin]: isV2 })}>
            <Grid item>
                {timelineStart !== minStartTime && (
                    <IconButton
                        id="left-arrow-button"
                        onClick={() => goBack(timelineStart, timelineEnd)}
                        className={classes.moveInTimeArrows}
                        icon={<KeyboardArrowLeftIcon />}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={isV2 ? null : 10}
                lg={isV2 ? null : 11}
                className={clsx({
                    [classes.timelineWithOverflow]: isV2,
                    [classes.unselectedMarker]: isV2 && !isClipClicked,
                })}
            >
                <React.Suspense fallback={<></>}>
                    {theme.palette.type === 'dark' ? <DarkThemeTimeline /> : <LightThemeTimeline />}
                </React.Suspense>
                <VisTimeline
                    //should we have a key?
                    ref={timelineRef}
                    clickHandler={clickHandler}
                    timechangedHandler={timechangedHandler}
                    rangechangedHandler={rangechangedHandler} //prop name is intentionally not camelCased
                    options={timelineConfig}
                />
            </Grid>
            <Grid item>
                {seeTheFuture && (
                    <IconButton
                        id="right-arrow-button"
                        onClick={() => goForward(timelineStart, timelineEnd)}
                        className={classes.moveInTimeArrows}
                        icon={<KeyboardArrowRightIcon />}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default withTheme(Timeline);
