import gql from 'graphql-tag';

export const ACCESS_POINTS_QUERY = gql`
    query getAccessPoints($pageSize: Int!, $offset: Int!) {
        getAccessPoints(limit: { rows: $pageSize, offset: $offset }) {
            accountId
            activationEnabled
            controlPanelCpNumber
            controlPanelId
            controlPanelName
            controlPanelTypeId
            deviceId
            doorAjarEnabled
            rexUnlock
            useRex
            id
            name
            reportLiveStatus
            siteId
            siteName
            twoFactorEnabled
            type
        }
    }
`;
export const ACCESS_POINT_COUNT = gql`
    query {
        getAccessPointCount
    }
`;
export const ACCESS_POINTS_FOR_SITES_QUERY = gql`
    query getAccessPointsForSites($pageSize: Int!, $offset: Int!, $sitesOids: [Int]!) {
        getAccessPointsForSites(limit: { rows: $pageSize, offset: $offset }, sitesOids: $sitesOids) {
            accountId
            activationEnabled
            controlPanelCpNumber
            controlPanelId
            controlPanelName
            controlPanelTypeId
            deviceId
            deviceAjarEnabled
            doorAjarEnabled
            rexUnlock
            useRex
            id
            name
            reportLiveStatus
            siteId
            siteName
            twoFactorEnabled
            type
        }
    }
`;

export const INTRUSION_PANELS_FOR_SITES_QUERY = gql`
    query getIntrusionPanelsForSites($pageSize: Int!, $offset: Int!, $siteOIds: [Int]) {
        getIntrusionPanelsForSites(limit: { rows: $pageSize, offset: $offset }, siteOIds: $siteOIds) {
            id
            siteId
            siteName
            alarmConfigurationId
            deviceId
            name
            armStatus
            armStatusUpdated
            created
            type
            macAddress
        }
    }
`;

export const ACCESS_POINTS_WITH_STATUS_QUERY = gql`
    query getAccessPoints(
        $pageSize: Int!
        $offset: Int!
        $sitesOids: [Int]
        $includeCardReaders: Boolean
        $includeMappedDevices: Boolean
    ) {
        getAccessPoints(
            limit: { rows: $pageSize, offset: $offset }
            sitesOids: $sitesOids
            includeCardReaders: $includeCardReaders
            includeMappedDevices: $includeMappedDevices
        ) {
            accountId
            activationEnabled
            controlPanelCpNumber
            controlPanelId
            controlPanelName
            controlPanelTypeId
            deviceId
            deviceAjarEnabled
            deviceAjarThreshold
            doorAjarEnabled
            rexUnlock
            useRex
            id
            name
            reportLiveStatus
            siteId
            siteName
            twoFactorEnabled
            type
            status {
                data {
                    deviceName
                    deviceId
                    siteId
                    reportLiveStatus
                    controlPanelId
                    lockState
                    deviceStatus
                    systemStatus
                    systemStatusMessage
                    messageReceivedTime
                    isActive
                    threatLevel
                }
                rawData {
                    connectedStatus
                    nextUnlockScheduleChange
                    nextIgnoreScheduleChange
                    unlockScheduleStatus
                    ignoreScheduleStatus
                    accessState
                    ajarStatus
                    inputStatus
                    intrusionStatus
                    batteryState
                    batteryRemaining
                    doorState
                    threatLevel
                    readerStatus
                    alternateReaderStatus
                    peripheralInfo {
                        type
                        networkInfo {
                            networkStatus
                        }
                    }
                }
            }
            engageMsg
            disengageMsg
            doorStationId
            isMapped
            pulseTime
        }
    }
`;

export const GET_ID_NAME_TYPE_FOR_ACCESS_POINTS_QUERY = gql`
    query getAccessPoints($pageSize: Int!, $offset: Int!) {
        getAccessPoints(limit: { rows: $pageSize, offset: $offset }) {
            id
            name
            type
        }
    }
`;

export const ACCESS_POINTS_WITH_STATUS_AND_COUNT_QUERY = gql`
    query getAccessPointsWithCount(
        $pageSize: Int!
        $offset: Int!
        $sitesOids: [Int]
        $filters: [DeviceFilter]
        $includeCardReaders: Boolean
        $includeMappedDevices: Boolean
    ) {
        getAccessPoints(
            limit: { rows: $pageSize, offset: $offset }
            sitesOids: $sitesOids
            includeCardReaders: $includeCardReaders
            includeMappedDevices: $includeMappedDevices
            filters: $filters
        ) {
            accountId
            activationEnabled
            controlPanelCpNumber
            controlPanelId
            controlPanelName
            controlPanelTypeId
            controlLockType
            deviceId
            deviceAjarEnabled
            deviceAjarThreshold
            doorAjarEnabled
            rexUnlock
            useRex
            id
            name
            reportLiveStatus
            siteId
            siteName
            twoFactorEnabled
            type
            status {
                data {
                    deviceName
                    deviceId
                    siteId
                    reportLiveStatus
                    controlPanelId
                    lockState
                    deviceStatus
                    systemStatus
                    systemStatusMessage
                    messageReceivedTime
                    isActive
                    threatLevel
                }
                rawData {
                    connectedStatus
                    nextUnlockScheduleChange
                    nextIgnoreScheduleChange
                    unlockScheduleStatus
                    ignoreScheduleStatus
                    accessState
                    ajarStatus
                    inputStatus
                    intrusionStatus
                    batteryState
                    batteryRemaining
                    doorState
                    threatLevel
                    readerStatus
                    alternateReaderStatus
                    peripheralInfo {
                        type
                        networkInfo {
                            networkStatus
                        }
                    }
                }
            }
            engageMsg
            disengageMsg
            doorStationId
            isMapped
        }

        getDeviceCountsForSites(sitesOids: $sitesOids, includeMappedDevices: $includeMappedDevices, filters: $filters) {
            cameras
            devices
            doors
            elevators
            panels
            intrusionPanels
            siteId
            totalCount
        }
    }
`;

export const ACCESS_POINTS_WITH_COUNT_QUERY = gql`
    query getAccessPointsWithCount(
        $pageSize: Int!
        $offset: Int!
        $sitesOids: [Int]
        $filters: [DeviceFilter]
        $includeCardReaders: Boolean
        $includeMappedDevices: Boolean
        $sortField: String
        $sortAsc: String
    ) {
        getAccessPoints(
            limit: { rows: $pageSize, offset: $offset }
            sitesOids: $sitesOids
            includeCardReaders: $includeCardReaders
            includeMappedDevices: $includeMappedDevices
            filters: $filters
            sort: { field: $sortField, direction: $sortAsc }
        ) {
            accountId
            activationEnabled
            controlPanelCpNumber
            controlPanelId
            controlPanelName
            controlPanelTypeId
            controlLockType
            deviceId
            deviceAjarEnabled
            deviceAjarThreshold
            doorAjarEnabled
            rexUnlock
            useRex
            id
            name
            reportLiveStatus
            siteId
            siteName
            twoFactorEnabled
            type
            engageMsg
            disengageMsg
            doorStationId
            isMapped
        }

        getDeviceCountsForSites(sitesOids: $sitesOids, includeMappedDevices: $includeMappedDevices, filters: $filters) {
            cameras
            devices
            doors
            elevators
            panels
            intrusionPanels
            siteId
            totalCount
        }
    }
`;

export const ACCESS_POINTS_FOR_OFFLINE_GROUP = gql`
    query getDevicesByOfflineGroup($pageSize: Int!, $offset: Int!, $groupOid: Int!) {
        getDevicesByOfflineGroup(limit: { rows: $pageSize, offset: $offset }, groupOid: $groupOid) {
            devices {
                accountId
                id
                name
                type
                controlLockType
            }
            totalCount
        }
    }
`;

export const ACCESS_POINTS_FOR_ONLINE_GROUP_HIERARCHY = gql`
    query getPropertyHierarchyAccessPoints($pageSize: Int!, $offset: Int!, $isOfflineGroup: Boolean, $siteOid: Int!) {
        getPropertyHierarchyAccessPoints(
            limit: { rows: $pageSize, offset: $offset }
            isOfflineGroup: $isOfflineGroup
            siteOid: $siteOid
        ) {
            devices {
                accountId
                id
                name
                type
                deviceId
                activationEnabled
                twoFactorEnabled
                controlLockType
            }
            totalCount
        }
    }
`;
