import React, { useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import ErrorBoundary from '../components/ErrorBoundary';
import { AWS_APPSYNC_GRAPHQL_URL, AWS_APPSYNC_REGION } from '../constants/Constants';
import GqlHelper from '../helpers/GqlHelper';
import { auth } from './Auth';
import { useFlagClient } from '@brivo/onairplus-services';
// this is to avoid error when creating multiple clients
// due to rerenders of this component at app init
// until we have the token populated
let prefix = 1;
let client;

// if it would be so easy to make a query, this is perfect. use the logic from inside this component
// treat the client as a singleton and instantiate it after the auth instance is doen.
/* const x = new AWSAppSyncClient();
x.query(); */

export const GQLProviderContext = React.createContext();

// an instance of Auth is used here. this should not prevent it from being a singleton
const CustomApolloProvider = ({ children }) => {
    const [gqlHelper, setGqlHelper] = useState();
    const flagClient = useFlagClient();
    if (!client) {
        if (auth.useAltAuthToken()) {
            client = new AWSAppSyncClient({
                url: AWS_APPSYNC_GRAPHQL_URL,
                region: AWS_APPSYNC_REGION,
                auth: {
                    type: AUTH_TYPE.AWS_LAMBDA,
                    token: async () => auth.getAccessToken(),
                },
                offlineConfig: {
                    keyPrefix: `dashboard-${++prefix}`,
                },
                disableOffline: true,
                cacheOptions: {
                    addTypename: false,
                },
            });
        } else {
            client = new AWSAppSyncClient({
                url: AWS_APPSYNC_GRAPHQL_URL,
                region: AWS_APPSYNC_REGION,
                auth: {
                    type: AUTH_TYPE.OPENID_CONNECT,
                    jwtToken: async () => auth.getAccessToken(),
                },
                offlineConfig: {
                    keyPrefix: `dashboard-${++prefix}`,
                },
                disableOffline: true,
                cacheOptions: {
                    addTypename: false,
                },
            });
        }
    }

    if (!gqlHelper) {
        // This is the ONLY use of setGqlHelper, making it essentially behave as a singleton
        // all client information is derived from the "auth" object which is an instance of Auth (see Auth.js)
        // which is ALSO treated as a singleton
        setGqlHelper(new GqlHelper(client, flagClient));
    }

    // how does apolloprovider use the auth client and the ldclient?
    // can a simple gql request be made using just the appsyncclient and the auth instance?
    return (
        <ErrorBoundary section={'Authentication'} auth={auth}>
            <ApolloProvider client={client}>
                <GQLProviderContext.Provider value={gqlHelper}>{children} </GQLProviderContext.Provider>
            </ApolloProvider>
        </ErrorBoundary>
    );
};

export default CustomApolloProvider;
