import React, { Suspense, lazy } from 'react';
import { JournalContextProvider } from './context/journalContext';

const JournalPage = lazy(() => import('./JournalPage'));

const JournalContainer = (props) => (
    <Suspense fallback={null}>
        <JournalContextProvider>
            <JournalPage {...props} />
        </JournalContextProvider>
    </Suspense>
);

export default JournalContainer;
