import { WebReqApi } from '../WebReqApi';
import { API_BASE_URL } from '@common/constants/Constants';

import {
    GET_SITES_TAG,
    GET_SITES_TAG_NO_GEOCODE,
    GET_SITES_BY_ID,
    GET_SITES_BY_NAME,
    SET_SITE_PARENT_ID_TAG,
    GET_SITES_FOR_FLOORPLANS,
    GET_PAGINATED_PROPERTIES_BY_PARENT,
    GET_PAGINATED_PROPERTIES_BY_NAME,
} from './gqlTags';

class SitesApi extends WebReqApi {
    getSites(args) {
        return this.makeGqlQuery({
            gqlTag: args?.includeGeocode && args?.includeGeocode === true ? GET_SITES_TAG : GET_SITES_TAG_NO_GEOCODE,
            defaultValue: [],
        });
    }

    getSitesById(id) {
        return this.makeGqlQuery({
            gqlTag: GET_SITES_BY_ID,
            variables: {
                id,
            },
            defaultValue: [],
        });
    }

    getSitesWithParentFiltering({ rows = 20, offset = 0, filters = { name: '', parentId: 0, siteType: 'site' } }) {
        const { name, parentId, siteType } = filters;
        return this.makeGqlQuery({
            gqlTag: GET_PAGINATED_PROPERTIES_BY_PARENT,
            variables: {
                siteType,
                name,
                parentId,
                rows,
                offset,
            },
            defaultValue: { properties: [], totalCount: 0 },
        });
    }

    getSitesWithFiltering({ rows = 20, offset = 0, filters = { name: '', siteType: 'site', hasNoChildren: false } }) {
        const { name, siteType, hasNoChildren } = filters;
        return this.makeGqlQuery({
            gqlTag: GET_PAGINATED_PROPERTIES_BY_NAME,
            variables: {
                siteType,
                name,
                hasNoChildren,
                rows,
                offset,
            },
            defaultValue: { properties: [], totalCount: 0 },
        });
    }

    getSitesForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_SITES_BY_NAME,
            variables: {
                name,
                rows,
            },
            defaultValue: [],
        });
    }

    getSitesForFloorPlans() {
        return this.makeGqlQuery({
            gqlTag: GET_SITES_FOR_FLOORPLANS,
            defaultValue: [],
        });
    }

    createSite(values) {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/sites`,
            body: {
                siteName: values.name,
                timeZone: values.address.timeZone,
                address: {
                    streetAddress1: values.address.streetAddress1,
                    streetAddress2: values.address.streetAddress2,
                    city: values.address.city,
                    state: values.address.state,
                    postalCode: values.address.postalCode,
                    country: values.address.country,
                },
            },
        });
    }

    editSite(values) {
        return this.makeRestRequest({
            method: 'PUT',
            url: `${API_BASE_URL}/sites/${values.id}`,
            body: {
                siteName: values.name,
                timeZone: values.address.timeZone,
                address: {
                    streetAddress1: values.address.streetAddress1,
                    streetAddress2: values.address.streetAddress2,
                    city: values.address.city,
                    state: values.address.state,
                    postalCode: values.address.postalCode,
                    country: values.address.country,
                },
            },
        });
    }

    deleteSite(siteId) {
        return this.makeRestRequest({
            method: 'DELETE',
            url: `${API_BASE_URL}/sites/:siteId`,
            urlParams: {
                siteId,
            },
        });
    }

    setParentId(id, parentObjectId) {
        return this.makeGqlMutation({
            gqlTag: SET_SITE_PARENT_ID_TAG,
            variables: {
                id: id,
                parentId: parentObjectId,
            },
        });
    }

    getSiteAccessPoints(id) {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/sites/${id}/access-points`,
            queryParams: {
                pageSize: '100',
                offset: '0',
            },
        });
    }

    getOfflineSites() {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/sites`,
            queryParams: {
                filter: 'type__eq:offline',
            },
        });
    }
}

export const sitesApi = new SitesApi();
