import { Column, Dimension, Filter } from '@/pages/NewReports/common/interfaces';

export const columnConfiguration: Column = {
    source: 'user_groups',
    id: 'user_group_name',
    uuid: 'user_group_name',
    alias: 'Group',
};

export const dimension: Dimension = {
    id: columnConfiguration.uuid,
    displayName: 'Groups',
    dataDefinition: {
        dataType: 'string',
    },
};
