import { makeStyles } from '@brivo/react-components';

const styles = makeStyles(() => {
    return {
        videoSection: {
            height: '428px',
        },
    };
});

export default styles;
