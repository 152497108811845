import gql from 'graphql-tag';

export const SAVE_VIDEO_PROVIDER_CREDENTIALS = gql`
    mutation saveVideoProviderCredentials($credentials: UsernamePassword!, $provider: VideoProvider!) {
        saveVideoProviderCredentials(credentials: $credentials, provider: $provider)
    }
`;

export const SAVE_VIDEO_PROVIDER_OAUTH_CREDENTIALS = gql`
    mutation saveVideoProviderOAuthCredentials($authorizationCode: String!, $provider: VideoProvider!) {
        saveVideoProviderOAuthCredentials(authorizationCode: $authorizationCode, provider: $provider)
    }
`;

export const GET_VIDEO_PROVIDER_DETAILS = gql`
    query getVideoProviderDetails($provider: VideoProvider!) {
        getVideoProviderDetails(provider: $provider) {
            name
            accountId
            created
            name
            username
            videoProviderId
            videoProviderType
            videoProviderTypeId
        }
    }
`;

export const REMOVE_VIDEO_PROVIDER_DETAILS = gql`
    mutation removeVideoProviderDetails($provider: VideoProvider!) {
        removeVideoProviderDetails(provider: $provider)
    }
`;
