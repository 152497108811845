import { useState, useCallback, useRef } from 'react';
import clsx from 'clsx';

import {
    MenuList,
    MenuItem,
    Paper,
    Popper,
    ClickAwayListener,
    DropdownCaretDownIcon,
    DropdownCaretUpIcon,
    Divider,
    Button,
} from '@brivo/react-components';

import TrimmedText from '../../../pages/BrivoAnalytics/components/TrimmedText';

import useStyles from './styles';
import { MINIMUM_MENU_WIDTH } from './constants';

const PageHeaderMenu = ({
    menuOptions,
    selectedOption,
    menuItemStyles,
    makeMenuWidthDynamic = false,
    placement = 'bottom',
    closeOnItemClick = false,
    footer = undefined,
}) => {
    const classes = useStyles();
    const [anchor, setAnchor] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [menuWidth, setMenuWidth] = useState(MINIMUM_MENU_WIDTH);
    const menuRef = useRef(null);

    const handleMenuClose = () => {
        setIsClicked(false);
        setAnchor(null);
    };

    const handleHeaderClick = useCallback(
        (event) => {
            let newMenuWidth = MINIMUM_MENU_WIDTH;
            if (menuRef?.current && menuRef.current?.offsetWidth > MINIMUM_MENU_WIDTH) {
                newMenuWidth = menuRef.current?.offsetWidth;
            }
            setMenuWidth(newMenuWidth);

            if (!isClicked) {
                setAnchor(event.currentTarget);
                setIsClicked(true);
            } else {
                handleMenuClose();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isClicked]
    );

    return (
        <div className="PageHeaderMenu" ref={menuRef}>
            <div className={classes.headerWrapper}>
                <Button
                    id="header-button"
                    text={<TrimmedText content={selectedOption?.name || ''} variant="h4" noWrap />}
                    endIcon={isClicked ? <DropdownCaretUpIcon /> : <DropdownCaretDownIcon />}
                    onClick={handleHeaderClick}
                    type="secondary"
                />
            </div>
            <Popper
                open={isClicked}
                anchorEl={anchor}
                onClose={handleMenuClose}
                transition
                placement={placement}
                style={makeMenuWidthDynamic && { width: menuWidth }}
            >
                <ClickAwayListener onClickAway={handleMenuClose}>
                    <Paper>
                        <div className={classes.scrollableArea}>
                            <MenuList open={isClicked} className={classes.list}>
                                {menuOptions?.length > 0
                                    ? menuOptions.map((menuOption) => {
                                          const Body = menuOption?.body;
                                          const Icon = menuOption?.icon;
                                          return (
                                              <MenuItem
                                                  onClick={() => {
                                                      menuOption.onClick();
                                                      if (closeOnItemClick) {
                                                          handleMenuClose();
                                                      }
                                                  }}
                                                  className={clsx(
                                                      classes.menuItems,
                                                      menuItemStyles ? menuItemStyles : null
                                                  )}
                                                  key={`dropDown-menuItem-${menuOption?.key}`}
                                                  selected={menuOption?.key === selectedOption?.key}
                                              >
                                                  {Body && <Body />}
                                                  {Icon && <Icon />}
                                              </MenuItem>
                                          );
                                      })
                                    : null}
                            </MenuList>
                        </div>
                        {footer && (
                            <div className="PageHeaderMenuFooter">
                                <Divider className={classes.divider} />
                                <div className={footer.additionalStyles}>
                                    <Button
                                        type="secondary"
                                        startIcon={footer.startIcon}
                                        text={footer.text}
                                        onClick={() => {
                                            footer.onClick();
                                            if (closeOnItemClick) {
                                                handleMenuClose();
                                            }
                                        }}
                                        id={footer.id}
                                        customWidth="100%"
                                    />
                                </div>
                            </div>
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

export default PageHeaderMenu;
