import React, { Suspense, lazy } from 'react';
import { DashboardConfigurationContextProvider } from './common/DashboardBuilderContext';
import { CHARTLET_WIDTH, CHARTLET_HEIGHT } from './components/KPI/KPIChartlet/constants';
import { useStyles } from './components/KPI/KPIChartlet/styles';

const BrivoAnalyticsPage = lazy(() => import('./BrivoAnalyticsPage'));

const BrivoAnalyticsContainer = (props) => {
    const classes = useStyles();

    return (
        <Suspense fallback={null}>
            <DashboardConfigurationContextProvider>
                <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <clipPath id="brivo-analytics-kpi-chartlet-cut-off">
                            <rect x="0" y="0" width={CHARTLET_WIDTH} height={CHARTLET_HEIGHT} />
                        </clipPath>
                        <linearGradient id="brivo-analytics-kpi-chartlet-green-gradient" x1="0" y1="0" x2="0" y2="1">
                            <stop
                                className={classes.green}
                                offset="10%"
                                stopColor="var(--green-stop-color)"
                                stopOpacity="0"
                            />
                            <stop
                                className={classes.green}
                                offset="100%"
                                stopColor="var(--green-stop-color)"
                                stopOpacity="0.8"
                            />
                        </linearGradient>
                        <linearGradient id="brivo-analytics-kpi-chartlet-red-gradient" x1="0" y1="0" x2="0" y2="1">
                            <stop
                                className={classes.red}
                                offset="10%"
                                stopColor="var(--red-stop-color)"
                                stopOpacity="0"
                            />
                            <stop
                                className={classes.red}
                                offset="100%"
                                stopColor="var(--red-stop-color)"
                                stopOpacity="0.8"
                            />
                        </linearGradient>
                    </defs>
                </svg>
                <BrivoAnalyticsPage {...props} />
            </DashboardConfigurationContextProvider>
        </Suspense>
    );
};

export default BrivoAnalyticsContainer;
