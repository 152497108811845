import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        '@keyframes load': {
            '0%': {
                transform: 'translateX(-100%)',
            },
            '100%': {
                transform: 'translateX(0)',
            },
        },
        buttonContainer: {
            position: 'relative',
            top: '0px',
            width: '307px',
            padding: '5px',
            height: '150px',
        },
        titleGridElement: {
            height: '25px',
        },
        snackbarTitle: {
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '16px',
        },
        iconButton: {
            position: 'absolute',
            top: '3px',
            right: '0',
            padding: '0',
            minWidth: '0',
            width: '25px',
            height: '25px',
        },
        doorInfo: {
            fontWeight: '700',
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
        },
        timeInfo: {
            fontWeight: '400',
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey200 : theme.palette.colors.grey600,
        },
        subtitleSpacer: {
            padding: '6px 0px 12px 0px',
        },
        buttonSpacer: {
            width: '80px',
            height: '8px',
        },
        btn: {
            borderRadius: '4px',
            textTransform: 'none',
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            width: '300px',
            backgroundColor:
                theme.palette.type === 'dark' ? theme.palette.colors.grey800 : theme.palette.colors.grey400,
        },
        loadingContainer: {
            paddingLeft: '80px',
            paddingTop: '4px',
            paddingBottom: '3px',
        },
        loadingIndicator: {
            position: 'relative',
            top: '-2px',
        },
        unlockMessage: {
            position: 'relative',
            top: '2px',
            marginTop: '2px',
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : theme.palette.colors.grey600,
        },
        unlockAttempted: {
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : theme.palette.colors.grey600,
        },
        loadingLine: {
            position: 'relative',
            height: '2px',
            overflow: 'hidden',
            width: '300px',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                animation: '$load 1s linear',
            },
        },
        loadingLineFailure: {
            '&::after': {
                backgroundColor:
                    theme.palette.type === 'dark' ? theme.palette.colors.red400 : theme.palette.colors.red800,
            },
        },
        loadingLineSuccess: {
            '&::after': {
                backgroundColor: theme.palette.colors.green500,
            },
        },
    };
});

export default styles;
