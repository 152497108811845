import React, { useEffect } from 'react';
import { get } from 'lodash';
import { makeStyles } from '@brivo/react-components';
import { useInAppMarketing } from '../../hooks/useInAppMarketing';

/** The purpose of this component is for use in targeted marketing campaigns (by Opt In Monster)
 * There is no display for this component, it only provides controlled access to relevant user data on the DOM.
 */

export const InAppMarketing = ({ context }) => {
    const user = get(context, 'userInfo', {});
    const inAppMessaging = useInAppMarketing();
    const featureEnabled = inAppMessaging.isEnabled();
    const isAllowed = inAppMessaging.isAllowed();

    const useStyles = makeStyles(() => ({
        hidden: {
            display: 'none',
        },
    }));

    let userMetadata = {
        AccountNumber: user.accountId,
        DealerName: user.dealerName,
        SnapshotEnabled: user.accountFeatures?.snapshotEnabled,
        Subscription: user.subscriptionLevel,
        UserId: user.userId,
        DealerAccountId: user.dealerAccountId,
    };

    useEffect(() => {
        inAppMessaging.initialize();
    }, [isAllowed, inAppMessaging]);

    //data must be assigned to window object for it to be accessed by third party app
    window.UserMetadata = userMetadata;

    //marketing provider can also derive data from querying the dom
    return featureEnabled && isAllowed ? (
        <ul className={useStyles.hidden} id="user-meta-data">
            {Object.keys(userMetadata)?.map((key) => (
                <li key={key}>
                    <input type="hidden" id={'user-' + key} value={userMetadata[key] || ''} />
                </li>
            ))}
        </ul>
    ) : (
        <></>
    );
};

export default InAppMarketing;
