import React from 'react';
import { SvgIcon } from '@brivo/react-components';

const FilledCircle = (props) => {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <circle cx={props.size} cy={props.size} r={props.size} fill={props?.fill || '#7DA6DB'} />
        </SvgIcon>
    );
};

export default FilledCircle;
