import gql from 'graphql-tag';

export const PREVIOUS_DAY_ACCESS_EVENTS_COUNT_QUERY = gql`
    query accessEventCountByHours($from: AWSDateTime, $to: AWSDateTime, $securityActionIdArray: [Int]) {
        getAccessEventCountByHours(
            filter: { occurred: { between: [$from, $to] }, securityActionId: { in: $securityActionIdArray } }
        ) {
            total
        }
    }
`;

export const PREVIOUS_DAY_EXCEPTION_EVENTS_COUNT_QUERY = gql`
    query accessEventCountByHours($from: AWSDateTime, $to: AWSDateTime, $securityActionIdArray: [Int]) {
        getAccessEventCountByHours(
            filter: {
                occurred: { between: [$from, $to] }
                securityActionId: { in: $securityActionIdArray }
                eventGroup: ALL_EVENTS
            }
        ) {
            total
        }
    }
`;

export const CURRENT_DAY_ACCESS_EVENTS_COUNT_QUERY = gql`
    query accessEventCountByHours($from: AWSDateTime, $to: AWSDateTime, $securityActionIdArray: [Int]) {
        getAccessEventCountByHours(
            filter: { occurred: { between: [$from, $to] }, securityActionId: { in: $securityActionIdArray } }
        ) {
            total
            hours {
                hour
                count
                actions {
                    securityActionId
                    count
                }
            }
        }
    }
`;

export const CURRENT_DAY_EXCEPTION_EVENTS_COUNT_QUERY = gql`
    query accessEventCountByHours($from: AWSDateTime, $to: AWSDateTime, $securityActionIdArray: [Int]) {
        getAccessEventCountByHours(
            filter: {
                occurred: { between: [$from, $to] }
                securityActionId: { in: $securityActionIdArray }
                eventGroup: ALL_EVENTS
            }
        ) {
            total
            hours {
                hour
                count
                actions {
                    securityActionId
                    count
                }
            }
        }
    }
`;

export const GET_EVENT_RANKING_CONFIG = gql`
    query getEventRankingConfig {
        getEventRankingConfig {
            accountId
            configId
            configType
            name
            siteId
            config {
                code
                hidden
                severity
            }
        }
    }
`;
