import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    paper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'width .5s',
        position: 'relative',
        width: '100%',
        zIndex: 0,
        backgroundColor: theme.palette.type === 'dark' ? theme.overrides.MuiPaper.root.backgroundColor : null,
        borderRight: 0,
    },
    drawerPaper: {
        height: '100%',
    },
    fullDrawer: {
        width: '100vw',
    },
    closeIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        cursor: 'pointer',
    },
    nav: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

export default styles;
