import { WebReqApi } from '../WebReqApi';
import { auth } from '../../utils/Auth';
import {
    API_UPSELL_LOGIN_EVENT,
    API_UPSELL_CONTACTUS_EVENT,
    API_UPSELL_DISMISSCAMPAIGN_EVENT,
    API_UPSELL_GETACTIVECAMPAIGN_EVENT,
    API_UPSELL_LEARNMORE_EVENT,
    API_UPSELL_VIEW_EVENT,
} from '@common/constants/Constants';

export class UpsellApi extends WebReqApi {
    constructor() {
        super();
        this.accessToken;
    }

    getRequestOptions = async (method = 'GET', body = null) => {
        if (!this.accessToken) {
            this.accessToken = await auth.getAccessToken();
        }

        let configObj = {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.accessToken}`,
            },
        };

        if ((method === 'POST' || method === 'PUT') && body) {
            configObj = {
                ...configObj,
                body: JSON.stringify(body),
            };
        }

        return configObj;
    };

    async getActiveCampaigns() {
        let response;
        const requestOptions = await this.getRequestOptions();

        try {
            response = await fetch(`${API_UPSELL_GETACTIVECAMPAIGN_EVENT}`, requestOptions);
            if (response.status !== 200) {
                throw Error('Error retrieving active campaigns.');
            }

            response = await response.json();
        } catch (e) {
            console.error(e);
        }

        return response;
    }

    /*
     * Makes API call, when user logs into account
     * @params
     *   adminId - a user's admin id
     */
    async userLogin() {
        const requestOptions = await this.getRequestOptions('PUT');
        try {
            const response = await fetch(`${API_UPSELL_LOGIN_EVENT}`, requestOptions);
            if (response.status !== 200) {
                throw Error('Error with user login for campaign.');
            }
        } catch (e) {
            console.error(e);
        }
    }

    /*
     * Makes API call, when user views upsell campaign
     * @params
     *    accountId - a user's account id
     *    campaign_id - the id of the campaign being viewed
     */
    async viewCampaign(accountId, campaignId) {
        const requestOptions = await this.getRequestOptions('PUT', {
            account_id: accountId,
            campaign_id: campaignId,
        });

        try {
            const response = await fetch(`${API_UPSELL_VIEW_EVENT}`, requestOptions);
            if (response.status !== 200) {
                throw Error('Error call for view campaign.');
            }
        } catch (e) {
            console.error(e);
        }
    }

    /*
     * Makes API call, when user does not want to see campaign anymore
     * @params
     *   accountId - a user's account id
     *   campaignId - the campaign that Contact Us was clicked on
     */
    async hideCampaign(accountId, campaignId) {
        const requestOptions = await this.getRequestOptions('PUT', {
            account_id: accountId,
            campaign_id: campaignId,
        });

        try {
            const response = await fetch(`${API_UPSELL_DISMISSCAMPAIGN_EVENT}`, requestOptions);
            if (response.status !== 200) {
                throw Error('Error call for hide campaign.');
            }
        } catch (e) {
            console.error(e);
        }
    }

    /*
     * Makes API call, when user has clicked 'Learn more' button
     * @params
     *   accountId - a user's account id
     *   campaignId - the campaign that Contact Us was clicked on
     */
    async learnMoreClick(accountId, campaignId) {
        const requestOptions = await this.getRequestOptions('PUT', {
            account_id: accountId,
            campaign_id: campaignId,
        });

        try {
            const response = await fetch(`${API_UPSELL_LEARNMORE_EVENT}`, requestOptions);
            if (response.status !== 200) {
                throw Error('Error call for learn more click for campaign.');
            }
        } catch (e) {
            console.error(e);
        }
    }

    /*
     * Makes API call, when user has clicked 'Contact us' button
     * @params
     *   accountId - a user's account id
     *   campaignId - the campaign that Contact Us was clicked on
     *   contactInfo - user's email, phone number, preferred time of contact
     */
    async contactUsClick({ accountId, campaignId, contactInfo }) {
        const requestOptions = await this.getRequestOptions('PUT', {
            account_id: accountId,
            campaign_id: campaignId,
            contact_info: contactInfo,
        });

        try {
            const response = await fetch(`${API_UPSELL_CONTACTUS_EVENT}`, requestOptions);
            if (response.status !== 200) {
                throw Error('Error call for contact us click for campaign.');
            }
        } catch (e) {
            console.error(e);
        }
    }
}

export const upsellApi = new UpsellApi();
