import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fill="#485769"
            d="M14.6629 15.3121C14.3983 15.0262 13.9447 15.0262 13.6801 15.2931C13.4533 15.5028 12.8863 15.7316 12.1302 15.7316C11.3743 15.7316 10.7883 15.5028 10.5804 15.2931C10.2969 15.0262 9.86212 15.0262 9.59752 15.3121C9.33292 15.5981 9.33292 16.0367 9.61637 16.3036C10.1456 16.8184 11.0906 17.1234 12.1301 17.1234C13.1696 17.1234 14.1146 16.8183 14.6438 16.3036C14.9085 16.0367 14.9275 15.579 14.6629 15.3121H14.6629Z"
        />
        <path
            fill="#485769"
            d="M8.25 8.92508V9.98337C8.25 10.3483 8.57033 10.6585 8.94719 10.6585C9.32403 10.6585 9.64437 10.3483 9.64437 9.98337V8.92508C9.64437 8.56019 9.32404 8.25 8.94719 8.25C8.57034 8.25 8.25 8.54197 8.25 8.92508Z"
        />
        <path
            fill="#485769"
            d="M11.25 11.3409V12.6591C11.25 13.1136 11.5946 13.5 12 13.5C12.4054 13.5 12.75 13.1136 12.75 12.6591V11.3409C12.75 10.8864 12.4054 10.5 12 10.5C11.5946 10.5 11.25 10.8637 11.25 11.3409V11.3409Z"
        />
        <path
            fill="#485769"
            d="M15.8758 9.98337V8.92508C15.8758 8.56019 15.5555 8.25 15.1786 8.25C14.8018 8.25 14.4814 8.56017 14.4814 8.92508V9.98337C14.4814 10.3483 14.8018 10.6585 15.1786 10.6585C15.5555 10.6585 15.8758 10.3483 15.8758 9.98337Z"
        />
        <path
            fill="#485769"
            d="M6 21C4.34315 21 3 19.6569 3 18V13.5C3.82843 13.5 4.5 14.1716 4.5 15V18C4.5 18.8284 5.17157 19.5 6 19.5H9C9.82843 19.5 10.5 20.1716 10.5 21H6ZM21 13.5C20.1716 13.5 19.5 14.1716 19.5 15V18C19.5 18.8284 18.8284 19.5 18 19.5H15C14.1716 19.5 13.5 20.1716 13.5 21H18C19.6569 21 21 19.6569 21 18V13.5ZM13.5 3C13.5 3.82843 14.1716 4.5 15 4.5H18C18.8284 4.5 19.5 5.17157 19.5 6V9C19.5 9.82843 20.1716 10.5 21 10.5V6C21 4.34315 19.6569 3 18 3H13.5ZM10.5 3C10.5 3.82843 9.82843 4.5 9 4.5H6C5.17157 4.5 4.5 5.17157 4.5 6V9C4.5 9.82843 3.82843 10.5 3 10.5V6C3 4.34315 4.34315 3 6 3H10.5Z"
        />
    </React.Fragment>,
    'RecentActivityUsingFaceprint'
);
