import React, { Suspense, lazy } from 'react';

const AccessDenied = lazy(() => import('./AccessDenied'));

const AccessDeniedContainer = (props) => (
    <Suspense fallback={null}>
        <AccessDenied {...props} />
    </Suspense>
);

export default AccessDeniedContainer;
