import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DialogActions, DialogContent, GenericDialog, Grid, Snackbar } from '@brivo/react-components';

import PropertySelector from '@pages/AccountSettings/Sites/Properties/PropertyWrapper/PropertyDetails/PropertyPageContent/DeviceSections/components/PropertySelector/PropertySelector';
import { propertiesApi } from '@common/webApis/properties/propertiesApi';
import Optional from '@common/components/Optional';

const TransformSiteModal = ({ open, closeModal, reloadList }) => {
    const { t } = useTranslation();
    const notifications = Snackbar();
    const [siteToTransform, setSiteToTransform] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
        if (!siteToTransform?.id) {
            return;
        }
        try {
            setIsLoading(true);
            await propertiesApi.transformProperty(siteToTransform.id);
            reloadList();
            notifications.addSuccessMessage({
                text: t('Page.property-list.transform-modal.message.success', { name: siteToTransform.name }),
            });
            onClose();
        } catch (e) {
            notifications.addErrorMessage({
                text: t('Page.property-list.transform-modal.message.error', { name: siteToTransform.name }),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onClose = () => {
        closeModal();
        setSiteToTransform('');
    };
    return (
        <GenericDialog
            open={open}
            onClose={onClose}
            data-testid="transform-site-modal"
            title={t('Page.property-list.transform-modal.title')}
        >
            <DialogContent>
                <Optional condition={open}>
                    <Grid container direction="row">
                        <Grid item xs={11}>
                            <PropertySelector
                                selectedSite={siteToTransform}
                                setSelectedSite={setSiteToTransform}
                                inputLabel={t('Page.property-list.transform-modal.input.label')}
                                type={'site'}
                            />
                        </Grid>
                    </Grid>
                </Optional>
            </DialogContent>
            <DialogActions>
                <Button
                    type="secondary"
                    onClick={onClose}
                    text={t('Page.property-list.transform-modal.cancel')}
                    id={'secondary-button'}
                />
                <Button
                    onClick={handleSave}
                    text={t('Page.property-list.transform-modal.save')}
                    id={'primary-button'}
                    disabled={isLoading}
                />
            </DialogActions>
        </GenericDialog>
    );
};

export default TransformSiteModal;
