import {
    DEVICES_WITH_STATUS_QUERY,
    CONFIG_BY_PANEL_QUERY,
    GET_ALL_PANELS_PER_SITES,
    GET_DEVICE_SITE_ID,
    GET_DEVICES_FOR_JOURNAL_PAGE,
    GET_USERS_AND_ADMINS_BY_BDS_ID,
    GET_DEVICES_FOR_GROUP,
    GET_DEVICES_FOR_GROUP_WITH_FILTERING,
} from './gqlTags';
import { WebReqApi } from '../WebReqApi';
import { MAX_PAGE_SIZE_GRAPHQL } from '../../constants/Constants';

class DevicesApi extends WebReqApi {
    fetchDevices(siteId) {
        return this.makeGqlQuery({
            gqlTag: DEVICES_WITH_STATUS_QUERY,
            variables: {
                offset: 0,
                pageSize: MAX_PAGE_SIZE_GRAPHQL,
                sitesOids: siteId,
            },
        });
    }

    fetchPanelsForSite(siteId) {
        return this.makeGqlQuery({
            gqlTag: GET_ALL_PANELS_PER_SITES,
            variables: {
                offset: 0,
                pageSize: MAX_PAGE_SIZE_GRAPHQL,
                sitesOids: siteId,
            },
        });
    }

    fetchPanelConfig(panelId) {
        return this.makeGqlQuery({
            gqlTag: CONFIG_BY_PANEL_QUERY,
            variables: {
                panelId,
            },
        });
    }

    getDeviceSiteId(deviceOId) {
        return this.makeGqlQuery({
            gqlTag: GET_DEVICE_SITE_ID,
            variables: {
                deviceOId, //typo in backend
            },
        });
    }

    getDevicesForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_DEVICES_FOR_JOURNAL_PAGE,
            variables: {
                name,
                rows,
            },
            defaultValue: [],
        });
    }

    getUsersAndAdminsByBdsId({ doorStationId, pageSize, offset, filters }) {
        return this.makeGqlQuery({
            gqlTag: GET_USERS_AND_ADMINS_BY_BDS_ID,
            variables: {
                doorStationId,
                pageSize,
                offset,
                filters,
            },
            defaultValue: [],
        });
    }

    getDevicesForGroup({ groupId, pageSize, offset }) {
        return this.makeGqlQuery({
            gqlTag: GET_DEVICES_FOR_GROUP,
            variables: {
                groupOid: groupId,
                pageSize,
                offset,
            },
            defaultValue: [],
        });
    }

    async getDevicesForGroupWithFiltering({ groupId, pageSize, offset, filters }) {
        return await this.makeGqlQuery({
            gqlTag: GET_DEVICES_FOR_GROUP_WITH_FILTERING,
            variables: {
                groupOid: groupId,
                pageSize,
                offset,
                filters,
            },
            defaultValue: { devices: [], totalCount: 0 },
        });
    }
}

export const devicesApi = new DevicesApi();
