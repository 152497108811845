/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    ArrowBack,
    Paper,
    TableRow,
    TableCell,
    Grid,
    Button,
    getHeightFromWindowSize,
    LoadingIndicator,
    MultiSelectFilter,
    Table,
    SaveIcon,
    ErrorAlert,
    InfoAlert,
    EditIcon,
    Snackbar,
} from '@brivo/react-components';
import clsx from 'clsx';

import { cancellablePromise } from '../../../../common/utils/promiseUtils';
import { roverApi } from '../../../../common/webApis/rover/roverApi';
import PageHeader from '../../../../common/components/PageHeader/PageHeader';
import { DashboardConfigurationContext } from '../../common/DashboardBuilderContext';
import SaveView from '../Save/SaveViews';

import TableHeader from '../TableHeader';
import RenameDialog from '../RenameDialog';
import NoVisualFound from '../NoVisualFound';
import TrimmedText from '../TrimmedText';

import {
    COLUMN_DESIGNATION,
    DATA_EXPLORER_TABLES_KEY,
    SORTABLE_DATA_TYPES,
    columnsDataConfiguration,
} from './constants';
import useStyles from './styles';
import { sortQueryResult, cellHandler, setTableHeight, setEditingTableHeight } from './utils';
import { useAnalyticsTableSetup } from './useAnalyticsTableSetup';
import EmptyResultsMessage from './EmptyResultsMessage';
import ErrorResultsMessage from './ErrorResultsMessage';
import ExportDialog from './ExportDialog';
import UserTracking from '@common/utils/UserTracking';
import {
    APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL,
    DATA_EXPLORER_TRACKING_METRICS,
    DATA_EXPLORER_TRACKING_STATUS,
} from '@common/constants/Constants';

function replaceNullValuesWithDash(list) {
    return list?.map((obj) => {
        Object.keys(obj).forEach((key) => {
            if (obj[key] === null) {
                obj[key] = '-';
            }
        });
        return obj;
    });
}

let initialLoadTime = null;

const MyAnalyticsTable = ({
    address,
    configuration,
    idx,
    queryModificationCallback,
    isPreview,
    isEditViewEnabled,
    isEditing,
    noVisualFound,
    dashboardConf,
    setDashboardConf,
    hideCaption,
    numberOfRows,
    myAssistantView = false,
    onResponseDataChange = () => {},
    onLoadingStateChange = () => {},
    tableHeight,
}) => {
    const [
        {
            addOrModifyGlobalFilter,
            getCurrentDashboardId,
            getDashboardsConfigurations,
            getGlobalFilters,
            currentDashboardId: contextDashboardId,
            roverApiProxy,
            addOrModifyOpenStateForTables,
            getTableOpenState,
        },
    ] = useContext(DashboardConfigurationContext);

    const {
        configuration: configurationState,
        pageHeader,
        isDashboard,
    } = getDashboardsConfigurations(getCurrentDashboardId());

    const isDataExplorerTable = configurationState?.key === DATA_EXPLORER_TABLES_KEY;

    const { header, headerKey, isDrilldown } = { ...configuration, ...configurationState };

    const {
        query,
        dimensions,
        data,
        loadMoreData,
        setData,
        metadata,
        summaryData,
        hasMore,
        loading,
        errors,
        isLoading,
        setIsLoading,
        loadTable,
    } = useAnalyticsTableSetup(
        configuration?.query ?? configuration?.queryKey,
        configuration?.dimensions,
        configuration?.summary,
        queryModificationCallback,
        (isPreview ?? false) || getTableOpenState(configurationState?.key, idx) || !isDataExplorerTable
    );

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [isExportDialogOpen, setExportDialogOpen] = useState(false);
    const [allSites, setAllSites] = useState(null);
    const [selectedSites, setSelectedSites] = useState([]);
    const [downloadButtonActive, setDownloadButtonActiveStatus] = useState(true);
    const { addSuccessMessage, addErrorMessage } = Snackbar();
    const dashboardId = getCurrentDashboardId();
    const [hasErrors, setHasErrors] = useState(false);
    const [isSaveDialogOpen, toggleSaveDialog] = useState(false);
    const [showInfo] = useState(true);
    const [isTableOpen, setTableOpenState] = useState(
        (isPreview ?? false) || getTableOpenState(configurationState?.key, idx) || !isDataExplorerTable || isEditing
    );

    const editTableRedirect = () => {
        const url = `${APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL}/visual-editor/pivot/${dashboardId}/@${address}`;
        history.push(url);
    };

    const toggleTableOpenState = (isOpen, idx) => {
        addOrModifyOpenStateForTables(configurationState?.key, isOpen, idx);
        setTableOpenState(isOpen);
        if (isOpen && !data) {
            loadTable(configuration?.query ?? configuration?.queryKey, configuration?.dimensions, true);
        }
    };

    const dataDefinitions = useMemo(() => {
        const tempDef = dimensions.map((dim) => {
            return {
                id: dim.id,
                dataType:
                    dim.dataDefinition?.dataType ?? columnsDataConfiguration[dim.id]?.type
                        ? columnsDataConfiguration[dim.id]?.type
                        : 'string',
                sortable:
                    dim.sortable ?? columnsDataConfiguration[dim.id]?.sortable
                        ? columnsDataConfiguration[dim.id]?.sortable
                        : false,
            };
        });

        if (metadata) {
            const columnTypes = metadata?.column_types ?? [];
            const columnTypesMap = Object.fromEntries(columnTypes.map((type) => [type.uuid, type.column_type]));
            tempDef.forEach((def) => {
                if (!def.dataType && columnTypesMap[def.id]) {
                    def.dataType = columnTypesMap[def.id];
                }
            });
        }

        return tempDef;
    }, [metadata, dimensions]);

    const [isAsc, setIsAsc] = useState(
        dimensions ? Object.fromEntries(configuration.dimensions.map((col) => ({ [col.id]: true }))) : true
    );

    const openExportDialog = () => {
        setExportDialogOpen(true);
    };

    const closeDialog = () => {
        setExportDialogOpen(false);
    };

    const removeTable = (uuid) => {
        const visuals = dashboardConf.visuals.filter((item) => item.uuid !== uuid);
        setDashboardConf({ ...dashboardConf, visuals });
    };

    const downloadReport = (filename) => {
        closeDialog();
        const startTime = performance.now();
        let endTime;
        if (filename && query && query.query?.columns?.length > 0 && dimensions?.length > 0) {
            setDownloadButtonActiveStatus(false);
            addSuccessMessage({ text: t('Page.brivo-analytics.report-requested-notification') });

            const { promise: dataPromise, cancel: dataCancel } = roverApiProxy.getReportAsync_V2(query);
            dataPromise
                ?.then(async (result) => {
                    const url = result.url;
                    await fetch(url, {
                        method: 'GET',
                        mode: 'cors',
                    })
                        .then((response) => response.blob())
                        .then((data) => {
                            const urlResponse = window.URL.createObjectURL(data);
                            const link = document.createElement('a');
                            link.href = urlResponse;
                            link.setAttribute('download', `${filename.replace(/\.csv$/, '')}.csv`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .then(() => {
                            endTime = performance.now();
                            UserTracking.track(DATA_EXPLORER_TRACKING_METRICS.TableDownloadCSVEvent, {
                                time: `${endTime - startTime} milliseconds`,
                                status: DATA_EXPLORER_TRACKING_STATUS.Success,
                            });
                        });
                })
                .catch((error) => {
                    endTime = performance.now();
                    UserTracking.track(DATA_EXPLORER_TRACKING_METRICS.TableDownloadCSVEvent, {
                        time: `${endTime - startTime} milliseconds`,
                        status: `${DATA_EXPLORER_TRACKING_STATUS.Error}: ${error}`,
                    });
                    addErrorMessage({ text: t('Page.brivo-analytics.report-generation-failed-notification') });
                })
                .finally(() => {
                    setDownloadButtonActiveStatus(true);
                });
            return dataCancel;
        } else {
            addErrorMessage({ text: t('Page.brivo-analytics.report-request-failure-notification') });
        }
    };

    const sortDataCallback = useCallback(
        (id) => {
            if (data) {
                setData(
                    sortQueryResult({
                        queryResult: [...data],
                        sortColumn: {
                            key: id,
                            dataType: dataDefinitions.filter((def) => def.id === id)[0].dataType,
                            order: !isAsc[id] === true ? 'asc' : 'desc',
                        },
                    })
                );
            }
            setIsAsc((oldAsc) => {
                oldAsc[id] = !oldAsc[id];
                return { ...oldAsc };
            });
        },
        [isAsc, setIsAsc, setData, data, dataDefinitions]
    );

    const columns = useMemo(() => {
        const columnTypes = metadata?.column_types ?? [];
        return dimensions
            ?.filter((dim) => {
                return dim.designation === COLUMN_DESIGNATION || dim.designation === undefined;
            })
            ?.sort((a, b) => b.level - a.level)
            ?.map((col) => {
                const columnMetadata = columnTypes.find((type) => type.uuid === col.id);
                const structuredColumn = {
                    Header: col?.displayName ?? col?.id,
                    id: col.uuid ?? col.id,
                    Cell: (arg) => {
                        const {
                            columns,
                            column,
                            row: { index },
                            rows,
                        } = arg;
                        const rowsNum = rows.length;
                        if (hasMore && rows[rowsNum - 1].index === index && column.id === columns[0].id) {
                            return cellHandler(
                                arg.row.original,
                                col.id,
                                columnMetadata?.column_type ??
                                    col.dataDefinition?.dataType ??
                                    columnsDataConfiguration[col.id]?.type,
                                loadMoreData
                            );
                        } else {
                            return cellHandler(
                                arg.row.original,
                                col.id,
                                columnMetadata?.column_type ??
                                    col.dataDefinition?.dataType ??
                                    columnsDataConfiguration[col.id]?.type
                            );
                        }
                    },
                };
                if (col.sortable === true || SORTABLE_DATA_TYPES.includes(columnMetadata?.column_type)) {
                    return {
                        ...structuredColumn,
                        backendSortCallback: () => sortDataCallback(col.id),
                        isSortedDesc: !isAsc[col.id],
                        sortType: 'ialphanumeric',
                        isBackendSortAsc: isAsc[col.id],
                    };
                } else {
                    return structuredColumn;
                }
            });
    }, [isAsc, data, metadata, dimensions, configuration, sortDataCallback, hasMore, loadMoreData]); // eslint-disable-line react-hooks/exhaustive-deps

    let menuItems = [];
    if (myAssistantView) {
        menuItems = [
            {
                handleClick: openExportDialog,
                text: 'Download All Records',
                id: 1,
                disabled: !downloadButtonActive,
            },
        ];
    } else {
        menuItems = [
            myAssistantView === false && {
                handleClick: editTableRedirect,
                text: t('Page.brivo-analytics.drilldown-table.edit-button-text'),
                id: 0,
            },
            {
                handleClick: openExportDialog,
                text: t('Page.brivo-analytics.drilldown-table.download-button-text'),
                id: 1,
                disabled: !downloadButtonActive,
            },
        ];
    }

    useEffect(() => {
        const { promise, cancel } = cancellablePromise(roverApi.getSites());
        promise.then(
            (data) => {
                setAllSites(data.getSites);
            },
            (e) => {
                throw e;
            }
        );
        return cancel;
    }, []);

    useEffect(() => {
        const currentGlobalFilters = getGlobalFilters(contextDashboardId) ?? {};
        setSelectedSites(currentGlobalFilters.sites);
    }, [contextDashboardId, getGlobalFilters]);

    const updateCurrentDashboardSites = useCallback(
        (value) => {
            const currentGlobalFilters = getGlobalFilters(contextDashboardId) ?? {};
            if (value?.length > 0) {
                Object.assign(currentGlobalFilters, {
                    sites: value,
                });
            } else {
                delete currentGlobalFilters?.sites;
            }

            addOrModifyGlobalFilter(contextDashboardId, currentGlobalFilters);
        },

        [addOrModifyGlobalFilter, contextDashboardId, getGlobalFilters]
    );

    useEffect(() => {
        // Data is null until it is given a value
        // The value will either be undefined or an array
        onResponseDataChange(data);
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const setExplicitTableHeight = () => {
        return tableHeight;
    };
    const tableHeightFunction =
        tableHeight !== undefined ? setExplicitTableHeight : isEditing ? setEditingTableHeight : setTableHeight;

    const dataWithNoNullValues = replaceNullValuesWithDash(data);
    const numberOfTableRows = numberOfRows ? dataWithNoNullValues?.slice(0, numberOfRows) : dataWithNoNullValues;

    useEffect(() => {
        onLoadingStateChange(loading);

        if (!loading) {
            UserTracking.track(DATA_EXPLORER_TRACKING_METRICS.TableLoadEvent, {
                time: `${performance.now() - initialLoadTime} milliseconds`,
            });
        }
    }, [loading]);

    // Workaround as rerendering logic is not working correctly
    useEffect(() => {
        if (!initialLoadTime) {
            initialLoadTime = performance.now();
        }
    }, []);

    return (
        <>
            {!isLoading ? (
                <>
                    {!isDashboard && !isPreview && (
                        <Grid item xs={12} className={classes.pageHeader}>
                            <Header {...pageHeader} isDrilldown={isDrilldown} />
                            {isDrilldown && (
                                <div className={classes.headerButtonsDiv}>
                                    <MultiSelectFilter
                                        key={`Sites-Selector-${contextDashboardId}`}
                                        id="sites"
                                        label={t('Page.brivo-analytics.my-analytics.global-sites-filter.label')}
                                        noSelectionLabel={t(
                                            'Page.brivo-analytics.my-analytics.global-sites-filter.no-selection-label'
                                        )}
                                        items={allSites}
                                        mapper={(item) => ({
                                            id: item.id,
                                            name: item.name,
                                        })}
                                        value={selectedSites}
                                        onValueChange={updateCurrentDashboardSites}
                                        noResultMessage={t('Component.multi-select-filter.no-options')}
                                        showMoreText={t('Page.event-tracker-filter.show-more')}
                                    />
                                    <Button
                                        id="edit-button"
                                        type="secondary"
                                        onClick={() => toggleSaveDialog(true)}
                                        text={t('Page.brivo-analytics.drilldown-table.make-a-copy')}
                                        size="medium"
                                    />
                                    <Button
                                        text={t('Page.brivo-analytics.drilldown-table.download-dialog.download-button')}
                                        id="download-button"
                                        size="medium"
                                        type="primary"
                                        startIcon={
                                            downloadButtonActive ? (
                                                <SaveIcon />
                                            ) : (
                                                <LoadingIndicator padding={1} size="small" />
                                            )
                                        }
                                        onClick={openExportDialog}
                                        disabled={!downloadButtonActive}
                                    />
                                </div>
                            )}
                        </Grid>
                    )}
                    {hasErrors && (
                        <div className={classes.errorAlert}>
                            <ErrorAlert
                                errorMsg={t('Page.brivo-analytics.save-view.error.title')}
                                secondaryErrorMsg={t('Page.brivo-analytics.save-view.error.explanation')}
                            />
                        </div>
                    )}

                    {!isDashboard && showInfo && !hasErrors && (
                        <div className={classes.errorAlert}>
                            <InfoAlert infoMessage={t('Page.brivo-analytics.drilldown-table.info')} />
                        </div>
                    )}
                    <div id="my-analytics-table">
                        <Paper
                            className={clsx(
                                !isDashboard || isEditing ? classes.viewTablePaper : classes.tablePaper,
                                isPreview && classes.tablePaperNoHeader
                            )}
                            elevation={myAssistantView ? 0 : 1} // 0 = no box-shadow, 1 = default box-shadow styling
                        >
                            {/* TODO: Remove myAssistantView conditional and add menuItems only when table has been fully rendered */}
                            {isDashboard && !isPreview && (myAssistantView ? !loading : true) ? (
                                <TableHeader
                                    headerKey={headerKey}
                                    header={myAssistantView ? undefined : header}
                                    handleRemoveTable={removeTable}
                                    isEditing={isEditViewEnabled}
                                    myAssistantView={myAssistantView}
                                    isPivotTable={false}
                                    isDataExplorerTable={isDataExplorerTable}
                                    isTableOpen={isTableOpen}
                                    handleToggleTable={toggleTableOpenState}
                                    idx={idx}
                                    isCaretDisabled={loading}
                                    menuItems={menuItems}
                                    uuid={configuration.uuid}
                                />
                            ) : null}

                            {noVisualFound ? (
                                <div
                                    style={{
                                        height: `${
                                            isEditing
                                                ? setEditingTableHeight()
                                                : !isDashboard
                                                ? setTableHeight()
                                                : getHeightFromWindowSize()
                                        }px`,
                                    }}
                                    className={classes.noVisualFoundWrapper}
                                >
                                    <NoVisualFound
                                        title={t('Page.brivo-analytics.pivot-table-editor.no-visual-found.title')}
                                        subtitle={t(
                                            'Page.brivo-analytics.pivot-table-editor.no-visual-found.subtitle.columns'
                                        )}
                                    />
                                </div>
                            ) : loading ? (
                                <LoadingIndicator />
                            ) : data?.length > 0 && !errors?.dataRetrieval ? (
                                isTableOpen ? (
                                    // If less than 10 data items, then remove extra white space created by table
                                    <div className={numberOfTableRows.length < 10 && classes.tableWrapper}>
                                        <Table
                                            id={`myAnalyticsTable-${configurationState?.type}-${configurationState?.header}`}
                                            columns={columns}
                                            data={numberOfTableRows}
                                            heightFunction={tableHeightFunction}
                                            clickToSortMessage={t('Table.column.header.sort')}
                                        />
                                        {summaryData ? (
                                            <TableRow>
                                                <TableCell>
                                                    {configuration?.summary?.label ? configuration.summary.label : ''}
                                                </TableCell>
                                                <TableCell>{summaryData}</TableCell>
                                            </TableRow>
                                        ) : null}
                                    </div>
                                ) : null
                            ) : errors?.dataRetrieval ? (
                                <ErrorResultsMessage hideCaption={hideCaption} />
                            ) : (
                                isTableOpen && <EmptyResultsMessage hideCaption={hideCaption} />
                            )}
                        </Paper>
                        {isExportDialogOpen && (
                            <ExportDialog
                                isOpen={isExportDialogOpen}
                                closeDialog={closeDialog}
                                downloadReport={downloadReport}
                                inputPlaceholder={t('Page.brivo-analytics.drilldown-table.download-dialog.placeholder')}
                                fileName={pageHeader?.title}
                            />
                        )}
                        <SaveView
                            showSaveButtonBar={false}
                            dashboardId={dashboardId}
                            currentConfig={configurationState}
                            openDialog={isSaveDialogOpen}
                            setOpenDialog={toggleSaveDialog}
                            isDashboard={isDashboard}
                            pageHeader={pageHeader}
                            isDataLoading={loading}
                            handleSubmitChanges={setIsLoading}
                            setHasErrors={setHasErrors}
                            isKpiTable={!isDashboard}
                        />
                    </div>
                </>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};

export default MyAnalyticsTable;

export const Header = ({ button, title, isDrilldown, handleRename }) => {
    const [
        {
            addOrModifyGlobalFilter,
            getGlobalFilters,
            currentDashboardId: contextDashboardId,
            removeDashboardConfigurationById,
        },
    ] = useContext(DashboardConfigurationContext);

    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    const applyGlobalFiltersToNewDashboardId = (newDashboardId) => {
        const currentGlobalFilters = getGlobalFilters(contextDashboardId) ?? {};
        addOrModifyGlobalFilter(newDashboardId, currentGlobalFilters);
    };

    const handleClickBack = () => {
        applyGlobalFiltersToNewDashboardId(button.dashboardId);
        history.push({ pathname: button.url, state: { dashboardId: button.dashboardId } });

        if (button.KPIId) {
            removeDashboardConfigurationById(button.KPIId);
        }
    };

    const [isEditDialogOpen, setIsDialogOpen] = useState(false);
    const handleRenameHeader = (name) => {
        handleRename(name);
        setIsDialogOpen(false);
    };

    return (
        <div data-testid="myAnalyticsTable-pageHeader">
            <div className="u-mb-full" data-testid="myAnalyticsTable-backButton">
                <Button
                    id="back-button"
                    size="small"
                    type="tertiary"
                    onClick={handleClickBack}
                    startIcon={<ArrowBack />}
                    text={button?.text}
                />
            </div>

            {!isDrilldown ? (
                <span className={classes.sectionTitleButton}>
                    <Button
                        id="section-title-button"
                        endIcon={<EditIcon />}
                        onClick={() => setIsDialogOpen(true)}
                        text={
                            <TrimmedText
                                content={title}
                                color="textSecondary"
                                id="page-title"
                                className="u-trim"
                                title={title}
                                variant="h4"
                            />
                        }
                    />
                </span>
            ) : (
                <PageHeader title={title} />
            )}
            <RenameDialog
                title={t('Page.brivo-analytics.pivot-table-editor.rename-dialog.title')}
                onRenameChange={handleRenameHeader}
                isOpen={isEditDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                nameInputProps={{
                    placeholder: t('Page.brivo-analytics.my-analytics.rename-section.dialog.placeholder'),
                    value: title,
                }}
            />
        </div>
    );
};
