import { useState, useCallback, useEffect, useContext } from 'react';
import { MultiSelectFilter } from '@brivo/react-components';
import { useQueryEditor } from '@/pages/NewReports/components/TableEditor/QueryEditorContext';
import { GQLProviderContext } from '@/common/utils/CustomApolloProvider';
import { useTranslation } from 'react-i18next';
import { columnConfiguration } from './constants';
import { Filter } from '@/pages/NewReports/common/interfaces';
import { withApi } from '@brivo/onairplus-services';
import DynamicReportingApi from '@/common/helpers/DynamicReportingApi';
import { createFilterConfiguration } from '@/pages/NewReports/common/utils';

interface Groups {
    [key: string]: string;
}

interface GroupsProps {
    disabled: boolean;
    filter: Filter;
    api: any;
}

const Groups = ({ disabled, filter, api }: GroupsProps) => {
    const [selectedSites, setSelectedGroups] = useState([]);
    const gqlHelper = useContext(GQLProviderContext);
    const [groups, setGroups] = useState([]);
    const QueryEditor: any = useQueryEditor();
    const { t } = useTranslation();

    // TODO: Improve searching later, currently runtime of n-squared
    useEffect(() => {
        if (groups.length > 0 && filter) {
            let selectedFilteredGroups: any = filter.test_values?.map((test_value: any) =>
                groups.find((device: any) => device.value === test_value.value)
            );
            selectedFilteredGroups = selectedFilteredGroups?.filter((filteredGroup) => filteredGroup !== undefined);

            setSelectedGroups(selectedFilteredGroups);
        }
    }, [filter, groups]);

    useEffect(() => {
        if (api && gqlHelper) {
            api.setAppSyncClient(gqlHelper);
            getAndSetGroups();
        }
    }, [api, gqlHelper]);

    const getAndSetGroups = async () => {
        const retrievedGroups = await api.getGroups();
        setGroups(retrievedGroups);
    };

    const handleMultiSelectFilterChange = (event: any) => {
        const newGroups = event;
        setSelectedGroups(newGroups);

        const formattedGroupValues = newGroups.map((group: any) => ({
            source: 'constant',
            type: 'string',
            value: group.value,
        }));

        const newFilter = createFilterConfiguration(columnConfiguration, formattedGroupValues);

        if (newGroups.length === 0) {
            QueryEditor.removeFilter(newFilter);
        } else {
            // groupsFilter.test_values = test_values;
            QueryEditor.addOrUpdateFilter(newFilter);
        }
    };

    const groupsDropdownMapper = useCallback((item) => ({ id: item.id, name: item.value }), []);

    return (
        <MultiSelectFilter
            disabled={disabled}
            limit={1}
            id="groups"
            label={t('Page.new-reports.column-filter.groups')}
            noSelectionLabel={t('Page.new-reports.column-filter.groups.no-selection-label')}
            items={groups}
            mapper={groupsDropdownMapper}
            value={selectedSites}
            onValueChange={handleMultiSelectFilterChange}
            noResultMessage={t('Page.new-reports.column-filter.no-options')}
            clearingValues={true}
            showMoreText={t('Page.new-reports.column-filter.show-more')}
        />
    );
};

export default withApi(Groups, DynamicReportingApi);
