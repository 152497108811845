import gql from 'graphql-tag';

export const GET_GROUPS_QUERY = gql`
    query Groups($pageSize: Int!, $offset: Int!, $sortField: String, $direction: String, $filters: [ListFilter]) {
        getAllGroups(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            groups {
                id
                name
                created
                activeUserCount
                totalUserCount
                trackUser
            }
        }
    }
`;

export const GET_ALLOWED_VISITORS_GROUPS_QUERY = gql`
    query Groups($pageSize: Int!, $offset: Int!, $sortField: String, $direction: String, $filters: [ListFilter]) {
        getAllGroups(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            groups {
                id
                name
                created
                activeUserCount
                totalUserCount
                trackUser
            }
        }
    }
`;

export const CREATE_GROUP = gql`
    mutation createGroup($name: String!, $excludedFromLockdown: Boolean) {
        createGroup(name: $name, excludedFromLockdown: $excludedFromLockdown) {
            id
        }
    }
`;
