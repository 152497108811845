import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import {
    Box,
    Button,
    GenericDialog,
    DialogActions,
    Typography,
    SingleSelectField,
    Autocomplete,
    TextField,
    InputLabel,
} from '@brivo/react-components';
import { useFlagClient } from '@brivo/onairplus-services';

import { TimeZone, getTimeZones } from '@common/utils/timeZones';
import NameInput from '@pages/BrivoAnalytics/components/RenameDialog/NameInput';
import { UserContext } from '../../../../user/UserProvider';
import { upsellApi } from '../../../../webApis/upsell/upsellApi';
import { Campaign } from '../../interfaces';
import useStyles from './styles';

interface ContactUsModalProps {
    onDismissModal: () => void;
    campaign: Campaign;
    onContactUsFormSubmit: () => void;
}

const validatePhoneNumber = (newPhoneNumber: string) => {
    const re =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    return re.test(newPhoneNumber.toLowerCase());
};

const ContactUsModal = ({ onDismissModal, campaign, onContactUsFormSubmit }: ContactUsModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [preferredTimeOfDay, setPreferredTimeOfDay] = useState<'morning' | 'afternoon' | 'evening' | 'no-preference'>(
        'morning'
    );
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [timeZone, setTimeZone] = useState<TimeZone>();
    const [isValidPhoneNumber, toggleIsValidPhoneNumber] = useState(false);
    const [isValidEmail, toggleIsValidEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserContext);
    const accountId = get(userContext, 'userInfo.accountId', '');

    const flagClient = useFlagClient();
    const showIstTimezoneFF = flagClient?.variation('show-ist-timezone', false);

    const timeZones = getTimeZones(showIstTimezoneFF);

    const handleSubmitClick = async () => {
        try {
            setLoading(true);
            const params = {
                campaignId: campaign.campaign_id,
                accountId,
                contactInfo: {
                    ...(isValidPhoneNumber && { phoneNumber }),
                    ...(isValidEmail && { email }),
                    preferredTimeOfDay,
                    timezone: timeZone?.value,
                },
            };

            upsellApi.contactUsClick(params);
            onContactUsFormSubmit();
        } catch (error) {
            console.error('Error: ', error);
        }
    };

    const handlePhoneNumberInputChange = (isValid: boolean, newPhoneNumber: string) => {
        toggleIsValidPhoneNumber(isValid);
        setPhoneNumber(newPhoneNumber);
    };

    const handleEmailInputChange = (isValid: boolean, newEmail: string) => {
        toggleIsValidEmail(isValid);
        setEmail(newEmail);
    };

    const handleCancelClick = () => {
        onDismissModal();
    };

    const handlePreferredTimeOfDayChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        setPreferredTimeOfDay(event.target.value);
    };

    /*
     * Set timezone based on user's current timezone
     */
    useEffect(() => {
        const userTimezone = timeZones.find(
            (timeZone: TimeZone) => timeZone.standardizedValue === Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        setTimeZone(userTimezone);
    }, [timeZones]);

    const handleTimeZoneInputChange = (event: React.ChangeEvent<{}>, newTimezone: TimeZone) => {
        setTimeZone(newTimezone);
    };

    return (
        <GenericDialog
            open
            actions={
                <DialogActions>
                    <Button
                        type="secondary"
                        id="cancel-btn"
                        text={t('InAppMessaging.ContactUsModal.cancel')}
                        size="small"
                        onClick={handleCancelClick}
                    />
                    <Button
                        type="primary"
                        id="submit-btn"
                        text={'Submit'}
                        size="small"
                        onClick={handleSubmitClick}
                        disabled={!(isValidEmail || isValidPhoneNumber) || loading}
                    />
                </DialogActions>
            }
            title={t('InAppMessaging.ContactUsModal.contact-us')}
        >
            <Typography className={classes.helpUsText} component="p">
                {t('InAppMessaging.ContactUsModal.title')}
            </Typography>

            <>
                <SingleSelectField
                    id="section"
                    selectedOption={preferredTimeOfDay}
                    options={[
                        {
                            name: t('InAppMessaging.ContactUsModal.morning'),
                            id: 'morning',
                        },
                        {
                            name: t('InAppMessaging.ContactUsModal.afternoon'),
                            id: 'afternoon',
                        },
                        {
                            name: t('InAppMessaging.ContactUsModal.evening'),
                            id: 'evening',
                        },
                        {
                            name: t('InAppMessaging.ContactUsModal.no-preference'),
                            id: 'no-preference',
                        },
                    ]}
                    label={t('InAppMessaging.ContactUsModal.preferred-time-of-the-day')}
                    handleChange={handlePreferredTimeOfDayChange}
                />
                <Box className={classes.spacer} />
                <InputLabel htmlFor="timezone" className="u-mb-half">
                    {t('InAppMessaging.ContactUsModal.timezone-selector.label')}
                </InputLabel>
                <Autocomplete
                    id="contactus-timezone-select"
                    className={classes.timezoneDropdown}
                    getOptionLabel={(option) => option.label}
                    options={timeZones}
                    onChange={handleTimeZoneInputChange}
                    value={timeZone}
                    renderInput={(params) => <TextField {...params} name="timezone" />}
                    disableClearable={true}
                />
            </>
            <>
                <Box className={classes.spacer} />
                <NameInput
                    className={classes.noMargin}
                    title={t('InAppMessaging.ContactUsModal.phone')}
                    placeholder={t('InAppMessaging.ContactUsModal.enter-phone-number')}
                    value={phoneNumber}
                    errorMessages={{
                        empty: t('InAppMessaging.ContactUsModal.input.error'),
                        default: t('InAppMessaging.ContactUsModal.invalid-phone-number'),
                    }}
                    extraValidationFunction={validatePhoneNumber}
                    onValidationChange={handlePhoneNumberInputChange}
                />
                <NameInput
                    className={classes.noMargin}
                    title={t('InAppMessaging.ContactUsModal.email')}
                    placeholder={t('InAppMessaging.ContactUsModal.enter-email')}
                    value={email}
                    errorMessages={{
                        empty: t('InAppMessaging.ContactUsModal.input.error'),
                    }}
                    onValidationChange={handleEmailInputChange}
                />
            </>
        </GenericDialog>
    );
};
export default ContactUsModal;
