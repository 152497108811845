import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import PropertyWrapper from '@pages/AccountSettings/Sites/Properties/PropertyWrapper';
import PropertyListContainer from '@pages/AccountSettings/Sites/Properties/PropertyListWrapper/PropertyListContainer';
import { APP_PROPERTIES_URL, APP_PROPERTY_DETAILS_URL } from '@common/constants/Constants';
import { checkPermissions, useFlagClient } from '@brivo/onairplus-services';
import { UserContext } from '@common/user/UserProvider';
import { GQLProviderContext } from '@common/utils/CustomApolloProvider';
import { MANAGE_ACCOUNT_SETTINGS_UPDATE } from '@common/permissions/Permissions';
import PageHeader from '@common/components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import NoAccess from '@common/components/NoAccess/NoAccess';
import { useVerifyPmsIntegration } from '@pages/UserManagement/hooks/useVerifyPmsIntegration';

const PropertyPageContainer = () => {
    const { t } = useTranslation();
    const gqlHelper = useContext(GQLProviderContext);
    const { permissions } = useContext(UserContext);
    const flagClient = useFlagClient();
    const userContext = useContext(UserContext);

    const multifamilySupportFlag = flagClient?.variation('ba-site-nesting-nested-sites', false);
    const checkPmsIntegrationFlag = flagClient?.variation('pms-integrations-in-access', false);
    const propertyLinkingFlag = flagClient?.variation('object-linking', false);
    const multifamilySupportEnabled = get(userContext, 'userInfo.isMultifamilySupportEnabled', false);

    const { shouldCheckPmsEntity } = useVerifyPmsIntegration(
        checkPmsIntegrationFlag,
        multifamilySupportFlag,
        multifamilySupportEnabled
    );

    const enablePropertyRoute = checkPmsIntegrationFlag && multifamilySupportEnabled;
    const hasUpdateAccountSettingsPermission = checkPermissions({
        userPermissions: permissions,
        anyOfPermissions: [MANAGE_ACCOUNT_SETTINGS_UPDATE],
    });
    return (
        <>
            <div className="u-fx">
                <PageHeader title={t('Page.property.page-title')} />
            </div>
            <Switch>
                <Route
                    exact
                    path={APP_PROPERTIES_URL}
                    render={() => (enablePropertyRoute ? <PropertyListContainer /> : <NoAccess />)}
                />
                <Route
                    exact
                    path={APP_PROPERTY_DETAILS_URL}
                    render={() =>
                        enablePropertyRoute ? (
                            <PropertyWrapper
                                canEdit={hasUpdateAccountSettingsPermission}
                                gqlHelper={gqlHelper}
                                shouldCheckPmsEntity={shouldCheckPmsEntity}
                                propertyLinkingFlag={propertyLinkingFlag}
                            />
                        ) : (
                            <NoAccess />
                        )
                    }
                />
            </Switch>
        </>
    );
};

export default PropertyPageContainer;
