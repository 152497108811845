import React, { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { GQLProviderContext } from '../../common/utils/CustomApolloProvider';
import { fetchSites } from '../../common/utils/Utils';
import { UserContext } from '../../common/user/UserProvider';
import { useWebSocketMessage } from '../../common/hooks/useSocket';

const SnapshotLog = lazy(() => import('./SnapshotLog'));

const SnapshotLogContainer = ({ ...rest }) => {
    const [sites, setSites] = useState([]);
    const gqlHelper = useContext(GQLProviderContext);
    const { messages } = useWebSocketMessage();
    useEffect(() => {
        fetchSites(gqlHelper, setSites, { includeGeocode: false });
    }, [gqlHelper]);

    const userContext = useContext(UserContext);
    const user = get(userContext, 'userInfo', {});

    return (
        <Suspense fallback={null}>
            <SnapshotLog sites={sites} user={user} gqlHelper={gqlHelper} messages={messages} {...rest} />
        </Suspense>
    );
};

const mapStateToProps = ({ accessToken }) => ({
    accessToken,
});

export default connect(mapStateToProps)(SnapshotLogContainer);
