import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { abortableCall } from '../../AccountSettings/UnifiedVideoIntegrationSection/utils/UnifiedVideoIntegrationUtils';
import { moveCamera, sortCamerasByOrdering } from '../../LiveVideo/utils';
import useMounted from '../../../common/hooks/useMounted';
import { GQLProviderContext } from '../../../common/utils/CustomApolloProvider';

export const ALL_CAMERAS = 'All Cameras';
export const MAX_NO_OF_CAMERAS = 16;

export function useLayoutsStateV2({ api, isUnifiedDashboard = false }) {
    const gqlHelper = useContext(GQLProviderContext);

    const isMounted = useMounted();

    const [currentLayout, setCurrentLayout] = useState(null);
    const [headerLayout, setHeaderLayout] = useState({ id: '', name: '' });

    const [layouts, setLayouts] = useState([]);
    const [cameras, setCameras] = useState([]);
    const [sites, setSites] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [camerasAvailable, setCamerasAvailable] = useState(false);
    const [isLayoutShuffled, setIsLayoutShuffled] = useState(false);

    const lastSavedCamerasDetails = useRef(null);
    const abortController = useRef({ current: null });
    const latestLayoutState = useRef({ current: null });

    const { t } = useTranslation();

    const getLayouts = useCallback(async () => {
        abortController.current = new AbortController();
        let fetchedLayouts;

        try {
            fetchedLayouts = await abortableCall(gqlHelper.getVideoLayoutsList, null, abortController.current.signal);

            const allCameraslayoutExist = fetchedLayouts.some((layout) => layout.name === ALL_CAMERAS);
            if (!allCameraslayoutExist) {
                await createAllCamerasLayout(fetchedLayouts, 'CREATE');
                fetchedLayouts = await abortableCall(
                    gqlHelper.getVideoLayoutsList,
                    null,
                    abortController.current.signal
                );
            }
            const defaultLayoutIndex = fetchedLayouts.findIndex((layout) => layout.isDefault);
            fetchedLayouts.unshift(fetchedLayouts.splice(defaultLayoutIndex, 1)[0]);

            return fetchedLayouts;
        } catch (error) {
            // doesn't seem to ever throw,
            // since `handleExceptions` from catch of call returns empty by default
            console.error(error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gqlHelper]);

    const getAllCameras = useCallback(
        async (offset, numberOfCameras = MAX_NO_OF_CAMERAS) => {
            try {
                const allCameras = gqlHelper.getAllCamerasPaginatedWithCount(numberOfCameras, offset);

                return allCameras;
            } catch (error) {
                // doesn't seem to ever throw,
                // since `handleExceptions` from catch of call returns empty by default
                console.error(error);
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gqlHelper]
    );

    const fetchLayoutById = useCallback(
        async (id, offset = 0, numberOfCameras = MAX_NO_OF_CAMERAS) => {
            // Defensive code for when there is an issue with an id returned as null
            if (typeof id !== 'number') {
                return {};
            }

            try {
                return await gqlHelper.getCamerasByLayout(id, numberOfCameras, offset);
            } catch (error) {
                // doesn't seem to ever throw,
                // since `handleExceptions` from catch of call returns empty by default
                console.error(error);
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gqlHelper, latestLayoutState]
    );

    const getCameras = useCallback(async () => {
        abortController.current = new AbortController();

        try {
            const cameraList = await api.getCamerasListV2(abortController.current.signal);

            if (isEmpty(cameraList)) {
                return [];
            }

            return cameraList.map((camera) => ({ ...camera, checked: false }));
        } catch (error) {
            throw new Error(t('Page.unified-video-integrations.imported-devices.page.error'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSites = useCallback(async () => {
        abortController.current = new AbortController();

        try {
            const sitesResults = await abortableCall(gqlHelper.getSites, null, abortController.current.signal);

            return sitesResults;
        } catch (error) {
            // doesn't seem to ever throw,
            // since `handleExceptions` from catch of call returns empty by default
            console.error(error);
        }
    }, [gqlHelper]);

    const deselectAllCameras = useCallback(() => {
        const cams = cameras.map((cam) => ({ ...cam, checked: false }));
        setCameras(cams);
    }, [cameras]);

    const getLayoutById = useCallback(
        async (id, layouts) => {
            setIsLayoutShuffled(false);
            let totalCamerasFetched = -1;
            let totalCameras = 0; // set this to total cameras after response comes back
            let camerasDetailsFetched = [];
            let offset = 0;

            const allCameraslayout = layouts.filter((layout) => layout.name === ALL_CAMERAS);

            if (!isEmpty(allCameraslayout) && id === allCameraslayout[0].id) {
                return await getCamerasForAllCamerasLayout(allCameraslayout);
            }

            // Assuming id in list and in fetched layout match
            const layoutDetails = layouts.find((l) => l.id === id);
            while (totalCamerasFetched < totalCameras) {
                await fetchLayoutById(id, offset, MAX_NO_OF_CAMERAS).then(async (layout) => {
                    camerasDetailsFetched = [...camerasDetailsFetched, ...layout.camerasDetails];
                    const fetchedLayoutDetails = {
                        camerasDetails: camerasDetailsFetched,
                        id: layoutDetails.id,
                        name: layoutDetails.name,
                        sharingType: layoutDetails.sharingType,
                        layoutType: layoutDetails.type,
                    };
                    totalCameras = layout.camerasCount;
                    offset += MAX_NO_OF_CAMERAS;
                    if (totalCameras !== null) {
                        totalCamerasFetched += layout.camerasDetails.length;
                    } else {
                        totalCamerasFetched = 0;
                    }

                    if (layout?.id === latestLayoutState.current?.id) {
                        if (!isEmpty(layout)) {
                            setHeaderLayout((prevState) => ({
                                ...prevState,
                                id: layoutDetails.id,
                                name: layoutDetails.name,
                                // add `sharingType` so it's updated at the same time as the dropdown selection
                                sharingType: layoutDetails.sharingType,
                            }));
                            setCurrentLayout({ ...fetchedLayoutDetails, ...layoutDetails });
                            lastSavedCamerasDetails.current = cloneDeep(
                                { ...layout, ...layoutDetails }.camerasDetailsFetched
                            );
                        } else {
                            setCurrentLayout(null);
                        }
                        setIsLoading(false);
                    }
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetchLayoutById, latestLayoutState]
    );

    const processRequests = useCallback(
        ([layouts, fetchedAllCameras, sites]) => {
            if (isMounted) {
                setLayouts(layouts);
                setCamerasAvailable(fetchedAllCameras.length > 0);
                setSites(sites);
                setCameras(fetchedAllCameras);

                if (isUnifiedDashboard) {
                    // to be filtered in upcoming iteration
                    const allCamerasLayout = layouts.find((layout) => layout.name === ALL_CAMERAS) || {};
                    // headerLayout determines which layout becomes the currentLayout,
                    // add `sharingType` so it's updated at the same time as the dropdown selection
                    setHeaderLayout({
                        id: allCamerasLayout?.id,
                        name: allCamerasLayout?.name,
                        sharingType: allCamerasLayout?.sharingType,
                    });
                } else {
                    setHeaderLayout((prevState) => ({
                        ...prevState,
                        id: layouts[0].id,
                        name: layouts[0].name,
                        sharingType: layouts[0].sharingType,
                    }));
                }
                setIsLoading(false);
            }
            return layouts;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMounted]
    );

    const getDefaultLayoutIfAny = useCallback(
        (layouts) => {
            setIsLoading(true);

            const defaultLayout = layouts?.find((layout) => layout.isDefault === true);

            if (isEmpty(defaultLayout)) {
                return getLayoutById(layouts[0].id, layouts);
            }

            if (defaultLayout) {
                return getLayoutById(defaultLayout.id, layouts);
            }
        },
        [getLayoutById]
    );

    const fetchData = useCallback(
        () => Promise.all([getLayouts(), getCameras(), getSites()]).then(processRequests).then(getDefaultLayoutIfAny),
        [getLayouts, getCameras, getSites, getDefaultLayoutIfAny, processRequests]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const reloadLayouts = async (shouldResetSelection) => {
        setIsLoading(true);

        const layouts = await getLayouts();
        setLayouts(layouts);

        if (!isEmpty(layouts) && shouldResetSelection) {
            await getLayoutById(layouts[0]?.id, layouts);
        }

        setIsLoading(false);
    };

    const getSavedLayout = useCallback(
        async (id, handleCloseModal, resetForm) => {
            setIsLoading(true);

            let totalCamerasFetched = 0;
            let offset = 0;
            let totalCameras = 0; // set this to total cameras after response comes back
            let camerasDetailsFetched = [];
            const layouts = await getLayouts();
            const layout = await fetchLayoutById(id, offset, MAX_NO_OF_CAMERAS);
            totalCameras = layout.camerasCount;
            camerasDetailsFetched = layout.camerasDetails;
            totalCamerasFetched = totalCamerasFetched + layout.camerasDetails.length;
            const layoutDetails = layouts.find((l) => l.id === id);

            if (isMounted) {
                const currentLayout = { ...layout, ...layoutDetails };

                if (!isEmpty(currentLayout)) {
                    // add `sharingType` so badge gets updated at the same time
                    setHeaderLayout((prevState) => ({
                        ...prevState,
                        id: layoutDetails.id,
                        name: layoutDetails.name,
                        sharingType: layoutDetails.sharingType,
                    }));

                    setCurrentLayout(currentLayout);
                    lastSavedCamerasDetails.current = cloneDeep(currentLayout.camerasDetails);
                }

                setLayouts(layouts);
                setIsLoading(false);
                handleCloseModal(resetForm);

                while (totalCameras !== null && totalCamerasFetched < totalCameras) {
                    offset += MAX_NO_OF_CAMERAS;
                    const layout = await fetchLayoutById(id, offset, MAX_NO_OF_CAMERAS);
                    camerasDetailsFetched = [...camerasDetailsFetched, ...layout.camerasDetails];
                    const fetchedLayoutDetails = {
                        camerasDetails: camerasDetailsFetched,
                        id: layout.id,
                        name: layout.name,
                        sharingType: layout.sharingType,
                        layoutType: layout.layoutType,
                    };
                    totalCamerasFetched = totalCamerasFetched + layout.camerasDetails.length;
                    setCurrentLayout({ ...fetchedLayoutDetails, ...layoutDetails });
                }
            }
        },
        [fetchLayoutById, getLayouts, isMounted]
    );

    const handleDragHover = useCallback(
        (dragItem, dropArea) => {
            const sourceCamId = dragItem.camId;
            const destinationCamId = dropArea.camId;

            if (sourceCamId === destinationCamId) {
                return;
            }

            const updatedCamerasDetails = moveCamera({
                camList: currentLayout.camerasDetails,
                sourceCamId,
                destinationCamId,
            });
            const updatedCurrentLayout = { ...currentLayout, camerasDetails: updatedCamerasDetails };

            const isShuffled = !isEqual(sortCamerasByOrdering(lastSavedCamerasDetails.current), updatedCamerasDetails);

            setIsLayoutShuffled(isShuffled);
            // add `sharingType` so badge gets updated at the same time
            setHeaderLayout((prevState) => ({
                ...prevState,
                id: updatedCurrentLayout.id,
                name: updatedCurrentLayout.name,
                sharingType: updatedCurrentLayout.sharingType,
            }));
            setCurrentLayout(updatedCurrentLayout);
        },
        [currentLayout]
    );

    const discardRearrangement = useCallback(() => {
        const updatedCurrentLayout = { ...currentLayout, camerasDetails: cloneDeep(lastSavedCamerasDetails.current) };

        setIsLayoutShuffled(false);
        setCurrentLayout(updatedCurrentLayout);
    }, [currentLayout]);

    const updateLayoutOrdering = async () => {
        setIsLoading(true);

        try {
            const updatedLayout = await saveNewLayoutOrdering();

            if (isEmpty(updatedLayout)) {
                throw new Error(t('Page.unified-video.update-layout.rearrange-cameras.fail'));
            }

            lastSavedCamerasDetails.current = cloneDeep(currentLayout?.camerasDetails);
            setIsLayoutShuffled(false);
        } catch (error) {
            throw new Error(t('Page.unified-video.update-layout.rearrange-cameras.fail'));
        } finally {
            setIsLoading(false);
        }
    };

    const saveNewLayoutOrdering = async () => {
        const cameraLayoutDetails = currentLayout?.camerasDetails?.map((cam) => ({
            cameraObjectId: cam.id,
            isHd: false,
            ordering: cam.ordering,
            size: cam.size,
        }));

        const payload = {
            configAction: 'UPDATE',
            layoutId: currentLayout.id,
            isDefault: currentLayout.isDefault,
            layoutType: currentLayout.type,
            sharingType: currentLayout.sharingType,
            layoutName: currentLayout.name,
            cameraLayoutDetails,
        };

        return await gqlHelper.saveV2LiveVideoCustomLayout(payload);
    };

    const saveLayoutToDb = async (
        configAction,
        isDefault,
        layoutType,
        sharingType,
        layoutId,
        layoutName,
        cameraDetails
    ) => {
        const payload = {
            configAction: configAction,
            layoutId: layoutId,
            isDefault: isDefault,
            layoutName: layoutName,
            layoutType: layoutType,
            sharingType: sharingType,
            cameraLayoutDetails: cameraDetails,
        };
        return await gqlHelper.saveV2LiveVideoCustomLayout(payload);
    };

    const deleteLayoutFromDb = async () => {
        const payload = {
            configAction: 'DELETE',
            layoutId: currentLayout.id,
        };

        const deleteResponse = await gqlHelper.saveV2LiveVideoCustomLayout(payload);
        if (!isEmpty(deleteResponse) && !isEmpty(layouts)) {
            setLayouts(layouts.filter((layout) => layout.id !== currentLayout.id));
            setHeaderLayout((prevState) => ({
                ...prevState,
                id: layouts[0].id,
                name: layouts[0].name,
                sharingType: layouts[0].sharingType,
            }));
            setCurrentLayout(null); // gets reset in `reloadLayouts`
        }
        return deleteResponse;
    };

    const setDefaultLayout = async (video) => {
        const updatedLayout = layouts.map((layout) => {
            if (layout.id === video.id) {
                return { ...layout, isDefault: true };
            } else if (layout.isDefault) {
                return { ...layout, isDefault: false };
            }

            return layout;
        });

        setLayouts(updatedLayout);

        if (video.name === ALL_CAMERAS) {
            const payload = {
                configAction: 'UPDATE',
                layoutId: video.id,
                layoutType: video.layoutType,
                sharingType: video.sharingType,
                isDefault: true,
                layoutName: video.name,
            };
            return await gqlHelper.saveV2LiveVideoCustomLayout(payload);
        }

        const layout = await fetchLayoutById(video.id);
        const cameraLayoutDetails = layout?.camerasDetails?.map((cam) => ({
            cameraObjectId: cam.id,
            isHd: false,
            ordering: cam.ordering,
            size: cam.size,
        }));
        const payload = {
            configAction: 'UPDATE',
            layoutId: layout.id,
            layoutType: layout.layoutType,
            sharingType: video.sharingType,
            isDefault: true,
            layoutName: video.name,
            cameraLayoutDetails,
        };
        return await gqlHelper.saveV2LiveVideoCustomLayout(payload);
    };

    const createAllCamerasLayout = async (layouts, configAction, layoutDetails) => {
        try {
            const defaultLayout = layouts.some((layout) => layout.isDefault);

            const isDefault = configAction === 'CREATE' ? !defaultLayout : layoutDetails[0].isDefault;
            const layoutId = configAction === 'CREATE' ? null : layoutDetails[0].id;

            const payload = {
                configAction: configAction,
                layoutId: layoutId,
                isDefault: isDefault,
                layoutType: 'ThreeByThree',
                sharingType: 'PUBLIC',
                layoutName: ALL_CAMERAS,
            };

            await gqlHelper.saveV2LiveVideoCustomLayout(payload);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        latestLayoutState.current = headerLayout;

        return () => (latestLayoutState.current = null);
    }, [headerLayout]);

    const getCamerasForAllCamerasLayout = useCallback(
        async (allCameraslayout) => {
            let cameraDetails = [];
            let totalCamerasFetched = 0;
            let offset = 0;
            const allCameras = await getAllCameras();

            if (allCameras.count === 0) {
                setIsLoading(false);
            }

            if (allCameras.count > 0 && latestLayoutState.current?.name === ALL_CAMERAS) {
                cameraDetails = [...cameraDetails, ...allCameras.cameras];
                const allCamerasLayoutDetails = {
                    camerasDetails: cameraDetails,
                    id: allCameraslayout[0].id,
                    isDefault: allCameraslayout[0].isDefault,
                    layoutType: allCameraslayout[0].type,
                    sharingType: allCameraslayout[0].sharingType,
                    name: ALL_CAMERAS,
                };

                setCurrentLayout((layout) => ({ ...layout, ...allCamerasLayoutDetails }));
                lastSavedCamerasDetails.current = cloneDeep(
                    { ...allCameraslayout, ...allCamerasLayoutDetails }.camerasDetails
                );
                totalCamerasFetched += allCameras.cameras.length;

                if (totalCamerasFetched > 0) {
                    setIsLoading(false);
                }

                //If needed to get more cameras in batches.
                while (totalCamerasFetched < allCameras.count) {
                    offset += MAX_NO_OF_CAMERAS;
                    const offsetcameras = await getAllCameras(offset);
                    if (latestLayoutState.current?.name === ALL_CAMERAS) {
                        cameraDetails = [...cameraDetails, ...offsetcameras.cameras];
                        const allCamerasLayoutDetails = {
                            camerasDetails: cameraDetails,
                            id: allCameraslayout[0].id,
                            isDefault: allCameraslayout[0].isDefault,
                            layoutType: allCameraslayout[0].type,
                            sharingType: allCameraslayout[0].sharingType,
                            name: ALL_CAMERAS,
                        };

                        setCurrentLayout((layout) => ({ ...layout, ...allCamerasLayoutDetails }));
                        lastSavedCamerasDetails.current = cloneDeep(
                            { ...allCameraslayout, ...allCamerasLayoutDetails }.camerasDetails
                        );
                        totalCamerasFetched += offsetcameras.cameras.length;
                    } else {
                        break;
                    }
                }
            }
        },
        [getAllCameras, latestLayoutState]
    );

    return {
        camerasAvailable,
        currentLayout,
        headerLayout,
        isLayoutShuffled,
        isLoading,
        layouts,
        sites,
        cameras,
        setHeaderLayout,
        setIsLoading,
        discardRearrangement,
        getLayoutById,
        getSavedLayout,
        handleDragHover,
        reloadLayouts,
        updateLayoutOrdering,
        setDefaultLayout,
        deselectAllCameras,
        saveLayoutToDb,
        deleteLayoutFromDb,
    };
}
