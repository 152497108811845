import React, { Suspense, lazy } from 'react';

const UnifiedVideoPage = lazy(() => import('./UnifiedVideoPage'));

const UnifiedVideoContainer = (props) => (
    <Suspense fallback={null}>
        <UnifiedVideoPage {...props} />
    </Suspense>
);

export default UnifiedVideoContainer;
