import gql from 'graphql-tag';

export const GET_UNKNOWN_CARDS = gql`
    query getUnknownCards($siteOIds: [Int]) {
        getUnknownCards(siteOIds: $siteOIds) {
            id
            siteName
            siteId
            doorName
            doorId
            hex
            numBits
            occurred
        }
    }
`;

export const GET_UNKNOWN_CARD_BY_ID = gql`
    query getUnknownCard($unknownCardId: Float!) {
        getUnknownCard(unknownCardId: $unknownCardId) {
            id
            siteName
            siteId
            doorName
            doorId
            hex
            numBits
            occurred
        }
    }
`;

export const GET_CONSENT_STATUS_BY_USER_ID = gql`
    query getConsentStatusByUserId($userId: Int!) {
        getConsentStatusByUserId(userId: $userId) {
            consentStatus
            faceprintError
            consentId
        }
    }
`;

export const SET_NFC_ENABLED_FOR_BMP = gql`
    mutation setNfcEnabledForBMP($accessCredentialIds: [Int]!, $nfcEnabled: Boolean!) {
        setNfcEnabledForBMP(accessCredentialIds: $accessCredentialIds, nfcEnabled: $nfcEnabled)
    }
`;
