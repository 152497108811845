import * as sitesTypes from '../types/Sites.types';
import * as apiTypes from '../types/Api.types';

export const sitesReducer = (state = null, action) => {
    const { payload } = action;

    switch (action.type) {
        case `${sitesTypes.FETCH_SITES} ${apiTypes.API_SUCCESS}`:
            return payload.body.data;

        default:
            return state;
    }
};

export default sitesReducer;
