import { createContext, useContext, useEffect, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { Query, Filter, Column, Sort, Dimension } from '../../common/interfaces';

const QueryEditorContext = createContext();

const blankQuery = {
    query: {
        columns: [],
        filters: [],
        sort: [],
    },
};

export const QueryEditorProvider = ({ children }: any) => {
    const [query, setQuery] = useState<Query>(blankQuery as any);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [columns, setColumns] = useState<Column[]>([]);
    const [sort, setSort] = useState<Sort[]>([]);
    const [dimensions, setDimensions] = useState<Dimension[]>([]);
    const [hasEditsBeenMade, setHasEditsBeenMade] = useState(false);

    /**
     * Hook for checking whether or not columns, filters, or sort has been changed
     * If so, then set hasEditsBeenMade to true, showing the Save Bar
     * @Public
     */
    useEffect(() => {
        const { filters: originalFilters, columns: originalColumns, sort: originalSort } = query.query;
        const hasChangesBeenMade =
            !isEqual(originalFilters, filters) || !isEqual(originalColumns, columns) || !isEqual(originalSort, sort);
        setHasEditsBeenMade(hasChangesBeenMade);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, columns, sort]);

    /**
     * Methods for updating the query
     */
    const revertQueryChanges = () => {
        const { filters, columns, sort } = query.query;
        setFilters(filters ?? []);
        setColumns(columns ?? []);
        setSort(sort ?? []);
    };

    const updateQuery = () => {
        const updatedQuery = {
            query: {
                columns,
                filters,
                sort,
            },
        };

        setQuery(updatedQuery);
        setInitialQuery(updatedQuery);
        setHasEditsBeenMade(false);
    };

    const setInitialQuery = (initialQuery: Query) => {
        const { filters, columns, sort } = cloneDeep(initialQuery.query);
        setFilters(filters ?? []);
        setColumns(columns ?? []);
        setSort(sort ?? []);
        setQuery(initialQuery);
    };

    const setInitialDimensions = (dimensions: Dimension[]) => {
        setDimensions(dimensions);
    };

    /**
     * Column methods
     */
    const addColumn = (column: Column) => {
        const isColumnAlreadyAdded = columns.find((existingColumn) => existingColumn.id === column.id);
        if (!isColumnAlreadyAdded) {
            const newColumns = [...columns, column];
            setColumns(newColumns);
        }
    };

    const removeColumn = (column: Column) => {
        const newColumns = columns.filter((existingColumn) => existingColumn.id !== column.id);
        setColumns(newColumns);
    };

    /**
     * Filter methods
     */
    const addOrUpdateFilter = (newFilter: Filter) => {
        const filterObjectIndex = filters.findIndex((filter) => filter?.value?.uuid === newFilter?.value?.uuid);
        let newFilters = [...filters];

        // Filter already exists, replace the object
        if (filterObjectIndex !== -1) {
            newFilters[filterObjectIndex] = newFilter;
        } else {
            newFilters = [...filters, newFilter];
        }

        setFilters(newFilters);
    };

    const removeFilter = (filterToRemove: Filter) => {
        const newFilters = filters.filter((filter) => filter?.value?.uuid !== filterToRemove?.value?.uuid);
        setFilters(newFilters);
    };

    // /**
    //  * Sort methods
    //  */
    // const addSort = (sort: Sort) => {
    //     console.log("addSort")
    // }

    // const removeSort = (sort: Sort) => {
    //     console.log("removeSort")
    // }

    /**
     * Dimension methods
     */
    const addDimension = (dimension: Dimension) => {
        const isDimensionAlreadyAdded = dimensions.find((existingDimension) => existingDimension.id === dimension.id);
        if (!isDimensionAlreadyAdded) {
            const updatedDimensions = [...dimensions, dimension];
            setDimensions(updatedDimensions);
        }
    };

    const removeDimension = (dimension: Dimension) => {
        const updatedDimensions = dimensions.filter((existingDimensino) => existingDimensino.id !== dimension.id);
        setDimensions(updatedDimensions);
    };

    const providerValues = {
        query,
        dimensions,
        setInitialDimensions,
        addOrUpdateFilter,
        removeFilter,
        addColumn,
        removeColumn,
        // addSort,
        // removeSort,
        addDimension,
        removeDimension,
        updateQuery,
        revertQueryChanges,
        hasEditsBeenMade,
        setInitialQuery,
    };

    return <QueryEditorContext.Provider value={providerValues}>{children}</QueryEditorContext.Provider>;
};

export const useQueryEditor = () => {
    return useContext(QueryEditorContext);
};
