import { useContext, useRef, useState } from 'react';

import { Snackbar } from '@brivo/react-components';
import { APP_FLOOR_PLANS_URL, PROMISE_CANCELLED } from '../../../../common/constants/Constants';
import { useAssignments } from '../../../../common/hooks/useAssignments';
import { floorPlansApi } from '../../../../common/webApis';
import { GQLProviderContext } from '../../../../common/utils/CustomApolloProvider';
import { cancellablePromise } from '../../../../common/utils/promiseUtils';
import { useTranslation } from 'react-i18next';

const getFloorPlans = async (gqlHelper) => {
    const sitesResponse = await gqlHelper.getSites();
    const siteIdArray = sitesResponse.map((site) => site.id);

    const floorPlansResponse = await floorPlansApi.fetchFloorPlanBySiteId(siteIdArray);

    return floorPlansResponse?.getFloorPlans;
};

const useFloorPlansNavigation = (history) => {
    const notifications = Snackbar();
    const { hasSiteAssignments } = useAssignments();
    const gqlHelper = useContext(GQLProviderContext);
    const { t } = useTranslation();

    const [isFloorPlanLoading, setIsFloorPlanLoading] = useState(false);
    const [shouldPreventFloorPlanRedirect, preventFloorPlanRedirect] = useState(false);
    const [isError, setIsError] = useState(false);

    const cancelFetch = useRef(null);

    const handleFloorPlanClick = async () => {
        if (!hasSiteAssignments) {
            history.push(APP_FLOOR_PLANS_URL, { from: 'redirect' });
            return;
        }
        setIsFloorPlanLoading(true);
        const { promise: fetchFloorPlans, cancel: cancelFloorPlansFetch } = cancellablePromise(
            getFloorPlans(gqlHelper)
        );
        cancelFetch.current = cancelFloorPlansFetch;

        fetchFloorPlans
            .then((floorPlans) => {
                setIsFloorPlanLoading(false);
                if (floorPlans?.length) {
                    const floorPlanUrl = `/floor-plans/${floorPlans[0].siteId}/${floorPlans[0].floorPlanId}?view`;
                    window.open(floorPlanUrl);
                } else history.push(APP_FLOOR_PLANS_URL, { from: 'redirect' });
            })
            .catch((error) => {
                setIsFloorPlanLoading(false);
                setIsError(true);
                if (error.message !== PROMISE_CANCELLED) {
                    notifications.addErrorMessage({ text: t('Page.floor-plans.get-error.message') });
                }
            });
    };
    return {
        isFloorPlanLoading,
        preventFloorPlanRedirect,
        shouldPreventFloorPlanRedirect,
        handleFloorPlanClick,
        isError,
        cancelFloorPlansFetch: cancelFetch.current,
    };
};

export default useFloorPlansNavigation;
