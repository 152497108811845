import React, { Fragment } from 'react';
import { withTheme, SvgIcon } from '@brivo/react-components';
import { APP_ROOT_URL, APP_HOME_URL, APP_DEFAULT_URL } from '@common/constants/Constants';

const createSvgIcon = (path, displayName) => {
    const Component = React.memo(
        React.forwardRef((props, ref) => (
            <SvgIcon data-mui-test={`${displayName}Icon`} ref={ref} {...props}>
                {path}
            </SvgIcon>
        ))
    );

    if (import.meta.env.DEV) {
        Component.displayName = `${displayName}Icon`;
    }

    Component.muiName = SvgIcon.muiName;

    return Component;
};

export const NavLogo = withTheme(({ theme }) => {
    const icon = (
        <Fragment>
            <g fill={theme.palette.colors.blue950}>
                <path d="M40,20 C40,31.0153846 31.0153846,40 20,40 C8.98461538,40 0,31.0153846 0,20 C0,8.98461538 8.98461538,0 20,0 C31.0153846,0 40,8.98461538 40,20 M4.43076923,20 C4.43076923,28.5538462 11.3846154,35.5076923 19.9384615,35.5076923 C28.4923077,35.5076923 35.4461538,28.5538462 35.4461538,20 C35.4461538,11.4461538 28.4923077,4.49230769 19.9384615,4.49230769 C11.3846154,4.49230769 4.43076923,11.4461538 4.43076923,20" />
                <path d="M38.4615385,24.4307692 C38.4615385,31.5692308 32.6769231,37.3538462 25.5384615,37.3538462 C18.4,37.3538462 12.6153846,31.5692308 12.6153846,24.4307692 C12.6153846,17.2923077 18.4,11.5076923 25.5384615,11.5076923 C32.6769231,11.5692308 38.4615385,17.2923077 38.4615385,24.4307692 M17.2307692,24.4307692 C17.2307692,29.0461538 20.9846154,32.7384615 25.5384615,32.7384615 C30.1538462,32.7384615 33.8461538,28.9846154 33.8461538,24.4307692 C33.8461538,19.8153846 30.0923077,16.1230769 25.5384615,16.1230769 C20.9230769,16.1230769 17.2307692,19.8153846 17.2307692,24.4307692" />
            </g>
            <g transform="translate(48.184615, 7.015385)" fill={theme.palette.colors.grey0} fillRule="nonzero">
                <path d="M29.6615385,26.4 L34.1538462,26.4 L34.1538462,16.0615385 C34.2153846,14.7076923 34.2153846,10.8923077 39.9384615,10.8307692 C41.4153846,10.8307692 42.2769231,11.0769231 42.7076923,11.2 L42.7076923,6.33846154 C42.2153846,6.27692308 41.7846154,6.27692308 41.1076923,6.27692308 C37.2307692,6.27692308 35.2615385,8.24615385 34.2769231,9.84615385 L34.1538462,9.84615385 L34.1538462,6.95384615 L29.6615385,6.95384615 L29.6615385,26.4 L29.6615385,26.4 Z" />
                <rect x="50.8923077" y="6.89230769" width="4.49230769" height="19.5076923" />
                <polygon points="62.4615385 6.89230769 71.6307692 26.4 76.1230769 26.4 85.4153846 6.89230769 80.4923077 6.89230769 73.9692308 21.3538462 67.3846154 6.89230769" />
                <path d="M100.615385,23.3230769 C96.5538462,23.3230769 94.2769231,20.8 94.2769231,16.6769231 C94.2769231,12.1230769 97.1692308,10.2153846 100.615385,10.2153846 C104,10.2153846 106.953846,12.1230769 106.953846,16.6769231 C106.953846,20.8615385 104.615385,23.3230769 100.615385,23.3230769 M100.676923,27.0769231 C106.030769,27.0769231 111.630769,24.4307692 111.630769,16.6769231 C111.630769,11.4461538 108.492308,6.27692308 100.676923,6.27692308 C92.9230769,6.27692308 89.7230769,11.4461538 89.7230769,16.6769231 C89.7230769,24.4307692 95.3230769,27.0769231 100.676923,27.0769231" />
                <path d="M4.49230769,12.8 C5.84615385,11.6923077 7.75384615,10.0923077 11.0153846,10.0923077 C14.4615385,10.0923077 17.9076923,12.2461538 17.9076923,16.6769231 C17.9076923,20.9846154 14.6461538,23.2 11.2,23.2 C8.12307692,23.2 6.27692308,21.7230769 4.55384615,20.3692308 L4.49230769,12.8 L4.49230769,12.8 Z M3.55271368e-15,26.4 L4.43076923,26.4 L4.43076923,23.8769231 C5.35384615,24.8615385 7.69230769,27.0153846 12.1846154,27.0153846 C17.7230769,27.0153846 22.4615385,23.0769231 22.4615385,16.4923077 C22.4615385,10.5846154 18.4,6.21538462 12,6.21538462 C8.24615385,6.21538462 6.46153846,7.50769231 4.49230769,8.92307692 L4.49230769,-1.10134124e-13 L3.55271368e-15,-1.10134124e-13 C3.55271368e-15,-1.10134124e-13 3.55271368e-15,26.4 3.55271368e-15,26.4 Z" />
            </g>
        </Fragment>
    );
    const LogoIcon = createSvgIcon(icon);

    return (
        <LogoIcon
            className="u-mb-half"
            style={{
                width: '9.6875rem',
                height: '2.5rem',
            }}
            viewBox="0 0 170 40"
        />
    );
});

export const isActiveItem = (item) => {
    return isDefaultActive(item) || window.location.pathname.startsWith(item.url);
};

export const isDefaultActive = (item) => {
    return (
        item.url === APP_DEFAULT_URL &&
        (window.location.pathname === APP_ROOT_URL ||
            window.location.pathname === APP_HOME_URL ||
            window.location.pathname === APP_DEFAULT_URL)
    );
};
