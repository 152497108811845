import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 0.5H3C1.34314 0.5 0 1.84315 0 3.5V15.5C0 17.1569 1.34315 18.5 3 18.5H9C10.6569 18.5 12 17.1569 12 15.5V3.5C12 1.84315 10.6569 0.5 9 0.5ZM2 3.5C2 2.94772 2.44772 2.5 3 2.5H9C9.55228 2.5 10 2.94772 10 3.5V15.5C10 16.0523 9.55228 16.5 9 16.5H3C2.44772 16.5 2 16.0523 2 15.5V3.5ZM5 12.5C4.44772 12.5 4 12.9477 4 13.5C4 14.0523 4.44772 14.5 5 14.5H7C7.55228 14.5 8 14.0523 8 13.5C8 12.9477 7.55228 12.5 7 12.5H5Z"
        />
    </React.Fragment>,
    'Device'
);
