import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '@brivo/react-components';
import { useStyles } from './styles';
import { TYPE_ASSIGN, TYPE_UNASSIGN } from '../constants';

import { Button } from '@brivo/react-components';

const AssignAllBtn = ({ type, disabled, assignAll = null, unassignAll = null, count = 0, isLoading = false }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    let props = {};

    switch (type) {
        case TYPE_ASSIGN:
            props = {
                type: 'secondary',
                id: 'assignAll',
                onClick: assignAll,
                text: !isLoading
                    ? t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.assign-all', {
                          count,
                      })
                    : t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.assigning'),
                customWidth: '100%',
                size: 'small',
                startIcon: isLoading && <LoadingIndicator size="small" shouldHavePadding={false} />,
                disabled: isLoading || disabled,
            };
            break;
        case TYPE_UNASSIGN:
            props = {
                type: 'secondary-error',
                id: 'unassignAll',
                onClick: unassignAll,
                text: !isLoading
                    ? t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.unassign-all')
                    : t('Page.lockdown-scenarios.lockdown-scenario-details.page.form.multiselect.doors.unassigning'),
                customWidth: '100%',
                size: 'small',
                startIcon: isLoading && <LoadingIndicator size="small" shouldHavePadding={false} />,
                disabled: isLoading || disabled,
            };
            break;
    }

    return (
        <div className={classes.buttonStyle}>
            <Button {...props} />
        </div>
    );
};

export default AssignAllBtn;
