import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <g fill="#739dd3" fillRule="nonzero">
            <g>
                <path
                    d="M11.333 0H.667C.299 0 0 .298 0 .665v12c0 .369.299.667.667.667h3.332c.368 0 .667-.298.667-.666v-1.334c0-.735.599-1.334 1.334-1.334.735 0 1.333.599 1.333 1.334v1.333c0 .369.299.667.667.667h3.333c.368 0 .667-.298.667-.667v-12c0-.368-.299-.667-.667-.667zm-.666 12h-2v-.667c0-1.47-1.196-2.667-2.667-2.667-1.47 0-2.667 1.197-2.667 2.667v.666h-2V1.333h9.334v10.666z"
                    transform="translate(2 1.333)"
                />
                <path
                    d="M3.333 4.666h1.334c.368 0 .666-.298.666-.667v-.666c0-.368-.298-.667-.666-.667H3.333c-.368 0-.666.3-.666.667v.666c0 .368.298.667.666.667zM7.333 4.666h1.334c.368 0 .666-.298.666-.667v-.666c0-.368-.298-.667-.666-.667H7.333c-.368 0-.666.3-.666.667v.666c0 .368.298.667.666.667zM3.333 8h1.334c.368 0 .666-.299.666-.667v-.667c0-.367-.298-.667-.666-.667H3.333c-.368 0-.666.3-.666.667v.667c0 .368.298.666.666.666zM7.333 8h1.334c.368 0 .666-.299.666-.667v-.667c0-.367-.298-.667-.666-.667H7.333c-.368 0-.666.3-.666.667v.667c0 .368.298.666.666.666z"
                    transform="translate(2 1.333)"
                />
            </g>
        </g>
    </React.Fragment>,
    'Site'
);
