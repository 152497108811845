import { SaveActionBar } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import { useQueryEditor } from '../QueryEditorContext';

const SaveChangesBar = () => {
    const QueryEditor: any = useQueryEditor();
    const { t } = useTranslation();

    const handlePrimaryActionClick = () => {
        QueryEditor.updateQuery();
    };

    const handleSecondaryActionClick = () => {
        QueryEditor.revertQueryChanges();
    };

    return (
        <SaveActionBar
            show
            disablePrimary={false}
            primaryActionText={t('Page.new-reports.table-editor.save-bar.save-changes')}
            primaryActionClickHandler={handlePrimaryActionClick}
            primaryActionId={'save-changes'}
            secondaryActionText={t('Page.new-reports.table-editor.save-bar.discard')}
            secondaryActionClickHandler={handleSecondaryActionClick}
            disableSecondary={false}
        />
    );
};

export default SaveChangesBar;
