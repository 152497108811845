import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    headerButton: {
        textTransform: 'none',

        '&.MuiButton-root': {
            '& .MuiButton-label': {
                justifyContent: 'start',
            },
        },
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '1000px',
        height: '35px',
        marginBottom: '1.25rem',

        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },

        '& #Button__header-button': {
            backgroundColor: 'transparent !important',
            padding: '0',
        },
    },
    list: {
        padding: '.5rem',
    },
    menuItems: {
        borderRadius: '3px',
    },
    divider: {
        margin: theme.spacing(0.5, 1),
    },
    scrollableArea: {
        maxHeight: '200px',
        overflowY: 'auto',
    },
}));

export default useStyles;
