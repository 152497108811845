const roverQueries = {
    activeUserCount: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'users',
                        id: 'first_name',
                    },
                    alias: 'User Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'or',
                    left_value: {
                        source: 'operation',
                        type: 'greater',
                        right_value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                        left_value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                    right_value: {
                        source: 'operation',
                        type: 'is_null',
                        value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                },
            ],
        },
    },
    activeUserList: {
        query: {
            columns: [
                {
                    source: 'users',
                    id: 'first_name',
                    alias: 'First Name',
                    uuid: 'first_name',
                },

                {
                    source: 'users',
                    id: 'last_name',
                    alias: 'Last Name',
                    uuid: 'last_name',
                },
                {
                    source: 'users',
                    id: 'created',
                    alias: 'Created',
                    uuid: 'created',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'or',
                    left_value: {
                        source: 'operation',
                        type: 'greater',
                        right_value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                        left_value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                    right_value: {
                        source: 'operation',
                        type: 'is_null',
                        value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'users',
                    id: 'first_name',
                },
            ],
        },
    },
    todayUsersCount: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count_distinct',
                    alias: 'User Count',
                    uuid: 'count',
                    over: {
                        source: 'events',
                        id: 'actor_object_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
            sort: [
                {
                    source: 'events',
                    id: 'occurred',
                },
            ],
        },
    },
    todayUsersList: {
        query: {
            columns: [
                {
                    alias: 'Event Time',
                    uuid: 'event_time',
                    source: 'transform',
                    type: 'timezone_conversion',
                    timezone: {
                        source: 'constant',
                        value: 'US/Eastern',
                        type: 'string',
                    },
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    alias: 'Site',
                    uuid: 'site_name',
                    source: 'sites',
                    id: 'site_name',
                },
                {
                    alias: 'First Name',
                    source: 'users',
                    id: 'first_name',
                    uuid: 'user_first_name',
                },
                {
                    alias: 'Last Name',
                    source: 'users',
                    id: 'last_name',
                    uuid: 'user_last_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 1,
                    },
                    left_value: {
                        source: 'window',
                        type: 'row_number',
                        over: {
                            source: 'window',
                            type: 'over',
                            partition: [
                                {
                                    source: 'events',
                                    id: 'actor_object_id',
                                },
                            ],
                            order: [
                                {
                                    source: 'events',
                                    id: 'occurred',
                                },
                            ],
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
            sort: [
                {
                    source: 'events',
                    id: 'occurred',
                },
            ],
        },
    },
    dailyUniqueUsersList: {
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_date',
                    alias: 'Date',
                    uuid: 'date',
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count_distinct',
                    alias: 'User Count',
                    uuid: 'user_count',
                    over: {
                        source: 'events',
                        id: 'actor_object_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -90,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    alias: 'Date',
                },
            ],
        },
    },
    uniqueUsersTrend: {
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_date_part',
                    granularity: {
                        source: 'constant',
                        value: {
                            source: 'ui_variables',
                            id: 'trendGranularity',
                        },
                        type: 'string',
                    },
                    alias: 'Date',
                    uuid: 'date',
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count_distinct',
                    alias: 'User Count',
                    uuid: 'user_count',
                    over: {
                        source: 'events',
                        id: 'actor_object_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: {
                                source: 'ui_variables',
                                id: 'trendGranularity',
                            },
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: {
                                    source: 'ui_variables',
                                    id: 'trendGranularity',
                                },
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: {
                                    source: 'ui_variables',
                                    id: 'trendLookback',
                                },
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    alias: 'Date',
                },
            ],
        },
    },
    uniqueUsersPerSiteList: {
        query: {
            columns: [
                {
                    source: 'sites',
                    alias: 'Site Name',
                    uuid: 'site',
                    id: 'site_name',
                },
                {
                    source: 'aggregation',
                    type: 'count_distinct',
                    alias: 'User Count',
                    uuid: 'count',
                    over: {
                        source: 'events',
                        id: 'actor_object_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -90,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
        },
    },
    firstLastVisitList: {
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_date',
                    alias: 'Date',
                    uuid: 'date',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                },
                {
                    source: 'aggregation',
                    type: 'min',
                    alias: 'Time of Entry',
                    uuid: 'time_of_entry',
                    over: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                },
                {
                    source: 'aggregation',
                    type: 'max',
                    alias: 'Last Event for the Day',
                    uuid: 'last_event',
                    over: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                },
                {
                    source: 'users',
                    id: 'first_name',
                    uuid: 'first_name',
                },
                {
                    source: 'users',
                    id: 'last_name',
                    uuid: 'last_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    alias: 'Date',
                },
            ],
        },
    },
    usersCreatedThisYear: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'users',
                        id: 'first_name',
                    },
                    alias: 'User Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'users',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    usersCreatedThisYearList: {
        query: {
            columns: [
                {
                    source: 'users',
                    id: 'first_name',
                    alias: 'First Name',
                    uuid: 'first_name',
                },

                {
                    source: 'users',
                    id: 'last_name',
                    alias: 'Last Name',
                    uuid: 'last_name',
                },
                {
                    source: 'users',
                    id: 'created',
                    alias: 'Created',
                    uuid: 'created',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'users',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'users',
                    id: 'first_name',
                },
            ],
        },
    },
    credentialsCreatedThisYear: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'credentials',
                        id: 'credential_id',
                    },
                    alias: 'Credential Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'credentials',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    credentialsCreatedThisYearList: {
        query: {
            columns: [
                {
                    source: 'credential_types',
                    id: 'credential_type',
                    alias: 'Credential Type',
                    uuid: 'credential_type',
                },
                {
                    source: 'credentials',
                    id: 'created',
                    alias: 'Credential Created',
                    uuid: 'credential_created',
                },
                {
                    source: 'users',
                    id: 'first_name',
                    alias: 'Owner First Name',
                    uuid: 'owner_first_name',
                },
                {
                    source: 'users',
                    id: 'middle_name',
                    alias: 'Owner Middle Name',
                    uuid: 'owner_middle_name',
                },
                {
                    source: 'users',
                    id: 'last_name',
                    alias: 'Owner Last Name',
                    uuid: 'owner_last_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'credentials',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    doorsCreatedThisYear: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'devices',
                        id: 'device_id',
                    },
                    alias: 'Device Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    right_value: {
                        source: 'devices',
                        id: 'device_type_id',
                    },
                },
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'devices',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    doorsCreatedThisYearList: {
        query: {
            columns: [
                {
                    source: 'devices',
                    id: 'device_name',
                    alias: 'Door Name',
                    uuid: 'door_name',
                },
                {
                    source: 'devices',
                    id: 'created',
                    alias: 'Door Registered',
                    uuid: 'door_registered',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    right_value: {
                        source: 'devices',
                        id: 'device_type_id',
                    },
                },
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'devices',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    usersCreatedByMonthList: {
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_month_name_short',
                    value: {
                        source: 'users',
                        id: 'created',
                    },
                    alias: 'month',
                    uuid: 'monthname',
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'users',
                        id: 'first_name',
                    },
                    alias: 'Count',
                    uuid: 'count',
                },
                {
                    source: 'transform',
                    type: 'date_truncate',
                    granularity: {
                        source: 'constant',
                        value: 'month',
                        type: 'string',
                    },
                    value: {
                        source: 'users',
                        id: 'created',
                    },
                    alias: 'month_trunc',
                    uuid: 'month_trunc',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'users',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'year',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    alias: 'month_trunc',
                },
            ],
        },
    },
    validEntriesTodayCount: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    alias: 'Valid Entries Count',
                    uuid: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
        },
    },
    validEntriesByDayList: {
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_date',
                    alias: 'Date',
                    uuid: 'date',
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    alias: 'Valid Entries Count',
                    uuid: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -90,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    right_value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    left_value: {
                        source: 'constant',
                        type: 'integer',
                        value: 2004,
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    alias: 'Date',
                },
            ],
        },
    },
    failedEntriesTodayCount: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    alias: 'Valid Entries Count',
                    uuid: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    test_values: [
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5065,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5056,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5012,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5013,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5014,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5015,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5016,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5017,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5018,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5057,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5058,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5059,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5060,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5061,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5062,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5063,
                        },
                    ],
                },
            ],
        },
    },
    failedEntriesByDayList: {
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_date',
                    alias: 'Date',
                    uuid: 'date',
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    alias: 'Valid Entries Count',
                    uuid: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -90,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    test_values: [
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5065,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5056,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5012,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5013,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5014,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5015,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5016,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5017,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5018,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5057,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5058,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5059,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5060,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5061,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5062,
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 5063,
                        },
                    ],
                },
            ],
            sort: [
                {
                    source: 'columns',
                    alias: 'Date',
                },
            ],
        },
    },
    countSuspenderUsers: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'users',
                        id: 'first_name',
                    },
                    alias: 'Suspended User Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'not_null',
                    value: {
                        source: 'users',
                        id: 'deactivated',
                    },
                },
            ],
        },
    },
    suspendedUsersList: {
        query: {
            columns: [
                {
                    source: 'users',
                    id: 'first_name',
                    alias: 'First Name',
                    uuid: 'first_name',
                },

                {
                    source: 'users',
                    id: 'last_name',
                    alias: 'Last Name',
                    uuid: 'last_name',
                },
                {
                    source: 'users',
                    id: 'created',
                    alias: 'Created',
                    uuid: 'created',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'not_null',
                    value: {
                        source: 'users',
                        id: 'deactivated',
                    },
                },
            ],
        },
    },
    countExpiredCredentials: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    alias: 'Expired Credentials Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'credentials',
                    id: 'is_expired',
                },
            ],
        },
    },
    expiredCredentialsList: {
        query: {
            columns: [
                {
                    alias: 'Card Number',
                    uuid: 'card_number',
                    source: 'credentials',
                    id: 'card_number',
                },
                {
                    alias: 'Facility Code',
                    uuid: 'facility_code',
                    source: 'credentials',
                    id: 'facility_code',
                },
                {
                    alias: 'Format',
                    uuid: 'format',
                    source: 'credential_types',
                    id: 'credential_type',
                },
                {
                    alias: 'Expiration Date',
                    uuid: 'expiration_date',
                    source: 'credentials',
                    id: 'expiration_date',
                },
            ],
            filters: [
                {
                    source: 'credentials',
                    id: 'is_expired',
                },
            ],
        },
    },
    countUnassignedCredentials: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    alias: 'Not Assigned Credentials Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'credentials',
                    id: 'not_assigned',
                },
            ],
        },
    },
    unassignedCredentialsList: {
        query: {
            columns: [
                {
                    alias: 'Card Number',
                    uuid: 'card_number',
                    source: 'credentials',
                    id: 'card_number',
                },
                {
                    alias: 'Facility Code',
                    uuid: 'facility_code',
                    source: 'credentials',
                    id: 'facility_code',
                },
                {
                    alias: 'Format',
                    uuid: 'format',
                    source: 'credential_types',
                    id: 'credential_type',
                },
            ],
            filters: [
                {
                    source: 'credentials',
                    id: 'not_assigned',
                },
            ],
        },
    },
    countTotalEvents: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                    alias: 'Total Event Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    totalEventsList: {
        query: {
            columns: [
                {
                    alias: 'Event Time',
                    uuid: 'event_time',
                    source: 'transform',
                    type: 'timezone_conversion',
                    timezone: {
                        source: 'constant',
                        value: 'US/Eastern',
                        type: 'string',
                    },
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    alias: 'Site',
                    uuid: 'event_site_name',
                    source: 'events',
                    id: 'event_site_name',
                },
                {
                    alias: 'Device',
                    uuid: 'device_name',
                    source: 'events',
                    id: 'object_name',
                },
                {
                    alias: 'Name',
                    source: 'events',
                    uuid: 'event_user_name',
                    id: 'event_user_name',
                },
                {
                    alias: 'Event Name',
                    source: 'event_types',
                    id: 'i18_event_type',
                    uuid: 'event_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'events',
                    id: 'occurred',
                },
            ],
        },
    },
    countFailedAccess: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                    alias: 'Failed Access Event Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    test_values: [
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5065',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5056',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5012',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5013',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5014',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5015',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5016',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5017',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5018',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5057',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5058',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5059',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5060',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5061',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5062',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5063',
                        },
                    ],
                },
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    failedAccessList: {
        query: {
            columns: [
                {
                    alias: 'Event Time',
                    uuid: 'event_time',
                    source: 'transform',
                    type: 'timezone_conversion',
                    timezone: {
                        source: 'constant',
                        value: 'US/Eastern',
                        type: 'string',
                    },
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    alias: 'Site',
                    uuid: 'site_name',
                    source: 'sites',
                    id: 'site_name',
                },
                {
                    alias: 'Device',
                    uuid: 'device_name',
                    source: 'devices',
                    id: 'device_name',
                },
                {
                    alias: 'First Name',
                    source: 'users',
                    id: 'first_name',
                    uuid: 'user_first_name',
                },
                {
                    alias: 'Last Name',
                    source: 'users',
                    id: 'last_name',
                    uuid: 'user_last_name',
                },
                {
                    alias: 'Event Name',
                    source: 'event_types',
                    id: 'i18_event_type',
                    uuid: 'event_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    test_values: [
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5065',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5056',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5012',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5013',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5014',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5015',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5016',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5017',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5018',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5057',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5058',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5059',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5060',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5061',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5062',
                        },
                        {
                            source: 'constant',
                            type: 'integer',
                            value: '5063',
                        },
                    ],
                },
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    countCriticalEvents: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                    alias: 'Event Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'security_action_group_actions',
                        id: 'security_action_group_id',
                    },
                    right_value: {
                        source: 'constant',
                        value: '2',
                        type: 'integer',
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    criticalEventsList: {
        query: {
            columns: [
                {
                    alias: 'Event Time',
                    uuid: 'event_time',
                    source: 'transform',
                    type: 'timezone_conversion',
                    timezone: {
                        source: 'constant',
                        value: 'US/Eastern',
                        type: 'string',
                    },
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    alias: 'Site',
                    uuid: 'site_name',
                    source: 'sites',
                    id: 'site_name',
                },
                {
                    alias: 'Device',
                    uuid: 'device_name',
                    source: 'devices',
                    id: 'device_name',
                },
                {
                    alias: 'First Name',
                    source: 'users',
                    id: 'first_name',
                    uuid: 'user_first_name',
                },
                {
                    alias: 'Last Name',
                    source: 'users',
                    id: 'last_name',
                    uuid: 'user_last_name',
                },
                {
                    alias: 'Event Name',
                    source: 'event_types',
                    id: 'i18_event_type',
                    uuid: 'event_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'security_action_group_actions',
                        id: 'security_action_group_id',
                    },
                    right_value: {
                        source: 'constant',
                        value: 2,
                        type: 'integer',
                    },
                },
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'events',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    countAnomalies: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'security_event_enhancement',
                        id: 'security_event_enhancement_id',
                    },
                    alias: 'Count Anomalies',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'security_event_enhancement',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    anomaliesList: {
        query: {
            columns: [
                {
                    alias: 'Event Time',
                    uuid: 'event_time',
                    source: 'transform',
                    type: 'timezone_conversion',
                    timezone: {
                        source: 'constant',
                        value: 'US/Eastern',
                        type: 'string',
                    },
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                },
                {
                    alias: 'Site',
                    uuid: 'site_name',
                    source: 'sites',
                    id: 'site_name',
                },
                {
                    alias: 'Device',
                    uuid: 'device_name',
                    source: 'devices',
                    id: 'device_name',
                },
                {
                    alias: 'First Name',
                    source: 'users',
                    id: 'first_name',
                    uuid: 'user_first_name',
                },
                {
                    alias: 'Last Name',
                    source: 'users',
                    id: 'last_name',
                    uuid: 'user_last_name',
                },
                {
                    alias: 'Event Name',
                    source: 'event_types',
                    id: 'i18_event_type',
                    uuid: 'event_name',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'security_event_enhancement',
                                id: 'occurred',
                            },
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'timezone_conversion',
                            timezone: {
                                source: 'constant',
                                value: 'US/Eastern',
                                type: 'string',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    average90DayDailyAttendance: {
        datasets: [
            {
                id: 'daily_counts',
                selectable: true,
                columns: [
                    {
                        id: 'event_date',
                        type: 'timestamp_ntz',
                        expression: {
                            source: 'table',
                            id: 'event_date',
                        },
                        selectable: true,
                    },
                    {
                        id: 'visit_count',
                        type: 'integer',
                        expression: {
                            source: 'table',
                            id: 'visit_count',
                        },
                        selectable: true,
                    },
                ],
                source: {
                    query: {
                        columns: [
                            {
                                uuid: 'event_date',
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'constant',
                                        value: 'US/Eastern',
                                        type: 'string',
                                    },
                                    value: {
                                        source: 'events',
                                        id: 'occurred',
                                    },
                                },
                            },
                            {
                                uuid: 'visit_count',
                                source: 'aggregation',
                                type: 'count_distinct',
                                over: {
                                    source: 'events',
                                    id: 'actor_object_id',
                                },
                            },
                        ],
                        filters: [
                            {
                                source: 'operation',
                                type: 'greater',
                                left_value: {
                                    source: 'columns',
                                    alias: 'event_date',
                                },
                                right_value: {
                                    source: 'transform',
                                    type: 'date_add',
                                    shift: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: -90,
                                    },
                                    granularity: {
                                        source: 'constant',
                                        value: 'day',
                                        type: 'string',
                                    },
                                    value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            timezone: {
                                                source: 'constant',
                                                value: 'US/Eastern',
                                                type: 'string',
                                            },
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                source: 'operation',
                                type: 'include',
                                value: {
                                    source: 'events',
                                    id: 'event_type_id',
                                },
                                test_values: [
                                    {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 2004,
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
        query: {
            columns: [
                {
                    source: 'operation',
                    type: 'round',
                    value: {
                        source: 'aggregation',
                        type: 'average',
                        over: {
                            source: 'daily_counts',
                            id: 'visit_count',
                        },
                    },
                    alias: 'Attendance',
                    uuid: 'count',
                },
            ],
        },
    },
    attendanceByDay90Days: {
        query: {
            columns: [
                {
                    uuid: 'event_date',
                    alias: 'Event Date',
                    source: 'transform',
                    type: 'extract_date',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                },
                {
                    uuid: 'visit_count',
                    alias: 'Attendance',
                    source: 'aggregation',
                    type: 'count_distinct',
                    over: {
                        source: 'events',
                        id: 'actor_object_id',
                    },
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'columns',
                        alias: 'event_date',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_add',
                        shift: {
                            source: 'constant',
                            type: 'integer',
                            value: -90,
                        },
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'extract_date',
                            value: {
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'constant',
                                    value: 'US/Eastern',
                                    type: 'string',
                                },
                                value: {
                                    source: 'database',
                                    id: 'current_timestamp',
                                },
                            },
                        },
                    },
                },
                {
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: 'events',
                        id: 'event_type_id',
                    },
                    test_values: [
                        {
                            source: 'constant',
                            type: 'integer',
                            value: 2004,
                        },
                    ],
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'event_date',
                },
            ],
        },
    },
    average90DaysAttendanceByWeekday: {
        datasets: [
            {
                id: 'daily_counts',
                selectable: true,
                columns: [
                    {
                        id: 'event_date',
                        type: 'timestamp_ntz',
                        expression: {
                            source: 'table',
                            id: 'event_date',
                        },
                        selectable: true,
                    },
                    {
                        id: 'visit_count',
                        type: 'integer',
                        expression: {
                            source: 'table',
                            id: 'visit_count',
                        },
                        selectable: true,
                    },
                ],
                source: {
                    query: {
                        columns: [
                            {
                                uuid: 'event_date',
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'constant',
                                        value: 'US/Eastern',
                                        type: 'string',
                                    },
                                    value: {
                                        source: 'events',
                                        id: 'occurred',
                                    },
                                },
                            },
                            {
                                uuid: 'visit_count',
                                source: 'aggregation',
                                type: 'count_distinct',
                                over: {
                                    source: 'events',
                                    id: 'actor_object_id',
                                },
                            },
                        ],
                        filters: [
                            {
                                source: 'operation',
                                type: 'greater',
                                left_value: {
                                    source: 'columns',
                                    alias: 'event_date',
                                },
                                right_value: {
                                    source: 'transform',
                                    type: 'date_add',
                                    shift: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: -90,
                                    },
                                    granularity: {
                                        source: 'constant',
                                        value: 'day',
                                        type: 'string',
                                    },
                                    value: {
                                        source: 'transform',
                                        type: 'extract_date',
                                        value: {
                                            source: 'transform',
                                            type: 'timezone_conversion',
                                            timezone: {
                                                source: 'constant',
                                                value: 'US/Eastern',
                                                type: 'string',
                                            },
                                            value: {
                                                source: 'database',
                                                id: 'current_timestamp',
                                            },
                                        },
                                    },
                                },
                            },
                            {
                                source: 'operation',
                                type: 'include',
                                value: {
                                    source: 'events',
                                    id: 'event_type_id',
                                },
                                test_values: [
                                    {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 2004,
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
        query: {
            columns: [
                {
                    source: 'transform',
                    type: 'extract_weekday_num',
                    value: {
                        source: 'daily_counts',
                        id: 'event_date',
                    },
                    uuid: 'weekday_num',
                    alias: 'Weekday Number',
                },
                {
                    source: 'transform',
                    type: 'extract_weekday_short',
                    value: {
                        source: 'daily_counts',
                        id: 'event_date',
                    },
                    uuid: 'weekday',
                    alias: 'Day of Week',
                },
                {
                    source: 'operation',
                    type: 'round',
                    value: {
                        source: 'aggregation',
                        type: 'average',
                        over: {
                            source: 'daily_counts',
                            id: 'visit_count',
                        },
                    },
                    alias: 'Attendance',
                    uuid: 'attendance',
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'weekday_num',
                },
            ],
        },
    },
    usersCreatedThisMonth: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'users',
                        id: 'first_name',
                    },
                    alias: 'User Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'users',
                        id: 'created',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'month',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    usersCreatedLast12Months: {
        query: {
            columns: [
                {
                    uuid: 'user_year',
                    source: 'transform',
                    type: 'extract_year',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'user_month_num',
                    source: 'transform',
                    type: 'extract_month',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'user_month_name',
                    source: 'transform',
                    type: 'extract_month_name_long',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    alias: 'User Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'users',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            granularity: {
                                source: 'constant',
                                type: 'string',
                                value: 'year',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'user_year',
                },
                {
                    source: 'columns',
                    uuid: 'user_month_num',
                },
            ],
        },
    },
    credentialsCreatedThisMonth: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'credentials',
                        id: 'credential_id',
                    },
                    alias: 'Credential Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'credentials',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    credentialsCreatedLast12Months: {
        query: {
            columns: [
                {
                    uuid: 'credential_year',
                    source: 'transform',
                    type: 'extract_year',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'credentials',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'credential_month_num',
                    source: 'transform',
                    type: 'extract_month',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'credentials',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'credential_month_name',
                    source: 'transform',
                    type: 'extract_month_name_long',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'credentials',
                            id: 'created',
                        },
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    alias: 'Credential Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'credentials',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            granularity: {
                                source: 'constant',
                                type: 'string',
                                value: 'year',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'credential_year',
                },
                {
                    source: 'columns',
                    uuid: 'credential_month_num',
                },
            ],
        },
    },
    doorsCreatedThisMonth: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'devices',
                        id: 'device_id',
                    },
                    alias: 'Device Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    right_value: {
                        source: 'devices',
                        id: 'device_type_id',
                    },
                },
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'devices',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    doorsCreatedLast12Months: {
        query: {
            columns: [
                {
                    uuid: 'door_year',
                    source: 'transform',
                    type: 'extract_year',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'devices',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'door_month_num',
                    source: 'transform',
                    type: 'extract_month',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'devices',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'door_month_name',
                    source: 'transform',
                    type: 'extract_month_name_long',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'devices',
                            id: 'created',
                        },
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    alias: 'Door Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    right_value: {
                        source: 'devices',
                        id: 'device_type_id',
                    },
                },
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'devices',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            granularity: {
                                source: 'constant',
                                type: 'string',
                                value: 'year',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'door_year',
                },
                {
                    source: 'columns',
                    uuid: 'door_month_num',
                },
            ],
        },
    },
    sitesCreatedThisMonth: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'sites',
                        id: 'site_id',
                    },
                    alias: 'Site Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'sites',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    sitesCreatedLast12Months: {
        query: {
            columns: [
                {
                    uuid: 'site_year',
                    source: 'transform',
                    type: 'extract_year',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'sites',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'site_month_num',
                    source: 'transform',
                    type: 'extract_month',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'sites',
                            id: 'created',
                        },
                    },
                },
                {
                    uuid: 'site_month_name',
                    source: 'transform',
                    type: 'extract_month_name_long',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'sites',
                            id: 'created',
                        },
                    },
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        value: 1,
                        type: 'integer',
                    },
                    alias: 'Site Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'constant',
                            value: 'US/Eastern',
                            type: 'string',
                        },
                        value: {
                            source: 'sites',
                            id: 'created',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: 'month',
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            shift: {
                                source: 'constant',
                                type: 'integer',
                                value: -1,
                            },
                            granularity: {
                                source: 'constant',
                                type: 'string',
                                value: 'year',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'site_year',
                },
                {
                    source: 'columns',
                    uuid: 'site_month_num',
                },
            ],
        },
    },
    badgesPrintedThisMonth: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'badge_print_job_item',
                        id: 'user_id',
                    },
                    alias: 'Badges Printed',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'badge_print_job_item',
                            id: 'time',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'month',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -1,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    countActiveAdministratorsLastWeek: {
        query: {
            columns: [
                {
                    alias: 'User',
                    uuid: 'admin_full_name',
                    source: 'admins',
                    id: 'full_name',
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                    alias: 'Actions Performed',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater_equal',
                    left_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'events',
                            id: 'occurred',
                        },
                    },
                    right_value: {
                        source: 'transform',
                        type: 'extract_date',
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -7,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
    usersDeletedThisMonth: {
        query: {
            columns: [
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'events',
                        id: 'event_id',
                    },
                    alias: 'Deleted User Count',
                    uuid: 'count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'equals',
                    left_value: {
                        source: 'constant',
                        value: 3003,
                        type: 'integer',
                    },
                    right_value: {
                        source: 'table',
                        id: 'security_action_id',
                    },
                },
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'month',
                            type: 'string',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
        },
    },
    data_explorer_all_activity: {
        query: {
            columns: [
                {
                    source: 'users',
                    id: 'full_name',
                    uuid: 'user_full_name',
                    alias: 'User',
                },
                {
                    source: 'devices',
                    id: 'device_name',
                    uuid: 'device_name',
                    alias: 'Door',
                },
                {
                    source: 'sites',
                    id: 'site_name',
                    uuid: 'site_name',
                    alias: 'Site',
                },
                {
                    source: 'event_site_address',
                    id: 'city',
                    uuid: 'site_address_city',
                    alias: 'City',
                },
                {
                    source: 'event_site_address',
                    id: 'state',
                    uuid: 'site_address_state',
                    alias: 'State',
                },
                {
                    source: 'event_site_address',
                    id: 'zipcode',
                    uuid: 'site_address_zip',
                    alias: 'Zip Code',
                },
                {
                    source: 'event_site_address',
                    id: 'country',
                    uuid: 'site_address_country',
                    alias: 'Country',
                },
                {
                    source: 'event_site_address_geolocation',
                    id: 'latitude',
                    uuid: 'site_address_latitude',
                    alias: 'Latitude',
                },
                {
                    source: 'event_site_address_geolocation',
                    id: 'longitude',
                    uuid: 'site_address_longitude',
                    alias: 'Longitude',
                },
                {
                    source: 'event_types',
                    id: 'i18_event_type',
                    uuid: 'event_type_name',
                    alias: 'Event Name',
                },
                {
                    source: 'events',
                    id: 'event_type',
                    uuid: 'event_type',
                    alias: 'Event Type',
                },
                {
                    source: 'events',
                    id: 'occurred',
                    uuid: 'event_time_utc',
                    alias: 'Event Time (UTC)',
                },
                {
                    alias: 'Event Time (Local)',
                    uuid: 'event_time_local',
                    source: 'transform',
                    type: 'timezone_conversion',
                    timezone: {
                        source: 'panels',
                        id: 'timezone',
                    },
                    value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    external_reference_id: 'event_time_local',
                },
                {
                    source: 'credentials',
                    id: 'card_number',
                    uuid: 'credential_card_number',
                    alias: 'Card Number',
                },
                {
                    source: 'credential_types',
                    id: 'credential_type',
                    uuid: 'credential_type',
                    alias: 'Credential Type',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: 'events',
                        id: 'event_type',
                    },
                    test_values: [
                        {
                            source: 'constant',
                            type: 'string',
                            value: 'PANEL_EVENT',
                        },
                        {
                            source: 'constant',
                            type: 'string',
                            value: 'CAMERA_EVENT',
                        },
                    ],
                },
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_add',
                        granularity: {
                            source: 'constant',
                            type: 'string',
                            value: 'month',
                        },
                        shift: {
                            source: 'constant',
                            type: 'integer',
                            value: -3,
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'event_time_utc',
                    direction: 'DESC',
                },
            ],
        },
    },
    data_explorer_occupancy_and_usage: {
        queryId: '1',
        datasets: [
            {
                id: 'occupancy_event_data',
                selectable: true,
                columns: [
                    {
                        id: 'event_time_utc',
                        type: 'timestamp_ntz',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_time_utc',
                        },
                    },
                    {
                        id: 'site_object_id',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'site_object_id',
                        },
                    },
                    {
                        id: 'site_name',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'site_name',
                        },
                    },
                    {
                        id: 'device_name',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'device_name',
                        },
                    },
                    {
                        id: 'user_full_name',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'user_full_name',
                        },
                    },
                    {
                        id: 'event_time_local',
                        type: 'timestamp_ntz',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_time_local',
                        },
                    },
                    {
                        id: 'timezone',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'timezone',
                        },
                    },
                    {
                        id: 'normal_hours',
                        type: 'boolean',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'normal_hours',
                        },
                    },
                    {
                        id: 'occupancy_timeout',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'occupancy_timeout',
                        },
                    },
                    {
                        id: 'event_order',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_order',
                        },
                    },
                    {
                        id: 'event_order_reversed',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_order_reversed',
                        },
                    },
                ],
                source: {
                    query: {
                        columns: [
                            {
                                alias: 'Event Time (UTC)',
                                uuid: 'event_time_utc',
                                source: 'events',
                                id: 'occurred',
                                external_reference_id: 'event_time_utc',
                            },
                            {
                                alias: 'Site Id',
                                uuid: 'site_object_id',
                                source: 'sites',
                                id: 'site_object_id',
                                external_reference_id: 'site_object_id',
                            },
                            {
                                alias: 'Site',
                                uuid: 'site_name',
                                source: 'sites',
                                id: 'site_name',
                                external_reference_id: 'site_name',
                            },
                            {
                                alias: 'Door',
                                uuid: 'device_name',
                                source: 'devices',
                                id: 'device_name',
                                external_reference_id: 'device_name',
                            },
                            {
                                alias: 'User',
                                uuid: 'user_full_name',
                                source: 'users',
                                id: 'full_name',
                                external_reference_id: 'user_full_name',
                            },
                            {
                                alias: 'Event Time (Local)',
                                uuid: 'event_time_local',
                                source: 'transform',
                                type: 'timezone_conversion',
                                timezone: {
                                    source: 'panels',
                                    id: 'timezone',
                                },
                                value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                                external_reference_id: 'event_time_local',
                            },
                            {
                                alias: 'Timezone',
                                uuid: 'timezone',
                                source: 'panels',
                                id: 'timezone',
                                external_reference_id: 'timezone',
                            },
                            {
                                alias: 'Normal Hours',
                                uuid: 'normal_hours',
                                source: 'operation',
                                type: 'between',
                                lower_bound: {
                                    source: 'constant',
                                    type: 'integer',
                                    value: 8,
                                },
                                upper_bound: {
                                    source: 'constant',
                                    type: 'integer',
                                    value: 17,
                                },
                                value: {
                                    source: 'transform',
                                    type: 'extract_hour',
                                    value: {
                                        source: 'transform',
                                        type: 'timezone_conversion',
                                        timezone: {
                                            source: 'panels',
                                            id: 'timezone',
                                        },
                                        value: {
                                            source: 'events',
                                            id: 'occurred',
                                        },
                                    },
                                },
                                external_reference_id: 'normal_hours',
                            },
                            {
                                alias: 'Occupancy Timeout',
                                uuid: 'occupancy_timeout',
                                source: 'transform',
                                type: 'coalesce',
                                arguments: [
                                    {
                                        source: 'occupancy_configuration',
                                        id: 'occupant_timeout',
                                    },
                                    {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 4,
                                    },
                                ],
                                external_reference_id: 'occupancy_timeout',
                            },
                            {
                                alias: 'Event Order',
                                uuid: 'event_order',
                                source: 'window',
                                type: 'row_number',
                                over: {
                                    source: 'window',
                                    type: 'over',
                                    partition: [
                                        {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                timezone: {
                                                    source: 'panels',
                                                    id: 'timezone',
                                                },
                                                value: {
                                                    source: 'events',
                                                    id: 'occurred',
                                                },
                                            },
                                        },
                                        {
                                            source: 'events',
                                            id: 'actor_object_id',
                                        },
                                        {
                                            source: 'events',
                                            id: 'site_object_id',
                                        },
                                    ],
                                    order: [
                                        {
                                            source: 'events',
                                            id: 'occurred',
                                            direction: 'ASC',
                                        },
                                    ],
                                },
                                external_reference_id: 'event_order',
                            },
                            {
                                alias: 'Event Order Reversed',
                                uuid: 'event_order_reversed',
                                source: 'window',
                                type: 'row_number',
                                over: {
                                    source: 'window',
                                    type: 'over',
                                    partition: [
                                        {
                                            source: 'transform',
                                            type: 'extract_date',
                                            value: {
                                                source: 'transform',
                                                type: 'timezone_conversion',
                                                timezone: {
                                                    source: 'panels',
                                                    id: 'timezone',
                                                },
                                                value: {
                                                    source: 'events',
                                                    id: 'occurred',
                                                },
                                            },
                                        },
                                        {
                                            source: 'events',
                                            id: 'actor_object_id',
                                        },
                                        {
                                            source: 'events',
                                            id: 'site_object_id',
                                        },
                                    ],
                                    order: [
                                        {
                                            source: 'events',
                                            id: 'occurred',
                                            direction: 'DESC',
                                        },
                                    ],
                                },
                                external_reference_id: 'event_order_reversed',
                            },
                        ],
                        filters: [
                            {
                                source: 'operation',
                                type: 'equals',
                                left_value: {
                                    source: 'events',
                                    id: 'event_type_id',
                                },
                                right_value: {
                                    source: 'constant',
                                    type: 'integer',
                                    value: 2004,
                                },
                            },
                            {
                                source: 'operation',
                                type: 'greater',
                                left_value: {
                                    source: 'events',
                                    id: 'occurred',
                                },
                                right_value: {
                                    source: 'transform',
                                    type: 'date_add',
                                    granularity: {
                                        source: 'constant',
                                        type: 'string',
                                        value: 'month',
                                    },
                                    shift: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: -3,
                                    },
                                    value: {
                                        source: 'database',
                                        id: 'current_timestamp',
                                    },
                                },
                            },
                        ],
                    },
                },
            },
            {
                id: 'augmented_occupancy_event_data',
                selectable: true,
                columns: [
                    {
                        id: 'event_time_utc',
                        type: 'timestamp_ntz',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_time_utc',
                        },
                    },
                    {
                        id: 'site_object_id',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'site_object_id',
                        },
                    },
                    {
                        id: 'site_name',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'site_name',
                        },
                    },
                    {
                        id: 'device_name',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'device_name',
                        },
                    },
                    {
                        id: 'user_full_name',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'user_full_name',
                        },
                    },
                    {
                        id: 'event_time_local',
                        type: 'timestamp_ntz',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_time_local',
                        },
                    },
                    {
                        id: 'timezone',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'timezone',
                        },
                    },
                    {
                        id: 'normal_hours',
                        type: 'boolean',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'normal_hours',
                        },
                    },
                    {
                        id: 'occupancy_timeout',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'occupancy_timeout',
                        },
                    },
                    {
                        id: 'event_order',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_order',
                        },
                    },
                    {
                        id: 'event_order_reversed',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_order_reversed',
                        },
                    },
                    {
                        id: 'event_type',
                        type: 'string',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'event_type',
                        },
                    },
                    {
                        id: 'first_event',
                        type: 'boolean',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'first_event',
                        },
                    },
                    {
                        id: 'last_event',
                        type: 'boolean',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'last_event',
                        },
                    },
                    {
                        id: 'occupancy_factor',
                        type: 'integer',
                        selectable: true,
                        expression: {
                            source: 'table',
                            id: 'occupancy_factor',
                        },
                    },
                ],
                source: {
                    query: [
                        {
                            columns: [
                                {
                                    source: 'occupancy_event_data',
                                    id: 'event_time_utc',
                                    alias: 'Event Time (UTC)',
                                    uuid: 'event_time_utc',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'site_object_id',
                                    alias: 'Site Id',
                                    uuid: 'site_object_id',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'site_name',
                                    alias: 'Site',
                                    uuid: 'site_name',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'device_name',
                                    alias: 'Door',
                                    uuid: 'device_name',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'user_full_name',
                                    alias: 'User',
                                    uuid: 'user_full_name',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'event_time_local',
                                    alias: 'Event Time (Local)',
                                    uuid: 'event_time_local',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'timezone',
                                    alias: 'Timezone',
                                    uuid: 'timezone',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'normal_hours',
                                    alias: 'Normal Hours',
                                    uuid: 'normal_hours',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'occupancy_timeout',
                                    alias: 'Occupancy Timeout',
                                    uuid: 'occupancy_timeout',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'event_order',
                                    alias: 'Event Order',
                                    uuid: 'event_order',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'event_order_reversed',
                                    alias: 'Event Order Reversed',
                                    uuid: 'event_order_reversed',
                                },
                                {
                                    source: 'constant',
                                    type: 'string',
                                    value: 'Open',
                                    alias: 'Event Type',
                                    uuid: 'event_type',
                                },
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    left_value: {
                                        source: 'occupancy_event_data',
                                        id: 'event_order',
                                    },
                                    right_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 1,
                                    },
                                    alias: 'First Event',
                                    uuid: 'first_event',
                                },
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    left_value: {
                                        source: 'occupancy_event_data',
                                        id: 'event_order_reversed',
                                    },
                                    right_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 1,
                                    },
                                    alias: 'Last Event',
                                    uuid: 'last_event',
                                },
                                {
                                    source: 'operation',
                                    type: 'iff',
                                    condition: {
                                        source: 'operation',
                                        type: 'equals',
                                        left_value: {
                                            source: 'occupancy_event_data',
                                            id: 'event_order',
                                        },
                                        right_value: {
                                            source: 'constant',
                                            type: 'integer',
                                            value: 1,
                                        },
                                    },
                                    pass_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 1,
                                    },
                                    fail_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 0,
                                    },
                                    alias: 'occupancy_factor',
                                    uuid: 'occupancy_factor',
                                },
                            ],
                        },
                        {
                            columns: [
                                {
                                    source: 'transform',
                                    type: 'timezone_conversion',
                                    timezone: {
                                        source: 'constant',
                                        type: 'string',
                                        value: 'UTC',
                                    },
                                    from_timezone: {
                                        source: 'occupancy_event_data',
                                        id: 'timezone',
                                    },
                                    value: {
                                        source: 'operation',
                                        type: 'least',
                                        arguments: [
                                            {
                                                source: 'transform',
                                                type: 'date_add',
                                                granularity: {
                                                    source: 'constant',
                                                    type: 'string',
                                                    value: 'minute',
                                                },
                                                shift: {
                                                    source: 'constant',
                                                    type: 'integer',
                                                    value: 1439,
                                                },
                                                value: {
                                                    source: 'transform',
                                                    type: 'date_truncate',
                                                    granularity: {
                                                        source: 'constant',
                                                        type: 'string',
                                                        value: 'day',
                                                    },
                                                    value: {
                                                        source: 'occupancy_event_data',
                                                        id: 'event_time_local',
                                                    },
                                                },
                                            },
                                            {
                                                source: 'transform',
                                                type: 'date_add',
                                                granularity: {
                                                    source: 'constant',
                                                    type: 'string',
                                                    value: 'hour',
                                                },
                                                shift: {
                                                    source: 'occupancy_event_data',
                                                    id: 'occupancy_timeout',
                                                },
                                                value: {
                                                    source: 'occupancy_event_data',
                                                    id: 'event_time_local',
                                                },
                                            },
                                        ],
                                    },
                                    alias: 'Event Time (UTC)',
                                    uuid: 'event_time_utc',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'site_object_id',
                                    alias: 'Site Id',
                                    uuid: 'site_object_id',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'site_name',
                                    alias: 'Site',
                                    uuid: 'site_name',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'device_name',
                                    alias: 'Door',
                                    uuid: 'device_name',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'user_full_name',
                                    alias: 'User',
                                    uuid: 'user_full_name',
                                },
                                {
                                    source: 'operation',
                                    type: 'least',
                                    arguments: [
                                        {
                                            source: 'transform',
                                            type: 'date_add',
                                            granularity: {
                                                source: 'constant',
                                                type: 'string',
                                                value: 'minute',
                                            },
                                            shift: {
                                                source: 'constant',
                                                type: 'integer',
                                                value: 1439,
                                            },
                                            value: {
                                                source: 'transform',
                                                type: 'date_truncate',
                                                granularity: {
                                                    source: 'constant',
                                                    type: 'string',
                                                    value: 'day',
                                                },
                                                value: {
                                                    source: 'occupancy_event_data',
                                                    id: 'event_time_local',
                                                },
                                            },
                                        },
                                        {
                                            source: 'transform',
                                            type: 'date_add',
                                            granularity: {
                                                source: 'constant',
                                                type: 'string',
                                                value: 'hour',
                                            },
                                            shift: {
                                                source: 'occupancy_event_data',
                                                id: 'occupancy_timeout',
                                            },
                                            value: {
                                                source: 'occupancy_event_data',
                                                id: 'event_time_local',
                                            },
                                        },
                                    ],
                                    alias: 'Event Time (Local)',
                                    uuid: 'event_time_local',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'timezone',
                                    alias: 'Timezone',
                                    uuid: 'timezone',
                                },
                                {
                                    source: 'null',
                                    alias: 'Normal Hours',
                                    uuid: 'normal_hours',
                                },
                                {
                                    source: 'occupancy_event_data',
                                    id: 'occupancy_timeout',
                                    alias: 'Occupancy Timeout',
                                    uuid: 'occupancy_timeout',
                                },
                                {
                                    source: 'null',
                                    alias: 'Event Order',
                                    uuid: 'event_order',
                                },
                                {
                                    source: 'null',
                                    alias: 'Event Order Reversed',
                                    uuid: 'event_order_reversed',
                                },
                                {
                                    source: 'constant',
                                    type: 'string',
                                    value: 'Tentative Departure',
                                    alias: 'Event Type',
                                    uuid: 'event_type',
                                },
                                {
                                    source: 'null',
                                    alias: 'First Event',
                                    uuid: 'first_event',
                                },
                                {
                                    source: 'null',
                                    alias: 'Last Event',
                                    uuid: 'last_event',
                                },
                                {
                                    source: 'constant',
                                    type: 'integer',
                                    value: -1,
                                    alias: 'occupancy_factor',
                                    uuid: 'occupancy_factor',
                                },
                            ],
                            filters: [
                                {
                                    source: 'operation',
                                    type: 'equals',
                                    left_value: {
                                        source: 'occupancy_event_data',
                                        id: 'event_order_reversed',
                                    },
                                    right_value: {
                                        source: 'constant',
                                        type: 'integer',
                                        value: 1,
                                    },
                                },
                            ],
                        },
                    ],
                },
            },
        ],
        query: {
            columns: [
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'event_time_utc',
                    alias: 'Event Time (UTC)',
                    uuid: 'event_time_utc',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'site_name',
                    alias: 'Site',
                    uuid: 'site_name',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'device_name',
                    alias: 'Door',
                    uuid: 'device_name',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'user_full_name',
                    alias: 'User',
                    uuid: 'user_full_name',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'event_time_local',
                    alias: 'Event Time (Local)',
                    uuid: 'event_time_local',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'timezone',
                    alias: 'Timezone',
                    uuid: 'timezone',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'normal_hours',
                    alias: 'Normal Hours',
                    uuid: 'normal_hours',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'occupancy_timeout',
                    alias: 'Occupancy Timeout',
                    uuid: 'occupancy_timeout',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'event_order',
                    alias: 'Event Order',
                    uuid: 'event_order',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'event_order_reversed',
                    alias: 'Event Order Reversed',
                    uuid: 'event_order_reversed',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'event_type',
                    alias: 'Event Type',
                    uuid: 'event_type',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'first_event',
                    alias: 'First Event',
                    uuid: 'first_event',
                },
                {
                    source: 'augmented_occupancy_event_data',
                    id: 'last_event',
                    alias: 'Last Event',
                    uuid: 'last_event',
                },
                {
                    source: 'window',
                    type: 'sum',
                    over: {
                        source: 'window',
                        type: 'over',
                        partition: [
                            {
                                source: 'augmented_occupancy_event_data',
                                id: 'site_object_id',
                            },
                            {
                                source: 'transform',
                                type: 'extract_date',
                                value: {
                                    source: 'augmented_occupancy_event_data',
                                    id: 'event_time_local',
                                },
                            },
                        ],
                        order: [
                            {
                                source: 'augmented_occupancy_event_data',
                                id: 'event_time_utc',
                            },
                        ],
                        frame: ['-Inf', 0],
                    },
                    value: {
                        source: 'augmented_occupancy_event_data',
                        id: 'occupancy_factor',
                    },
                    alias: 'Occupancy',
                    uuid: 'occupancy',
                },
            ],
            sort: [
                {
                    source: 'columns',
                    uuid: 'event_time_utc',
                    direction: 'DESC',
                },
            ],
        },
    },
    data_explorer_credentials: {
        query: {
            columns: [
                {
                    source: 'users',
                    id: 'full_name',
                    uuid: 'user_full_name',
                    alias: 'User',
                },
                {
                    source: 'credential_types',
                    id: 'credential_type',
                    uuid: 'credential_type',
                    alias: 'Credential Type',
                },
                {
                    source: 'credentials',
                    id: 'card_number',
                    uuid: 'credential_card_number',
                    alias: 'Card Number',
                },
                {
                    source: 'credentials',
                    id: 'enablement_date',
                    uuid: 'credential_enablement_date',
                    alias: 'Enabled On',
                },
                {
                    source: 'credentials',
                    id: 'expiration_date',
                    uuid: 'credential_expiration_date',
                    alias: 'Expires After',
                },
                {
                    source: 'credential_usage',
                    id: 'last_used',
                    uuid: 'last_used_date',
                    alias: 'Last Used Date',
                },
                {
                    source: 'operation',
                    type: 'not',
                    value: {
                        source: 'credentials',
                        id: 'is_expired',
                    },
                    uuid: 'credential_valid',
                    alias: 'Credential Valid',
                },
                {
                    source: 'operation',
                    type: 'or',
                    left_value: {
                        source: 'operation',
                        type: 'greater',
                        left_value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                        right_value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                    right_value: {
                        source: 'operation',
                        type: 'is_null',
                        value: {
                            source: 'users',
                            id: 'deactivated',
                        },
                    },
                    uuid: 'user_enabled',
                    alias: 'User Enabled',
                },
            ],
        },
    },
    data_explorer_doors_and_sites: {
        query: {
            columns: [
                {
                    source: 'sites',
                    id: 'site_name',
                    uuid: 'site_name',
                    alias: 'Site',
                },
                {
                    source: 'devices',
                    id: 'device_name',
                    uuid: 'device_name',
                    alias: 'Device',
                },
                {
                    source: 'panels',
                    id: 'panel_name',
                    uuid: 'panel_name',
                    alias: 'Panel',
                },
                {
                    source: 'panels',
                    id: 'serial_number',
                    uuid: 'panel_serial_number',
                    alias: 'Panel Serial Number',
                },
                {
                    source: 'site_address',
                    id: 'city',
                    uuid: 'site_address_city',
                    alias: 'City',
                },
                {
                    source: 'site_address',
                    id: 'state',
                    uuid: 'site_address_state',
                    alias: 'State',
                },
                {
                    source: 'site_address',
                    id: 'zipcode',
                    uuid: 'site_address_zip',
                    alias: 'Zip Code',
                },
                {
                    source: 'site_address',
                    id: 'country',
                    uuid: 'site_address_country',
                    alias: 'Country',
                },
                {
                    source: 'site_address_geolocation',
                    id: 'latitude',
                    uuid: 'site_address_latitude',
                    alias: 'Latitude',
                },
                {
                    source: 'site_address_geolocation',
                    id: 'longitude',
                    uuid: 'site_address_longitude',
                    alias: 'Longitude',
                },
            ],
        },
    },
    data_explorer_admins_and_roles: {
        query: {
            columns: [
                {
                    source: 'admins',
                    id: 'full_name',
                    uuid: 'admin_name',
                    alias: 'Name',
                },
                {
                    source: 'transform',
                    type: 'coalesce',
                    arguments: [
                        {
                            source: 'admin_email',
                            id: 'email',
                        },
                        {
                            source: 'admin_identity',
                            id: 'email',
                        },
                    ],
                    uuid: 'admin_email',
                    alias: 'Email',
                },
                {
                    source: 'admins',
                    id: 'timezone',
                    uuid: 'admin_timezone',
                    alias: 'Timezone',
                },
                {
                    source: 'admins',
                    id: 'created',
                    uuid: 'admin_craeted',
                    alias: 'Created',
                },
                {
                    source: 'transform',
                    type: 'coalesce',
                    arguments: [
                        {
                            source: 'admins',
                            id: 'last_login_time',
                        },
                        {
                            source: 'app_user',
                            id: 'last_login_time',
                        },
                    ],
                    uuid: 'admin_last_login_time',
                    alias: 'Last Login Time',
                },
                {
                    source: 'admin_roles',
                    id: 'role_name',
                    uuid: 'admin_role',
                    alias: 'Role',
                },
            ],
        },
    },
    data_explorer_users_and_groups: {
        query: {
            columns: [
                {
                    source: 'users',
                    id: 'full_name',
                    uuid: 'user_full_name',
                    alias: 'User',
                },
                {
                    source: 'user_groups',
                    id: 'user_group_name',
                    uuid: 'user_group_name',
                    alias: 'Group',
                },
                {
                    source: 'user_groups',
                    id: 'created',
                    uuid: 'user_group_created',
                    alias: 'Group Created',
                },
                {
                    source: 'operation',
                    type: 'is_null',
                    value: {
                        source: 'users',
                        id: 'deactivated',
                    },
                    uuid: 'user_enabled',
                    alias: 'User Enabled',
                },
            ],
        },
    },
    pivot_table: {
        query: {
            columns: [
                {
                    source: 'sites',
                    id: 'site_name',
                    uuid: 'site',
                    alias: 'Site',
                },
                {
                    source: 'events',
                    id: 'event_type',
                    uuid: 'event_type',
                    alias: 'Event Type',
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        type: 'integer',
                        value: 1,
                    },
                    uuid: 'count',
                    alias: 'Event Count',
                },
            ],
        },
    },
    nested_pivot_table: {
        query: {
            columns: [
                {
                    source: 'event_site_address',
                    id: 'state',
                    uuid: 'state',
                    alias: 'State',
                },
                {
                    source: 'sites',
                    id: 'site_name',
                    uuid: 'site',
                    alias: 'Site',
                },
                {
                    source: 'events',
                    id: 'event_type',
                    uuid: 'event_type',
                    alias: 'Event Type',
                },
                {
                    source: 'devices',
                    id: 'device_name',
                    uuid: 'device_name',
                    alias: 'Device Name',
                },
                {
                    source: 'aggregation',
                    type: 'count',
                    over: {
                        source: 'constant',
                        type: 'integer',
                        value: 1,
                    },
                    uuid: 'count',
                    alias: 'Event Count',
                },
            ],
            filters: [
                {
                    source: 'operation',
                    type: 'greater',
                    left_value: {
                        source: 'events',
                        id: 'occurred',
                    },
                    right_value: {
                        source: 'transform',
                        type: 'date_truncate',
                        granularity: {
                            source: 'constant',
                            value: 'day',
                            type: 'string',
                        },
                        value: {
                            source: 'transform',
                            type: 'date_add',
                            granularity: {
                                source: 'constant',
                                value: 'day',
                                type: 'string',
                            },
                            shift: {
                                source: 'constant',
                                value: -90,
                                type: 'integer',
                            },
                            value: {
                                source: 'database',
                                id: 'current_timestamp',
                            },
                        },
                    },
                },
            ],
        },
    },
};

export default roverQueries;
