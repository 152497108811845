/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { withApi } from '@brivo/onairplus-services';
import { Grid, LoadingIndicator, makeStyles, NoResultsListIcon, EmptyStateSection } from '@brivo/react-components';

import VideoPlayer from '../VideoPlayerV2';
import { parseCameraUrls } from '../VideoPlayerV2/utils';

import { sortCamerasByOrdering } from '../../../LiveVideo/utils';
import { VideoGridItem } from '../../../LiveVideo/components/LiveVideoLayout/VideoGridItem';
import { VideoDraggable } from '../../../LiveVideo/components/LiveVideoLayout/VideoDraggable';
import { VideoDroppable } from '../../../LiveVideo/components/LiveVideoLayout/VideoDroppable';
import { VideoBlockContainer } from '../../../LiveVideo/components/LiveVideoLayout/VideoBlockContainer';

import ApiHelper from '@common/helpers/Helpers';
import { ALL_CAMERAS } from '../../hooks/useLayoutStateV2';
import DoorOverlay from '../../../LiveVideo/components/LiveVideoLayout/DoorOverlay';

const layoutOptions = {
    TwoByTwo: 'TwoByTwo',
    ThreeByThree: 'ThreeByThree',
    FourByFour: 'FourByFour',
};
const layoutTypes = {
    [layoutOptions.TwoByTwo]: 6,
    [layoutOptions.ThreeByThree]: 4,
    [layoutOptions.FourByFour]: 3,
    OneByFive: '1X5',
}; // based on grid 12 columns

const useStyles = makeStyles((theme) => ({
    layoutContainer: {
        paddingTop: theme.spacing(1.5),
        display: 'flex',

        '& [class*="dragWrapper"]': {
            '& [class*="dragIcon"]': {
                zIndex: 1,
                display: 'none',
                transition: 'all',
            },
            '&:hover': {
                '& [class*="dragIcon"]': {
                    display: 'block',
                    cursor: 'move',
                },
            },
        },
    },
    thumbnail: {
        cursor: 'default',
    },
    // taken from RenameCamerasConfiguration
    cameraNameBadge: {
        position: 'absolute',
        left: theme.spacing(1),
        bottom: theme.spacing(1),
        background: '#29394B',
        borderRadius: theme.spacing(3.2),
        padding: theme.spacing(0.2, 1),
        color: '#7DA6DB',
        height: theme.spacing(2.2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 20px)',
        whiteSpace: 'nowrap',
        zIndex: 1,
    },
    liveVideoBlock: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

function CamerasByLayout({
    api,
    setIsDragging,
    handleDragHover,
    currentLayout,
    doorStatusData,
    selectedSites,
    clearSitesFilter,
    cameras,
    setCameraToView,
    forceStaticPreviewImages,
    isDragEnabled = true,
    pulseDoor = false,
}) {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [isDelaying, setIsDelaying] = useState(false);
    const classes = useStyles();

    const limitPerPage = useMemo(() => {
        if (currentLayout.layoutType === layoutOptions.ThreeByThree) {
            return 12;
        }
        if (currentLayout.layoutType === layoutOptions.FourByFour) {
            return 16;
        }

        return 8;
    }, [currentLayout]);

    const camerasByScroll = useMemo(() => {
        if (isEmpty(selectedSites)) {
            return currentLayout?.camerasDetails;
        }

        return currentLayout?.camerasDetails.filter((camDetails) => {
            const cameraInList = cameras?.find((camera) => camera.cameraId === camDetails.id);

            return selectedSites.find((site) => site.id === cameraInList.siteId);
        });
    }, [selectedSites, currentLayout?.camerasDetails, cameras]);

    const camerasSorted = sortCamerasByOrdering(camerasByScroll).slice(0, limitPerPage * page);

    let timeout;
    const delayPageUpdate = () => {
        setIsDelaying(true);

        timeout = setTimeout(() => {
            setPage(page + 1);
            setIsDelaying(false);
        }, 2000);
    };

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, [timeout]);

    const renderVideoBlocks = () =>
        camerasSorted?.map((camera, index) => (
            <Fragment key={camera.id}>
                <VideoGridItem
                    key={camera.id}
                    id={camera.id}
                    columnSize={layoutTypes[currentLayout.layoutType]}
                    nrOfVideos={layoutTypes[currentLayout.layoutType]}
                    hasCustomColumnSize
                >
                    <VideoDraggable
                        camId={camera.id}
                        isDragEnabled={isDragEnabled}
                        setIsDragging={setIsDragging}
                        hasPreviewOpacity
                    >
                        <VideoDroppable camId={camera.id} onHover={handleDragHover}>
                            <VideoBlockContainer id={camera.ordering} className={classes.thumbnail}>
                                <div
                                    className={classes.liveVideoBlock}
                                    data-testid="live-video-block"
                                    onClick={() => setCameraToView(camera)}
                                >
                                    <div className={classes.cameraNameBadge}>
                                        {currentLayout?.name === ALL_CAMERAS && camera.name
                                            ? camera.name
                                            : camera.cameraName}
                                    </div>
                                    {pulseDoor && doorStatusData && (
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <DoorOverlay
                                                apiHelper={api}
                                                cameraId={camera.id}
                                                cameraStatus={camera.status}
                                                doorStatusData={doorStatusData}
                                            />
                                        </div>
                                    )}
                                    <VideoPlayer
                                        streamUrls={parseCameraUrls(camera, currentLayout?.name)}
                                        cameraData={camera}
                                        isPreview
                                        forceStaticImage={forceStaticPreviewImages}
                                    />
                                </div>
                            </VideoBlockContainer>
                        </VideoDroppable>
                    </VideoDraggable>
                    {index + 1 === limitPerPage * page && (
                        <Waypoint onEnter={delayPageUpdate} bottomOffset="-500px" fireOnRapidScroll />
                    )}
                </VideoGridItem>
            </Fragment>
        ));

    return (
        <Grid item container direction="row" spacing={1} className={classes.layoutContainer}>
            {isEmpty(camerasSorted) ? (
                <Grid item xs={12}>
                    <EmptyStateSection
                        title={t('Page.unified-video.view-layout.search.no-results.title')}
                        subtitle={t('Page.unified-video.view-layout.search.no-results.desc')}
                        buttonText={t('Page.unified-video.view-layout.search.no-results.button')}
                        onButtonClick={clearSitesFilter}
                        showButton={true}
                        icon={<NoResultsListIcon style={{ height: 100, width: 100 }} />}
                    />
                </Grid>
            ) : (
                <DndProvider backend={HTML5Backend}>
                    {/* left align video blocks */}
                    {camerasByScroll ? (
                        renderVideoBlocks()
                    ) : (
                        // center align loading indicator
                        <Grid item xs={12}>
                            <LoadingIndicator />
                        </Grid>
                    )}
                    {/* LoadingIndicator added below elements for infinite scroll */}
                    {isDelaying && (
                        // center align loading indicator
                        <Grid item xs={12}>
                            <LoadingIndicator />
                        </Grid>
                    )}
                </DndProvider>
            )}
        </Grid>
    );
}

CamerasByLayout.propTypes = {
    setIsDragging: PropTypes.func.isRequired,
    handleDragHover: PropTypes.func.isRequired,
    restrictNumberOfHdCameras: PropTypes.func,
    acctId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currentLayout: PropTypes.object,
    selectedSites: PropTypes.array,
    clearSitesFilter: PropTypes.func,
    doorStatusData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    cameras: PropTypes.array,
    setCameraToView: PropTypes.func.isRequired,
    forceStaticPreviewImages: PropTypes.bool,
    isDragEnabled: PropTypes.bool,
    pulseDoor: PropTypes.bool,
};

export default withApi(CamerasByLayout, ApiHelper);
