import { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    MuiCloseIcon,
    LoadingIndicator,
    Tooltip,
    ErrorIcon,
    MoreHoriz,
    IconButton,
    CheckboxField,
} from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import IconMenu from '../../../BrivoAnalytics/components/IconMenu';
import useRoverQueries from '../../../BrivoAnalytics/common/useRoverQueries';
import { kpiFormatter } from '../../../BrivoAnalytics/common/utils';
import useStyles from '../../../BrivoAnalytics/components/KPI/KPITile/styles';
import { PROMISE_CANCELLED } from '../../../../common/constants/Constants';
import KPITrend from '../KPITrend';

const KPITile = ({
    configuration,
    isEditViewEnabled,
    dashboardConf,
    setDashboardConf,
    roverApiProxy,
    shouldDisplayViewData,
    isDrawerTile,
    updateSelectedMetrics,
    isSelectedDrawerMetric,
}) => {
    const [kpiValue, setKpiValue] = useState(null);

    const { header, headerKey, isOccupancyTile } = configuration;
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    const [query] = useRoverQueries(configuration?.query ?? configuration?.queryKey);
    const [detailsQuery] = useRoverQueries(
        configuration?.detailsQuery ?? configuration?.detailsQueryKey,
        configuration?.uiVariables?.values
    );

    useEffect(() => {
        const { promise, cancel } = roverApiProxy.getData(query);
        promise?.then(
            (data) => {
                setKpiValue(
                    kpiFormatter(data[0].count, i18n.language) || t('Page.brivo-analytics.my-analytics.kpi-tile')
                ); //TODO: accessor should not be hardcoded
            },
            (e) => {
                if (e.message !== PROMISE_CANCELLED) {
                    console.error(e);
                    setKpiValue(<ErrorIcon />);
                }
            }
        );
        return cancel;
    }, [JSON.stringify(query), roverApiProxy]); // eslint-disable-line react-hooks/exhaustive-deps

    const removeKpiTile = () => {
        let visuals = dashboardConf.visuals.map((item) => {
            if (Array.isArray(item)) {
                return item.filter((metric) => metric.type === 'kpiMetric' && metric.headerKey !== headerKey);
            }

            return item;
        });

        visuals = visuals.filter((visual) => visual.length !== 0);
        setDashboardConf({ ...dashboardConf, visuals });
    };

    const formatStringHeader = (headerKey, header) => {
        if (isOccupancyTile) {
            return header;
        }
        if (headerKey) {
            const isolatedHeaderKey = `${headerKey}`.replace('Page.brivo-analytics.header.', '');
            const headerTooltip = t(`Page.brivo-analytics.header-tooltip.${isolatedHeaderKey}`);
            const header = t(`Page.brivo-analytics.header.${isolatedHeaderKey}`);

            return (
                <>
                    {headerTooltip ? (
                        <Tooltip className={classes.titleTooltipIcon} title={headerTooltip}>
                            <Typography
                                noWrap
                                variant="h5"
                                className={classes.header}
                                data-testid={`kpi-header-${headerKey}`}
                            >
                                {header}
                            </Typography>
                        </Tooltip>
                    ) : (
                        <Typography
                            noWrap
                            variant="h5"
                            className={classes.header}
                            data-testid={`kpi-header-${headerKey}`}
                        >
                            {header}
                        </Typography>
                    )}
                </>
            );
        } else return null;
    };

    const actualHeader = formatStringHeader(headerKey, header);
    const showKPITrend = configuration?.trendQuery !== undefined && configuration?.uiVariables !== undefined;

    return (
        <div>
            {kpiValue === null || kpiValue === undefined ? (
                <LoadingIndicator />
            ) : (
                <Card
                    className={classes.root}
                    onClick={
                        isDrawerTile
                            ? (e) => {
                                  e.preventDefault();
                                  updateSelectedMetrics(configuration);
                              }
                            : null
                    }
                    style={{ cursor: isDrawerTile ? 'pointer' : 'auto' }}
                >
                    <CardContent>
                        <Grid container>
                            <Grid container item xs={12} direction="row" justifyContent="space-between">
                                <Grid item container>
                                    <Grid xs={10} item>
                                        {actualHeader}
                                    </Grid>

                                    {isDrawerTile && (
                                        <Grid xs={2} item className={classes.metricCheckbox}>
                                            <CheckboxField
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    updateSelectedMetrics(configuration);
                                                }}
                                                checked={isSelectedDrawerMetric}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                {shouldDisplayViewData && (
                                    <Grid item xs="auto">
                                        {detailsQuery && !isEditViewEnabled ? (
                                            <IconMenu
                                                icon={<MoreHoriz />}
                                                menuItems={[
                                                    {
                                                        text: t(
                                                            'Page.brivo-analytics.drilldown-table.menu-option.view-drilldown'
                                                        ),
                                                        id: 0, //this will come dynamically in the future
                                                    },
                                                ]}
                                            />
                                        ) : isEditViewEnabled ? (
                                            <IconButton onClick={removeKpiTile} icon={<MuiCloseIcon />} />
                                        ) : null}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.kpiAndTrendDiv}>
                                        <Typography variant="h5" data-testid="metric" title={kpiValue} noWrap>
                                            {kpiValue}
                                        </Typography>
                                        {showKPITrend && (
                                            <KPITrend
                                                trendQuery={configuration.trendQuery}
                                                uiVariables={configuration.uiVariables.values}
                                                roverApiProxy={roverApiProxy}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};
export default KPITile;
