import React from 'react';
import { Waypoint } from 'react-waypoint';
import { camelCase } from 'lodash';

import { Button, CheckboxField, ChevronRight, makeStyles } from '@brivo/react-components';

import { UserCell } from '../../../common/components/UserCell/UserCell';

import { MAX_SELECTED_USERS } from '../../../common/constants/Constants';

const useStyles = makeStyles(() => ({
    cell: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
}));

export const isSelected = (selectedUsers, id) =>
    !!(selectedUsers.length && selectedUsers.find((user) => user.id === id));

export const isUserCheckboxDisabled = (selectedUsers, id) =>
    selectedUsers.length >= MAX_SELECTED_USERS && !isSelected(selectedUsers, id);

const createFormattedNameValue = (canViewCheckboxes, selectedUsers, context, onClick, bulkActionsFlag) => {
    const {
        row: {
            original: { id, firstName, lastName, avatar, suspended },
        },
    } = context;

    const checked = isSelected(selectedUsers, id);
    const onClickHandler = () => onClick(context.row.original);
    const checkboxId = `${camelCase(firstName)}${camelCase(lastName)}_${id}`;
    return (
        <>
            {canViewCheckboxes ? (
                <CheckboxField
                    checked={checked}
                    id={checkboxId}
                    disabled={bulkActionsFlag && isUserCheckboxDisabled(selectedUsers, id)}
                />
            ) : null}
            <UserCell
                firstName={firstName}
                lastName={lastName}
                id={id}
                avatar={avatar}
                suspended={suspended}
                onClickHandler={onClickHandler}
            />
        </>
    );
};

export const fullNameCellWithWaypoint = (
    context,
    loadNextPage,
    selectedUsers,
    canViewCheckboxes,
    onClick,
    bulkActionsFlag
) => {
    const {
        rows,
        row: { index },
    } = context;
    const rowsNum = rows.length;

    const formattedValue = createFormattedNameValue(
        canViewCheckboxes,
        selectedUsers,
        context,
        onClick,
        bulkActionsFlag
    );

    if (rows[rowsNum - 1].index === index) {
        return (
            <>
                <Waypoint key="loadMore" onEnter={loadNextPage} fireOnRapidScroll />
                {formattedValue}
            </>
        );
    }

    return formattedValue;
};

export const fullNameCellWithoutWaypoint = (context, selectedUsers, canViewCheckboxes, onClick, bulkActionsFlag) => {
    return createFormattedNameValue(canViewCheckboxes, selectedUsers, context, onClick, bulkActionsFlag);
};

export const ViewButton = ({ buttonClickHandler, disabled, label = 'View', id }) => {
    const classes = useStyles();
    return (
        <div className={classes.cell}>
            <Button
                size="small"
                type="tertiary"
                onClick={(event) => {
                    if (buttonClickHandler) {
                        event.stopPropagation();
                        buttonClickHandler();
                    }
                }}
                id={`${label}__${id}`}
                disabled={disabled}
                endIcon={<ChevronRight />}
                text={label}
            />
        </div>
    );
};

export const viewCell = (context, onClick, isDisabled, label) => {
    const buttonClickHandler = () => onClick(context.row.original);
    return (
        <ViewButton
            buttonClickHandler={buttonClickHandler}
            disabled={isDisabled}
            label={label}
            id={context.cell.row.original.id}
        />
    );
};

export const customCell = (context, cellMeta, i18n) => {
    const {
        row: {
            original: { customFields },
        },
    } = context;

    let index = customFields.findIndex((element) => element.id === cellMeta.id);
    let value = '';
    if (index >= 0 && customFields[index].value) {
        value = customFields[index].value;
        let type = customFields[index].fieldType;
        //deprecating the user listing call returning `date_value` in favor of the `DATE` type
        const isDateType = type === 'date_value' || type === 'DATE';
        if (type && isDateType && value) {
            value = new Date(value).toLocaleDateString(i18n.language, {
                timeZone: 'UTC',
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
    }

    return (
        <span className="u-trim" title={value}>
            {value}
        </span>
    );
};
