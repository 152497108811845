import { memo } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { ContentBlock, CloseIcon, DraggableIcon, Button, MoreHoriz } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import IconMenu from '../../../BrivoAnalytics/components/IconMenu';
import useStyles from './styles';

const DashboardWidget = ({
    title,
    titleDescription,
    settingMenuItems = [],
    id,
    index,
    children,
    hasInnerScroll = false,
    drawerOpen,
    deSelectWidget,
    moreWidgetSettings,
    cameraContainerHeight,
    isLoading = false,
    isEditViewEnabled = false,
}) => {
    const classes = useStyles({ hasInnerScroll, cameraContainerHeight, isLoading });
    const { t } = useTranslation();

    return (
        <Draggable draggableId={id} index={index} isDragDisabled={!isEditViewEnabled}>
            {(provided) => (
                <div
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    className={classes.widgetContainer}
                    ref={provided.innerRef}
                    id={`dashboard-${id}`}
                    data-testid={`dashboard-${id}`}
                >
                    <ContentBlock
                        title={
                            <div className={classes.widgetHeaderLeft}>
                                {isEditViewEnabled && <DraggableIcon />}
                                <span>{title}</span>
                                {titleDescription}
                            </div>
                        }
                        titleEndAdornment={
                            drawerOpen ? (
                                <Button
                                    text={t('Page.unified-dashboard.widget.title.button-text')}
                                    type="tertiary"
                                    onClick={() => deSelectWidget(title)}
                                    startIcon={<CloseIcon />}
                                />
                            ) : (
                                <div className={classes.widgetSettings}>
                                    {moreWidgetSettings}
                                    <IconMenu
                                        icon={<MoreHoriz />}
                                        menuItems={settingMenuItems}
                                        id={`widget-menu-${id}`}
                                    />
                                </div>
                            )
                        }
                        successMessage={t('Component.Save.save-success')}
                        failureMessage={t('Component.Save.save-fail')}
                    >
                        {children}
                    </ContentBlock>
                </div>
            )}
        </Draggable>
    );
};

DashboardWidget.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired,
    titleDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    index: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    settingMenuItems: PropTypes.array,
    hasInnerScroll: PropTypes.bool,
    cameraContainerHeight: PropTypes.number,
    moreWidgetSettings: PropTypes.node,
    isLoading: PropTypes.bool,
    isEditViewEnabled: PropTypes.bool,
    drawerOpen: PropTypes.bool,
    deSelectWidget: PropTypes.func,
    setFormatTime: PropTypes.func,
};

export default memo(DashboardWidget);
