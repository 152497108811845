import { restClient } from './RestClient';
import { gqlClient } from './GqlClient';
import { defaultRestRequestOptions } from './constants';

export class WebReqApi {
    constructor() {
        // TODO -> IDEA: using get() or query() before auth instance is ready would throw
        // an error/warning and retry after a timeout? sounds terrible
        // !! (1/2 solved) gqlClient implements a "ready" event that waits for the auth instance to be "ready"
        this.gqlClient = gqlClient;
        this.restClient = restClient;
    }

    makeGqlQuery({ gqlTag, variables, options, defaultValue, throwWhenErrored } = {}) {
        return this.gqlClient.query(...arguments);
    }

    makeGqlMutation({ gqlTag, variables, options, defaultValue, throwWhenErrored } = {}) {
        return this.gqlClient.mutate(...arguments);
    }

    makeRestRequest(options = defaultRestRequestOptions) {
        return this.restClient.makeRequest(options);
    }

    makeParallelRestRequests = async (commonOptions = defaultRestRequestOptions, individualOptions = []) => {
        const promises = individualOptions.map((iOpt) => this.restClient.makeRequest({ ...commonOptions, ...iOpt }));
        const results = await Promise.allSettled(promises);
        const resultsWithOptions = results.map((r, idx) => ({ ...r, requestOptions: individualOptions[idx] }));

        const isSuccessful = results.every((res) => res.status === 'fulfilled') ? true : false;

        return { isSuccessful, results: resultsWithOptions };
    };
}
