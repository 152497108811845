import { makeStyles } from '@brivo/react-components';

const styles = makeStyles(() => {
    return {
        pageContentGrid: {
            marginTop: '30px',
        },
        eventPanelGrid: {
            '&.one-column': {
                width: '100%',
                order: 2,
            },
            '&.two-columns': {
                width: '370px',
                maxWidth: '370px',
                order: 1,
            },
        },
        videoSectionGrid: {
            '&.one-column': {
                order: 1,
            },
            '&.two-columns': {
                order: 2,
            },
        },
    };
});

export default styles;
