import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Typography, TextField, ErrorIconLine } from '@brivo/react-components';

import useStyles from './styles';

interface NameInputProps {
    title?: string;
    className?: any;
    placeholder: string;
    required?: boolean;
    value?: string;
    existingNames?: string[];
    errorMessages?: {
        taken?: string;
        empty?: string;
        default?: string;
    };
    onValidationChange: (isValid: boolean, newName: string) => void;
    extraValidationFunction?: (input: string) => boolean;
}

const NameInput = ({
    title,
    className,
    placeholder,
    value,
    existingNames,
    required,
    errorMessages,
    onValidationChange,
    extraValidationFunction,
}: NameInputProps) => {
    const [input, setNewInput] = useState<string>(value ?? '');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isFirstInputEntered, toggleIsFirstInputEntered] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(
        () => {
            validateNewName(input);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        validateNewName(newName);
        setNewInput(newName);

        toggleIsFirstInputEntered(true);
    };

    const validateNewName = (newName: string) => {
        const trimmedName = newName.toLowerCase().trim();
        const isEmptyName = trimmedName === '';
        const isNameTaken = existingNames?.includes(trimmedName);
        const extraValidationFunctionValidity = extraValidationFunction ? extraValidationFunction(newName) : true;

        const isValid = !isEmptyName && !isNameTaken && extraValidationFunctionValidity;

        if (!isValid) {
            if (isEmptyName) {
                setErrorMessage(errorMessages?.empty ?? t('Page.brivo-analytics.input-error.name'));
            } else if (isNameTaken) {
                setErrorMessage(errorMessages?.taken ?? t('Page.brivo-analytics.input-error.name.taken'));
            } else {
                setErrorMessage(errorMessages?.default);
            }
        } else {
            setErrorMessage(undefined);
        }

        onValidationChange(isValid, newName.trim());
    };

    return (
        <Box
            className={clsx(
                classes.input,
                classes.nameInput,
                errorMessage !== undefined && classes.inputError && isFirstInputEntered,
                className
            )}
        >
            <TextField
                type="text"
                id="input"
                label={title}
                required={required}
                placeholder={placeholder}
                value={input}
                maxLength={255}
                onChange={handleNameChange}
            />
            {errorMessage !== undefined && isFirstInputEntered && (
                <Typography variant="caption" className={clsx(classes.errorMessage)}>
                    <span className="error-message">
                        <ErrorIconLine />
                        <span data-testid="error-message-id">{errorMessage}</span>
                    </span>
                </Typography>
            )}
        </Box>
    );
};

export default NameInput;
