import { fetchCookie } from '../../UnifiedVideo/components/VideoPlayerV2/utils';

//adapted from Event Tracker recorded video
export const getClipFromVideoServices = async (
    api,
    cameraId,
    { eventId, startTime, endTime, download },
    signal = null
) => {
    let clipResponse;
    if (eventId) {
        clipResponse = await api.getVideoClipByEventId(eventId, cameraId, signal);
    } else if (startTime && endTime) {
        clipResponse = await api.getVideoClipByStartTimeEndTime(cameraId, startTime, endTime, signal);
    }
    return await prepareClip(api, clipResponse, download).catch((error) => {
        throw error;
    });
};

const prepareClip = async (api, clipResponse, download) => {
    if (clipResponse?.clip) {
        if (!download && clipResponse.clip.includes('eagleeyenetworks.com')) {
            const parts = clipResponse?.clip.split('&access_token=');
            await fetchCookie(parts[0], parts[1]);
            return [{ src: parts[0], type: 'video/mp4' }]; //all eev3 clips are mp4
        } else if (clipResponse.clip.includes('amazonaws.com')) {
            return [{ src: clipResponse.clip, type: 'video/mp4' }]; //all precached clips are mp4
        } else {
            const vcsResponse = await streamClipFromVcs(api, clipResponse.clip).catch((error) => {
                throw error;
            });
            return getClipSrcAndType(vcsResponse);
        }
    } else {
        throw new Error('Page.recorded-video.error.clip-not-retrieved');
    }
};

const streamClipFromVcs = async (api, clipUrl) => {
    const vcsToken = await api.getAccessToken();
    return fetch(clipUrl, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${vcsToken}`,
        },
        responseType: 'blob',
    }).catch(() => {
        throw new Error('Page.recorded-video.error.clip-not-retrieved');
    });
};

const getClipSrcAndType = async (clipResponse) => {
    if (clipResponse.ok) {
        const clipBlob = await clipResponse.blob();
        if (clipBlob.type !== 'video/x-flv' && clipBlob.type !== 'video/mp4') {
            throw new Error('Page.recorded-video.error.clip-unsupported-format');
        }
        const file = new Blob([clipBlob], {
            type: clipBlob.type,
        });
        const srcUrl = URL.createObjectURL(file);

        return [{ src: srcUrl, type: `${clipBlob.type}` }];
    } else {
        throw new Error('Page.recorded-video.error.cached-clip-not-retrieved');
    }
};

const findPrecachedClip = (event, cameraId) => {
    if (event?.enrichmentMetadata?.precachedClips?.length) {
        return event.enrichmentMetadata?.precachedClips?.find((clip) => clip.cameraId === cameraId) ?? null;
    } else {
        return null;
    }
};

export const getSignedPreviewImageUrl = (event, cameraId) => {
    let signedPreviewImageUrl = null;
    const cachedClip = findPrecachedClip(event, cameraId);
    if (cachedClip) {
        ({
            storedClipLocation: { signedPreviewImageUrl },
        } = cachedClip);
        signedPreviewImageUrl = signedPreviewImageUrl ? signedPreviewImageUrl : null;
    }
    return signedPreviewImageUrl;
};

export const getSignedClipUrlAndType = (event, cameraId) => {
    let signedUrlAndType = null;
    const matchingClip = findPrecachedClip(event, cameraId);
    if (matchingClip) {
        const {
            storedClipLocation: { signedClipUrl, key },
        } = matchingClip;
        const clipContentType = getClipContentType(getCachedClipExtension(key));
        signedUrlAndType = { src: signedClipUrl, type: clipContentType, key: key };
        // if the video is missing a type (mp4/flv), this will crash the VideoPlayer. We should consider this
        // to be missing a clip
        if (signedUrlAndType.type == null) {
            return null;
        }
    }
    return signedUrlAndType;
};

export const getCachedClipExtension = (cachedClipKey) => {
    if (cachedClipKey?.length > 4) {
        return cachedClipKey.substring(cachedClipKey.length - 3);
    } else {
        console.error('invalid cached clip key');
    }
};

export const getClipContentType = (fileExtension) => {
    switch (fileExtension) {
        case 'mp4':
            return 'video/mp4';
        case 'flv':
            return 'video/x-flv';
        default:
            return null;
    }
};

export const getClipForDownload = async (event, cameraId, api, notifications, t) => {
    notifications.addSuccessMessage({ text: t('Page.recorded-video.download-requested') });
    let clip = getSignedClipUrlAndType(event, cameraId);
    if (!clip?.src) {
        notifications.addSuccessMessage({ text: t('Page.recorded-video.video-from-provider') });
        [clip] = await getClipFromVideoServices(api, cameraId, {
            eventId: event.id,
            download: true,
        }).catch(() => {
            return [{}];
        });
    } else if (clip?.type === 'video/x-flv' && clip?.key) {
        let clipResponse = {};
        const clipDownloadResponse = await api.getS3FlvConversionUrl(clip.key, cameraId);
        clipResponse.clip = clipDownloadResponse.clip[0]?.downloadUrl;
        [clip] = await prepareClip(api, clipResponse);
    }

    if (clip?.src) {
        let tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        if (clip?.src?.indexOf('blob:') === -1) {
            const blob = await fetch(clip.src, {
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0',
                },
                cache: 'no-store',
            }).then((r) => r.blob());
            const objectURL = URL.createObjectURL(blob);
            tempLink.href = objectURL;
        } else {
            tempLink.href = clip.src;
        }
        tempLink.setAttribute('download', `${event.id}.${cameraId}.mp4`);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        notifications.addSuccessMessage({ text: t('Page.recorded-video.download-completed') });
    } else {
        notifications.addErrorMessage({ text: t('Page.recorded-video.download-error') });
    }
};
