import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.00098 16H14.001V14H4.00098V5H17.001L17.0016 10.526C17.0012 10.5266 17.001 10.5268 17.001 10.5268V14H17.002L17.0016 10.526C17.0158 10.5069 17.2761 10.1662 17.8575 10.0843C18.4415 9.93848 19.001 10 19.001 10V5C19.001 3.896 18.104 3 17.001 3H4.00098C2.89798 3 2.00098 3.896 2.00098 5V14C2.00098 15.103 2.89798 16 4.00098 16Z"
        />
        <rect x="12.001" y="7" width="4" height="2" />
        <path d="M12.001 11H16.001C16.001 11 15.3613 11.3885 15 11.87C14.6387 12.3514 14.5 13 14.5 13H12.001V11Z" />
        <path d="M5.00098 13C5.00098 11.159 6.15998 10 8.00098 10C9.84198 10 11.001 11.159 11.001 13H5.00098Z" />
        <circle cx="8.00098" cy="8" r="2" />
        <circle cx="19" cy="22" r="1" />
        <path d="M19 11C16.795 11 15 12.794 15 15H17C17 13.898 17.897 13 19 13C20.104 13 21 13.898 21 15C21 16.103 20.104 17 19 17H18V20H20V18.874C21.724 18.428 23 16.861 23 15C23 12.794 21.206 11 19 11Z" />
    </React.Fragment>,
    'UnknownCardDetail'
);
