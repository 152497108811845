import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const NewIndicator = ({ categoryIsNew, item }) => {
    const classes = styles();
    const { t } = useTranslation();

    if ((!item && categoryIsNew) || (item?.isNew && !categoryIsNew)) {
        return (
            <div className={classes.newFeatureOuterDiv}>
                <div className={classes.dot}></div>
                <div className={classes.text}>{t('Navigation.new-item')}</div>
            </div>
        );
    }
    return null;
};

export default NewIndicator;
