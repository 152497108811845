import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { makeStyles, DraggableIcon } from '@brivo/react-components';
import { VIDEO_ITEM } from '../../utils';

const useStyles = makeStyles((theme) => ({
    dragWrapper: {
        position: 'relative',
        cursor: ({ isDragEnabled }) => (isDragEnabled ? 'move' : null),
        opacity: ({ isDragging, hasPreviewOpacity }) => {
            if (hasPreviewOpacity && isDragging) {
                return theme.palette.type === 'dark' ? 0.2 : 0.3;
            }

            return 1;
        },
    },
    dragIcon: {
        position: 'absolute',
        top: 2,
        right: 2,
        backgroundColor: ({ isDragging }) => (isDragging ? theme.palette.colors.blue950 : theme.palette.colors.grey800),
        fill: ({ isDragging }) => (isDragging ? theme.palette.colors.grey200 : theme.palette.colors.blue950),
        zIndex: 1,
        borderRadius: '0 2px 0 3px',
    },
}));

export const VideoDraggable = ({ hasPreviewOpacity = false, camId, setIsDragging, isDragEnabled = true, children }) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: VIDEO_ITEM,
            item: { camId },
            canDrag: () => isDragEnabled,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [camId, isDragEnabled]
    );

    useEffect(() => {
        setIsDragging(isDragging);
    }, [isDragging, setIsDragging]);

    const classes = useStyles({ isDragging, isDragEnabled, hasPreviewOpacity });

    return (
        <div ref={drag} className={classes.dragWrapper}>
            {isDragEnabled && <DraggableIcon className={classes.dragIcon} />}
            {children}
        </div>
    );
};

VideoDraggable.propTypes = {
    hasPreviewOpacity: PropTypes.bool,
    camId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setIsDragging: PropTypes.func.isRequired,
    isDragEnabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
