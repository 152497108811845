import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Typography,
    Popover,
    makeStyles,
    Tooltip,
    LockedIcon,
    MoreVertIcon,
    IconButton,
    Button,
} from '@brivo/react-components';

import DoorActionFromCamera from './DoorActionFromCamera';
import { useAccessPointsData } from './useAccessPointsData';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    camerasPopup: {
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
        },
    },
    cameraComponent: {
        height: '38px',
        width: '181px',
        padding: '5px 5px 5px 11px',
        opacity: '0.9',
        borderRadius: '3px',
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.colors.grey950 : theme.palette.colors.blue950,
    },
    cameraText: {
        height: '15px',
        fontSize: '12px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
    },
    spaceDiv: {
        height: '3px',
        opacity: '0',
    },
    cameraActionBadgeRight: {
        position: (props) => (props.inlineLayout ? 'relative' : 'absolute'),
        right: theme.spacing(0),
        bottom: (props) => (props.inlineLayout ? 0 : theme.spacing(1)),
        padding: (props) => (props.inlineLayout ? 0 : theme.spacing(0.2, 1)),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: (props) => (props.inlineLayout ? 'auto' : 'calc(100% - 20px)'),
        whiteSpace: 'nowrap',
        zIndex: 1,
        cursor: 'pointer',
    },
    cameraActionBageLeft: {
        position: (props) => (props.inlineLayout ? 'relative' : 'absolute'),
        left: theme.spacing(0),
        bottom: (props) => (props.inlineLayout ? 0 : theme.spacing(1)),
        padding: (props) => (props.inlineLayout ? 0 : theme.spacing(0.2, 1)),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 20px)',
        whiteSpace: 'nowrap',
        zIndex: 1,
        cursor: 'pointer',
    },
}));

const DoorOverlay = ({ cameraId, apiHelper, doorStatusData, layoutSideRight = true, inlineLayout = false }) => {
    const classes = useStyles({ inlineLayout });
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const [accessPoints, handleEnableMonitoringForDoor, handleUnlockDoor] = useAccessPointsData(
        cameraId,
        apiHelper,
        doorStatusData
    );
    const open = Boolean(anchorEl);
    const id = open ? 'doors-popover' : undefined;
    const sideClass = layoutSideRight ? classes.cameraActionBadgeRight : classes.cameraActionBageLeft;

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const cameraComponents = accessPoints?.map((accessPoint) => (
        <div key={accessPoint.id}>
            <li
                key={accessPoint.id}
                id={accessPoint.id}
                data-testid={accessPoint.id}
                className={clsx(classes.cameraComponent, 'u-fx', 'u-fx-align-center', 'u-fx-justify-space-between')}
            >
                <Typography title={accessPoint.name} className={clsx(classes.cameraText, 'u-mr-half')}>
                    {accessPoint.name}
                </Typography>
                <DoorActionFromCamera
                    accessPoint={accessPoint}
                    openConfirmationDialog={handleUnlockDoor}
                    handleEnableMonitoringForDoor={handleEnableMonitoringForDoor}
                />
            </li>
            <div className={classes.spaceDiv} />
        </div>
    ));

    if (!doorStatusData || cameraComponents.length === 0) {
        return null;
    }

    const singleAccessPoint = cameraComponents.length === 1 ? accessPoints[0] : null;
    const hasSingleAccessPointStatus = !!singleAccessPoint?.status?.lockState;

    if (cameraComponents.length === 1 && !hasSingleAccessPointStatus) {
        return null;
    }

    return singleAccessPoint && hasSingleAccessPointStatus ? (
        <Tooltip
            id="tooltip-placement"
            placement={layoutSideRight ? 'left' : 'right'}
            title={t('Page.unified-video-integrations.door-status', {
                doorStatus: singleAccessPoint.status.lockState === 'Locked' ? 'Unlock' : 'Lock',
                doorName: singleAccessPoint.name,
            })}
        >
            <div className={sideClass} id={'door-overlay-' + cameraId} data-testid={'door-overlay-' + cameraId}>
                <DoorActionFromCamera
                    accessPoint={singleAccessPoint}
                    openConfirmationDialog={handleUnlockDoor}
                    handleEnableMonitoringForDoor={handleEnableMonitoringForDoor}
                    inlineLayout={inlineLayout}
                />
            </div>
        </Tooltip>
    ) : (
        <div id={`door-overlay-${cameraId}`} data-testid={`door-overlay-${cameraId}`} className={sideClass}>
            {inlineLayout ? (
                <Button
                    type="secondary"
                    text={t('Page.unified-video.video-modal.button.unlock')}
                    onClick={handleClick}
                    startIcon={<LockedIcon />}
                />
            ) : (
                <IconButton
                    id={id}
                    type="secondary"
                    size="small"
                    onClick={handleClick}
                    className="u-pa-zero"
                    icon={<MoreVertIcon fontSize="small" />}
                />
            )}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onMouseLeave={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                BackdropProps={{
                    open: true,
                    onMouseDown: () => {
                        handleClose();
                    },
                    invisible: true,
                }}
                className={classes.camerasPopup}
            >
                {cameraComponents.length > 0 ? (
                    <ul className={classes.camerasPopup}>{cameraComponents}</ul>
                ) : (
                    <div
                        className={clsx(
                            classes.cameraComponent,
                            'u-fx',
                            'u-fx-align-center',
                            'u-fx-justify-space-between'
                        )}
                    >
                        <Typography className={clsx(classes.cameraText, 'u-mr-half')}>
                            {t('Page.video.no-connected-devices')}
                        </Typography>
                    </div>
                )}
            </Popover>
        </div>
    );
};

export default DoorOverlay;
