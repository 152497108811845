import React from 'react';
import { SvgIcon } from '@brivo/react-components';

export const DoorMarkerLight = ({ width, height, isActive }) => {
    const markerSize = {
        width: width || 50,
        height: height || 50,
    };

    return (
        <SvgIcon viewBox="0 0 50 50" style={markerSize}>
            {isActive && <circle cx="25" cy="25" r="25" fill="#7DA6DB" />}
            <circle cx="25" cy="25" r={isActive ? '22' : '25'} fill="#CED7E2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.7693 14.2799C23.3724 13.7364 21.8823 14.8077 21.8823 16.3554V28.1667V33.2862C21.8823 34.7283 23.1887 35.7864 24.5373 35.4367L30.9474 33.7742C31.8986 33.5275 32.5659 32.6409 32.5659 31.6238V18.8491C32.5659 17.9251 32.0134 17.098 31.1794 16.7736L24.7693 14.2799ZM24.019 16.3554L30.4292 18.8491L30.4292 31.6238L24.019 33.2862V28.1667V16.3554ZM27.2241 24.7309C27.2241 24.1188 26.7458 23.6226 26.1558 23.6226C25.5657 23.6226 25.0874 24.1188 25.0874 24.7309C25.0874 25.343 25.5657 25.8392 26.1558 25.8392C26.7458 25.8392 27.2241 25.343 27.2241 24.7309ZM19.7456 15.8644C20.3356 15.8644 20.8139 16.3606 20.8139 16.9727C20.8139 17.5848 20.3356 18.081 19.7456 18.081H17.6089V27.4032V31.3808H19.7456C20.3356 31.3808 20.8139 31.877 20.8139 32.4891C20.8139 33.1012 20.3356 33.5974 19.7456 33.5974H17.6089C16.4288 33.5974 15.4721 32.605 15.4721 31.3808V27.4032V18.081C15.4721 16.8568 16.4288 15.8644 17.6089 15.8644H19.7456Z"
                fill="#10171F"
            />
        </SvgIcon>
    );
};
