import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Box, ErrorDialog, DialogContentText, Typography, Button } from '@brivo/react-components';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import ContactUsModal from '../UpsellBanner/ContactUsModal';
import useStyles from './styles';
import { Campaign } from '../interfaces';
import { upsellApi } from '../../../webApis/upsell/upsellApi';
import FeaturePreviewImage from './FeaturePreviewImage';

interface FeaturePreviewProps {
    onHideNavItem: (navItemUrl: string) => void;
    children: React.ReactNode;
    accountId: number;
    campaigns: Campaign[];
}

const FeaturePreview = ({ onHideNavItem, children, campaigns, accountId }: FeaturePreviewProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [showContactUsModal, toggleContactUsModal] = useState(false);
    const [showRemoveConfirmationModal, toggleShowRemoveConfirmationModal] = useState(false);
    const [currentFeaturePreview, setCurrentFeaturePreview] = useState<Campaign>();
    const [urlsToFeaturePreviews, setUrlsToFeaturePreviewsMapping] = useState<Map<string, Campaign>>(new Map());

    /*
     * Create mapping of urls to their campaigns
     * Using the urls we can grab the campaign data in constant time
     */
    useEffect(() => {
        const urlToFeaturePreviews = new Map();
        campaigns?.forEach((campaign: Campaign) => {
            if (campaign.campaign_type === 'feature_preview') {
                urlToFeaturePreviews.set(campaign.campaign_url, campaign);
            }
        });

        setUrlsToFeaturePreviewsMapping(urlToFeaturePreviews);
    }, [campaigns]);

    /*
     * Checks the current location to see if feature previews exist for that url
     * If exists, then set that feature preview for that page/url
     */
    useEffect(() => {
        const currentUrl = location.pathname;
        if (urlsToFeaturePreviews.has(currentUrl)) {
            const featurePreview = urlsToFeaturePreviews.get(currentUrl);
            setCurrentFeaturePreview(featurePreview);
        } else {
            setCurrentFeaturePreview(undefined);
        }
    }, [location.pathname, urlsToFeaturePreviews]);

    /*
     * After ContactUsModal form is submitted, remove menu item from navigation
     */
    const handleContactUsFormSubmit = () => {
        if (currentFeaturePreview) {
            onHideNavItem(currentFeaturePreview.campaign_url as string);
            history.push('/');
        }

        toggleContactUsModal(false);
    };

    /*
     * Removes menu item from navigation by emitting an array of urls to the <Navigation> component
     */
    const handleHideCampaign = async () => {
        if (currentFeaturePreview) {
            try {
                upsellApi.hideCampaign(accountId, currentFeaturePreview.campaign_id);
                onHideNavItem(currentFeaturePreview.campaign_url as string);
                toggleShowRemoveConfirmationModal(false);
                history.push('/');
            } catch (error) {
                console.error('Error removing campaign: ', error);
            }
        }
    };

    const handleContactUsClick = () => {
        toggleContactUsModal(true);
    };

    const handleDontShowClick = () => {
        toggleShowRemoveConfirmationModal(true);
    };

    return currentFeaturePreview ? (
        <>
            <ErrorDialog
                open={showRemoveConfirmationModal}
                title={t('InAppMessaging.FeaturePreview.remove-navigation-title')}
                text={t('InAppMessaging.FeaturePreview.remove-navigation-message')}
                children={
                    <DialogContentText>
                        {t('Page.brivo-analytics.my-analytics.delete-section.dialog.text2')}
                    </DialogContentText>
                }
                primaryActionText={t('InAppMessaging.FeaturePreview.confirm')}
                secondaryActionText={t('InAppMessaging.FeaturePreview.cancel')}
                onPrimaryClick={handleHideCampaign}
                onSecondaryClick={() => {
                    toggleShowRemoveConfirmationModal(false);
                }}
            />
            <FeaturePreviewImage featurePreview={currentFeaturePreview} />
            <Box className={classes.gradient}>
                <Box className={classes.container}>
                    <Box className={classes.centeredElement}>
                        <Typography className={classes.heading}>
                            {t('InAppMessaging.FeaturePreview.see-more')}
                        </Typography>
                        <Typography className={classes.upgradeText}>
                            <Trans
                                i18nKey={'InAppMessaging.FeaturePreview.upgrade-enterprise'}
                                values={{ enterprise: t('InAppMessaging.FeaturePreview.enterprise') }}
                                components={{
                                    a: <a />,
                                }}
                            />
                        </Typography>
                        <Box className={clsx(classes.centeredElement, classes.buttonPadding)}>
                            <Button
                                text={t('InAppMessaging.FeaturePreview.contact-us')}
                                type="primary"
                                onClick={handleContactUsClick}
                            />
                        </Box>
                        <Button
                            text={t('InAppMessaging.FeaturePreview.do-not-show')}
                            id="do-not-show-checkbox"
                            type="tertiary"
                            onClick={handleDontShowClick}
                        />
                    </Box>
                </Box>
            </Box>
            {showContactUsModal && (
                <ContactUsModal
                    onContactUsFormSubmit={handleContactUsFormSubmit}
                    onDismissModal={() => toggleContactUsModal(false)}
                    campaign={currentFeaturePreview}
                />
            )}
        </>
    ) : (
        children ?? null
    );
};

export default FeaturePreview;
