export const METRIC_DESIGNATION = 'metric';
export const ROW_DESIGNATION = 'row';
export const COLUMN_DESIGNATION = 'column';

export const windowHeightPixelMultiplier = 48;

export const TOTAL_LABEL = 'Total'; //TODO: i18n
export const TOTAL_ID = 'Total';

export const DATASET_ID_PREFIX = 'rover_visual_raw_data';

export const DOWNLOAD_VISUAL_OPTION = 'visual';
export const DOWNLOAD_DATA_OPTION = 'data';
