import React from 'react';
import { SvgIcon } from '@brivo/react-components';

export const CameraMarkerLight = ({ width, height, isActive }) => {
    const markerSize = {
        width: width || 50,
        height: height || 50,
    };

    return (
        <SvgIcon viewBox="0 0 50 50" style={markerSize}>
            {isActive && <circle cx="25" cy="25" r="25" fill="#7DA6DB" />}
            <circle cx="25" cy="25" r={isActive ? '22' : '25'} fill="#CED7E2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1367 29.553V31.4601M14.1367 31.4601H17.8477C18.56 31.4601 19.2155 31.0789 19.5566 30.4662L21.728 26.5662M14.1367 31.4601V33.3672M34.3119 24.3412L30.0385 29.4268C29.4337 30.1466 28.3864 30.3325 27.5623 29.8663L21.728 26.5662M34.3119 24.3412L35.504 25.0155M34.3119 24.3412L20.501 16.5293C19.57 16.0026 18.3794 16.3152 17.8419 17.2273L15.6591 20.9313C15.1216 21.8434 15.4406 23.0098 16.3716 23.5365L21.728 26.5662M21.5187 21.7764L20.3267 21.1021"
                stroke="#10171F"
                strokeWidth="2.56407"
                strokeLinecap="round"
                fill="#CED7E2"
            />
        </SvgIcon>
    );
};
