import React from 'react';
import { MuiVisibilityOffIcon, makeStyles } from '@brivo/react-components';
import { Link } from 'react-router-dom';

import { APP_HOME_URL } from '../../constants/Constants';
import { history } from '../../../redux/Store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.type === 'dark' ? theme.palette.colors.blue400 : theme.palette.colors.blue800,
    },
    helperText: {
        fontStyle: 'italic',
        color: theme.palette.type === 'dark' ? theme.palette.colors.grey500 : theme.palette.colors.grey700,
    },
    marginTop: {
        marginTop: '2rem',
    },
}));

const NoAccess = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div
            data-testid="page_no_access"
            className="u-fx u-fx-column u-fx-align-center u-fx-justify-center u-grey"
            style={{ height: 'calc(40vh - 2rem)' }}
        >
            <p className="u-mb-full u-extra-large-text">
                <MuiVisibilityOffIcon fontSize="inherit" />
            </p>
            <p className="u-large-text">{t('No.Access-first-row-message')}</p>
            <p className="u-large-text">{t('No.Access-second-row-message')}</p>

            <p className="u-large-text u-mb-double">
                <Link
                    className={classes.link}
                    to=""
                    onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                    }}
                >
                    {t('No.Access-go-back')}
                </Link>{' '}
                {t('No.Access-or')}{' '}
                <Link
                    className={classes.link}
                    to=""
                    onClick={() => {
                        history.push(APP_HOME_URL);
                    }}
                >
                    {t('No.Access-return-home')}
                </Link>
            </p>
            <p className={`${classes.helperText} ${classes.marginTop}`}>{t('No.Access-bottom-first-row-message')}</p>
            <p className={classes.helperText}>{t('No.Access-bottom-second-row-message')}</p>
        </div>
    );
};

export default NoAccess;
