import React, { Suspense, lazy } from 'react';

const UserManagementPage = lazy(() => import('./UserManagementPage'));

const UserManagementContainer = (props) => (
    <Suspense fallback={null}>
        <UserManagementPage {...props} />
    </Suspense>
);

export default UserManagementContainer;
