import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
    DEVICE_STATUS,
    ENRICHMENT_EVENT_TYPE,
    GLOBAL_SCOPE,
    PAGE_SCOPE,
    PANEL_EVENT,
    WEBSOCKET_URL,
} from '../constants/Constants';
import { UserContext } from '../user/UserProvider';
import { usePrevious } from './usePrevious';
import { useFlagClient } from '@brivo/onairplus-services';
import { isEmpty, isEqual, union } from 'lodash';

const DEFAULT_RETRY_INTERVAL = 10 * 1000; // ms
const DEFAULT_MAX_RETRIES = 10;
const PAGES_WITH_SOCKET_BUFFERING = ['/global-view', '/device/status', '/snapshot'];
const PARENT_PAGES_WITH_SOCKET_BUFFERING = ['/floor-plans'];

/**
 * useSocket
 * @description
 * React hook which connects to a websocket specified by an environment variable
 * and allows components to subscribe to messages
 * @param {String} token
 * token used for handshake authentication
 * @param {Number} retryInterval
 * time interval in milliseconds to rety connection at
 * @param {Number} maxRetries
 * maximum number of retries before giving up
 * @returns {{messages: *[], message: {}}} [message]
 */
export const useSocket = (token, retryInterval = DEFAULT_RETRY_INTERVAL, maxRetries = DEFAULT_MAX_RETRIES) => {
    /**
     * @type {[WebSocket, Function]}
     */
    const flagClient = useFlagClient();
    const [socket, setSocket] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const [message, setMessage] = useState({});
    const [messages, setMessages] = useState([]);
    const prevToken = usePrevious(token);
    const buffer = useRef([]);
    const [pageEventTypes, setPageEventTypes] = useState(null);
    const [globalEventTypes, setGlobalEventTypes] = useState(null);
    const [siteIds, setSiteIds] = useState(null);

    const useBufferWebsocketEvents = flagClient.variation('buffer-websocket-events', false);

    let requestTimeout = useRef(null);
    let connect, reconnect;
    const user = useContext(UserContext);

    const send = useCallback(
        (message) => {
            if (message && socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(message));
            }
        },
        [socket]
    );

    const waitUntilWebSocketIsOpen = useCallback(async () => {
        for (let i = 0; i < 100; i++) {
            if (socket.readyState === WebSocket.OPEN) {
                return;
            }
            await new Promise((resolve) => setTimeout(resolve, 10));
        }
    }, [socket]);

    const sendEventTypesUpwards = useCallback(
        async (filterOptions) => {
            function hasNewPageSiteIds(filterOptions) {
                return (
                    filterOptions.scope === PAGE_SCOPE &&
                    filterOptions?.siteIds?.length > 0 &&
                    !(
                        filterOptions?.siteIds?.length === siteIds?.length &&
                        filterOptions?.siteIds?.every((element, index) => element === siteIds[index])
                    )
                );
            }

            if (socket) {
                await waitUntilWebSocketIsOpen();
            }

            if (socket && socket.readyState === WebSocket.OPEN) {
                const hasNewGlobalEventTypes =
                    filterOptions.scope === GLOBAL_SCOPE &&
                    filterOptions.eventTypes.some((eventType) => {
                        return !globalEventTypes?.includes(eventType);
                    });
                const hasNewPageEventTypes =
                    filterOptions.scope === PAGE_SCOPE && !isEqual(filterOptions.eventTypes, pageEventTypes);

                if (hasNewPageSiteIds(filterOptions)) {
                    setSiteIds(filterOptions?.siteIds);
                }
                if (hasNewGlobalEventTypes) {
                    const updatedGlobalEventTypes = union(globalEventTypes, filterOptions.eventTypes);
                    if (pageEventTypes) {
                        socket.send(
                            JSON.stringify({
                                action: 'filter',
                                eventTypes: [...pageEventTypes, ...updatedGlobalEventTypes],
                                siteIds,
                            })
                        );
                    }

                    setGlobalEventTypes(updatedGlobalEventTypes);
                } else if (hasNewPageEventTypes) {
                    if (globalEventTypes) {
                        socket.send(
                            JSON.stringify({
                                action: 'filter',
                                eventTypes: [...globalEventTypes, ...filterOptions.eventTypes],
                                siteIds,
                            })
                        );
                    }
                    setPageEventTypes(filterOptions.eventTypes);
                }
            }
        },
        [socket, pageEventTypes, globalEventTypes, siteIds, waitUntilWebSocketIsOpen]
    );

    const matchesPath = (pathsArr, exact = true) => {
        const currentPath = window.location.pathname;
        if (exact) {
            return pathsArr.includes(currentPath);
        }
        return pathsArr.some((path) => currentPath.startsWith(path));
    };

    const handleNewMessage = useCallback(
        (message) => {
            if (message && buffer.current) {
                if (useBufferWebsocketEvents) {
                    buffer.current.push(message);
                } else {
                    setMessages([message]);
                }
                // This is meant to be temporary
                if (
                    !useBufferWebsocketEvents ||
                    (!matchesPath(PAGES_WITH_SOCKET_BUFFERING, true) &&
                        !matchesPath(PARENT_PAGES_WITH_SOCKET_BUFFERING, false))
                ) {
                    setMessage(message);
                }
            }
        },
        [useBufferWebsocketEvents]
    );

    const clearBuffer = () => {
        buffer.current = [];
        setMessages(buffer.current);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (useBufferWebsocketEvents) {
                if (buffer.current && buffer.current.length === 0) {
                    return;
                }

                setMessages([...buffer.current]);
                buffer.current = [];
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [useBufferWebsocketEvents]);

    const userIsAssignedToSite = (user, data) => {
        return (
            user.assignments.sites.isAll ||
            user.assignments.sites.assignments.some((siteAssignment) => siteAssignment.site.id === data.data.siteId)
        );
    };

    connect = useCallback(() => {
        if (user && user.assignments) {
            const websocket = new WebSocket(`${WEBSOCKET_URL}?token=${btoa(token)}`);

            websocket.addEventListener('open', () => {});

            websocket.addEventListener('message', (event) => {
                const data = JSON.parse(event.data);
                if (data && data.data && (!data.data.siteId || userIsAssignedToSite(user, data))) {
                    handleNewMessage(data);
                }
            });

            websocket.addEventListener('close', (event) => {
                // Enable for debugging
                // console.warn('[@WebSocket] close ', event);
                switch (event.code) {
                    case 1000: // CLOSE_NORMAL
                        break;
                    default:
                        // Abnormal closure
                        reconnect();
                        break;
                }
            });

            websocket.addEventListener('error', (event) => {
                // Enable for debugging
                // console.warn('[@WebSocket] error ', event);
                switch (event.code) {
                    case 'ECONNREFUSED':
                    default:
                        reconnect();
                        break;
                }
            });
            setSocket(websocket);
        }
    }, [token, reconnect, user, handleNewMessage]);

    reconnect = useCallback(() => {
        // If connection closes, set the message to the empty one
        setMessage((message) => {
            if (!isEmpty(message)) {
                return {};
            }
            return message;
        });
        clearBuffer();

        if (requestTimeout.current) {
            clearTimeout(requestTimeout.current);
            requestTimeout.current = null;
        }

        if (socket) {
            // errors and close events close connection
            // this is an edge case handler
            if (socket.readyState !== WebSocket.CLOSED) {
                // Status Code 1000: Normal Closure
                socket.close(1000);
            }
            setSocket(null);
        }

        if (retryCount <= maxRetries) {
            requestTimeout.current = setTimeout(() => {
                connect();
                setRetryCount(retryCount + 1);
            }, retryInterval);
        }
    }, [connect, maxRetries, retryCount, retryInterval, socket]);

    useEffect(() => {
        if ((token && !socket) || prevToken !== token) {
            connect();
        }
    }, [token, prevToken, socket, connect]);

    useEffect(() => {
        return () => {
            if (socket) {
                // Status Code 1000: Normal Closure
                socket.close(1000);
            }
        };
    }, [socket]);

    return { message, messages, sendEventTypesUpwards, send };
};

const WebSocketContext = React.createContext();

const UnwrappedProvider = ({ accessToken, children, retryInterval, maxRetries }) => {
    const context = useSocket(accessToken, retryInterval, maxRetries);
    const value = useMemo(() => context, [context]);

    return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

const mapStateToProps = ({ accessToken }) => ({
    accessToken,
});

export const WebSocketProvider = connect(mapStateToProps)(UnwrappedProvider);

export const useWebSocketMessage = (
    filterOptions = {
        scope: PAGE_SCOPE,
        eventTypes: [DEVICE_STATUS, PANEL_EVENT, ENRICHMENT_EVENT_TYPE],
    }
) => {
    const context = useContext(WebSocketContext);
    const flagClient = useFlagClient();
    const filterLiveEventsByEventType = flagClient?.variation('filtering-live-events-by-event-type', false);

    if (filterLiveEventsByEventType) {
        context.sendEventTypesUpwards(filterOptions);
    }

    if (!context) {
        throw new Error('`useWebSocketMessage` must be used within a `WebSocketProvider`');
    }

    return context;
};
