import React, { Suspense, lazy } from 'react';

const OnairPage = lazy(() => import('./OnairPage'));

const OnairPageContainer = (props) => (
    <Suspense fallback={null}>
        <OnairPage {...props} />
    </Suspense>
);

export default OnairPageContainer;
