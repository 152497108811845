import React, { useEffect } from 'react';

import { Launch, Typography } from '@brivo/react-components';
import clsx from 'clsx';
import { get } from 'lodash';

import { checkPermissions } from '@brivo/onairplus-services';

import { isActiveItem } from '../../../NewNavigationUtils';
import NewIndicator from '../NewIndicator';
import styles from './styles';

const NavItem = ({ item, user, index, nested, setOpened, categoryIsNew }) => {
    const classes = styles();
    const { id, name, icon, url, newTab } = item;

    if (!name) {
        return null;
    }

    const active = isActiveItem(item);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (active) {
            setOpened(active);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const necessaryPermissions = get(item, 'necessaryPermissions', []);
    const anyOfPermissions = get(item, 'anyOfPermissions', []);

    return item.isVisible !== false &&
        checkPermissions({
            userPermissions: user.permissions,
            necessaryPermissions,
            anyOfPermissions,
        }) ? (
        <a
            className={clsx(classes.navItem, { [classes.nested]: nested, [classes.active]: active }, 'u-trim')}
            title={name}
            id={`nav-link-${id}`}
            key={`${name}-${index}`}
            onClick={(e) => {
                if (!item.link) {
                    e.preventDefault();
                    item.onClick();
                } else {
                    // allow for link items to execute onClick actions ( e.g. send Google Analytics when clicking )
                    item.onClick();
                }
                if (!nested) {
                    setOpened(false);
                }
            }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {!nested && icon}
            <Typography className={classes.newNavText} variant="subtitle1" component="p">
                {name}
            </Typography>
            <NewIndicator categoryIsNew={categoryIsNew} item={item} />
            {newTab ? <Launch fontSize="small" className="u-ml-auto" /> : null}
        </a>
    ) : null;
};

export default NavItem;
