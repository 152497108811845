import MarketingProvider from '../components/InAppMarketing/Providers/OptInMonster';
import { useFlagClient } from '@brivo/onairplus-services';

export const useInAppMarketing = () => {
    const flagClient = useFlagClient();
    let enabled = flagClient.variation(MarketingProvider.getFeatureFlag(), false);

    const isEnabled = () => {
        return enabled;
    };

    /**
     * This is mostly because of opt in monsters requirement that it cannot run on localhost:3000, to display a courtesy warning to other devs
     * @returns {void}
     */
    const isAllowed = () => MarketingProvider.isAllowed();

    /**
     * Do Any initialization of the Marketing Provider
     * @returns {void}
     */
    const initialize = () => {
        if (enabled && isAllowed()) {
            MarketingProvider.initialize();
        }
    };

    /**
     * In a single page app, transition
     * @returns {void}
     */
    const checkConditionsToDisplay = () => {
        if (enabled && isAllowed()) {
            MarketingProvider.reset();
        }
    };

    /**
     * Checks Whether the target promotion met the display rules was displayed
     * @param {int} promotionId
     * @returns {boolean}
     */
    const checkPromotionDidDisplay = async (promotionId, delaySeconds) => {
        return await MarketingProvider.checkPromotionDisplayed(promotionId, delaySeconds);
    };

    /**
     * Render the promotion.  Promotion rendering changes by Marketing Provider.  MAY need to find a different home for this??
     * @param {*} promoCode
     * @returns JSX.Element
     */
    const renderPromotion = (promoCode) => {
        return enabled ? MarketingProvider.renderPromotion(promoCode) : null;
    };

    const getLinkToPromotion = (promoCode) => {
        return enabled ? MarketingProvider.getLinkToPromotion(promoCode) : '#';
    };

    const sendDataToPromotion = (promoCode, data) => {
        MarketingProvider.sendDataToPromo(promoCode, data);
    };

    return {
        isEnabled,
        isAllowed,
        initialize,
        checkConditionsToDisplay,
        checkPromotionDidDisplay,
        renderPromotion,
        getLinkToPromotion,
        sendDataToPromotion,
    };
};
