import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    newSnackbar: {
        // minWidth: 500,
        // minHeight: 100,
        // borderLeft: '6px solid',
        // padding: '26px 15px 26px 20px',
        // '& #notistack-snackbar': {
        //     padding: '0px !important',
        //     width: '100%',
        // },
        margin: '20px',
        maxHeight: '185px',
        maxWidth: '565px',
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.colors.grey900 : theme.palette.colors.grey100,
    },
}));

export default useStyles;
