import { GET_EVENT_RANKING_CONFIG } from '@graphql';

import { isDoorType } from '@brivo/device-status-utils';

import { NUMBER_OF_EVENTS_BEFORE_SCROLL } from '@pages/EventTracker/eventTrackerUtils';
import { CAMERA, DOOR, MAX_PAGE_SIZE_GRAPHQL, PANEL } from '../../constants/Constants';
import { WebReqApi } from '../WebReqApi';
import {
    DELETE_FLOOR_PLAN,
    GET_EVENTS_SEVERITY_COUNT_FOR_DEVICES,
    GET_EVENTS_FOR_DEVICES,
    GET_FLOOR_PLAN_BY_ID,
    GET_FLOOR_PLANS,
    GET_SITE_CAMERAS,
    GET_SITE_CAMERAS_WITH_LIVE_URL,
    GET_SITE_CAMERAS_WITH_STATUS,
    GET_SITE_DEVICES,
    GET_SITE_DEVICES_WITH_STATUS,
    GET_SITE_PANELS,
    GET_SITE_PANELS_WITH_STATUS,
    SAVE_FLOOR_PLAN,
    UPDATE_FLOOR_PLAN,
} from './gqlTags';

class FloorPlansApi extends WebReqApi {
    async getSiteDevices(siteId, withStatus) {
        const [fetchDevicesForSiteData, fetchCamerasForSiteData, fetchPanelsForSiteData] = await Promise.all([
            this.fetchDevicesForSite(siteId, withStatus),
            this.fetchCamerasForSite(siteId, withStatus),
            this.fetchPanelsForSite(siteId, withStatus),
        ]);
        const panelsReference = new Set();
        let siteDevices = [];
        const devices = fetchDevicesForSiteData.getAccessPointsForSites ?? [];
        const cameras = fetchCamerasForSiteData.getDetailedCameras ?? [];
        const panels = fetchPanelsForSiteData.getAllPanels ?? [];
        const doors = devices?.filter((device) => isDoorType(device.type));
        devices.forEach((device) => panelsReference.add(device.controlPanelId));

        if (devices?.length > 0) {
            siteDevices = [
                ...doors.map((device) => {
                    return {
                        ...device,
                        type: 'door',
                        deviceType: DOOR,
                    };
                }),
            ];
        }
        if (cameras?.length > 0) {
            siteDevices.push(
                ...cameras.map((cam) => ({
                    ...cam,
                    type: 'camera',
                    deviceType: CAMERA,
                }))
            );
        }

        if (panels.length > 0) {
            siteDevices.push(
                ...panels
                    .filter((panel) => panelsReference.has(panel.id))
                    .map((panel) => ({
                        ...panel,
                        name: panel.panelName,
                        type: 'panel',
                        deviceType: PANEL,
                    }))
            );
        }
        return siteDevices;
    }

    fetchDevicesForSite(siteId, withStatus = false) {
        return this.makeGqlQuery({
            gqlTag: withStatus ? GET_SITE_DEVICES_WITH_STATUS : GET_SITE_DEVICES,
            variables: {
                offset: 0,
                pageSize: MAX_PAGE_SIZE_GRAPHQL,
                sitesOids: siteId,
            },
        });
    }

    fetchCamerasForSite(siteId, withStatus = false) {
        return this.makeGqlQuery({
            gqlTag: withStatus ? GET_SITE_CAMERAS_WITH_STATUS : GET_SITE_CAMERAS,
            variables: {
                offset: 0,
                pageSize: MAX_PAGE_SIZE_GRAPHQL,
                filters: [
                    {
                        field: 'siteId',
                        type: 'field',
                        value: siteId,
                    },
                ],
            },
        });
    }

    fetchPanelsForSite(siteId, withStatus = false) {
        return this.makeGqlQuery({
            gqlTag: withStatus ? GET_SITE_PANELS_WITH_STATUS : GET_SITE_PANELS,
            variables: {
                offset: 0,
                pageSize: MAX_PAGE_SIZE_GRAPHQL,
                sitesOids: siteId,
            },
        });
    }

    fetchCamerasForSiteWithLiveUrl(siteId) {
        return this.makeGqlQuery({
            gqlTag: GET_SITE_CAMERAS_WITH_LIVE_URL,
            variables: {
                offset: 0,
                pageSize: 1000,
                filters: [
                    {
                        field: 'siteId',
                        type: 'field',
                        value: siteId,
                    },
                ],
            },
        });
    }
    saveFloorPlanForSite(floorPlanInput) {
        return this.makeGqlMutation({
            gqlTag: SAVE_FLOOR_PLAN,
            variables: {
                floorPlanInput,
            },
        });
    }

    fetchFloorPlanBySiteId(siteIds) {
        return this.makeGqlQuery({
            gqlTag: GET_FLOOR_PLANS,
            variables: {
                siteIds: siteIds,
            },
        });
    }
    fetchFloorPlanById(floorPlanId) {
        return this.makeGqlQuery({
            gqlTag: GET_FLOOR_PLAN_BY_ID,
            variables: {
                floorPlanId: floorPlanId,
            },
        });
    }

    getDeviceEventsSeverityCount(from, to, deviceList, securityActionIds) {
        return this.makeGqlQuery({
            gqlTag: GET_EVENTS_SEVERITY_COUNT_FOR_DEVICES,
            variables: {
                from: from,
                to: to,
                devices: deviceList,
                securityActionIds: securityActionIds,
            },
        });
    }

    updateFloorPlan(floorPlanUpdateInput) {
        return this.makeGqlMutation({
            gqlTag: UPDATE_FLOOR_PLAN,
            variables: {
                floorPlanUpdateInput,
            },
        });
    }

    deleteFloorPlan(floorPlanId, siteId) {
        return this.makeGqlMutation({
            gqlTag: DELETE_FLOOR_PLAN,
            variables: {
                floorPlanId,
                siteId,
            },
        });
    }

    fetchEventsForDevice(deviceId, from, to, hiddenEvents, securityActionIds) {
        return this.makeGqlQuery({
            gqlTag: GET_EVENTS_FOR_DEVICES,
            variables: {
                from: from,
                to: to,
                limit: NUMBER_OF_EVENTS_BEFORE_SCROLL,
                devices: [deviceId],
                excludedSecurityActionIds: hiddenEvents,
                securityActionIds: securityActionIds,
            },
        });
    }

    getEventsRanking() {
        return this.makeGqlQuery({
            gqlTag: GET_EVENT_RANKING_CONFIG,
            variables: {},
            fetchPolicy: 'no-cache',
        });
    }
}

export const floorPlansApi = new FloorPlansApi();
