import { makeStyles } from '@brivo/react-components';
const styles = makeStyles((theme) => {
    return {
        videoDialog: {
            '& .MuiDialog-paper': {
                overflow: 'inherit',
            },
            margin: 'auto',
            maxWidth: 'calc(78vw)',
            '& .MuiCardContent-root:last-child': {
                paddingBottom: '0px !important',
            },
        },
        header: {
            height: '0px',
            margin: '0px',
            padding: '0px',
        },
        dialogContent: {
            padding: '0px  !important',
        },
        videoGrid: {
            padding: '0px',
        },
        infoGrid: {
            padding: '0, 2rem, 2rem, 2rem',
            height: '80px',
        },
        displayedVideo: {
            width: '100%',
            height: 'calc(70vh - 104px)',
            objectFit: 'cover',
        },
        hiddenLocalVideo: { hidden: true, width: 0 },
        iconButton: {
            position: 'absolute',
            left: '100%',
            top: '-4%',
            '& .MuiSvgIcon-root': {
                fill: '#CED7E2',
            },
        },
        menuIcon: {
            fontSize: '0.75rem',
            height: '1.5rem',
            width: '1.5rem',
            justifyContent: 'right',
        },
        audioCallIcon: { color: 'white' },
        audioCallButton: {
            borderRadius: '50%',
            position: 'absolute',
            top: '104%',
            left: '45%',
            height: '5rem',
            width: '5rem',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.colors.grey800,
            '&:hover': {
                background: theme.palette.colors.grey900,
            },
        },
        micOffIcon: {
            position: 'relative',
            left: '1px',
            top: '2px',
        },
        btn: {
            marginRight: 20,
            textTransform: 'none',
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.875rem',
            minWidth: '300px',
            backgroundColor:
                theme.palette.type === 'dark' ? theme.palette.colors.grey800 : theme.palette.colors.grey400,
        },
        loadingContainer: {
            paddingTop: '4px',
            paddingBottom: '3px',
            minWidth: '300px',
        },
        loadingIndicator: {
            position: 'relative',
            top: '-2px',
        },
        unlockMessage: {
            position: 'relative',
            top: '2px',
            marginTop: '2px',
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.875rem',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : theme.palette.colors.grey600,
        },
        unlockAttempted: {
            fontWeight: '600',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.875rem',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : theme.palette.colors.grey600,
        },
        loadingLine: {
            position: 'relative',
            height: '2px',
            overflow: 'hidden',
            width: '300px',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                animation: '$load 1s linear',
            },
        },
        loadingLineFailure: {
            '&::after': {
                backgroundColor:
                    theme.palette.type === 'dark' ? theme.palette.colors.red400 : theme.palette.colors.red800,
            },
        },
        loadingLineSuccess: {
            '&::after': {
                backgroundColor: theme.palette.colors.green500,
            },
        },
        '@keyframes load': {
            '0%': {
                transform: 'translateX(-100%)',
            },
            '100%': {
                transform: 'translateX(0)',
            },
        },
        doorInfo: {
            fontSize: '1rem',
            padding: '0, 2rem, 2rem, 2rem',
        },
        doorName: {
            fontWeight: 600,
            paddingLeft: '20px',
        },
        timeStamp: {
            marginLeft: '20px',
            color: theme.palette.colors.grey200,
        },
        videoError: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'calc(70vh - 104px)',
            backgroundColor:
                theme.palette.type === 'dark' ? theme.palette.colors.grey1200 : theme.palette.colors.grey50,
        },
        loadingVideo: {
            position: 'absolute',
            left: '45%',
            top: '40%',
        },
    };
});
export default styles;
