import React from 'react';
import { Paper, Typography } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';

const ErrorResultsMessage = ({ hideCaption }) => {
    const { t } = useTranslation();

    return (
        <Paper elevation={0}>
            <div className="u-pa-double u-center">
                <Typography variant="h6">{t('Page.brivo-analytics.table.error-results-message.title')}</Typography>
                {hideCaption !== true ? (
                    <Typography variant="caption">
                        {t('Page.brivo-analytics.table.error-results-message.caption1')}
                    </Typography>
                ) : null}
                {hideCaption !== true ? (
                    <Typography>{t('Page.brivo-analytics.table.error-results-message.caption2')}</Typography>
                ) : null}
            </div>
        </Paper>
    );
};

export default ErrorResultsMessage;
