import { useTranslation } from 'react-i18next';
import { NoResultsListIcon, EmptyStateSection } from '@brivo/react-components';

const EmptyResultsMessage = ({ removeColumns, handleRemoveColumns, hideCaption }) => {
    const { t } = useTranslation();

    return (
        <>
            <EmptyStateSection
                icon={<NoResultsListIcon style={{ height: 100, width: 100 }} />}
                title={t('Page.brivo-analytics.table.empty-results-message.title')}
                subtitle={!hideCaption ? t('Page.brivo-analytics.table.empty-results-message.caption') : null}
                showButton={removeColumns}
                buttonText={t('Page.brivo-analytics.table.empty-results-message.remove-columns-button')}
                onButtonClick={handleRemoveColumns}
            />
        </>
    );
};

export default EmptyResultsMessage;
