import { get } from 'lodash';
import {
    GET_RECENT_ACTIVITY_CSV,
    LIST_AVAILABLE_REPORTS,
    GET_RECENT_ACTIVITY_PREVIEW,
    GET_PRESIGNED_URL_FOR_REPORT,
    GET_GROUPS,
    GET_SITES,
    GET_USERS_WITH_FILTERING,
    GET_RECENT_ACTIVITY_COLUMNS,
    GET_CUSTOM_FIELDS_DISTINCT_VALUE,
    GET_DEVICES_WITH_FILTERING,
} from '../../graphql/queries/dynamicReporting';
import { debouncePromise } from '../utils/promiseUtils';
export default class DynamicReportingApi {
    constructor(authToken) {
        this.authToken = authToken;
    }

    setAppSyncClient(gqlHelper) {
        this.gqlHelper = gqlHelper;
    }

    async getRecentActivityCSV(columnList, filters) {
        const gqlResponse = await this.gqlHelper.query({
            query: GET_RECENT_ACTIVITY_CSV,
            variables: { columnList: columnList, filters: filters },
            fetchPolicy: 'no-cache',
        });
        const data = get(gqlResponse, 'data', {});
        const errors = get(gqlResponse, 'errors', null);
        if (errors) {
            if (!data) {
                throw new Error(errors);
            }
        }
        return data;
    }

    async loadAvailableReports() {
        const gqlResponse = await this.gqlHelper.query({
            query: LIST_AVAILABLE_REPORTS,
            variables: {},
            fetchPolicy: 'no-cache',
        });
        const data = get(gqlResponse, 'data', {});
        const errors = get(gqlResponse, 'errors', null);
        if (errors) {
            if (!data) {
                throw new Error(errors);
            }
        }
        return data;
    }

    async getPresignedUrl(fileName) {
        const gqlResponse = await this.gqlHelper.query({
            query: GET_PRESIGNED_URL_FOR_REPORT,
            variables: { fileName: fileName },
            fetchPolicy: 'no-cache',
        });
        const data = get(gqlResponse, 'data', {});
        const errors = get(gqlResponse, 'errors', null);
        if (errors) {
            if (!data) {
                throw new Error(errors);
            }
        }
        return data;
    }

    getRecentActivityPreview = debouncePromise(
        (columnList, filters) => this._getRecentActivityPreview(columnList, filters),
        500
    );

    _getRecentActivityPreview(columnList, filters) {
        const abortController = new AbortController();
        return {
            value: this.gqlHelper
                .query({
                    query: GET_RECENT_ACTIVITY_PREVIEW,
                    variables: { columnList: columnList, filters: filters },
                    fetchPolicy: 'no-cache',
                    context: {
                        fetchOptions: {
                            signal: abortController.signal,
                        },
                    },
                })
                .then((gqlResponse) => {
                    const data = get(gqlResponse, 'data', {});
                    const errors = get(gqlResponse, 'errors', null);
                    if (errors) {
                        if (!data) {
                            throw new Error(errors);
                        }
                    }
                    return data;
                }),
            aborter: () => abortController.abort(),
        };
    }

    async getRecentActivityColumns() {
        const gqlResponse = await this.gqlHelper.query({
            query: GET_RECENT_ACTIVITY_COLUMNS,
            variables: {},
            fetchPolicy: 'no-cache',
        });
        const data = get(gqlResponse, 'data', {});
        const errors = get(gqlResponse, 'errors', null);
        if (errors) {
            if (!data) {
                throw new Error(errors);
            }
        }
        return data;
    }

    async getGroups() {
        const gqlResponse = await this.gqlHelper.query({
            query: GET_GROUPS,
            variables: {},
            fetchPolicy: 'no-cache',
        });
        const data = get(gqlResponse, 'data', {});
        const errors = get(gqlResponse, 'errors', null);
        if (errors) {
            if (!data) {
                throw new Error(errors);
            }
        }
        return data.getGroups && data.getGroups.length > 0
            ? data.getGroups.map((group) => {
                  return { value: group.name, id: group.id };
              })
            : [];
    }

    async getSites() {
        const gqlResponse = await this.gqlHelper.query({
            query: GET_SITES,
            variables: {},
            fetchPolicy: 'no-cache',
        });
        const data = get(gqlResponse, 'data', {});
        const errors = get(gqlResponse, 'errors', null);
        if (errors) {
            if (!data) {
                throw new Error(errors);
            }
        }
        return data.getSites && data.getSites.length > 0
            ? data.getSites.map((site) => {
                  return { value: site.name, id: site.id };
              })
            : [];
    }

    getUsersWithFiltering = debouncePromise((filters) => this._getUsersWithFiltering(filters), 500);

    _getUsersWithFiltering(filters) {
        const abortController = new AbortController();
        return {
            value: this.gqlHelper
                .query({
                    query: GET_USERS_WITH_FILTERING,
                    variables: { filters: filters },
                    fetchPolicy: 'no-cache',
                    context: {
                        fetchOptions: {
                            signal: abortController.signal,
                        },
                    },
                })
                .then((gqlResponse) => {
                    const data = get(gqlResponse, 'data', {});
                    const errors = get(gqlResponse, 'errors', null);
                    if (errors) {
                        if (!data) {
                            throw new Error(errors);
                        }
                    }
                    return data.getAllUsers && data.getAllUsers.users && data.getAllUsers.users.length
                        ? data.getAllUsers.users.map((user) => {
                              return { id: user.id, value: `${user.firstName} ${user.lastName}` };
                          })
                        : [];
                }),
            aborter: () => abortController.abort(),
        };
    }

    getCustomFieldsDistinctValues = debouncePromise(
        (columnKey, filters, dataType) => this._getCustomFieldsDistinctValues(columnKey, filters, dataType),
        500
    );

    _getCustomFieldsDistinctValues(columnKey, filters, dataType) {
        //note that this basically means always passing a filter, which may just be ""
        const abortController = new AbortController();
        return {
            value: this.gqlHelper
                .query({
                    query: GET_CUSTOM_FIELDS_DISTINCT_VALUE,
                    variables: { columnKey: columnKey, filters: filters },
                    fetchPolicy: 'no-cache',
                    context: {
                        fetchOptions: {
                            signal: abortController.signal,
                        },
                    },
                })
                .then((gqlResponse) => {
                    const data = get(gqlResponse, 'data', {});
                    const errors = get(gqlResponse, 'errors', null);
                    if (errors) {
                        if (!data) {
                            throw new Error(errors);
                        }
                    }
                    return data.getCustomFieldsDistinctValues && data.getCustomFieldsDistinctValues.length
                        ? data.getCustomFieldsDistinctValues.map((item) => {
                              return { id: item[dataType], value: `${item[dataType]}` };
                          })
                        : [];
                }),
            aborter: () => abortController.abort(),
        };
    }

    getDevices = debouncePromise((filters) => this._getDevices(filters), 500);

    _getDevices(filters) {
        const abortController = new AbortController();
        return {
            value: this.gqlHelper
                .query({
                    query: GET_DEVICES_WITH_FILTERING, //this naming convention is starting to get funky
                    variables: { filters: { name: filters } },
                    fetchPolicy: 'no-cache',
                    context: {
                        fetchOptions: {
                            signal: abortController.signal,
                        },
                    },
                })
                .then((gqlResponse) => {
                    const data = get(gqlResponse, 'data', {});
                    const errors = get(gqlResponse, 'errors', null);
                    if (errors) {
                        if (!data) {
                            throw new Error(errors);
                        }
                    }
                    return data.getDevices && data.getDevices.length > 0
                        ? data.getDevices.map((device) => {
                              return { id: device.objectId, value: device.name };
                          })
                        : [];
                }),
            aborter: () => abortController.abort(),
        };
    }

    getDistinctValuesForField(column, filters) {
        if (column.columnKey === 'ACTOR') {
            return this.getUsersWithFiltering({ field: 'name', value: filters, type: 'field' });
        } else if (column.columnKey.indexOf('CF_') === 0) {
            return this.getCustomFieldsDistinctValues(column.columnKey, filters, column.dataDefinition.dataType);
        } else if (column.columnKey === 'GROUPS') {
            return this.getGroups();
        } else if (column.columnKey === 'DEVICE') {
            return this.getDevices();
        } else if (column.columnKey === 'SITE') {
            return this.getSites();
        }
        return Promise.resolve([]); // This method should always return a promise
    }
}
