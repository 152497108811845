import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
    CustomLayoutLandingIcon,
    GenericDialog,
    Grid,
    LoadingIndicator,
    SingleSelectFilter,
    Typography,
} from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';
import CamerasByLayout from '../../UnifiedVideo/components/CamerasByLayout';
import VideoModal from '../../UnifiedVideo/components/VideoModal';
import EventsApi from '../../../common/helpers/EventsApi';
import { UserContext } from '../../../common/user/UserProvider';
import { GQLProviderContext } from '../../../common/utils/CustomApolloProvider';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import { auth } from '../../../common/utils/Auth';
import ApiHelper, { MAX_PAGE_SIZE } from '../../../common/helpers/Helpers';
import { ALL_CAMERAS, MAX_NO_OF_CAMERAS } from '../../UnifiedVideo/hooks/useLayoutStateV2';
import { abortableCall } from '../../AccountSettings/UnifiedVideoIntegrationSection/utils/UnifiedVideoIntegrationUtils';

import useStyles from './styles';
import { mapEventsRanking } from '../utils';

const CamerasLayout = ({
    api,
    selectedSites,
    clearSitesFilter,
    showCameraLayoutModal,
    setShowCameraLayoutModal,
    sites,
    currentCameraLayout,
    handleSaveCameraLayoutSelection,
}) => {
    const [cameras, setCameras] = useState([]);
    const [cameraLayouts, setCameraLayouts] = useState([]);
    const [isLayoutsLoading, setIsLayoutsLoading] = useState(false);
    const [isCamerasDetailsLoading, setIsCamerasDetailsLoading] = useState(true);
    const [layoutCamDetails, setLayoutCamDetails] = useState({});
    const [cameraToView, setCameraToView] = useState(null);
    const [eventsRanking, setEventsRanking] = useState({ infoEvents: [], warningEvents: [], criticalEvents: [] });
    const [eventsRankingLoading, setEventsRankingLoading] = useState({});

    const abortController = useRef();

    const user = useContext(UserContext);
    const gqlHelper = useContext(GQLProviderContext);
    const eventsApi = new EventsApi(gqlHelper);

    const { t } = useTranslation();

    const classes = useStyles();

    const acctId = user && user.accountId ? user.accountId : auth.getAccountId();

    const selectedCameraFromV2 = useMemo(
        () => cameras.find((camera) => camera.cameraId === cameraToView?.id) || {},
        [cameraToView?.id, cameras]
    );

    const fetchCameraLayoutById = useCallback(
        async (id) => {
            // Defensive code for when there is an issue with an id returned as null
            if (typeof id !== 'number') {
                return {};
            }

            try {
                const fetchedLayout = await gqlHelper.getCamerasByLayout(id, MAX_PAGE_SIZE, 0);

                return fetchedLayout;
            } catch (error) {
                // doesn't seem to ever throw,
                // since `handleExceptions` from catch of call returns empty by default
                console.error(error);
            }
        },
        [gqlHelper]
    );

    const getCameras = useCallback(async () => {
        abortController.current = new AbortController();

        try {
            const cameraList = await api.getCamerasListV2(abortController.current.signal);

            if (isEmpty(cameraList)) {
                return [];
            }

            return cameraList;
        } catch (error) {
            console.error(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createAllCamerasLayout = async (layouts, configAction, layoutDetails) => {
        try {
            const defaultLayout = layouts.some((layout) => layout.isDefault);
            const isCreateAction = configAction === 'CREATE';

            const isDefault = isCreateAction ? !defaultLayout : layoutDetails[0].isDefault;
            const layoutId = isCreateAction ? null : layoutDetails[0].id;

            const payload = {
                configAction: configAction,
                layoutId: layoutId,
                isDefault: isDefault,
                layoutType: 'ThreeByThree',
                layoutName: ALL_CAMERAS,
            };

            await gqlHelper.saveV2LiveVideoCustomLayout(payload);
        } catch (error) {
            console.error(error);
        }
    };

    const getAllCameras = useCallback(
        async (offset) => {
            try {
                const allCameras = gqlHelper.getAllCamerasPaginatedWithCount(MAX_NO_OF_CAMERAS, offset);

                return allCameras;
            } catch (error) {
                // doesn't seem to ever throw,
                // since `handleExceptions` from catch of call returns empty by default
                console.error(error);
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gqlHelper]
    );

    // recursion
    const getCamerasForAllCamerasLayout = useCallback(
        async (cameraDetails = [], totalCamerasFetched = 0, offset = 0) => {
            const allCameras = await getAllCameras(offset);
            const newCameraDetails = [...cameraDetails, ...allCameras.cameras];

            totalCamerasFetched += allCameras.count;

            if (totalCamerasFetched >= allCameras.count) {
                return newCameraDetails;
            }

            while (totalCamerasFetched < allCameras.count) {
                return await getCamerasForAllCamerasLayout(
                    newCameraDetails,
                    totalCamerasFetched + allCameras.cameras.length,
                    offset + MAX_NO_OF_CAMERAS
                );
            }
        },
        [getAllCameras]
    );

    const getCameraLayouts = useCallback(async () => {
        abortController.current = new AbortController();
        let fetchedLayouts;

        try {
            fetchedLayouts = await abortableCall(gqlHelper.getVideoLayoutsList, null, abortController.current.signal);

            const allCameraslayoutExist = fetchedLayouts.some((layout) => layout.name === ALL_CAMERAS);

            if (!allCameraslayoutExist) {
                await createAllCamerasLayout(fetchedLayouts, 'CREATE');

                fetchedLayouts = await abortableCall(
                    gqlHelper.getVideoLayoutsList,
                    null,
                    abortController.current.signal
                );
            }

            const defaultLayoutIndex = fetchedLayouts.findIndex((layout) => layout.isDefault);
            fetchedLayouts.unshift(fetchedLayouts.splice(defaultLayoutIndex, 1)[0]);

            return fetchedLayouts;
        } catch (error) {
            // doesn't seem to ever throw,
            // since `handleExceptions` from catch of call returns empty by default
            console.error(error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gqlHelper]);

    const onCamLayoutSelect = async (selectedCamLayout) => {
        // close modal before saving
        setShowCameraLayoutModal(false);
        await handleSaveCameraLayoutSelection(cameraLayouts.find(({ id }) => id === selectedCamLayout.id));
    };

    useEffect(() => {
        const getCameraLayoutsList = async () => {
            try {
                setIsLayoutsLoading(true);

                const camLayouts = await getCameraLayouts();
                setCameraLayouts(camLayouts);

                if (!isEmpty(camLayouts)) {
                    const cams = await getCameras();
                    setCameras(cams);
                } else {
                    setCameras([]);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLayoutsLoading(false);
            }
        };

        // only fetch camera layouts if dashboard config has camera section
        // TODO: what should happen if there are no sites?
        if (!isEmpty(sites)) {
            getCameraLayoutsList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sites]);

    useEffect(() => {
        const getCameraDetails = async () => {
            setIsCamerasDetailsLoading(true);

            const camsFromLayout = await fetchCameraLayoutById(currentCameraLayout?.id);

            if (!isEmpty(camsFromLayout)) {
                if (currentCameraLayout?.name === ALL_CAMERAS) {
                    const allCamerasDetails = await getCamerasForAllCamerasLayout(camsFromLayout.camerasDetails);

                    setLayoutCamDetails({
                        ...currentCameraLayout,
                        // `layoutType` for cameras layout vs `type` for dashboard layout
                        layoutType: camsFromLayout.layoutType || null,
                        camerasDetails: allCamerasDetails,
                    });
                } else {
                    setLayoutCamDetails({
                        ...currentCameraLayout,
                        // `layoutType` for cameras layout vs `type` for dashboard layout
                        layoutType: camsFromLayout.camerasDetails[0]?.layoutType || null,
                        camerasDetails: camsFromLayout.camerasDetails,
                    });
                }
            } else {
                setLayoutCamDetails({
                    ...currentCameraLayout,
                    camerasDetails: [],
                });
            }

            setIsCamerasDetailsLoading(false);
        };

        if (currentCameraLayout?.id) {
            getCameraDetails();
        } else {
            // default state is `true`
            setIsCamerasDetailsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCameraLayout]);

    useEffect(() => {
        const getEventsRanking = async () => {
            try {
                setEventsRankingLoading(true);

                // ranking for all sites, not for individual cameras for now
                const eventsRanking = await eventsApi.getEventRankingConfig();
                const mappedEventsRanking = mapEventsRanking(eventsRanking);

                setEventsRanking(mappedEventsRanking);
            } catch (e) {
                console.error(e);
            } finally {
                setEventsRankingLoading(false);
            }
        };

        getEventsRanking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const customLayoutInitialMessage = () => {
        if (isEmpty(cameras)) {
            return t('Page.video.no-cameras-message');
        } else if (isEmpty(currentCameraLayout)) {
            return t('Page.unified-dashboard.widgets.camera-layouts.no-selected');
        } else if (isLayoutEmpty) {
            return t('Page.video.no-cameras-on-layout');
        } else {
            return t('Page.video.no-layouts-rights-message');
        }
    };

    const isLayoutEmpty = useMemo(
        () => layoutCamDetails && !layoutCamDetails.camerasDetails?.length,
        [layoutCamDetails]
    );

    const layoutAndHasCameras = useMemo(() => layoutCamDetails && !isLayoutEmpty, [isLayoutEmpty, layoutCamDetails]);

    if (isLayoutsLoading || isCamerasDetailsLoading) {
        return (
            <Grid item container justifyContent="center" xs={12}>
                <LoadingIndicator />
            </Grid>
        );
    }

    return (
        <>
            <Grid item container className={classes.errorBoundary}>
                {layoutAndHasCameras ? (
                    <ErrorBoundary sectionName="Camera Widget">
                        <CamerasByLayout
                            currentLayout={layoutCamDetails}
                            setIsDragging={() => {}}
                            handleDragHover={() => {}}
                            acctId={acctId}
                            selectedSites={selectedSites}
                            clearSitesFilter={clearSitesFilter}
                            cameras={cameras}
                            setCameraToView={setCameraToView}
                            forceStaticPreviewImages={false}
                            isDragEnabled={false}
                            pulseDoor={false}
                        />
                    </ErrorBoundary>
                ) : (
                    <Grid container className={classes.landingMessageWrapper}>
                        <CustomLayoutLandingIcon />
                        <Typography className={classes.personalisedMessage}>{customLayoutInitialMessage()}</Typography>
                    </Grid>
                )}
            </Grid>

            <GenericDialog
                open={showCameraLayoutModal && !isLayoutsLoading && !isCamerasDetailsLoading}
                id="cameraLayouts"
                title={t('Page.unified-dashboard.widgets.camera-layouts.title')}
                onClose={() => setShowCameraLayoutModal(false)}
                className={classes.cameraLayoutList}
            >
                <SingleSelectFilter
                    id="CameraLayoutFilter"
                    searchPlaceholder={t('Page.unified-dashboard.widgets.camera-layouts.modal.search.placeholder')}
                    noSelectionLabel={t('Page.unified-dashboard.widgets.camera-layouts.modal.select.placeholder')}
                    onValueChange={onCamLayoutSelect}
                    items={cameraLayouts}
                    mapper={(camLayout) => ({ id: camLayout.id, name: camLayout.name })}
                    noResultMessage={t('Page.unified-dashboard.widgets.camera-layouts.modal.search.no-results')}
                    value={currentCameraLayout}
                />
            </GenericDialog>

            <VideoModal
                currentLayout={currentCameraLayout}
                cameraToView={{ ...cameraToView, associated_devices: selectedCameraFromV2.associatedDeviceIds }}
                setCameraToView={setCameraToView}
                selectedSites={selectedSites}
                eventsApi={eventsApi}
                eventsRanking={eventsRanking}
                eventsRankingLoading={eventsRankingLoading}
            />
        </>
    );
};

CamerasLayout.propTypes = {
    selectedSites: PropTypes.array,
    clearSitesFilter: PropTypes.func,
    showCameraLayoutModal: PropTypes.bool.isRequired,
    setShowCameraLayoutModal: PropTypes.func.isRequired,
    sites: PropTypes.array,
    currentCameraLayout: PropTypes.object,
    handleSaveCameraLayoutSelection: PropTypes.func.isRequired,
};

export default withApi(memo(CamerasLayout), ApiHelper);
