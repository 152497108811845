import React from 'react';
import { Typography, Grid } from '@brivo/react-components';
import PreviewImage from '../PreviewImage';
import EventDetails from './components/EventDetails';
import { useTranslation } from 'react-i18next';
import ApiHelper from '../../../../../../../../../../common/helpers/Helpers';
import { withApi } from '@brivo/onairplus-services';
import { Tooltip } from '@brivo/react-components';
import { isEventAdminPulse } from './util';
import { useTranslatedEventNames } from '../../../../../../../../../../common/hooks/useTranslatedEventNames';
import { getSignedPreviewImageUrl } from '../../../../../../../../utils/clipUtils';
import styles from './styles';
import clsx from 'clsx';

const TimelineItem = ({ api, event, canViewUserPage, handleClickEventCamera, selectedClipClicked, eventRef }) => {
    const classes = styles();
    const { i18n } = useTranslation();
    const { translateEventName } = useTranslatedEventNames();

    const eventName = translateEventName(event.securityActionId, event.detail);
    return (
        <>
            <Grid container className={classes.eventInfoContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.eventTime}>
                        {new Date(event.occurred).toLocaleString(i18n.language, {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        })}
                    </Typography>
                    <Tooltip title={eventName} id={`tooltip-event-name-${event.id}`} size="small" placement="top-end">
                        <Typography
                            component={'div'}
                            className={classes.eventName}
                            data-testid={`event-name-${event.id}`}
                        >
                            <div ref={selectedClipClicked?.eventId === event.id ? eventRef : null}>{eventName}</div>
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>

            <EventDetails
                eventId={event.id}
                device={event.objectName}
                site={event.siteName}
                user={event.actorName}
                userIsClickable={canViewUserPage && !isEventAdminPulse(event)}
                actorId={event.actorId}
            />

            <Grid container spacing={1}>
                {event.deviceCameras?.map((camera) => {
                    const signedPreviewImageUrl = getSignedPreviewImageUrl(event, camera.id);
                    const singleCamera = event.deviceCameras?.length === 1 ? 'Single' : '';
                    return (
                        <Grid item key={camera.id}>
                            <div
                                id={`${selectedClipClicked?.eventId}.${selectedClipClicked?.cameraId}`}
                                data-testid={`${event.id}.${camera.id}`}
                                className={clsx(
                                    classes.videoThumbnail,
                                    selectedClipClicked?.eventId === event.id &&
                                        selectedClipClicked?.cameraId === camera.id &&
                                        'Selected',
                                    singleCamera && 'SingleCamera'
                                )}
                                onClick={() =>
                                    handleClickEventCamera({
                                        event,
                                        cameraId: camera.id,
                                        cameraName: camera.name,
                                        updateTimelinePositionMarker: true,
                                    })
                                }
                            >
                                <PreviewImage
                                    imgSrc={signedPreviewImageUrl}
                                    event={event}
                                    cameraName={camera.name}
                                    cameraId={camera.id}
                                    api={api}
                                />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
            <div className={classes.timelineGroupSpacer}></div>
        </>
    );
};

export default withApi(TimelineItem, ApiHelper);
