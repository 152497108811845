import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { withApi } from '@brivo/onairplus-services';
import {
    Tooltip,
    StatusChip,
    Button,
    Grid,
    SaveActionBar,
    MultiSelectFilter,
    Drawer,
    LoadingIndicator,
    Typography,
    IconButton,
    CloseIcon,
    Divider,
    TextFilter,
    EmptyStateSection,
    Snackbar,
    NoResultsListIconUpdated,
} from '@brivo/react-components';

import MyAnalytics from './MyAnalytics';
import CamerasLayout from './CamerasLayout';
import EventTrackerContainer from './EventTracker/EventTrackerContainer';
import DropDownPageMenu from '../UnifiedVideo/components/DropDownPageMenu';
import DashboardWidget from './components/DashboardWidget';
import CreateDashboardModal from '@pages/UnifiedDashboard/components/CreateDashboardModal';
import DashboardLayout from './components/DashboardLayout';

import { abortableCall } from '../AccountSettings/UnifiedVideoIntegrationSection/utils/UnifiedVideoIntegrationUtils';
import { GQLProviderContext } from '../../common/utils/CustomApolloProvider';
import ApiHelper from '../../common/helpers/Helpers';

import { CameraWidgetIcon, EventTrackerWidgetIcon, KPIMetricsWidgetIcon, FilterIcon } from './icons';
import { SECTION_TYPES, KPI_METRICS_FILTERS } from './constants';
import { FULL_WIDTH, HALF_WIDTH } from '../BrivoAnalytics/components/RenameDialog/constants';

import useStyles from './styles';

export const drawerWidth = 300;
export const smallerDrawerWidth = drawerWidth - 100;
const cameraContainerHeight = 657;
const countDownTimer = 300;

const UnifiedDashboardPage = () => {
    const { t } = useTranslation();
    const notifications = Snackbar();
    const gqlHelper = useContext(GQLProviderContext);
    const [currentDashboardLayout, setCurrentDashboardLayout] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const [clearingValues, setClearingValues] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isSmallerThan600Width, setSmallerThan600Width] = useState(window.innerWidth <= 600);
    const [sites, setSites] = useState([]);
    const [widgetSearchInput, setWidgetSearchInput] = useState('');
    const [isSubDrawerOpen, setIsSubDrawerOpen] = useState(false);
    const [originalWidgetsState, setOriginalWidgetsState] = useState([]);
    const [widgetsToSave, setWidgetsToSave] = useState([]);
    const [showSaveActionBar, setShowSaveActionBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDrawerWidget, setSelectedDrawerWidget] = useState(null);
    const [selectedMetricTiles, setSelectedMetricTiles] = useState([]);
    const [isEventFilterDrawerOpen, setIsEventFilterDrawerOpen] = useState(false);
    // since updating can happen from the dashboard & from the drawer, have temp state
    const [tempSelectedMetricTiles, setTempSelectedMetricTiles] = useState([]);
    const [createDashboardModalOpen, setCreateDashboardModalOpen] = useState(false);
    const [dashboardList, setDashboardList] = useState([]);
    const [currentCameraLayout, setCurrentCameraLayout] = useState({});
    const [showCameraLayoutModal, setShowCameraLayoutModal] = useState(false);
    const [isEditViewEnabled, setIsEditViewEnabled] = useState(false);
    const [dashboardIds, setDashboardIds] = useState(new Set());
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [timeLeft, setTimeLeft] = useState(countDownTimer);

    const abortController = useRef();
    const classes = useStyles();
    const lastAddedWidgetRef = useRef(null);
    const isMounted = useRef({ current: null });

    const defaultDashboards = ['Facility Manager', 'Mission Control', 'Security Operations'];
    const isKPIDrawer = selectedDrawerWidget === SECTION_TYPES.KPI_METRICS;

    const getSites = async () => {
        if (isMounted.current) {
            abortController.current = new AbortController();

            try {
                const sitesResults = await abortableCall(gqlHelper.getSites, null, abortController.current.signal);

                return sitesResults;
            } catch (error) {
                // doesn't seem to ever throw,
                // since `handleExceptions` from catch of call returns empty by default
                console.error(error);
            }
        }
    };

    const getSelectedSites = (layout) => {
        if (layout && layout.globalFilters) {
            const parsedGlobalFilters = JSON.parse(layout.globalFilters);

            if (isEmpty(parsedGlobalFilters)) {
                return [];
            }

            return parsedGlobalFilters;
        }

        return [];
    };

    const handleLayoutSelected = async (layout) => {
        if (layout.id === currentDashboardLayout.id) {
            return;
        }

        setIsLoading(true);
        handleMainDrawerClose(true);

        let dashboardLayout = {};

        const unifiedDashboards = await fetchDashboards();

        await unifiedDashboards.forEach((dashboard) => {
            if (dashboard.id === layout.id) {
                dashboardLayout = {
                    id: dashboard.id,
                    name: dashboard.dashboardName,
                    isDefault: dashboard.isDefault,
                    layoutType: dashboard.layoutType,
                    sharingType: dashboard.sharingType,
                    globalFilters: dashboard.globalFilters,
                };
                setCurrentDashboardLayout(dashboard);
            }
        });

        const globalFilters = await getSelectedSites(dashboardLayout);
        setSelectedSites(globalFilters);

        await getSectionsByDashboard(layout.id);

        setIsLoading(false);
    };

    const clearSitesFilter = () => {
        setClearingValues(true);
        setSelectedSites([]);
    };

    const handleDashboardModalClose = async () => {
        setCreateDashboardModalOpen(false);
    };

    const handleDashboardModalOpen = () => {
        setCreateDashboardModalOpen(true);
    };

    const toggleSidebar = () => {
        setIsSubDrawerOpen(isSubDrawerOpen && !drawerOpen);
        setShowSaveActionBar(false);
        if (drawerOpen) {
            setIsSubDrawerOpen(false);
            setSelectedDrawerWidget(null);
        }

        setDrawerOpen(!drawerOpen);
    };

    const handleMainDrawerClose = (shouldEntireDrawerClose = false) => {
        if (shouldEntireDrawerClose) {
            if (initialWidgetsChanged) {
                setWidgetsToSave(widgetsToSave);
            }
            setDrawerOpen(false);
            setIsSubDrawerOpen(false);
            setSelectedDrawerWidget(null);

            // if kpi metrics were unselected but not saved,
            // restore subdrawer selections to metrics from dashboard
            setSelectedMetricTiles(tempSelectedMetricTiles);
        } else if (isSubDrawerOpen) {
            setSelectedDrawerWidget(null);
            setIsSubDrawerOpen(false);

            // if kpi metrics were unselected but not saved,
            // restore subdrawer selections to metrics from dashboard
            setSelectedMetricTiles(tempSelectedMetricTiles);
        } else {
            handleSaveWidgets();
            setDrawerOpen(false);
        }

        // Find the KPI widget in the original widgets state
        const originalKPIWidget = originalWidgetsState
            .flatMap((section) => section.sectionWidgets)
            .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

        // Find the KPI widget in the widgets to save
        const kpiWidgetToSave = widgetsToSave
            .flatMap((section) => section.sectionWidgets)
            .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

        const isKpiMetricsChanged = !isEqual(originalKPIWidget?.filters, kpiWidgetToSave?.filters);
        if (!isKpiMetricsChanged) {
            setSelectedMetricTiles([...tempSelectedMetricTiles]);
        }
    };

    const resize = () => {
        setTimeout(() => {
            setSmallerThan600Width(window.innerWidth <= 600);
        }, 0);
    };

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    });

    const resetDrawerState = () => {
        setDrawerOpen(false);
        setIsSubDrawerOpen(false);
        setSelectedDrawerWidget(false);
    };

    const hasWidgetsToSave = !isEmpty(widgetsToSave);

    useEffect(() => {
        const pageBoxElement = document.getElementById('page_box');

        if (pageBoxElement) {
            if (drawerOpen) {
                pageBoxElement.style.transition = 'width 0.3s';
                const mainNavWidth = document.getElementsByClassName('NewNavigation')[0].style.width;
                // adjust width of main box without mixins
                // otherwise, the side drawer below would need to be built around more complex solutions
                pageBoxElement.style.width = `calc(100% - ${
                    isSmallerThan600Width ? smallerDrawerWidth : drawerWidth
                }px - ${mainNavWidth})`;
            } else {
                pageBoxElement.style.transition = 'width 0.5s';
                pageBoxElement.style.width = '100%';
            }

            if (!hasWidgetsToSave || isLoading) {
                pageBoxElement.style.display = 'flex';
                pageBoxElement.style.flexDirection = 'column';
            } else {
                pageBoxElement.style.display = 'block';
            }
        }
    }, [isSmallerThan600Width, isSubDrawerOpen, drawerOpen, hasWidgetsToSave, isLoading]);

    useEffect(() => {
        const initialLoading = async () => {
            setIsLoading(true);

            const sites = await getSites();
            const unifiedDashboards = await getUnifiedDashboardLayouts();

            if (!isEmpty(unifiedDashboards)) {
                setSites(sites);
                setDashboardList(unifiedDashboards);

                await getSectionsByDashboard(unifiedDashboards[0]?.id);
            }

            setIsLoading(false);
        };

        if (isMounted.current) {
            initialLoading();
        }

        return () => {
            // cancel all calls, but
            // super edge case where this won't stop gql specific calls are ongoing
            // https://github.com/apollographql/apollo-client/issues/4150#issuecomment-476434972
            abortController.current?.abort();

            isMounted.current = null;

            const pageBoxElement = document.getElementById('page_box');
            pageBoxElement.style.width = '100%';
            pageBoxElement.style.display = 'block';
            pageBoxElement.style.flexDirection = 'unset';
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createUnifiedDashboard = async (
        configAction,
        isDefault,
        dashboardName,
        sharingType,
        layoutType,
        globalFilters
    ) => {
        try {
            const payload = {
                configAction: configAction,
                isDefault: isDefault,
                dashboardName: dashboardName,
                sharingType: sharingType,
                layoutType,
                globalFilters: JSON.stringify(globalFilters),
            };
            const savedUnifiedDashboard = await gqlHelper.saveUnifiedDashboardLayout(payload);
            const unifiedDashboards = await fetchDashboards();
            setDashboardList(unifiedDashboards);
            await unifiedDashboards.forEach((dashboard) => {
                if (dashboard.id === savedUnifiedDashboard.id) {
                    setCurrentDashboardLayout(dashboard);
                }
            });

            resetDrawerState();
            setOriginalWidgetsState([]);
            setWidgetsToSave([]);
            setSelectedMetricTiles([]);
            setTempSelectedMetricTiles([]);
        } catch (e) {
            console.error(e);
        }
    };

    const createDefaultUnifiedDashboard = async (missingDashboardNames, defaultLayoutIndex) => {
        try {
            missingDashboardNames.forEach(async (dashboard) => {
                const isDefault = dashboard === 'Facility Manager' && defaultLayoutIndex === -1;

                let kpiMetricsFilters = {};
                switch (dashboard) {
                    case 'Facility Manager':
                        kpiMetricsFilters = KPI_METRICS_FILTERS.FACILITY_MANAGER;
                        break;
                    case 'Security Operations':
                        kpiMetricsFilters = KPI_METRICS_FILTERS.SECURITY_OPERATIONS;
                        break;
                    case 'Mission Control':
                        kpiMetricsFilters = KPI_METRICS_FILTERS.MISSION_CONTROL;
                        break;
                }

                const fullSectionId = generateUniqueId();
                const halfSectionId = generateUniqueId();

                let sections = [
                    {
                        sectionColumns: FULL_WIDTH,
                        sectionId: fullSectionId,
                        sectionName: 'Metrics',
                        sectionOrder: 0,
                        sectionDirection: 'vertical',
                        sectionWidgets: [
                            {
                                widgetId: generateUniqueId(),
                                widgetOrder: 0,
                                sectionId: fullSectionId,
                                widgetColumns: FULL_WIDTH,
                                widgetType: SECTION_TYPES.KPI_METRICS,
                                filters: kpiMetricsFilters,
                                configurations: '{}',
                            },
                        ],
                    },
                    {
                        sectionColumns: HALF_WIDTH,
                        sectionId: halfSectionId,
                        sectionName: 'Cameras & Events',
                        sectionOrder: 1,
                        sectionDirection: 'horizontal',
                        sectionWidgets: [
                            {
                                widgetId: generateUniqueId(),
                                widgetOrder: 0,
                                sectionId: halfSectionId,
                                widgetColumns: FULL_WIDTH,
                                widgetType: SECTION_TYPES.CAMERAS,
                                filters: '{}',
                                configurations: '{}',
                            },
                        ],
                    },
                ];

                if (dashboard !== 'Mission Control') {
                    const EVENT_TRACKER_WIDGET = {
                        widgetId: generateUniqueId(),
                        widgetOrder: 1,
                        widgetColumns: FULL_WIDTH,
                        widgetType: SECTION_TYPES.EVENTS,
                        sectionId: halfSectionId,
                        filters: '{}',
                        configurations: '{}',
                    };
                    sections[1].sectionWidgets.push(EVENT_TRACKER_WIDGET);
                }

                const payload = {
                    configAction: 'CREATE',
                    isDefault: isDefault,
                    dashboardName: dashboard,
                    sharingType: 'PUBLIC',
                    layoutType: 'THREEBYTHREE',
                    globalFilters: '{}',
                    sections: sections,
                };

                await gqlHelper.saveUnifiedDashboardLayout(payload);
            });
        } catch (error) {
            console.error('error', error);
        }
    };

    const setDefaultUnifiedDashboard = async (value) => {
        const defaultDashboard = dashboardList.filter((dashboard) => dashboard.isDefault === true);

        const updatedDashboard = dashboardList.map((dashboard) => {
            if (dashboard.id === value.id) {
                return { ...dashboard, isDefault: true };
            } else if (dashboard.isDefault) {
                return { ...dashboard, isDefault: false };
            }

            return dashboard;
        });

        setDashboardList(updatedDashboard);

        await getSectionsByDashboard(defaultDashboard[0].id);
        const defaultPayload = {
            configAction: 'UPDATE',
            dashboardId: defaultDashboard[0].id,
            isDefault: false,
            layoutType: defaultDashboard[0].layoutType,
            dashboardName: defaultDashboard[0].name,
            sharingType: defaultDashboard[0].sharingType,
            globalFilters: defaultDashboard[0].globalFilters,
            sections: widgetsToSave,
        };

        await gqlHelper.saveUnifiedDashboardLayout(defaultPayload);

        await getSectionsByDashboard(value.id);
        const payload = {
            configAction: 'UPDATE',
            dashboardId: value.id,
            isDefault: true,
            layoutType: value.layoutType,
            dashboardName: value.name,
            sharingType: value.sharingType,
            globalFilters: value.globalFilters,
            sections: widgetsToSave,
        };

        await gqlHelper.saveUnifiedDashboardLayout(payload);
    };

    const fetchDashboards = async () => {
        abortController.current = new AbortController();

        try {
            const dashboardList = await abortableCall(
                gqlHelper.getUnifiedDashboardLayouts,
                null,
                abortController.current.signal
            );
            const updatedDashboardList = dashboardList.dashboards.map((dashboard) => ({
                id: dashboard.id,
                name: dashboard.dashboardName,
                isDefault: dashboard.isDefault,
                layoutType: dashboard.layoutType,
                sharingType: dashboard.sharingType,
                globalFilters: dashboard.globalFilters,
            }));
            return updatedDashboardList;
        } catch (error) {
            console.error('error', error);
        }
    };

    const updateUnifiedDashboardList = async (defaultDashboards, layoutIndex, totalDashboardsCount) => {
        await createDefaultUnifiedDashboard(defaultDashboards, layoutIndex);

        let unifiedDashboardList = await fetchDashboards();
        while (unifiedDashboardList.length < totalDashboardsCount) {
            await new Promise((resolve) => setTimeout(resolve, 1000));

            unifiedDashboardList = await fetchDashboards();
        }

        const defaultLayoutIndex = unifiedDashboardList.findIndex((layout) => layout.isDefault);
        unifiedDashboardList.unshift(unifiedDashboardList.splice(defaultLayoutIndex, 1)[0]);
        setCurrentDashboardLayout(unifiedDashboardList[0]);
        setSelectedSites(getSelectedSites(unifiedDashboardList[0]));

        return unifiedDashboardList;
    };

    const getUnifiedDashboardLayouts = async () => {
        try {
            if (isMounted.current) {
                let dashboardList = {};
                dashboardList = await fetchDashboards();

                if (isEmpty(dashboardList)) {
                    dashboardList = await updateUnifiedDashboardList(defaultDashboards, -1, 3);
                } else {
                    const missingDashboardNames = defaultDashboards.filter(
                        (name) => !dashboardList.some((dashboard) => dashboard.name === name)
                    );

                    const totalDashboards = missingDashboardNames.length + dashboardList.length;
                    const defaultLayoutIndex = dashboardList.findIndex((layout) => layout.isDefault);
                    if (!isEmpty(missingDashboardNames)) {
                        dashboardList = await updateUnifiedDashboardList(
                            missingDashboardNames,
                            defaultLayoutIndex,
                            totalDashboards
                        );
                        return dashboardList;
                    }

                    dashboardList.unshift(dashboardList.splice(defaultLayoutIndex, 1)[0]);
                    setCurrentDashboardLayout(dashboardList[0]);
                    setSelectedSites(getSelectedSites(dashboardList[0]));
                }
                return dashboardList;
            }
        } catch (error) {
            // doesn't seem to ever throw,
            // since `handleExceptions` from catch of call returns empty by default
            console.error(error);
        }
    };

    const getSectionsByDashboard = async (dashboardId) => {
        abortController.current = new AbortController();

        try {
            const sectionDetails = await abortableCall(
                gqlHelper.getSectionsForUnifiedDashboard,
                dashboardId,
                abortController.current.signal
            );

            if (isEmpty(sectionDetails)) {
                setOriginalWidgetsState([]);
                setWidgetsToSave([]);
                setSelectedMetricTiles([]);
                setTempSelectedMetricTiles([]);
                setCurrentCameraLayout({});
            } else {
                setOriginalWidgetsState(cloneDeep(sectionDetails));
                setWidgetsToSave(cloneDeep(sectionDetails));

                // get all section ids and widget ids and add to setDashboardIds
                const sectionIds = sectionDetails.map((section) => section.sectionId);
                const widgetIds = sectionDetails.flatMap((section) =>
                    section.sectionWidgets.map((widget) => widget.widgetId)
                );
                setDashboardIds(new Set([...sectionIds, ...widgetIds]));

                const cameraLayout = sectionDetails
                    ?.flatMap((section) => section.sectionWidgets)
                    .find((widget) => widget.widgetType === SECTION_TYPES.CAMERAS);

                if (cameraLayout?.filters) {
                    const camLayoutFilters = JSON.parse(cameraLayout?.filters);

                    if (!isEmpty(camLayoutFilters)) {
                        setCurrentCameraLayout(camLayoutFilters);
                    }
                } else {
                    setCurrentCameraLayout({});
                }

                const kpiMetrics = sectionDetails
                    ?.flatMap((section) => section.sectionWidgets)
                    .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

                if (kpiMetrics?.filters && kpiMetrics?.filters !== '{}') {
                    const parsedMetricFilters = JSON.parse(kpiMetrics.filters);

                    setSelectedMetricTiles(parsedMetricFilters);
                    setTempSelectedMetricTiles(parsedMetricFilters);
                } else {
                    setSelectedMetricTiles([]);
                    setTempSelectedMetricTiles([]);
                }
            }
        } catch (error) {
            // doesn't seem to ever throw,
            // since `handleExceptions` from catch of call returns empty by default
            console.error(error);
        }
    };

    const discardSavingDashboard = async () => {
        setWidgetsToSave(cloneDeep(originalWidgetsState));

        const kpiMetrics = originalWidgetsState
            .flatMap((section) => section.sectionWidgets)
            .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

        const cameraFilters = originalWidgetsState
            .flatMap((section) => section.sectionWidgets)
            .find((widget) => widget.widgetType === SECTION_TYPES.CAMERAS);

        if (kpiMetrics?.filters) {
            const metricFilters = JSON.parse(kpiMetrics.filters);

            setSelectedMetricTiles(metricFilters);
            setTempSelectedMetricTiles(metricFilters);
        } else {
            setSelectedMetricTiles([]);
            setTempSelectedMetricTiles([]);
        }

        if (cameraFilters?.filters) {
            const camLayoutFilters = JSON.parse(cameraFilters.filters);

            if (!isEmpty(camLayoutFilters)) {
                setCurrentCameraLayout(camLayoutFilters);
            }
        } else {
            setCurrentCameraLayout({});
        }

        setShowSaveActionBar(false);
    };

    const widgetDrawerItems = useMemo(
        () => [
            {
                name: t('Page.unified-dashboard.widgets.drawer.kpi-metrics'),
                label: t('Page.unified-dashboard.widgets.drawer.kpi-metrics'),
                type: SECTION_TYPES.KPI_METRICS,
                icon: KPIMetricsWidgetIcon,
                drawerContent: (
                    <MyAnalytics
                        selectedSites={selectedSites}
                        setSelectedSites={setSelectedSites}
                        currentDashboardLayout={currentDashboardLayout}
                        drawerClasses={{
                            paper: clsx(isSmallerThan600Width ? classes.smallerDrawerPaper : classes.drawerPaper),
                        }}
                        selectedMetricTiles={selectedMetricTiles}
                        setSelectedMetricTiles={setSelectedMetricTiles}
                        isSubDrawerVariant
                    />
                ),
            },
            {
                name: t('Page.unified-dashboard.widgets.drawer.event-tracker'),
                label: t('Page.unified-dashboard.widgets.drawer.event-tracker'),
                type: SECTION_TYPES.EVENTS,
                icon: EventTrackerWidgetIcon,
            },
            {
                name: t('Page.unified-dashboard.widgets.drawer.camera'),
                label: t('Page.unified-dashboard.widgets.drawer.camera'),
                type: SECTION_TYPES.CAMERAS,
                icon: CameraWidgetIcon,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSubDrawerOpen, selectedMetricTiles, selectedDrawerWidget, isKPIDrawer]
    );

    const filteredDrawerWidgets = useMemo(
        () => widgetDrawerItems.filter((item) => item.name.toLowerCase().includes(widgetSearchInput.toLowerCase())),
        [widgetDrawerItems, widgetSearchInput]
    );

    const updateGlobalFilters = async (val) => {
        setSelectedSites(val);

        const payload = {
            configAction: 'UPDATE',
            dashboardId: currentDashboardLayout.id,
            isDefault: currentDashboardLayout.isDefault,
            layoutType: currentDashboardLayout.layoutType,
            dashboardName: currentDashboardLayout.name,
            sharingType: 'PRIVATE',
            globalFilters: JSON.stringify(val),
            sections: widgetsToSave,
        };

        await gqlHelper.saveUnifiedDashboardLayout(payload);
    };

    const isWidgetTypeUnique = (widgetType) => {
        const allWidgets = widgetsToSave.flatMap((section) => section.sectionWidgets);

        const typeCount = allWidgets.reduce((count, widget) => {
            return count + (widget.widgetType === widgetType ? 1 : 0);
        }, 0);

        return typeCount == 0;
    };

    const handleWidgetDrawerItemClick = (widgetType) => {
        // widgetsToSave is now an array of sections with sectionWidgets
        // we need to ensure that widgets are unique across sections and sectionWidget arrays
        const isUniqueToDashboard = isWidgetTypeUnique(widgetType);
        if (!isUniqueToDashboard && widgetType !== SECTION_TYPES.KPI_METRICS) {
            return;
        }

        const halfWidthSections = widgetsToSave.filter(
            (section) => section.sectionColumns === HALF_WIDTH && section.sectionWidgets.length < 2
        );

        const fullWidthSections = widgetsToSave.filter(
            (section) => section.sectionColumns === FULL_WIDTH && section.sectionWidgets.length < 1
        );

        let firstHalfSection = halfWidthSections.length ? halfWidthSections[0] : null;
        let firstFullSection = fullWidthSections.length ? fullWidthSections[0] : null;

        switch (widgetType) {
            case SECTION_TYPES.CAMERAS:
                let commonCameraWidget = {
                    widgetType: widgetType,
                    filters: currentCameraLayout?.id ? JSON.stringify(currentCameraLayout) : '{}',
                    configurations: '{}',
                    widgetId: generateUniqueId(),
                };

                if (fullWidthSections.length) {
                    firstFullSection.sectionWidgets.push({
                        widgetOrder: firstFullSection.sectionWidgets.length,
                        widgetColumns: FULL_WIDTH,
                        ...commonCameraWidget,
                    });

                    // update the section in the state
                    let tempWidgetsToSave = [...widgetsToSave];
                    let fullWidthSectionIndex = tempWidgetsToSave.findIndex(
                        (section) => section.sectionId === firstFullSection.sectionId
                    );
                    tempWidgetsToSave[fullWidthSectionIndex] = firstFullSection;
                    setWidgetsToSave(tempWidgetsToSave);
                } else {
                    let newSection = {
                        sectionColumns: FULL_WIDTH,
                        sectionId: generateUniqueId(),
                        sectionName: 'New Section',
                        sectionOrder: widgetsToSave.length,
                        sectionDirection: 'vertical',
                        sectionWidgets: [
                            {
                                widgetOrder: 0,
                                widgetColumns: FULL_WIDTH,
                                ...commonCameraWidget,
                            },
                        ],
                    };
                    setWidgetsToSave([...widgetsToSave, newSection]);
                }

                break;
            case SECTION_TYPES.KPI_METRICS:
                setIsSubDrawerOpen(true);
                setSelectedDrawerWidget(widgetType);
                // handleAddKPISubToDashboard should handle some base logic
                break;
            case SECTION_TYPES.EVENTS:
                // if any half width sections exist, add the widget to the first one
                // if any full width sections exist, add the widget to the first one
                // if no sections exist, create a new section with the widget
                let commonWidget = {
                    widgetType: widgetType,
                    filters: '',
                    configurations: '{}',
                    widgetId: generateUniqueId(),
                };

                if (halfWidthSections.length) {
                    firstHalfSection.sectionWidgets.push({
                        widgetOrder: firstHalfSection.sectionWidgets.length,
                        widgetColumns: HALF_WIDTH,
                        ...commonWidget,
                    });

                    // update the section in the state
                    let tempWidgetsToSave = [...widgetsToSave];
                    let halfWidthSectionIndex = tempWidgetsToSave.findIndex(
                        (section) => section.sectionId === firstHalfSection.sectionId
                    );
                    tempWidgetsToSave[halfWidthSectionIndex] = firstHalfSection;
                    setWidgetsToSave(tempWidgetsToSave);
                } else if (fullWidthSections.length) {
                    firstFullSection.sectionWidgets.push({
                        widgetOrder: firstFullSection.sectionWidgets.length,
                        widgetColumns: FULL_WIDTH,
                        ...commonWidget,
                    });

                    // update the section in the state
                    let tempWidgetsToSave = [...widgetsToSave];
                    let fullWidthSectionIndex = tempWidgetsToSave.findIndex(
                        (section) => section.sectionId === firstFullSection.sectionId
                    );
                    tempWidgetsToSave[fullWidthSectionIndex] = firstFullSection;
                    setWidgetsToSave(tempWidgetsToSave);
                } else {
                    let newSection = {
                        sectionColumns: FULL_WIDTH,
                        sectionId: generateUniqueId(),
                        sectionName: 'New Section',
                        sectionOrder: widgetsToSave.length,
                        sectionDirection: 'vertical',
                        sectionWidgets: [
                            {
                                widgetOrder: 0,
                                widgetColumns: FULL_WIDTH,
                                ...commonWidget,
                            },
                        ],
                    };
                    setWidgetsToSave([...widgetsToSave, newSection]);
                }
                break;
        }
    };

    const handleAddKPISubToDashboard = async (e) => {
        e.preventDefault();

        const sectionIndex = widgetsToSave.findIndex((section) =>
            section.sectionWidgets.some((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS)
        );

        let filteredTempWidgetsToSave = [...widgetsToSave];

        if (sectionIndex === -1) {
            let newSectionId = generateUniqueId();

            let newKPIWidget = {
                widgetType: SECTION_TYPES.KPI_METRICS,
                filters: JSON.stringify(selectedMetricTiles),
                configurations: '{}',
                widgetId: generateUniqueId(),
                widgetOrder: 0,
                widgetColumns: FULL_WIDTH,
                sectionId: newSectionId,
            };

            const updatedWidgets = [
                ...widgetsToSave,
                {
                    sectionColumns: FULL_WIDTH,
                    sectionId: newSectionId,
                    sectionName: 'New Section',
                    sectionDirection: 'vertical',
                    sectionOrder: widgetsToSave.length,
                    sectionWidgets: [newKPIWidget],
                },
            ];

            setWidgetsToSave(updatedWidgets);
            filteredTempWidgetsToSave = [...updatedWidgets];
        } else {
            const kpiIndex = widgetsToSave[sectionIndex].sectionWidgets.findIndex(
                (widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS
            );

            const kpiWidgetFromList = widgetsToSave
                .flatMap((section) => section.sectionWidgets)
                .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

            if (isEmpty(selectedMetricTiles) && kpiIndex !== -1) {
                filteredTempWidgetsToSave = filteredTempWidgetsToSave.flatMap((section) =>
                    section.sectionWidgets.filter((widget) => widget.widgetType !== SECTION_TYPES.KPI_METRICS)
                );

                setWidgetsToSave(filteredTempWidgetsToSave);
            }

            if (!isEmpty(selectedMetricTiles)) {
                if (kpiIndex === -1) {
                    let newSectionId = generateUniqueId();

                    let newKPIWidget = {
                        widgetType: SECTION_TYPES.KPI_METRICS,
                        filters: JSON.stringify(selectedMetricTiles),
                        configurations: '{}',
                        widgetId: generateUniqueId(),
                        widgetOrder: 0,
                        widgetColumns: FULL_WIDTH,
                        sectionId: newSectionId,
                    };

                    filteredTempWidgetsToSave = [
                        ...filteredTempWidgetsToSave,
                        {
                            sectionColumns: FULL_WIDTH,
                            sectionId: newSectionId,
                            sectionName: 'New Section',
                            sectionDirection: 'vertical',
                            sectionOrder: widgetsToSave.length,
                            sectionWidgets: [newKPIWidget],
                        },
                    ];

                    setWidgetsToSave(filteredTempWidgetsToSave);
                } else {
                    filteredTempWidgetsToSave[sectionIndex].sectionWidgets[kpiIndex] = {
                        ...kpiWidgetFromList,
                        filters: JSON.stringify(selectedMetricTiles),
                    };
                }
            }
        }

        await handleSaveWidgets(filteredTempWidgetsToSave);
        setTempSelectedMetricTiles(selectedMetricTiles);
    };

    const deSelectWidget = async (title) => {
        if (lastAddedWidgetRef.current === title) {
            lastAddedWidgetRef.current = null;
        }

        let filteredTempWidgetsToSave = [...widgetsToSave];
        let sectionIndex = filteredTempWidgetsToSave.findIndex((section) =>
            section.sectionWidgets.some((widget) => widget.widgetType === title)
        );

        if (sectionIndex !== -1) {
            let widgetIndex = filteredTempWidgetsToSave[sectionIndex].sectionWidgets.findIndex(
                (widget) => widget.widgetType === title
            );

            filteredTempWidgetsToSave[sectionIndex].sectionWidgets.splice(widgetIndex, 1);
            setWidgetsToSave(filteredTempWidgetsToSave);
        }
    };

    const handleSaveCameraLayoutSelection = async (selectedCamLayout = {}) => {
        setCurrentCameraLayout(selectedCamLayout);

        let filteredTempWidgetsToSave = [...widgetsToSave];
        const cameraLayoutIndex = widgetsToSave.findIndex((section) =>
            section.sectionWidgets.some((widget) => widget.widgetType === SECTION_TYPES.CAMERAS)
        );

        filteredTempWidgetsToSave[cameraLayoutIndex].sectionWidgets.find(
            (widget) => widget.widgetType === SECTION_TYPES.CAMERAS
        ).filters = JSON.stringify(selectedCamLayout);

        setWidgetsToSave(filteredTempWidgetsToSave);
    };

    const handleSaveWidgets = async (tempWidgetsToSave = null) => {
        try {
            const payload = {
                configAction: 'UPDATE',
                dashboardId: currentDashboardLayout.id,
                isDefault: currentDashboardLayout.isDefault,
                layoutType: currentDashboardLayout.layoutType,
                sections: tempWidgetsToSave ? tempWidgetsToSave : widgetsToSave,
                dashboardName: currentDashboardLayout.name,
                sharingType: currentDashboardLayout.sharingType,
                globalFilters: currentDashboardLayout.globalFilters,
            };

            // remove empty sections and update the state
            payload.sections = payload.sections.filter((section) => !isEmpty(section.sectionWidgets));
            setWidgetsToSave(payload.sections);

            const savedUnifiedDashboard = await gqlHelper.saveUnifiedDashboardLayout(payload);

            // only KPI widget sub drawer adds to the dashboard & saved together,
            // all other widgets from drawer gets added to state & saved via "Done" click
            const hasKPIMetrics = (tempWidgetsToSave ? [...tempWidgetsToSave] : [...widgetsToSave])
                .flatMap((section) => section.sectionWidgets)
                .some((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

            if (!hasKPIMetrics) {
                setSelectedMetricTiles([]);
                setTempSelectedMetricTiles([]);
            }

            if (savedUnifiedDashboard?.id) {
                notifications.addSuccessMessage({
                    text: t('Page.unified-dashboard.save-widgets.success.message'),
                });
                setIsEditViewEnabled(false);
                setShowSaveActionBar(false);
                setOriginalWidgetsState([...payload.sections]);
            }
        } catch (error) {
            console.error('error', error);
        }
    };

    const disableEditView = () => {
        setIsEditViewEnabled(false);
    };
    const enableEditView = () => {
        setIsEditViewEnabled(true);
    };

    useEffect(() => {
        const isKPIWidgetVisible = widgetsToSave
            ?.flatMap((section) => section.sectionWidgets)
            .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

        if (isKPIWidgetVisible) {
            setTimeLeft(countDownTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDashboardLayout]);

    useEffect(() => {
        const isKPIWidgetVisible = widgetsToSave
            ?.flatMap((section) => section.sectionWidgets)
            .find((widget) => widget.widgetType === SECTION_TYPES.KPI_METRICS);

        if (isKPIWidgetVisible) {
            if (timeLeft <= 0) {
                setRefreshTrigger((prev) => prev + 1);
                return;
            }

            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [timeLeft, widgetsToSave]);

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(countDownTimer);
        }
    }, [timeLeft]);

    const formatTime = useMemo(() => {
        const minutes = Math.floor(timeLeft / 60);
        const remainingSeconds = timeLeft % 60;
        const remainingTime = t('Page.unified-dashboard.widgets.kpi-metrics.header-timer.format-time.text', {
            min: minutes,
            sec: remainingSeconds,
        });
        return remainingTime;
    }, [timeLeft, t]);

    const generateUniqueId = useCallback(() => {
        let newId;
        do {
            newId = uuidv4();
        } while (dashboardIds.has(newId));

        setDashboardIds((prevIdSet) => new Set(prevIdSet).add(newId));
        return newId;
    }, [dashboardIds]);

    const getSection = useCallback(
        (widgetType, widgetFilters, index, parentWidth = FULL_WIDTH, isEditViewEnabled) => {
            const commonProps = {
                index,
                key: `${currentDashboardLayout?.name}-${widgetType}`,
                drawerOpen,
                isEditViewEnabled,
                deSelectWidget,
            };

            if (widgetType === SECTION_TYPES.CAMERAS) {
                return (
                    <DashboardWidget
                        {...commonProps}
                        title={t('Page.recorded-video.cameras')}
                        id={t('Page.recorded-video.cameras')}
                        settingMenuItems={[
                            {
                                handleClick: () => setShowCameraLayoutModal(true),
                                text: t('Page.unified-dashboard.widgets.menu.settings'),
                                id: 0, //this will come dynamically in the future
                            },
                        ]}
                        hasInnerScroll={true}
                        cameraContainerHeight={cameraContainerHeight}
                        children={
                            <CamerasLayout
                                selectedSites={selectedSites}
                                clearSitesFilter={clearSitesFilter}
                                showCameraLayoutModal={showCameraLayoutModal}
                                setShowCameraLayoutModal={setShowCameraLayoutModal}
                                currentCameraLayout={currentCameraLayout}
                                setCurrentCameraLayout={setCurrentCameraLayout}
                                sites={sites}
                                handleSaveCameraLayoutSelection={handleSaveCameraLayoutSelection}
                            />
                        }
                    />
                );
            } else if (widgetType === SECTION_TYPES.KPI_METRICS && widgetFilters !== '{}') {
                return (
                    <DashboardWidget
                        {...commonProps}
                        title={t('Page.unified-dashboard.widgets.kpi-metrics.title')}
                        id={t('Page.unified-dashboard.widgets.kpi-metrics.title')}
                        titleDescription={
                            <Typography variant="caption" className={classes.widgetTitleDescription}>
                                {t('Page.unified-dashboard.widgets.kpi-metrics.header-timer.text', {
                                    formatTime: formatTime,
                                })}
                            </Typography>
                        }
                        settingMenuItems={[
                            {
                                handleClick: () => console.log('menu clicked'),
                                text: t('Page.brivo-analytics.drilldown-table.menu-option.view-drilldown'),
                                id: 0, //this will come dynamically in the future
                            },
                        ]}
                        children={
                            <MyAnalytics
                                selectedSites={selectedSites}
                                setSelectedSites={setSelectedSites}
                                currentDashboardLayout={currentDashboardLayout}
                                parentWidth={parentWidth}
                                selectedMetricTiles={tempSelectedMetricTiles}
                                setSelectedMetricTiles={setSelectedMetricTiles}
                                refreshTrigger={refreshTrigger}
                            />
                        }
                    />
                );
            } else if (widgetType === SECTION_TYPES.EVENTS) {
                return (
                    <DashboardWidget
                        {...commonProps}
                        title={t('Page.unified-dashboard.widgets.event-tracker.title')}
                        titleDescription={
                            drawerOpen ? (
                                <Typography variant="caption" className={classes.widgetTitleDescription}>
                                    ({t('Page.unified-dashboard.widgets.event-tracker.table.video.disabled')})
                                </Typography>
                            ) : null
                        }
                        id={t('Page.unified-dashboard.widgets.event-tracker.title')}
                        moreWidgetSettings={
                            <IconButton
                                onClick={handleEventFilterDrawerToggle}
                                icon={<FilterIcon />}
                                size="large"
                                id="eventTracker-filter-btn"
                            />
                        }
                        settingMenuItems={[
                            {
                                handleClick: () => console.log('menu clicked'),
                                text: t('Page.brivo-analytics.drilldown-table.menu-option.view-drilldown'),
                                id: 0, //this will come dynamically in the future
                            },
                        ]}
                        hasInnerScroll={true}
                        cameraContainerHeight={cameraContainerHeight}
                        children={
                            // using `Grid container` wrapper here messes with styling
                            <EventTrackerContainer
                                selectedSites={selectedSites}
                                sites={sites}
                                isWidgetDrawerOpen={drawerOpen}
                                handleMainDrawerClose={handleMainDrawerClose}
                                isEventFilterDrawerOpen={isEventFilterDrawerOpen}
                                handleEventFilterDrawerToggle={handleEventFilterDrawerToggle}
                                filterDrawerTitle={t('Page.unified-dashboard.widgets.event-tracker.filter.title')}
                            />
                        }
                    />
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isEventFilterDrawerOpen,
            sites,
            tempSelectedMetricTiles,
            selectedSites,
            drawerOpen,
            currentCameraLayout,
            showCameraLayoutModal,
            formatTime,
        ]
    );

    useEffect(() => {
        if (!isEmpty(widgetsToSave) && originalWidgetsState.length !== widgetsToSave.length) {
            const latestSection = widgetsToSave[widgetsToSave.length - 1];
            const latestWidget = latestSection.sectionWidgets[latestSection.sectionWidgets.length - 1];
            const latestWidgetType = `dashboard-${latestWidget?.widgetType}`;

            // adding a new section, doesn't have any widgets
            if (!latestWidget || isEditViewEnabled) {
                return;
            }

            const latestWidgetElement = document.querySelector(`[id="${latestWidgetType}"]`);
            if (latestWidgetType !== lastAddedWidgetRef.current && latestWidgetElement) {
                lastAddedWidgetRef.current = latestWidgetType;

                const scrollToWidget = () => {
                    latestWidgetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                };
                scrollToWidget();

                const resizeObserver = new ResizeObserver(() => {
                    scrollToWidget();
                });

                resizeObserver.observe(latestWidgetElement);

                return () => {
                    resizeObserver.disconnect();
                    lastAddedWidgetRef.current = null;
                };
            }
        }
    }, [widgetsToSave, originalWidgetsState, currentDashboardLayout, isEditViewEnabled]);

    const handleEventFilterDrawerToggle = () => {
        if (isEventFilterDrawerOpen) {
            setIsEventFilterDrawerOpen(false);
        } else {
            handleMainDrawerClose(true);

            setIsEventFilterDrawerOpen(true);
        }
    };

    const selectedDrawerWidgetContent = selectedDrawerWidget
        ? widgetDrawerItems.find(({ type }) => type === selectedDrawerWidget)
        : null;

    const initialWidgetsChanged = useMemo(
        () => !isEqual(originalWidgetsState, widgetsToSave),
        [originalWidgetsState, widgetsToSave]
    );

    return (
        <>
            {/* Header Row */}
            <Grid container direction="row" className={classes.buttonHeader}>
                {!isEmpty(dashboardList) && (
                    <>
                        <Grid className={classes.leftSideHeader}>
                            <DropDownPageMenu
                                menuOptions={dashboardList}
                                buttonText={t('Page.unified-dashboard.create-dashboard.button.text')}
                                closeMenu={handleDashboardModalOpen}
                                handleIconClick={setDefaultUnifiedDashboard}
                                enableDividerButton
                                handleOptionClicked={handleLayoutSelected}
                                optionSelected={currentDashboardLayout}
                                starIconTooltipText={t('Page.unified-dashboard.select-dashboard.favorite-icon.tooltip')}
                            />

                            <Tooltip
                                className={classes.titleTooltip}
                                title={
                                    currentDashboardLayout.sharingType === 'PRIVATE'
                                        ? t('Page.unified-video.private-view.tooltip')
                                        : t('Page.unified-video.public-view.tooltip')
                                }
                            >
                                <Grid>
                                    <StatusChip
                                        size="small"
                                        label={
                                            currentDashboardLayout.sharingType === 'PRIVATE'
                                                ? t('Page.unified-video.private-view')
                                                : t('Page.unified-video.public-view')
                                        }
                                        type="success"
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid item className={classes.siteFilterContainer}>
                            <MultiSelectFilter
                                key={currentDashboardLayout.id}
                                id="sites"
                                label={t('Page.live-video.dialog.filters.sites.label')}
                                searchPlaceholder={t('Page.live-video.dialog.filters.sites.search-placeholder')}
                                noSelectionLabel={t('Page.live-video.dialog.filters.sites.no-selection')}
                                fullWidth
                                items={sites}
                                onValueChange={(val) => {
                                    updateGlobalFilters(val);
                                }}
                                value={selectedSites}
                                mapper={(item) => ({ id: item.id, name: item.siteName })}
                                displayMenuArrow={false}
                                limit={1}
                                clearingValues={clearingValues}
                                setClearingValues={setClearingValues}
                            />
                        </Grid>
                        <Grid item className={classes.createNewDashboardButton}>
                            <Button
                                onClick={() => setCreateDashboardModalOpen(true)}
                                text={t('Page.unified-dashboard.create-new.button.text')}
                            />
                        </Grid>
                    </>
                )}

                {hasWidgetsToSave && (
                    <Grid item xs={12} className={classes.widgetAddButton}>
                        <div className={classes.widgetEditButton}>
                            {isEditViewEnabled ? (
                                <Button
                                    type="secondary"
                                    id="secondary-btn"
                                    text={t('Page.unified-dashboard.edit-view-discard.button.text')}
                                    onClick={disableEditView}
                                    customWidth="120px"
                                />
                            ) : (
                                <Button
                                    type="secondary"
                                    id="secondary-btn"
                                    text={t('Page.unified-dashboard.edit-view.button.text')}
                                    onClick={enableEditView}
                                    customWidth="120px"
                                />
                            )}
                        </div>
                        <Button onClick={toggleSidebar} text={t('Page.unified-dashboard.add-widgets.button.text')} />
                    </Grid>
                )}
            </Grid>

            {/* Loading and Widgets */}
            {isLoading ? (
                <Grid className={classes.loadingIndicator}>
                    <LoadingIndicator />
                </Grid>
            ) : !hasWidgetsToSave ? (
                <Grid className={classes.emptyStateSection}>
                    <EmptyStateSection
                        title={t('Page.unified-dashboard.add-widgets.empty-state.title')}
                        subtitle={t('Page.unified-dashboard.add-widgets.empty-state.desc')}
                        buttonText={t('Page.unified-dashboard.add-widgets.button.text')}
                        onButtonClick={toggleSidebar}
                        showButton={true}
                        icon={<NoResultsListIconUpdated style={{ height: 140, width: 140 }} />}
                    />
                </Grid>
            ) : (
                <DashboardLayout
                    sections={widgetsToSave}
                    isEditViewEnabled={isEditViewEnabled}
                    getSection={getSection}
                    disableEditView={disableEditView}
                    enableEditView={enableEditView}
                    setIsEditViewEnabled={setIsEditViewEnabled}
                    toggleSidebar={toggleSidebar}
                    setWidgetsToSave={setWidgetsToSave}
                    setShowSaveActionBar={setShowSaveActionBar}
                />
            )}

            <Drawer
                onClose={handleMainDrawerClose}
                variant="persistent"
                anchor="right"
                open={drawerOpen || (drawerOpen && isSubDrawerOpen)}
                classes={{
                    paper: clsx(isSmallerThan600Width ? classes.smallerDrawerPaper : classes.drawerPaper),
                }}
                transitionDuration={{ enter: 500, exit: 225 }}
            >
                <Grid container>
                    <Grid item container xs={12} className={classes.drawerPadding} spacing={1}>
                        <div className={classes.drawerHeadingTitle}>
                            <Typography variant="h6" data-testid="widgets-drawer-header">
                                {t('Page.unified-dashboard.widgets.drawer.title')}
                            </Typography>
                        </div>
                        <div className={classes.drawerHeadingButton}>
                            <IconButton onClick={() => handleMainDrawerClose(true)} icon={<CloseIcon />} size="small" />
                        </div>
                    </Grid>
                </Grid>

                <Divider />

                <Grid item container className={classes.drawerWithScroll}>
                    {!isSubDrawerOpen && (
                        <Grid item xs={12} className={classes.drawerSearch}>
                            <TextFilter
                                placeholder={t('Page.unified-dashboard.widgets.drawer.search-placeholder')}
                                value={widgetSearchInput}
                                onValueChange={setWidgetSearchInput}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        className={clsx(classes.drawerWidgetContainer, {
                            [classes.subDrawerContainer]: isSubDrawerOpen && !!selectedDrawerWidgetContent,
                        })}
                    >
                        {selectedDrawerWidgetContent
                            ? selectedDrawerWidgetContent.drawerContent
                            : filteredDrawerWidgets.map((widget, index) => (
                                  <Grid item xs={6} className={classes.drawerWidget} key={`${widget.type}-${index}`}>
                                      <IconButton
                                          className={classes.drawerWidgetIcon}
                                          icon={widget.icon}
                                          onClick={() => handleWidgetDrawerItemClick(widget.type)}
                                          type="secondary"
                                          id={widget.type}
                                      />
                                      <Typography variant="body2" className={classes.drawerWidgetLabel}>
                                          {widget.label}
                                      </Typography>
                                  </Grid>
                              ))}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    className={clsx(classes.drawerPadding, classes.mainDrawerActionsContainer)}
                >
                    <Grid item className={classes.mainDrawerCancelButton}>
                        <Button
                            type="tertiary"
                            text={
                                isKPIDrawer
                                    ? t('Page.unified-dashboard.widgets.kpi-metrics.drawer.back')
                                    : t('Page.unified-dashboard.widgets.drawer.cancel')
                            }
                            onClick={() => handleMainDrawerClose(isSubDrawerOpen ? false : true)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            type="primary"
                            id="add-to-dashboard"
                            disabled={isKPIDrawer && isEmpty(selectedMetricTiles)}
                            text={
                                isKPIDrawer
                                    ? t('Page.unified-dashboard.widgets.kpi-metrics.drawer.add')
                                    : t('Page.unified-dashboard.widgets.drawer.done')
                            }
                            onClick={(e) =>
                                isSubDrawerOpen && isKPIDrawer ? handleAddKPISubToDashboard(e) : handleMainDrawerClose()
                            }
                        />
                    </Grid>
                </Grid>
            </Drawer>

            {currentDashboardLayout && (
                <CreateDashboardModal
                    isOpen={createDashboardModalOpen}
                    onClose={(values) => handleDashboardModalClose(values)}
                    layoutType={currentDashboardLayout.layoutType}
                    globalFilters={selectedSites}
                    dashboards={dashboardList}
                    dashboardCreated={(
                        configAction,
                        isDefault,
                        dashboardName,
                        sharingType,
                        layoutType,
                        globalFilters
                    ) =>
                        createUnifiedDashboard(
                            configAction,
                            isDefault,
                            dashboardName,
                            sharingType,
                            layoutType,
                            globalFilters
                        )
                    }
                />
            )}

            <SaveActionBar
                show={(!drawerOpen && showSaveActionBar) || initialWidgetsChanged}
                showPrimaryIcon
                primaryActionText={t('Page.unified-video.edit.custom-layout.save-bar.save')}
                primaryActionClickHandler={(e) => {
                    e.preventDefault();
                    // `handleSaveWidgets` called with either temp state or no params
                    handleSaveWidgets();
                }}
                secondaryActionText={t('Page.unified-video.edit.custom-layout.save-bar.discard')}
                secondaryActionClickHandler={discardSavingDashboard}
            />
        </>
    );
};

export default withApi(UnifiedDashboardPage, ApiHelper);
