import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, testLanguage, es, fr, nl, de, sv } from './locale/';

import enCentralTranslations from '@brivo/security-actions-intl/EventDetails.json';
import esCentralTranslations from '@brivo/security-actions-intl/EventDetails_es_ES.json';
import frCentralTranslations from '@brivo/security-actions-intl/EventDetails_fr_CA.json';
import nlCentralTranslations from '@brivo/security-actions-intl/EventDetails_nl_NL.json';
import deCentralTranslations from '@brivo/security-actions-intl/EventDetails_de_DE.json';
import svCentralTranslations from '@brivo/security-actions-intl/EventDetails_sv_SE.json';

export const resources = {
    en: {
        translations: en,
        centralTranslations: enCentralTranslations,
    },
    testLanguage: {
        translations: testLanguage,
    },
    es: {
        translations: es,
        centralTranslations: esCentralTranslations,
    },
    'fr-CA': {
        translations: fr,
        centralTranslations: frCentralTranslations,
    },
    nl: {
        translations: nl,
        centralTranslations: nlCentralTranslations,
    },
    de: {
        translations: de,
        centralTranslations: deCentralTranslations,
    },
    sv: {
        translations: sv,
        centralTranslations: svCentralTranslations,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['navigator'],
        },
        resources,
        fallbackLng: 'en',
        //debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        react: {
            wait: true,
        },
    });

export default i18n;
