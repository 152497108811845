import { API_BASE_URL } from '@common/constants/Constants';
import { restClient } from '../RestClient';
import { WebReqApi } from '../WebReqApi';
import { GET_SCHEDULES_FOR_JOURNAL } from './gqlTags';

class SchedulesApi extends WebReqApi {
    getPagedSchedules(offset, pageSize, name, site) {
        const queryParams = { offset, pageSize };

        const filterParams = [];
        if (name) {
            filterParams.push(`name__eq:${name}`);
        }

        if (site) {
            filterParams.push(`site__eq:${site.label}`);
        }

        if (filterParams.length > 0) {
            queryParams.filter = filterParams.join(';');
        }

        return restClient.makeRequest({
            method: 'GET',
            url: `${API_BASE_URL}/schedules`,
            queryParams,
        });
    }

    async getAllSchedules(filters) {
        if (filters) {
            return this.fetchAllData(this.getPagedSchedules, [filters.name, filters.site]);
        } else {
            return this.fetchAllData(this.getPagedSchedules);
        }
    }

    createSchedule(params) {
        return this.makeRestRequest({
            method: 'POST',
            url: `${API_BASE_URL}/schedules`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            body: params,
        });
    }

    updateSchedule(id, params) {
        return this.makeRestRequest({
            method: 'PUT',
            url: `${API_BASE_URL}/schedules/${id}`,
            additionalHeaders: {
                'Cache-Control': 'no-store',
            },
            body: params,
        });
    }

    viewSchedule(id) {
        return this.makeRestRequest({
            method: 'GET',
            url: `${API_BASE_URL}/schedules/${id}`,
        });
    }

    deleteSchedule(id) {
        return this.makeRestRequest({
            method: 'DELETE',
            url: `${API_BASE_URL}/schedules/${id}`,
        });
    }

    fetchAllData = async (api, params) => {
        let data = [];
        let offset = 0;
        const pageSize = 100;
        let keepFetching = true;
        while (keepFetching) {
            const res = params ? await api(offset, pageSize, ...params) : await api(offset, pageSize);
            if (res.data.length === 0) {
                keepFetching = false;
            } else {
                data = [...data, ...res.data];
                offset += pageSize;
                if (data.length === res.count) {
                    keepFetching = false;
                }
            }
        }
        return data;
    };

    getSchedulesForJournal({ name = '', rows = 10 }) {
        return this.makeGqlQuery({
            gqlTag: GET_SCHEDULES_FOR_JOURNAL,
            variables: {
                name,
                rows,
            },
            defaultValue: [],
        });
    }
}

export const schedulesApi = new SchedulesApi();
