import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    newFeatureOuterDiv: {
        marginLeft: 'auto',
        alignItems: 'center !important',
        display: 'inline-flex',
    },
    dot: {
        width: '0.25rem',
        height: '0.25rem',
        borderRadius: '50%',
        boxShadow: '0 0 0 0.1875rem rgba(91, 150, 213, 0.25)',
        backgroundColor: theme.palette.brivoAliases.newNavNewIndicatorColor,

        marginRight: '0.375rem',
        marginLeft: 'auto',
    },
    text: {
        color: theme.palette.brivoAliases.newNavNewIndicatorColor,
        fontSize: '0.75rem',
        fontWeight: '600',
    },
}));
export default styles;
