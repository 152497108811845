import * as types from '../types/Api.types';

export const apiRequest = (body, method, url, token, entity) => ({
    type: `${entity} ${types.API_REQUEST}`,
    payload: {
        body: body,
        meta: {
            method,
            url,
            token,
            entity,
        },
    },
});

export const apiError = (error, entity) => ({
    type: `${entity} ${types.API_ERROR}`,
    payload: {
        body: error,
        meta: {
            entity,
        },
    },
});

export const apiSuccess = (response, entity) => ({
    type: `${entity} ${types.API_SUCCESS}`,
    payload: {
        body: response,
        meta: {
            entity,
        },
    },
});
