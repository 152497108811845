import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import { NavLogo } from '../../NewNavigationUtils';
import clsx from 'clsx';
import styles from './styles';

const NavigationTopSection = ({ logoUrl }) => {
    const classes = styles();
    return (
        <section className={clsx('NavigationTopSection', classes.section)}>
            {logoUrl ? (
                <img
                    data-testid="brand-logo"
                    src={logoUrl}
                    alt="brand-logo"
                    className={clsx(classes.brandLogo, 'u-mb-half')}
                />
            ) : (
                <NavLogo />
            )}
            <div className="u-fx u-fx-center u-fx-justify-center">
                <span className={classes.appName}>Brivo Access</span>
                <LanguageSwitcher />
            </div>
        </section>
    );
};

export default NavigationTopSection;
