import { useCallback, useContext, useMemo } from 'react';
import { UserContext } from '../user/UserProvider';

export function useAssignments() {
    const user = useContext(UserContext);

    const siteAssignments = user?.assignments?.sites;
    const assignedSites = siteAssignments?.assignments;
    const isAssignedToAllSites = siteAssignments?.isAll;

    const groupAssignments = user?.assignments?.groups;
    const assignedGroups = groupAssignments?.assignments;
    const isAssignedToAllGroups = groupAssignments?.isAll;

    const assignedSiteIds = useMemo(() => (assignedSites ? assignedSites.map((a) => a.site.id) : []), [assignedSites]);
    const assignedGroupIds = useMemo(
        () => (assignedGroups ? assignedGroups.map((g) => g.group.id) : []),
        [assignedGroups]
    );

    const hasSiteAssignments = isAssignedToAllSites || assignedSiteIds.length > 0;
    const hasGroupAssignments = isAssignedToAllGroups || assignedGroupIds.length > 0;

    const lockdownAssignments = user.assignments?.lockdowns;
    const isAssignedToAllLockdowns = lockdownAssignments?.isAll;

    const isAssignedToSite = useCallback(
        (siteId) => {
            if (typeof siteId !== 'number') {
                throw new TypeError(`Invalid argument. Expected "number", received "${typeof siteId}"`);
            }

            if (isAssignedToAllSites) return true;

            return assignedSiteIds.includes(siteId);
        },
        [assignedSiteIds, isAssignedToAllSites]
    );

    return {
        isAssignedToSite,
        isAssignedToAllSites,
        isAssignedToAllGroups,
        hasSiteAssignments,
        hasGroupAssignments,
        isAssignedToAllLockdowns,
    };
}
