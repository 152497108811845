import React, { Suspense, lazy } from 'react';
import { LoadingIndicator } from '@brivo/react-components';

const VisitorsListingPage = lazy(() => import('./VisitorsListingPage/VisitorsListingPage'));

const VistorsPageContainer = () => {
    return (
        <>
            <Suspense fallback={<LoadingIndicator />}>
                <VisitorsListingPage />
            </Suspense>
        </>
    );
};

export default VistorsPageContainer;
