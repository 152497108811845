import React, { lazy, Suspense } from 'react';

const PrivacyPolicyPage = lazy(() => import('./PrivacyPolicyPage'));

const PrivacyPolicyContainer = (props) => (
    <Suspense fallback={null}>
        <PrivacyPolicyPage {...props} />
    </Suspense>
);

export default PrivacyPolicyContainer;
