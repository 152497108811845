import { isEmpty } from 'lodash';

import { REQUEST_OPTIONS } from '../constants/Constants';
import { auth } from '../utils/Auth';

export function createUrl(urlTemplate, urlParams, queryParams) {
    let url;
    url = insertUrlParams(urlTemplate, urlParams);

    if (!isEmpty(queryParams)) {
        url += '?' + new URLSearchParams(queryParams).toString();
    }

    return url;
}

// TODO -> check for all existing url params in the string
// and make sure a value for them exists in urlParams?
// Would throw a "missing url param" exeption
function insertUrlParams(url = '', urlParams = {}) {
    let urlWithParams = url;
    const paramTuples = Object.entries(urlParams);

    if (paramTuples.length > 0) {
        paramTuples.forEach(([param, val]) => {
            const regex = new RegExp(`/:${param}`);

            const isValidParam = regex.test(urlWithParams);
            if (!isValidParam) throw new Error(`Invalid url param found "${param}" while matching url "${url}".`);

            urlWithParams = urlWithParams.replace(`/:${param}`, `/${val}`);
        });
    }

    return urlWithParams;
}

export function redirect(url) {
    window.location.replace(url);
}

export async function readResponseBody(response) {
    if (isJson(response)) {
        try {
            return await response.json();
        } catch (e) {
            console.warn('Response content type is "application/json", but data is invalid json.');
            console.warn(e);
        }
    } else {
        try {
            return await response.text();
        } catch (e) {
            console.warn('Unable to parse response text data.');
            console.warn(e);
        }
    }

    return null;
}

export function isJson(response) {
    return response.headers.get('content-type').includes('application/json');
}

const mapGroupToCommand = (item) => ({
    type: 'ADD',
    groupId: item.id,
    groupName: item.name,
});

export function mapControlLockCommands(groups) {
    return groups.map((group) => mapGroupToCommand(group));
}

export const getRequestDetails = async ({
    method = 'GET',
    body = null,
    additionalHeaders = {},
    additionalOptions = {},
}) => {
    const token = await auth.getAccessToken();

    const requestDetails = {
        options: {
            ...REQUEST_OPTIONS,
            method,
            headers: {
                ...REQUEST_OPTIONS.headers,
                Authorization: `Bearer ${token}`,
                ...additionalHeaders,
            },
            ...additionalOptions,
            body: body ? JSON.stringify(body) : null,
        },
    };

    return requestDetails;
};
