import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import { ArrowBack, Button, AddItemIcon, EmptyListIcon } from '@brivo/react-components';
import { Paper, Typography, makeStyles } from '@brivo/react-components';
import clsx from 'clsx';
import { useFlagClient } from '@brivo/onairplus-services';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '500px',
    },

    noResultsListIcon: {
        height: '80px',
        marginBottom: '12px',
    },

    addItem: {
        '& svg': {
            fill: theme.palette.colors.blueAlt600,
        },
        marginTop: '13px',
    },
    title: {
        marginBottom: '1px',
    },
    explanation: {
        color: `${theme.palette.type === 'light' ? theme.palette.colors.grey800 : theme.palette.colors.grey300}`,
    },
}));

const LockdownNotFound = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');
    const classes = useStyles();

    const handleGoBack = () => {
        if (emergencyScenariosFlag) {
            history.push(`/scenarios`);
        } else {
            history.push(`/lockdown-scenarios`);
        }
    };

    const onCreateScenarioClick = () => {
        if (emergencyScenariosFlag) {
            history.push(`/scenarios/create-scenario`);
        } else {
            history.push(`/lockdown-scenarios/create-lockdown-scenario`);
        }
    };

    return (
        <>
            <div className="u-mb-half u-pt-full u-pb-full">
                <Button
                    id="back-to-lockdown-scenarios"
                    size="small"
                    type="tertiary"
                    onClick={handleGoBack}
                    startIcon={<ArrowBack />}
                    text={t('Page.lockdown-scenarios.lockdown-scenario-details.page.back')}
                />
            </div>
            <PageHeader
                title={
                    emergencyScenariosFlag
                        ? t('Page.emergency-scenarios.emergency-scenario-details.page.title')
                        : t('Page.lockdown-scenarios.lockdown-scenario-details.page.title')
                }
            />
            <Paper className={clsx(classes.wrapper, 'u-fx u-fx-align-center u-fx-justify-center')}>
                <div className="u-pa-double u-center">
                    <EmptyListIcon className={classes.noResultsListIcon} />
                    <Typography className={classes.title} variant="subtitle1">
                        {emergencyScenariosFlag
                            ? t('Page.emergency-scenario-not-found.title')
                            : t('Page.lockdown-scenario-not-found.title')}
                    </Typography>
                    <Typography className={classes.explanation} variant="caption">
                        {emergencyScenariosFlag
                            ? t('Page.emergency-scenario-not-found.explanation')
                            : t('Page.lockdown-scenario-not-found.explanation')}
                    </Typography>
                    <div className={classes.addItem}>
                        <Button
                            id="create_lockdown_scenario"
                            type="secondary"
                            size="small"
                            onClick={onCreateScenarioClick}
                            startIcon={<AddItemIcon />}
                            text={
                                emergencyScenariosFlag
                                    ? t('Page.emergency-scenario-not-found.button')
                                    : t('Page.lockdown-scenario-not-found.button')
                            }
                        />
                    </div>
                </div>
            </Paper>
        </>
    );
};

export default LockdownNotFound;
