import { useEffect, useState, useRef, memo, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles, LoadingIndicator } from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';
import ApiHelper from '../../../../common/helpers/Helpers';
import './index.css';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        aspectRatio: '16 / 9',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.palette.colors.black,
        display: 'flex',
        justifyContent: 'center',
    },
    posterImage: {
        width: '100%',
        height: 'auto',
        transition: 'all',
        zIndex: (props) => (!props.hdStream || !props.previewStream ? 0 : -1),
        opacity: (props) => (props.poster && !props.fetchingExternalClip && !props.recordedVideoSrc ? 1 : 0),
        position: 'absolute',
        left: 0,
        top: 0,
    },
    previewStream: {
        transition: 'all',
        opacity: (props) =>
            props.showPreviewStream && !props.showLoadingIndicator && !props.showNoVideoAvailable && props.previewStream
                ? 1
                : 0,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    hdStreamContainer: {
        width: '100%',
        height: '100%',
        transition: 'all',
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: (props) => (props.fetchingExternalClip || !props.hdStream ? 0 : 1),
    },
    videoNotAvailable: {
        color: theme.palette.colors.grey0, // white
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
    },
    liveStatusIndicator: {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(2),
        zIndex: 11,
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.colors.green400,
    },
}));

function VideoPlayer({
    cameraData,
    isPreview = false,
    api,
    forceStaticImage = false,
    streamUrls,
    clipStatus,
    removeSelectedClip,
}) {
    const [previewUrl, setPreviewUrl] = useState(null);
    const [hdUrl, setHdUrl] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [posterBase64, setPosterBase64] = useState(null);
    const abortController = useRef({ current: null });
    const [loaded, setLoaded] = useState({ poster: null, previewStream: null, hdStream: null, cookie: null });
    const [inViewport, setInViewport] = useState(false);
    const [rerenderKey, setRerenderKey] = useState(0);
    const [attemptHdRefresh, setAttemptHdRefresh] = useState(true);
    const { t } = useTranslation();

    const fetchingExternalClip = !clipStatus?.fetchingExternalClip ? false : clipStatus?.fetchingExternalClip;
    const isLive = !clipStatus?.selectedClip?.src ? true : false;
    const recordedVideoSrc = clipStatus?.selectedClip?.src;
    const { poster, previewStream, hdStream } = loaded;

    const showLoadingIndicator =
        (fetchingExternalClip || (!poster && !previewStream && hdStream !== false)) && isEmpty(streamUrls) !== true;
    const showPosterImage =
        !fetchingExternalClip || isLive || (forceStaticImage && poster && !previewStream && !hdStream);
    const showPreviewStream =
        isLive && previewUrl && !hdStream && !forceStaticImage && !fetchingExternalClip && inViewport;
    const showHdStream =
        !isPreview &&
        !fetchingExternalClip &&
        ((isLive && hdUrl?.length > 0) || (!isLive && recordedVideoSrc?.length > 0));
    const showNoVideoAvailable =
        (poster === false && ((previewStream === false && isPreview) || (hdStream === false && !isPreview))) ||
        isEmpty(streamUrls);

    const classes = useStyles({
        fetchingExternalClip,
        hdStream,
        previewStream,
        poster,
        recordedVideoSrc,
        showPreviewStream,
        showLoadingIndicator,
        showNoVideoAvailable,
    });

    useEffect(() => {
        return () => {
            if (!isPreview) {
                // cleanup state when hd video stream is done running.
                setPreviewUrl(null);
                setHdUrl(null);
                setLoaded({ poster: null, previewStream: null, hdStream: null, cookie: null });
                setAuthToken(null);
                setPosterBase64(null);
                removeSelectedClip(); // don't add this to the dependency array, we only want to run the cleanup when a hd video is closed.
                setAttemptHdRefresh(true);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadingThumbnail = async () => {
        // `posterBase64` can get set to `false` elsewhere
        if (posterBase64 === null && isEmpty(streamUrls) !== true) {
            try {
                const thumbnail = await getThumbnail(cameraData.id);

                setPosterBase64(thumbnail);
                updateLoaded({ poster: !thumbnail ? false : true });

                return;
            } catch (error) {
                console.error('error', error);

                updateLoaded({ poster: false });

                return;
            }
        }
    };

    const updateLoaded = useCallback(
        (newLoaded) => {
            setLoaded((prev) => ({ ...prev, ...newLoaded }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loaded]
    );

    useEffect(() => {
        // if `thumbnail` call fails, recall when back in viewport
        handleLoadingThumbnail();

        return () => {
            // `.abort` might not be defined through UnifiedDashboard's CamerasLayout
            abortController.current?.abort && abortController.current?.abort();
        }; // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraData?.id]);

    const getThumbnail = async (cameraId) => {
        abortController.current = new AbortController();

        try {
            const thumbnail = await api.getCameraThumbnail(cameraId, abortController.current?.signal);

            if (isEmpty(thumbnail)) {
                return false;
            }

            return thumbnail;
        } catch (error) {
            console.error(error);

            throw new Error(error);
        }
    };

    useEffect(() => {
        const { previewStreamUrl, hdStreamUrl, authToken = null } = streamUrls;
        setPreviewUrl(previewStreamUrl);
        setHdUrl(hdStreamUrl);
        if (authToken) {
            setAuthToken(authToken);
        }
    }, [streamUrls]);

    useEffect(() => {
        let timer;
        if (showHdStream && attemptHdRefresh) {
            timer = setTimeout(() => {
                setRerenderKey(rerenderKey + 1);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [showHdStream, rerenderKey, attemptHdRefresh]);

    const getVideoPlayerConfig = () =>
        isLive
            ? {
                  file: {
                      forceHLS: true,
                      hlsOptions: {
                          debug: false,
                          xhrSetup: function (xhr) {
                              xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
                          },
                      },
                  },
              }
            : {};

    const getNoVideoAvailableMessage = () => {
        if (cameraData.status === 'DISCONNECTED') {
            return t('Page.unified-video.video-player.messages.video-disconnected');
        }

        return t('Page.unified-video.video-player.messages.video-not-available');
    };

    return (
        <div className={classes.videoContainer}>
            <Waypoint onEnter={() => setInViewport(true)} onLeave={() => setInViewport(false)} />

            {showLoadingIndicator && <LoadingIndicator />}

            {showNoVideoAvailable && (
                <div className={classes.videoNotAvailable}>
                    <p>{getNoVideoAvailableMessage()}</p>
                </div>
            )}

            {showPosterImage && (
                <img className={classes.posterImage} src={`data:image/jpeg;base64,${posterBase64}`} alt="Poster" />
            )}

            {isLive && !isPreview && !showLoadingIndicator && (
                <div className={classes.liveStatusIndicator}>
                    <div className="status-dot" />
                    {loaded.hdStream
                        ? t('Page.unified-video.video-modal.status-indicator.live-hd-video')
                        : t('Page.unified-video.video-modal.status-indicator.live-video')}
                </div>
            )}

            {showPreviewStream && (
                <img
                    className={classes.previewStream}
                    src={previewUrl}
                    onLoad={() => updateLoaded({ previewStream: true })}
                    onError={() => updateLoaded({ previewStream: false })}
                    alt="Preview Stream"
                />
            )}

            {showHdStream && (
                <div className={classes.hdStreamContainer} key={rerenderKey}>
                    <ReactPlayer
                        url={isLive ? hdUrl : clipStatus?.selectedClip?.src}
                        config={getVideoPlayerConfig()}
                        controls
                        playing
                        volume={0}
                        width="100%"
                        height="100%"
                        onReady={() => {
                            updateLoaded({ hdStream: true });
                            setAttemptHdRefresh(false);
                        }}
                        onError={() => {
                            updateLoaded({ hdStream: false });
                        }}
                    />
                </div>
            )}
        </div>
    );
}

VideoPlayer.propTypes = {
    cameraData: PropTypes.object,
    isPreview: PropTypes.bool,
    forceStaticImage: PropTypes.bool,
    selectedClipClicked: PropTypes.shape({
        hour: PropTypes.string,
        eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        cameraId: PropTypes.number,
    }),
    clipStatus: PropTypes.shape({
        selectedClip: PropTypes.object,
        error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        fetchingExternalClip: PropTypes.bool,
    }),
    streamUrls: PropTypes.object,
    removeSelectedClip: PropTypes.func,
};

export default withApi(memo(VideoPlayer), ApiHelper);
