import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10.001C0 4.48698 4.486 0.000976562 10 0.000976562C15.514 0.000976562 20 4.48698 20 10.001C20 15.515 15.514 20.001 10 20.001C4.486 20.001 0 15.515 0 10.001ZM2 10.001C2 14.412 5.589 18.001 10 18.001C14.411 18.001 18 14.412 18 10.001C18 5.58998 14.411 2.00098 10 2.00098C5.589 2.00098 2 5.58998 2 10.001Z"
            fill="#485769"
        />
        <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5Z" fill="#7DA6DB" />
    </React.Fragment>,
    'AddSchedule'
);
