import gql from 'graphql-tag';
import { endOfDay, startOfDay } from 'date-fns';
import { get } from 'lodash';
import {
    ACCESS_POINTS_FOR_SITES_QUERY,
    ACCESS_POINTS_QUERY,
    ACCESS_POINTS_WITH_STATUS_QUERY,
    ACCESS_POINTS_WITH_COUNT_QUERY,
    ACCESS_POINT_COUNT,
    CREATE_BULK_CARDS,
    CREATE_GROUP,
    GET_ALL_CAMERAS,
    GET_ALL_CAMERAS_PAGINATED_WITH_COUNT,
    GET_ALL_CARDS_QUERY,
    GET_ALL_PANELS_PER_SITES,
    GET_ALL_USERS_WITH_FILTERING_QUERY,
    GET_CAMERAS_BY_LAYOUT,
    GET_CREDENTIAL_INFO,
    GET_DEVICE_COUNT_FOR_SITES,
    GET_FACEPRINTS_LIST_QUERY,
    GET_GROUPS_QUERY,
    GET_ID_NAME_FOR_ALL_PANELS_QUERY,
    GET_ID_NAME_TYPE_FOR_ACCESS_POINTS_QUERY,
    GET_MOBILE_PASS_LIST_QUERY,
    GET_UNASSOCIATED_PANELS,
    GET_USERS_QUERY,
    GET_VIDEO_LAYOUTS_LIST,
    GET_VIRTUAL_PANEL_URL_BY_SERIAL_NUMBER_QUERY,
    INTRUSION_PANELS_FOR_SITES_QUERY,
    LIST_ADMINS_WITH_LIMIT_QUERY,
    SEND_CONSENT,
    SET_EE_LPR_LICENSE_PLATE_CARDS,
    SET_FACIAL_ID_CARD,
    SET_LICENSE_PLATE_CARDS,
    UPDATE_USER_IMAGE_MUTATION,
    ACCESS_POINTS_WITH_STATUS_AND_COUNT_QUERY,
    ACCESS_POINTS_FOR_OFFLINE_GROUP,
} from '../../graphql';
import { fetchGraphQLDataPaginated } from '../utils/Utils';
import { datadogRum } from '@datadog/browser-rum';
import UserTracking from '../utils/UserTracking';
import { COLLISION_TYPE, CREDENTIAL_COLLISION, MAX_PAGE_SIZE_GRAPHQL } from '../constants/Constants';
import { isTypeForDevicePrivileges } from '../../common/utils/Utils';
import { integrationsPmsApi } from '@common/webApis/integrations/pms/integrationsPmsApi';
import { ACCESS_POINTS_FOR_ONLINE_GROUP_HIERARCHY } from '@graphql/queries/accessPoints';

export const pageSize = 1000;

export const SITE_EXCEPTION_EVENTS_QUERY = gql`
    query siteExceptionEvents(
        $pageSize: Int!
        $nextToken: String
        $siteId: Int
        $securityActionIdArray: [Int]
        $from: AWSDateTime
        $to: AWSDateTime
    ) {
        listAccessEvents(
            limit: $pageSize
            nextToken: $nextToken
            filter: {
                siteId: { eq: $siteId }
                securityActionId: { in: $securityActionIdArray }
                eventGroup: ALL_EVENTS
                occurred: { between: [$from, $to] }
            }
        ) {
            items {
                id
                securityActionId
                objectId
                objectName
                siteId
                siteName
                actorId
                actorName
                userTypeId
                occurred
                detail
            }
            nextToken
        }
    }
`;

export const SITE_EVENTS_COUNT_QUERY = gql`
    query getSiteEventCount($siteIdArray: [Int], $securityActionIdArray: [Int], $from: AWSDateTime, $to: AWSDateTime) {
        getSiteAccessEventCount(
            filter: {
                siteId: { in: $siteIdArray }
                securityActionId: { in: $securityActionIdArray }
                occurred: { between: [$from, $to] }
            }
        ) {
            total
            actions {
                siteId
                count
            }
        }
    }
`;

export const USER_IMAGE_SIGNED_URL_QUERY = gql`
    query getUserImageSignedUrl($userIds: [Int]!) {
        getUserImageSignedUrl(userIds: $userIds) {
            url
            userId
        }
    }
`;

export const SITE_EVENTS_COUNT_BY_EVENT_IDS_QUERY = gql`
    query getSiteEventCount(
        $idArray: [String]
        $siteIdArray: [Int]
        $securityActionIdArray: [Int]
        $from: AWSDateTime
        $to: AWSDateTime
    ) {
        getSiteAccessEventCount(
            filter: {
                id: { in: $idArray }
                siteId: { in: $siteIdArray }
                securityActionId: { in: $securityActionIdArray }
                occurred: { between: [$from, $to] }
            }
        ) {
            total
            actions {
                siteId
                count
            }
        }
    }
`;

export const HOURLY_ACCESS_EVENTS_COUNT_QUERY = gql`
    query accessEventCountByHours($from: AWSDateTime, $to: AWSDateTime) {
        getAccessEventCountByHours(filter: { occurred: { between: [$from, $to] } }) {
            total
            hours {
                hour
                count
                actions {
                    securityActionId
                    count
                }
            }
        }
    }
`;

export const ACCESS_EVENTS_QUERY = gql`
    query listAccessEvents(
        $from: AWSDateTime
        $to: AWSDateTime
        $limit: Int = 100
        $securityActionId: Int
        $eventType: EventType
    ) {
        listAccessEvents(
            filter: {
                occurred: { between: [$from, $to] }
                securityActionId: { eq: $securityActionId }
                eventType: $eventType
            }
            limit: $limit
        ) {
            items {
                actorId
                actorName
                eventType
                id
                objectId
                objectName
                occurred
                securityActionId
                siteName
                siteId
                metadata {
                    snapshotUrl
                    snapshotPreviewUrl
                    sourceEventId
                    cameraId
                    panelEventOccurred
                    sourceEventSecurityActionId
                }
                detail
            }
        }
    }
`;

export const ENRICHMENT_EVENTS_QUERY = gql`
    query listEnrichmentEvents($from: AWSDateTime, $to: AWSDateTime, $limit: Int = 100, $securityActionId: Int) {
        listEnrichmentEvents(
            filter: { occurred: { between: [$from, $to] }, securityActionId: { eq: $securityActionId } }
            limit: $limit
        ) {
            items {
                id
                parentEventId
                rootEventId
                accountId
                securityActionId
                objectId
                objectName
                siteId
                siteName
                actorId
                actorName
                occurred
                credentialObjectId
                metadata {
                    snapshotUrl
                    snapshotPreviewUrl
                    sourceEventId
                    cameraId
                    panelEventOccurred
                    sourceEventSecurityActionId
                }
                userDefinedMessage
            }
        }
    }
`;

export const LAST_ACCESS_EVENTS_QUERY_BY_ACTOR_ID = gql`
    query listAccessEvents($actorId: Int, $limit: Int!, $from: AWSDateTime, $to: AWSDateTime) {
        listAccessEvents(filter: { occurred: { between: [$from, $to] }, actorId: { eq: $actorId } }, limit: $limit) {
            items {
                actorId
                actorName
                eventType
                id
                objectId
                objectName
                occurred
                securityActionId
                siteName
                siteId
                credentialObjectId
                detail
            }
        }
    }
`;

export const LAST_ACCESS_EVENTS_QUERY_BY_CREDENTIAL_ID = gql`
    query listAccessEvents($credentialId: Int, $limit: Int!, $from: AWSDateTime, $to: AWSDateTime) {
        listAccessEvents(
            filter: { occurred: { between: [$from, $to] }, credentialId: { eq: $credentialId } }
            limit: $limit
        ) {
            items {
                actorId
                actorName
                eventType
                id
                objectId
                objectName
                occurred
                securityActionId
                siteName
                siteId
                detail
            }
        }
    }
`;

export const LIST_ADMINS_QUERY = gql`
    query getAdmins {
        getAdmins {
            capStatus
            limit
            administrators {
                id
                onairObjectId
                isPrimary
                lastLogin
                customAssignment
                adminInfo {
                    firstName
                    lastName
                    email
                    created
                    migrated
                }
                roles {
                    id
                    name
                }
            }
        }
    }
`;

export const LIST_ROLES_QUERY = gql`
    query getRoles {
        getRoles {
            id
            name
            description
            editable
        }
    }
`;

export const LIST_CAMERAS_QUERY = gql`
    query getDetailedCameras($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getDetailedCameras(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            id
            cameraId
            name
            site {
                id
                siteName
            }
            videoProvider {
                type
                version
            }
            liveViewUrl
            status
        }
    }
`;

export const LIST_CAMERAS_FROM_SITE_QUERY = gql`
    query getAllCameras($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getAllCameras(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            id
            cameraId
            name
            site {
                id
                siteName
            }
        }
    }
`;

export const GET_ID_NAME_FOR_CAMERAS_QUERY = gql`
    query getDetailedCameras($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getDetailedCameras(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            id
            name
        }
    }
`;

export const LIST_CAMERAS_WITH_ASSOCIATED_DEVICES_QUERY = gql`
    query getCamerasWithAssociatedDevices($pageSize: Int!, $offset: Int!) {
        getCamerasWithAssociatedDevices(limit: { rows: $pageSize, offset: $offset }) {
            associated_devices {
                device_mapping_id
                device_name
                device_object_id
                device_type
                id
            }
            cameraId
            id
            name
            site {
                id
                siteName
            }
        }
    }
`;

export const LIST_FEATURES_QUERY = gql`
    query getFeatures {
        getFeatures {
            id
            key
            displayOrder
            permissions {
                id
                key
                limited
            }
        }
    }
`;

export const LIST_ASSIGNMENTS_FROM_ONAIR_QUERY = gql`
    query getAssignmentOnairObjects {
        getGroups {
            id
            name
        }
        getSites {
            id
            name
        }
        getLockdowns(includeEgressScenarios: false) {
            id
            name
            isDefault
            issued
        }
    }
`;

export const LIST_SITES = gql`
    query getSites {
        getSites {
            id
            name
            description
            address {
                id
                streetAddress1
                streetAddress2
                city
                state
                country
                postalCode
                timeZone
            }
            geolocation {
                geometry {
                    coordinates
                }
                center
                relevance
                accuracy
                validAddress
            }
        }
    }
`;

export const LIST_SITES_NO_GEOCODE = gql`
    query getSites {
        getSites {
            id
            name
            description
            address {
                timeZone
            }
        }
    }
`;

export const OCCUPANCY_COUNT_QUERY = gql`
    query occupancyCount($hours: Int!, $siteIds: [Int]!) {
        occupancyCount(hours: $hours, siteIds: $siteIds) {
            siteId
            onSiteCount
        }
    }
`;

export const GET_ROLE_QUERY = gql`
    query getRoleById($roleId: Int!) {
        getRoleById(id: $roleId) {
            id
            name
            description
            editable
            features {
                id
                featureSet
                permissions {
                    id
                }
            }
            numOfAdminsAssigned
        }
    }
`;

export const GET_ADMIN_QUERY = gql`
    query getAdminById($adminId: Int!) {
        getAdminById(id: $adminId) {
            id
            isPrimary
            adminInfo {
                firstName
                lastName
                email
                phoneNumber
                timeZone
                migrated
                showSiteTimeZone
                languageId
            }
            userTypeId
            roles {
                id
            }
            assignments {
                sites {
                    isAll
                    assignments {
                        site {
                            id
                        }
                    }
                }
                groups {
                    isAll
                    assignments {
                        group {
                            id
                        }
                    }
                }
                lockdowns {
                    isAll
                    assignments {
                        lockdown {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LIVE_VIDEO_CUSTOM_LAYOUTS_QUERY = gql`
    query getLiveVideoCustomLayouts {
        getLiveVideoCustomLayouts {
            id
            name
            isDefault
            layoutType
            cameraLayoutDetails {
                id
                cameraId
                cameraType {
                    type
                }
                cameraName
                isHd
                ordering
                size
            }
        }
    }
`;

export const SAVE_ROLE_MUTATION = gql`
    mutation saveRole($role: RoleInput!) {
        saveRole(role: $role) {
            id
            name
        }
    }
`;

export const DELETE_ADMIN_MUTATION = gql`
    mutation deleteAdmin($adminId: Int!) {
        deleteAdmin(id: $adminId)
    }
`;

export const DELETE_ROLE_MUTATION = gql`
    mutation deleteRole($id: Int!) {
        deleteRole(id: $id)
    }
`;

export const WHOAMI_WITH_ACCOUNT_FEATURES_QUERY = gql`
    query whoami {
        whoami {
            id
            adminInfo {
                username
                email
                firstName
                lastName
            }
            account {
                accountId
                accountName
                subscriptionLevel
                dealerName
                dealerAccountId
                hdStreamLimitValue
                nfcCredentialEnabled
                brivoAllegionMobilePass
                blockOnair
                suppressEvents
                facialIdValue
                multifamilySupportEnabled
            }
            onairObjectId
            isPrimary
            adminInfo {
                username
                firstName
                lastName
                middleName
                email
                timeZone
                showSiteTimeZone
            }
            account {
                accountId
                accountFeatures {
                    lockdownEnabled
                    snapshotEnabled
                    scheduleExceptionsSupport
                    isLPRByAvutecEnabled
                    isLPRByEEEnabled
                }
            }
            accessibleAccounts {
                id
                name
            }
            permissions {
                id
                name
            }
            assignments {
                sites {
                    isAll
                    assignments {
                        site {
                            id
                            name
                        }
                    }
                }
                groups {
                    isAll
                    assignments {
                        group {
                            id
                            name
                        }
                    }
                }
                lockdowns {
                    isAll
                    assignments {
                        lockdown {
                            id
                            name
                            isDefault
                            issued
                        }
                    }
                }
            }
        }
    }
`;

export const SAVE_ADMIN_MUTATION = gql`
    mutation saveAdmin($admin: AdminInput!) {
        saveAdmin(admin: $admin) {
            id
        }
    }
`;

export const SAVE_LIVE_VIDEO_CUSTOM_LAYOUT_MUTATION = gql`
    mutation saveLiveVideoCustomLayout($layout: LiveVideoCustomLayoutInput!) {
        saveLiveVideoCustomLayout(layout: $layout) {
            id
        }
    }
`;

export const SAVE_LIVE_VIDEO_CUSTOM_LAYOUT_MUTATION_V2 = gql`
    mutation saveV2LiveVideoCustomLayout($layout: LiveV2VideoCustomLayoutInput!) {
        saveV2LiveVideoCustomLayout(layout: $layout) {
            id
        }
    }
`;

export const ADD_OCCUPANCY_CONFIG_MUTATION = gql`
    mutation addOccupancyConfiguration($occupancyConfig: OccupancyConfigInput!) {
        addOccupancyConfiguration(occupancyConfig: $occupancyConfig) {
            id
        }
    }
`;

export const UPDATE_OCCUPANCY_CONFIG_MUTATION = gql`
    mutation updateOccupancyConfiguration($occupancyConfig: OccupancyConfigInput!) {
        updateOccupancyConfiguration(occupancyConfig: $occupancyConfig) {
            id
        }
    }
`;

export const DELETE_OCCUPANCY_CONFIG_MUTATION = gql`
    mutation deleteOccupancyConfiguration($occupancyConfig: OccupancyConfigInput!) {
        deleteOccupancyConfiguration(occupancyConfig: $occupancyConfig) {
            id
        }
    }
`;

export const UPDATE_DOOR_SCHEDULES = gql`
    mutation updateDoorSchedules($doorSchedules: DoorSchedulesInput!) {
        updateDoorSchedules(doorSchedules: $doorSchedules)
    }
`;

export const UPDATE_ELEVATOR_SCHEDULES = gql`
    mutation updateElevatorSchedules($elevatorSchedules: ElevatorSchedulesInput!) {
        updateElevatorSchedules(elevatorSchedules: $elevatorSchedules)
    }
`;

export const SET_INTRUSION_FOR_ALULA = gql`
    mutation setIntrusionForAlula($panelId: Int!, $armStatus: IntrusionState!) {
        setIntrusionForAlula(panelId: $panelId, armStatus: $armStatus) {
            id
            siteId
            armLevel
        }
    }
`;

export const GET_FLOORS_FOR_ELEVATOR = gql`
    query getFloorsForElevator($deviceOId: Int!) {
        getFloorsForElevator(deviceOId: $deviceOId) {
            id
            deviceId
            accessPointName
            type
            deviceScheduleId
            siteId
            siteName
        }
    }
`;

export const UPDATE_FLOOR_SCHEDULE = gql`
    mutation updateFloorSchedules($floorSchedules: FloorSchedulesInput!) {
        updateFloorSchedules(floorSchedules: $floorSchedules)
    }
`;

export const PRESIGNED_URLS_FOR_S3_GET_QUERY = gql`
    query presignedUrlsForS3Get($s3Objects: [presignedUrlInput]!) {
        presignedUrlsForS3Get(s3Objects: $s3Objects) {
            id
            presignedUrl
        }
    }
`;

const GET_CREDENTIAL_FORMATS_QUERY = gql`
    query getCredentialFormats {
        getCredentialFormats {
            id
            name
            credentialFields {
                id
                name
                maxValue
            }
        }
    }
`;

export const GET_ACCESS_POINT_STATUS = gql`
    query getAccessPointStatus($deviceOId: Int!, $doorStationId: Boolean = false) {
        getAccessPointStatus(deviceOId: $deviceOId) {
            id
            deviceId
            accessPointName
            type
            deviceScheduleId
            twoFactorScheduleId
            cardRequiredScheduleId
            doorAjarEnabled
            panelId
            panelName
            panelTypeId
            panelCpNumber
            panelFirmware
            canPanelSupportScheduleOverride
            scheduleOverride {
                behavior
                endTimeMode
                endTime
            }
            siteTimezone
            accessPointState
            statusUpdated
            unlockSchedule {
                id
                isScheduleBlockActive
                name
                nextTimeChange
            }
            videoCallConnection @include(if: $doorStationId) {
                presignedUrl
                iceServers {
                    credential
                    urls
                    username
                }
                kinesisVideoError
            }
        }
    }
`;

const GET_RECENT_ACTIVITY = gql`
    query getRecentActivity($actorId: Int!) {
        getRecentActivity(actorId: $actorId) {
            id
            accountId
            actorId
            actorName
            credentialObjectId
            credentialType
            credentialReference
            eventType
            objectId
            objectName
            occurred
            securityActionId
            siteId
            siteName
        }
    }
`;

const GET_USER_ID_MAPPING = gql`
    query getUserIdMapping($userOId: Int!) {
        getUserIdMapping(userOId: $userOId)
    }
`;

const GET_OCCUPANT_NAME_AND_IMAGE = gql`
    query getOccupantNameAndImage($eventId: String!) {
        getOccupantNameAndImage(eventId: $eventId) {
            id
            firstName
            lastName
            companyName
            userImage {
                id
                userImageUri
            }
        }
    }
`;

const GET_SCHEDULE_RELATIONSHIPS = gql`
    query getScheduleRelationships($scheduleId: Int!) {
        getRelationshipsForSchedule(scheduleId: $scheduleId) {
            devices {
                objectId
                name
                siteName
                deviceType
            }
            groups {
                id
                name
                siteName
                deviceName
                deviceType
            }
            notifications {
                notifRuleId
                name
                siteName
            }
        }
    }
`;

const GET_SCHEDULE_BLOCKS_BY_IDS = gql`
    query getScheduleBlocksByIds($scheduleIds: [Int]!) {
        getScheduleBlocksByIds(scheduleIds: $scheduleIds) {
            id
            scheduleBlocks {
                sunday {
                    start
                    end
                }
                monday {
                    start
                    end
                }
                tuesday {
                    start
                    end
                }
                wednesday {
                    start
                    end
                }
                thursday {
                    start
                    end
                }
                friday {
                    start
                    end
                }
                saturday {
                    start
                    end
                }
                holiday {
                    start
                    end
                }
            }
        }
    }
`;

const GET_DOOR_STATION_NOTIFICATIONS_BY_IDS_QUERY = gql`
    query getDoorStationNotificationsById($doorStationId: Int!) {
        getDoorStationNotificationsById(doorStationId: $doorStationId) {
            id
            scheduleId
            userId
            doorStationId
            firstName
            lastName
            scheduleName
            userTypeId
        }
    }
`;

export const GET_AI_VOICE_ASSISTANT_BY_BDS_ID_QUERY = gql`
    query getAiVoiceAssistantByBdsId($doorStationId: Int!) {
        getAiVoiceAssistantByBdsId(doorStationId: $doorStationId) {
            aiAssistantEnabled
        }
    }
`;

export const SET_AI_VOICE_ASSISTANT_FOR_BDS_ID_QUERY = gql`
    mutation setAiVoiceAssistantForBdsId($doorStationId: Int!, $aiAssistantEnabled: Boolean!) {
        setAiVoiceAssistantForBdsId(doorStationId: $doorStationId, aiAssistantEnabled: $aiAssistantEnabled)
    }
`;

export const UPDATE_DOOR_STATION_NOTIFICATIONS = gql`
    mutation updateDoorStationNotifications($doorStationNotificationInput: DoorStationNotificationInput!) {
        updateDoorStationNotifications(doorStationNotificationInput: $doorStationNotificationInput)
    }
`;
export const DELETE_DOOR_STATION_NOTIFICATIONS_MUTATION = gql`
    mutation deleteDoorStationNotifications($id: Int!) {
        deleteDoorStationNotifications(id: $id)
    }
`;

export const SAVE_UNIFIED_DASHBOARD_LAYOUT_MUTATION = gql`
    mutation saveUnifiedDashboardLayout($unifiedDashboard: UnifiedDashboardInput!) {
        saveUnifiedDashboardLayout(unifiedDashboard: $unifiedDashboard) {
            id
        }
    }
`;

export const GET_ALL_PAGINATED_DASHBOARDS = gql`
    query getPaginatedDashboards($rows: Int = 100, $offset: Int = 0) {
        getPaginatedDashboards(limit: { rows: $rows, offset: $offset }) {
            dashboards {
                id
                dashboardName
                isDefault
                layoutType
                sharingType
                globalFilters
            }
            totalCount
        }
    }
`;

export const GET_SECTIONS_BY_DASHBOARD = gql`
    query getSections($dashboardId: Int!) {
        getSections(dashboardId: $dashboardId) {
            sectionColumns
            sectionDirection
            sectionId
            sectionName
            sectionOrder
            sectionWidgets {
                configurations
                filters
                widgetId
                sectionId
                widgetColumns
                widgetOrder
                widgetType
            }
        }
    }
`;
const eventTimeDescSort = (a, b) => new Date(b.occurred) - new Date(a.occurred);

export default class GqlHelper {
    constructor(client, flagClient) {
        this.client = client;

        this.flagClient = flagClient;
    }

    mutate = async (req) => {
        this.trackGqlMutation(req);
        try {
            const result = await this.client.mutate({
                ...req,
                errorPolicy: 'all',
            });

            this.handleGqlErrors(result);

            return result;
        } catch (err) {
            return Promise.reject(JSON.stringify(err));
        }
    };

    handleGqlErrors(result) {
        if (result.errors) {
            result.errors.forEach((err) => {
                const errorMessage = `GraphQLError occurred at ${JSON.stringify(err.path)} with error ${JSON.stringify(
                    err.errorInfo || err.message
                )}`;
                const error = Error(errorMessage);
                console.log(errorMessage);
                datadogRum.addError(error, err);
            });
            if (this.flagClient.variation('alternative-authentication', false)) {
                this.handleExceptions(result.errors, []);
            }
        }
    }

    handleExceptions = (e, object) => {
        if (this.requiresLogout(e)) {
            e.requiresLogout = true;
            return Promise.reject(e);
        }
        return object;
    };

    requiresLogout = (e) =>
        this.flagClient.variation('alternative-authentication', false) && JSON.stringify(e).includes('401');

    query = async (req) => {
        this.trackGqlQuery(req);
        try {
            const result = await this.client.query({
                ...req,
                errorPolicy: 'all',
            });

            this.handleGqlErrors(result);

            return result;
        } catch (err) {
            if (this.requiresLogout(err)) {
                err.requiresLogout = true;
            }
            return Promise.reject(err);
        }
    };

    getUsers = async (
        pageSize = 50,
        offset = 0,
        sortField = 'last_name',
        direction = 'ASC',
        filters,
        withVisitors = false
    ) => {
        filters = filters || [
            {
                field: 'name',
                type: 'field',
                value: '',
            },
        ];
        try {
            const gqlResponse = await this.query({
                query: GET_USERS_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sortField,
                    direction,
                    filters,
                    withVisitors,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getAllUsers', { count: 0, users: [] });
        } catch (e) {
            return this.handleExceptions(e, { count: 0, users: [] });
        }
    };

    getUsersWithPmsCheck = async (
        pageSize = 50,
        offset = 0,
        sortField = 'last_name',
        direction = 'ASC',
        filters,
        enableForPmsGroup,
        withVisitors = false
    ) => {
        return this.getUsers(pageSize, offset, sortField, direction, filters, withVisitors).then(async (response) => {
            if (!enableForPmsGroup && response.users.length > 0) {
                const userIds = response.users.map((user) => user.id);
                if (userIds.length) {
                    const pmsResult = await integrationsPmsApi.retrieveUsersPmsActiveStatus(userIds);
                    response.users = response.users.map((user) => {
                        return {
                            ...user,
                            isActivatedInPMS: pmsResult === null ? true : !!pmsResult[user.id],
                        };
                    });
                }
            }
            return response;
        });
    };

    getAllUsersWithFiltering = async (
        pageSize = 50,
        offset = 0,
        sortField = 'last_name',
        direction = 'ASC',
        filters = []
    ) => {
        try {
            const gqlResponse = await this.query({
                query: GET_ALL_USERS_WITH_FILTERING_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sortField,
                    direction,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });
            if (gqlResponse.data && gqlResponse.data.getAllUsersWithFiltering) {
                return gqlResponse.data.getAllUsersWithFiltering;
            } else {
                return { count: 0, users: [] };
            }
        } catch (e) {
            return this.handleExceptions(e, { count: 0, users: [] });
        }
    };

    getGroups = async (pageSize = 50, offset = 0, sortField = 'name', direction = 'ASC', filters) => {
        filters = filters || [
            {
                field: 'name',
                type: 'field',
                value: '',
            },
        ];
        try {
            const gqlResponse = await this.query({
                query: GET_GROUPS_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sortField,
                    direction,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });

            if (gqlResponse.data && gqlResponse.data.getAllGroups) {
                return gqlResponse.data.getAllGroups;
            } else {
                return { count: null, groups: [] };
            }
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getGroupsWithPmsCheck = async (
        pageSize = 50,
        offset = 0,
        sortField = 'name',
        direction = 'ASC',
        filters,
        enableForPmsUser
    ) => {
        return this.getGroups(pageSize, offset, sortField, direction, filters).then(async (response) => {
            if (!enableForPmsUser) {
                const groupIds = response.groups.map((group) => group.id);
                if (groupIds.length) {
                    const pmsResult = await integrationsPmsApi.retrieveGroupsPmsActiveStatus(groupIds);
                    response.groups = response.groups.map((group) => {
                        return {
                            ...group,
                            isActivatedInPMS: pmsResult === null ? true : !!pmsResult[group.id],
                        };
                    });
                }
            }
            return response;
        });
    };

    getAllGroups = async () => {
        try {
            return await fetchGraphQLDataPaginated(this, GET_GROUPS_QUERY, 'getAllGroups', pageSize, 'groups');
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAllowedVisitorsGroups = async () => {
        try {
            const filters = [
                {
                    field: 'allow_visitors',
                    type: 'field',
                    value: 'true',
                },
            ];
            return await fetchGraphQLDataPaginated(this, GET_GROUPS_QUERY, 'getAllGroups', pageSize, 'groups', filters);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    createGroup = async (group) => {
        try {
            const response = await this.mutate({
                mutation: CREATE_GROUP,
                fetchPolicy: 'no-cache',
                variables: {
                    name: group.name,
                    excludedFromLockdown: group.excludedFromLockdown,
                },
                errorPolicy: 'all',
            });

            return get(response, 'data.createGroup', {});
        } catch (e) {
            this.handleExceptions(e, {});
        }
    };

    getGqlSiteExceptionEvents = async (events, siteId, securityActionIdArray, from, to, nextToken, resolve, reject) => {
        if (!events) {
            events = [];
        }

        const gqlResponse = await this.query({
            query: SITE_EXCEPTION_EVENTS_QUERY,
            variables: {
                pageSize: pageSize,
                nextToken: nextToken,
                siteId: siteId,
                securityActionIdArray: securityActionIdArray,
                from: from,
                to: to,
            },
            fetchPolicy: 'no-cache',
        });

        if (
            gqlResponse &&
            gqlResponse.data &&
            gqlResponse.data.listAccessEvents &&
            gqlResponse.data.listAccessEvents.items
        ) {
            const items = gqlResponse.data.listAccessEvents.items;
            events = events.concat(items);

            if (items.length < pageSize) {
                return resolve(events);
            } else {
                const newNextToken = gqlResponse.data.listAccessEvents.nextToken;
                this.getGqlSiteExceptionEvents(
                    events,
                    siteId,
                    securityActionIdArray,
                    from,
                    to,
                    newNextToken,
                    resolve,
                    reject
                );
            }
        } else {
            return resolve(events);
        }
    };

    getGqlSiteExceptionEventsPromise = async (siteId, securityActionIdArray, from, to) => {
        return new Promise((resolve, reject) => {
            this.getGqlSiteExceptionEvents(
                [],
                siteId,
                securityActionIdArray,
                from,
                to,
                to.toISOString(),
                resolve,
                reject
            );
        }).catch((err) => {
            this.handleExceptions(err);
        });
    };

    getSiteExceptionEventsForDay = async (siteId, securityActionIdArray, date) => {
        const from = startOfDay(date);
        const to = endOfDay(date);

        let events = (await this.getGqlSiteExceptionEventsPromise(siteId, securityActionIdArray, from, to)) || [];

        return events.sort(eventTimeDescSort);
    };

    getSitesEventsCount = async (fromDate, toDate, siteIdArray, securityActionIdArray, eventIdArray) => {
        const defaultReturn = {
            total: 0,
            actions: [],
        };

        try {
            const gqlResponse = await this.query({
                query: eventIdArray ? SITE_EVENTS_COUNT_BY_EVENT_IDS_QUERY : SITE_EVENTS_COUNT_QUERY,
                variables: {
                    siteIdArray: siteIdArray,
                    securityActionIdArray: securityActionIdArray,
                    from: fromDate,
                    to: toDate,
                    idArray: eventIdArray,
                },
                fetchPolicy: 'no-cache',
            });

            if (gqlResponse && gqlResponse.data && gqlResponse.data.getSiteAccessEventCount) {
                return gqlResponse.data.getSiteAccessEventCount;
            } else {
                return defaultReturn;
            }
        } catch (e) {
            return this.handleExceptions(e, defaultReturn);
        }
    };

    getAccessEvents = async (from, to, limit, securityActionId, eventType) => {
        try {
            const response = await this.query({
                query: ACCESS_EVENTS_QUERY,
                variables: {
                    from,
                    to,
                    limit,
                    securityActionId,
                    eventType,
                },
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.listAccessEvents.items', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getEnrichmentEvents = async (from, to, limit, securityActionId) => {
        try {
            const response = await this.query({
                query: ENRICHMENT_EVENTS_QUERY,
                variables: {
                    from,
                    to,
                    limit,
                    securityActionId,
                },
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.listEnrichmentEvents.items', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getLatestActivityByActorId = async (actorId, limit, fromDate, toDate) => {
        try {
            const response = await this.query({
                query: LAST_ACCESS_EVENTS_QUERY_BY_ACTOR_ID,
                variables: {
                    actorId: actorId,
                    limit: limit,
                    from: fromDate,
                    to: toDate,
                },
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.listAccessEvents.items', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getLatestActivityByCredentialId = async (credentialId, limit, fromDate, toDate) => {
        try {
            const response = await this.query({
                query: LAST_ACCESS_EVENTS_QUERY_BY_CREDENTIAL_ID,
                variables: {
                    credentialId: credentialId,
                    limit: limit,
                    from: fromDate,
                    to: toDate,
                },
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.listAccessEvents.items', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getRole = async (id) => {
        try {
            const response = await this.query({
                query: GET_ROLE_QUERY,
                fetchPolicy: 'no-cache',
                variables: {
                    roleId: id,
                },
            });

            return get(response, 'data.getRoleById', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    getAdmin = async (id) => {
        try {
            const response = await this.query({
                query: GET_ADMIN_QUERY,
                fetchPolicy: 'no-cache',
                variables: {
                    adminId: id,
                },
            });

            return get(response, 'data.getAdminById', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    getAdmins = async () => {
        try {
            const response = await this.query({
                query: LIST_ADMINS_QUERY,
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.getAdmins', { administrators: [] });
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAdminsWithLimit = async (pageSize, offset, filters, sortField = 'last_name', direction = 'ASC') => {
        try {
            const response = await this.query({
                query: LIST_ADMINS_WITH_LIMIT_QUERY,
                variables: {
                    pageSize,
                    offset,
                    filters,
                    sortField,
                    direction,
                },
                fetchPolicy: 'no-cache',
            });

            return response.data?.getAdminsWithLimit
                ? response.data.getAdminsWithLimit
                : { count: 0, administrators: [] };
        } catch (e) {
            return this.handleExceptions(e, { count: 0, administrators: [] });
        }
    };

    getRoles = async () => {
        try {
            const response = await this.query({
                query: LIST_ROLES_QUERY,
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.getRoles', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getFeatures = async () => {
        try {
            const response = await this.query({
                query: LIST_FEATURES_QUERY,
                fetchPolicy: 'no-cache',
            });

            return get(response, 'data.getFeatures', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    sendConsent = async (userId, email, language) => {
        try {
            const response = await this.mutate({
                mutation: SEND_CONSENT,
                fetchPolicy: 'no-cache',
                variables: {
                    userId,
                    email,
                    language,
                },
                errorPolicy: 'all',
            });
            return response.data?.sendConsent
                ? get(response, 'data.sendConsent', {})
                : { errors: get(response, 'errors', {}) };
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    createRole = async (role) => {
        try {
            const response = await this.mutate({
                mutation: SAVE_ROLE_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    role,
                },
                errorPolicy: 'all',
            });
            if (response.data.saveRole) {
                return get(response, 'data.saveRole', {});
            }
            if (response.errors) {
                return {};
            }
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    updateUserImage = async (user) => {
        const response = await this.mutate({
            mutation: UPDATE_USER_IMAGE_MUTATION,
            fetchPolicy: 'no-cache',
            variables: {
                user: user,
            },
            errorPolicy: 'all',
        });

        if (!response || response.errors) {
            throw Error(response.errors);
        }

        return response.id;
    };

    addBulkCards = async (card) => {
        const response = await this.mutate({
            mutation: CREATE_BULK_CARDS,
            fetchPolicy: 'no-cache',
            variables: {
                card: card,
            },
            errorPolicy: 'all',
        });

        if (!response || response.errors) {
            throw Error(response.errors);
        }

        return get(response, 'data.createBulkCards', []);
    };

    getUserImageSignedUrl = async (userIds) => {
        try {
            const gqlResponse = await this.query({
                query: USER_IMAGE_SIGNED_URL_QUERY,
                variables: {
                    userIds,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getUserImageSignedUrl', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    setLicensePlateCards = async (userId, licensePlates) => {
        const response = await this.mutate({
            mutation: SET_LICENSE_PLATE_CARDS,
            fetchPolicy: 'no-cache',
            variables: {
                userId,
                licensePlates,
            },
            errorPolicy: 'all',
        });

        if (!response || response.errors) {
            const plateNumbers = licensePlates.map((lp) => lp.value);

            let message = `Error setting license plates: ${plateNumbers.join(', ')}`;

            if (Array.isArray(response.errors)) {
                const additionalMessages = response.errors.map((e) => e?.errorInfo).join('\n');
                message += '\n' + additionalMessages;
            }

            let err = new Error(message);

            if (message.includes(CREDENTIAL_COLLISION)) {
                err.collisionData = {
                    type: COLLISION_TYPE.ADD_LICENSE_PLATE,
                    userId,
                    licensePlates,
                };
            }

            throw err;
        }

        return get(response, 'data.setLicensePlateCards', []);
    };

    setEELPRLicensePlateCards = async (userId, licensePlates) => {
        const response = await this.mutate({
            mutation: SET_EE_LPR_LICENSE_PLATE_CARDS,
            fetchPolicy: 'no-cache',
            variables: {
                userId,
                licensePlates,
            },
            errorPolicy: 'all',
        });

        if (!response || response.errors) {
            const plateNumbers = licensePlates.map((lp) => lp.value);

            let message = `Error setting license plates: ${plateNumbers.join(', ')}`;

            if (Array.isArray(response.errors)) {
                const additionalMessages = response.errors.map((e) => e?.errorInfo).join('\n');
                message += '\n' + additionalMessages;
            }

            let err = new Error(message);

            if (message.includes(CREDENTIAL_COLLISION)) {
                err.collisionData = {
                    type: COLLISION_TYPE.ADD_LICENSE_PLATE,
                    userId,
                    licensePlates,
                };
            }

            throw err;
        }

        return get(response, 'data.setEELPRLicensePlateCards', []);
    };

    setFacialIdCard = async (userId, facialId, consentId) => {
        const response = await this.mutate({
            mutation: SET_FACIAL_ID_CARD,
            fetchPolicy: 'no-cache',
            variables: {
                userId,
                facialId,
                consentId,
            },
            errorPolicy: 'all',
        });

        if (!response || response.errors) {
            let message = 'Error creating facial ID credential';

            if (Array.isArray(response.errors)) {
                const additionalMessages = response.errors.map((e) => e?.errorInfo).join('\n');
                message += '\n' + additionalMessages;
            }

            throw new Error(message);
        }

        return get(response, 'data.setFacialIdCredential', {});
    };

    saveAdmin = async (admin) => {
        try {
            const response = await this.mutate({
                mutation: SAVE_ADMIN_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    admin: admin,
                },
            });
            const { errors } = response;
            if (errors) {
                return {
                    error: get(errors[0], 'errorInfo.message') || get(errors[0], 'message') || 'Saving failed',
                };
            }

            return get(response, 'data.saveAdmin', {});
        } catch (e) {
            return this.handleExceptions(e, {
                error:
                    get(e.graphQLErrors[0], 'errorInfo.message') ||
                    get(e.graphQLErrors[0], 'message') ||
                    'Saving failed',
            });
        }
    };

    deleteAdmin = async (id) => {
        try {
            const response = await this.mutate({
                mutation: DELETE_ADMIN_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    adminId: id,
                },
            });

            return get(response, 'data.deleteAdmin', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    deleteRole = async (id) => {
        try {
            const response = await this.mutate({
                mutation: DELETE_ROLE_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    id,
                },
            });

            return get(response, 'data.deleteRole', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    whoami = async () => {
        try {
            const response = await this.query(
                {
                    query: WHOAMI_WITH_ACCOUNT_FEATURES_QUERY,
                    fetchPolicy: 'no-cache',
                },
                {}
            );

            return get(response, 'data', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    getAssignmentOnairObjects = async () => {
        try {
            const response = await this.query({
                query: LIST_ASSIGNMENTS_FROM_ONAIR_QUERY,
                fetchPolicy: 'no-cache',
            });

            return {
                getSites: get(response, 'data.getSites', []),
                getGroups: get(response, 'data.getGroups', []),
                getLockdowns: get(response, 'data.getLockdowns', []),
            };
        } catch (e) {
            return this.handleExceptions(e, {
                getSites: [],
                getGroups: [],
                getLockdowns: [],
            });
        }
    };

    listCameras = async () => {
        try {
            return await fetchGraphQLDataPaginated(this, LIST_CAMERAS_QUERY, 'getDetailedCameras');
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    listCamerasForSites = async (sitesOids, pageSize = MAX_PAGE_SIZE_GRAPHQL, offset = 0) => {
        const filters = [
            {
                field: 'siteId',
                type: 'field',
                value: JSON.stringify(sitesOids),
            },
        ];
        try {
            const result = await this.query({
                query: LIST_CAMERAS_QUERY,
                variables: {
                    filters,
                    pageSize,
                    offset,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.getDetailedCameras', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    listCamerasBySite = async (pageSize = 1000, offset = 0, filters) => {
        filters = filters || [
            {
                field: 'siteId',
                type: 'field',
                value: '',
            },
        ];
        try {
            const allCameras = await this.query({
                query: LIST_CAMERAS_FROM_SITE_QUERY,
                variables: {
                    pageSize,
                    offset,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });
            return get(allCameras, 'data.getAllCameras', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    listCamerasWithAssociatedDevices = async () => {
        try {
            const allCamerasWithAssociatedDevices = await fetchGraphQLDataPaginated(
                this,
                LIST_CAMERAS_WITH_ASSOCIATED_DEVICES_QUERY,
                'getCamerasWithAssociatedDevices'
            );
            return allCamerasWithAssociatedDevices;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPoints = async () => {
        try {
            const result = await fetchGraphQLDataPaginated(
                this,
                GET_ID_NAME_TYPE_FOR_ACCESS_POINTS_QUERY,
                'getAccessPoints'
            );
            const accessPoints = result?.map((accessPoint) => {
                return { id: accessPoint.id, name: accessPoint.name, type: accessPoint.type };
            });
            return accessPoints;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointStatus = async (deviceOId, doorStationId) => {
        try {
            const gqlResponse = await this.query({
                query: GET_ACCESS_POINT_STATUS,
                variables: {
                    deviceOId,
                    doorStationId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getAccessPointStatus', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    getAccessPointsWithStatusForSites = async (
        sitesOids,
        includeCardReaders = false,
        includeMappedDevices = false,
        pageSize = MAX_PAGE_SIZE_GRAPHQL
    ) => {
        let offset = 0;
        try {
            const result = await this.query({
                query: ACCESS_POINTS_WITH_STATUS_QUERY,
                variables: {
                    offset,
                    pageSize,
                    sitesOids,
                    includeCardReaders,
                    includeMappedDevices,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.getAccessPoints', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointsWithStatusAndCount = async (
        sitesOids,
        offset,
        filters,
        includeCardReaders = false,
        includeMappedDevices = false,
        pageSize = MAX_PAGE_SIZE_GRAPHQL
    ) => {
        try {
            const result = await this.query({
                query: ACCESS_POINTS_WITH_STATUS_AND_COUNT_QUERY,
                variables: {
                    offset,
                    pageSize,
                    sitesOids,
                    filters,
                    includeCardReaders,
                    includeMappedDevices,
                },
                fetchPolicy: 'no-cache',
            });

            return {
                devices: get(result, 'data.getAccessPoints', []) || [],
                totalCount: get(result, 'data.getDeviceCountsForSites[0].doors', 0),
            };
        } catch (e) {
            return this.handleExceptions(e, { devices: [], totalCount: 0 });
        }
    };

    getAccessPointsWithCount = async (
        sitesOids,
        offset,
        filters,
        includeCardReaders = false,
        includeMappedDevices = false,
        pageSize = MAX_PAGE_SIZE_GRAPHQL,
        sortField,
        sortAsc
    ) => {
        try {
            const result = await this.query({
                query: ACCESS_POINTS_WITH_COUNT_QUERY,
                variables: {
                    offset,
                    pageSize,
                    sitesOids,
                    filters,
                    includeCardReaders,
                    includeMappedDevices,
                    sortField,
                    sortAsc,
                },
                fetchPolicy: 'no-cache',
            });

            return {
                devices: get(result, 'data.getAccessPoints', []),
                totalCount: get(result, 'data.getDeviceCountsForSites[0].doors', 0),
            };
        } catch (e) {
            return this.handleExceptions(e, { devices: [], totalCount: 0 });
        }
    };

    getAccessPointsFromOnlineHierarchy = async (offset, filters, pageSize = MAX_PAGE_SIZE_GRAPHQL) => {
        try {
            const result = await this.query({
                query: ACCESS_POINTS_FOR_ONLINE_GROUP_HIERARCHY,
                variables: {
                    offset,
                    pageSize,
                    siteOid: filters?.siteOid,
                    isOfflineGroup: filters?.isOfflineGroup || false,
                },
                fetchPolicy: 'no-cache',
            });
            return {
                devices: get(result, 'data.getPropertyHierarchyAccessPoints.devices', []) || [],
                totalCount: get(result, 'data.getPropertyHierarchyAccessPoints.totalCount', 0),
            };
        } catch (e) {
            return this.handleExceptions(e, { devices: [], totalCount: 0 });
        }
    };

    getAccessPointsForOfflineGroup = async (groupOid, offset, pageSize = MAX_PAGE_SIZE_GRAPHQL) => {
        try {
            const result = await this.query({
                query: ACCESS_POINTS_FOR_OFFLINE_GROUP,
                variables: {
                    offset,
                    pageSize,
                    groupOid,
                },
                fetchPolicy: 'no-cache',
            });
            return {
                devices: get(result, 'data.getDevicesByOfflineGroup.devices', []),
                totalCount: get(result, 'data.getDevicesByOfflineGroup.totalCount', 0),
            };
        } catch (e) {
            return this.handleExceptions(e, { devices: [], totalCount: 0 });
        }
    };

    getDeviceCountsForSites = async (sitesOids, includeMappedDevices = false) => {
        try {
            const result = await this.query({
                query: GET_DEVICE_COUNT_FOR_SITES,
                variables: {
                    sitesOids,
                    includeMappedDevices,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.getDeviceCountsForSites', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointsForSites = async (pageSize = 1000, offset = 0, sitesOids) => {
        try {
            const result = await this.query({
                query: ACCESS_POINTS_FOR_SITES_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sitesOids,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.getAccessPointsForSites', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getIntrusionPanelsForSites = async (siteOIds, pageSize = MAX_PAGE_SIZE_GRAPHQL) => {
        try {
            let offset = 0;
            const result = await this.query({
                query: INTRUSION_PANELS_FOR_SITES_QUERY,
                variables: {
                    offset,
                    pageSize,
                    siteOIds,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.getIntrusionPanelsForSites', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointsWithSiteId = async () => {
        try {
            const result = await fetchGraphQLDataPaginated(this, ACCESS_POINTS_QUERY, 'getAccessPoints');
            const accessPoints = result?.map((accessPoint) => {
                return {
                    id: accessPoint.id,
                    name: accessPoint.name,
                    siteId: accessPoint.siteId,
                    siteName: accessPoint.siteName,
                    type: accessPoint.type,
                };
            });
            return accessPoints;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointDoors = async () => {
        try {
            const result = await fetchGraphQLDataPaginated(this, ACCESS_POINTS_QUERY, 'getAccessPoints');
            const doors = [];
            for (const item of result) {
                if (item.type === 'Door') {
                    doors.push(item);
                }
            }

            const accessPoints = {
                accessPoints: doors?.map((accessPoint) => {
                    return {
                        id: accessPoint.id,
                        name: accessPoint.name,
                        type: accessPoint.type,
                    };
                }),
            };
            return accessPoints;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointsForDevicePrivilegesBatched = async (showControlLocks) => {
        try {
            let countResult = await this.query({ query: ACCESS_POINT_COUNT, fetchPolicy: 'no-cache' });
            const count = countResult.data.getAccessPointCount;
            const pageSize = 1000;
            let allResults = [];

            if (count || count > 0) {
                let resultPromises = [];
                for (let i = 0; i < Math.ceil(count / pageSize); i++) {
                    resultPromises.push(
                        this.query({
                            query: ACCESS_POINTS_QUERY,
                            fetchPolicy: 'no-cache',
                            variables: {
                                offset: i * pageSize,
                                pageSize: pageSize,
                            },
                        })
                    );
                }
                const resolvedResults = await Promise.allSettled(resultPromises);
                resolvedResults.forEach((resolved) => {
                    const data = resolved?.value?.data?.getAccessPoints;
                    if (data) {
                        allResults.push(
                            ...data.map((accessPoint) => {
                                return {
                                    id: accessPoint.id,
                                    name: accessPoint.name,
                                    siteId: accessPoint.siteId,
                                    siteName: accessPoint.siteName,
                                    type: accessPoint.type,
                                };
                            })
                        );
                    }
                });
            }
            return allResults.filter((accessPoint) => isTypeForDevicePrivileges(accessPoint.type, showControlLocks));
        } catch (e) {
            console.log(e);
            return this.handleExceptions(e, []);
        }
    };

    getAccessPointsForDevicePrivileges = async (showControlLocks = false) => {
        try {
            let result = await fetchGraphQLDataPaginated(this, ACCESS_POINTS_QUERY, 'getAccessPoints');
            result = result.filter((accessPoint) => isTypeForDevicePrivileges(accessPoint.type, showControlLocks));
            const accessPoints = result?.map((accessPoint) => {
                return {
                    id: accessPoint.id,
                    name: accessPoint.name,
                    siteId: accessPoint.siteId,
                    siteName: accessPoint.siteName,
                    type: accessPoint.type,
                };
            });
            return accessPoints;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAllPanels = async () => {
        try {
            const result = await fetchGraphQLDataPaginated(this, GET_ID_NAME_FOR_ALL_PANELS_QUERY, 'getAllPanels');
            const panels = result?.map((panel) => {
                return { id: panel.id, name: panel.panelName };
            });
            return panels;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getVirtualPanelUrlBySerialNumber = async (serialNumber) => {
        try {
            const result = await this.query({
                query: GET_VIRTUAL_PANEL_URL_BY_SERIAL_NUMBER_QUERY,
                variables: {
                    serialNumber,
                },
                fetchPolicy: 'no-cache',
            });

            return get(result, 'data.getVirtualPanelUrl', null);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAllPanelsPerSites = async (sitesOids, offset = 0, pageSize = MAX_PAGE_SIZE_GRAPHQL) => {
        try {
            const result = await this.query({
                query: GET_ALL_PANELS_PER_SITES,
                variables: {
                    offset,
                    pageSize,
                    sitesOids,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.panelsPerSites', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getUnassociatedPanels = async (offset = 0, pageSize = MAX_PAGE_SIZE_GRAPHQL) => {
        try {
            const result = await this.query({
                query: GET_UNASSOCIATED_PANELS,
                variables: {
                    offset,
                    pageSize,
                },
                fetchPolicy: 'no-cache',
            });
            return get(result, 'data.getUnassociatedPanels', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getDetailedCameras = async (pageSize = 1000, offset = 0, filters) => {
        filters = filters || [
            {
                field: 'name',
                type: 'field',
                value: '',
            },
        ];
        try {
            const allCameras = await this.query({
                query: GET_ID_NAME_FOR_CAMERAS_QUERY,
                variables: {
                    pageSize,
                    offset,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });
            return get(allCameras, 'data.getDetailedCameras', []) || [];
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getSites = async (args) => {
        try {
            const query = args?.includeGeocode && args?.includeGeocode === true ? LIST_SITES : LIST_SITES_NO_GEOCODE;

            const response = await this.query({
                query: query,
                fetchPolicy: 'no-cache',
            });

            if (!response || !response.data || !response.data.getSites) {
                return [];
            }

            const sites = response.data.getSites?.map((site) => ({
                ...site,
                siteName: site.name,
            }));

            return sites;
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getOccupancyCount = async (hours, siteIds) => {
        try {
            const gqlResponse = await this.query({
                query: OCCUPANCY_COUNT_QUERY,
                variables: {
                    hours,
                    siteIds,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.occupancyCount', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAllCards = async (pageSize = 50, offset = 0, sortField, sortAsc, filters = [{}]) => {
        const defaultReturn = {
            count: 0,
            cards: [],
        };

        try {
            const gqlResponse = await this.query({
                query: GET_ALL_CARDS_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sortField,
                    direction: sortAsc,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getAllCards', defaultReturn);
        } catch (e) {
            return this.handleExceptions(e, defaultReturn);
        }
    };

    getMobilePassList = async (pageSize = 50, offset = 0, sortField, sortAsc, filters = [{}]) => {
        const defaultReturn = {
            count: 0,
            totalCount: 0,
            cards: [],
        };

        try {
            const gqlResponse = await this.query({
                query: GET_MOBILE_PASS_LIST_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sortField,
                    direction: sortAsc,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getMobilePassList', defaultReturn);
        } catch (e) {
            return this.handleExceptions(e, defaultReturn);
        }
    };

    getFaceprintsList = async (pageSize = 50, offset = 0, sortField = 'last_name', direction = 'ASC', filters = []) => {
        const defaultReturn = {
            count: 0,
            totalCount: 0,
            faceprints: [],
        };

        try {
            const gqlResponse = await this.query({
                query: GET_FACEPRINTS_LIST_QUERY,
                variables: {
                    pageSize,
                    offset,
                    sortField,
                    direction,
                    filters,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getUserFaceIdCredentials', defaultReturn);
        } catch (e) {
            return this.handleExceptions(e, defaultReturn);
        }
    };
    saveLiveVideoCustomLayout = async (layout) => {
        try {
            const response = await this.mutate({
                mutation: SAVE_LIVE_VIDEO_CUSTOM_LAYOUT_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    layout: layout,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.saveLiveVideoCustomLayout) {
                return get(response, 'data.saveLiveVideoCustomLayout', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    saveV2LiveVideoCustomLayout = async (layout) => {
        try {
            const response = await this.mutate({
                mutation: SAVE_LIVE_VIDEO_CUSTOM_LAYOUT_MUTATION_V2,
                fetchPolicy: 'no-cache',
                variables: {
                    layout: layout,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.saveV2LiveVideoCustomLayout) {
                return get(response, 'data.saveV2LiveVideoCustomLayout', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getLiveVideoCustomLayouts = async () => {
        try {
            const response = await this.query({
                query: GET_LIVE_VIDEO_CUSTOM_LAYOUTS_QUERY,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            });
            if (response.data && response.data.getLiveVideoCustomLayouts) {
                return get(response, 'data.getLiveVideoCustomLayouts', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getCamerasByLayout = async (layoutId, rows = MAX_PAGE_SIZE_GRAPHQL, offset = 0) => {
        try {
            const response = await this.query({
                query: GET_CAMERAS_BY_LAYOUT,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                variables: {
                    layoutId,
                    rows,
                    offset,
                    sortBy: 'cameraOrder',
                },
            });
            if (response.data?.getCamerasByLayout) {
                return get(response, 'data.getCamerasByLayout', {});
            } else if (response.errors) {
                console.error(response.errors[0].message);
                return {};
            }
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getVideoLayoutsList = async () => {
        try {
            const response = await this.query({
                query: GET_VIDEO_LAYOUTS_LIST,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            });
            if (response.data && response.data.getVideoLayoutsList) {
                return get(response, 'data.getVideoLayoutsList', []);
            } else if (response.errors) {
                console.error(response.errors[0].message);
                return [];
            }
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAllCameras = async (rows = 100, offset = 0, filters) => {
        filters = filters || [
            {
                field: 'name',
                type: 'field',
                value: '',
            },
        ];

        let result = { cameras: [], count: 0 };
        try {
            const response = await this.query({
                query: GET_ALL_CAMERAS,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                variables: {
                    rows,
                    offset,
                    filters,
                },
            });
            if (response.data && response.data.getAllCameras) {
                result.cameras = get(response, 'data.getAllCameras', []);
            }
            if (response.data && response.data.getAllCamerasCount) {
                result.count = get(response, 'data.getAllCamerasCount', 0);
            }
            if (response.errors) {
                console.error(response.errors[0].message);
            }
            return result;
        } catch (e) {
            return this.handleExceptions(e, result);
        }
    };

    getAllCamerasPaginatedWithCount = async (rows = 100, offset = 0, filters) => {
        filters = filters || [
            {
                field: 'name',
                type: 'field',
                value: '',
            },
        ];

        let result = { cameras: [], count: 0 };
        try {
            const response = await this.query({
                query: GET_ALL_CAMERAS_PAGINATED_WITH_COUNT,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                variables: {
                    rows,
                    offset,
                    filters,
                },
            });
            if (response.data && response.data.getDetailedCameras) {
                result.cameras = get(response, 'data.getDetailedCameras', []);
            }
            if (response.data && response.data.getAllCamerasCount) {
                result.count = get(response, 'data.getAllCamerasCount', 0);
            }
            if (response.errors) {
                console.error(response.errors[0].message);
            }
            return result;
        } catch (e) {
            return this.handleExceptions(e, result);
        }
    };

    addOccupancyConfig = async (occupancyConfig) => {
        try {
            const response = await this.mutate({
                mutation: ADD_OCCUPANCY_CONFIG_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    occupancyConfig: occupancyConfig,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.addOccupancyConfig) {
                return get(response, 'data.addOccupancyConfig', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    updateOccupancyConfig = async (occupancyConfig) => {
        try {
            const response = await this.mutate({
                mutation: UPDATE_OCCUPANCY_CONFIG_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    occupancyConfig: occupancyConfig,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.updateOccupancyConfig) {
                return get(response, 'data.updateOccupancyConfig', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    deleteOccupancyConfig = async (occupancyConfig) => {
        try {
            const response = await this.mutate({
                mutation: DELETE_OCCUPANCY_CONFIG_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    occupancyConfig: occupancyConfig,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.updateOccupancyConfig) {
                return get(response, 'data.deleteOccupancyConfig', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    updateDoorSchedules = async (doorSchedules) => {
        try {
            const gqlResponse = await this.client.mutate({
                mutation: UPDATE_DOOR_SCHEDULES,
                fetchPolicy: 'no-cache',
                variables: {
                    doorSchedules: doorSchedules,
                },
                errorPolicy: 'all',
            });
            if (gqlResponse.data && gqlResponse.data.updateDoorSchedules) {
                return get(gqlResponse, 'data.updateDoorSchedules', {});
            } else return get(gqlResponse, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    updateElevatorSchedules = async (elevatorSchedules) => {
        try {
            const gqlResponse = await this.client.mutate({
                mutation: UPDATE_ELEVATOR_SCHEDULES,
                fetchPolicy: 'no-cache',
                variables: {
                    elevatorSchedules,
                },
                errorPolicy: 'all',
            });
            if (gqlResponse.data && gqlResponse.data.updateElevatorSchedules) {
                return get(gqlResponse, 'data.updateElevatorSchedules', {});
            } else return get(gqlResponse, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    setIntrusionForAlula = async (panelId, armStatus) => {
        try {
            const gqlResponse = await this.client.mutate({
                mutation: SET_INTRUSION_FOR_ALULA,
                fetchPolicy: 'no-cache',
                variables: {
                    panelId,
                    armStatus,
                },
                errorPolicy: 'all',
            });
            if (gqlResponse.data && gqlResponse.data.setIntrusionForAlula) {
                return get(gqlResponse, 'data.setIntrusionForAlula', {});
            } else return get(gqlResponse, 'errors', {});
        } catch (e) {
            console.log(e);
            return this.handleExceptions(e, []);
        }
    };

    getFloorsForElevator = async (deviceOId) => {
        try {
            const gqlResponse = await this.query({
                query: GET_FLOORS_FOR_ELEVATOR,
                variables: {
                    deviceOId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getFloorsForElevator', {});
        } catch (e) {
            return this.handleExceptions(e, {});
        }
    };

    updateFloorSchedule = async (floorSchedule) => {
        try {
            const gqlResponse = await this.client.mutate({
                mutation: UPDATE_FLOOR_SCHEDULE,
                fetchPolicy: 'no-cache',
                variables: {
                    floorSchedules: floorSchedule,
                },
                errorPolicy: 'all',
            });
            if (gqlResponse.data && gqlResponse.data.updateFloorSchedules) {
                return get(gqlResponse, 'data.updateFloorSchedules', {});
            } else return get(gqlResponse, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getS3PresignedUrls = async (s3Objects) => {
        try {
            const gqlResponse = await this.query({
                query: PRESIGNED_URLS_FOR_S3_GET_QUERY,
                variables: {
                    s3Objects,
                },
                fetchPolicy: 'no-cache',
            });

            return get(gqlResponse, 'data.presignedUrlsForS3Get', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getCredentialFormats = async () => {
        try {
            const gqlResponse = await this.query({
                query: GET_CREDENTIAL_FORMATS_QUERY,
                fetchPolicy: 'no-cache',
            });

            return get(gqlResponse, 'data.getCredentialFormats', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getRecentActivity = async (actorId) => {
        try {
            const gqlResponse = await this.query({
                query: GET_RECENT_ACTIVITY,
                variables: {
                    actorId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getRecentActivity', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getUserIdMapping = async (userOId) => {
        try {
            const gqlResponse = await this.query({
                query: GET_USER_ID_MAPPING,
                variables: {
                    userOId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getUserIdMapping', null);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getOccupantNameAndImage = async (eventId) => {
        try {
            const gqlResponse = await this.query({
                query: GET_OCCUPANT_NAME_AND_IMAGE,
                variables: {
                    eventId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getOccupantNameAndImage', null);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    trackGqlQuery = (request) => {
        UserTracking.debugTrack(`Running GQL Query ${this.extractGqlRequestName(request?.query)}`);
    };

    trackGqlMutation = (request) => {
        UserTracking.debugTrack(`Running GQL Mutation ${this.extractGqlRequestName(request?.mutation)}`);
    };

    extractGqlRequestName = (request) => {
        try {
            return request?.definitions?.find((od) => od?.kind === 'OperationDefinition')?.name?.value || 'GqlRequest';
        } catch (err) {
            return 'GqlRequest';
        }
    };

    getCredentialInfo = async (accessCredentialId) => {
        let result = {};

        try {
            const response = await this.query({
                query: GET_CREDENTIAL_INFO,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                variables: {
                    accessCredentialId,
                },
            });

            if (response.data && response.data.getCredentialInfo) {
                result = response.data.getCredentialInfo;
            }

            if (response.errors) {
                console.error(response.errors[0].message);
            }

            return result;
        } catch (e) {
            return this.handleExceptions(e, result);
        }
    };

    getScheduleRelationships = async (scheduleId) => {
        try {
            const gqlResponse = await this.query({
                query: GET_SCHEDULE_RELATIONSHIPS,
                variables: {
                    scheduleId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getRelationshipsForSchedule', null);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getScheduleBlocksByIds = async (scheduleIds) => {
        try {
            const gqlResponse = await this.query({
                query: GET_SCHEDULE_BLOCKS_BY_IDS,
                variables: {
                    scheduleIds,
                },
                fetchPolicy: 'no-cache',
            });
            return get(gqlResponse, 'data.getScheduleBlocksByIds', null);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getDoorStationNotificationsById = async (doorStationId) => {
        try {
            const response = await this.query({
                query: GET_DOOR_STATION_NOTIFICATIONS_BY_IDS_QUERY,
                variables: {
                    doorStationId,
                },
                fetchPolicy: 'no-cache',
            });
            return get(response, 'data.getDoorStationNotificationsById', []);
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getAiVoiceAssistantByBdsId = async (doorStationId) => {
        try {
            const response = await this.query({
                query: GET_AI_VOICE_ASSISTANT_BY_BDS_ID_QUERY,
                variables: {
                    doorStationId,
                },
                fetchPolicy: 'no-cache',
            });

            if (response.data && response.data.getAiVoiceAssistantByBdsId) {
                return get(response, 'data.getAiVoiceAssistantByBdsId', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    setAiVoiceAssistantForBdsId = async (doorStationId, aiAssistantEnabled) => {
        try {
            const gqlResponse = await this.client.mutate({
                mutation: SET_AI_VOICE_ASSISTANT_FOR_BDS_ID_QUERY,
                variables: {
                    doorStationId,
                    aiAssistantEnabled,
                },
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            });

            if (gqlResponse.data && gqlResponse.data.setAiVoiceAssistantForBdsId) {
                return get(gqlResponse, 'data.setAiVoiceAssistantForBdsId', {});
            } else return get(gqlResponse, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    updateDoorStationNotifications = async (doorStationNotificationInput) => {
        try {
            const gqlResponse = await this.client.mutate({
                mutation: UPDATE_DOOR_STATION_NOTIFICATIONS,
                fetchPolicy: 'no-cache',
                variables: {
                    doorStationNotificationInput: doorStationNotificationInput,
                },
                errorPolicy: 'all',
            });
            if (gqlResponse.data && gqlResponse.data.updateDoorStationNotifications) {
                return get(gqlResponse, 'data.updateDoorStationNotifications', {});
            } else return get(gqlResponse, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    deleteDoorStationNotifications = async (id) => {
        try {
            const response = await this.mutate({
                mutation: DELETE_DOOR_STATION_NOTIFICATIONS_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    id: id,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.deleteDoorStationNotifications) {
                return get(response, 'data.deleteDoorStationNotifications', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    saveUnifiedDashboardLayout = async (unifiedDashboard) => {
        try {
            const response = await this.mutate({
                mutation: SAVE_UNIFIED_DASHBOARD_LAYOUT_MUTATION,
                fetchPolicy: 'no-cache',
                variables: {
                    unifiedDashboard: unifiedDashboard,
                },
                errorPolicy: 'all',
            });
            if (response.data && response.data.saveUnifiedDashboardLayout) {
                return get(response, 'data.saveUnifiedDashboardLayout', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            console.log('errors', e);
            return this.handleExceptions(e, []);
        }
    };

    getUnifiedDashboardLayouts = async (rows = 100, offset = 0) => {
        try {
            const response = await this.query({
                query: GET_ALL_PAGINATED_DASHBOARDS,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                variables: {
                    rows,
                    offset,
                },
            });
            if (response.data && response.data.getPaginatedDashboards) {
                return get(response, 'data.getPaginatedDashboards', {});
            } else return get(response, 'errors', {});
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };

    getSectionsForUnifiedDashboard = async (dashboardId) => {
        try {
            const response = await this.query({
                query: GET_SECTIONS_BY_DASHBOARD,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                variables: {
                    dashboardId: dashboardId,
                },
            });
            if (response.data?.getSections) {
                return get(response, 'data.getSections', {});
            } else if (response.errors) {
                console.error(response.errors[0].message);
                return {};
            }
        } catch (e) {
            return this.handleExceptions(e, []);
        }
    };
}
