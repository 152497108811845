import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        categoryTime: {
            height: '18px',
            color: ({ isOpen }) =>
                isOpen ? theme.palette.colors.blue400 : theme.palette.brivoAliases.timeBinHourColor,
            fontStyle: 'normal',
            fontWeight: ({ isOpen }) => (isOpen ? 600 : 400),
            lineHeight: '18px',
        },
        inlineDiv: {
            display: 'inline-block',
            paddingRight: '10px',
        },
    };
});

export default styles;
