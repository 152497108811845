import gql from 'graphql-tag';

export const GET_PROPERTY_COMMON_GROUPS = gql`
    query getPropertyCommonGroups($siteOid: Int!) {
        getPropertyLinkedGroups(siteOid: $siteOid) {
            commonOnline {
                id
            }
            commonOffline {
                id
            }
        }
    }
`;

export const GET_PROPERTY_MAIN_GROUP = gql`
    query getPropertyCommonGroups($siteOid: Int!) {
        getPropertyLinkedGroups(siteOid: $siteOid) {
            mainOnline {
                id
            }
        }
    }
`;

export const GET_PROPERTY_COMMON_ONLINE_GROUP = gql`
    query getPropertyCommonGroups($siteOid: Int!) {
        getPropertyLinkedGroups(siteOid: $siteOid) {
            commonOnline {
                id
            }
        }
    }
`;

export const CHECK_GROUP_LINKING = gql`
    query checkGroupLinking($groupOid: Int!) {
        checkGroupLinking(groupOid: $groupOid)
    }
`;
