import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            borderRadius: '10px',
        },
        header: {
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : '',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        descriptionText: {
            marginBottom: '4px',
            color: theme.palette.type === 'dark' ? theme.palette.colors.grey300 : '',
        },
        viewKPIOptionsIconDisabled: {
            pointerEvents: 'none',
        },
        metricCheckbox: {
            '& > .CheckboxField': {
                display: 'flex',
                justifyContent: 'flex-end',
            },
        },
        titleTooltipIcon: {
            display: 'inline-block',
            verticalAlign: 'middle',
            maxWidth: '100%',
        },
        kpiAndTrendDiv: {
            display: 'flex',
            alignItems: 'baseline',
            gap: '.5rem',
            alignSelf: 'stretch',
        },
    };
});

export default useStyles;
