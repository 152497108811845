import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        eventsPanel: {
            maxHeight: '594px',
            overflowY: 'scroll',
        },
        dateHeader: {
            color: theme.palette.brivoAliases.primaryTextColor,
            height: '53px',
            padding: '16px 24px',
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '21px',
        },
        accordion: {
            paddingTop: 0,
            paddingBottom: 0,
            borderTop: `1px solid ${
                theme.palette.type === 'dark' ? theme.palette.colors.grey700 : theme.palette.colors.grey200
            }`,
            borderImage: 'none',
        },
        accordionSummary: {
            minHeight: '40px',
        },
        accordionExpandIcon: {
            height: '30px !important',
            width: '30px !important',
            color: theme.palette.colors.grey400,
        },
    };
});

export default styles;
