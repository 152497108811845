import React, { Suspense, lazy } from 'react';

const CustomFieldsPage = lazy(() => import('./CustomFieldsPage'));

const CustomFieldsContainer = (props) => (
    <Suspense fallback={null}>
        <CustomFieldsPage {...props} />
    </Suspense>
);

export default CustomFieldsContainer;
