import React from 'react';
import createSvgIcon from '../../../../../common/utils/createSvgIcon';

export const BrivoEye = createSvgIcon(
    <>
        <svg viewBox="0 0 108 109" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.122 109C83.779 109 107.969 84.5169 107.969 54.5C107.969 24.4831 83.779 0 54.122 0C24.4649 0 0.275391 24.4831 0.275391 54.5C0.275391 84.5169 24.4649 109 54.122 109ZM53.9564 96.7606C30.9266 96.7606 12.2046 77.8114 12.2046 54.5022C12.2046 31.1929 30.9266 12.2437 53.9564 12.2437C76.9862 12.2437 95.7082 31.1929 95.7082 54.5022C95.7082 77.8114 76.9862 96.7606 53.9564 96.7606Z"
                fill="#739DD2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.0324 101.791C88.2515 101.791 103.826 86.028 103.826 66.5757C103.826 47.1234 88.2515 31.528 69.0324 31.3604C49.8133 31.3604 34.2393 47.1234 34.2393 66.5757C34.2393 86.028 49.8133 101.791 69.0324 101.791ZM69.0327 89.2141C56.7722 89.2141 46.6656 79.1525 46.6656 66.5756C46.6656 53.9987 56.6065 43.9372 69.0327 43.9372C81.2931 43.9372 91.3997 53.9987 91.3997 66.5756C91.3997 78.9848 81.4588 89.2141 69.0327 89.2141Z"
                fill="#739DD2"
            />
        </svg>
    </>
);
