import BrowserNotifications from './BrowserNotifications';
import CallNotifications from './CallNotifications/CallNotifications';
import { useWebSocketMessage } from '../../hooks/useSocket';
import { CALL_EVENT, GLOBAL_SCOPE, NOTIFICATION_EVENT } from '../../constants/Constants';

const GlobalNotifications = () => {
    const { messages } = useWebSocketMessage({ scope: GLOBAL_SCOPE, eventTypes: [CALL_EVENT, NOTIFICATION_EVENT] });

    return (
        <>
            <CallNotifications messages={messages} />
            <BrowserNotifications messages={messages} />
        </>
    );
};

export default GlobalNotifications;
