import { useEffect, useState } from 'react';
import { integrationsPmsApi } from '@common/webApis/integrations/pms/integrationsPmsApi';

export const useVerifyPmsIntegration = (pmsInAccessFlag, multifamilySupportFlag, multifamilySupportEnabled) => {
    const [shouldCheckPmsEntity, setShouldCheckPmsEntity] = useState(false);
    useEffect(() => {
        if (pmsInAccessFlag && multifamilySupportEnabled && multifamilySupportFlag) {
            const checkPmsIntegrations = async () => {
                const supportedIntegrationsResult = await integrationsPmsApi.getIntegrationsPMSVendors();
                if (supportedIntegrationsResult && supportedIntegrationsResult?.count > 0) {
                    const hasActiveIntegrations = supportedIntegrationsResult.results.some(
                        (integration) => integration?.enabled
                    );
                    setShouldCheckPmsEntity(hasActiveIntegrations);
                }
            };
            checkPmsIntegrations();
        }
    }, [multifamilySupportEnabled, multifamilySupportFlag, pmsInAccessFlag]);
    return { shouldCheckPmsEntity };
};
