import React, { useContext, useEffect, useState } from 'react';
import { Grid, InputLabel } from '@brivo/react-components';
import { AutocompleteSelectField } from '@brivo/react-components';
import { sitesApi } from '@common/webApis';
import { useTranslation } from 'react-i18next';
import { GQLProviderContext } from '@common/utils/CustomApolloProvider';
import useStyles from './styles';

const PropertySelector = ({ selectedSite, setSelectedSite, inputLabel, type = 'all' }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const gqlHelper = useContext(GQLProviderContext);

    const [sites, setSites] = useState([]);

    useEffect(() => {
        const getSites = async () => {
            const result = await sitesApi.getSitesWithFiltering({
                rows: null,
                offset: null,
                filters: { name: '', siteType: type, hasNoChildren: false },
            });

            setSites(result?.getPaginatedProperties?.properties);
        };

        getSites();
    }, [gqlHelper, type]);

    const handleChange = (event, newValue) => {
        setSelectedSite(newValue);
    };

    return (
        <Grid item className={classes.root}>
            <InputLabel htmlFor="site" className="u-mb-half">
                {inputLabel}
            </InputLabel>
            <AutocompleteSelectField
                id="site"
                options={sites}
                selectedOption={selectedSite}
                handleChange={handleChange}
                placeholder={t('Page.property.details.devices-section.device-card.property-list.placeholder.search')}
                disableClearable={false}
            />
        </Grid>
    );
};

export default PropertySelector;
