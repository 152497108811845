import { SET_TOKEN } from '../types/Auth.types';

export const authReducer = (state = null, action) => {
    const { payload } = action;

    switch (action.type) {
        case SET_TOKEN:
            return payload;

        default:
            return state;
    }
};

export default authReducer;
