import React, { useEffect, useCallback, useState } from 'react';
import { INACTIVITY_ALERT_TIMEOUT, INACTIVITY_EXTRA_TIMEOUT, ONE_MINUTE } from '../../../constants/Constants';
import IdleTimer from 'react-idle-timer';
import Countdown from 'react-countdown';
import { Dialog, DialogActions, DialogContent } from '@brivo/react-components';

import { Button } from '@brivo/react-components';

import UserTracking from '../../../utils/UserTracking';
import { useFlagClient } from '@brivo/onairplus-services';

const InactivityTimer = ({ auth }) => {
    const [open, setOpen] = useState(false);
    const flagClient = useFlagClient();
    const inactivityTimeoutFlag = flagClient.variation('inactivity-timeout');
    let timeoutValue = INACTIVITY_ALERT_TIMEOUT;

    if (inactivityTimeoutFlag && inactivityTimeoutFlag !== -1) {
        timeoutValue = inactivityTimeoutFlag * ONE_MINUTE;
    } else {
        timeoutValue = inactivityTimeoutFlag;
    }

    const doLogout = useCallback(async () => {
        try {
            UserTracking.log('performing a logout');
            auth.logout();
        } catch (e) {
            console.log(e.message);
        }
    }, [auth]);

    const handleOnIdle = useCallback(() => {
        if (!auth.isAuthenticated()) {
            UserTracking.log('User is idle and not authenticated and will log out');
            UserTracking.track('Idle No Auth Logout');
            return doLogout();
        }
        UserTracking.track('Idle Timeout Warning');
        setOpen(true);
    }, [auth, doLogout]);

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            UserTracking.log('Timeout completed and user will be logged out');
            UserTracking.track('Timeout Logout');
            doLogout();

            return null;
        } else {
            return (
                <span>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
            );
        }
    };

    const renewSession = useCallback(async () => {
        UserTracking.log('Renewing session');
        UserTracking.track('InactivityTimer session renewal');
        if (auth.isAuthenticated()) {
            UserTracking.log('Authenticated during renewal');
            try {
                await auth.renewSession();
                setOpen(false);
            } catch (error) {
                UserTracking.log('user is not authenticated so we cannot renew the session and will log out');
                doLogout();
            }
        }
    }, [auth, doLogout]);

    useEffect(() => {
        const listener = (e) => {
            if (e.storageArea === localStorage && e.key === 'expiresAt' && e.newValue === 'null') {
                UserTracking.log('local storage cleared expires at and will log out');
                UserTracking.track('Inactivity Local Storage change timeout');
                doLogout();
            }
        };

        window.addEventListener('storage', listener);

        return () => window.removeEventListener('storage', listener);
    }, [doLogout]);

    return timeoutValue !== -1 ? (
        <>
            {!open && <IdleTimer element={document} onIdle={handleOnIdle} timeout={timeoutValue} />}
            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <div className="InactivityTimer">
                    <DialogContent className=" u-pa-full u-fx u-fx-column u-fx-align-center">
                        <div className="u-mb-double">
                            Are you still there? If not, you’ll be logged out in{' '}
                            {
                                <Countdown
                                    renderer={renderer}
                                    precision={2}
                                    date={Date.now() + INACTIVITY_EXTRA_TIMEOUT}
                                />
                            }
                            .
                        </div>
                        <DialogActions>
                            <Button id="logout-button" type="secondary" onClick={() => doLogout()} text="Logout" />
                            <Button
                                id="keep-me-logged-in-button"
                                onClick={() => renewSession()}
                                type="primary"
                                text="Keep Me Logged In"
                            />
                        </DialogActions>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    ) : null;
};

export default React.memo(InactivityTimer);
