import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { unionBy } from 'lodash';
import clsx from 'clsx';

import {
    ErrorDialog,
    LoadingIndicator,
    LockdwonRoundedIcon,
    SingleSelectField,
    Snackbar,
} from '@brivo/react-components';
import { useFlagClient, withApi } from '@brivo/onairplus-services';

import { getEmergencyScenarios } from '../../../../../../../pages/LockdownScenarios/LockdowScenarioPage/helpers';
import {
    buildScenariosGqlFilter,
    initialScenariosFiltersValue,
} from '../../../../../../../pages/LockdownScenarios/LockdownScenariosContainer';
import { ALL_SCENARIOS_FILTER_ENABLED, APP_SCENARIOS_URL } from '../../../../../../constants/Constants';
import { LOCKDOWN_CLEAR_LOCKDOWN, LOCKDOWN_INITIATE_LOCKDOWN } from '../../../../../../permissions/Permissions';
import { lockdownApi, usersApi } from '../../../../../../webApis';
import { usePermissions } from '../../../../../../hooks/usePermissions';
import { ID_FOR_FULL_LOCKDOWN, MAX_NUMBER_OF_DOORS } from '../../../../../../../pages/Lockdown/Lockdown';
import { UserContext } from '../../../../../../user/UserProvider';
import ApiHelper from '../../../../../../helpers/Helpers';
import styles from './styles';

const PAGE_SIZE = 100;
const SORT_FIELD = 'type';
const DIRECTION = 'ASC';

const ActivateEmergencyScenarios = ({ toggleActivateES, isActivateESDialogOpen, fetchIsLockdownActive, api }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const notifications = Snackbar();
    const flagClient = useFlagClient();
    const { hasPermissions } = usePermissions();
    const userContext = useContext(UserContext);
    const userCanInitiateScenario = hasPermissions(LOCKDOWN_INITIATE_LOCKDOWN);
    const userCanClearScenario = hasPermissions(LOCKDOWN_CLEAR_LOCKDOWN);
    const enableLockdownAssignments = flagClient.variation('enable-lockdown-assignments', false);
    const useNewEmergencyEndpoints = flagClient.variation('new-emergency-scenarios-endpoints', false);
    const classes = styles();

    const lockdownAssignments = userContext?.assignments?.lockdowns;

    const [scenarios, setScenarios] = useState([]);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [activeScenarios, setActiveScenarios] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [activationInitiated, setActivationInitated] = useState(false);

    const [allEmergencyScenarios, setAllEmergencyScenarios] = useState([]);
    const [counts, setCounts] = useState({
        count: -1,
        totalCount: 0,
        offset: 0,
    });
    const [allScenariosLoaded, setAllScenariosLoaded] = useState(false);

    const mapper = (item) => ({ id: item.id, name: item.name });
    const isAllLockdownAssignments = lockdownAssignments?.isAll;
    const shouldShowDeactivationButton = !isAllLockdownAssignments || !userContext?.assignments?.sites?.isAll;

    const fetchScenarioData = useCallback(
        async (allEmergencyScenarios) => {
            const queryParamsAllScenarios = {
                filter: ALL_SCENARIOS_FILTER_ENABLED,
            };

            let partialLockdownAssignments;
            if (!isAllLockdownAssignments) {
                partialLockdownAssignments = lockdownAssignments?.assignments?.map((el) => el?.lockdown?.id);
            }

            try {
                const activeScenarios = await lockdownApi.getAllActiveScenarios({
                    queryParams: queryParamsAllScenarios,
                });
                const allActiveScenarios = activeScenarios.data;

                const inactiveScenarios = !isAllLockdownAssignments
                    ? allEmergencyScenarios
                          ?.filter((scenario) => allActiveScenarios.every((active) => active.id !== scenario.id))
                          .filter((scenario) => partialLockdownAssignments.includes(scenario?.id))
                    : allEmergencyScenarios?.filter((scenario) =>
                          allActiveScenarios.every((active) => active.id !== scenario.id)
                      );

                const { identityType } = await usersApi.getCurrentUser();

                const response = await lockdownApi.isLessThanTotalDoors(MAX_NUMBER_OF_DOORS);
                const isLessThanTotalDoors = response.isLessThanTotalDoors;

                const fullLockdownScenario = {
                    id: ID_FOR_FULL_LOCKDOWN,
                    name: t('Page.lockdown.scenario.default'),
                };

                const defaultScenario = allEmergencyScenarios?.filter((scenario) => scenario.isDefault);
                const isDefaultScenarioActive = allActiveScenarios?.find((scenario) => scenario.isDefault);
                const isFullLockdownActive = allActiveScenarios?.find(
                    (scenario) => scenario.fullLockdownForEntireAccount
                );

                const isFullLockdownDisplayed = enableLockdownAssignments
                    ? defaultScenario.length === 0 &&
                      isAllLockdownAssignments &&
                      !isFullLockdownActive &&
                      isLessThanTotalDoors &&
                      identityType !== 'ASSISTANT'
                    : defaultScenario.length === 0 && !isFullLockdownActive && identityType !== 'ASSISTANT';

                const isDefaultLockdownDisplayed = !isDefaultScenarioActive && defaultScenario.length > 0;

                const selectedScenario = isFullLockdownDisplayed
                    ? fullLockdownScenario
                    : isDefaultLockdownDisplayed
                    ? defaultScenario[0]
                    : inactiveScenarios[0];

                if (isFullLockdownDisplayed && isLessThanTotalDoors) {
                    inactiveScenarios?.unshift(fullLockdownScenario);
                }

                setScenarios(inactiveScenarios);
                setSelectedScenario(selectedScenario?.id);
                setActiveScenarios(allActiveScenarios);
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                setError(true);
            }
        },
        [enableLockdownAssignments, t, isAllLockdownAssignments, lockdownAssignments]
    );

    useEffect(() => {
        setLoading(true);
        if (isActivateESDialogOpen) {
            if (counts.count < counts.totalCount) {
                getEmergencyScenarios(
                    PAGE_SIZE,
                    counts.offset,
                    SORT_FIELD,
                    DIRECTION,
                    buildScenariosGqlFilter(initialScenariosFiltersValue),
                    true,
                    t
                )
                    .then((response) => {
                        setAllEmergencyScenarios((allEmergencyScenarios) =>
                            allEmergencyScenarios.concat(response.lockdownScenarios)
                        );
                        setCounts((counts) => ({
                            count:
                                counts.count === -1
                                    ? response.lockdownScenarios?.length
                                    : counts.count + response.lockdownScenarios?.length,
                            totalCount: response.totalCount,
                            offset: counts.offset + PAGE_SIZE,
                        }));
                    })
                    .catch((e) => {
                        console.error(e);
                        setError(true);
                        setLoading(false);
                    });
            } else {
                if (!allScenariosLoaded) {
                    setAllScenariosLoaded(true);
                }
            }
        }
    }, [counts, allScenariosLoaded, isActivateESDialogOpen, t]);

    useEffect(() => {
        if (allScenariosLoaded) {
            fetchScenarioData(allEmergencyScenarios);
        }
    }, [isActivateESDialogOpen, fetchScenarioData, allScenariosLoaded, allEmergencyScenarios]);

    useEffect(() => {
        if (error) {
            notifications.addErrorMessage({ text: t('Dialog.Activate-Emergency-Scenarios.notification.error') });
            toggleActivateES(false);
        }
    }, [error, notifications, toggleActivateES, t]);

    const handleScenarioChange = (e, scenario) => {
        setSelectedScenario(scenario.props.value);
    };

    const handleGoToScenarios = () => {
        history.push(APP_SCENARIOS_URL);
        toggleActivateES(false);
    };

    const handleClearScenarios = async () => {
        try {
            setLoading(true);
            const queryParamsAllScenarios = {
                filter: ALL_SCENARIOS_FILTER_ENABLED,
            };

            const activeScenarios = await lockdownApi.getAllActiveScenarios({
                queryParams: queryParamsAllScenarios,
            });
            const allActiveScenarios = unionBy(activeScenarios?.data, 'id');

            if (allActiveScenarios.length === 0) {
                notifications.addNewWarningMessage('Page.lockdown.bottom-bar-no-active-lockdowns-to-clear');
                return;
            }

            const response = useNewEmergencyEndpoints
                ? await api.getClearedLockdownsNew(allActiveScenarios)
                : await api.loopRequest(allActiveScenarios, api.clearLockdownScenario, 'id');

            const failedScenarios = response.filter((scenario) => scenario.apiCallStatus !== 200);
            const clearedScenarios = response.filter((scenario) => scenario.apiCallStatus === 200);

            const allWereCleared = failedScenarios.length === 0;
            const allFailed = clearedScenarios.length === 0;
            const someFailedSomeNot = failedScenarios.length > 0 && clearedScenarios.length > 0;

            if (allWereCleared) {
                notifications.addSuccessMessage({ text: t('Page.emergency-scenarios.bottom-bar-successful-clear') });
                toggleActivateES(false);
                fetchIsLockdownActive();
                return;
            }

            if (allFailed) {
                notifications.addErrorMessage({ text: t('Page.emergency-scenarios.bottom-bar-fail-clear') });
                toggleActivateES(false);
                return;
            }

            if (someFailedSomeNot) {
                const failedScenariosNames = failedScenarios
                    .map((scenario) =>
                        scenario.fullLockdownForEntireAccount ? t('Page.lockdown.scenario.default') : scenario.name
                    )
                    .join(', ');
                const clearedScenariosNames = clearedScenarios
                    .map((scenario) =>
                        scenario.fullLockdownForEntireAccount ? t('Page.lockdown.scenario.default') : scenario.name
                    )
                    .join(', ');

                if (clearedScenarios.length === 1) {
                    notifications.addSuccessMessage({
                        text: (
                            <span className={classes.snackbar}>
                                {t('Page.emergency-scenarios.bottom-bar.partial-success.one', {
                                    field: clearedScenariosNames,
                                })}
                            </span>
                        ),
                        isSticky: true,
                    });
                } else {
                    notifications.addSuccessMessage({
                        text: (
                            <span>
                                {t('Page.emergency-scenarios.bottom-bar.partial-success', {
                                    number: clearedScenarios.length,
                                })}
                                <span className={clsx('u-trim', classes.snackbar)} title={clearedScenariosNames}>
                                    {clearedScenariosNames}
                                </span>
                            </span>
                        ),
                        isSticky: true,
                    });
                }

                if (failedScenarios.length === 1) {
                    notifications.addErrorMessage({
                        text: (
                            <span className={classes.snackbar}>
                                {t('Page.emergency-scenarios.bottom-bar.partial-failure.one', {
                                    field: failedScenariosNames,
                                })}
                            </span>
                        ),
                        isSticky: true,
                    });
                } else {
                    notifications.addErrorMessage({
                        text: (
                            <span>
                                {t('Page.emergency-scenarios.bottom-bar.partial-failure', {
                                    number: failedScenarios.length,
                                })}
                                <span className={clsx('u-trim', classes.snackbar)} title={failedScenariosNames}>
                                    {failedScenariosNames}
                                </span>
                            </span>
                        ),
                        isSticky: true,
                    });
                }

                toggleActivateES(false);
                fetchIsLockdownActive();
            }
        } catch (e) {
            console.error(e);
            notifications.addErrorMessage({ text: t('Page.emergency-scenarios.bottom-bar-fail-clear') });
            toggleActivateES(false);
        }
    };

    const handleActivateScenario = async (selectedScenario) => {
        setActivationInitated(true);
        const scenarioObj = scenarios.find((scenario) => scenario.id === selectedScenario);

        try {
            const response =
                selectedScenario === ID_FOR_FULL_LOCKDOWN
                    ? useNewEmergencyEndpoints
                        ? await lockdownApi.initiateFullLockdownNew()
                        : await lockdownApi.initiateFullLockdown()
                    : useNewEmergencyEndpoints
                    ? await lockdownApi.initiateLockdownNew(scenarioObj)
                    : await lockdownApi.initiateLockdown(selectedScenario);

            if (!response || response.didAllFail) {
                notifications.addErrorMessage({ text: t('Page.emergency-scenarios.failed-activated') });
            } else {
                notifications.addSuccessMessage({ text: t('Page.emergency-scenarios.successfully-activated') });
            }
            toggleActivateES(false);
            fetchIsLockdownActive();
        } catch (e) {
            console.error(e);
            notifications.addErrorMessage({ text: t('Page.emergency-scenarios.failed-activated') });
            toggleActivateES(false);
        }
    };

    const buttons = () => {
        if (shouldShowDeactivationButton) {
            if (userCanClearScenario) {
                return {
                    primaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-primary.text'),
                    onPrimaryClick: () => handleActivateScenario(selectedScenario),
                    primaryDisabled: loading || activationInitiated || !scenarios?.length,
                    secondaryActionText: t('Page.emergency-scenarios.clear-all-emergency-scenarios'),
                    secondaryDisabled: !activeScenarios?.length || loading || activationInitiated,
                    onSecondaryClick: handleClearScenarios,
                    tertiaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-tertiary.text'),
                    onTertiaryClick: () => toggleActivateES(false),
                };
            } else {
                return {
                    primaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-primary.text'),
                    onPrimaryClick: () => handleActivateScenario(selectedScenario),
                    primaryDisabled: loading || activationInitiated || !scenarios?.length,
                    secondaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-tertiary.text'),
                    onSecondaryClick: () => toggleActivateES(false),
                    secondaryDisabled: loading,
                };
            }
        } else {
            if (userCanInitiateScenario) {
                return {
                    primaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-primary.text'),
                    onPrimaryClick: () => handleActivateScenario(selectedScenario),
                    primaryDisabled: loading || activationInitiated || !scenarios?.length,
                    secondaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-secondary.text'),
                    secondaryDisabled: loading || activationInitiated,
                    onSecondaryClick: handleGoToScenarios,
                    tertiaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-tertiary.text'),
                    onTertiaryClick: () => toggleActivateES(false),
                };
            } else {
                return {
                    primaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-secondary.text'),
                    primaryDisabled: loading || activationInitiated,
                    onPrimaryClick: handleGoToScenarios,
                    secondaryActionText: t('Dialog.Activate-Emergency-Scenarios.button-tertiary.text'),
                    onSecondaryClick: () => toggleActivateES(false),
                };
            }
        }
    };

    return (
        <ErrorDialog
            icon={LockdwonRoundedIcon}
            title={t('Dialog.Activate-Emergency-Scenarios.title')}
            open={isActivateESDialogOpen}
            {...buttons()}
            onClose={() => toggleActivateES(false)}
            children={
                loading ? (
                    <LoadingIndicator />
                ) : (
                    <SingleSelectField
                        label={t('Dialog.Activate-Emergency-Scenarios.single-select.label')}
                        id="activate-scenario-listing"
                        selectedOption={selectedScenario || ''}
                        options={scenarios.map((scenario) => mapper(scenario))}
                        handleChange={handleScenarioChange}
                        mapper={mapper}
                        name={'activate-scenario-listing'}
                        helperText={
                            !scenarios?.length
                                ? t('Dialog.Activate-Emergency-Scenarios.single-select.helper-text')
                                : null
                        }
                        isDisabled={!scenarios?.length}
                    />
                )
            }
        />
    );
};

export default withApi(ActivateEmergencyScenarios, ApiHelper);
