import React from 'react';
import { makeStyles } from '@brivo/react-components';
import { useTranslation } from 'react-i18next';
import { Button, AddItemIcon, RemoveIcon, Table, Tooltip } from '@brivo/react-components';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';

const useStyles = makeStyles(() => ({
    content: {
        margin: '15px 0',
    },
    table: {
        height: '180px !important',
        overflow: 'hidden',
    },
    loading: {
        marginBottom: -32,
    },
    itemName: {
        maxWidth: 165,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
}));

const ItemsList = ({
    allItems,
    isLoading,
    loadNextPage,
    selectItem,
    deselectItem,
    currentSelectedItems,
    headers,
    allItemsCount,
    maximumLimitReached,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isChecked = (selection, id) => {
        if (selection) {
            return selection.findIndex((cam) => cam.id === id) >= 0;
        }
        return false;
    };

    const renderItemCell = (original) => {
        const item = <p className={clsx(classes.itemName)}>{original.name}</p>;
        return (
            <div data-testid="item-name">
                {original.name.length > 20 ? (
                    <Tooltip title={original.name} placement="top" size="medium">
                        {item}
                    </Tooltip>
                ) : (
                    item
                )}
            </div>
        );
    };
    const itemCell = (context, loadNextPage) => {
        const {
            row: { index, original },
            rows,
        } = context;

        const rowsNum = rows.length;
        const item = renderItemCell(original);
        if (rowsNum - 1 === index && allItems.length < allItemsCount) {
            return (
                <>
                    {item}
                    <Waypoint key="loadMore" onEnter={loadNextPage} fireOnRapidScroll />
                </>
            );
        }
        return item;
    };

    const renderAssignButton = (context) => {
        const {
            row: { original },
        } = context;

        if (isChecked(currentSelectedItems, original.id)) {
            return (
                <Button
                    type="tertiary-error"
                    size="small"
                    onClick={() => deselectItem(original)}
                    disabled={isLoading}
                    id={`assigned-item-${original.id}`}
                    startIcon={<RemoveIcon />}
                    text={t('Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.button.selected')}
                />
            );
        } else {
            return (
                <Button
                    type="tertiary"
                    size="small"
                    onClick={() => selectItem(original)}
                    startIcon={<AddItemIcon />}
                    disabled={isLoading || maximumLimitReached}
                    id={`assign-item-${original.id}`}
                    text={t('Page.lockdown-scenarios.lockdown-scenario-details.page.select-dialog.button.deselected')}
                />
            );
        }
    };
    const createCell = (className, content) => {
        const item = <span className={className}>{content}</span>;
        return content.length > 20 ? (
            <Tooltip title={content} placement="top" size="medium">
                {item}
            </Tooltip>
        ) : (
            item
        );
    };
    const columns = [
        {
            Header: headers[0],
            id: 'name',
            Cell: (context) => {
                return itemCell(context, loadNextPage);
            },
        },
        {
            Header: headers[1],
            id: 'panel',
            Cell: (context) => {
                return createCell(classes.itemName, context.row.original.controlPanelName);
            },
        },
        {
            Header: headers[2],
            id: 'site',
            Cell: (context) => {
                return createCell(classes.itemName, context.row.original.siteName);
            },
        },
        {
            id: 'assign',
            Cell: (context) => {
                return createCell(classes.button, renderAssignButton(context));
            },
        },
    ];

    return (
        <div className={classes.content}>
            <Table
                data={allItems}
                columns={columns}
                tableContainerProps={{
                    'data-test-id': 'table-container',
                    className: classes.table,
                }}
                heightFunction={() => {
                    return 180;
                }}
                hasClickableRows={false}
                clickToSortMessage={t('Table.column.header.sort')}
            />
        </div>
    );
};

export default ItemsList;
