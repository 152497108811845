import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => {
    return {
        tableHeader: {
            paddingTop: '1.5rem',
            paddingBottom: '.5rem',
        },
        noPaddingTableHeader: {
            paddingTop: '0rem',
            paddingBottom: '.5rem',
        },
        iconMenu: {
            float: 'right',
        },
        iconButtonDisabled: {
            '& .MuiSvgIcon-root': {
                fill: theme.palette.type === 'dark' ? theme.palette.colors.grey600 : theme.palette.colors.grey300,
            },
        },
    };
});

export default useStyles;
