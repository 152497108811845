import SecurityActionMessages from './SecurityActionMessages';
import { get } from 'lodash';

export const REQUEST_OPTIONS = {
    method: 'GET',
    withCredentials: true,
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

export const isProduction = get(window, 'brivoAccess.VITE_DEPLOY_ENV' === 'prod', import.meta.env.PROD);
const isStorybook = process.env.STORYBOOK;

export const DEPLOY_ENV = envVar('VITE_DEPLOY_ENV');

export const RESTRICT_THIRD_PARTY_USER_DATA = envVar('VITE_RESTRICT_THIRD_PARTY_USER_DATA') === 'true';

export const ALT_AUTH_TESTING = envVar('VITE_ALT_AUTH_TESTING');

export const AWS_APPSYNC_GRAPHQL_URL = envVar('VITE_AWS_APPSYNC_GRAPHQL_URL');
export const AWS_APPSYNC_REGION = envVar('VITE_AWS_APPSYNC_REGION');
export const AWS_APPSYNC_AUTH_TYPE = envVar('VITE_AWS_APPSYNC_AUTH_TYPE');
export const ONE_MINUTE = 60000;
export const ONE_HOUR = 3.6e6;

// Show inactivity dialog after 10 minutes (expressed in ms)
export const INACTIVITY_ALERT_TIMEOUT = 10 * ONE_MINUTE;
// Allow user to spend 5 more minutes in the app, after original inactivity dialog message (expressed in ms)
export const INACTIVITY_EXTRA_TIMEOUT = 5 * ONE_MINUTE;

export const MAP_PANELS_REFRESH_INTERVAL = ONE_MINUTE;

export const CLEARED_EVENTS_CHECK_INTERVAL = 2 * ONE_MINUTE;

export const HOME_DASHBOARD_EVENTS_CLEANUP_INTERVAL = 5 * ONE_MINUTE;

export const ACCESS_TOKEN_KEY = `com.brivo.${DEPLOY_ENV}.dashboard.accessToken`;

export const APP_BASENAME = '';
export const API_BASE_URL = '/api';

// APP URLs
export const PATH_SHARED_ACCESS = '/shared-access';
export const PATH_UNIFIED_VIDEO_INTEGRATIONS = '/video-integrations';
export const CREATE_CUSTOM_FIELD = 'create-custom-field';

export const APP_ROOT_URL = `/`;
export const APP_HOME_URL = `${APP_BASENAME}/home`;

export const APP_CONSENT_URL = `${APP_BASENAME}/consent/:uuid`;
export const APP_OPTOUT_URL = `${APP_BASENAME}/consent/optout/:uuid`;
export const APP_GLOBAL_VIEW_URL = `${APP_BASENAME}/global-view`;
export const APP_LOGIN_URL = `${APP_BASENAME}/auth/login`;
export const APP_LOGOUT_URL = `${APP_BASENAME}/auth/logout`;
export const APP_ACCESS_DENIED_URL = `${APP_BASENAME}/access-denied`;
export const APP_CALLBACK_URL = `${APP_BASENAME}/auth/callback`;
export const APP_EVENT_TRACKER_URL = `${APP_BASENAME}/events`;
export const APP_SNAPSHOT_LOG_URL = `${APP_BASENAME}/snapshot`;
export const APP_DEVICE_STATUS_URL = `${APP_BASENAME}/device/status`;
export const APP_VISITORS_URL = `${APP_BASENAME}/visitors`;
export const APP_VISITOR_URL = `${APP_BASENAME}/visitor/:uuid`;
export const APP_LIVE_VIDEO_CUSTOM_LAYOUT = `${APP_BASENAME}/live/video/custom-layout`;
export const APP_LIVE_VIDEO_VIEW_ALL_CAMERAS = `${APP_BASENAME}/live/video/all-cameras`;
export const APP_RECORDED_VIDEO_URL = `${APP_BASENAME}/video/recorded`;
export const APP_RECORDED_VIDEO_URL_EVENTS = `${APP_BASENAME}/video/recorded/events`;
export const APP_RECORDED_VIDEO_URL_TIME = `${APP_BASENAME}/video/recorded/time`;
export const APP_EVENT_CLASSIFICATION_URL = `${APP_BASENAME}/config/event`;
export const APP_LOCKDOWN_URL = `${APP_BASENAME}/lockdown`;
export const APP_DATA_EXPLORER_REPORT_URL = `${APP_BASENAME}/dashboard/reports/explorer`;
export const APP_ONAIR_UNKNOWN_CARDS_URL = `${APP_BASENAME}/onair/credentials/unknown`;
export const APP_ONAIR_HOLIDAYS_URL = `${APP_BASENAME}/onair/holidays`;
export const APP_ONAIR_ACCOUNT_SETTINGS_URL = `${APP_BASENAME}/onair/account-settings`;
export const APP_ONAIR_NOTIFICATIONS_VIEW_URL = `${APP_BASENAME}/onair/notifications/view`;
export const APP_ONAIR_GROUP_LOCKDOWN_URL = `${APP_BASENAME}/onair/lockdown/groupLockdown`;
export const APP_ONAIR_LOCKDOWN_CONFIG_URL = `${APP_BASENAME}/onair/lockdown/config`;
export const APP_ONAIR_REPORT_JOBS_URL = `${APP_BASENAME}/onair/reporting/report-jobs`;
export const APP_ONAIR_REPORT_SCHEDULES_URL = `${APP_BASENAME}/onair/reporting/report-schedules`;
export const APP_ONAIR_REPORT_CONFIGURATIONS_URL = `${APP_BASENAME}/onair/reporting/report-configs`;
export const APP_ONAIR_USER_REPORTS_URL = `${APP_BASENAME}/onair/reporting/user`;
export const APP_ONAIR_ACTIVITY_REPORTS_URL = `${APP_BASENAME}/onair/reporting/activity`;
export const APP_ONAIR_IN_OUT_REPORTS_URL = `${APP_BASENAME}/onair/reporting/inout`;
export const APP_ONAIR_CUSTOM_FIELDS_URL = `${APP_BASENAME}/onair/access/users/list-custom-fields`;
export const APP_CUSTOM_FIELDS_URL = `${APP_BASENAME}/custom-fields`;
export const APP_CUSTOM_FIELDS_DETAILS_URL = `${APP_BASENAME}/custom-fields/:id(\\d+|${CREATE_CUSTOM_FIELD})`;
export const APP_CUSTOM_FIELDS_CREATE_URL = `${APP_BASENAME}/custom-fields/${CREATE_CUSTOM_FIELD}`;
export const APP_ONAIR_USER_BY_ID_URL = `${APP_BASENAME}/onair/access/user-edit`;
export const APP_CREDENTIAL_MANAGEMENT_URL = `${APP_BASENAME}/credentials`;
export const APP_CREDENTIAL_CARDS_URL = `${APP_BASENAME}/credentials/cards`;
export const APP_CREDENTIAL_UNKNOWN_CARDS_URL = `${APP_BASENAME}/unknown-cards`;
export const APP_CREDENTIAL_MOBILEPASS_URL = `${APP_BASENAME}/credentials/mobile-pass`;
export const APP_CREDENTIAL_CARD_DETAILS_URL = `${APP_BASENAME}/credentials/cards/:id(\\d+)`;
export const APP_CREDENTIAL_UNKNOWN_CARDS_DETAILS_URL = `${APP_BASENAME}/unknown-cards/:id(\\d+)`;
export const APP_CREDENTIAL_MOBILE_PASS_DETAILS_URL = `${APP_BASENAME}/credentials/mobile-pass/:id(\\d+)`;
export const APP_CREDENTIAL_FACEPRINTS_URL = `${APP_BASENAME}/faceprints`;
export const APP_USERS_LISTING_URL = `${APP_BASENAME}/users`;
export const APP_GROUPS_LISTING_URL = `${APP_BASENAME}/groups`;
export const APP_USER_DETAILS_URL = `${APP_BASENAME}/users/:id(\\d+|create-user)`;
export const APP_ADD_USER_URL = `${APP_BASENAME}/users/create-user`;
export const APP_GROUP_DETAILS_URL = `${APP_BASENAME}/groups/:id(\\d+|create-group)`;
export const APP_ADD_GROUP_URL = `${APP_BASENAME}/groups/create-group`;
export const APP_BADGING_URL = `${APP_BASENAME}/badging`;
export const APP_FACEPRINT_CONSENTS_URL = `${APP_BASENAME}/faceprint-consents`;
export const APP_ADMIN_MANAGEMENT_URL = `${APP_BASENAME}/admin-management`;
export const APP_ADMIN_MANAGEMENT_ADMINS_URL = `${APP_BASENAME}/admin-management/admins`;
export const APP_ADMIN_MANAGEMENT_ROLES_URL = `${APP_BASENAME}/admin-management/roles`;
export const APP_ADMIN_MANAGEMENT_CREATE_ADMIN_URL = `${APP_BASENAME}/admin-management/admins/create`;
export const APP_ADMIN_MANAGEMENT_CREATE_ROLE_URL = `${APP_BASENAME}/admin-management/roles/create`;
export const APP_DEFAULT_URL = APP_EVENT_TRACKER_URL;
export const APP_OCCUPANCY_MANAGEMENT_URL = `${APP_BASENAME}/occupancy-management`;
export const APP_OCCUPANCY_DASHBOARD_URL = `${APP_BASENAME}/occupancy-management/dashboard`;
export const APP_CONFIGURED_SITES_URL = `${APP_BASENAME}/occupancy-management/sites`;
export const APP_CONFIGURED_SITE_DETAILS_URL = `${APP_BASENAME}/occupancy-management/sites/:id(\\d+)`;
export const APP_COMMAND_URL = `${APP_BASENAME}/commands`;
export const APP_COMMAND_DETAILS_URL = `${APP_BASENAME}/commands/:id(\\d+|create-command)`;
export const APP_COMMAND_CREATE_URL = `${APP_BASENAME}/commands/create-command`;
export const APP_NOTIFICATIONS_URL = `${APP_BASENAME}/notifications`;
export const APP_NOTIFICATION_DETAILS_URL = `${APP_BASENAME}/notifications/:id(\\d+|create-notification)`;
export const APP_ACCOUNT_SETTINGS_URL = `${APP_BASENAME}/account-settings`;
export const APP_SITE_SETTINGS_URL = `${APP_BASENAME}/site-settings`;
export const APP_PROPERTIES_URL = `${APP_BASENAME}/properties`;
export const APP_PROPERTY_DETAILS_URL = `${APP_BASENAME}/properties/:id(\\d+|create-property)`;
export const APP_GATEWAY_DETAILS_URL = `${APP_BASENAME}/gateway/:id(\\d+)`;
export const APP_FLOOR_PLAN_URL = `${APP_BASENAME}/floor-plans/:site_id/:id(\\d+|create-floor-plan)`;
export const APP_FLOOR_PLANS_URL = `${APP_BASENAME}/floor-plans`;
export const APP_NEW_REPORTS_URL = `${APP_BASENAME}/new-reports`;
export const APP_SITE_DETAILS_URL = `${APP_BASENAME}/site/:id(\\d+|create-site)`;
export const APP_SHARED_ACCESS_URL = `${APP_BASENAME}${PATH_SHARED_ACCESS}`;
export const APP_UNIFIED_VIDEO_INTEGRATIONS_URL = `${APP_BASENAME}${PATH_UNIFIED_VIDEO_INTEGRATIONS}`;
export const APP_UNIFIED_VIDEO_URL = `${APP_BASENAME}/unified-video`;
export const APP_UNIFIED_DASHBOARD_URL = `${APP_BASENAME}/unified-dashboard`;
export const APP_BRIVO_SMARTHOME = `${APP_BASENAME}/brivo-smarthome`;
export const APP_INTEGRATIONS = `${APP_BASENAME}/integrations`;
export const APP_JOURNAL_URL = `${APP_BASENAME}/journal`;
export const APP_SCHEDULES_URL = `${APP_BASENAME}/schedules`;
export const APP_SCHEDULES_CREATE_URL = `${APP_BASENAME}/schedules/create`;
export const APP_SCHEDULES_PRINT_URL = `${APP_BASENAME}/schedules/print`;
export const APP_BRIVO_ANALYTICS_COMPANY_OVERVIEW_URL = `${APP_BASENAME}/analytics/company-overview`;
export const APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL = `${APP_BASENAME}/analytics/my-analytics`;
export const APP_BRIVO_ANALYTICS_DASHBOARD_URL = `${APP_BASENAME}/analytics/my-analytics/:dashboardId`;
export const APP_BRIVO_ANALYTICS_EDITOR_DASHBOARD_URL = `${APP_BASENAME}/analytics/my-analytics/editor/:dashboardId/@:metricPosition`;
export const APP_BRIVO_ANALYTICS_MY_ASSISTANT_URL = `${APP_BASENAME}/analytics/my-assistant`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_URL = `${APP_BASENAME}/analytics/my-analytics/visual-editor/:visualType/:dashboardId/@:metricPosition`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_NEW_URL_PART = `new`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_NEW_URL = `${APP_BRIVO_ANALYTICS_VISUAL_EDITOR_URL}/${APP_BRIVO_ANALYTICS_VISUAL_EDITOR_NEW_URL_PART}`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_PIVOT_BASE_URL = `${APP_BASENAME}/analytics/my-analytics/visual-editor/pivot`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_PIVOT_URL = `${APP_BASENAME}/analytics/my-analytics/visual-editor/pivot/:dashboardId/@:metricPosition`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_QUERY_BASE_URL = `${APP_BASENAME}/analytics/my-analytics/visual-editor/query`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_QUERY_URL = `${APP_BASENAME}/analytics/my-analytics/visual-editor/query/:dashboardId/@:metricPosition`;
export const APP_BRIVO_ANALYTICS_VISUAL_EDITOR_NEW_TABLE_QUERY_URL = `${APP_BASENAME}/analytics/my-analytics/visual-editor/query/:dashboardId/@:metricPosition/new`;
export const APP_LOCKDOWN_SCENARIOS_URL = `${APP_BASENAME}/lockdown-scenarios`;
export const APP_LOCKDOWN_SCENARIO_DETAILS_URL = `${APP_BASENAME}/lockdown-scenarios/:id`;
export const APP_ADD_LOCKDOWN_SCENARIO_URL = `${APP_BASENAME}/lockdown-scenarios/create-lockdown-scenario`;
export const APP_SCENARIOS_URL = `${APP_BASENAME}/scenarios`;
export const APP_SCENARIO_DETAILS_URL = `${APP_BASENAME}/scenarios/:id`;
export const APP_ADD_SCENARIO_URL = `${APP_BASENAME}/scenarios/create-scenario`;
export const APP_INCIDENT_MANAGEMENT_URL = `${APP_BASENAME}/incident-management`;
export const APP_INCIDENT_MANAGEMENT_DETAILS_URL = `${APP_BASENAME}/incident-management/:id`;

// IN APP MESSAGING - SUPPORTED URLS
export const SUPPORTED_CAMPAIGN_URLS = [APP_FLOOR_PLANS_URL, APP_BRIVO_ANALYTICS_MY_ANALYTICS_URL];

// BADGING URLs
const BADGING_APP_BASENAME = envVar('VITE_BADGING_URL');

export const BADGING_APP_BASE_URL = `${BADGING_APP_BASENAME}`;
export const BADGING_APP_TEMPLATES_URL = `${BADGING_APP_BASENAME}/badges`;
export const BADGING_APP_PRINT_URL = `${BADGING_APP_BASENAME}/badges/prints`;
export const BADGING_APP_REPORTS_URL = `${BADGING_APP_BASENAME}/badges/reports`;

//brand logo url
export const BRAND_LOGO_URL = '/brand/logo/';

// API URLs
export const API_SITES_URL = `${API_BASE_URL}/sites`;
export const API_READER_COMMANDS = `${API_BASE_URL}/reader-commands`;
export const API_SCHEDULES_URL = `${API_BASE_URL}/schedules`;
export const API_ACCOUNTS_URL = `${API_BASE_URL}/accounts`;
export const API_ROVER_DATA_V2_URL = `${API_BASE_URL}/rover/v2/data`;
export const API_ROVER_METADATA_V2_URL = `${API_BASE_URL}/rover/v2/metadata`;
export const API_ROVER_REPORT_V2_URL = `${API_BASE_URL}/rover/v2/report`;
export const API_ROVER_COLUMNS_V2_URL = `${API_BASE_URL}/rover/v2/columns`;
export const API_ROVER_UNIQUE_VALUES_V2_URL = `${API_BASE_URL}/rover/v2/uniqueValues`;
export const API_ROVER_ASYNC_DATA_V2_URL = `${API_BASE_URL}/rover/v2/async-data`;
export const API_ROVER_ASYNC_RESULT_V2_URL = `${API_BASE_URL}/rover/v2/async-results`;
export const API_ROVER_ASYNC_REPORT_V2_URL = `${API_BASE_URL}/rover/v2/async-report`;
export const API_ROVER_ASYNC_REPORT_RESULT_V2_URL = `${API_BASE_URL}/rover/v2/async-report-results`;
export const API_ROVER_ASYNC_UNIQUE_VALUES_V2_URL = `${API_BASE_URL}/rover/v2/async-unique-values`;
export const API_ROVER_ASYNC_UNIQUE_VALUES_RESULT_V2_URL = `${API_BASE_URL}/rover/v2/async-unique-values-results`;
export const API_ROVER_TRANSLATE_V2_URL = `${API_BASE_URL}/rover/v2/translate`;
export const API_UPSELL_LOGIN_EVENT = `${API_BASE_URL}/campaigns/putLoginEvent`;
export const API_UPSELL_VIEW_EVENT = `${API_BASE_URL}/campaigns/putViewEvent`;
export const API_UPSELL_CONTACTUS_EVENT = `${API_BASE_URL}/campaigns/putContactUsEvent`;
export const API_UPSELL_LEARNMORE_EVENT = `${API_BASE_URL}/campaigns/putLearnMoreEvent`;
export const API_UPSELL_DISMISSCAMPAIGN_EVENT = `${API_BASE_URL}/campaigns/putCloseEvent`;
export const API_UPSELL_GETACTIVECAMPAIGN_EVENT = `${API_BASE_URL}/campaigns/getActiveCampaigns`;

// Hard Redirect
export const redirect = (url) => window.location.replace(url);

export const APPLICATION_VERSION = envVar('VITE_BUILD_TAG');

const possibleDomain = envVar('VITE_ONAIR_CLASSIC_DOMAIN');
export const ONAIR_CLASSIC_URL = possibleDomain ? possibleDomain.toLowerCase() : 'https://onair-inta.brivo.com';

export const BRIVO_WORKSPACE_URL = `https://brivo.proximity.app`;

export const APP_PRIVACY_POLICY_URL = `${APP_BASENAME}/privacy-policy`;

export const THEME_QUERY_STRING = 'brivoAccessView';

// WEBSOCKET
export const WEBSOCKET_URL = envVar('VITE_WEBSOCKET_URL');

export const MAPBOX_TOKEN = envVar('VITE_MAPBOX_TOKEN');

// Door type
export const OFFLINE_DOOR_LOCK = 'Offline Door Lock';
export const OFFLINE_CREDENTIAL_DEVICE = 'Offline Credential Device';
export const SALTO_DOOR_LOCK = 'Salto Door Lock';
export const ALLEGION_DOOR_LOCK = 'Allegion Door Lock';
export const ASSA_ABLOY_DOOR_LOCK = 'Assa Abloy Door Lock';
export const DOOR = 'Door';
export const SIMONS_VOSS_LOCK = 'Simons Voss Lock';
export const DORMAKABA_LOCK = 'Dormakaba Lock';

export const OFFLINE_CONTROL_DEVICES = [OFFLINE_CREDENTIAL_DEVICE, OFFLINE_DOOR_LOCK];
// Switch type
export const SWITCH = 'Switch';

// Floor type
export const FLOOR = 'Floor';

// Camera type
export const CAMERA = 'Camera';

// Panel type
export const PANEL = 'Panel';

// Elevator type
export const ELEVATOR = 'Elevator';

// Valid Credential type
export const VALID_CREDENTIAL = 'Valid Credential';

// Schedule types
export const ALWAYS_ACCESS = '(Always Access)';

//led state
export const UNKNOWN_STATE = 'UNKNOWN_STATE';
export const ONLINE_STATE = 'ONLINE_STATE';
export const OFFLINE_STATE = 'OFFLINE_STATE';
export const WARNING_STATE = 'WARNING_STATE';
export const LOCKDOWN_STATE = 'LOCKDOWN_STATE';

//Event Tracker tabs
export const TRENDS_TAB = 'TRENDS_TAB';
export const SWIPE_AND_SHOW_TAB = 'SWIPE_AND_SHOW_TAB';

//PMS Mapped List tabs
export const FLAT_TAB = 'FLAT_TAB';
export const MULTI_TIER_TAB = 'MULTI_TIER_TAB';

//Device List tabs
export const SITE_DEVICES = 'SITE_DEVICES';
export const COMMON_DEVICES = 'COMMON_DEVICES';
export const GROUP_DEVICES = 'GROUP_DEVICES';

//access commands events
export const COMMAND_TRIGGERED_BY_USER = 5100;

// Activity Log
export const VALID_ACCESS = 2004;
export const ADMIN_PULSE_ACTION_ID = 5064;
export const DISPLAYED_ACTIVITY_EVENTS = [VALID_ACCESS, ADMIN_PULSE_ACTION_ID];
export const ANOMALY_EVENT = 7800;
export const ENRICHMENT_EVENT_TYPE = 'ENRICHMENT_EVENT';
export const MAX_EVENT_AGE_DAYS = 90;

//Administrator Management
export const ADMINISTRATOR = 'Administrator';
export const SUPER_ADMINISTRATOR = 'Super Administrator';

// Video
export const CAMERA_CONNECT_EVENT_ID = 5066;
export const CAMERA_DISCONNECT_EVENT_ID = 5067;
export const CAMERA_MOTION_EVENT_ID = 5068;
export const COMPLETED_PEAKFACE_SECURITY_ACTION_ID = 7721;
export const PRECACHED_CLIP_SECURITY_ACTION_ID = 7725;
export const MAX_SNAPSHOT_AGE_DAYS = 90;
export const MAX_NO_OF_CAMERAS_PER_LAYOUT = 16;
export const VIDEO_REFRESH_TIME_SECONDS = 540;
export const EE_STREAM_DURATION_MS = 600000;
export const CAMERAS_SIZE = 20;

//exception events
export const DOOR_OPEN_TOO_LONG = 5001;
export const SCHEDULE_ACTIVATED = 5055;
export const DOOR_AJAR_RESOLVED = 5011;
export const ACTION_DOOR_UNAUTHORIZED_OPEN = 5008;
export const ACTION_SALTO_DOOR_OPEN_METALLIC_KEY = 5204;
export const ACTION_SALTO_DOOR_OPEN_PPD = 5205;
export const ACTION_DOOR_OPEN_PRIVACY_OVERRIDE = 5231;
export const ACCESS_GRANTED_OFFLINE = 5073;
export const UNKNOWN_CREDENTIAL_ACCESS_GRANTED_OFFLINE = 5074;

export const INTRUSION_ALARM = 5207;
export const INTRUSION_ALARM_CLEARED = 5208;
export const INVALID_CREDENTIAL_THRESHOLD = 5006;
export const ACTION_SCD_UNLOCK_OVERRIDE_END = 5051;
export const DOOR_LOCKED_VIA_KEYPAD = 5005;
export const ACTION_SCD_UNLOCK_OVERRIDE_BEGIN = 5050;
export const DOOR_UNLOCKED_VIA_KEYPAD = 5004;
export const DOOR_LOCKED_VIA_TIMER = 5003;
export const DOOR_UNLOCKED_VIA_TIMER = 5002;
export const ACTION_ACCESS_FAIL_UNKNOWN_CRED = 5012;
export const ACTION_ACCESS_FAIL_UNASSIGNED = 5013;
export const ACTION_INVALID_SECOND_FACTOR_UNKNOWN_CARD = 5056;
export const ACTION_INVALID_SECOND_FACTOR_UNKNOWN_PIN = 5057;
export const ACTION_INVALID_CREDENTIAL_TYPE_UNKNOWN_USER = 5070;
export const ACTION_ACCESS_FAIL_DELETED_USER = 5014;
export const ACTION_ACCESS_FAIL_OLD_CRED = 5015;
export const ACTION_ACCESS_FAIL_OUT_OF_SCHED = 5016;
export const ACTION_ACCESS_FAIL_EXPIRED = 5017;
export const ACTION_ACCESS_FAIL_WRONG_DOOR = 5018;
export const ACTION_ACCESS_FAIL_NO_PERM_THREAT_ACCESS = 5072;
export const ACTION_ACCESS_FAIL_AUTHORIZATION_PENDING = 5034;
export const ACTION_INVALID_SECOND_FACTOR_INVALID_CARD = 5058;
export const ACTION_INVALID_SECOND_FACTOR_TWO_CARDS = 5059;
export const ACTION_INVALID_SECOND_FACTOR_TWO_PINS = 5060;
export const ACTION_SECOND_FACTOR_NOT_GIVEN = 5061;
export const ACTION_ANTIPASSBACK_FAILURE = 5063;
export const ACTION_ACCESS_FAIL_DEACTIVATED = 5065;
export const ACTION_ACCESS_FAIL_INVALID_CREDENTIAL_TYPE = 5069;
export const ACTION_FAIL_ENTRANCE_SALTO_ROUTER_OFFLINE = 5214;
export const ACTION_DOOR_LOCK_REJECT_PRIVACY_MODE = 5232;

export const ACTION_SALTO_DOOR_LOCK_LOW_BATTERY = 5210;
export const ACTION_WIRELESS_DOOR_LOCK_CRITICAL_BATTERY = 5236;

export const SALTO_REJECT_LOW_BATTERY = 5209;

//device events
export const DEVICE_ENGAGED = 5037;
export const DEVICE_DISENGAGED = 5038;
export const ACTION_AUX_RELAY_ACTIVE = 5019;
export const ACTION_AUX_RELAY_DEACTIVE = 5020;

// smart home
export const SMART_HOME_URL = envVar('VITE_SMART_HOME_URL');

// integrations
export const INTEGRATIONS_URL = envVar('VITE_INTEGRATIONS_URL');

//control panel events
export const CONTROL_PANEL_UNIT_OPENED = 5024;
export const CONTROL_PANEL_UNIT_CLOSED = 5025;
export const ACTION_BOARD_TAMPER_SET = 5045;
export const ACTION_BOARD_TAMPER_CLR = 5046;
export const ACTION_CP_SWITCH_BATTERY = 5021;
export const ACTION_CP_AC_POWER_RESTORED = 5022;
export const ACTION_CP_BATTERY_LEVEL = 5023;
export const ACTION_BOARD_BATTERY_SET = 5043;
export const ACTION_BOARD_BATTERY_CLR = 5044;
export const ACTION_WIRE_CUT_SET = 5039;
export const ACTION_WIRE_CUT_CLR = 5040;
export const ACTION_BOARD_COMMS_FAILURE_CLR = 5042;
export const ACTION_BOARD_COMMS_FAILURE_SET = 5041;
export const ACTION_WIRE_SHORT_SET = 5048;
export const ACTION_WIRE_SHORT_CLR = 5049;
export const EXECUTE_COMMAND = 5082;
export const PANEL_COMMUNICATION_FAILURE = 5300;
export const ACTION_READER_TAMPER_SET = 8023;
export const ACTION_READER_TAMPER_CLR = 8024;
//intrusion events
export const INTRUSION_PANEL_ARMED = 2502;
export const INTRUSION_PANEL_DISARMED = 2503;
//lockdown events
export const CREATE_LOCKDOWN = 2030;
export const MODIFY_LOCKDOWN = 2031;
export const DELETE_LOCKDOWN = 2032;
export const INITIATE_LOCKDOWN = 2033;
export const CLEAR_LOCKDOWN = 2034;

export const MOTION_IN_REGION_DETECTED = 5500;
export const LICENSE_PLATE_READ = 5501;
export const LOITER_DETECTION = 5502;
export const TAMPER_DETECTION = 5503;
export const OBJECT_LINE_CROSSING = 5504;
export const OBJECT_INTRUSION = 5505;
export const DOOR_STATION_CALL_INITIATED = 7900;
export const DOOR_STATION_CALL_ANSWERED = 7905;
export const DOOR_STATION_CALL_UNANSWERED = 7906;
export const DOOR_STATION_CALL_FAILED = 7907;
export const INVALID_SECOND_FACTOR_INVALID_CREDENTIAL = 5506;
export const INVALID_SECOND_FACTOR_TWO_OF_SAME_TYPE = 5507;

export const EEV3_ANALYTICS_EVENTS = [
    MOTION_IN_REGION_DETECTED,
    LICENSE_PLATE_READ,
    LOITER_DETECTION,
    TAMPER_DETECTION,
    OBJECT_LINE_CROSSING,
    OBJECT_INTRUSION,
];

export const LOCKDOWN_ACTIONS = [CREATE_LOCKDOWN, MODIFY_LOCKDOWN, DELETE_LOCKDOWN, INITIATE_LOCKDOWN, CLEAR_LOCKDOWN];

const existingActivityEvents = Object.keys(SecurityActionMessages)?.map((eventNumStr) => {
    return Number(eventNumStr);
});

export const ACCESS_EVENTS_KEY = 'access';
export const EXCEPTION_EVENTS_KEY = 'exception';

export const allAccessEvents = [
    ...existingActivityEvents,
    5002,
    5003,
    5004,
    5005,
    5019,
    5020,
    5021,
    5022,
    5023,
    5024,
    5025,
    5037,
    5038,
    5040,
    5042,
    5044,
    5046,
    5049,
    5055,
    5066,
    5067,
    5068,
    5202,
    5203,
    5206,
    5208,
    5212,
    5218,
    5219,
    5220,
    5221,
    5231,
    5232,
    5300,
    7900,
    7905,
    7906,
    7907,
];

export const allCameraEvents = [
    CAMERA_CONNECT_EVENT_ID,
    CAMERA_DISCONNECT_EVENT_ID,
    CAMERA_MOTION_EVENT_ID,
    MOTION_IN_REGION_DETECTED,
    LICENSE_PLATE_READ,
    LOITER_DETECTION,
    TAMPER_DETECTION,
    OBJECT_LINE_CROSSING,
    OBJECT_INTRUSION,
];

export const videoClipCameraEvents = [
    CAMERA_CONNECT_EVENT_ID,
    CAMERA_MOTION_EVENT_ID,
    MOTION_IN_REGION_DETECTED,
    LICENSE_PLATE_READ,
    LOITER_DETECTION,
    TAMPER_DETECTION,
    OBJECT_LINE_CROSSING,
    OBJECT_INTRUSION,
];

export const SUCCESSFUL_EVENT_LIST = [VALID_ACCESS, SCHEDULE_ACTIVATED];
export const TRIGGER_EVENTS_WITH_MANDATORY_FILTERS = [VALID_ACCESS, SCHEDULE_ACTIVATED];

export const DEVICE_EVENT_LIST = [
    DOOR_UNLOCKED_VIA_KEYPAD,
    DOOR_LOCKED_VIA_KEYPAD,
    ACTION_ACCESS_FAIL_UNKNOWN_CRED,
    ACTION_ACCESS_FAIL_UNASSIGNED,
    ACTION_ACCESS_FAIL_DELETED_USER,
    ACTION_ACCESS_FAIL_OLD_CRED,
    ACTION_ACCESS_FAIL_OUT_OF_SCHED,
    ACTION_ACCESS_FAIL_EXPIRED,
    ACTION_ACCESS_FAIL_WRONG_DOOR,
    ACTION_SCD_UNLOCK_OVERRIDE_BEGIN,
    ACTION_SCD_UNLOCK_OVERRIDE_END,
    ACTION_INVALID_SECOND_FACTOR_UNKNOWN_CARD,
    ACTION_INVALID_SECOND_FACTOR_UNKNOWN_PIN,
    ACTION_INVALID_SECOND_FACTOR_INVALID_CARD,
    ACTION_INVALID_SECOND_FACTOR_TWO_PINS,
    ACTION_SECOND_FACTOR_NOT_GIVEN,
    ACTION_ANTIPASSBACK_FAILURE,
    ACTION_ACCESS_FAIL_DEACTIVATED,
    ACTION_ACCESS_FAIL_INVALID_CREDENTIAL_TYPE,
    ACTION_INVALID_CREDENTIAL_TYPE_UNKNOWN_USER,
    SALTO_REJECT_LOW_BATTERY,
    ACTION_FAIL_ENTRANCE_SALTO_ROUTER_OFFLINE,
    DOOR_OPEN_TOO_LONG,
    DOOR_UNLOCKED_VIA_TIMER,
    DOOR_LOCKED_VIA_TIMER,
    INVALID_CREDENTIAL_THRESHOLD,
    ACTION_DOOR_UNAUTHORIZED_OPEN,
    DOOR_AJAR_RESOLVED,
    ACTION_SALTO_DOOR_OPEN_METALLIC_KEY,
    ACTION_SALTO_DOOR_OPEN_PPD,
    INTRUSION_ALARM,
    INTRUSION_ALARM_CLEARED,
    ACTION_SALTO_DOOR_LOCK_LOW_BATTERY,
    ACTION_DOOR_OPEN_PRIVACY_OVERRIDE,
    ACTION_DOOR_LOCK_REJECT_PRIVACY_MODE,
    ACTION_WIRELESS_DOOR_LOCK_CRITICAL_BATTERY,
    ACTION_WIRE_CUT_SET,
    ACTION_WIRE_CUT_CLR,
    ACTION_WIRE_SHORT_SET,
    ACTION_WIRE_SHORT_CLR,
    ACTION_READER_TAMPER_SET,
    ACTION_READER_TAMPER_CLR,
    DEVICE_ENGAGED,
    DEVICE_DISENGAGED,
    DOOR_STATION_CALL_INITIATED,
    DOOR_STATION_CALL_ANSWERED,
    DOOR_STATION_CALL_UNANSWERED,
    DOOR_STATION_CALL_FAILED,
    INVALID_SECOND_FACTOR_INVALID_CREDENTIAL,
    INVALID_SECOND_FACTOR_TWO_OF_SAME_TYPE,
];

export const MONITORED_DOOR_EVENT_LIST = [DOOR_AJAR_RESOLVED, DOOR_OPEN_TOO_LONG];

export const PANEL_EVENT_LIST = [
    ACTION_CP_SWITCH_BATTERY,
    ACTION_CP_AC_POWER_RESTORED,
    ACTION_CP_BATTERY_LEVEL,
    CONTROL_PANEL_UNIT_OPENED,
    CONTROL_PANEL_UNIT_CLOSED,
    ACTION_BOARD_COMMS_FAILURE_SET,
    ACTION_BOARD_COMMS_FAILURE_CLR,
    ACTION_BOARD_BATTERY_SET,
    ACTION_BOARD_BATTERY_CLR,
    ACTION_BOARD_TAMPER_SET,
    ACTION_BOARD_TAMPER_CLR,
    PANEL_COMMUNICATION_FAILURE,
];

export const CAMERA_EVENT_LIST = [
    CAMERA_CONNECT_EVENT_ID,
    CAMERA_DISCONNECT_EVENT_ID,
    CAMERA_MOTION_EVENT_ID,
    MOTION_IN_REGION_DETECTED,
    LICENSE_PLATE_READ,
    LOITER_DETECTION,
    TAMPER_DETECTION,
    OBJECT_LINE_CROSSING,
    OBJECT_INTRUSION,
];

export const allSecurityEvents = [
    DOOR_OPEN_TOO_LONG,
    DOOR_AJAR_RESOLVED,
    ACTION_DOOR_UNAUTHORIZED_OPEN,
    ACTION_SALTO_DOOR_OPEN_METALLIC_KEY,
    ACTION_SALTO_DOOR_OPEN_PPD,
    ACTION_DOOR_OPEN_PRIVACY_OVERRIDE,
    INTRUSION_ALARM,
    INTRUSION_ALARM_CLEARED,
    INVALID_CREDENTIAL_THRESHOLD,
    DOOR_LOCKED_VIA_KEYPAD,
    ACTION_SCD_UNLOCK_OVERRIDE_END,
    DOOR_UNLOCKED_VIA_KEYPAD,
    ACTION_SCD_UNLOCK_OVERRIDE_BEGIN,
    DOOR_LOCKED_VIA_TIMER,
    DOOR_UNLOCKED_VIA_TIMER,
    ACTION_ACCESS_FAIL_UNKNOWN_CRED,
    ACTION_ACCESS_FAIL_UNASSIGNED,
    ACTION_INVALID_SECOND_FACTOR_UNKNOWN_CARD,
    ACTION_INVALID_SECOND_FACTOR_UNKNOWN_PIN,
    ACTION_INVALID_CREDENTIAL_TYPE_UNKNOWN_USER,
    ACTION_ACCESS_FAIL_DELETED_USER,
    ACTION_ACCESS_FAIL_OLD_CRED,
    ACTION_ACCESS_FAIL_OUT_OF_SCHED,
    ACTION_ACCESS_FAIL_EXPIRED,
    ACTION_ACCESS_FAIL_WRONG_DOOR,
    ACTION_ACCESS_FAIL_NO_PERM_THREAT_ACCESS,
    ACTION_ACCESS_FAIL_AUTHORIZATION_PENDING,
    ACTION_INVALID_SECOND_FACTOR_INVALID_CARD,
    ACTION_INVALID_SECOND_FACTOR_TWO_CARDS,
    ACTION_INVALID_SECOND_FACTOR_TWO_PINS,
    ACTION_SECOND_FACTOR_NOT_GIVEN,
    ACTION_ANTIPASSBACK_FAILURE,
    ACTION_ACCESS_FAIL_DEACTIVATED,
    ACTION_ACCESS_FAIL_INVALID_CREDENTIAL_TYPE,
    ACTION_FAIL_ENTRANCE_SALTO_ROUTER_OFFLINE,
    ACTION_DOOR_LOCK_REJECT_PRIVACY_MODE,
    ACTION_SALTO_DOOR_LOCK_LOW_BATTERY,
    ACTION_WIRELESS_DOOR_LOCK_CRITICAL_BATTERY,
    DEVICE_ENGAGED,
    DEVICE_DISENGAGED,
    ACTION_AUX_RELAY_ACTIVE,
    ACTION_AUX_RELAY_DEACTIVE,
    CONTROL_PANEL_UNIT_OPENED,
    CONTROL_PANEL_UNIT_CLOSED,
    ACTION_BOARD_TAMPER_SET,
    ACTION_BOARD_TAMPER_CLR,
    ACTION_CP_SWITCH_BATTERY,
    ACTION_CP_AC_POWER_RESTORED,
    ACTION_CP_BATTERY_LEVEL,
    ACTION_BOARD_BATTERY_SET,
    ACTION_BOARD_BATTERY_CLR,
    ACTION_WIRE_CUT_SET,
    ACTION_WIRE_CUT_CLR,
    ACTION_BOARD_COMMS_FAILURE_CLR,
    ACTION_BOARD_COMMS_FAILURE_SET,
    ACTION_WIRE_SHORT_SET,
    ACTION_WIRE_SHORT_CLR,
    CAMERA_CONNECT_EVENT_ID,
    CAMERA_DISCONNECT_EVENT_ID,
    CAMERA_MOTION_EVENT_ID,
    MOTION_IN_REGION_DETECTED,
    LICENSE_PLATE_READ,
    LOITER_DETECTION,
    TAMPER_DETECTION,
    OBJECT_LINE_CROSSING,
    OBJECT_INTRUSION,
    INTRUSION_PANEL_ARMED,
    INTRUSION_PANEL_DISARMED,
    VALID_ACCESS,
    SCHEDULE_ACTIVATED,
    ACCESS_GRANTED_OFFLINE,
    UNKNOWN_CREDENTIAL_ACCESS_GRANTED_OFFLINE,
    SALTO_REJECT_LOW_BATTERY,
    PANEL_COMMUNICATION_FAILURE,
    ACTION_READER_TAMPER_SET,
    ACTION_READER_TAMPER_CLR,
    DOOR_STATION_CALL_INITIATED,
    DOOR_STATION_CALL_ANSWERED,
    DOOR_STATION_CALL_UNANSWERED,
    DOOR_STATION_CALL_FAILED,
    INVALID_SECOND_FACTOR_INVALID_CREDENTIAL,
    INVALID_SECOND_FACTOR_TWO_OF_SAME_TYPE,
];

export const LOCKED_STATE = 'Locked';
export const SCHEDULE_CHANGE_MODE = 'UNTIL_NEXT_SCHEDULE_CHANGE';
export const CERTAIN_TIME_MODE = 'UNTIL_TIME';
export const INDEFINITE_MODE = 'INDEFINITE';
export const UNLOCKED_STATE = 'Unlocked';
export const HELD_OPEN_TOO_LONG = 'Held open too long';

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const LOCKDOWN = 'Lockdown';
export const EGRESS_SCENARIO = 3;
export const LOCKDOWN_SCENARIO = 2;
export const OFFLINE = 'Offline';
export const ONLINE = 'Online';
export const UNKNOWN = 'Unknown';
export const LOCKED_DOWN = 'Locked Down';
export const NOT_OPERATING_NORMALLY = 'Not Operating Normally';
export const OPERATING_NORMALLY = 'Operating Normally';
export const NA = 'N/A';
export const STATUS_CURRENTLY_UNKNOWN = 'Status currently unknown';
export const UNLOCKED = 'Unlocked';
export const LOCKED = 'Locked';
export const INPUT_STATUS_OPEN = 0;
export const INPUT_STATUS_CLOSED = 1;
export const INPUT_STATUS_WIRE_CUT = 2;
export const INPUT_STATUS_WIRE_SHORTED = 3;
export const AJAR_STATUS_DOOR_AJAR = 1;
export const INTRUSION_STATUS_WARNING = 1;
export const BATTERY_STATE_WARNING = 1;
export const BATTERY_REMAINING_LESS_THAN_15_WARNING = 15;
export const DOOR_STATE_OPENED = 0;
export const DOOR_STATE_CLOSED = 1;
export const UNLOCKED_SCHEDULE_STATUS_UNLOCKED = 1;
export const ACCESS_STATE_UNLOCKED = 2;
export const MAX_NR_CREDENTIALS_ASSIGNED = 16;

export const ACTIVE = 'active';
export const INACTIVE = 'inactive';

//legacy panels
export const ACS5000 = 5;
export const ACS5000_CDMA = 6;
export const ACS5000_GSM = 7;
export const ACS5000F = 25;
export const IPDC1 = 15;
export const IPDC2 = 16;
export const IPDC1_DOOR_APARATO = 17;
export const IPDC2_DOOR_APARATO = 18;
export const HID_EDGE = 9;

//mercury panels
export const MERCURY_EP4502 = 40;
export const MERCURY_EP1502 = 41;
export const MERCURY_LP4502 = 43;
export const MERCURY_LP1501 = 44;
export const MERCURY_LP1502 = 45;
export const MERCURY_LP2500 = 46;
export const MERCURY_EP2500 = 51;

export const MERCURY_EP1501 = 52;

//current panels
export const ACS100 = 42;
export const ACSSDC = 48;
export const ACS6000 = 28;
export const ACS300 = 33;

export const userEvents = [
    2004,
    2014, // Visitor Valid Acces
    5016,
    5017,
    5018,
    5050,
    5051,
    5057,
    5058,
    5059,
    5060,
    5061,
    5063,
    5064,
    5072,
    5073,
    5077,
    5209,
    5214,
    5231,
    5065, //Failed Access: User suspended
    5015, //Failed Access: Revoked Credential
    5204,
    5205,
    5206,
    5080,
    5100,
    5101, // Visitor Failed Access Out of Effective Time Range
    7905,
    5506,
    5507,
];

export const allVideoLinkableEvents = [
    2004, 5001, 5004, 5005, 5006, 5007, 5008, 5011, 5012, 5013, 5014, 5015, 5016, 5017, 5018, 5019, 5020, 5034, 5037,
    5038, 5039, 5040, 5048, 5049, 5056, 5057, 5058, 5059, 5060, 5061, 5063, 5064, 5065, 5069, 5072, 5073, 5074, 5077,
    5500, 5501, 5502, 5503, 5504, 5505,
];

export const LAUNCH_DARKLY_CLIENT_ID = envVar('VITE_LAUNCH_DARKLY_CLIENT_ID');

export const EAGLE_EYE_V3_CLIENT_ID = envVar('VITE_EAGLE_EYE_V3_CLIENT_ID');

export const TIMEZONE_FORMAT = 'z (UTC Z)';

export const DEFAULT_FILTERS = {
    excludeEventsWithoutVideo: false,
    showCameraEvents: false,
    selectedDate: null,
    selectedSeries: [],
    eventsType: [],
    selectedSites: [],
    selectedUser: '',
    selectedDevices: [],
    selectedEvents: [],
};

// Onair Classic
export const ONAIR_CLASSIC_SSO_DEFAULT_LOGIN_URL = `${ONAIR_CLASSIC_URL}/onair/auth0/login`;
export const ONAIR_CLASSIC_ENTERPRISE_SSO_BASE_URL = `${ONAIR_CLASSIC_URL}/onair/auth0/auth`;
export const ONAIR_CLASSIC_ALT_LOGIN = `${ONAIR_CLASSIC_URL}/onair/alt/callback`;
export const ONAIR_CLASSIC_UNKNOWN_CARDS_URL = `/access/cards/listUnknown.do`;
export const ONAIR_CLASSIC_HOLIDAYS_URL = `/access/schedules/loadHolidays.do`;
export const ONAIR_CLASSIC_ACCOUNT_SETTINGS_URL = `/access/account/ViewAccountSettings.do`;
export const ONAIR_CLASSIC_NOTIFICATIONS_VIEW_URL = `/access/notifs/viewNotifRule.do`;
export const ONAIR_CLASSIC_RECORDED_VIDEO_URL = `/access/view/video/recordedVideo`;
export const ONAIR_CLASSIC_GROUP_LOCKDOWN_URL = `/access/lockdown/lockdown.do`;
export const ONAIR_CLASSIC_LOCKDOWN_CONFIG_URL = `/access/view/lockdown`;
export const ONAIR_CLASSIC_REPORT_JOBS_URL = `/access/acs_report/ListReportJobs.do`;
export const ONAIR_CLASSIC_REPORT_SCHEDULES_URL = `/access/acs_report/ListReportSchedules.do`;
export const ONAIR_CLASSIC_REPORT_CONFIGURATIONS_URL = `/access/acs_report/ListReportConfigurations.do`;
export const ONAIR_CLASSIC_USER_REPORT_URL = `/access/report/ListReports.do?reportTypeID=1`;
export const ONAIR_CLASSIC_ACTIVITY_REPORT_URL = `/access/report/ListReports.do?reportTypeID=2`;
export const ONAIR_CLASSIC_IN_OUT_REPORT_URL = `/access/activity/generateInOut.do`;
export const ONAIR_CLASSIC_CUSTOM_FIELDS_URL = `/access/users/listFieldDefinitions.do`;
export const ONAIR_CLASSIC_USER_BY_ID_URL = '/access/users/userSetup.do';

// Google Analytics

export const GA_CLIENT_ID = envVar('VITE_GOOGLE_ANALYTICS_CLIENT_ID');
export const GA4_CLIENT_ID = envVar('VITE_GOOGLE_ANALYTICS_GA4_CLIENT_ID');

export const DEBOUNCE_TIMEOUT_MS = 500;
export const DEBOUNCE_TIMEOUT_MS_MSF = 1000;

export const LICENSE_PLATE_FORMAT = 'License plate';
export const EE_LPR_LICENSE_PLATE_FORMAT = 'EE LPR License plate';
export const LICENSE_PLATE = 'License plate';
export const UNKNOWN_FORMAT = 'Unknown format';
export const FACIAL_ID_FORMAT = 'Facial Id';
export const CASCADE_PASS_FORMAT = 'Cascade Pass';
export const PIN_CODE_FORMAT = 'PIN Code';

export const HID_36_BIT_CARD_FORMAT = 'HID 36 bit Card';
export const AWID_50_CARD_FORMAT = 'AWID 50-bit';

export const FACEPRINT_NAME_REPLACEMENT = 'Faceprint';

export const OCCUPANCY_SITE_BATCH_SIZE = 100;

export const THROTTLE_TIMEOUT_MS = 10000;

export const MULTIPLE_SELECT_ON_CHANGE_TIMEOUT = 1500;

export const OCCUPANCY_SITE_REFRESH_INTERVAL = 900000;

export const OCCUPANCY_DEFAULT_TIMEOUT_HOURS = 4;

export const OCCUPANCY_DEFAULT_USER_LIMIT = 50;

export const MAX_PAGE_SIZE_GRAPHQL = 1000;

export const SSID_MAX_LENGTH = 32;

export const MAX_SELECTED_USERS = 100;

//AUTH0

export const AUTH0_DOMAIN = envVar('VITE_AUTH0_DOMAIN');
export const AUTH0_CLIENT_ID = envVar('VITE_AUTH0_CLIENT_ID');

export const AUTH0_CALLBACK_URL = envVar('VITE_AUTH0_CALLBACK_URL');
export const AUTH0_AUDIENCE = envVar('VITE_AUTH0_AUDIENCE');

export const AUTH0_CONNECTION = envVar('VITE_AUTH0_CONNECTION');
export const IAMSVC_DOMAIN = envVar('VITE_IAMSVC_DOMAIN');

export const APPLICATION_DOMAIN = envVar('VITE_APPLICATION_DOMAIN');

export const API_BENTO_URL = envVar('VITE_BENTO_HOST');
export const API_GATEWAY_URL = envVar('VITE_API_GATEWAY_URL');
//build

export const BUILD_TAG = envVar('VITE_BUILD_TAG');
const ALT_AUTH_BASE = envVar('VITE_ALT_AUTHENTICATION_URL');

export const ALT_AUTHENTICATION_URL = `${ALT_AUTH_BASE}/alt/index.html?clientId=${AUTH0_CLIENT_ID}&redirectUrl=${AUTH0_CALLBACK_URL}`;

//SiteSettings
export const SCREEN_OPTION_ENABLED = 2;
export const SCREEN_OPTION_DISABLED = 1;
//For Events API
export const INCLUDED_SECURITY_ACTION_IDS = ', securityActionId: { in: $securityActionIds}';
export const EXCLUDED_SECURITY_ACTION_IDS = ', securityActionId: { notIn: $excludedSecurityActionIds}';
export const INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS =
    ', securityActionId: { in: $securityActionIds, notIn: $excludedSecurityActionIds}';

export const ARGUMENTS_INCLUDED_SECURITY_ACTION_IDS = '$securityActionIds: [Int]';
export const ARGUMENTS_EXCLUDED_SECURITY_ACTION_IDS = '$excludedSecurityActionIds: [Int]';
export const ARGUMENTS_INCLUDED_AND_EXCLUDED_SECURITY_ACTION_IDS = `$securityActionIds: [Int]
$excludedSecurityActionIds: [Int]`;

//in app messaging
export const OPTIN_MONSTER_ACCOUNT_ID = envVar('VITE_OPTIN_MONSTER_ACCOUNT_ID');
export const OPTIN_MONSTER_USER_ID = envVar('VITE_OPTIN_MONSTER_USER_ID');

export const SMARTHOME_API_DOMAIN = envVar('VITE_SMARTHOME_API_DOMAIN');
export const SMARTHOME_API_SCHEME = envVar('VITE_SMARTHOME_API_SCHEME');

export const MOBILE_SSO_LOGIN_URL = '/mobile/auth/sso/:connectionKey';
export const MOBILE_DEEP_LINK_URL = envVar('VITE_MOBILE_DEEP_LINK_URL');

export const STATUS_CHIP_TYPES = {
    New: 'warning',
    Cleared: 'success',
    Acknowledged: 'success',
    Escalated: 'error',
};

function envVar(envVarName) {
    const processEnv = process.env[envVarName];
    return envVarDefault(envVarName, processEnv);
}

function envVarDefault(envVarName, defaultVal) {
    const val = get(window, `brivoAccess.${envVarName}`, defaultVal);
    const varExists = typeof val !== 'undefined';
    if (!val && !isProduction && !isStorybook && !varExists) {
        alert(`Environment Variable ${envVarName} is not set!`);
    }
    return val;
}

export const CARD_CREDENTIAL_NAME = 'Card';
export const CREDENTIAL_NAME = 'Credential';
export const PIN_CREDENTIAL_NAME = 'PIN';
export const BRIVO_MOBILE_PASS_CREDENTIAL_NAME = 'Brivo Mobile Pass';
export const LICENSE_PLATE_CREDENTIAL_NAME = 'License plate';
export const EE_LPR_LICENSE_PLATE_CREDENTIAL_NAME = 'EE LPR License plate';
export const FACIAL_ID_CREDENTIAL_NAME = 'Facial Id';
export const UNKNOWN_CREDENTIAL_NAME = 'Unknown credential';
export const UNASSIGNED = 'unassigned';

export const MOBILE_PASS_FORMATS = [BRIVO_MOBILE_PASS_CREDENTIAL_NAME, 'Brivo Mobile Credential'];

export const EDITION_DEMO = 'Demo';
export const EDITION_STANDARD = 'Standard';
export const EDITION_PROFESSIONAL = 'Professional';
export const EDITION_ENTERPRISE = 'Enterprise';
export const EDITION_MULTIFAMILY = 'Multifamily';

export const ADMIN_CAP_STATUS_ACTIVE = 'ACTIVE';
export const ADMIN_CAP_STATUS_INACTIVE = 'INACTIVE';
export const PROMISE_CANCELLED = 'Promise cancelled.';
export const CREDENTIAL_COLLISION = 'Credential already exists in a linked group';
export const GROUP_COLLISION =
    'User cannot be assigned to the group as its credentials already exist in a linked group';
export const GROUPS_LINKING_COLLISION = 'Credential collision between the two groups exists';

export const COLLISION_TYPE = {
    ADD_CARD: 'ADD CARD',
    ADD_LICENSE_PLATE: 'ADD LICENSE PLATE',
    ADD_PIN: 'ADD PIN',
};

export const LAST_NAME = 'last_name';
export const LAST_LOGIN_TIME = 'last_login_time';

export const ARM_LEVEL = {
    DISARM: 'DISARM',
    STAY: 'STAY',
    AWAY: 'AWAY',
    NIGHT: 'NIGHT',
};

export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday'];

export const DEFAULT_PAGE_SIZE = 20;
export const DEVICE_STATUS = 'DEVICE_STATUS';
export const PANEL_EVENT = 'PANEL_EVENT';
export const NOTIFICATION_EVENT = 'NOTIFICATION_EVENT';
export const CALL_EVENT = 'CALL_EVENT';
export const CAMERA_EVENT = 'CAMERA_EVENT';

export const ALL_SCENARIOS_FILTER_ENABLED = 'all__eq:true;hardware__eq:true;';

export const GLOBAL_SCOPE = 'GLOBAL_SCOPE';
export const PAGE_SCOPE = 'PAGE_SCOPE';

export const MAX_LENGTH_NAME = 35;
export const MAX_LENGTH_EMAIL = 74;

export const calculateScrollMarginTop = (detailedErrorMessage) => {
    if (detailedErrorMessage) {
        return 150 + detailedErrorMessage.length * 25;
    }
    return 0;
};

export const PMS_INTEGRATION = 'PMS';
export const API_INTEGRATION = 'API';
export const DORMAKABA_INTEGRATION = 'DORMAKABA';

// APP URLs
export const IAMSVC_SSO_LOGOUT_URL = envVar('VITE_IAMSVC_DOMAIN') + '/api/sso/logout';

export const APP_SSO_LOGIN_URL = `${APP_BASENAME}/auth/sso/:connectionKey`;
export const APP_SSO_LOGGED_OUT_URL = `${APP_BASENAME}/sso/loggedOut`;

// API URLs
export const API_ACCESS_POINTS_URL = `${API_BASE_URL}/access-points`;
export const API_VISITOR_INFO_URL = `${API_BASE_URL}/brivo-visitor`;

// ============ Granular permissions
export const INITIATE_LOCKDOWN_PERMISSION = 'Initiate Lockdown';
export const CLEAR_LOCKDOWN_PERMISSION = 'Clear Lockdown';
export const LOCKDOWN_CREATE_LOCKDOWN_SCENARIO = 'LOCKDOWN_CREATE_LOCKDOWN_SCENARIO';
export const LOCKDOWN_EDIT_LOCKDOWN_SCENARIO = 'LOCKDOWN_EDIT_LOCKDOWN_SCENARIO';
export const LOCKDOWN_DELETE_LOCKDOWN_SCENARIO = 'LOCKDOWN_DELETE_LOCKDOWN_SCENARIO';
export const LOCKDOWN_INITIATE_LOCKDOWN = 'LOCKDOWN_INITIATE_LOCKDOWN';
export const LOCKDOWN_CLEAR_LOCKDOWN = 'LOCKDOWN_CLEAR_LOCKDOWN';
export const ONAIR_GROUP_BASED_LOCKDOWN = 'ONAIR_GROUP_BASED_LOCKDOWN';

// Device
export const WARNING = 'Warning';

// Device Statuses
export const STATUS_CONNECTED = 'CONNECTED';
export const STATUS_DISCONNECTED = 'DISCONNECTED';
export const STATUS_UNKNOWN = 'UNKNOWN';

export const PMS_INTEGRATIONS_WITH_RELATIONSHIPS = ['realpage'];

export const OFFLINE_DOOR_LOCK_ID = 13;
export const PRECONDITION_FAILED = 412;
export const USERNAME_OR_PASSWORD_INVALID = 'Username or password invalid';

export const MAXIMUM_NUMBER_OF_SELECTED_GROUPS = 16;

export const CURRENT_PRIVACY_POLICY_VERSION = '2023-07-23'; //current privacy policy version

export const MIN_FIRMWARE_FOR_GRANT_FLOOR_ACCESS = '6.2.5.5';

// Global Login
export const GLOBAL_LOGIN_TESTING = envVar('VITE_GLOBAL_LOGIN_TESTING');
export const GLOBAL_LOGIN_BASE = envVar('VITE_ALT_AUTHENTICATION_URL');
export const GLOBAL_LOGIN_URL = `${GLOBAL_LOGIN_BASE}/global/index.html?useGlobalLogin=true`;
export const MATCH_ACT_REQUESTS = envVar('VITE_MATCH_ACT_REQUESTS');

//brivo wallet pass statuses
export const ACTIVE_STATUS = 'active';

export const SUSPENDED_STATUS = 'suspended';
export const PENDING_STATUS = 'pending';
export const UNLINKED_STATUS = 'unlinked';

//Site types
export const DEFAULT_SITE_TYPE = 'site';

export const MAXIMUM_RECIPIENTS = 50;
export const MAXIMUM_CHARACTERS = 1000;

// User types
export const VISITOR_TYPE_ID = 2;
export const USER_TYPE_ID = 1;

// Tracking variables
export const DATA_EXPLORER_TRACKING_METRICS = {
    // KPI Tiles
    KPITileLoadEvent: 'KPITile Load Event',
    KPITileAddEvent: 'KPITile Add Event',
    // Tables
    TableLoadEvent: 'Table Load Event',
    TableAddEvent: 'Table Add Event',
    TableDownloadCSVEvent: 'Table Download CSV Event',
    // Pivot Tables
    PivotTableLoadEvent: 'Pivot Table Load Event',
    PivotTableAddEvent: 'Pivot Table Add Event',
    PivotTableDownloadCSVEvent: 'Pivot Table Download CSV Event',
    // Views
    ViewLoadEvent: 'View Load Event',
    ViewAddEvent: 'View Add Event',
};

export const DATA_EXPLORER_TRACKING_STATUS = {
    Success: 'Success',
    Error: 'Error',
};
