import { WebReqApi } from '../WebReqApi';
import {
    GET_ANALYTICS_DASHBOARDS,
    SAVE_ANALYTICS_DASHBOARD,
    SAVE_DASHBOARD_AS_DEFAULT,
    DELETE_PRIVATE_VIEW_FOR_ADMIN,
    RESET_PUBLIC_VIEW_TO_DEFAULT,
} from './gqlTags';

class DashboardsApi extends WebReqApi {
    getAnalyticsDashboards() {
        return this.makeGqlQuery({
            gqlTag: GET_ANALYTICS_DASHBOARDS,
            variables: {},
            throwWhenErrored: true,
        });
    }

    saveAnalyticsDashboard(dashboardId, dashboardName, configurations, sharingType) {
        return this.makeGqlMutation({
            gqlTag: SAVE_ANALYTICS_DASHBOARD,
            variables: {
                analyticsDashboard: {
                    dashboardId,
                    dashboardName,
                    configurations,
                    sharingType,
                },
            },
            throwWhenErrored: true,
        });
    }

    setDashboardAsDefault(dashboardId) {
        return this.makeGqlMutation({
            gqlTag: SAVE_DASHBOARD_AS_DEFAULT,
            variables: {
                dashboardId,
            },
            throwWhenErrored: true,
        });
    }

    deletePrivateAnalyticsViewForAdmin(dashboardId) {
        return this.makeGqlMutation({
            gqlTag: DELETE_PRIVATE_VIEW_FOR_ADMIN,
            variables: {
                dashboardId,
            },
            throwWhenErrored: true,
        });
    }

    resetToCuratedAnalyticsView(dashboardId) {
        return this.makeGqlMutation({
            gqlTag: RESET_PUBLIC_VIEW_TO_DEFAULT,
            variables: {
                dashboardId,
            },
            throwWhenErrored: true,
        });
    }
}

export const dashboardsApi = new DashboardsApi();
