import { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { isEmpty, size } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    LoadingIndicator,
    CountChip,
    ChevronLeft,
    ChevronRight,
    IconButton,
    Tooltip,
    Typography,
    withTheme,
    List,
    ListItem,
} from '@brivo/react-components';
import { withApi } from '@brivo/onairplus-services';
import VideoTimeline from './components/Timeline';
import TimelineZoomButtons from './components/TimelineZoomButtons';
import {
    getNewTimelineStart,
    getNewStartOnZoomOut,
    getNewStartOnZoomIn,
    getCurrentEndTime,
    getDefaultZoomLevel,
    isTimeInRange,
    getVisibleEventCount,
    formatClipsForTimeline,
    formatEventDataForTimeline,
} from './components/Timeline/timelineUtils';
import { filterEventsForSpecificCamera } from '../../events';
import ApiHelper from '../../../../../../common/helpers/Helpers';
import { cancellablePromise } from '../../../../../../common/utils/promiseUtils';
import { PROMISE_CANCELLED } from '../../../../../../common/constants/Constants';
import { TIME_TRAVEL_DIRECTIONS, ZOOM_LEVELS_V2 } from './timelineConstants';
import FilledCircle from './icons/FilledCircle';

import styles from './styles';

// needs to be 999 for v2 timeline
const TIME_WINDOW = 999;
const DISABLED = 'disabled';

const TimelineSection = ({
    api,
    events,
    minStartTime,
    maxEndTime,
    handleClickEventCamera,
    handleClickTimelineProviderClip,
    timelineEndTimestampRef,
    timelineRef,
    removeSelectedClip,
    selectedEventId,
    selectedCamera,
    isV2 = false,
    theme,
    isClipClicked,
    setUnifiedCurrentStart = null,
    setUnifiedCurrentEnd = null,
    setClipsCount = null,
}) => {
    const classes = styles();
    const { t } = useTranslation();
    const [zoomLevel, setZoomLevel] = useState(
        isV2 ? ZOOM_LEVELS_V2.HOURS_8 : getDefaultZoomLevel(minStartTime, maxEndTime, isV2)
    ); // default zoom level for Unified Live & Recorded to 8 hour window
    const [clips, setClips] = useState({});
    const [loadingClips, setLoadingClips] = useState(null);
    const [currentStart, setCurrentStart] = useState(isV2 ? maxEndTime - zoomLevel : minStartTime);
    const [previousMinStart, setPreviousMinStart] = useState(minStartTime);
    const [previousSelectedEvent, setPreviousSelectedEvent] = useState(null);

    const eventTypes = [
        { type: 'info', color: theme.palette.severityIcon.background.informational },
        { type: 'warning', color: theme.palette.severityIcon.background.warning },
        { type: 'critical', color: theme.palette.severityIcon.background.critical },
    ];

    const selectedEvent = events?.find((event) => event.id === selectedEventId);
    const selectedEventTime = selectedEvent ? Date.parse(selectedEvent.occurred) : null;

    useEffect(() => {
        if (isV2 && new Date(maxEndTime).toLocaleDateString() === new Date().toLocaleDateString()) {
            setCurrentStart(maxEndTime - zoomLevel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isV2, minStartTime, maxEndTime]);

    if (previousMinStart !== minStartTime && !selectedEventId) {
        setCurrentStart(minStartTime);
        setPreviousMinStart(minStartTime);
    }

    if (selectedEventId && selectedEventId !== previousSelectedEvent) {
        setCurrentStart(
            getNewStartOnZoomIn({
                timelineMinStart: minStartTime,
                timelineMaxEnd: maxEndTime,
                selectedTime: selectedEventTime,
                zoomLevel,
            })
        );
        setPreviousSelectedEvent(selectedEventId);
    }

    const currentEnd = getCurrentEndTime(maxEndTime, currentStart, zoomLevel);

    const handleSetZoomLevel = (event, newZoomLevel) => {
        if (newZoomLevel !== zoomLevel && newZoomLevel !== null) {
            //it ends up as null if you click the same button twice, not sure why.
            setZoomLevel(newZoomLevel);
            if (currentStart > maxEndTime - newZoomLevel) {
                const newStart = getNewStartOnZoomOut(minStartTime, currentEnd, newZoomLevel);
                setCurrentStart(newStart);
            } else {
                let selectedTime;
                try {
                    selectedTime = Date.parse(timelineRef.current.timeline.getCustomTime('timeline-position'));
                } catch (e) {
                    return;
                }
                if (selectedTime && isTimeInRange(selectedTime, currentStart, currentEnd)) {
                    setCurrentStart(
                        getNewStartOnZoomIn({
                            timelineMinStart:
                                isV2 && new Date(maxEndTime).toLocaleDateString() === new Date().toLocaleDateString()
                                    ? maxEndTime - newZoomLevel
                                    : minStartTime,
                            timelineMaxEnd: maxEndTime,
                            selectedTime,
                            zoomLevel: newZoomLevel,
                        })
                    );
                }
            }
        }
    };

    const handleGoBack = (currentStart, currentEnd) => {
        const newStartTime = getNewTimelineStart(currentStart, currentEnd, {
            timelineMinStart: minStartTime,
            timelineMaxEnd: maxEndTime,
            zoomLevel: zoomLevel,
            direction: TIME_TRAVEL_DIRECTIONS.PAST,
        });

        setCurrentStart(newStartTime);
    };

    const handleGoForward = (currentStart, currentEnd) => {
        const newStartTime = getNewTimelineStart(currentStart, currentEnd, {
            timelineMinStart: minStartTime,
            timelineMaxEnd: maxEndTime,
            zoomLevel: zoomLevel,
            direction: TIME_TRAVEL_DIRECTIONS.FUTURE,
        });

        setCurrentStart(newStartTime);
    };

    const clipsForTimeline = useMemo(
        () =>
            clips
                ? formatClipsForTimeline(clips, {
                      timelineMinStart: minStartTime,
                      timelineMaxEnd: maxEndTime,
                  })
                : [],
        [clips, maxEndTime, minStartTime]
    );
    const eventsForTimeline = formatEventDataForTimeline(events, zoomLevel, isV2); //use callback?

    const handleJumpClips = (position) => {
        let currentTime;
        let clipToJump;

        try {
            currentTime = Date.parse(timelineRef.current.timeline.getCustomTime('timeline-position'));
        } catch (e) {
            return;
        }

        const isCurrentTimeInRange =
            typeof currentTime === 'number' && isTimeInRange(currentTime, currentStart - TIME_WINDOW, currentEnd);
        if (isCurrentTimeInRange && !isEmpty(clipsForTimeline)) {
            const filteredClips = clipsForTimeline?.filter((clip) => {
                if (position === 'next') {
                    // adding TIME_WINDOW ms, since the calculation is not fully accurate to the ms
                    return clip.start > currentTime + TIME_WINDOW;
                }

                // subtracting TIME_WINDOW ms, in case
                return clip.start < currentTime - TIME_WINDOW;
            });

            if (!isEmpty(filteredClips)) {
                if (position === 'next') {
                    clipToJump = filteredClips[0];
                } else {
                    clipToJump = filteredClips[filteredClips.length - 1];
                }

                if (!isTimeInRange(clipToJump.start, currentStart - TIME_WINDOW, currentEnd)) {
                    setCurrentStart(clipToJump.start);
                }

                return handleClickTimelineProviderClip(
                    { startTime: clipToJump.start, endTime: clipToJump.end, cameraId: selectedCamera.cameraId },
                    selectedCamera.cameraName,
                    true // updateTimelinePositionMarker
                );
            }
        }
    };

    const shouldShowJumpClipDirection = (direction) => {
        let currentTime;

        try {
            currentTime = Date.parse(timelineRef.current.timeline.getCustomTime('timeline-position'));
        } catch (e) {
            if (isEmpty(clipsForTimeline) || !currentTime) {
                if (direction === 'previous') {
                    return false;
                }
                if (!isEmpty(clipsForTimeline) && direction === 'next') {
                    return true;
                }
            }
        }

        const isCurrentTimeInRange =
            typeof currentTime === 'number' && isTimeInRange(currentTime, currentStart - TIME_WINDOW, currentEnd);
        const filteredClips = clipsForTimeline?.filter((clip) => {
            if (direction === 'next') {
                // adding TIME_WINDOW ms, since the calculation is not fully accurate to the ms
                return clip.start > currentTime + TIME_WINDOW;
            }

            // subtracting TIME_WINDOW ms, in case
            return clip.start < currentTime - TIME_WINDOW;
        });

        if (isCurrentTimeInRange && !isEmpty(clipsForTimeline)) {
            if (size(filteredClips) >= 1) {
                return true;
            } else {
                return false;
            }
        } else if (!isCurrentTimeInRange && !isEmpty(clipsForTimeline)) {
            return DISABLED;
        } else {
            return false;
        }
    };

    // old recorded video page
    useEffect(() => {
        if (!isV2) {
            let ignore = false;
            let clipListPromise;
            if (!ignore && selectedCamera?.cameraId) {
                setLoadingClips(true);
                (async function () {
                    clipListPromise = cancellablePromise(
                        api.getClipList(
                            [selectedCamera?.cameraId],
                            new Date(minStartTime).toISOString(),
                            new Date(maxEndTime).toISOString()
                        )
                    );

                    clipListPromise.promise
                        .then(
                            (result) => {
                                if (!ignore) {
                                    setClips(result ?? {});
                                }
                            },
                            (error) => {
                                if (error.message === PROMISE_CANCELLED) {
                                    if (!ignore) {
                                        setClips({});
                                    }
                                }
                            }
                        )
                        .finally(() => {
                            if (!ignore) {
                                setLoadingClips(false);
                            }
                        });
                })();
            } else {
                setClips({});
            }
            return () => {
                ignore = true;
                clipListPromise?.cancel();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCamera?.cameraId, minStartTime, maxEndTime]);

    useEffect(() => {
        setUnifiedCurrentStart && setUnifiedCurrentStart(currentStart);
        setUnifiedCurrentEnd && setUnifiedCurrentEnd(currentEnd);
    }, [currentStart, currentEnd, setUnifiedCurrentStart, setUnifiedCurrentEnd]);

    // used for unified video
    // when view window changes, fetch clips
    useEffect(() => {
        if (isV2) {
            let ignore = false;
            let clipListPromise;
            if (!ignore && selectedCamera?.cameraId) {
                setLoadingClips(true);
                (async function () {
                    clipListPromise = cancellablePromise(
                        api.getClipList(
                            [selectedCamera?.cameraId],
                            new Date(currentStart).toISOString(),
                            new Date(currentEnd).toISOString()
                        )
                    );

                    clipListPromise.promise
                        .then(
                            (result) => {
                                if (!ignore) {
                                    setClips(result ?? {});
                                    setClipsCount(result ? size(result[selectedCamera?.cameraId]) : 0);
                                }
                            },
                            (error) => {
                                if (error.message === PROMISE_CANCELLED) {
                                    if (!ignore) {
                                        setClips({});
                                        setClipsCount(0);
                                    }
                                }
                            }
                        )
                        .finally(() => {
                            if (!ignore) {
                                setLoadingClips(false);
                            }
                        });
                })();
            } else {
                setClips({});
            }
            return () => {
                ignore = true;
                clipListPromise?.cancel();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCamera?.cameraId, currentStart, currentEnd]);

    //see if useMemo would help here
    const eventsForSelectedCamera = selectedCamera?.cameraId
        ? filterEventsForSpecificCamera(events, selectedCamera?.cameraId)
        : [];

    const currentlyVisibleEventsCount = getVisibleEventCount(eventsForSelectedCamera, currentStart, currentEnd);

    const shouldRenderTimeline = events || !selectedCamera?.cameraId;

    const hasPreviousJumpState = shouldShowJumpClipDirection('previous');
    const hasNextJumpState = shouldShowJumpClipDirection('next');

    return (
        <Grid container direction="column" spacing={0}>
            <Grid
                item
                container
                xs={12}
                className={clsx(classes.timelineFiltersContainer, {
                    [classes.timelineFiltersContainerJustified]: isV2,
                })}
            >
                {isV2 && (
                    <Grid item container className={classes.jumpClipLoadingText} xs={2} justifyContent="flex-start">
                        <Grid item>
                            {loadingClips && (
                                <Typography>{t('Page.unified-video.video-modal.clips.loading-events')}</Typography>
                            )}
                        </Grid>
                    </Grid>
                )}

                {isV2 && shouldRenderTimeline && !isEmpty(clipsForTimeline) && (
                    <Grid item container className={classes.jumpClipContainer} xs={4} justifyContent="flex-end">
                        <Grid item>
                            <IconButton
                                size="small"
                                className={clsx(classes.jumpClipsToggle, {
                                    [classes.hidden]: !hasPreviousJumpState,
                                    [classes.disabled]: hasPreviousJumpState === DISABLED,
                                })}
                                icon={<ChevronLeft />}
                                onClick={() => handleJumpClips('prev')}
                                disabled={hasPreviousJumpState === DISABLED}
                            />
                            {t('Page.unified-video.video-modal.clips.jump-clip')}
                            <IconButton
                                size="small"
                                className={clsx(classes.jumpClipsToggle, {
                                    [classes.hidden]: !hasNextJumpState,
                                    [classes.disabled]: hasNextJumpState === DISABLED,
                                })}
                                icon={<ChevronRight />}
                                onClick={() => handleJumpClips('next')}
                                disabled={hasNextJumpState === DISABLED}
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid item md={!isV2 && 6}>
                    {shouldRenderTimeline ? (
                        <TimelineZoomButtons
                            currentZoomLevel={zoomLevel}
                            handleSetZoomLevel={handleSetZoomLevel}
                            isV2={isV2}
                        />
                    ) : null}
                </Grid>
            </Grid>

            {shouldRenderTimeline && !isV2 && (
                <div className={classes.visibleEventCount}>
                    <CountChip
                        number={currentlyVisibleEventsCount.toLocaleString()}
                        size="medium"
                        label={
                            currentlyVisibleEventsCount === 1
                                ? t('Page.recorded-video.event')
                                : t('Page.recorded-video.events')
                        }
                        type="secondary"
                    />
                </div>
            )}
            <Grid item className={classes.timelineContainer}>
                {shouldRenderTimeline ? (
                    <>
                        <Grid item container>
                            <VideoTimeline
                                events={eventsForSelectedCamera}
                                clips={clips}
                                eventsForTimelineV2={eventsForTimeline}
                                clipsForTimelineV2={clipsForTimeline}
                                selectedCamera={selectedCamera}
                                selectedEventTime={selectedEventTime}
                                minStartTime={minStartTime}
                                maxEndTime={maxEndTime}
                                timelineStart={currentStart}
                                setCurrentStart={setCurrentStart}
                                timelineEnd={currentEnd}
                                goForward={handleGoForward}
                                goBack={handleGoBack}
                                zoomLevel={zoomLevel}
                                removeSelectedClip={removeSelectedClip}
                                handleClickEventCamera={handleClickEventCamera}
                                handleClickTimelineProviderClip={handleClickTimelineProviderClip}
                                timelineEndTimestampRef={timelineEndTimestampRef}
                                timelineRef={timelineRef}
                                isV2={isV2}
                                isClipClicked={isClipClicked}
                            />
                        </Grid>
                        {isV2 && (
                            <Grid item xs={11} className={classes.timelineTooltip}>
                                <Tooltip
                                    title={
                                        <>
                                            <span className={classes.eventLegendTitle}>
                                                {t('Navigation.event-classification.page-title')}
                                            </span>
                                            <List>
                                                {eventTypes.map((eventType, index) => (
                                                    <ListItem
                                                        className={classes.eventClassification}
                                                        key={`${eventType.type}-${index}`}
                                                    >
                                                        <span className={classes.eventCircle}>
                                                            <FilledCircle
                                                                fill={eventType.color}
                                                                height={12}
                                                                width={12}
                                                                size={12}
                                                                // svg inline-styles
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '12px',
                                                                    width: '12px',
                                                                }}
                                                            />
                                                        </span>
                                                        {t(`Page.event-classification.${eventType.type}-column`)}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    }
                                    placement="top-end"
                                    size="medium"
                                >
                                    <span className={classes.eventTooltipTitle}>
                                        {t('Page.unified-video.video-modal.event-classification.tooltip')}
                                    </span>
                                </Tooltip>
                            </Grid>
                        )}
                    </>
                ) : (
                    <LoadingIndicator />
                )}
            </Grid>
        </Grid>
    );
};

export default withTheme(withApi(TimelineSection, ApiHelper));
