import { useEffect, useState } from 'react';
import useStyles from './styles';
import MyAnalyticsTable from '@/pages/BrivoAnalytics/components/Table/MyAnalyticsTable';
import { Box, Typography, LoadingIndicator } from '@brivo/react-components';
import { useQueryEditor } from './QueryEditorContext';
import ColumnFilters from './ColumnFilters';
import SaveChangesBar from './SaveChangesBar';
import { Dimension, Query } from '../../common/interfaces';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface TableEditorProps {
    query: Query;
    dimensions: Dimension[];
}

const TableEditor = ({ query, dimensions }: TableEditorProps) => {
    const classes = useStyles();
    const QueryEditor: any = useQueryEditor();
    const { t } = useTranslation();
    const [isTableLoading, setTableLoadingState] = useState(true);

    useEffect(() => {
        if (query && dimensions) {
            QueryEditor.setInitialQuery(query);
            QueryEditor.setInitialDimensions(dimensions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadingStateChange = (isLoading: boolean) => {
        setTableLoadingState(isLoading);
    };

    const mockMyAnalyticsTableProps = {
        address: '0,3',
        configuration: {
            width: 12,
            height: 1,
            type: 'table',
            headerKey: 'Page.new-reports.header.user-activity-report',
            headerTooltipKey: 'Page.new-reports.header-tooltip.user-activity-report',
            dimensions: QueryEditor?.dimensions ?? [],
            query: QueryEditor.query ?? {},
            header: 'User Activity Report',
            headerTooltip:
                'The dataset includes events, users, sites, & device information. Useful for creating activity reports that include where the event occurred and any user related custom fields.',
            uuid: '622d9f94-e109-4953-b432-1564eeb034a9',
        },
        idx: 3,
        dashboardConf: {},
    };

    const fadeInStyle = {
        opacity: isTableLoading ? 0 : 1,
        transform: isTableLoading ? 'translateY(15px)' : 'translateY(0px)',
        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    };

    return (
        <Box className={classes.wrapper}>
            <div id="table-editor-wrapper">
                <Box className={classes.columnEditor}>
                    <ColumnFilters disabled={isTableLoading} query={query} />
                </Box>
                <div style={fadeInStyle}>
                    <Box className={clsx(classes.table)}>
                        <MyAnalyticsTable
                            {...mockMyAnalyticsTableProps}
                            tableHeight={500}
                            onLoadingStateChange={handleLoadingStateChange}
                        />
                    </Box>
                </div>
            </div>
            {/* Loading State */}
            {isTableLoading && (
                <div id="loading">
                    <LoadingIndicator show />
                    <Typography className={classes.loadingText}>{t('Page.new-reports.loading-report')}</Typography>
                </div>
            )}
            {QueryEditor?.hasEditsBeenMade && <SaveChangesBar />}
        </Box>
    );
};

export default TableEditor;
