import { makeStyles } from '@brivo/react-components';
const styles = makeStyles(() => {
    return {
        customToggleButtons: {
            width: 'unset',
            maxWidth: '8rem',
            padding: '15px',
            '& span': {
                maxHeight: '100%',
            },
        },
    };
});
export default styles;
