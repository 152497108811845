import React, { useState, useEffect, useCallback, useContext } from 'react';
import { sortBy, unionBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    withTheme,
    InputLabel,
    Grid,
    Card,
    CardContent,
    makeStyles,
    MuiLockOpen,
    Button,
    SingleSelectFilter,
    LoadingIndicator,
    Lock,
    Snackbar,
} from '@brivo/react-components';

import { withApi, useFlagClient, checkPermissions } from '@brivo/onairplus-services';
import { INITIATE_LOCKDOWN_PERMISSION, CLEAR_LOCKDOWN_PERMISSION } from '@common/constants/Constants';

import ApiHelper from '../../common/helpers/Helpers';
import { UserContext } from '../../common/user/UserProvider';
import AccessControl from '../../common/components/AccessControl';
import PageHeader from '../../common/components/PageHeader/PageHeader';

import { LOCKDOWN_CLEAR_LOCKDOWN, LOCKDOWN_INITIATE_LOCKDOWN } from '../../common/permissions/Permissions';
import { GQLProviderContext } from '../../common/utils/CustomApolloProvider';
import { lockdownApi } from '../../common/webApis/lockdown/lockdownApi';

const useStyles = makeStyles(() => ({
    lockdownScenarioSelect: {
        '& p': {
            width: '100px',
        },
    },
}));

export const ID_FOR_FULL_LOCKDOWN = 'full-lockdown';
export const MAX_NUMBER_OF_DOORS = 300;

const Lockdown = ({ api, fetchIsLockdownActive }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const lockdownDropdownMapper = useCallback((item) => ({ id: item.id, name: item.name }), []);

    const [lockdownScenarios, setLockdownScenarios] = useState(null);
    const [selectedLockdownScenario, setSelectedLockdownScenario] = useState(null);
    const [selectedClearLockdownScenario, setSelectedClearLockdownScenario] = useState(null);
    const [activeLockdowns, setActiveLockdowns] = useState([]);
    const [initiateLockdownButtonEnabled, setInitiateLockdownButtonEnabled] = useState(true);
    const [lockdownLoading, setLockdownLoading] = useState(false);
    const [isAllLockdown, setIsAllLockdown] = useState(false);
    const notifications = Snackbar();
    const { permissions } = useContext(UserContext);
    const gqlHelper = useContext(GQLProviderContext);

    const flagClient = useFlagClient();
    const enableLockdownAssignments = flagClient.variation('enable-lockdown-assignments', false);

    const hasInitiatePermission = checkPermissions({
        userPermissions: permissions,
        anyOfPermissions: [
            INITIATE_LOCKDOWN_PERMISSION,
            LOCKDOWN_INITIATE_LOCKDOWN,
            CLEAR_LOCKDOWN_PERMISSION,
            LOCKDOWN_CLEAR_LOCKDOWN,
        ],
    });

    const fetchLockdownScenarios = useCallback(async () => {
        if (!hasInitiatePermission) {
            return [];
        }
        const activeLockdownScenariosResponse = await api.getLockdownActiveScenarios();
        const activeNonHardwiredLockdownScenarios = activeLockdownScenariosResponse.data;

        const lockdownScenariosIncludingHardwiredLockdowns =
            await api.getLockDownScenariosIncludingHardwiredLockdowns();
        const activeLockdownsIncludingHardwiredLockdowns = lockdownScenariosIncludingHardwiredLockdowns.data?.filter(
            (lockdownScenario) => {
                return lockdownScenario.issued;
            }
        );

        const { identityType } = await api.getCurrentUser();

        let inactiveLockdownScenarios = [];
        let allActiveLockdowns = [];
        let isAllLockdownAssignments;
        if (enableLockdownAssignments) {
            const data = await gqlHelper.whoami();
            const whoami = data?.whoami;
            const assignments = whoami?.assignments;
            isAllLockdownAssignments = assignments?.lockdowns?.isAll;
            let lockdownAssignments = [];
            if (isAllLockdownAssignments) {
                setIsAllLockdown(true);
                const assignmentOnairObjectsFromAppsync = await gqlHelper.getAssignmentOnairObjects();
                lockdownAssignments = assignmentOnairObjectsFromAppsync?.getLockdowns;
            } else {
                lockdownAssignments = assignments?.lockdowns?.assignments?.map((el) => {
                    return {
                        id: el?.lockdown?.id,
                        name: el?.lockdown?.name,
                        isDefault: el?.lockdown?.isDefault,
                        issued: el?.lockdown?.issued,
                    };
                });
            }
            inactiveLockdownScenarios = lockdownAssignments?.filter((lockdownScenario) => !lockdownScenario.issued);
        } else {
            const lockdownScenarios = await api.getLockdownScenarios();
            inactiveLockdownScenarios = lockdownScenarios.data?.filter((lockdownScenario) => !lockdownScenario.issued);
        }

        const response = await lockdownApi.isLessThanTotalDoors(MAX_NUMBER_OF_DOORS);
        const isLessThanTotalDoors = response.isLessThanTotalDoors;

        allActiveLockdowns = unionBy(
            activeNonHardwiredLockdownScenarios,
            activeLockdownsIncludingHardwiredLockdowns,
            'id'
        );
        const defaultLockdownScenario =
            inactiveLockdownScenarios?.find((lockdownScenario) => lockdownScenario.isDefault) ||
            allActiveLockdowns.find((s) => s.isDefault);

        const isFullLockdownActive =
            allActiveLockdowns.length && allActiveLockdowns.some((item) => !item.name && item.isDefault);

        if (isFullLockdownActive) {
            allActiveLockdowns.forEach((activeLockdown) => {
                if (!activeLockdown.name && activeLockdown.fullLockdownForEntireAccount) {
                    activeLockdown.name = t('Page.lockdown.scenario.default');
                    activeLockdown.id = ID_FOR_FULL_LOCKDOWN;
                }
            });
        }

        setActiveLockdowns(allActiveLockdowns);

        let isFullLockdownDisplayed = false;
        isFullLockdownDisplayed = enableLockdownAssignments
            ? !defaultLockdownScenario &&
              isAllLockdownAssignments &&
              !isFullLockdownActive &&
              identityType !== 'ASSISTANT' &&
              isLessThanTotalDoors
            : !defaultLockdownScenario && !isFullLockdownActive && identityType !== 'ASSISTANT';

        if (defaultLockdownScenario) {
            setSelectedLockdownScenario(defaultLockdownScenario);
        } else if (isFullLockdownDisplayed) {
            setSelectedLockdownScenario({ id: ID_FOR_FULL_LOCKDOWN, name: t('Page.lockdown.scenario.default') });
        }

        if (isFullLockdownDisplayed && isLessThanTotalDoors) {
            inactiveLockdownScenarios &&
                inactiveLockdownScenarios.push({
                    id: ID_FOR_FULL_LOCKDOWN,
                    name: t('Page.lockdown.scenario.default'),
                });
        }

        setLockdownScenarios(sortBy(inactiveLockdownScenarios, (scenario) => scenario.name.toUpperCase()));
    }, [hasInitiatePermission, api, enableLockdownAssignments, gqlHelper, t]);

    useEffect(() => {
        fetchLockdownScenarios();
    }, [fetchLockdownScenarios]);

    const handleLockdownScenarioChange = (event) => {
        if (event) {
            setSelectedLockdownScenario({ id: event.id, name: event.name });
        } else {
            setSelectedLockdownScenario(null);
        }
    };

    const handleClearLockdownScenarioChange = (event) => {
        if (event?.id === undefined) {
            setSelectedClearLockdownScenario(null);
        } else {
            setSelectedClearLockdownScenario({ id: event.id, name: event.name });
        }
    };

    const initiateLockdown = async () => {
        setInitiateLockdownButtonEnabled(false);
        setLockdownLoading(true);

        const response =
            selectedLockdownScenario.id === ID_FOR_FULL_LOCKDOWN
                ? await api.initiateFullLockdown()
                : await api.initiateLockdown(selectedLockdownScenario.id);

        setInitiateLockdownButtonEnabled(true);

        if (!response || response.didAllFail) {
            notifications.addErrorMessage({ text: t('Page.lockdown.bottom-bar-fail') });
        } else {
            notifications.addSuccessMessage({ text: t('Page.lockdown.bottom-bar-success') });
            setSelectedLockdownScenario(null);
            fetchIsLockdownActive();
            await fetchLockdownScenarios();
        }
        setLockdownLoading(false);
    };

    const clearLockdowns = async () => {
        try {
            setLockdownLoading(true);
            const response = await api.clearAllLockdowns();

            if (!response || !response.message) {
                notifications.addErrorMessage({ text: t('Page.lockdown.bottom-bar-fail-clear') });
            } else {
                if (response.atLeastOneLockdownCleared) {
                    fetchIsLockdownActive();
                    await fetchLockdownScenarios();
                }
                notifications.addSuccessMessage({ text: response.message });
            }
        } catch (e) {
            notifications.addErrorMessage({ text: t('Page.lockdown.bottom-bar-fail-clear') });
        }
        setLockdownLoading(false);
    };

    const clearSelectedLockdown = async () => {
        setLockdownLoading(true);
        const response =
            selectedClearLockdownScenario.id === ID_FOR_FULL_LOCKDOWN
                ? await api.clearAccountDefaultLockdown()
                : await lockdownApi.clearLockdownScenario(selectedClearLockdownScenario.id);

        if (!response || response.didAllFail) {
            notifications.addErrorMessage({ text: t('Page.lockdown.fail-cleared-lockdown') });
        } else {
            setSelectedClearLockdownScenario(null);
            fetchIsLockdownActive();
            await fetchLockdownScenarios();
            notifications.addSuccessMessage({ text: t('Page.lockdown.successful-cleared-lockdown') });
        }
        setLockdownLoading(false);
    };

    if (!lockdownScenarios) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <PageHeader title={t('Page.lockdown.title')} />
            <Grid container spacing={3}>
                <AccessControl anyOfPermissions={[INITIATE_LOCKDOWN_PERMISSION, LOCKDOWN_INITIATE_LOCKDOWN]}>
                    <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="card-initiate" className="u-mb-half">
                            {t('Page.lockdown.initiate-lockdown-label')}
                        </InputLabel>
                        <Card
                            id="card-initiate"
                            style={{
                                minHeight: '9rem',
                                height: '100%',
                            }}
                        >
                            <CardContent style={{ height: '100%' }}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-evenly"
                                    style={{ marginTop: '35px' }}
                                >
                                    <div style={{ marginBlock: '10px' }}>
                                        <SingleSelectFilter
                                            id="initiate-lockdown-scenarios-select"
                                            label={t('Page.lockdown.select-scenario')}
                                            onValueChange={handleLockdownScenarioChange}
                                            value={selectedLockdownScenario}
                                            items={lockdownScenarios}
                                            mapper={lockdownDropdownMapper}
                                            className={classes.lockdownScenarioSelect}
                                            fullWidth={false}
                                            noResultMessage={t('Page.lockdown.no-scenario-available')}
                                            noSelectionLabel={''}
                                        />
                                    </div>
                                    <Button
                                        type="error"
                                        onClick={initiateLockdown}
                                        id="initiate-lockdown-button"
                                        disabled={
                                            !lockdownScenarios.length ||
                                            !initiateLockdownButtonEnabled ||
                                            !selectedLockdownScenario ||
                                            lockdownLoading
                                        }
                                        data-testid="initiate-lockdown-button"
                                        startIcon={<Lock fontSize="small" className="u-mr-half" />}
                                        text={t('Page.lockdown.initiate-lockdown-btn')}
                                        size={'medium'}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </AccessControl>
                <AccessControl anyOfPermissions={[CLEAR_LOCKDOWN_PERMISSION, LOCKDOWN_CLEAR_LOCKDOWN]}>
                    <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="card-active" style={{ marginBottom: '0.5rem' }}>
                            {t('Page.lockdown.active-lockdowns-label')}
                        </InputLabel>
                        <Card
                            id="card-active"
                            style={{
                                minHeight: '9rem',
                                height: '100%',
                            }}
                        >
                            <CardContent style={{ height: '100%' }}>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-evenly"
                                    style={{ marginTop: '35px' }}
                                >
                                    <div style={{ marginBlock: '10px' }}>
                                        <SingleSelectFilter
                                            id="clear-lockdown-scenarios-select"
                                            label={t('Page.lockdown.select-scenario')}
                                            onValueChange={handleClearLockdownScenarioChange}
                                            value={selectedClearLockdownScenario}
                                            items={activeLockdowns}
                                            mapper={lockdownDropdownMapper}
                                            className={classes.lockdownScenarioSelect}
                                            fullWidth={false}
                                            noResultMessage={t('Page.lockdown.no-scenario-available')}
                                            noSelectionLabel={''}
                                        />
                                    </div>
                                    <Button
                                        size="medium"
                                        onClick={clearSelectedLockdown}
                                        id="clear-selected-lockdown-button"
                                        disabled={!selectedClearLockdownScenario || lockdownLoading}
                                        startIcon={<MuiLockOpen fontSize="small" className="u-mr-half" />}
                                        text={t('Page.lockdown.clear-selected-lockdown-btn')}
                                        type={'secondary'}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-around"
                                    style={{ marginTop: '25px' }}
                                >
                                    <Button
                                        size="large"
                                        onClick={clearLockdowns}
                                        id="clear-all-lockdowns-button"
                                        disabled={
                                            activeLockdowns?.length === 0 ||
                                            lockdownLoading ||
                                            (enableLockdownAssignments && !isAllLockdown)
                                        }
                                        startIcon={<MuiLockOpen fontSize="small" className="u-mr-half" />}
                                        text={t('Page.lockdown.clear-lockdowns-btn')}
                                        customWidth="270px"
                                        type={'primary'}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </AccessControl>
            </Grid>
            {lockdownLoading && (
                <div className="u-fx u-fx-justify-center u-mt-full">
                    <LoadingIndicator shouldHavePadding={false} />
                </div>
            )}
        </>
    );
};

export default withTheme(withApi(Lockdown, ApiHelper));
