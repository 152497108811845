import React from 'react';
import { useTranslation } from 'react-i18next';

import { SaveIcon, Snackbar } from '@brivo/react-components';

import styles from './styles';
import { getClipForDownload } from '../../../../../../../../../../utils/clipUtils';

const DownloadClip = ({ event, cameraId, api, imgSrc }) => {
    const classes = styles();
    const { t } = useTranslation();
    const notifications = Snackbar();

    const handleClickDownload = async ({ event, cameraId, api }) => {
        await getClipForDownload(event, cameraId, api, notifications, t);
    };
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                handleClickDownload({ event, cameraId, api });
            }}
            data-testid={`downloadClipButton-event-${event.id}-camera-${cameraId}`}
        >
            <SaveIcon className={imgSrc ? classes.downloadIconPreview : classes.downloadIconSansPreview} />
        </div>
    );
};
export default DownloadClip;
