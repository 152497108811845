import gql from 'graphql-tag';

export const DEVICE_STATUS_PAGE_DOOR_STATUS_QUERY = gql`
    query DoorStatus {
        getDOORSTATUSData {
            data {
                deviceName
                deviceId
                siteId
                reportLiveStatus
                controlPanelId
                lockState
                deviceStatus
                systemStatus
                systemStatusMessage
                messageReceivedTime
                isActive
                threatLevel
            }
            rawData {
                connectedStatus
                nextUnlockScheduleChange
                nextIgnoreScheduleChange
                unlockScheduleStatus
                ignoreScheduleStatus
                accessState
                ajarStatus
                inputStatus
                intrusionStatus
                batteryState
                batteryRemaining
                doorState
                threatLevel
                peripheralInfo {
                    type
                    networkInfo {
                        networkStatus
                    }
                }
            }
        }
    }
`;
