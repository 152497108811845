import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => ({
    trendDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    greenColor: {
        color: theme.palette.colors.green500,
    },
    redColor: {
        color: theme.palette.colors.red400,
    },
}));

export default useStyles;
