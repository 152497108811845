export interface TimeZone {
    label: string;
    value: string;
    standardizedValue?: string;
}

export const getTimeZones = (showIstTimezoneFF: boolean): TimeZone[] => {
    const timeZones: TimeZone[] = [
        {
            label: '(GMT-12:00) International Date Line West',
            value: 'Etc/GMT+12',
        },
        {
            label: '(GMT-11:00) Midway Island, Samoa',
            value: 'Pacific/Midway',
            standardizedValue: 'Pacific/Midway',
        },
        {
            label: '(GMT-10:00) Hawaii',
            value: 'Pacific/Honolulu',
            standardizedValue: 'Pacific/Honolulu',
        },
        {
            label: '(GMT-09:00) Alaska',
            value: 'US/Alaska',
            standardizedValue: 'America/Anchorage',
        },
        {
            label: '(GMT-08:00) Pacific Time (US & Canada)',
            value: 'US/Pacific',
            standardizedValue: 'America/Los_Angeles',
        },
        {
            label: '(GMT-08:00) Tijuana, Baja California',
            value: 'America/Tijuana',
            standardizedValue: 'America/Tijuana',
        },
        {
            label: '(GMT-07:00) Arizona',
            value: 'US/Arizona',
            standardizedValue: 'America/Phoenix',
        },
        {
            label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
            value: 'America/Chihuahua',
            standardizedValue: 'America/Mexico_City',
        },
        {
            label: '(GMT-07:00) Mountain Time (US & Canada)',
            value: 'US/Mountain',
            standardizedValue: 'America/Denver',
        },
        {
            label: '(GMT-06:00) Central America',
            value: 'America/Managua',
            standardizedValue: 'America/Managua',
        },
        {
            label: '(GMT-06:00) Central Time (US & Canada)',
            value: 'US/Central',
            standardizedValue: 'America/Chicago',
        },
        {
            label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
            value: 'America/Mexico_City',
            standardizedValue: 'America/Mexico_City',
        },
        {
            label: '(GMT-06:00) Saskatchewan',
            value: 'Canada/Saskatchewan',
            standardizedValue: 'America/Regina',
        },
        {
            label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
            value: 'America/Bogota',
            standardizedValue: 'America/Bogota',
        },
        {
            label: '(GMT-05:00) Eastern Time (US & Canada)',
            value: 'US/Eastern',
            standardizedValue: 'America/New_York',
        },
        {
            label: '(GMT-05:00) Indiana (East)',
            value: 'US/East-Indiana',
            standardizedValue: 'America/New_York',
        },
        {
            label: '(GMT-04:00) Atlantic Time (Canada)',
            value: 'Canada/Atlantic',
            standardizedValue: 'America/Halifax',
        },
        {
            label: '(GMT-04:00) Caracas, La Paz',
            value: 'America/Caracas',
            standardizedValue: 'America/Caracas',
        },
        {
            label: '(GMT-04:00) Manaus',
            value: 'America/Manaus',
            standardizedValue: 'America/Manaus',
        },
        {
            label: '(GMT-04:00) Santiago',
            value: 'America/Santiago',
            standardizedValue: 'America/Santiago',
        },
        {
            label: '(GMT-03:30) Newfoundland',
            value: 'Canada/Newfoundland',
            standardizedValue: 'America/St_Johns',
        },
        {
            label: '(GMT-03:00) Brasilia',
            value: 'America/Sao_Paulo',
            standardizedValue: 'America/Sao_Paulo',
        },
        {
            label: '(GMT-03:00) Buenos Aires, Georgetown',
            value: 'America/Argentina/Buenos_Aires',
            standardizedValue: 'America/Argentina/Buenos_Aires',
        },
        {
            label: '(GMT-03:00) Greenland',
            value: 'America/Godthab',
            standardizedValue: 'America/Nuuk',
        },
        {
            label: '(GMT-03:00) Montevideo',
            value: 'America/Montevideo',
            standardizedValue: 'America/Montevideo',
        },
        {
            label: '(GMT-02:00) Mid-Atlantic',
            value: 'America/Noronha',
            standardizedValue: 'America/Noronha',
        },
        {
            label: '(GMT-01:00) Cape Verde Is.',
            value: 'Atlantic/Cape_Verde',
            standardizedValue: 'Atlantic/Cape_Verde',
        },
        {
            label: '(GMT-01:00) Azores',
            value: 'Atlantic/Azores',
            standardizedValue: 'Atlantic/Azores',
        },
        {
            label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
            value: 'Africa/Casablanca',
            standardizedValue: 'Africa/Casablanca',
        },
        {
            label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
            value: 'Etc/Greenwich',
        },
        {
            label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
            value: 'Europe/Amsterdam',
            standardizedValue: 'Europe/Amsterdam',
        },
        {
            label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
            value: 'Europe/Belgrade',
            standardizedValue: 'Europe/Belgrade',
        },
        {
            label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
            value: 'Europe/Brussels',
            standardizedValue: 'Europe/Brussels',
        },
        {
            label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
            value: 'Europe/Sarajevo',
            standardizedValue: 'Europe/Sarajevo',
        },
        {
            label: '(GMT+01:00) West Central Africa',
            value: 'Africa/Lagos',
            standardizedValue: 'Africa/Lagos',
        },
        {
            label: '(GMT+02:00) Amman',
            value: 'Asia/Amman',
            standardizedValue: 'Asia/Amman',
        },
        {
            label: '(GMT+02:00) Athens, Bucharest, Istanbul',
            value: 'Europe/Athens',
            standardizedValue: 'Europe/Athens',
        },
        {
            label: '(GMT+02:00) Beirut',
            value: 'Asia/Beirut',
            standardizedValue: 'Asia/Beirut',
        },
        {
            label: '(GMT+02:00) Cairo',
            value: 'Africa/Cairo',
            standardizedValue: 'Africa/Cairo',
        },
        {
            label: '(GMT+02:00) Harare, Pretoria',
            value: 'Africa/Harare',
            standardizedValue: 'Africa/Harare',
        },
        {
            label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
            value: 'Europe/Helsinki',
            standardizedValue: 'Europe/Helsinki',
        },
        {
            label: '(GMT+02:00) Jerusalem',
            value: 'Asia/Jerusalem',
            standardizedValue: 'Asia/Jerusalem',
        },
        {
            label: '(GMT+02:00) Minsk',
            value: 'Europe/Minsk',
            standardizedValue: 'Europe/Minsk',
        },
        {
            label: '(GMT+02:00) Windhoek',
            value: 'Africa/Windhoek',
            standardizedValue: 'Africa/Windhoek',
        },
        {
            label: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
            value: 'Asia/Kuwait',
            standardizedValue: 'Asia/Kuwait',
        },
        {
            label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
            value: 'Europe/Moscow',
            standardizedValue: 'Europe/Moscow',
        },
        {
            label: '(GMT+03:00) Nairobi',
            value: 'Africa/Nairobi',
            standardizedValue: 'Africa/Nairobi',
        },
        {
            label: '(GMT+03:00) Tbilisi',
            value: 'Asia/Tbilisi',
            standardizedValue: 'Asia/Tbilisi',
        },
        {
            label: '(GMT+03:30) Tehran',
            value: 'Asia/Tehran',
            standardizedValue: 'Asia/Tehran',
        },
        {
            label: '(GMT+04:00) Abu Dhabi, Muscat',
            value: 'Asia/Muscat',
            standardizedValue: 'Asia/Muscat',
        },
        {
            label: '(GMT+04:00) Baku',
            value: 'Asia/Baku',
            standardizedValue: 'Asia/Baku',
        },
        {
            label: '(GMT+04:00) Yerevan',
            value: 'Asia/Yerevan',
            standardizedValue: 'Asia/Yerevan',
        },
        {
            label: '(GMT+04:30) Kabul',
            value: 'Asia/Kabul',
            standardizedValue: 'Asia/Kabul',
        },
        {
            label: '(GMT+05:00) Yekaterinburg',
            value: 'Asia/Yekaterinburg',
            standardizedValue: 'Asia/Yekaterinburg',
        },
        {
            label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
            value: 'Asia/Karachi',
            standardizedValue: 'Asia/Karachi',
        },
        {
            label: showIstTimezoneFF
                ? '(GMT+05:30) Indian Standard Time'
                : '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
            value: 'Asia/Calcutta',
            standardizedValue: 'Asia/Kolkata',
        },
        {
            label: '(GMT+05:30) Sri Jayawardenapura',
            value: 'Asia/Calcutta',
            standardizedValue: 'Asia/Kolkata',
        },
        {
            label: '(GMT+05:45) Kathmandu',
            value: 'Asia/Katmandu',
            standardizedValue: 'Asia/Kathmandu',
        },
        {
            label: '(GMT+06:00) Almaty, Novosibirsk',
            value: 'Asia/Almaty',
            standardizedValue: 'Asia/Almaty',
        },
        {
            label: '(GMT+06:00) Astana, Dhaka',
            value: 'Asia/Dhaka',
            standardizedValue: 'Asia/Dhaka',
        },
        {
            label: '(GMT+06:30) Yangon (Rangoon)',
            value: 'Asia/Rangoon',
            standardizedValue: 'Asia/Yangon',
        },
        {
            label: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
            value: 'Asia/Bangkok',
            standardizedValue: 'Asia/Bangkok',
        },
        {
            label: '(GMT+07:00) Krasnoyarsk',
            value: 'Asia/Krasnoyarsk',
            standardizedValue: 'Asia/Novosibirsk',
        },
        {
            label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
            value: 'Asia/Hong_Kong',
            standardizedValue: 'Asia/Hong_Kong',
        },
        {
            label: '(GMT+08:00) Kuala Lumpur, Singapore',
            value: 'Asia/Kuala_Lumpur',
            standardizedValue: 'Asia/Kuala_Lumpur',
        },
        {
            label: '(GMT+08:00) Irkutsk, Ulaan Bataar',
            value: 'Asia/Irkutsk',
            standardizedValue: 'Asia/Irkutsk',
        },
        {
            label: '(GMT+08:00) Perth',
            value: 'Australia/Perth',
            standardizedValue: 'Australia/Perth',
        },
        {
            label: '(GMT+08:00) Taipei',
            value: 'Asia/Taipei',
            standardizedValue: 'Asia/Taipei',
        },
        {
            label: '(GMT+09:00) Osaka, Sapporo, Tokyo',
            value: 'Asia/Tokyo',
            standardizedValue: 'Asia/Tokyo',
        },
        {
            label: '(GMT+09:00) Seoul',
            value: 'Asia/Seoul',
            standardizedValue: 'Asia/Seoul',
        },
        {
            label: '(GMT+09:00) Yakutsk',
            value: 'Asia/Yakutsk',
            standardizedValue: 'Asia/Chita',
        },
        {
            label: '(GMT+09:30) Adelaide',
            value: 'Australia/Adelaide',
            standardizedValue: 'Australia/Adelaide',
        },
        {
            label: '(GMT+09:30) Darwin',
            value: 'Australia/Darwin',
            standardizedValue: 'Australia/Darwin',
        },
        {
            label: '(GMT+10:00) Brisbane',
            value: 'Australia/Brisbane',
            standardizedValue: 'Australia/Brisbane',
        },
        {
            label: '(GMT+10:00) Canberra, Melbourne, Sydney',
            value: 'Australia/Canberra',
            standardizedValue: 'Australia/Sydney',
        },
        {
            label: '(GMT+10:00) Hobart',
            value: 'Australia/Hobart',
            standardizedValue: 'Australia/Sydney',
        },
        {
            label: '(GMT+10:00) Guam, Port Moresby',
            value: 'Pacific/Guam',
            standardizedValue: 'Pacific/Guam',
        },
        {
            label: '(GMT+10:00) Vladivostok',
            value: 'Asia/Vladivostok',
            standardizedValue: 'Asia/Vladivostok',
        },
        {
            label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
            value: 'Asia/Magadan',
            standardizedValue: 'Asia/Sakhalin',
        },
        {
            label: '(GMT+12:00) Auckland, Wellington',
            value: 'Pacific/Auckland',
            standardizedValue: 'Pacific/Auckland',
        },
        {
            label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
            value: 'Pacific/Fiji',
            standardizedValue: 'Pacific/Fiji',
        },
        {
            label: "(GMT+13:00) Nuku'alofa",
            value: 'Pacific/Tongatapu',
            standardizedValue: 'Pacific/Tongatapu',
        },
    ];

    return timeZones;
};
