// User Activities Report
export const userActivityDimensions = [
    {
        id: 'rover_visual_raw_data_0',
        displayName: 'Timestamp (Local)',
        dataDefinition: {
            dataType: 'string',
        },
    },
    {
        id: 'i18_rover_visual_raw_data_1',
        displayName: 'Event Name',
        dataDefinition: {
            dataType: 'string',
        },
    },
    {
        id: 'rover_visual_raw_data_3',
        displayName: 'Credential Type',
        dataDefinition: {
            dataType: 'string',
        },
    },
    {
        id: 'site_name',
        displayName: 'Site Name',
        dataDefinition: {
            dataType: 'string',
        },
    },
    {
        id: 'device_name',
        displayName: 'Device Name',
        dataDefinition: {
            dataType: 'string',
        },
    },
    {
        id: 'user_full_name',
        displayName: 'Users',
        dataDefinition: {
            dataType: 'string',
        },
    },
];

export const userActivityQuery = {
    query: {
        columns: [
            {
                source: 'events',
                id: 'occurred_local',
                alias: 'Timestamp (Local)',
                uuid: 'rover_visual_raw_data_0',
            },
            {
                source: 'event_types',
                id: 'i18_event_type',
                alias: 'Event Name',
                uuid: 'i18_rover_visual_raw_data_1',
            },
            {
                source: 'users',
                id: 'full_name',
                uuid: 'user_full_name',
                alias: 'User',
            },
            {
                source: 'event_credential_types',
                id: 'description',
                alias: 'Credential Type',
                uuid: 'rover_visual_raw_data_3',
            },
            {
                source: 'sites',
                id: 'site_name',
                uuid: 'site_name',
                alias: 'Site',
            },
            {
                source: 'devices',
                id: 'device_name',
                alias: 'Device Name',
                uuid: 'device_name',
            },
        ],
        filters: [
            {
                source: 'operation',
                type: 'equals',
                left_value: {
                    source: 'transform',
                    type: 'extract_date',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'columns',
                            uuid: 'rover_visual_raw_data_0',
                        },
                    },
                },
                right_value: {
                    source: 'transform',
                    type: 'extract_date',
                    value: {
                        source: 'transform',
                        type: 'timezone_conversion',
                        timezone: {
                            source: 'system',
                            id: 'admin_timezone',
                        },
                        value: {
                            source: 'database',
                            id: 'current_timestamp',
                        },
                    },
                },
            },
        ],
    },
};

export const mockMyAnalyticsTableProps = {
    address: '0,3',
    configuration: {
        width: 12,
        height: 1,
        type: 'table',
        headerKey: 'User Activity Report',
        headerTooltipKey: 'User Activity Report Tooltip',
        dimensions: userActivityDimensions,
        query: userActivityQuery,
        header: 'User Activity Report',
        headerTooltip:
            'The dataset includes events, users, sites, & device information. Useful for creating activity reports that include where the event occurred and any user related custom fields.',
        uuid: '622d9f94-e109-4953-b432-1564eeb034a9',
    },
    idx: 3,
    dashboardConf: {},
    isEditViewEnabled: false,
    myAssistantView: false,
    isEditing: false,
};
