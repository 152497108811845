import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Drawer, MuiClearIcon } from '@brivo/react-components';

import { checkPermissions } from '@brivo/onairplus-services';

import NavItems from './Components/NavItems';
import NavigationTopSection from './Components/NavigationTopSection';
import NavigationBottomSection from './Components/NavigationBottomSection';
import styles from './styles';

const NewNavigation = ({
    userAvatar,
    toggleNavigation,
    isLockdownActive,
    lockdownEnabled,
    logoUrl,
    config,
    showNewActivationPopup,
}) => {
    const classes = styles();
    const { expanded, user, items, onClose, canPageToggleNavigation } = config;

    const [isSmallerThan600Width, setSmallerThan600Width] = useState(window.innerWidth <= 600);

    const resize = () => {
        setTimeout(() => {
            setSmallerThan600Width(window.innerWidth <= 600);
        }, 0);
    };

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    });

    const lockDownItems = items?.filter((item) => item.id === 'link-lockdown-configurations' || item.id === 'lockdown');
    const groupLockdownItems = items?.filter((item) => item.id === 'group-lockdown');

    return (
        <div
            className="NewNavigation"
            style={{
                width: isSmallerThan600Width || (canPageToggleNavigation && !expanded) ? '0' : '288px',
                flexShrink: 0,
                transition: 'width .5s',
                marginTop: isLockdownActive ? '40px' : '0px',
            }}
        >
            {isSmallerThan600Width ? (
                <Drawer
                    anchor="left"
                    open={expanded}
                    PaperProps={{
                        classes: { root: clsx(classes.paper, classes.fullDrawer) },
                    }}
                >
                    <MuiClearIcon className={classes.closeIcon} onClick={onClose} />
                    <nav id="site-nav" className={classes.nav}>
                        <NavigationTopSection toggleNavigation={toggleNavigation} />
                        <NavItems items={items} user={user} checkPermissions={checkPermissions} />
                    </nav>
                    <NavigationBottomSection
                        userAvatar={userAvatar}
                        lockDownItems={lockDownItems}
                        checkPermissions={checkPermissions}
                        activeLockdown={isLockdownActive}
                        user={user}
                        isSmallerThan600Width={isSmallerThan600Width}
                        groupLockdownItems={groupLockdownItems}
                        lockdownEnabled={lockdownEnabled}
                        showNewActivationPopup={showNewActivationPopup}
                    />
                </Drawer>
            ) : (
                <Drawer
                    className={classes.drawerPaper}
                    variant="persistent"
                    anchor="left"
                    open={canPageToggleNavigation ? expanded : true}
                    PaperProps={{
                        elevation: 1,
                        square: true,
                        classes: { root: classes.paper },
                    }}
                >
                    <nav id="site-nav" className={classes.nav}>
                        <NavigationTopSection
                            userAvatar={userAvatar}
                            toggleNavigation={toggleNavigation}
                            logoUrl={logoUrl}
                        />
                        <NavItems items={items} user={user} checkPermissions={checkPermissions} />
                    </nav>
                    <NavigationBottomSection
                        userAvatar={userAvatar}
                        lockDownItems={lockDownItems}
                        checkPermissions={checkPermissions}
                        activeLockdown={isLockdownActive}
                        user={user}
                        groupLockdownItems={groupLockdownItems}
                        lockdownEnabled={lockdownEnabled}
                        showNewActivationPopup={showNewActivationPopup}
                    />
                </Drawer>
            )}
        </div>
    );
};

export default NewNavigation;
