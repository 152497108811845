import { useState, useEffect, useCallback, useContext } from 'react';
import { MultiSelectFilter } from '@brivo/react-components';
import ApiHelper from '@/common/helpers/Helpers';
import { GQLProviderContext } from '@/common/utils/CustomApolloProvider';
import { withApi } from '@brivo/onairplus-services';
import { useQueryEditor } from '@/pages/NewReports/components/TableEditor/QueryEditorContext';
import { useTranslation } from 'react-i18next';
import { columnConfiguration, dimension, filter as devicesFilter } from './constants';
import { fetchDevices } from '@/common/utils/Utils';
import { Filter } from '@/pages/NewReports/common/interfaces';
import { createFilterConfiguration } from '@/pages/NewReports/common/utils';

interface Device {
    [key: string]: string;
}

interface DeviceProps {
    devices: Device[];
    disabled: boolean;
    filter: Filter;
}

const Device = ({ disabled, filter }: DeviceProps) => {
    const [selectedDevices, setSelectedDevices] = useState();
    const gqlHelper = useContext(GQLProviderContext);
    const [devices, setDevices] = useState([]);
    const QueryEditor: any = useQueryEditor();
    const { t } = useTranslation();

    useEffect(() => {
        fetchDevices(ApiHelper, gqlHelper, setDevices, { includeGeocode: false });
    }, []);

    // TODO: Improve searching later, currently runtime of n-squared
    useEffect(() => {
        if (devices.length > 0 && filter) {
            let selectedFilteredDevices: any = filter.test_values?.map((test_value: any) =>
                devices.find((device: any) => device.name === test_value.value)
            );
            selectedFilteredDevices = selectedFilteredDevices?.filter((filteredDevice) => filteredDevice !== undefined);

            setSelectedDevices(selectedFilteredDevices);
        }
    }, [filter, devices]);

    const handleMultiSelectFilterChange = (event: any) => {
        const newDevices = event;
        setSelectedDevices(newDevices);

        const formattedDeviceValues = newDevices.map((newDevice: any) => ({
            source: 'constant',
            type: 'string',
            value: newDevice.name,
        }));

        const newFilter = createFilterConfiguration(columnConfiguration, formattedDeviceValues);

        if (newDevices.length === 0) {
            QueryEditor.removeFilter(newFilter);
        } else {
            QueryEditor.addOrUpdateFilter(newFilter);
        }
    };

    const deviceDropdownMapper = useCallback((item) => ({ id: item.id, name: item.name }), []);

    return (
        <MultiSelectFilter
            disabled={disabled}
            id="devices"
            limit={1}
            label={t('Page.new-reports.column-filter.devices')}
            noSelectionLabel={t('Page.new-reports.column-filter.devices.no-selection-label')}
            items={devices}
            mapper={deviceDropdownMapper}
            value={selectedDevices}
            onValueChange={handleMultiSelectFilterChange}
            noResultMessage={t('Page.new-reports.column-filter.no-options')}
            clearingValues={true}
            // setClearingValues={setClearingValues}
            showMoreText={t('Page.new-reports.column-filter.show-more')}
        />
    );
};

export default withApi(Device, ApiHelper);
