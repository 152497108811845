import React, { Component } from 'react';
import { withTheme } from '@brivo/react-components';
import { Link } from '@brivo/react-components';
import { withTranslation } from 'react-i18next';

import { history } from '../../../redux/Store';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.auth = props.auth;
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Something went wrong! Error occurred:', error);
        console.error('Error Info:', errorInfo);

        let { reloadIfError } = this.props;

        if (reloadIfError && error.message === 'Failed to initialize WebGL.') {
            // refreshes the page
            history.go();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ hasError: false });
        }
    }
    // TODO -> make a button to click and throw to test if this works
    rejectionHandler = (event) => {
        if (event && event.reason?.requiresLogout) {
            if (this.auth) {
                this.auth.setAuthError('Your session has expired. Please log in again.');
                this.auth.logout();
            }
        }
    };

    componentDidMount() {
        // Add an event listener to the window to catch unhandled rejections & stash the error in the state
        window.addEventListener('UnhandledPromiseRejection', this.rejectionHandler);
        window.addEventListener('unhandledrejection', this.rejectionHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('UnhandledPromiseRejection', this.rejectionHandler);
        window.removeEventListener('unhandledrejection', this.rejectionHandler);
    }

    render() {
        let { sectionName, theme, t } = this.props;

        if (!sectionName) {
            sectionName = t('Error.Boundary-section-placeholder-message');
        }
        return this.state.hasError ? (
            <div
                className="u-fx u-fx-column u-fx-align-center u-fx-justify-center"
                style={{
                    height: '100%',
                    backgroundColor:
                        theme.palette.type === 'dark' ? theme.palette.brivoAliases.blockBackground : 'white',
                }}
            >
                <h4 style={{ marginBottom: '1rem' }}>
                    {sectionName} {t('Error.Boundary-failed-to-load-message')}
                </h4>

                <Link
                    variant="h5"
                    onClick={() => {
                        // refreshes the current page
                        history.go();
                    }}
                >
                    {t('Error.Boundary-refresh-message')}
                </Link>
            </div>
        ) : (
            this.props.children
        );
    }
}

export default withTheme(withTranslation()(ErrorBoundary));
