import React from 'react';
import { OPTIN_MONSTER_ACCOUNT_ID, OPTIN_MONSTER_USER_ID } from '../../../constants/Constants';

export default {
    _featureFlag: 'opt-in-monster',

    _accountNumber: OPTIN_MONSTER_ACCOUNT_ID,

    _userId: OPTIN_MONSTER_USER_ID,

    _initialized: false,

    //will fire as soon as the script tag is loaded.  this has to be fired manually to allow for ajax requests, which re-computes the display logic
    reset() {
        var config = window[`om${this._accountNumber}_${this._userId}`];

        if (config) {
            config.reset();
        }
    },

    //to discourage but not prevent, editing of the flag externally
    getFeatureFlag() {
        return this._featureFlag;
    },

    //OIM will not work on localhost, View the README project for local setup instructions
    isAllowed() {
        const isLocalhost = window.location.hostname.includes('localhost');
        return !isLocalhost;
    },

    // Inject required script tag onto the page
    initialize() {
        if (this._initialized) return;

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://a.omappapi.com/app/js/api.min.js';
        s.async = true;
        s.dataset.user = OPTIN_MONSTER_USER_ID;
        s.dataset.account = OPTIN_MONSTER_ACCOUNT_ID;

        if (this.isAllowed) {
            document.body.appendChild(s);
        } else {
            console.warn(
                'Opt In Monster In App Messaging functionality is enabled but will not work on localhost.  View Project README for local environment testing instructions'
            );
        }

        this._initialized = true;
    },

    checkPromotionDisplayed(promotionId, delaySeconds = 3) {
        return new Promise((resolve, reject) => {
            let timesChecked = 0;

            //checks for success every second up to specified delay second
            const interval = setInterval(() => {
                timesChecked++;
                const container = this.getPromotionContainer(promotionId);
                if (container && container.childElementCount > 0) {
                    clearInterval(interval);
                    resolve(true);
                }
                if (timesChecked === delaySeconds) {
                    clearInterval(interval);
                    reject(false);
                }
            }, 1000);
        });
    },

    renderPromotion(promoCode) {
        return <div id={'om-' + promoCode + '-holder'} data-testid={promoCode}></div>;
    },

    getPromotionContainer(promoCode) {
        //If campaign A has Id 1 and they're A/B testing several variations, campaign B will render in A's container and change the id to B's campaign Id, thus failing all render/fallback checks
        return document.querySelector(`[data-testId='${promoCode}']`) || document.querySelector(`#om-${promoCode}`);
    },

    getLinkToPromotion(promoCode) {
        return 'https://app.monstercampaigns.com/c/' + promoCode;
    },

    sendDataToPromo(promoCode, data) {
        var self = this;
        this.checkPromotionDisplayed(promoCode)
            .then(() => {
                var container = self.getPromotionContainer(promoCode);

                var iframe = container.querySelector('iframe');
                if (iframe != null) {
                    iframe.contentWindow.postMessage(data, '*');
                }
            })
            .catch((error) => console.error('something bad happened: ' + error));
    },
};
