import { v4 as uuidv4 } from 'uuid';
import { SectionHeaderConfiguration } from './interfaces';
import { VISUAL_TYPES } from '../../common/DashboardBuilderContext/constants';

export const defaultViews = [
    {
        nameKey: 'Page.brivo-analytics.my-analytics.dropdown-header.executive-overview-option',
        key: 'ExecutiveOverview',
    },
    {
        nameKey: 'Page.brivo-analytics.my-analytics.dropdown-header.facility-manager-option',
        key: 'FacilityManager',
    },
    {
        nameKey: 'Page.brivo-analytics.my-analytics.dropdown-header.occupancy-dashboard-option',
        key: 'OccupancyDashboard',
    },
    {
        nameKey: 'Page.brivo-analytics.my-analytics.dropdown-header.data-explorer-option',
        key: 'DataExplorerTables',
    },
];

export const sectionHeaderConfiguration: SectionHeaderConfiguration = {
    className: 'firstSubheader',
    height: 2,
    textKey: 'Page.brivo-analytics.my-analytics.section-header.new-section',
    type: VISUAL_TYPES.sectionHeader,
    uuid: uuidv4(),
    variant: 'h5',
    width: 12,
};
