import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FiltersHeader,
    FiltersContainer,
    MultiSelectFilter,
    DateFilter,
    CheckboxFilter,
} from '@brivo/react-components';
import {
    DEBOUNCE_TIMEOUT_MS,
    DEBOUNCE_TIMEOUT_MS_MSF,
    MULTIPLE_SELECT_ON_CHANGE_TIMEOUT,
} from '../../../../../../common/constants/Constants';
import { debounce } from 'lodash';
import { startOfDay, subDays } from 'date-fns';
import { useFormatLocale } from '../../../../../../common/hooks/useFormatLocale';
import { useTranslatedEventNames } from '../../../../../../common/hooks/useTranslatedEventNames';

const RecordedVideoFiltersNew = ({
    filterValues,
    filterChangeMethods,
    nrFiltersChanged,
    handleRemovingFilters,
    sites,
    cameras,
    events,
    showCameraEventsCheckbox,
    clearingValues,
    setClearingValues,
}) => {
    const { t } = useTranslation();
    const { i18n, translateEventName } = useTranslatedEventNames();
    const language = i18n.language;
    const { localeDateFormat } = useFormatLocale();

    const dateFormat = localeDateFormat().toLowerCase().replace(/m/g, 'M');
    const minimumDate = startOfDay(subDays(new Date(), 90));

    const eventDropdownMapper = useCallback(
        (securityActionId) => {
            return {
                id: securityActionId,
                name: translateEventName(securityActionId),
            };
        },
        [translateEventName]
    );
    const deviceDropdownMapper = useCallback((item) => ({ id: item.id, name: item.name }), []);

    return (
        <FiltersContainer
            showMore={{
                enabled: false,
                showMoreTitle: t('Component.filters-container.show-more-title'),
                showLessTitle: t('Component.filters-container.show-less-title'),
            }}
            topLeft={
                <FiltersHeader
                    activeFiltersId="video-all-cameras-active-filter"
                    clearAllButtonId="video-all-cameras-clear-all"
                    headerTitle={t('Filter-Group.default-title')}
                    activeFiltersLabel={t('Component.filters-header.active-filters')}
                    clearAllButtonTitle={t('Component.filters-header.clear-filters')}
                    numberOfSelectedFilters={nrFiltersChanged}
                    removeFilters={handleRemovingFilters}
                />
            }
        >
            <MultiSelectFilter
                id="sites"
                label={t('Page.live-video.all-cameras.header.filters.sites.label')}
                noSelectionLabel={t('Page.live-video.all-cameras.header.filters.sites.no-selection')}
                items={sites}
                mapper={(item) => ({ id: item.id, name: item.siteName })}
                value={filterValues.selectedSites}
                onValueChange={
                    filterChangeMethods.selectedSites &&
                    debounce(filterChangeMethods.selectedSites, DEBOUNCE_TIMEOUT_MS_MSF)
                }
                noResultMessage={t('Filters.multi-select.no-results')}
                disabled={!sites?.length}
                clearingValues={clearingValues}
                setClearingValues={setClearingValues}
                showMoreText={t('Page.event-tracker-filter.show-more')}
            />
            <DateFilter
                id="selectedDate"
                label={t('Page.event-tracker.filter.date-label')}
                noSelectionLabel={t('Page.event-tracker.filter.date-no-selection')}
                minDate={minimumDate}
                minDateMessage={t('Component.date-filter.min-date-message')}
                maxDateMessage={t('Component.date-filter.max-date-message')}
                invalidDateMessage={t('Component.date-filter.invalid-date-message')}
                clearMessage={t('Component.date-filter.clear-message')}
                format={dateFormat}
                locale={language}
                onValueChange={
                    filterChangeMethods.selectedDate && debounce(filterChangeMethods.selectedDate, DEBOUNCE_TIMEOUT_MS)
                }
                dateValue={filterValues.selectedDate}
                disableFuture={true}
            />

            <MultiSelectFilter
                key={`eventNames_${language}`}
                id="eventNames"
                label={t('Page.event-tracker.filter.events-label')}
                noSelectionLabel={t('Page.event-tracker.filter.events-no-selection-label')}
                items={events}
                loading={!events.length}
                mapper={eventDropdownMapper}
                value={filterValues.selectedEvents}
                disabled={!events?.length}
                onValueChange={
                    filterChangeMethods.selectedEvents &&
                    debounce(filterChangeMethods.selectedEvents, MULTIPLE_SELECT_ON_CHANGE_TIMEOUT)
                }
                noResultMessage={t('Filters.multi-select.no-results')}
                clearingValues={clearingValues}
                setClearingValues={setClearingValues}
                showMoreText={t('Page.event-tracker-filter.show-more')}
            />

            <MultiSelectFilter
                key={`cameras_${language}`}
                id="cameras"
                label={t('Page.recorded-video.cameras')}
                noSelectionLabel={t('Page.recorded-video.cameras-filter.no-selection')}
                items={cameras}
                mapper={deviceDropdownMapper}
                value={filterValues.selectedCameras}
                disabled={!cameras?.length}
                onValueChange={
                    filterChangeMethods.selectedCameras &&
                    debounce(filterChangeMethods.selectedCameras, MULTIPLE_SELECT_ON_CHANGE_TIMEOUT)
                }
                noResultMessage={t('Filters.multi-select.no-results')}
                clearingValues={clearingValues}
                setClearingValues={setClearingValues}
                showMoreText={t('Page.event-tracker-filter.show-more')}
            />

            {showCameraEventsCheckbox && (
                <CheckboxFilter
                    id="checkbox-show-camera-events"
                    label={t('Page.event-tracker.filter.show-camera-events-label')}
                    value={filterValues.showCameraEvents}
                    onValueChange={
                        filterChangeMethods.showCameraEvents &&
                        debounce(filterChangeMethods.showCameraEvents, DEBOUNCE_TIMEOUT_MS)
                    }
                />
            )}
        </FiltersContainer>
    );
};

export default RecordedVideoFiltersNew;
