import { GET_JOURNAL_EVENTS, GET_JOURNAL_EVENT_TYPE_IDS } from './gqlTags';
import { WebReqApi } from '../WebReqApi';

class JournalApi extends WebReqApi {
    getJournalEvents(limit, nextToken, filters) {
        return this.makeGqlQuery({
            gqlTag: GET_JOURNAL_EVENTS,
            variables: {
                limit,
                nextToken,
                securityActionIds: filters.securityActionIds,
                actorId: filters.actorId,
                eventTargetOids: filters.eventTargetOids,
                from: filters.from,
                to: filters.to,
            },
        });
    }

    getJournalEventTypeIds() {
        return this.makeGqlQuery({
            gqlTag: GET_JOURNAL_EVENT_TYPE_IDS,
        });
    }
}

export const journalApi = new JournalApi();
