import { MAX_PAGE_SIZE_GRAPHQL } from '../../../../common/constants/Constants';
import { checkArgsTypes } from './utils';
import { intersectionBy } from 'lodash';

const callForCameras = async (gqlHelper, offset, siteFilters) => {
    try {
        return await gqlHelper.listCamerasBySite(MAX_PAGE_SIZE_GRAPHQL, offset, siteFilters);
    } catch (e) {
        console.error('Call to get cameras failed');
        return [];
    }
};

export const getAdminCameras = async (gqlHelper, adminSites) => {
    checkArgsTypes([gqlHelper.listCamerasBySite], ['gqlHelper.listCamerasBySite'], 'function');
    if (adminSites?.length) {
        const siteFilters = buildSiteFilterForCameraQuery(adminSites);
        let adminCamerasList = [];
        let offset = 0;
        let keepFetching = true;
        while (keepFetching) {
            const currentCameras = await callForCameras(gqlHelper, offset, siteFilters);
            if (Array.isArray(currentCameras) && currentCameras?.length) {
                adminCamerasList = [...adminCamerasList, ...currentCameras];
                offset = offset + MAX_PAGE_SIZE_GRAPHQL;
            }
            if ((currentCameras?.length ?? 0) < MAX_PAGE_SIZE_GRAPHQL) {
                //TODO compare to total count instead of pageSize, so we can fetch batches concurrently
                keepFetching = false;
            }
        }
        return adminCamerasList;
    } else {
        return [];
    }
};

const buildSiteFilterForCameraQuery = (adminSites) => {
    if (adminSites?.length) {
        return [
            {
                field: 'siteId',
                type: 'field',
                value: adminSites?.map((site) => site.id).toString(),
            },
        ];
    }
};

export const getSelectedCamerasWithDevices = (selectedCameras, camerasWithAssociatedDevices) => {
    let selectedCamerasWithDevices = [];
    if (selectedCameras?.length) {
        selectedCamerasWithDevices = camerasWithAssociatedDevices.filter((camera) =>
            selectedCameras?.find((selectedCamera) => selectedCamera.id === camera.id)
        );
    }
    return selectedCamerasWithDevices;
};

export const fetchAdminCamerasWithAssociatedDevices = async (gqlHelper, adminCameras) => {
    if (!gqlHelper?.listCamerasWithAssociatedDevices) {
        throw new Error('missing camera query function');
    }
    if (Array.isArray(adminCameras) && adminCameras?.length) {
        try {
            const camerasWithAssociatedDevices = await gqlHelper.listCamerasWithAssociatedDevices();
            return intersectionBy(camerasWithAssociatedDevices, adminCameras, 'id');
        } catch (e) {
            console.error('error getting cameras with devices');
            return [];
        }
    } else {
        return [];
    }
};

export const filterCameraListBySites = (selectedSites, cameras) => {
    let filteredCameras = [];
    if (selectedSites?.length && cameras?.length) {
        filteredCameras = cameras.filter((camera) => {
            return selectedSites.find((site) => site.id === camera.site?.id);
        });
    }
    return filteredCameras;
};
