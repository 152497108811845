import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon - ui help">
                <g id="icon - ui help_2">
                    <g id="Line_Icons">
                        <g id="Group">
                            <path
                                id="Shape"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2 12C2 17.515 6.486 22 12 22C17.514 22 22 17.515 22 12C22 6.486 17.514 2 12 2C6.486 2 2 6.487 2 12ZM4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20C7.589 20 4 16.411 4 12Z"
                                fill="#485769"
                            />
                            <path
                                id="Path"
                                d="M13 15H11V12H12C13.104 12 14 11.103 14 10C14 8.896 13.104 8 12 8C10.896 8 10 8.896 10 10H8C8 7.795 9.795 6 12 6C14.205 6 16 7.795 16 10C16 11.86 14.723 13.428 13 13.875V15Z"
                                fill="#485769"
                            />
                            <circle id="Oval" cx="12" cy="17" r="1.25" fill="#485769" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </React.Fragment>,
    'RecentActivityUsingUnknown'
);
