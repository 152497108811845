import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { formatDistanceStrict, formatDistance } from 'date-fns';
import { TIMEZONE_FORMAT } from '../constants/Constants';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import sv from 'date-fns/locale/sv';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/es';
import 'moment/locale/es-do';
import 'moment/locale/es-us';
import 'moment/locale/nl';
import 'moment/locale/sv';

export const useFormatLocale = () => {
    const { i18n } = useTranslation();

    const getLocale = (language) => {
        switch (language) {
            case 'en':
                return en;
            case 'de':
                return de;
            case 'es':
                return es;
            case 'fr-CA':
                return fr;
            case 'nl':
                return nl;
            case 'sv':
                return sv;
            default:
                return en;
        }
    };

    const formatDate = useCallback(
        (date) => {
            moment.locale(i18n.language);
            return moment(date).format('L');
        },
        [i18n.language]
    );

    const formatDateAndTime = useCallback(
        (date) => {
            moment.locale(i18n.language);
            return moment(date).format('LTS') + ' ' + moment(date).format('L');
        },
        [i18n.language]
    );

    const formatTime = useCallback(
        (time) => {
            moment.locale(i18n.language);
            return moment(time).format('LTS');
        },
        [i18n.language]
    );

    const formatTimeWithTimeZone = useCallback(
        (time, timeZone, timeFormat = 'LTS', offsetFormat = TIMEZONE_FORMAT) => {
            moment.locale(i18n.language);
            if (!timeZone) {
                timeZone = momentTz.tz.guess();
            }
            return {
                time: momentTz.tz(time, timeZone).format(timeFormat),
                offset: momentTz.tz(time, timeZone).format(offsetFormat),
            };
        },
        [i18n.language]
    );

    const localeDateFormat = useCallback(() => {
        moment.locale(i18n.language);
        return moment.localeData().longDateFormat('L');
    }, [i18n.language]);

    const formatDistanceFixed = useCallback(
        (fromDate, currentDate, addSuffix) => {
            const locale = getLocale(i18n.language);
            return formatDistanceStrict(fromDate, currentDate, { addSuffix: addSuffix, locale });
        },
        [i18n.language]
    );

    const formatDistancePrefixed = useCallback(
        (fromDate, currentDate) => {
            const locale = getLocale(i18n.language);
            return formatDistance(fromDate, currentDate, { locale });
        },
        [i18n.language]
    );

    const formatDateAndTimeWithTimeZone = useCallback(
        (dateTime, timeZone) => {
            moment.locale(i18n.language);
            if (!timeZone) {
                timeZone = momentTz.tz.guess();
            }

            return {
                date: momentTz.tz(dateTime, timeZone).format('LL'),
                time: momentTz.tz(dateTime, timeZone).format('LT'),
                timeZone: momentTz.tz(timeZone).zoneAbbr(),
            };
        },
        [i18n.language]
    );

    return {
        formatDate,
        formatDateAndTime,
        formatDistanceFixed,
        formatDistancePrefixed,
        formatTime,
        localeDateFormat,
        formatTimeWithTimeZone,
        formatDateAndTimeWithTimeZone,
    };
};
