import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { get, unionBy } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import { hotjar } from 'react-hotjar';

import { Route, useLocation } from 'react-router-dom';

import { Page } from '@brivo/react-components';
import FeaturePreview from './FeaturePreview';

import LockdownBanner from './LockdownBanner';
import AccessControl from '../AccessControl';
import ErrorBoundary from '../ErrorBoundary';
import UserTracking from '../../utils/UserTracking';
import { UserContext } from '../../user/UserProvider';
import InactivityTimer from './InactivityTimer';
import NavigationContainer from '../Navigation/NavigationContainer';
import UserAvatar from './UserAvatarContainer/UserAvatarContainer';
import GeneralRequestsContainer from './GeneralRequests/GeneralRequestsContainer';
import OnairSSO from '../../../pages/OnairSSO/OnairSSO';
import { InAppMarketing } from '../InAppMarketing/InAppMarketing';
import { auth } from '../../utils/Auth';
import { lockdownApi } from '../../webApis/lockdown/lockdownApi';
import { useFlagClient } from '@brivo/onairplus-services';
import {
    APP_UNIFIED_DASHBOARD_URL,
    ALL_SCENARIOS_FILTER_ENABLED,
    APP_FLOOR_PLANS_URL,
    RESTRICT_THIRD_PARTY_USER_DATA,
    EDITION_STANDARD,
    EDITION_PROFESSIONAL,
    SUPPORTED_CAMPAIGN_URLS,
} from '../../constants/Constants';
import UpsellBanner from './UpsellBanner';
import { upsellApi } from '../../webApis/upsell/upsellApi';

const AuthenticatedRoute = ({
    component: Component,
    necessaryPermissions,
    anyOfPermissions,
    requiredFlag,
    unauthorizedComponent,
    pageProps,
    title,
    displayNavigationBar = true,
    ...rest
}) => {
    const flagClient = useFlagClient();
    const userContext = useContext(UserContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [activeScenarios, setActiveScenarios] = useState(null);
    const [showSsoIframe, setShowSsoIframe] = useState(null);
    const [hiddenNavItemsUrls, setHiddenNavItems] = useState(SUPPORTED_CAMPAIGN_URLS);
    const [campaigns, setCampaigns] = useState([]);
    const [isFloorPlanCampaignExist, setFloorPlanCampaignExists] = useState(false);
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');
    const mountedRef = useRef(false);

    const campaignsFlag = flagClient?.variation('enterprise-feature-preview', false);
    const [showUpsellBanner, toggleUpsellBanner] = useState(true);

    const location = useLocation();

    const canPageToggleNavigation = window.location.pathname === APP_UNIFIED_DASHBOARD_URL;

    const fetchIsLockdownActive = useCallback(async () => {
        if (!isAuthenticated || !get(userContext, 'userInfo.accountFeatures.lockdownEnabled', false)) {
            return;
        }

        if (emergencyScenariosFlag) {
            const queryParamsAllScenarios = {
                filter: ALL_SCENARIOS_FILTER_ENABLED,
            };

            const response = await lockdownApi.getAllActiveScenarios({ queryParams: queryParamsAllScenarios });

            mountedRef.current && setActiveScenarios(response?.data);
        } else {
            const activeLockdowns = await lockdownApi.getLockdownActiveScenarios();
            const lockdownScenariosIncludingHardwiredLockdowns = await lockdownApi.getLockDownScenarios({
                queryParams: {
                    filter: 'hardware__eq:true',
                    pageSize: '100',
                },
            });

            const activeLockdownsIncludingHardwiredLockdowns =
                lockdownScenariosIncludingHardwiredLockdowns?.data?.filter(
                    (lockdownScenario) => lockdownScenario.issued
                );

            const allActiveLockdowns = unionBy(activeLockdowns?.data, activeLockdownsIncludingHardwiredLockdowns, 'id');

            mountedRef.current && setActiveScenarios(allActiveLockdowns ? allActiveLockdowns : []);
        }
    }, [userContext, isAuthenticated, emergencyScenariosFlag]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        fetchIsLockdownActive();
    }, [fetchIsLockdownActive, Component]);

    useEffect(() => {
        let finalTitle = 'Brivo Access';
        if (title && title.length !== 0) {
            finalTitle = `${finalTitle} - ${title}`;
        }
        document.title = finalTitle;
        UserTracking.pageView();
    }, [title]);

    useEffect(() => {
        if (auth) {
            auth.isAuthenticated()
                .then((authenticated) => {
                    if (authenticated !== isAuthenticated) {
                        mountedRef.current && setIsAuthenticated(authenticated);
                    }
                    if (authenticated === false) {
                        UserTracking.track('Not Authenticated on Page load');
                    }

                    auth.renewSession().then((resolved) => {
                        if (resolved) {
                            UserTracking.track('Successfully Renewed Session on Page Load');
                            mountedRef.current && setIsAuthenticated(true);
                        } else {
                            UserTracking.track('Failed to Renew Session on Page Load');
                        }
                    });
                })
                .catch((e) => {
                    UserTracking.log(`Not Authenticated: ${e.toString()}`);
                    mountedRef.current && setIsAuthenticated(false);
                    auth.login();
                });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (campaignsFlag && userContext?.userInfo?.accountId && UserTracking.hasUserPerformedLogIn) {
            upsellApi.userLogin();
        }
    }, [userContext?.userInfo?.accountId, campaignsFlag]);

    useEffect(() => {
        (async () => {
            const identifyLdClient = async () => {
                if (auth && auth.getIdentified() && !auth.getThirdPartySetupComplete()) {
                    datadogRum.setUser({
                        id: auth.getUserId(),
                        email: RESTRICT_THIRD_PARTY_USER_DATA ? '' : auth.getEmail(),
                        accountId: auth.getAccountId(),
                    });
                    if (hotjar.initialized()) {
                        hotjar.identify(auth.getUserId(), {
                            id: auth.getUserId(),
                            email: RESTRICT_THIRD_PARTY_USER_DATA ? '' : auth.getEmail(),
                            accountId: auth.getAccountId(),
                        });
                    }
                    auth.setThirdPartySetupComplete(true);
                }
            };

            await identifyLdClient();

            if (mountedRef.current && isAuthenticated) {
                setShowSsoIframe(true);
                await userContext.updateUser();
            }
        })();
    }, [isAuthenticated, userContext, flagClient]);

    useEffect(() => {
        if (campaignsFlag && UserTracking.hasUserPerformedLogIn && userContext?.userInfo?.accountId) {
            upsellApi.userLogin();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getCampaigns = async () => {
            const activeCampaigns = await upsellApi.getActiveCampaigns();

            // Hide the nav items initially to prevent initial render/flicker bug of nav items that are expected to have feature previews
            let initialHiddenNavItemsUrls = SUPPORTED_CAMPAIGN_URLS;
            const activeCampaignUrls = activeCampaigns.map((campaign) => campaign.campaign_url);

            // Unhide nav items that have a feature preview associated with them
            initialHiddenNavItemsUrls = initialHiddenNavItemsUrls.filter(
                (hiddenNavItemUrl) => !activeCampaignUrls.includes(hiddenNavItemUrl)
            );

            setHiddenNavItems(initialHiddenNavItemsUrls);

            const floorPlanCampaign = activeCampaigns.find((campaign) => campaign.campaign_url === APP_FLOOR_PLANS_URL);
            if (floorPlanCampaign) {
                setFloorPlanCampaignExists(true);
            }
            setCampaigns(activeCampaigns);
        };

        if (
            campaignsFlag &&
            userContext?.userInfo?.accountId &&
            (userContext?.userInfo?.subscriptionLevel === EDITION_STANDARD ||
                userContext?.userInfo?.subscriptionLevel === EDITION_PROFESSIONAL)
        ) {
            getCampaigns();
        } else if (userContext?.userInfo?.accountId) {
            setHiddenNavItems([]);
        }
    }, [userContext, campaignsFlag]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!auth || !isAuthenticated) {
        return null;
    }

    const handleHideNavItem = (navItemUrl) => {
        const uniqueHiddenNavItemsUrls = Array.from(new Set([...hiddenNavItemsUrls, navItemUrl]));
        setHiddenNavItems(uniqueHiddenNavItemsUrls);
    };

    return (
        <Route
            {...rest}
            render={(props) => {
                // component may need access to `toggleNavigation` or `navigationExpanded` properties from @brivo/react-components
                const OnairSSOComponent = ({ toggleNavigation, navigationExpanded }) => {
                    return (
                        <OnairSSO showSsoIframe={showSsoIframe} auth={auth}>
                            <AccessControl
                                necessaryPermissions={necessaryPermissions}
                                anyOfPermissions={anyOfPermissions}
                                requiredFlag={requiredFlag}
                                unauthorizedComponent={unauthorizedComponent}
                                redirectToPage={true}
                            >
                                <GeneralRequestsContainer auth={auth} />

                                <ErrorBoundary location={location.pathname} sectionName={title}>
                                    <Component
                                        {...props}
                                        {...pageProps}
                                        fetchIsLockdownActive={fetchIsLockdownActive}
                                        activeScenarios={activeScenarios}
                                        toggleNavigation={toggleNavigation}
                                        navigationExpanded={navigationExpanded}
                                    />
                                </ErrorBoundary>
                            </AccessControl>
                        </OnairSSO>
                    );
                };

                // keep original component render for pages (ex: Administrators `/admin-management/admins/${id}`)
                // that have underlying issues when rendering with cloned properties
                const onairSSOComponent = (
                    <OnairSSO showSsoIframe={showSsoIframe} auth={auth}>
                        <AccessControl
                            necessaryPermissions={necessaryPermissions}
                            anyOfPermissions={anyOfPermissions}
                            requiredFlag={requiredFlag}
                            unauthorizedComponent={unauthorizedComponent}
                            redirectToPage={true}
                        >
                            <GeneralRequestsContainer auth={auth} />
                            <ErrorBoundary location={location.pathname} sectionName={title}>
                                <Component
                                    {...props}
                                    {...pageProps}
                                    fetchIsLockdownActive={fetchIsLockdownActive}
                                    activeScenarios={activeScenarios}
                                />
                            </ErrorBoundary>
                        </AccessControl>
                    </OnairSSO>
                );
                return displayNavigationBar ? (
                    <Page
                        renderNav={(navigationExpanded, closeNavigation, toggleNavigation) => (
                            <NavigationContainer
                                navigationExpanded={navigationExpanded}
                                closeNavigation={closeNavigation}
                                toggleNavigation={toggleNavigation}
                                userAvatar={UserAvatar}
                                isLockdownActive={activeScenarios?.length}
                                fetchIsLockdownActive={fetchIsLockdownActive}
                                canPageToggleNavigation={canPageToggleNavigation}
                                hiddenNavItemsUrls={hiddenNavItemsUrls}
                                // TODO: Discuss making floor plan nav item functionality more consistent with other nav items so we don't need this prop for FeaturePreviews
                                modifyFloorPlanNavItem={isFloorPlanCampaignExist}
                            />
                        )}
                        renderBanner={() => (
                            <>
                                <LockdownBanner activeScenarios={activeScenarios} />
                                {campaignsFlag && showUpsellBanner && (
                                    <UpsellBanner
                                        campaigns={campaigns}
                                        onDismissBanner={() => toggleUpsellBanner(false)}
                                        accountId={userContext?.userInfo?.accountId}
                                    />
                                )}
                            </>
                        )}
                        activeScenarios={activeScenarios}
                    >
                        {/* conditonal render since rendering component-style underlying issue for Administrators `/admin-management/admins/${id}` */}
                        {/* as of now, only My Dashboard needs access to @brivo/react-components Page component cloned property */}

                        {campaignsFlag ? (
                            <FeaturePreview
                                campaigns={campaigns}
                                onHideNavItem={handleHideNavItem}
                                accountId={userContext?.userInfo?.accountId}
                            >
                                {canPageToggleNavigation ? <OnairSSOComponent /> : onairSSOComponent}
                            </FeaturePreview>
                        ) : canPageToggleNavigation ? (
                            <OnairSSOComponent />
                        ) : (
                            onairSSOComponent
                        )}
                        <InactivityTimer auth={auth} />
                        {isAuthenticated && userContext.userInfo && <InAppMarketing context={userContext} />}
                    </Page>
                ) : (
                    <>
                        {/* conditonal render since rendering component-style underlying issue for Administrators `/admin-management/admins/${id}` */}
                        {/* as of now, only My Dashboard needs access to @brivo/react-components Page component cloned property */}

                        {campaignsFlag ? (
                            <FeaturePreview
                                campaigns={campaigns}
                                onHideNavItem={handleHideNavItem}
                                accountId={userContext?.userInfo?.accountId}
                            >
                                {canPageToggleNavigation ? <OnairSSOComponent /> : onairSSOComponent}
                            </FeaturePreview>
                        ) : canPageToggleNavigation ? (
                            <OnairSSOComponent />
                        ) : (
                            onairSSOComponent
                        )}
                        <InactivityTimer auth={auth} />
                    </>
                );
            }}
        />
    );
};

export default AuthenticatedRoute;
