import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { VIDEO_ITEM } from '../../utils';

export const VideoDroppable = ({ camId, children, onDrop, onHover }) => {
    const ref = useRef(null);

    const [, drop] = useDrop(
        () => ({
            accept: VIDEO_ITEM,
            drop: (dragItem) => {
                typeof onDrop === 'function' && onDrop(dragItem);
            },
            hover: (dragItem) => {
                const dropArea = { camId };
                typeof onHover === 'function' && onHover(dragItem, dropArea);
            },
        }),
        [camId]
    );

    drop(ref);

    return <div ref={ref}>{children}</div>;
};
