import React from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MuiArrowDropUpIcon,
    MuiArrowRightIcon,
} from '@brivo/react-components';
import { get } from 'lodash';

import { checkPermissions } from '@brivo/onairplus-services';

import NewIndicator from '../NewIndicator';
import NavItem from '../NavItem';
import styles from './styles';

const NestedItems = ({ user, expanded, item, index, setOpened, categoryIsNew }) => {
    const { id, name, icon, children } = item;
    const classes = styles();

    return (
        <Accordion
            classes={{
                root: classes.expansionPanel,
            }}
            expanded={expanded}
            onChange={(event, isExpanded) => setOpened(isExpanded)}
            square
            id={`NavAccordion-${id}`}
            key={`${name}-${index}`}
        >
            <AccordionSummary
                id={`NavAccordion__Label-${id}`}
                onClick={() => {
                    const childThatShouldBeClicked = item.children.find((child) => {
                        const necessaryPermissions = get(child, 'necessaryPermissions', []);
                        const anyOfPermissions = get(child, 'anyOfPermissions', []);
                        return (
                            child.isVisible !== false &&
                            checkPermissions({
                                userPermissions: user.permissions,
                                necessaryPermissions,
                                anyOfPermissions,
                            })
                        );
                    });
                    if (childThatShouldBeClicked) {
                        childThatShouldBeClicked.onClick();
                    }
                }}
                expandIcon={expanded ? <MuiArrowDropUpIcon /> : <MuiArrowRightIcon />}
            >
                {icon}
                <Typography className={classes.newNavText}>{name}</Typography>
                <NewIndicator categoryIsNew={categoryIsNew} item={null} />
            </AccordionSummary>
            <AccordionDetails>
                {children?.map((child, i) => (
                    <NavItem
                        user={user}
                        key={`${child.name}-${i}`}
                        item={child}
                        index={i}
                        nested={true}
                        setOpened={setOpened}
                        categoryIsNew={categoryIsNew}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
};

export default NestedItems;
