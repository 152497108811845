import gql from 'graphql-tag';

export const GET_READER_COMMANDS_FOR_SWITCH = gql`
    query getReaderCommandsForSwitch($switchId: Int!) {
        getReaderCommandsForSwitch(switchId: $switchId) {
            readerCommandId
            readerCommandName
            inputStateRelay
        }
    }
`;
