export const convertToNewMappings = (input) => {
    const newMappings = [];
    for (const [pms_id, site] of Object.entries(input)) {
        newMappings.push({
            unit_id: parseInt(pms_id),
            site_id: site.id,
            site_name: site.name,
        });
    }

    return newMappings;
};

export const filterUniqueSiteIds = (mappings) => {
    const uniqueMappings = {};
    const siteIds = new Set();

    for (const [key, value] of Object.entries(mappings)) {
        if (!siteIds.has(value.id)) {
            siteIds.add(value.id);
            uniqueMappings[key] = value;
        }
    }

    return uniqueMappings;
};
