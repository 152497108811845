import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@brivo/react-components';

export const VideoGridItem = ({ id, children, columnSize, nrOfVideos, hasCustomColumnSize = false }) => {
    const computedProps = useMemo(() => {
        if (hasCustomColumnSize) {
            return { md: nrOfVideos };
        }

        return nrOfVideos > 1 ? { md: 6 } : {};
    }, [hasCustomColumnSize, nrOfVideos]);

    return (
        <Grid item xs={12} lg={columnSize} id={`gridblock~${id}`} {...computedProps}>
            {children}
        </Grid>
    );
};

VideoGridItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.node.isRequired,
    columnSize: PropTypes.number.isRequired,
    nrOfVideos: PropTypes.number,
    hasCustomColumnSize: PropTypes.bool,
};
