import { startOfDay, endOfDay, startOfHour, addMilliseconds } from 'date-fns';
import { zip } from 'lodash';

const THIRTY_SECONDS_MS = 30000;

export const chooseListOrDefault = (idealChoice, defaultChoice) => {
    return idealChoice?.length ? idealChoice : defaultChoice;
};

const argIsType = (argValue, argName, requiredType) => {
    if (typeof argValue !== requiredType) {
        throw new Error(`argument ${argName} must be type ${requiredType}, received type ${typeof argValue}`);
    }
};

export const checkArgsTypes = (argList, argNames, requiredType) => {
    if (!requiredType) {
        throw new Error('missing arg requiredType');
    }
    if (!(argList instanceof Array) || !(argNames instanceof Array)) {
        throw new Error('argList and argNames must be arrays');
    }
    if (!argList?.length || !argNames?.length) {
        throw new Error('missing argList or argNames');
    } else if (argList?.length !== argNames?.length) {
        throw new Error(
            `argList and argNames must have same length, received ${argList?.length} and ${argNames?.length}`
        );
    }
    const args = zip(argList, argNames);
    args.forEach(([argValue, argName]) => {
        argIsType(argValue, argName, requiredType);
    });
};

export const getTimelineMaxEndTimestamp = (selectedDate, currentDate) => {
    return Date.parse(
        endOfDay(selectedDate ?? new Date()) > Date.now() ? currentDate : endOfDay(selectedDate ?? currentDate)
    );
};

export const binEventsByHour = (events) => {
    if (!events?.length) {
        return false;
    }

    const hoursMap = {};
    events.forEach((event) => {
        const occurred = new Date(event.occurred);
        const binHourString = startOfHour(occurred).toISOString();
        const binDateString = startOfDay(occurred).toISOString();

        try {
            if (!hoursMap[binDateString]) {
                hoursMap[binDateString] = [];
            }
            if (!hoursMap[binDateString][binHourString]) {
                hoursMap[binDateString][binHourString] = [];
            }
            hoursMap[binDateString][binHourString] = [...(hoursMap[binDateString][binHourString] ?? []), event];
        } catch (e) {
            console.error(`event category ${binDateString} ${binHourString} does not exist`);
        }
    });
    console.debug(`mapped events to day bins: ${JSON.stringify(Object.keys(hoursMap))}`);
    return hoursMap;
};

/**
 * Until we have a better solution for viewing long videos,
 * request at monst 30 seconds of a long provider clip, ensuring
 * requested end time is not after end of clip or current time
 */
export const getProviderClipRequestTimes = (clipFromList, currentTimelineRangeEnd) => {
    checkArgsTypes([currentTimelineRangeEnd], ['currentTimelineRangeEnd'], 'number');
    if (clipFromList.startTime > currentTimelineRangeEnd) {
        throw new Error('cannot play clip after visible timeline window');
    }
    const startTime = new Date(clipFromList.startTime).toISOString();
    let endTime;
    let margin;

    const clipIsShort = clipFromList.endTime && clipFromList.endTime - clipFromList.startTime <= THIRTY_SECONDS_MS;
    if (clipIsShort) {
        endTime = new Date(clipFromList.endTime).toISOString();
    } else {
        margin =
            Math.min(clipFromList.endTime ?? currentTimelineRangeEnd, currentTimelineRangeEnd) - clipFromList.startTime;
        const millisecondsToAskFor = Math.min(THIRTY_SECONDS_MS, margin);
        endTime = addMilliseconds(new Date(startTime), millisecondsToAskFor).toISOString();
    }
    return { startTime, endTime };
};
