import React from 'react';
import { Waypoint } from 'react-waypoint';

import TimeDate from '../../../../common/components/TimeDate';
import {
    checkNullEntry,
    formatDateToDateString,
    formatDateToTimeString,
    formatDurationInMinutes,
    formatNullAsDash,
} from '../../common/utils';

import {
    BOOLEAN_DATATYPE,
    DATE_DATATYPE,
    NUMBER_DATATYPE,
    STRING_DATATYPE,
    TIME_DATATYPE,
    TIMESTAMP_DATATYPE,
    TIMESTAMP_NTZ_DATATYPE,
} from './constants';

export const sortQueryResult = ({ queryResult, sortColumn }) => {
    return queryResult.sort((a, b) => {
        const directionMultiplier = sortColumn.order === 'desc' ? -1 : 1;
        const aValue = getDefaultIfNull(a[sortColumn.key], sortColumn.dataType);
        const bValue = getDefaultIfNull(b[sortColumn.key], sortColumn.dataType);
        switch (sortColumn.dataType) {
            case STRING_DATATYPE:
                const comparison = aValue.localeCompare(bValue);
                if (comparison !== 0) {
                    return comparison * directionMultiplier;
                }
                return comparison;
            case TIMESTAMP_DATATYPE:
            case TIMESTAMP_NTZ_DATATYPE:
                const comp = aValue.localeCompare(bValue);
                if (comp !== 0) {
                    return comp * directionMultiplier;
                }
                return comp;
            case NUMBER_DATATYPE:
                return (aValue - bValue) * directionMultiplier;
            case DATE_DATATYPE:
                return (new Date(bValue) - new Date(aValue)) * directionMultiplier * -1;
            case BOOLEAN_DATATYPE:
                return (aValue === bValue ? 0 : aValue ? 1 : -1) * directionMultiplier;
            case TIME_DATATYPE:
                return (
                    (new Date(`1970-01-01T${aValue}`).getTime() - new Date(`1970-01-01T${bValue}`).getTime()) *
                    directionMultiplier
                );
            default:
                return 0;
        }
    });
};

const typeFormatters = {
    timestamp_ntz: (item, col) => {
        return checkNullEntry(item[col]) ? '-' : <TimeDate dateValue={item[col]} />;
    },
    timestamp: (item, col) => {
        return checkNullEntry(item[col]) ? '-' : <TimeDate dateValue={item[col]} />;
    },
    duration_minutes: (item, col) => {
        return <div>{formatDurationInMinutes(item[col])}</div>;
    },
    timestamp_to_time: (item, col) => {
        return <div>{formatDateToTimeString(item[col])}</div>;
    },
    timestamp_to_date: (item, col) => {
        return <div>{formatDateToDateString(item[col])}</div>;
    },
    string_not_null: (item, col) => {
        return <div>{formatNullAsDash(item[col])}</div>;
    },
};

export const cellHandler = (item, col, dataType, loadMoreDataCallback) => {
    let cellJSX;
    if (typeof typeFormatters[dataType] === 'function') {
        cellJSX = <span className="u-trim">{typeFormatters[dataType](item, col)}</span>;
    } else {
        cellJSX = <div title={`${item[col]}`} className="u-trim">{`${item[col]}`}</div>;
    }
    if (typeof loadMoreDataCallback === 'function') {
        return (
            <>
                <Waypoint key="loadMore" onEnter={loadMoreDataCallback} fireOnRapidScroll />
                {cellJSX}
            </>
        );
    } else {
        return cellJSX;
    }
};

export const formatData = (data = [], formatters = []) => {
    if (formatters?.length > 0) {
        data = data.map((row) => {
            const updatedRow = { ...row };
            formatters.forEach((format) => {
                updatedRow[format.id] = format.formatter(updatedRow[format.id]);
            });
            return updatedRow;
        });
        return data;
    }
    return data;
};

export const setTableHeight = () => {
    let x = window.innerHeight;

    if (window.innerHeight > 900) {
        return x / 1.7;
    } else {
        return x / 2;
    }
};
export const setPivotTableHeightInDashboard = () => {
    let x = window.innerHeight;

    if (window.innerHeight > 900) {
        return x / 1.4;
    } else {
        return x / 1.5;
    }
};

export const setEditingTableHeight = () => {
    let x = window.innerHeight;
    if (window.innerHeight > 900) {
        return x / 2;
    } else {
        return x / 2.4;
    }
};

export const setDimensionsSelectorHeight = () => {
    let x = window.innerHeight;

    if (window.innerHeight > 900) {
        return x / 2.1;
    } else {
        return x / 2.8;
    }
};

export const setVisualSetupHeight = () => {
    let x = window.innerHeight;
    if (window.innerHeight > 900) {
        return x / 2.4;
    } else {
        return x / 3.2;
    }
};

const sortingDefaultValues = {
    [NUMBER_DATATYPE]: -Infinity,
    [STRING_DATATYPE]: '',
    [TIMESTAMP_NTZ_DATATYPE]: '1970-01-01 00:00:00.000',
    [TIMESTAMP_DATATYPE]: '1970-01-01 00:00:00.000',
    [DATE_DATATYPE]: '1970-01-01',
    [BOOLEAN_DATATYPE]: false,
    [TIME_DATATYPE]: '00:00:00.0000',
};

const getDefaultIfNull = (value, dataType) => {
    if (value === null) {
        return sortingDefaultValues[dataType];
    } else {
        return value;
    }
};
