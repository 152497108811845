import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    GenericDialog,
    AccountInfoIcon,
    Avatar,
    DialogActions,
    ThemeContext,
    Grid,
    CustomLayoutLargeIcon,
    TwoColumnGridIcon,
    Typography,
} from '@brivo/react-components';
import NameInput from './NameInput';
import { FULL_WIDTH, HALF_WIDTH } from './constants';

const RenameDialog = ({
    title,
    nameInputProps,
    onRenameChange,
    isOpen,
    onClose,
    showEditLayoutButtons = false,
    currentLayout,
    onLayoutChange = null,
}) => {
    const [name, setName] = useState('');
    const [isDashboardNameValid, setNameValidity] = useState(false);

    const { muiTheme } = useContext(ThemeContext);
    const { t } = useTranslation();

    const handleValidateInput = (isValid, newDashboardName) => {
        setNameValidity(isValid);

        if (isValid) {
            setName(newDashboardName);
        }
    };

    const applyRename = () => {
        onRenameChange(name);
    };

    const onEditLayout = (newLayout) => {
        onLayoutChange(newLayout);
    };

    const {
        title: inputTitle,
        placeholder: inputPlaceholder,
        value: inputValue,
        existingNames,
        errorMessages,
    } = nameInputProps;

    return (
        <GenericDialog
            open={isOpen}
            onClose={onClose}
            actions={
                <DialogActions>
                    <Button
                        type="primary"
                        id="secondary-btn"
                        text={t('Page.brivo-analytics.my-analytics.rename-dashboard.dialog.button')}
                        size="small"
                        onClick={applyRename}
                        disabled={!isDashboardNameValid}
                    />
                </DialogActions>
            }
            avatarIcon={
                <Avatar size={'small'}>{<AccountInfoIcon style={{ color: muiTheme.palette.primary.main }} />}</Avatar>
            }
            title={title}
        >
            <NameInput
                title={inputTitle}
                placeholder={inputPlaceholder}
                value={inputValue}
                existingNames={existingNames}
                errorMessages={errorMessages}
                onValidationChange={handleValidateInput}
            />

            {showEditLayoutButtons && onLayoutChange && (
                <>
                    <Typography variant="body1" style={{ marginBottom: muiTheme.spacing(0.5) }}>
                        {t('Page.brivo-analytics.my-analytics.edit-section.dialog.layout')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                id="edit_section_full_layout"
                                type="secondary"
                                onClick={() => onEditLayout(FULL_WIDTH)}
                                customWidth="100%"
                                disabled={currentLayout === FULL_WIDTH}
                                startIcon={<CustomLayoutLargeIcon style={{ color: muiTheme.palette.primary.main }} />}
                                text={t('Page.brivo-analytics.my-analytics.edit-section.dialog.full-layout')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                id="edit_section_half_layout"
                                type="secondary"
                                onClick={() => onEditLayout(HALF_WIDTH)}
                                customWidth="100%"
                                disabled={currentLayout === HALF_WIDTH}
                                startIcon={<TwoColumnGridIcon style={{ color: muiTheme.palette.primary.main }} />}
                                text={t('Page.brivo-analytics.my-analytics.edit-section.dialog.half-layout')}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </GenericDialog>
    );
};
export default RenameDialog;
