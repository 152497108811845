import React from 'react';
import { AddItemIcon, Button, FiltersContainer, TextFilter } from '@brivo/react-components';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMEOUT_MS_MSF } from '@common/constants/Constants';
import PropertyHeader from '../PropertyHeader';
import { useTranslation } from 'react-i18next';
import Optional from '@common/components/Optional';

export function PropertyListFilterSection({
    sites,
    filterValues,
    titleProps,
    filterChangeMethods,
    onCreateSiteClick,
    onTransformSiteClick,
    isLoading,
    clearingValues,
    setClearingValues,
}) {
    const showWhenSitesExist = !(sites?.length === 0 && filterValues.name === '');
    const { t } = useTranslation();

    return (
        <FiltersContainer
            showMore={{
                enabled: false,
                showMoreTitle: t('Component.filters-container.show-more-title'),
                showLessTitle: t('Component.filters-container.show-less-title'),
            }}
            topLeft={
                <PropertyHeader
                    title={t('Page.property-list-section.title')}
                    subtitle={t('Page.property-list.table.subtitle')}
                />
            }
            topRight={
                <div className={'u-fx u-fx-row'}>
                    <Optional condition={showWhenSitesExist}>
                        <div className="u-mr-full">
                            <Button
                                id="create-property"
                                text={titleProps.buttonText}
                                startIcon={<AddItemIcon />}
                                onClick={onCreateSiteClick}
                            />
                        </div>
                    </Optional>
                    <Optional condition={onTransformSiteClick}>
                        <div className="u-mr-full">
                            <Button
                                id="transform-site"
                                text={t('Page.property-list.transform-button')}
                                startIcon={<AddItemIcon />}
                                onClick={onTransformSiteClick}
                            />
                        </div>
                    </Optional>
                </div>
            }
            showMoreTitle={t('Component.filters-container.show-more-title')}
            showLessTitle={t('Component.filters-container.show-less-title')}
        >
            {showWhenSitesExist && (
                <TextFilter
                    id="sites_filter"
                    label={titleProps.filterTitle}
                    placeholder={titleProps.filterPlaceholder}
                    items={sites}
                    value={filterValues.name}
                    onValueChange={
                        filterChangeMethods.name && debounce(filterChangeMethods.name, DEBOUNCE_TIMEOUT_MS_MSF)
                    }
                    disabled={isLoading}
                    clearingValues={clearingValues}
                    setClearingValues={setClearingValues}
                />
            )}
        </FiltersContainer>
    );
}
