import gql from 'graphql-tag';

export const CREATE_DEVICE_TO_SITE_MAPPING = gql`
    mutation createDeviceMapping($deviceIds: [Int]!, $siteId: Int!) {
        createDeviceMapping(siteId: $siteId, deviceIds: $deviceIds)
    }
`;

export const UPDATE_DEVICE_TO_SITE_MAPPING = gql`
    mutation updateDeviceMapping($siteId: Int!, $deviceId: Int!) {
        updateDeviceMapping(deviceId: $deviceId, siteId: $siteId)
    }
`;

export const DELETE_DEVICE_TO_SITE_MAPPING = gql`
    mutation deleteDeviceMapping($deviceId: Int!) {
        deleteDeviceMapping(deviceId: $deviceId)
    }
`;

export const DELETE_DEVICES_MAPPED_TO_SITE = gql`
    mutation deleteDeviceMappingForSite($siteId: Int!) {
        deleteDeviceMappingForSite(siteId: $siteId)
    }
`;

export const GET_HIERARCHICAL_OFFLINE_GROUPS = gql`
    query getHierarchicalOfflineGroups($siteOid: Int!) {
        getPropertyHierarchyOfflineGroups(siteOid: $siteOid) {
            id
            name
        }
    }
`;

export const GET_OFFLINE_GROUPS = gql`
    query getOfflineGroups($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getOfflineGroups(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            offlineGroups {
                id
                name
            }
            totalCount
        }
    }
`;

export const GET_ACCESS_POINT_MAPPING_INFO = gql`
    query getAccessPointMappingInfo($deviceOid: Int!) {
        getAccessPointMappingInformation(deviceOid: $deviceOid) {
            mapped
            common
            site {
                id
                name
            }
        }
    }
`;
