import gql from 'graphql-tag';

export const GET_SITE_CAMERAS = gql`
    query getDetailedCameras($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getDetailedCameras(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            id
            cameraId
            name
        }
    }
`;

export const GET_SITE_CAMERAS_WITH_STATUS = gql`
    query getDetailedCameras($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getDetailedCameras(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            id
            cameraId
            status
            name
        }
    }
`;

export const GET_SITE_CAMERAS_WITH_LIVE_URL = gql`
    query getDetailedCameras($pageSize: Int!, $offset: Int!, $filters: [ListFilter]) {
        getDetailedCameras(limit: { rows: $pageSize, offset: $offset }, filters: $filters) {
            id
            cameraId
            status
            name
            videoProvider {
                type
                version
            }
            liveViewUrl
        }
    }
`;
export const GET_SITE_DEVICES = gql`
    query getAllSiteDevices($pageSize: Int!, $offset: Int!, $sitesOids: [Int]) {
        getAccessPointsForSites(limit: { rows: $pageSize, offset: $offset }, sitesOids: $sitesOids) {
            controlPanelId
            controlPanelName
            controlPanelTypeId
            deviceId
            id
            name
            type
        }
    }
`;

export const GET_SITE_DEVICES_WITH_STATUS = gql`
    query getAllSiteDevices($pageSize: Int!, $offset: Int!, $sitesOids: [Int]) {
        getAccessPointsForSites(limit: { rows: $pageSize, offset: $offset }, sitesOids: $sitesOids) {
            controlPanelId
            controlPanelName
            controlPanelTypeId
            deviceId
            id
            name
            type
            status {
                data {
                    deviceName
                    deviceId
                    siteId
                    reportLiveStatus
                    controlPanelId
                    lockState
                    deviceStatus
                    systemStatus
                    systemStatusMessage
                    messageReceivedTime
                    isActive
                    threatLevel
                }
                rawData {
                    connectedStatus
                    nextUnlockScheduleChange
                    nextIgnoreScheduleChange
                    unlockScheduleStatus
                    ignoreScheduleStatus
                    accessState
                    ajarStatus
                    inputStatus
                    intrusionStatus
                    batteryState
                    batteryRemaining
                    doorState
                    threatLevel
                    readerStatus
                    alternateReaderStatus
                }
            }
        }
    }
`;
export const GET_SITE_PANELS = gql`
    query getAllPanels($pageSize: Int!, $offset: Int!) {
        getAllPanels(limit: { rows: $pageSize, offset: $offset }) {
            id
            panelName
            panelType
        }
    }
`;
export const GET_SITE_PANELS_WITH_STATUS = gql`
    query getAllPanels($pageSize: Int!, $offset: Int!) {
        getAllPanels(limit: { rows: $pageSize, offset: $offset }) {
            id
            panelName
            panelType
            status
        }
    }
`;

export const SAVE_FLOOR_PLAN = gql`
    mutation saveFloorPlan($floorPlanInput: FloorPlanInput!) {
        saveFloorPlan(floorPlanInput: $floorPlanInput) {
            floorPlanId
            floorPlanImageURL
        }
    }
`;

export const GET_FLOOR_PLANS = gql`
    query getFloorPlans($siteIds: [Int]!) {
        getFloorPlans(siteIds: $siteIds) {
            floorPlanId
            name
            contentType
            filename
            siteId
            siteName
        }
    }
`;

export const GET_FLOOR_PLAN_BY_ID = gql`
    query getFloorPlanById($floorPlanId: Int!) {
        getFloorPlanById(floorPlanId: $floorPlanId) {
            floorPlanId
            name
            contentType
            filename
            floorPlanImageURL
            devices {
                deviceId
                top
                left
                rotation
                deviceType
                deviceName
            }
        }
    }
`;

export const UPDATE_FLOOR_PLAN = gql`
    mutation updateFloorPlan($floorPlanUpdateInput: FloorPlanUpdateInput!) {
        updateFloorPlan(floorPlanUpdateInput: $floorPlanUpdateInput) {
            floorPlanImageURL
        }
    }
`;
export const DELETE_FLOOR_PLAN = gql`
    mutation deleteFloorPlan($floorPlanId: Int!, $siteId: Int!) {
        deleteFloorPlan(floorPlanId: $floorPlanId, siteId: $siteId)
    }
`;
const precachedClips = ` precachedClips {
            id
            occurred
            cameraName
            cameraId
            storedClipLocation {
                key
                bucket
                signedClipUrl
                signedPreviewImageUrl
            }
          }`;

const snapshots = ` snapshots {
            snapshotUrl
            snapshotPreviewUrl
            cameraId
            sourceEventId
            sourceEventSecurityActionId
            panelEventOccurred
        }`;

const enrichmentMetadataQueryItems =
    precachedClips || snapshots
        ? `enrichmentMetadata {
                        ${precachedClips}
                        ${snapshots}
                    }`
        : '';
export const GET_EVENTS_FOR_DEVICES = gql`
    query listAccessEvents(
        $from: AWSDateTime
        $to: AWSDateTime
        $limit: Int
        $devices: [Int]
        $nextToken: String
        $excludedSecurityActionIds: [Int]
        $securityActionIds: [Int]
    ) {
        listAccessEvents(
            filter: {
                occurred: { between: [$from, $to] }
                objectId: { in: $devices }
                securityActionId: { notIn: $excludedSecurityActionIds, in: $securityActionIds }
            }
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                occurred
                securityActionId
                actorId
                actorName
                ${enrichmentMetadataQueryItems}
                objectId
            }
            nextToken
        }
    }
`;

export const GET_EVENTS_SEVERITY_COUNT_FOR_DEVICES = gql`
    query getAccessEventsCountByDevices(
        $from: AWSDateTime
        $to: AWSDateTime
        $devices: [Int]
        $securityActionIds: [Int]
    ) {
        getAccessEventsCountByDevices(
            filter: {
                occurred: { between: [$from, $to] }
                objectId: { in: $devices }
                securityActionId: { in: $securityActionIds }
            }
        ) {
            total
            severityCounts {
                deviceId
                count
                informationalCount
                warningCount
                criticalCount
                severity
            }
        }
    }
`;
