import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import sites from './reducers/Sites.reducer';
import accessToken from './reducers/Auth.reducer';
import * as authTypes from './types/Auth.types';
import * as apiTypes from './types/Api.types';

const lastAction = (_ = null, action) => action;

const appReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        accessToken,
        sites,
        lastAction,
    });

const rootReducer = (history) => (state, action) => {
    if (
        action.type === `${authTypes.LOGOUT} ${apiTypes.API_SUCCESS}` ||
        action.type.includes(authTypes.AUTH[401]) ||
        action.type.includes(authTypes.AUTH[403])
    ) {
        // Reset to global initial state
        state = {
            accessToken: null,
            sites: null,
            lastAction: null,
        };
    }

    return appReducer(history)(state, action);
};

const reduceHistory = (history) => rootReducer(history);

export default reduceHistory;
