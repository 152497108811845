import { WebReqApi } from '../../WebReqApi';
import { SMARTHOME_API_DOMAIN, SMARTHOME_API_SCHEME } from '../../../constants/Constants';

import { auth } from '@common/utils/Auth';
import { createUrl } from '../../utils';
import { convertToNewMappings } from '@pages/Integrations/PMS/utils/mappingUtils';
import { integrationApi } from '@common/webApis/integrations/integrationsApi';

class IntegrationsPmsApi extends WebReqApi {
    constructor(authToken) {
        super();
        this.authToken = authToken;
    }
    getRequestOptions = (method = 'GET', body = null) => {
        let configObj = {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.authToken.accessToken}`,
            },
        };

        if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && body) {
            configObj = {
                ...configObj,
                body: JSON.stringify(body),
            };
        }

        return configObj;
    };

    getIntegrationsPMSVendors = async () => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/supported-pms-integrations`,
            this.getRequestOptions()
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.error(`Call to get pms integrations failed`);
                    return [];
                }
            })
            .catch(() => {
                console.error(`Call to get pms integrations failed`);
                return [];
            });
    };

    enablePMSIntegration = async (vendor) => {
        try {
            await integrationApi.saveIntegrationInfoState(vendor.toUpperCase(), true);
        } catch (error) {
            console.error(`Error enabling ${vendor} integration:`, error);
        }
        const body = {
            vendor: vendor,
            credentials: {},
        };
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/activate-pms-integration`,
            this.getRequestOptions('POST', body)
        ).then(async (response) => {
            if (response.status === 201) {
                return response.json();
            }

            if (response.status >= 400) {
                const errorResponse = await response.json();
                throw new Error(errorResponse);
            }
        });
    };

    disablePMSIntegration = async (vendor, retentionSettings = {}) => {
        const queryParams = {};
        try {
            await integrationApi.saveIntegrationInfoState(vendor.toUpperCase(), false);
        } catch (error) {
            console.error(`Error disabling ${vendor} integration:`, error);
        }
        if (retentionSettings?.forceDelete === true) {
            queryParams.force_delete = true;
        } else {
            queryParams.remove_sites = retentionSettings?.deleteSites || false;
            queryParams.remove_residents = retentionSettings?.deleteResidents || false;
            queryParams.force_delete = retentionSettings?.forceDelete || false;
        }

        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}`,
            {},
            queryParams
        );
        return fetch(urlWithParams, this.getRequestOptions('DELETE')).then(async (response) => {
            if (response.status === 409) {
                throw new Error('Disabling Integration failed.');
            }
            return response;
        });
    };

    getIntegrationDetails = async (vendor) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}`,
            this.getRequestOptions()
        ).then(async (response) => {
            if (response.ok) {
                return response.json();
            } else {
                let errorMessage = response.statusText;
                if (response.headers.get('Content-Type')?.includes('application/json')) {
                    const errorBody = await response.json();
                    errorMessage = errorBody.detail || errorMessage;
                }
                throw new Error(errorMessage);
            }
        });
    };

    updateIntegration = async (vendor, payload) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration`,
            this.getRequestOptions('PATCH', payload)
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        });
    };

    getPmsUnits = async (vendor, pageSize = 15, offset = 0, filters) => {
        const queryParams = { limit: pageSize, offset: offset, name: filters.name };

        if (filters?.parentId) {
            queryParams.parent_id = filters.parentId;
        }

        if (filters?.onlyLastLevel) {
            queryParams.only_last_level = true;
        }
        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/units`,
            {},
            queryParams
        );

        return fetch(urlWithParams, this.getRequestOptions()).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error(`Call to get pms units failed`);
                return { results: [], count: 0 };
            }
        });
    };

    getMappedUnits = async (vendor, pageSize = 10, offset = 0, filters = { name: '' }) => {
        let queryParams = { limit: pageSize, offset: offset, name: filters.name };

        if (filters?.parentId) {
            queryParams.parent_id = filters.parentId;
        }

        if (filters?.filteredIds && filters.filteredIds.length > 0) {
            queryParams = { ...queryParams, ...filters.filteredIds };
        }

        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/mapped-units`,
            {},
            queryParams
        );

        return fetch(urlWithParams, this.getRequestOptions()).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error(`Call to get mapped failed`);
                return { results: [], count: 0 };
            }
        });
    };

    getMappedUnitsTree = async (vendor, pageSize = 10, offset = 0, filters = { name: '' }) => {
        const queryParams = { limit: pageSize, offset: offset, name: filters.name };

        if (filters?.parentId) {
            queryParams.parent_id = filters.parentId;
        }

        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/mapped-units-tree`,
            {},
            queryParams
        );

        return fetch(urlWithParams, this.getRequestOptions()).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error(`Call to get mapped failed`);
                return { results: [], count: 0 };
            }
        });
    };

    updateMappedUnits = async (vendor, addedUnits, removedUnits, allSelected = false) => {
        let body = {
            vendor: vendor,
            new_mappings: addedUnits,
            removed_mappings: removedUnits,
        };

        if (allSelected) {
            body = { vendor: vendor, select_all: allSelected };
        }
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/map-units`,
            this.getRequestOptions('POST', body)
        ).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error(`Call to update mapped units failed`);
                throw new Error('Call to update mapped units failed');
            }
        });
    };

    mapExistingUnits = async (vendor, unitsWithSites) => {
        const body = {
            vendor: vendor,
            new_mappings: convertToNewMappings(unitsWithSites),
        };

        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/map-existing-sites`,
            this.getRequestOptions('POST', body)
        ).then(async (response) => {
            if (response.status === 200 || response.status === 400) {
                return response.json();
            } else {
                console.error(`Call to update mapped units failed`);
            }
        });
    };

    lastSuccessfullRuns = async (vendor) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/last-successful-runs`,
            this.getRequestOptions()
        ).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 404) {
                return 'integrationDisabled';
            } else {
                console.error(`Call to update mapped units failed`);
                return [];
            }
        });
    };

    syncResidents = async (vendor) => {
        const body = {
            vendor: vendor,
        };

        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/sync-residents`,
            this.getRequestOptions('POST', body)
        ).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Syncing residents failed');
            }
        });
    };

    syncMapping = async (vendor) => {
        const body = {
            vendor: vendor,
        };

        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/sync-mapped-units`,
            this.getRequestOptions('POST', body)
        ).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Mapping Units Failed');
            }
        });
    };

    syncUnits = async (vendor) => {
        const body = {
            vendor: vendor,
        };

        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/sync-vendor-units`,
            this.getRequestOptions('POST', body)
        ).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Fetching integration units failed');
            }
        });
    };

    getAvailableRelationships = async (vendor, credentials) => {
        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/retrieve-available-relationships`,
            {},
            { vendor: vendor, ...credentials }
        );

        return fetch(urlWithParams, this.getRequestOptions()).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error(`Call to get available relationships failed`);
                throw Error('Failed to fetch relationships');
            }
        });
    };

    getRelationships = async (vendor) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/${vendor}/get-relationships`,
            this.getRequestOptions()
        ).then(async (response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.error(`Call to update mapped units failed`);
                return [];
            }
        });
    };

    checkGroupInPMS = async (id) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/group/${id}`,
            this.getRequestOptions()
        )
            .then(async (response) => {
                if (response && response.status === 200) {
                    const resp = await response.json();
                    return !!(resp && resp.is_found);
                }
                return null;
            })
            .catch(() => {
                return null;
            });
    };

    checkUserInPMS = async (id) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/user/${id}`,
            this.getRequestOptions()
        )
            .then(async (response) => {
                if (response && response.status === 200) {
                    const resp = await response.json();
                    return !!(resp && resp.is_found);
                }
                return null;
            })
            .catch(() => {
                return null;
            });
    };

    checkSiteInPMS = async (id) => {
        return fetch(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/site/${id}`,
            this.getRequestOptions()
        )
            .then(async (response) => {
                if (response && response.status === 200) {
                    const resp = await response.json();
                    return !!(resp && resp.is_found);
                }
                return null;
            })
            .catch(() => {
                return null;
            });
    };

    retrieveUsersPmsActiveStatus = async (ids) => {
        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/users-list/`,
            {},
            { ...ids }
        );
        return fetch(urlWithParams, this.getRequestOptions())
            .then(async (response) => {
                if (response && response.status === 200) {
                    return await response.json();
                }
                return null;
            })
            .catch(() => {
                return null;
            });
    };

    retrieveGroupsPmsActiveStatus = async (ids) => {
        const urlWithParams = createUrl(
            `${SMARTHOME_API_SCHEME}://${SMARTHOME_API_DOMAIN}/v3/pms-integration/groups-list/`,
            {},
            { ...ids }
        );
        return fetch(urlWithParams, this.getRequestOptions())
            .then(async (response) => {
                if (response && response.status === 200) {
                    return await response.json();
                }
                return null;
            })
            .catch(() => {
                return null;
            });
    };
}

export const integrationsPmsApi = new IntegrationsPmsApi(auth);
