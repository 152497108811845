import { memo } from 'react';
import { t } from 'i18next';
import { Waypoint } from 'react-waypoint';
import { isEqual as isDateEqual, isSameDay, startOfDay, startOfHour, toDate } from 'date-fns';
import { differenceWith, intersectionWith, isEqual, omit, unionWith, xorWith, isEmpty, cloneDeep } from 'lodash';

import { Button, ChevronRight, withTheme, hexToRGB } from '@brivo/react-components';

import { hasValidTLD } from './allowed-tlds';
import {
    ALLEGION_DOOR_LOCK,
    ASSA_ABLOY_DOOR_LOCK,
    BRIVO_MOBILE_PASS_CREDENTIAL_NAME,
    CARD_CREDENTIAL_NAME,
    DOOR,
    EDITION_DEMO,
    EDITION_ENTERPRISE,
    EDITION_PROFESSIONAL,
    EDITION_STANDARD,
    LICENSE_PLATE_CREDENTIAL_NAME,
    FACIAL_ID_CREDENTIAL_NAME,
    MAX_PAGE_SIZE_GRAPHQL,
    OCCUPANCY_SITE_BATCH_SIZE,
    OFFLINE_DOOR_LOCK,
    PIN_CREDENTIAL_NAME,
    SALTO_DOOR_LOCK,
    SWITCH,
    FLOOR,
    VALID_CREDENTIAL,
    SIMONS_VOSS_LOCK,
    ALWAYS_ACCESS,
} from '../constants/Constants';
import {
    RecentActivityUsingCard,
    RecentActivityUsingLicensePlate,
    RecentActivityUsingMobilePass,
    RecentActivityUsingPin,
    RecentActivityUsingUnknown,
    RecentActivityUsingFaceprint,
} from '../components/Icons';

const PEAKFACE_BBOX_THRESHOLD = 0.44;
const FILTER_OFFLINE_DOOR = `access_point_type__eq:OFFLINE_DOOR`;
const FILTER_NOT_OFFLINE_DOOR = `access_point_type__ne:OFFLINE_DOOR`;

// subscription level is all lower case
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const groupByDate = (arr, key) => {
    return arr.reduce((a, b) => {
        (a[startOfDay(new Date(b[key]))] = a[startOfDay(new Date(b[key]))] || []).push(b);
        return a;
    }, {});
};

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const clearCanvas = (ctx) => {
    // draw new background
    // note: this has to occur before clearRect(), as the context
    // does not know the canvas has already been drawn on
    // this has a negligble performance impact (~10ms/per operation)
    ctx.beginPath();
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.closePath();
    // clear canvas for re-draw
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
};

const drawBoundingBox = (boundingBox, dx, dy, ctx) => {
    ctx.beginPath();
    ctx.strokeStyle = '#F5D66A';
    ctx.lineWidth = 4;
    ctx.strokeRect(
        dx + (+boundingBox.left - 16),
        dy + (+boundingBox.top - 16),
        +boundingBox.width + 32,
        +boundingBox.height + 32
    );
    ctx.closePath();
};

// used for peakface image generation
export const makeNewImageWithBoundingBox = (
    image,
    boundingBox = { width: 0, height: 0, top: 0, left: 0 },
    confidence = 0,
    canvas
) => {
    return new Promise((resolve, reject) => {
        if (!canvas) {
            reject();
        }
        let ctx = canvas.getContext('2d');
        // clear
        clearCanvas(ctx);
        // create new image
        let img = new Image();
        img.crossOrigin = 'anonymous'; // required
        // draw on canvas
        img.onload = () => {
            let imgWidth = img.width;
            let imgHeight = img.height;
            let ratio = 0; // aspect ratio
            let dx = 0;
            let dy = 0;

            // resize image if width is larger than canvas width
            if (img.width > ctx.canvas.width) {
                ratio = ctx.canvas.width / imgWidth;
                img.width = ctx.canvas.width;
                img.height = imgHeight * ratio;
                imgHeight = imgHeight * ratio;
                imgWidth = imgWidth * ratio;
            }

            // resize image if height is larger than canvas height
            if (img.height > ctx.canvas.height) {
                ratio = ctx.canvas.height / imgHeight;
                img.height = ctx.canvas.height;
                img.width = imgWidth * ratio;
                imgWidth = imgWidth * ratio;
            }

            // center image on canvas
            // required to handle "portrait" images
            if (ratio > 0) {
                dx = ctx.canvas.width / 2 - imgWidth / 2;
            }

            ctx.drawImage(img, dx, dy);

            // the lowest reasonable confidence value to draw a bounding box
            if (confidence >= PEAKFACE_BBOX_THRESHOLD) {
                drawBoundingBox(boundingBox, dx, dy, ctx);
            }

            // TODO
            // cache this result using the service worker
            // the original image url should be used as the key
            // which is valid for 1 week
            canvas.toBlob(
                (blob) => {
                    window.URL = window.URL || window.webkitURL;
                    const url = window.URL.createObjectURL(blob);
                    resolve(url);
                },
                'image/jpeg',
                1
            );
        };
        img.src = image;
    });
};

// used for peakface image generation
export const zoomImageFromBoundingBox = (image, boundingBox = { width: 0, height: 0, top: 0, left: 0 }, canvas) => {
    return new Promise((resolve, reject) => {
        if (!canvas) {
            reject();
        }
        let ctx = canvas.getContext('2d');
        // clear canvas for re-draw
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // create new image
        let img = new Image();
        img.crossOrigin = 'anonymous'; // required
        // draw on canvas
        img.onload = () => {
            ctx.drawImage(
                img,
                +boundingBox.left - 12,
                +boundingBox.top - 12,
                +boundingBox.width + 24,
                +boundingBox.height + 24,
                0,
                0,
                canvas.width,
                canvas.height
            );

            // TODO
            // cache this result using the service worker
            // the original image url should be used as the key
            // which is valid for 1 week
            canvas.toBlob(
                (blob) => {
                    window.URL = window.URL || window.webkitURL;
                    const url = window.URL.createObjectURL(blob);
                    resolve(url);
                },
                'image/jpeg',
                1
            );
        };
        img.src = image;
    });
};

export const diffSites = (cache, sites) => {
    const omitGeolocation = (site) => omit(site, 'geolocation');
    const trimmedCache = cache?.map(omitGeolocation);

    const compId = (x, y) => x.id === y.id;

    const sitesToBeRemoved = differenceWith(trimmedCache, sites, isEqual);

    const sitesToBeAdded = differenceWith(sites, trimmedCache, isEqual);
    /**
     * The sites to be added need to be the first argument
     * since when two elements are "equal", the item from
     * the first array is returned
     */
    const sitesToBeUpdated = intersectionWith(sitesToBeAdded, sitesToBeRemoved, compId);

    const sitesToBeLeftTheSame = differenceWith(cache, unionWith(sitesToBeAdded, sitesToBeRemoved, compId), compId);
    return [
        // Actual diff with operations
        [
            ...(xorWith(sitesToBeRemoved, sitesToBeUpdated, compId)?.map((site) => ({
                ...site,
                operation: 'REMOVE',
            })) ?? []),
            ...(xorWith(sitesToBeAdded, sitesToBeUpdated, compId)?.map((site) => ({
                ...site,
                operation: 'ADD',
            })) ?? []),
            ...(sitesToBeUpdated?.map((site) => ({ ...site, operation: 'UPDATE' })) ?? []),
        ],
        // Untouched cache
        sitesToBeLeftTheSame,
    ];
};

export const getTriggerEventId = (detail) => {
    let eventId = detail.split(':');
    return eventId[1];
};

const defaultSeverityIconStyles = {
    minWidth: '.5rem',
    width: '.5rem',
    height: '.5rem',
    borderRadius: '.25rem',
    marginRight: '1rem',
};

export const SeverityIcon = memo(
    withTheme(({ theme, eventType, style }) => {
        const colorIndex = typeof eventType === 'string' ? eventType?.toLowerCase() : '';
        const color = theme.palette.severityIcon.background[colorIndex];
        if (!color) return null;

        const shadowColor = hexToRGB(color, '.2');
        return (
            <div
                style={{
                    ...defaultSeverityIconStyles,
                    ...style,
                    backgroundColor: color,
                    boxShadow: `0 0 0 4px ${shadowColor}`,
                }}
            />
        );
    })
);

export const isToday = (date) => isSameDay(date, new Date());

export const getHoursDiff = (date) => Math.floor((new Date() - new Date(date)) / 36e5);

export const compareDatesOfNotes = (bestIndexSoFar, currentlyTestedNote, currentlyTestedIndex, array) => {
    return currentlyTestedNote.timestamp > array[bestIndexSoFar].timestamp ? currentlyTestedIndex : bestIndexSoFar;
};

export const findMostRecentStatus = (notes) => {
    if (notes.length === 0) {
        return 'New';
    }

    notes.forEach((note) => {
        if (!note.status) {
            note.status = 'New'; //Default status for an event
        }
    });

    const indexOfLatestNote = notes.reduce(compareDatesOfNotes, 0);

    return notes[indexOfLatestNote].status;
};

export const getTotalEventsHourlyCount = (initialValues, criticalValues, warningValues, infoValues) => {
    const totalEventsCount = [];
    for (const hour in initialValues) {
        totalEventsCount.push(criticalValues[hour] + warningValues[hour] + infoValues[hour]);
    }
    return totalEventsCount.reverse();
};

export const getEventsHourlyCountForCategory = (xAxisHourValues, eventHourlyCategoryArray) => {
    let newXAxisHourValues = xAxisHourValues;

    if (eventHourlyCategoryArray) {
        eventHourlyCategoryArray.forEach((hourEntry) => {
            const hourCount = hourEntry.count;

            const hourDate = toDate(hourEntry.hour);
            const day = hourDate.getDay();
            const hour = hourDate.getHours();
            const dayAndHour = `${day}-${hour}`;

            if (newXAxisHourValues[dayAndHour] !== undefined) {
                newXAxisHourValues[dayAndHour] = hourCount;
            }
        });
    }

    const eventsCount = Object.keys(newXAxisHourValues)
        ?.map((key) => newXAxisHourValues[key])
        .reverse();

    return eventsCount;
};

export const isStartOfDay = (date) => {
    return isDateEqual(startOfDay(date), date);
};

/**
 * Return the array of dates within the specified time interval
 * Inspired from https://github.com/date-fns/date-fns/blob/16a561df59b9a015517fce568ffd929f46961f82/src/eachDayOfInterval/index.js
 */
export const eachHourOfInterval = (dirtyInterval, options) => {
    const interval = dirtyInterval || {};
    const startDate = toDate(interval.start);
    const endDate = toDate(interval.end);

    const endTime = endDate.getTime();

    // Throw an exception if start date is after end date or if any date is `Invalid Date`
    if (!(startDate.getTime() <= endTime)) {
        throw new RangeError('Invalid interval');
    }

    let dates = [];

    let currentDate = startOfHour(startDate);

    const step = options && 'step' in options ? Number(options.step) : 1;
    if (step < 1 || isNaN(step)) {
        throw new RangeError('`options.step` must be a number greater than 1');
    }
    while (currentDate.getTime() <= endTime) {
        dates.push(toDate(currentDate));
        currentDate.setHours(currentDate.getHours() + step, 0, 0, 0);
    }

    return dates;
};

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && hasValidTLD(email);
};

export const validateForm = (values) => {
    const errors = {};
    if (!values.firstName.trim()) {
        errors.firstName = t('Field.validation.required');
    }

    if (!values.lastName.trim()) {
        errors.lastName = t('Field.validation.required');
    }

    if (!values.email) {
        errors.email = t('Field.validation.required');
    } else if (!validateEmail(values.email)) {
        errors.email = t('Page.admin-details.validation.invalid-email');
    }

    if (!values.phoneNumber) {
        errors.phoneNumber = t('Field.validation.required');
    } else if (
        !/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
            values.phoneNumber
        )
    ) {
        errors.phoneNumber = t('Page.admin-details.validation.invalid-phone-number');
    }

    if (!values.timezone.trim()) {
        errors.timezone = t('Field.validation.required');
    }

    return errors;
};

export const validateRoleForm = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = t('Field.validation.required');
    }

    return errors;
};

export const validateRetentionSettings = (values) => {
    const errors = {};
    if (!/^0*[0-9]{1,4}$/.test(values.retentionDays)) {
        errors.retentionDays = t('Page.account-settings.validation.invalid-vdr', {
            maxRetentionDays: values.maxRetentionDays + 1,
        });
    } else {
        let val = parseInt(values.retentionDays);

        if (val > values.maxRetentionDays + 1) {
            errors.retentionDays = t('Page.account-settings.validation.invalid-vdr', {
                maxRetentionDays: values.maxRetentionDays + 1,
            });
        }
    }
    return errors;
};

export const sortById = (a, b) => {
    return a.id - b.id;
};

export const sortByName = (a, b) => {
    return a.name.localeCompare(b.name);
};

/*WARNING: this function contains a call to a React hook.
Be careful where you use it. See rules of React hooks
TODO: not pass hook functions around like this*/
export const fetchSites = async (gqlHelper, setSites, includeGeocode) => {
    const sitesFromAppsync = await gqlHelper.getSites(includeGeocode);
    setSites(sitesFromAppsync);
};

/*WARNING: this function contains a call to a React hook.
Be careful where you use it. See rules of React hooks
TODO: not pass hook functions around like this
*/
export const fetchDevices = async (api, gqlHelper, setDevices, setDetailedCameras) => {
    return Promise.allSettled([
        fetchIdNameForAllPanels(api, gqlHelper),
        gqlHelper.getAccessPoints(),
        gqlHelper.getDetailedCameras(),
    ])
        .then((values) => {
            let devices = values?.filter((val) => val.status === 'fulfilled').flatMap((val) => val.value || []);
            if (setDevices) {
                devices = devices?.filter((accessPoint) => {
                    // exclude floors from Devices filter on Event Tracker page
                    return !isTypeFloor(accessPoint.type);
                });
                setDevices(devices);
            }
            if (setDetailedCameras) {
                setDetailedCameras(values[2].value);
            }
        })
        .catch((err) => {
            if (JSON.stringify(err).includes('401')) {
                return Promise.reject(JSON.stringify(err));
            }
            console.error(err);
        });
};

export const getSlicedArray = (array, chunkSize) => {
    var slicedArray = [];
    for (let index = 0; index < array.length; index += chunkSize) {
        let currentChunk = array.slice(index, index + chunkSize);
        slicedArray.push(currentChunk);
    }
    return slicedArray;
};

export const getUrlQueryParam = (key) => {
    const windowUrl = new URL(window.location);
    return windowUrl.searchParams.get(key);
};

/*WARNING: this function contains a call to a React hook.
Be careful where you use it. See rules of React hooks*/
export const fetchOccupancyCountInSlices = async (gqlHelper, setOccupancyCount, hours, siteIds) => {
    if (!siteIds || siteIds.length === 0) {
        return;
    }
    // split in slices of OCCUPANCY_SITE_BATCH_SIZE items
    let slicedSiteIdsArray = getSlicedArray(siteIds, OCCUPANCY_SITE_BATCH_SIZE);
    let tempArray = [];
    for (let i = 0; i < slicedSiteIdsArray.length; i++) {
        const occupancyCountFromAppsync = await gqlHelper.getOccupancyCount(hours, slicedSiteIdsArray[i]);
        tempArray = tempArray.concat(occupancyCountFromAppsync);
    }
    setOccupancyCount(tempArray);
};

export const fetchGraphQLDataPaginated = async (
    gqlHelper,
    query,
    queryName,
    pageSize = MAX_PAGE_SIZE_GRAPHQL,
    innerPropertyName,
    filters = [],
    offsetIn = 0
) => {
    let data = [];
    let offset = offsetIn;
    let keepFetching = true;

    while (keepFetching) {
        const queryParams = {
            query,
            fetchPolicy: 'no-cache',
            variables: {
                offset,
                pageSize,
                filters,
            },
        };
        const result = await gqlHelper.query(queryParams);

        const specificResult = innerPropertyName ? result.data[queryName][innerPropertyName] : result.data[queryName];

        if (!specificResult || !data || specificResult?.length === 0) {
            keepFetching = false;
        } else {
            data = [...data, ...specificResult];
            offset = offset + pageSize;

            if (specificResult.length < pageSize) {
                keepFetching = false;
            }
        }
    }

    return data;
};

const fetchIdNameForAllPanels = async (api, gqlHelper) => {
    return gqlHelper.getAllPanels();
};

export const isCheckpointDevice = (accessPointType) => {
    return (
        accessPointType === DOOR ||
        accessPointType === SALTO_DOOR_LOCK ||
        accessPointType === ALLEGION_DOOR_LOCK ||
        accessPointType === ASSA_ABLOY_DOOR_LOCK ||
        accessPointType === SIMONS_VOSS_LOCK
    );
};

export const isTypeSwitch = (accessPointType) => {
    return accessPointType === SWITCH;
};

export const isTypeValidCredential = (accessPointType) => {
    return accessPointType === VALID_CREDENTIAL;
};

export const isTypeFloor = (accessPointType) => {
    return accessPointType === FLOOR;
};

export const isTypeForDevicePrivileges = (accessPointType, showControlLocks = false) => {
    return (
        accessPointType === DOOR ||
        accessPointType === FLOOR ||
        accessPointType === SALTO_DOOR_LOCK ||
        accessPointType === ALLEGION_DOOR_LOCK ||
        accessPointType === ASSA_ABLOY_DOOR_LOCK ||
        accessPointType === SIMONS_VOSS_LOCK ||
        accessPointType === VALID_CREDENTIAL ||
        (showControlLocks ? accessPointType === OFFLINE_DOOR_LOCK : false)
    );
};

export const getNextEdition = (currentEdition) => {
    const editions = [EDITION_DEMO, EDITION_STANDARD, EDITION_PROFESSIONAL, EDITION_ENTERPRISE];

    const index = editions.indexOf(currentEdition);

    return index === editions.length - 1 || index === -1 ? null : editions[index + 1];
};

export const getFiltersFromLocalStorage = (pageName, userId, accountId) => {
    const filtersFromLocalStorage = localStorage.getItem(`filterValues_${pageName}_${userId}_${accountId}`);
    let savedFilters = null;
    try {
        savedFilters = JSON.parse(filtersFromLocalStorage);
    } catch (e) {
        console.error(e);
    }

    return savedFilters;
};

export const getCredentialIcon = (credentialType) => {
    switch (credentialType) {
        case CARD_CREDENTIAL_NAME:
            return <RecentActivityUsingCard />;
        case PIN_CREDENTIAL_NAME:
            return <RecentActivityUsingPin />;
        case BRIVO_MOBILE_PASS_CREDENTIAL_NAME:
            return <RecentActivityUsingMobilePass />;
        case LICENSE_PLATE_CREDENTIAL_NAME:
            return <RecentActivityUsingLicensePlate />;
        case FACIAL_ID_CREDENTIAL_NAME:
            return <RecentActivityUsingFaceprint />;
        default:
            return <RecentActivityUsingUnknown />;
    }
};

export const mapSchedules = (schedules, t) => {
    let mappedSchedules = cloneDeep(schedules);
    if (mappedSchedules) {
        mappedSchedules = mappedSchedules?.filter(
            (schedule) => schedule.name !== '24x7 Schedule' && schedule.name !== 'Mon-Fri 9-5'
        );
        mappedSchedules.forEach((element) => {
            element.originalName = element.name;
            if (!element.siteId) {
                if (element.name !== ALWAYS_ACCESS) {
                    element.name = t('Page.notifications.notification-details.schedule.universal', {
                        elementName: element.name,
                    });
                } else {
                    element.name = t('Page.notifications.notification-details.schedule.always-access');
                }
            }
        });
        mappedSchedules = mappedSchedules?.map((schedule) => ({
            id: schedule.id,
            name: schedule.name,
            siteId: schedule.siteId,
            siteName: schedule.siteName,
            originalName: schedule.originalName,
        }));
        return mappedSchedules;
    }
    return null;
};

export const overwriteHelpdocsStyles = () => {
    const intervalId = setInterval(() => {
        const lightHouseIframe = document.getElementById('Launcher');
        if (lightHouseIframe) {
            let dataRadiums = lightHouseIframe.contentDocument.querySelectorAll('[data-radium="true"]');
            dataRadiums = Array.from(dataRadiums);
            const divToChange = dataRadiums[1];
            if (divToChange) {
                divToChange.style.height = '48px';
                divToChange.style.width = '48px';
                divToChange.style.margin = null;
                divToChange.style.background = 'none';

                lightHouseIframe.style.zIndex = 0;

                lightHouseIframe.style.width = '48px';
                lightHouseIframe.style.height = '48px';
                lightHouseIframe.style.background = 'url(/Help-bubble.svg) center center / cover no-repeat';
                clearInterval(intervalId);
            }
        }
    }, 1);
};

export const updateOpenIconStyles = () => {
    const intervalId = setInterval(() => {
        const lightHouseIframe = document.getElementById('Launcher');
        if (lightHouseIframe) {
            let dataRadiums = lightHouseIframe.contentDocument.querySelectorAll('[data-radium="true"]');
            dataRadiums = Array.from(dataRadiums);
            const divToChange = dataRadiums[1];
            if (divToChange) {
                divToChange.style.animation = null;

                clearInterval(intervalId);
            }
        }
    }, 1);
};

export const updateCloseIconStyles = () => {
    const intervalId = setInterval(() => {
        const lightHouseIframe = document.getElementById('Launcher');
        if (lightHouseIframe) {
            let dataRadiums = lightHouseIframe.contentDocument.querySelectorAll('[data-radium="true"]');
            dataRadiums = Array.from(dataRadiums);
            const divToChange = dataRadiums[1];

            if (divToChange) {
                divToChange.style.background = 'none';

                clearInterval(intervalId);
            }
        }
    }, 1);
};

export function alphabetically(getCompareValue = (x) => x) {
    return (a, b) => (getCompareValue(a).toLowerCase().trim() > getCompareValue(b).toLowerCase().trim() ? 1 : -1);
}

export const viewCell = (context, onClick, isDisabled, label) => {
    const buttonClickHandler = () => onClick(context.row.original);
    return (
        <ViewButton
            buttonClickHandler={buttonClickHandler}
            disabled={isDisabled}
            label={label}
            id={context.row.original.id}
        />
    );
};

export const ViewButton = ({ buttonClickHandler, classes = '', disabled, label = 'View', id }) => {
    return (
        <div className={classes}>
            <Button
                size="small"
                type="tertiary"
                onClick={(event) => {
                    if (buttonClickHandler) {
                        event.stopPropagation();
                        buttonClickHandler();
                    }
                }}
                disabled={disabled}
                endIcon={<ChevronRight />}
                text={label}
                id={`${label}__${id}`}
            />
        </div>
    );
};

export function validateObject(obj, options = { truthyKeys: [] }) {
    if (typeof obj !== 'object') throw new TypeError(`Invalid object ${obj}`);
    options.truthyKeys.forEach((key) => {
        if (!obj[key]) throw new TypeError(`Invalid object, missing key "${key}"`);
    });
}

export const scrollToTop = () => {
    const elem = document.querySelector('#page-title');
    if (elem) {
        elem.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });
    }
};

export function getNameCell(context, onEnterWaypoint) {
    const {
        row: {
            index,
            original: { name },
        },
        rows,
    } = context;
    const rowsNum = rows.length;

    const formattedValue = (
        <span className="u-trim" title={`${name}`}>
            {name}
        </span>
    );

    if (rows[rowsNum - 1].index === index) {
        return (
            <>
                <Waypoint key="loadMore" onEnter={onEnterWaypoint} fireOnRapidScroll />
                {formattedValue}
            </>
        );
    }

    return formattedValue;
}

export const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
};

export const getAccessPointTypeFilter = (isOffline) => {
    return isOffline ? FILTER_OFFLINE_DOOR : FILTER_NOT_OFFLINE_DOOR;
};

export const getAccessPointNameFilter = (name) => {
    return `ACCESS_POINT_NAME__eq:${name}`;
};

export const isValidFirmwareVersion = (currentVersion, minimumVersion) => {
    if (!currentVersion) {
        return false;
    }
    if (!minimumVersion) {
        return true;
    }

    const [currentMajor, currentMinor, currentBuild = 0, currentRevision = 0] = currentVersion
        .split('.')
        .map((v) => parseInt(v, 10));
    const [major, minor, build = 0, revision = 0] = minimumVersion.split('.').map((v) => parseInt(v, 10));

    if (currentMajor > major) return true;
    if (currentMajor === major) {
        if (currentMinor > minor) return true;
        if (currentMinor === minor) {
            if (currentBuild > build) return true;
            if (currentBuild === build) {
                if (currentRevision >= revision) return true;
            }
        }
    }
    return false;
};
