import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme) => {
    const dropdownBackgroundItems = theme.palette.brivoAliases.inputBackground;
    const dropdownBackground = theme.palette.brivoAliases.dropdownBackground;
    const filterActiveBackground = theme.palette.brivoAliases.filterActiveBackground;
    const newInputBorderColor = theme.palette.brivoAliases.newInputBorderColor;
    const newInputBorderHoverColor = theme.palette.brivoAliases.newInputBorderHoverColor;
    const newInputBorderFocusColor = theme.palette.brivoAliases.newInputBorderFocusColor;
    const newInputIconActiveColor = theme.palette.brivoAliases.newInputIconActiveColor;
    const newDropdownBackground = theme.palette.brivoAliases.newDropdownBackground;
    const newLabelColor = theme.palette.brivoAliases.newLabelColor;
    const newLabelActiveColor = theme.palette.brivoAliases.newLabelActiveColor;

    return {
        root: {
            display: 'inline-flex',
            position: 'relative',
            cursor: 'pointer',
            '& .MuiAutocomplete-listbox ': {
                padding: '0',
            },
            scrollMarginTop: (props) => `${props.scrollMarginTop}px`,
        },
        maxWidth: {
            width: '35%',
            height: 'fit-content',
        },
        wrapper: {
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            height: 'fit-content',
            borderRadius: 4,
            border: `1px solid ${newInputBorderColor}`,
            '&:focus': {
                outline: 'none',
            },
            '&.has-value': {
                backgroundColor: filterActiveBackground,
                '& label': {
                    backgroundColor: 'unset',
                    borderRight: 'unset',
                    color: newLabelActiveColor,
                },
                '&:not(:focus-within):hover': {
                    borderColor:
                        theme.palette.type === 'light' ? theme.palette.colors.grey800 : theme.palette.colors.grey300,
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.type === 'light' ? theme.palette.colors.grey800 : theme.palette.colors.grey300,
                },
            },
            '&:not(.is-open):focus-within': {
                borderColor: newInputBorderFocusColor,
                boxShadow: '0 0 0 2px #739DD333',
                '& .MuiSvgIcon-root': {
                    color: newInputIconActiveColor,
                },
            },
            '&:hover:not(:focus-within)': {
                borderColor: newInputBorderHoverColor,
                '& label': {
                    borderColor: newInputBorderHoverColor,
                },
            },
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            fontSize: '.75rem !important',
            borderRight: `1px solid ${newInputBorderColor}`,
            transition:
                'background 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95), border-color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95), padding 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95)',
            color: newLabelColor,
            padding: '0 0.75rem',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            backgroundColor: newDropdownBackground,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
        },
        selectedItemsWrapper: {
            fontSize: '.75rem !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            minWidth: '9.37rem',
            color: theme.palette.brivoAliases.newInputPlaceholderColor,
            backgroundColor: `${dropdownBackgroundItems} !important`,
            borderRadius: '0 .25rem .25rem 0',
            width: '100%',
            fontWeight: 600,

            '& .MuiSvgIcon-root': {
                marginRight: '14.5px',
            },
        },
        chipWrapper: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            margin: 6,
            '& .MuiChip-root.MuiChip-deletable': {
                margin: 3,
            },
            minHeight: 26,
        },
        activeFilters: {
            height: '100%',
        },
        noSelectionLabel: {
            marginLeft: 12,
        },

        chipText: {
            fontSize: '0.68rem',
            fontWeight: 600,
            color: theme.palette.type === 'light' ? 'rgba(96, 113, 133, 1)' : 'rgba(255, 255, 255, 1)',
            minWidth: '50px',
        },
        fullWidth: {
            width: '100%',
        },
        disabledOverlay: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: dropdownBackground,
            top: 0,
            left: 0,
            opacity: '.3',
            cursor: 'not-allowed',
        },
    };
});

export default useStyles;
