import React, { useMemo } from 'react';
import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@brivo/react-components';
import * as _ from 'lodash';

import { ErrorAlert, SuccessAlert, SaveActionBar } from '@brivo/react-components';

import LockdownScenarioForm from './LockdownScenarioForm';

const LockdownScenarioDetailsContent = ({
    saveStatus,
    setSaveStatus,
    values,
    setFieldValue,
    accountHasDefaultScenario,
    dirty,
    isCreateMode,
    isSubmitting,
    submitForm,
    resetForm,
    setErrors,
    errors,
    touched,
    assignedTriggers,
    isActiveScenario,
}) => {
    const { t } = useTranslation();
    const actionBarProps = useMemo(() => {
        return isCreateMode
            ? {
                  primaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.create.primary-action'
                  ),
                  secondaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.create.secondary-action'
                  ),
                  primaryActionId: 'create-scenario-btn',
              }
            : {
                  text: t('Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.edit.text'),
                  primaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.edit.primary-action'
                  ),
                  secondaryActionText: t(
                      'Page.lockdown-scenarios.lockdown-scenario-details.bottom-bar.edit.secondary-action'
                  ),
                  primaryActionId: 'save-changes-btn',
              };
    }, [t, isCreateMode]);

    const handleReset = () => {
        resetForm();
        if (setSaveStatus) {
            setSaveStatus({
                showSuccess: false,
                showError: false,
                errorMsg: '',
            });
        }

        if (setErrors) {
            setErrors({});
        }
    };

    const onFormChange = (event) => {
        if (event.target.name === 'name') {
            if (event.target.value) {
                setErrors({ ...errors, nameInput: null });
            } else {
                setErrors({
                    ...errors,
                    nameInput: t('Field.validation.required'),
                });
            }
        }
    };

    return (
        <Grid item xs={12}>
            {saveStatus && saveStatus.showSuccess ? (
                <Grid item xs={12} className="u-mb-full">
                    <SuccessAlert
                        description={t('Page.lockdown-scenarios.lockdown-scenario-details.save-success.message')}
                    />
                </Grid>
            ) : null}
            {saveStatus && saveStatus.showError ? (
                <Grid item xs={12} className="u-mb-full">
                    <ErrorAlert errorMsg={saveStatus.errorMsg} />
                </Grid>
            ) : null}
            {!_.values(errors).every(_.isEmpty) && isSubmitting && (
                <Grid item xs={12} className="u-mb-full">
                    <ErrorAlert
                        errorMsg={t(
                            'Page.lockdown-scenarios.lockdown-scenario-details.save-error.general-message.explanation'
                        )}
                    />
                </Grid>
            )}
            <Form onChange={onFormChange}>
                <LockdownScenarioForm
                    values={values}
                    setFieldValue={setFieldValue}
                    accountHasDefaultScenario={accountHasDefaultScenario}
                    errors={errors}
                    touched={touched}
                    setErrors={setErrors}
                    assignedTriggers={assignedTriggers}
                    isCreateMode={isCreateMode}
                    isActiveScenario={isActiveScenario}
                />
                <SaveActionBar
                    show={dirty}
                    text={actionBarProps.text}
                    disablePrimary={isSubmitting}
                    primaryActionText={actionBarProps.primaryActionText}
                    primaryActionClickHandler={submitForm}
                    primaryActionId={actionBarProps.primaryActionId}
                    secondaryActionText={actionBarProps.secondaryActionText}
                    secondaryActionClickHandler={handleReset}
                    showPrimaryIcon={!isCreateMode}
                />
            </Form>
        </Grid>
    );
};

export default LockdownScenarioDetailsContent;
