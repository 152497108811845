import gql from 'graphql-tag';

export const GET_USERS_QUERY = gql`
    query Users(
        $pageSize: Int!
        $offset: Int!
        $sortField: String
        $direction: String
        $filters: [ListFilter]
        $withVisitors: Boolean
    ) {
        getAllUsers(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
            withVisitors: $withVisitors
        ) {
            count
            users {
                id
                userTypeId
                firstName
                middleName
                lastName
                customFields {
                    id
                    value
                    fieldName
                    fieldType
                }
                avatar
                suspended
                groupCount
                groups {
                    id
                    name
                    isAdminPermitted
                }
            }
        }
    }
`;

export const GET_ALL_USERS_WITH_FILTERING_QUERY = gql`
    query Users($pageSize: Int!, $offset: Int!, $sortField: String, $direction: String, $filters: [ListFilter]) {
        getAllUsersWithFiltering(
            limit: { rows: $pageSize, offset: $offset }
            sort: { field: $sortField, direction: $direction }
            filters: $filters
        ) {
            count
            totalCount
            users {
                id
                firstName
                middleName
                lastName
                customFields {
                    id
                    value
                    fieldName
                    fieldType
                }
                avatar
                suspended
                groupCount
                groups {
                    id
                    name
                    isAdminPermitted
                }
            }
        }
    }
`;

export const UPDATE_USER_IMAGE_MUTATION = gql`
    mutation updateUserImage($user: UserUploadInput!) {
        updateUserImage(user: $user) {
            id
        }
    }
`;
