import { useEffect, useState } from 'react';
import Banner from 'react-js-banner';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Button, Typography, CloseIcon, withTheme, ITheme } from '@brivo/react-components';

import { upsellApi } from '../../../webApis/upsell/upsellApi';
import { Campaign } from '../interfaces';

import BannerIconLightMode from './Icons/BannerIcon-LightMode';
import BannerIconDarkMode from './Icons/BannerIcon-DarkMode';
import useStyles from './styles';
import ContactUsModal from './ContactUsModal';
import { LEARN_MORE_LINK_URL } from './constant';

interface UpsellBannerProps {
    onDismissBanner: () => void;
    theme: ITheme;
    accountId: number;
    campaigns: Campaign[];
}

const UpsellBanner = ({ theme, accountId, campaigns, onDismissBanner }: UpsellBannerProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showContactUsModal, toggleContactUsModal] = useState(false);
    const [upsellBannerCampaign, setUpsellBannerCampaign] = useState<Campaign>();

    useEffect(() => {
        // Find first instance of banner campaign - theoretically there shouldn't be more than 1
        const currentBanner = campaigns?.find((campaign: Campaign) => campaign.campaign_type === 'banner');
        setUpsellBannerCampaign(currentBanner);
    }, [campaigns]);

    const handleLearnMoreClick = () => {
        upsellApi.learnMoreClick(accountId, upsellBannerCampaign?.campaign_id);
        window.open(LEARN_MORE_LINK_URL);
    };

    const handleCloseBannerClick = async () => {
        upsellApi.hideCampaign(accountId, upsellBannerCampaign?.campaign_id);
        onDismissBanner();
    };

    const handleContactUsClick = () => {
        toggleContactUsModal(true);
    };

    const bannerStyles = {
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'absolute',
        width: '100%',
        zIndex: 999,
        backgroundColor: theme.palette.type === 'dark' ? 'rgba(41, 57, 75, 0.95)' : 'rgba(216, 228, 244, 0.95)',
        borderBottom: ` 1px solid rgba(125, 166, 219, 0.95)`,
        marginBottom: 0,
        padding: '16px 40px',
    };

    return upsellBannerCampaign ? (
        <div className="UpsellBanner">
            <Banner css={bannerStyles}>
                {theme.palette.type === 'dark' ? (
                    <BannerIconLightMode className={classes.icon} />
                ) : (
                    <BannerIconDarkMode className={classes.icon} />
                )}
                <div>
                    <Typography className={`${classes.text} ${classes.boldedText}`}>
                        {t('InAppMessaging.UpsellBanner.easier-login')}
                    </Typography>
                </div>
                <Box className={classes.buttonsContainer}>
                    <Button
                        text={t('InAppMessaging.UpsellBanner.contact-us')}
                        type="primary"
                        onClick={handleContactUsClick}
                    />
                    <Box className={classes.marginRight}>
                        <Button
                            text={t('InAppMessaging.UpsellBanner.learn-more')}
                            type="tertiary"
                            onClick={handleLearnMoreClick}
                        />
                    </Box>
                    <IconButton id="close-btn" type="tertiary" onClick={handleCloseBannerClick} icon={<CloseIcon />} />
                </Box>
            </Banner>
            {showContactUsModal && (
                <ContactUsModal
                    campaign={upsellBannerCampaign}
                    onContactUsFormSubmit={onDismissBanner}
                    onDismissModal={() => toggleContactUsModal(false)}
                />
            )}
        </div>
    ) : null;
};

export default withTheme(UpsellBanner);
