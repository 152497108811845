import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.763 10.6425C5.40826 10.2813 5.41349 9.70091 5.77468 9.34617C6.13587 8.99143 6.71625 8.99665 7.07099 9.35784L10.0837 12.4253L10.0837 2.75016C10.0837 2.2439 10.4941 1.8335 11.0003 1.8335C11.5066 1.8335 11.917 2.2439 11.917 2.75016V12.4253L14.9297 9.35785C15.2844 8.99665 15.8648 8.99143 16.226 9.34617C16.5872 9.70091 16.5924 10.2813 16.2377 10.6425L11.6543 15.3091C11.482 15.4846 11.2463 15.5835 11.0003 15.5835C10.7543 15.5835 10.5187 15.4846 10.3463 15.3091L5.763 10.6425ZM4.58366 18.3335C4.0774 18.3335 3.66699 18.7439 3.66699 19.2502C3.66699 19.7564 4.0774 20.1668 4.58366 20.1668H17.417C17.9233 20.1668 18.3337 19.7564 18.3337 19.2502C18.3337 18.7439 17.9233 18.3335 17.417 18.3335H4.58366Z"
        />
    </React.Fragment>,
    'DownloadIcon'
);
