import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { AWS_APPSYNC_GRAPHQL_URL, AWS_APPSYNC_REGION } from '../constants/Constants';
import { auth as authSingleton } from '../utils/Auth';
import { EventCallbacks } from './EventCallbacks';

const QUERY_TIMEOUT = 10_000;

class GqlClient extends EventCallbacks {
    client = null;
    auth = null;
    ready = false;
    eventListeners = {
        ready: [],
    };

    constructor(auth) {
        super();

        this.auth = auth;

        if (auth.isReady()) {
            this.initialize();
        } else {
            this.auth.on('ready', () => {
                this.initialize();
            });
        }
    }

    initialize() {
        if (this.auth.useAltAuthToken()) {
            this.client = new AWSAppSyncClient({
                url: AWS_APPSYNC_GRAPHQL_URL,
                region: AWS_APPSYNC_REGION,
                auth: {
                    type: AUTH_TYPE.AWS_LAMBDA,
                    token: async () => this.auth.getAccessToken(),
                },
            });
        } else {
            this.client = new AWSAppSyncClient({
                url: AWS_APPSYNC_GRAPHQL_URL,
                region: AWS_APPSYNC_REGION,
                auth: {
                    type: AUTH_TYPE.OPENID_CONNECT,
                    jwtToken: async () => this.auth.getAccessToken(),
                },
                offlineConfig: {
                    // an incrementing prefix was used when there was a possibility of instantiating multiple clients
                    // TODO -> check if prefix is still required, since this is now a singleton
                    keyPrefix: `dashboard-1000`,
                },
                disableOffline: true,
                cacheOptions: {
                    addTypename: false,
                },
            });
        }
        this.ready = true;
        this.callListenersFor('ready');
    }

    query({ gqlTag, variables, options, defaultValue, throwWhenErrored }) {
        if (this.ready) {
            return this.executeQuery(...arguments);
        } else {
            return new Promise((resolve, reject) => {
                let isRejected = false;

                this.on('ready', () => {
                    if (isRejected) return;
                    resolve(this.executeQuery(...arguments));
                });

                setTimeout(() => {
                    isRejected = true;
                    reject(
                        `Gql query "${gqlTag.definitions[0].name.value}" timed out while waiting for GqlClient to initialize.`
                    );
                }, QUERY_TIMEOUT);
            });
        }
    }

    executeQuery = async ({ gqlTag, variables, options, defaultValue = null, throwWhenErrored = false }) => {
        try {
            const response = await this.client.query({
                query: gqlTag,
                variables,
                fetchPolicy: 'no-cache',
                ...options,
            });

            if (response.errors && throwWhenErrored) {
                throw new Error(response.errors);
            }

            if (!response || !response.data) {
                return defaultValue;
            }

            return response.data;
        } catch (e) {
            return this.handleExceptions(e);
        }
    };

    mutate({ gqlTag, variables, options, defaultValue, throwWhenErrored }) {
        if (this.ready) {
            return this.executeMutation(...arguments);
        } else {
            return new Promise((resolve, reject) => {
                this.on('ready', () => resolve(this.executeMutation(...arguments)));
            });
        }
    }

    executeMutation = async ({ gqlTag, variables, options, defaultValue = null, throwWhenErrored = false }) => {
        try {
            const response = await this.client.mutate({
                mutation: gqlTag,
                variables,
                fetchPolicy: 'no-cache',
                ...options,
            });

            if (!response || !response.data) {
                return defaultValue;
            }

            if (response.errors && throwWhenErrored) {
                throw new Error(response.errors);
            }

            return response.data;
        } catch (e) {
            return this.handleExceptions(e);
        }
    };

    handleExceptions = (e) => {
        // TODO -> only log when in a dev environment
        console.warn(e);

        if (this.requiresLogout(e)) {
            e.requiresLogout = true;
        }
        return Promise.reject(e);
    };

    requiresLogout = (e) =>
        this.auth.flagClient.variation('alternative-authentication', false) && JSON.stringify(e).includes('401');
}

export const gqlClient = new GqlClient(authSingleton);
