import React from 'react';
import useListData from '../UserManagement/shared/useListData';
import { getEmergencyScenarios, orderListBy } from './LockdowScenarioPage/helpers';
import AccessControl from '../../common/components/AccessControl';
import NoAccess from '../../common/components/NoAccess/NoAccess';
import { Route, Switch } from 'react-router';
import {
    APP_ADD_LOCKDOWN_SCENARIO_URL,
    APP_ADD_SCENARIO_URL,
    APP_LOCKDOWN_SCENARIO_DETAILS_URL,
    APP_LOCKDOWN_SCENARIOS_URL,
    APP_SCENARIO_DETAILS_URL,
    APP_SCENARIOS_URL,
} from '../../common/constants/Constants';
import { DEVICES_VIEW_DEVICES, LOCKDOWN_CREATE_LOCKDOWN_SCENARIO } from '../../common/permissions/Permissions';
import LockdownScenariosListing from './LockdownScenarioListing/LockdownScenariosListing';
import LockdownScenarioPage from './LockdowScenarioPage/LockdownScenarioPage';
import { useFlagClient } from '@brivo/onairplus-services';

const SCENARIOS_PAGE_SIZE = 20;

const LockdownScenariosContainer = ({ fetchIsLockdownActive, activeScenarios }) => {
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios', false);

    const [
        scenarios,
        count,
        loadNextPage,
        sortAsc,
        changeSortDirection,
        loading,
        reloadList,
        filters,
        handleFiltersChange,
        handleFiltersRestore,
        totalCount,
    ] = useListData(
        initialScenariosFiltersValue,
        getEmergencyScenarios,
        'type',
        buildScenariosGqlFilter,
        'lockdownScenarios',
        SCENARIOS_PAGE_SIZE,
        true
    );
    return (
        <AccessControl
            requireAssignments={{ allSites: true, allLockdowns: true }}
            requireFeatures={{ lockdownEnabled: true }}
            unauthorizedComponent={NoAccess}
            redirectToPage={true}
            requiredFlag={'emergency-scenarios'}
        >
            <Switch>
                <Route
                    exact
                    path={emergencyScenariosFlag ? APP_SCENARIOS_URL : APP_LOCKDOWN_SCENARIOS_URL}
                    render={() => (
                        <LockdownScenariosListing
                            scenarios={orderListBy(scenarios, 'name', 'asc')}
                            count={count}
                            isLoading={loading}
                            totalCount={totalCount}
                            loadNextPage={loadNextPage}
                            sortAsc={sortAsc}
                            changeSortDirection={changeSortDirection}
                            loadingScenarios={loading}
                            filters={filters}
                            handleFiltersChange={handleFiltersChange}
                            handleFiltersRestore={handleFiltersRestore}
                            showEgressScenarios={emergencyScenariosFlag}
                            fetchIsLockdownActive={fetchIsLockdownActive}
                            activeScenarios={activeScenarios}
                        />
                    )}
                />
                <Route
                    exact
                    path={emergencyScenariosFlag ? APP_ADD_SCENARIO_URL : APP_ADD_LOCKDOWN_SCENARIO_URL}
                    render={() => (
                        <AccessControl
                            necessaryPermissions={[LOCKDOWN_CREATE_LOCKDOWN_SCENARIO, DEVICES_VIEW_DEVICES]}
                            unauthorizedComponent={NoAccess}
                            redirectToPage={true}
                        >
                            <LockdownScenarioPage
                                scenarios={scenarios}
                                isLoading={loading}
                                reloadList={reloadList}
                                isCreateMode={true}
                            />
                        </AccessControl>
                    )}
                />
                <Route
                    exact
                    path={emergencyScenariosFlag ? APP_SCENARIO_DETAILS_URL : APP_LOCKDOWN_SCENARIO_DETAILS_URL}
                    render={() => (
                        <AccessControl
                            necessaryPermissions={[DEVICES_VIEW_DEVICES]}
                            unauthorizedComponent={NoAccess}
                            redirectToPage={true}
                        >
                            <LockdownScenarioPage
                                scenarios={scenarios}
                                isLoading={loading}
                                reloadList={reloadList}
                                isCreateMode={false}
                            />
                        </AccessControl>
                    )}
                />
            </Switch>
        </AccessControl>
    );
};

export default LockdownScenariosContainer;

export const initialScenariosFiltersValue = {
    type: 'hardware__eq:false',
};

export const buildScenariosGqlFilter = function (scenarioFilters) {
    let localFilters = [];
    localFilters.push({
        field: 'type',
        type: 'field',
        value: scenarioFilters.type,
    });
    return localFilters;
};
