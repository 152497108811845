import React, { Fragment, useState } from 'react';
import { get } from 'lodash';

import NestedItems from './NestedItems';
import NavItem from './NavItem';

const NavItems = ({ items, user, checkPermissions }) => {
    const [open, setOpened] = useState(false);
    const navItems = {
        main: [],
        features: [],
    };

    const hasVisibleChildren = (item) =>
        item.children?.filter((child) => {
            const necessaryPermissions = get(child, 'necessaryPermissions', []);
            const anyOfPermissions = get(child, 'anyOfPermissions', []);
            return (
                child.isVisible !== false &&
                checkPermissions({
                    userPermissions: user.permissions,
                    necessaryPermissions,
                    anyOfPermissions,
                })
            );
        }).length > 0;

    const wholeCategoryIsNew = (item) => item.children?.filter((child) => child.isNew).length === item.children.length;

    items.forEach((item, index) => {
        const category = get(item, 'category', 'features');
        if (Array.isArray(item.children)) {
            if (hasVisibleChildren(item)) {
                navItems[category].push(
                    <NestedItems
                        user={user}
                        key={`${index}-${item.id}`}
                        expanded={open === index}
                        item={item}
                        index={index}
                        setOpened={(expanded) => setOpened(expanded ? index : false)}
                        categoryIsNew={wholeCategoryIsNew(item)}
                    />
                );
            }
        } else if (item.url) {
            navItems[category].push(
                <NavItem
                    user={user}
                    key={`${index}-${item.id}`}
                    item={item}
                    index={index}
                    nested={false}
                    setOpened={setOpened}
                />
            );
        }
    });
    return (
        <Fragment>
            {navItems.main}
            {navItems.features}
        </Fragment>
    );
};

export default NavItems;
