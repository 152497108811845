import React, { useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SMART_HOME_URL, APP_BRIVO_SMARTHOME } from '../../common/constants/Constants';

const BrivoSmartHome = () => {
    const { pathname } = useLocation();
    const iframeEl = useRef(null);
    let willRedirect = useRef(true);
    let history = useHistory();

    useEffect(() => {
        //remove padding
        document.querySelector('#page_box').style.padding = null;

        return () => (document.querySelector('#page_box').style.padding = '2.5rem');
    }, []);

    useEffect(() => {
        if (willRedirect.current) {
            //pass along extention
            iframeEl.current.src = SMART_HOME_URL + pathname.split('/brivo-smarthome/')[1];
        } else {
            willRedirect.current = true;
        }
    }, [pathname]);

    useEffect(() => {
        const listener = function (e) {
            willRedirect.current = false;
            if (e.data === 'companies') {
                history.push(APP_BRIVO_SMARTHOME + '/companies');
            }
            if (e.data === 'properties') {
                history.push(APP_BRIVO_SMARTHOME + '/properties');
            }
            if (e.data === 'access') {
                history.push(APP_BRIVO_SMARTHOME + '/accesses');
            }
            if (e.data === 'logs') {
                history.push(APP_BRIVO_SMARTHOME + '/logs');
            }
            if (e.data === 'integration') {
                history.push(APP_BRIVO_SMARTHOME + '/integration');
            }
            if (e.data === 'installer') {
                history.push(APP_BRIVO_SMARTHOME + '/installer');
            }
            if (e.data === 'profile') {
                history.push(APP_BRIVO_SMARTHOME + '/profile');
            }
            if (e.data === 'support/troubleshoot') {
                history.push(APP_BRIVO_SMARTHOME + '/support/troubleshoot');
            }
        };
        window.addEventListener('message', listener);
        return () => window.removeEventListener('message', listener);
    }, [history]);

    return (
        <iframe
            id="smarthome-iframe"
            ref={iframeEl}
            title="Brivo Smarthome App"
            src={SMART_HOME_URL}
            width="100%"
            height="100%"
        />
    );
};

export default BrivoSmartHome;
