import gql from 'graphql-tag';

export const GET_SHARED_GROUPS_TO_OCCUPANT = gql`
    query getSharedGroupsToOccupant($accountId: Int!) {
        getSharedGroupsToOccupant(requestAccountId: $accountId) {
            groupObjectId
            groupName
        }
    }
`;

export const GET_SHARED_GROUPS_BY_MANAGER = gql`
    query getSharedGroupsByManager($accountId: Int!) {
        getSharedGroupsByManager(pmAccountId: $accountId) {
            groupId
            groupName
            groupObjectId
            groups
        }
    }
`;
export const GET_GROUPS_FOR_JOURNAL_PAGE = gql`
    query groups($name: String!, $rows: Int = 10) {
        getAllGroups(limit: { offset: 0, rows: $rows }, filters: [{ type: "field", field: "name", value: $name }]) {
            groups {
                name
                id
            }
        }
    }
`;
