import { Suspense } from 'react';
import { DashboardConfigurationContextProvider } from '../BrivoAnalytics/common/DashboardBuilderContext';
import { QueryEditorProvider } from './components/TableEditor/QueryEditorContext';
import PageHeader from '@/common/components/PageHeader/PageHeader';
import TableEditor from './components/TableEditor';
import { useTranslation } from 'react-i18next';
import { userActivityDimensions, userActivityQuery } from './mockData';

const NewReports = () => {
    const { t } = useTranslation();

    return (
        <Suspense fallback={null}>
            <DashboardConfigurationContextProvider>
                <QueryEditorProvider>
                    <PageHeader title={t('Page.new-reports.title')} />
                    <TableEditor query={userActivityQuery} dimensions={userActivityDimensions} />
                </QueryEditorProvider>
            </DashboardConfigurationContextProvider>
        </Suspense>
    );
};
export default NewReports;
