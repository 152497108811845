import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import application from './Reducers';
import { sitesMiddleware } from './middlewares/application/Sites.middleware';

window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || undefined;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appMiddleware = [sitesMiddleware];

const getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
        // pass the callback to delegate
        // to the invoked dialog
        return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
};

export const history = createBrowserHistory({ getUserConfirmation });

export const store = createStore(
    application(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), ...appMiddleware))
);
