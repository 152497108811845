import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            d="M14.4 0H1.6C.717 0 0 .715 0 1.6v4h1.6v-4h12.8v4H16v-4c0-.883-.717-1.6-1.6-1.6zM0 10.399H1.6V11.998999999999999H0zM14.4 10.399H16V11.998999999999999H14.4zM1.6 13.6H0v.8c0 .882.717 1.6 1.6 1.6h.8v-1.6h-.8v-.8zM14.4 14.4h-.8V16h.8c.883 0 1.6-.718 1.6-1.6v-.8h-1.6v.8zM4 14.399H5.6V15.998999999999999H4zM10.4 14.399H12V15.998999999999999H10.4zM7.2 14.399H8.8V15.998999999999999H7.2z"
            transform="translate(1.6 1.6)"
        />
        <path
            d="M8 10.068V7.199c0-.883.717-1.6 1.6-1.6V4C7.836 4 6.4 5.435 6.4 7.2v2.869L5.366 9.034l-1.132 1.13L7.2 13.13l2.966-2.965-1.132-1.131L8 10.068zM0 7.199H4V8.799H0zM10.4 7.199H16V8.799H10.4z"
            transform="translate(1.6 1.6)"
        />
    </React.Fragment>,
    'SwitchSides'
);
