import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ListItemText, ListItemIcon, AddItemIcon, StarIcon, Tooltip } from '@brivo/react-components';

import PageHeaderMenu from '../../../../common/components/PageHeaderMenu';
import TrimmedText from '../../../BrivoAnalytics/components/TrimmedText';

import useStyles from './styles';

const DropDownPageMenu = ({
    optionSelected,
    menuOptions,
    handleIconClick,
    buttonText,
    enableDividerButton = false,
    closeMenu,
    handleOptionClicked,
    starIconTooltipText = '',
}) => {
    const classes = useStyles();

    const handleSetOptionAsDefault = useCallback(
        (e, menuOption) => {
            e.stopPropagation();
            handleIconClick(menuOption);
        },
        [handleIconClick]
    );

    const favoriteIcon = useCallback(
        (option) => (
            <ListItemIcon
                className={clsx(classes.starIcon, option.isDefault ? classes.defaultStyle : null)}
                data-testid={`star-icon-${option?.id}`}
                onClick={(e) => handleSetOptionAsDefault(e, option)}
            >
                <StarIcon />
            </ListItemIcon>
        ),
        [classes.defaultStyle, classes.starIcon, handleSetOptionAsDefault]
    );

    const preppedMenuOptions = useMemo(() => {
        if (menuOptions) {
            return menuOptions.map((option) => {
                return {
                    ...option,
                    key: option.id,
                    onClick: () => {
                        handleOptionClicked(option);
                    },
                    body: () => (
                        <ListItemText>
                            <TrimmedText content={option?.name} noWrap />
                        </ListItemText>
                    ),
                    icon: () =>
                        !option.isDefault ? (
                            <Tooltip title={starIconTooltipText} size="small" placement="top-start">
                                {favoriteIcon(option)}
                            </Tooltip>
                        ) : (
                            favoriteIcon(option)
                        ),
                };
            });
        }
    }, [menuOptions, handleOptionClicked, starIconTooltipText, favoriteIcon]);

    return (
        <PageHeaderMenu
            menuOptions={preppedMenuOptions}
            selectedOption={{ ...optionSelected, key: optionSelected?.id }}
            closeOnItemClick={true}
            menuItemStyles={classes.menuItems}
            footer={
                enableDividerButton
                    ? {
                          startIcon: <AddItemIcon color="primary" />,
                          text: buttonText,
                          onClick: () => closeMenu(true),
                          additionalStyles: classes.createViewIcon,
                          id: 'dropDownPageMenuFooterButton',
                      }
                    : null
            }
            placement="bottom-end"
        />
    );
};

DropDownPageMenu.propTypes = {
    menuOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleIconClick: PropTypes.func,
    buttonText: PropTypes.string.isRequired,
    enableDividerButton: PropTypes.bool,
    closeMenu: PropTypes.func.isRequired,
    handleOptionClicked: PropTypes.func.isRequired,
    optionSelected: PropTypes.object,
};

export default DropDownPageMenu;
