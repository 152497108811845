import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { cloneDeep } from 'lodash';
import { SEVEN_DAYS_IN_MS } from './constants';
import { roverApi } from '../../../../common/webApis/rover/roverApi';
import { cancellablePromise } from '../../../../common/utils/promiseUtils';
import useDashboardData from '../../useDashboardData';

import { UserContext } from '../../../../common/user/UserProvider';

/*
    The context state will be an object keyed by uuid which would point to loaded dashboard configurations.
    The object will contain a full definition of a dashboard. Which is a collection of visuals placed on a grid.
*/

// {
//     "uuid": {
//         name: "Dashboard name",
//         visuals: [
//             {
//                 x: integer,
//                 y: integer,
//                 width: integer,
//                 height: integer,
//                 type: "table | pivotTable | barChart | etc",
//                 dimensions: [
//                     {
//                         id: "uuid",
//                         displayNameKey: "string",
//                         displayName: "string",
//                         dataDefinition: {
//                             dataType: "NUMBER, STRING, TIMESTAMP, DATE, TIME"
//                         }
//                     },
//                     ...
//                 ],
//                 query: {
//                     ...
//                 }
//             },
//             ...
//         ]
//     }
// }

/*
Pivot table Example
Say we are producing an access report for daily user counts for sites.
Dates for rows, Sites for columns and for emtric we count unique users who visited a site.

Data will look like:
[
    {
        "date": blah,
        "site": blah-blah,
        "unque user count": 10000,
        "total visits count": 20000
    },
    .......
]

Pivot descriptions will be:
{
    column_groups: [site]
    row_groups: [date]
    metrics: ["unique user count", "total visits count"]
}
*/

// {
//     "uuid": {
//         name: "Dashboard name",
//         visuals: [
//             {
//                 x: integer,
//                 y: integer,
//                 width: integer,
//                 height: integer,
//                 type: "pivotTable",
//                 dimensions: [
//                     {
//                         id: "uuid",
//                         displayName: "Sites",
//                         level: 0, <-- the hierarchy of groupings, just integers in order
//                         designation: "column",
//                         dataDefinition: {
//                             dataType: "NUMBER, STRING, TIMESTAMP, DATE, TIME"
//                         }
//                     },
//                     {
//                         id: "uuid",
//                         displayName: "Date",
//                         level: 0,
//                         designation: "row",
//                         dataDefinition: {
//                             dataType: "NUMBER, STRING, TIMESTAMP, DATE, TIME"
//                         }
//                     },
//                     {
//                         id: "uuid",
//                         displayName: "User Count",
//                         level: 0,
//                         designation: "metric",
//                         dataDefinition: {
//                             dataType: "NUMBER, STRING, TIMESTAMP, DATE, TIME"
//                         }
//                     },
//                     ...
//                 ],
//                 query: {
//                     ...
//                 }
//             },
//             ...
//         ]
//     }
// }

const queryExecutor = (object) => {
    Object.keys(object).forEach((prop) => {
        if (prop === 'query') {
            if (typeof object.query === 'function') {
                object.query = object.query(object.queryParams);
                if (object.queryParams !== undefined) {
                    delete object.queryParams;
                }
            }
        } else if (object[prop] && Array.isArray(object[prop])) {
            object[prop].forEach((item) => queryExecutor(item));
        } else if (object[prop] && typeof object[prop] === 'object') {
            queryExecutor(object[prop]);
        }
    });
};

class DashboardConfigurationObject {
    constructor(
        dbConfiguration,
        setConfigurationState,
        userDetails,
        defaultDashboard,
        globalFilters,
        setGlobalFilters
    ) {
        this.configurationState = dbConfiguration;
        this.setConfigurationState = setConfigurationState;
        this.currentDashboardId = null;
        this.userDetails = userDetails;
        this.defaultView = defaultDashboard;
        this.globalFiltersState = globalFilters;
        this.setGlobalFilters = setGlobalFilters;
    }

    setStateReferences = (
        dbConfiguration,
        setConfigurationState,
        defaultDashboard,
        globalFilters,
        setGlobalFilters
    ) => {
        this.configurationState = dbConfiguration;
        this.setConfigurationState = setConfigurationState;
        this.defaultView = defaultDashboard;
        this.globalFiltersState = globalFilters;
        this.setGlobalFilters = setGlobalFilters;
    };

    retrieveUUIDUnderAlias = (alias) => {
        if (typeof alias === 'string') {
            return localStorage.getItem(`ROVER_Saved_Dashboard-${alias}`);
        }
        return null;
    };

    persistUUIDUnderAlias = (alias, dashbaord_uuid) => {
        if (typeof alias === 'string' && typeof dashbaord_uuid === 'string') {
            localStorage.setItem(`ROVER_Saved_Dashboard-${alias}`, dashbaord_uuid);
        }
    };

    getCurrentDashboardId = () => {
        return this.currentDashboardId;
    };

    isStoredDashboardViable = (dashboardConfig) => {
        if (this.userDetails) {
            const { id, accountId } = this.userDetails;

            return dashboardConfig?.userDetails?.id === id && dashboardConfig?.userDetails?.accountId === accountId;
        }
        return false;
    };

    removeDashboardConfigurationById = (key) => {
        localStorage.removeItem(key);
        this.setConfigurationState((currentConfigurationState) => {
            const newConfiguration = cloneDeep(currentConfigurationState);
            //eslint-disable-next-line
            if (newConfiguration.hasOwnProperty(key)) {
                // Modify existing dashboard configuration
                delete newConfiguration[key];
            }
            return newConfiguration;
        });
    };

    getDashboardsConfigurations = (id) => {
        if (id) {
            const dashboardConfig = JSON.parse(localStorage.getItem(id));

            if (this.isStoredDashboardViable(dashboardConfig)) {
                if (dashboardConfig?.cache_expiration >= Date.now()) {
                    this.currentDashboardId = id;

                    return {
                        configuration: dashboardConfig.configuration,
                        hasUnsavedChanges: dashboardConfig.hasUnsavedChanges?.filter(Boolean),
                        isDashboard: dashboardConfig.isDashboard,
                        hasError: dashboardConfig.hasError,
                        pageHeader: dashboardConfig.pageHeader,
                    };
                }
            }

            this.currentDashboardId = id;
            const config = this.configurationState?.find((config) => config.key === id);
            return { configuration: cloneDeep(config), hasUnsavedChanges: [], isDashboard: true, hasError: false };
        }
        this.currentDashboardId = this.defaultView;
        const config = this.configurationState?.find((config) => config.key === this.defaultView);

        return {
            configuration: cloneDeep(config),
            hasUnsavedChanges: config?.hasUnsavedChanges,
            pageHeader: config?.pageHeader,
            isDashboard: true,
            hasError: false,
        };
    };

    getGlobalFilters = (id) => {
        const dashboardId = id ? id : this.currentDashboardId;
        if (dashboardId) {
            if (this.globalFiltersState) {
                const { userId, accountId } = this.userDetails;
                return cloneDeep(this.globalFiltersState?.[accountId]?.[userId]?.[dashboardId]);
            }
        }
        return null;
    };

    addOrModifyGlobalFilter = (id, filters) => {
        if (id && filters) {
            const { userId, accountId } = this.userDetails;
            this.setGlobalFilters((currentFilters) => {
                const newFilters = {
                    ...currentFilters,
                    [accountId]: {
                        ...currentFilters?.[accountId],
                        [userId]: {
                            ...currentFilters?.[accountId]?.[userId],
                            [id]: filters,
                        },
                    },
                };
                localStorage.setItem('brivoAnalyticsGlobalFilters', JSON.stringify(newFilters));
                return newFilters;
            });
        }
    };

    addOrModifyOpenStateForTables = (id, isOpen, idx) => {
        if (id) {
            const { userId, accountId } = this.userDetails;
            const currentTablesState = JSON.parse(localStorage.getItem('brivoAnalyticsTablesOpenState'));
            const newTablesState = {
                ...currentTablesState,
                [accountId]: {
                    ...currentTablesState?.[accountId],
                    [userId]: {
                        ...currentTablesState?.[accountId]?.[userId],
                        [id]: {
                            ...currentTablesState?.[accountId]?.[userId]?.[id],
                            [idx]: isOpen,
                        },
                    },
                },
            };
            localStorage.setItem('brivoAnalyticsTablesOpenState', JSON.stringify(newTablesState));
        }
    };

    getTableOpenState = (id, idx) => {
        const { userId, accountId } = this.userDetails;
        const currentTablesState = JSON.parse(localStorage.getItem('brivoAnalyticsTablesOpenState'));
        if (currentTablesState) {
            if (!currentTablesState[accountId]?.[userId]?.[id]) {
                this.addOrModifyOpenStateForTables(id, true, 0);
                return idx === 0;
            }
            return currentTablesState[accountId]?.[userId]?.[id]?.[idx] || false;
        }
        this.addOrModifyOpenStateForTables(id, true, 0);
        return idx === 0;
    };

    deepSearchForDatasource = (object, sourceName) => {
        return Object.keys(object).some((prop) => {
            if (prop === 'source') {
                if (object.source === sourceName && object.id) {
                    return true;
                }
            } else if (object[prop] && Array.isArray(object[prop])) {
                return object[prop].some((item) => this.deepSearchForDatasource(item, sourceName));
            } else if (object[prop] && typeof object[prop] === 'object') {
                return this.deepSearchForDatasource(object[prop], sourceName);
            }
            return false;
        });
    };

    updateQuerySitesFilters = (query, sites) => {
        if (Array.isArray(query)) {
            query.forEach((queryItem) => this.updateQuerySitesFilters(queryItem, sites));
            return;
        }
        const updater = (query, sites, sourceName, filterSourceName, filterFieldName) => {
            if (query && this.deepSearchForDatasource(query, sourceName)) {
                const currentFilters = query.filters ?? [];
                currentFilters.push({
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: filterSourceName,
                        id: filterFieldName,
                    },
                    test_values: sites.map((site) => ({
                        source: 'constant',
                        type: 'integer',
                        value: site.id,
                    })),
                });
                query.filters = currentFilters;
            }
        };

        const sourcesToBeUpdated = [
            {
                sourceName: 'sites',
                filterSourceName: 'sites',
                filterFieldName: 'site_object_id',
            },
            {
                sourceName: 'events',
                filterSourceName: 'events',
                filterFieldName: 'site_object_id',
            },
            {
                sourceName: 'devices',
                filterSourceName: 'sites',
                filterFieldName: 'site_object_id',
            },
            {
                sourceName: 'site_address',
                filterSourceName: 'site_address',
                filterFieldName: 'site_object_id',
            },
        ];

        for (const { sourceName, filterSourceName, filterFieldName } of sourcesToBeUpdated) {
            updater(query, sites, sourceName, filterSourceName, filterFieldName);
        }
    };

    updateQueryObject = (query) => {
        const globalFilters = this.getGlobalFilters();
        if (query && globalFilters?.sites?.length > 0) {
            const updatedQuery = cloneDeep(query);
            if (updatedQuery.datasets?.length > 0) {
                updatedQuery.datasets.forEach((dataset) =>
                    this.updateQuerySitesFilters(dataset.source?.query, globalFilters.sites)
                );
            }
            this.updateQuerySitesFilters(updatedQuery.query, globalFilters.sites);
            return updatedQuery;
        }
        return cloneDeep(query);
    };

    roverApiProxy = {
        getData: (query) => {
            const updatedQuery = this.updateQueryObject(query);
            return cancellablePromise(roverApi.getData(updatedQuery));
        },
        getData_V2: (query) => {
            const updatedQuery = this.updateQueryObject(query);
            return cancellablePromise(roverApi.getData_V2(updatedQuery));
        },
        getMetadata_V2: (query) => {
            const updatedQuery = this.updateQueryObject(query);
            return cancellablePromise(roverApi.getMetadata_V2(updatedQuery));
        },
        getSites: () => {
            return roverApi.getSites();
        },
        getReport: (query) => {
            const updatedQuery = this.updateQueryObject(query);
            return cancellablePromise(roverApi.getReport(updatedQuery));
        },
        getReport_V2: (query) => {
            const updatedQuery = this.updateQueryObject(query);
            return cancellablePromise(roverApi.getReport_V2(updatedQuery));
        },
        getColumns: () => {
            return roverApi.getColumns();
        },
        getDataAsync_V2: (query, rowIndexStart, rowIndexEnd) => {
            const updatedQuery = this.updateQueryObject(query);
            return roverApi.getDataAsync_V2(updatedQuery, rowIndexStart, rowIndexEnd);
        },
        fetchMoreDataAsync_V2: (queryList) => {
            return roverApi.fetchMoreDataAsync_V2(queryList);
        },
        getReportAsync_V2: (query) => {
            const updatedQuery = this.updateQueryObject(query);
            return roverApi.getReportAsync_V2(updatedQuery);
        },
        getUniqueValuesAsync_V2: (query, rowIndexStart, rowIndexEnd) => {
            const updatedQuery = this.updateQueryObject(query);
            return roverApi.getUniqueValuesAsync_V2(updatedQuery, rowIndexStart, rowIndexEnd);
        },
        getUniqueValuesResultsAsync_V2: (query, initialResponse, rowIndexStart, rowIndexEnd) => {
            const updatedQuery = this.updateQueryObject(query);
            return roverApi.getUniqueValuesResultsAsync_V2(updatedQuery, initialResponse, rowIndexStart, rowIndexEnd);
        },
        getUniqueValuesResultsDebounceAsync_V2: (query, initialResponse, rowIndexStart, rowIndexEnd) => {
            const updatedQuery = this.updateQueryObject(query);
            return roverApi.getUniqueValuesResultsAsyncDebounce_V2(
                updatedQuery,
                initialResponse,
                rowIndexStart,
                rowIndexEnd
            );
        },
        getUniqueValuesDebounceAsync_V2: (query, rowIndexStart, rowIndexEnd) => {
            const updatedQuery = this.updateQueryObject(query);
            return roverApi.getUniqueValuesAsyncDebounce_V2(updatedQuery, rowIndexStart, rowIndexEnd);
        },
    };

    modifyStorageProperty = (id, property, value) => {
        if (id) {
            const config = JSON.parse(localStorage.getItem(id));
            if (config) {
                config[property] = value;

                localStorage.setItem(id, JSON.stringify(config));
                this.setConfigurationState((currentConfigurationState) => {
                    const newConfiguration = cloneDeep(currentConfigurationState);
                    //eslint-disable-next-line
                    if (newConfiguration.hasOwnProperty(id)) {
                        // Modify existing dashboard configuration
                        Object.assign(newConfiguration[id], config);
                    } else {
                        Object.assign(newConfiguration, Object.fromEntries([[id, config]]));
                    }
                    return newConfiguration;
                });
            }
        }
    };

    addOrModifyConfiguration = (
        id,
        configuration,
        pageHeader,
        sectionName,
        isDashboard,
        hasError,
        expiration = Date.now() + SEVEN_DAYS_IN_MS
    ) => {
        const keysToRemove = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            try {
                const dashboardConfig = JSON.parse(localStorage.getItem(key));
                if (dashboardConfig?.cache_expiration && dashboardConfig?.cache_expiration < Date.now())
                    keysToRemove.push(key);
            } catch (error) {
                // Ignore Error
            }
        }
        keysToRemove.forEach((key) => localStorage.removeItem(key));

        if (id && configuration) {
            let dashboardConfig = JSON.parse(localStorage.getItem(id));
            if (dashboardConfig?.cache_expiration >= Date.now()) {
                Object.assign(dashboardConfig.configuration, configuration);
                dashboardConfig.cache_expiration = expiration;
                dashboardConfig.userDetails = this.userDetails;
                dashboardConfig.hasUnsavedChanges = [...dashboardConfig.hasUnsavedChanges, sectionName].filter(Boolean);
                dashboardConfig.isDashboard = isDashboard;
                dashboardConfig.hasError = hasError;
                dashboardConfig.pageHeader = pageHeader;
            } else {
                dashboardConfig = {
                    cache_expiration: expiration,
                    configuration: configuration,
                    userDetails: this.userDetails,
                    hasUnsavedChanges: [sectionName].filter(Boolean),
                    hasError,
                    isDashboard,
                    pageHeader,
                };
            }
            // Queries that are pushed in as executables should be executed and saved in frozen in time state.
            queryExecutor(dashboardConfig.configuration);
            localStorage.setItem(id, JSON.stringify(dashboardConfig));
            this.setConfigurationState((currentConfigurationState) => {
                const newConfiguration = cloneDeep(currentConfigurationState);
                //eslint-disable-next-line
                if (newConfiguration.hasOwnProperty(id)) {
                    // Modify existing dashboard configuration
                    Object.assign(newConfiguration[id], configuration);
                } else {
                    Object.assign(newConfiguration, Object.fromEntries([[id, configuration]]));
                }
                return newConfiguration;
            });
        }
    };

    bulkAddOrModifyConfiguration = (configurations, expiration = Date.now() + SEVEN_DAYS_IN_MS) => {
        const configurationStateToPush = {};
        for (const dashboardId in configurations) {
            const id = dashboardId;
            const configuration = configurations[id];
            if (id && configuration) {
                let dashboardConfig = JSON.parse(localStorage.getItem(id));
                if (dashboardConfig?.cache_expiration >= Date.now()) {
                    Object.assign(dashboardConfig.configuration, configuration);
                    dashboardConfig.cache_expiration = expiration;
                    dashboardConfig.userDetails = this.userDetails;
                } else {
                    dashboardConfig = {
                        cache_expiration: expiration,
                        configuration: configuration,
                        userDetails: this.userDetails,
                    };
                }
                // Queries that are pushed in as executables should be executed and saved in frozen in time state.
                queryExecutor(dashboardConfig.configuration);
                localStorage.setItem(id, JSON.stringify(dashboardConfig));
                Object.assign(configurationStateToPush, { dashboardId: configuration });
            }
        }
        this.setConfigurationState((currentConfigurationState) => {
            const newConfiguration = cloneDeep(currentConfigurationState);
            for (const dashboardId in configurationStateToPush) {
                const configuration = configurationStateToPush[dashboardId];
                //eslint-disable-next-line
                if (newConfiguration.hasOwnProperty(dashboardId)) {
                    // Modify existing dashboard configuration
                    Object.assign(newConfiguration[dashboardId], configuration);
                } else {
                    Object.assign(newConfiguration, Object.fromEntries([[dashboardId, configuration]]));
                }
            }
            return newConfiguration;
        });
    };
    getDashboardViews = () => {
        const options = [];
        let defaultFromDb;

        this.configurationState?.forEach((data) => {
            options.push({ name: data.name, key: data.key, isDefault: data.isDefault, sharingType: data.sharingType });
            if (data.isDefault) {
                defaultFromDb = data.key;
            }
        });
        if (this.defaultView) {
            options.forEach((option) => (option.isDefault = option.key === this.defaultView));
        } else {
            this.defaultView = defaultFromDb;
        }
        return options;
    };

    setDashboardAsDefault = (id) => {
        this.defaultView = id;
    };
}

let defaultDasboardConfigurations = {};

const defaultSetConfigurationState = (value) => {
    if (typeof value === 'function') {
        defaultDasboardConfigurations = value(defaultDasboardConfigurations);
    } else {
        defaultDasboardConfigurations = value;
    }
};

// This context should not be used in its default configuration. Use the provider defined further down instead.
// We include this default implementation so that we a have at least some parts of it functioning that may be useful during debugging
// Since this default implementation does not use React state, do not expect your components automatically updating when context is changed.
const DashboardConfigurationContext = createContext(
    new DashboardConfigurationObject(defaultDasboardConfigurations, defaultSetConfigurationState, {})
);

// Use this provider component to wrap the top level report configuration
const DashboardConfigurationContextProvider = ({ children }) => {
    const userContext = useContext(UserContext);
    const [configurations, getDashboardData] = useDashboardData();
    const [dbConfiguration, setConfigurationState] = useState(configurations);
    const [globalFilters, setGlobalFilters] = useState(JSON.parse(localStorage.getItem('brivoAnalyticsGlobalFilters')));
    const [loading, setIsLoading] = useState(false);
    const [defaultDashboard, setDefaultDashboard] = useState(null);

    const userDetails = useMemo(
        () => ({
            userId: userContext?.userInfo.id,
            accountId: userContext?.userInfo.accountId,
        }),
        [userContext?.userInfo]
    );

    useEffect(() => {
        const getDashboards = async () => {
            const [response] = await getDashboardData();
            const foundDefaultOption = response.find((option) => option.isDefault === true);
            setDefaultDashboard(foundDefaultOption?.key);
            setConfigurationState(response);
            setIsLoading(false);
        };

        if (!dbConfiguration) {
            setIsLoading(true);
            getDashboards();
        }
    }, [setConfigurationState, dbConfiguration, getDashboardData]);

    const reloadDashboards = async () => {
        setIsLoading(true);
        const [response] = await getDashboardData();
        const foundDefaultOption = response.find((option) => option.isDefault === true);
        setDefaultDashboard(foundDefaultOption?.key);
        setConfigurationState(response);
        setIsLoading(false);
    };

    const [sharedConfigurationObjectReference] = useState(
        new DashboardConfigurationObject(
            dbConfiguration,
            setConfigurationState,
            userDetails,
            globalFilters,
            setGlobalFilters
        )
    );

    const sharedConfigurationObject = useMemo(() => {
        sharedConfigurationObjectReference.setStateReferences(
            dbConfiguration,
            setConfigurationState,
            defaultDashboard,
            globalFilters,
            setGlobalFilters
        );
        return sharedConfigurationObjectReference;
    }, [
        dbConfiguration,
        setConfigurationState,
        globalFilters,
        setGlobalFilters,
        sharedConfigurationObjectReference,
        defaultDashboard,
    ]);

    return (
        <DashboardConfigurationContext.Provider
            value={[sharedConfigurationObject, dbConfiguration, reloadDashboards, loading]}
        >
            {children}
        </DashboardConfigurationContext.Provider>
    );
};

export const DashboardDBConfigContext = React.createContext();

export { DashboardConfigurationContext, DashboardConfigurationContextProvider };
