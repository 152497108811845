import createSvgIcon from '../../../common/utils/createSvgIcon';

const FilterIcon = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1998 8.16587C19.2001 8.69945 18.7313 9.13221 18.1527 9.13246L5.84788 9.1379C5.26932 9.13815 4.80008 8.70581 4.7998 8.17223C4.79953 7.63865 5.26832 7.20589 5.84688 7.20563L18.1517 7.2002C18.7303 7.19994 19.1995 7.63229 19.1998 8.16587ZM16.8737 11.9967C16.8742 12.5303 16.4055 12.9632 15.827 12.9636L8.17425 12.969C7.59569 12.9695 7.12631 12.5372 7.12587 12.0037C7.12542 11.4701 7.59407 11.0372 8.17263 11.0368L15.8254 11.0313C16.4039 11.0309 16.8733 11.4632 16.8737 11.9967ZM13.7723 15.8247C13.7747 16.3583 13.3076 16.7926 12.729 16.7948L11.2791 16.8002C10.7006 16.8024 10.2296 16.3716 10.2273 15.838C10.2249 15.3044 10.692 14.8701 11.2706 14.8679L12.7205 14.8625C13.2991 14.8603 13.77 15.2911 13.7723 15.8247Z"
    />,
    'FilterIcon'
);

export default FilterIcon;
