import React, { useContext, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import {
    ONAIR_CLASSIC_ENTERPRISE_SSO_BASE_URL,
    ONAIR_CLASSIC_SSO_DEFAULT_LOGIN_URL,
    ONE_MINUTE,
    ONAIR_CLASSIC_ALT_LOGIN,
} from '../../common/constants/Constants';
import { auth } from '../../common/utils/Auth';
import { useFlagClient } from '@brivo/onairplus-services';

export const OnairSsoContext = React.createContext(null);

export const useOnairSso = () => useContext(OnairSsoContext);

const iframeStyle = {
    overflow: 'hidden',
    height: '0%',
    width: '0%',
    position: 'absolute',
};

export const resetIframeSrc = () => {
    const iframe = document.getElementById('onairSSOFrame');
    if (iframe) {
        const { src } = iframe;
        iframe.src = src;
    }
};

export const OnairSSO = React.memo(({ children, showSsoIframe, resetIframeSrcFn = resetIframeSrc }) => {
    const flagClient = useFlagClient();
    const ssoEnabled = showSsoIframe && flagClient.variation('onair-sso', false);
    const storedSsoKey = localStorage.getItem('enterpriseConnectionKey') || '';

    const isAltAuthOn = auth.isAltAuthOn();
    const useIframe = (ssoEnabled || isAltAuthOn) && !storedSsoKey;
    const [ssoLoading, setSsoLoading] = useState(useIframe);
    const [ssoLoggedIn, setSsoLoggedIn] = useState(useIframe);
    const OnairLoginSrc = makeOnairLoginUrl(isAltAuthOn, auth, storedSsoKey);

    const iframe = useMemo(
        () =>
            useIframe ? (
                <iframe
                    id="onairSSOFrame"
                    title={'Onair Login'}
                    src={OnairLoginSrc}
                    onLoad={() => {
                        setSsoLoggedIn(true);
                        setSsoLoading(false);
                    }}
                    style={iframeStyle}
                />
            ) : null,
        [OnairLoginSrc, useIframe, setSsoLoggedIn]
    );

    useEffect(() => {
        if (useIframe && !ssoLoading) {
            let intervalId;
            intervalId = setInterval(resetIframeSrcFn, 10 * ONE_MINUTE);

            return () => intervalId && clearInterval(intervalId);
        }
    }, [useIframe, resetIframeSrcFn, ssoLoading]);

    return (
        <OnairSsoContext.Provider
            value={{
                ssoLoading,
                ssoLoggedIn,
                OnairLoginSrc,
                setLoggedIn: (loginState) => setSsoLoggedIn(loginState),
            }}
        >
            {iframe}
            {children}
        </OnairSsoContext.Provider>
    );
});

const mapStateToProps = ({ accessToken }) => ({
    accessToken,
});

export default connect(mapStateToProps)(OnairSSO);

export function makeOnairLoginUrl(isAltAuthOn, auth, storedSsoKey) {
    let OnairLoginSrc = '';
    const remoteCode = auth.getRemoteCode();
    if (isAltAuthOn) {
        OnairLoginSrc = ONAIR_CLASSIC_ALT_LOGIN + '?code=' + auth.getAltToken();
    } else {
        const ssoUrl = storedSsoKey
            ? `${ONAIR_CLASSIC_ENTERPRISE_SSO_BASE_URL}/${storedSsoKey}`
            : ONAIR_CLASSIC_SSO_DEFAULT_LOGIN_URL;
        if (remoteCode) {
            OnairLoginSrc = ssoUrl + '?remote_access=' + remoteCode;
        } else {
            OnairLoginSrc = ssoUrl + '?accountId=' + (auth.getAccountId() || '');
        }
    }
    return OnairLoginSrc;
}
