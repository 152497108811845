import { ITheme, makeStyles } from '@brivo/react-components';

const useStyles = makeStyles((theme: ITheme) => {
    return {
        gradient: {
            width: 'calc(100% + 2.5rem)',
            height: 'calc(100% + 2.5rem)',
            position: 'absolute',
            padding: '2.5rem',
            left: '-2.5rem',
            top: '-2.5rem',
            background:
                theme.palette.type === 'dark'
                    ? `linear-gradient(0deg, ${theme.palette.colors.grey1200} 35.06%, rgba(16, 23, 31, 0.00) 100%)`
                    : `linear-gradient(0deg, ${theme.palette.colors.grey0} 35.06%, rgba(16, 23, 31, 0.00) 100%)`,
        },
        container: {
            position: 'absolute',
            width: '100%',
            bottom: '0',
            paddingBottom: '6rem',
        },
        upgradeText: {
            fontSize: '18px',
            fontWeight: '600',
            '& a': {
                color: theme.palette.type === 'dark' ? theme.palette.colors.blue400 : theme.palette.colors.blue700,
                textDecoration: 'none',
            },
        },
        centeredElement: {
            width: 'fit-content',
            margin: 'auto',
            textAlign: 'center',
        },
        heading: {
            display: 'block',
            marginBottom: '1.5rem',
            fontWeight: '600',
            fontSize: '2.25rem',
        },
        buttonPadding: {
            paddingTop: '2.5rem',
            paddingBottom: '1.5rem',
        },
    };
});

export default useStyles;
