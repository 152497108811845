import { makeStyles } from '@brivo/react-components';

export const useStyles = makeStyles((theme) => {
    const devicesListSubheaderBg =
        theme.palette.type === 'dark' ? theme.palette.colors.grey1000 : theme.palette.colors.grey15;
    const dragAndDropBackgroundColor =
        theme.palette.type === 'dark' ? theme.palette.colors.grey1000 : theme.palette.colors.grey15;
    const panelAvatarFillColor =
        theme.palette.type === 'dark' ? theme.palette.colors.grey950 : theme.palette.colors.grey0;
    const panelAvatarDisabledColor =
        theme.palette.type === 'dark' ? theme.palette.colors.grey50 : theme.palette.colors.grey950;
    const errorTextColor = theme.palette.type === 'dark' ? theme.palette.colors.grey200 : theme.palette.colors.grey700;
    const errorIconPathColor =
        theme.palette.type === 'dark' ? theme.palette.colors.red400 : theme.palette.colors.red800;
    const errorIconRectColor =
        theme.palette.type === 'dark' ? theme.palette.colors.redAltDark : theme.palette.colors.redAltLight;
    return {
        mapContainer: {
            '&.withDeviceDrawer': {
                '& .leaflet-control-zoom': {
                    marginRight: '420px',
                },
                '& .topControls': {
                    marginRight: '400px',
                },
            },
            '&:not(.withDeviceDrawer)': {
                '& .leaflet-control-zoom': {
                    marginRight: '20px',
                },
                '& .topControls': {
                    marginRight: '0',
                },
            },

            '&.dark .leaflet-image-layer': {
                filter: 'invert(100%)',
            },

            '&.dark .leaflet-container': {
                background: theme.palette.colors.black,
            },
            '&.light .leaflet-container': {
                background: theme.palette.colors.grey0,
            },

            '& .leaflet-marker-icon .MuiSvgIcon-root': {
                width: '50px',
                height: '50px',
                transition: 'all 0.3s ease-out',
            },

            '& .leaflet-marker .MuiSvgIcon-root': {
                width: '50px',
                height: '50px',
            },
        },
        deviceMarkerWrapper: {
            display: 'flex',
            position: 'absolute',
        },
        cameraDirectionWrapper: {
            position: 'absolute',
            transformOrigin: 'bottom left',
        },
        rotationButton: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            border: `1px solid ${theme.palette.colors.grey400}`,
            boxShadow: `0px 2px 2px ${theme.palette.colors.grey400}`,
            backgroundColor: theme.palette.colors.grey0,
            borderRadius: '100%',
            width: '27px',
            height: '27px',
        },
        imageUploadFailedText: {
            fontFamily: 'IBM Plex Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: errorTextColor,
        },
        imageUploadFailedTitle: {
            fontFamily: 'IBM Plex Sans',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '21px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: theme.palette.brivoAliases.errorTitleColor,
            marginBottom: '10px',
        },
        errorContainer: {
            display: 'flex',
            marginBottom: '10px',
            border: 'solid 1px',
            padding: '25px 34px 25px 21px',
            borderColor: `${
                theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red400
            }`,
            '@media (max-width: 1143px)': {
                flexDirection: 'column',
                alignItems: 'center',
                padding: '25px 16px 25px 25px',
            },
        },
        errorIcon: {
            marginTop: '-5px',
            marginRight: '10px',
            '& rect': {
                fill: errorIconRectColor,
            },
            '& path': {
                fill: errorIconPathColor,
            },
        },
        errorIconColor: {
            color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red400}`,
        },
        errorMessage: {
            marginTop: '.5rem',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300}`,
        },
        input: {
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                '&::placeholder': {
                    color: theme.palette.type === 'light' ? theme.palette.colors.grey800 : theme.palette.colors.grey300,
                    fontSize: '12px',
                    fontWeight: 200,
                    opacity: 1,
                },
            },
            '& .MuiFormLabel-root.MuiInputLabel-root': {
                color: theme.palette.brivoAliases.newLabelColor,
            },
        },

        inputError: {
            '& .MuiInputBase-root': {
                border: `1px solid ${
                    theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red400
                }`,
                borderRadius: 4,
            },
        },
        floorImageContainer: {
            height: '100vh',
            width: 'auto',

            '&.dark': {
                filter: 'invert(100%)',
            },
        },
        dragAndDropImageContainer: {
            height: '100vh',
            width: '100%',
            padding: '2rem',

            '& .dropzone': {
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23344559FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e")`,
                backgroundColor: dragAndDropBackgroundColor,
                borderRadius: '3px',
                backgroundSize: 'cover',
                cursor: 'pointer',

                '& .MuiSvgIcon-root': {
                    width: '5rem',
                    height: '5rem',
                },
                '& h4': {
                    color: theme.palette.brivoAliases.fieldTextSecondary,
                },
            },
        },
        replaceFileContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.brivoAliases.dividerColor,
            padding: '0 0.3rem',
            marginTop: '0.8rem',
            borderRadius: '3px',

            '& .MuiTypography-body2': {
                fontSize: '0.6rem',
            },
            '& .MuiButton-label': {
                fontSize: '0.6rem',
            },

            '& #Button__replace_image_button': {
                marginLeft: '0.2rem',
                padding: '6px 3px',
            },

            '& .MuiSvgIcon-root': {
                width: '1rem',
                cursor: 'pointer',
            },
        },
        buttonContainer: {
            display: 'flex',
        },
        deleteFileIcon: {
            display: 'flex',
            alignItems: 'center',

            '&.disabled': {
                pointerEvents: 'none',
                color: theme.palette.brivoAliases.disabledButtonLabel,
            },
        },
        filenameWrapper: {
            display: 'flex',
            alignItems: 'center',

            '& .MuiSvgIcon-root': {
                marginRight: '0.3rem',
            },
        },
        imageFileName: {
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
            wordBreak: 'break-all',
        },
        dndHelptext: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        dndHelptextTitle: {
            fontWeight: 'bold',
            color: `${theme.palette.colors.grey700} !important`,
        },
        sidebarContainer: {
            backgroundColor: theme.palette.brivoAliases.blockBackground,
            borderRight: `1px solid ${theme.palette.brivoAliases.dividerColor}`,
            position: 'relative',
        },
        sidebarActionButtons: {
            position: 'absolute',
            padding: '1rem',
            bottom: '0',
            left: '0',
            display: 'block',
            width: '100%',
            backgroundColor: theme.palette.brivoAliases.blockBackground,

            '& #Button__save_floor_plan': {
                width: '100%',
                marginBottom: '0.5rem',
            },
            '& #Button__discard_floor_plan': {
                width: '100%',
                marginBottom: '0.5rem',
            },
            '& #Button__delete_floor_plan': {
                width: '100%',
                color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300}`,
            },
        },
        sidebarTitleContainer: {
            padding: '1.5rem 1rem',
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem',
            fontWeight: 'bold',

            '& .MuiSvgIcon-root': {
                marginRight: '0.5rem',
            },
        },
        filePickerWrapper: {
            marginBottom: '1.5rem',

            '& #Button__add_image_button': {
                width: '100%',
                marginTop: '0.8rem',
            },
        },
        filePickerLabel: {
            fontWeight: 'bold',
        },
        floorPlanNameInputLabel: {
            marginBottom: '0.3rem',
        },
        devicesBlock: {
            borderTop: `1px solid ${theme.palette.brivoAliases.dividerColor}`,
            padding: '0',
            maxHeight: 'calc(100vh - 490px)',
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor: theme.palette.brivoAliases.blockListBackground,
        },
        devicesBlockWithSearch: {
            borderTop: `1px solid ${theme.palette.brivoAliases.dividerColor}`,
            padding: '0',
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor: theme.palette.brivoAliases.blockListBackground,
        },
        devicesTitle: {
            padding: '0 0 0 1.2rem',
            fontWeight: 'bold',
            backgroundColor: theme.palette.brivoAliases.blockBackground,
            color: theme.palette.brivoAliases.blockTextColor,
            '&.disabled': {
                color: theme.palette.colors.grey400,
            },
        },
        devicesListTitle: {
            padding: '0 0.8rem',
            backgroundColor: theme.palette.brivoAliases.blockBackground,
            color: theme.palette.brivoAliases.blockTextColor,

            '& .MuiTypography-body1': {
                fontWeight: 'bold',
                marginLeft: '0.4rem',
            },

            '& .MuiAccordionSummary-expandIcon svg': {
                width: '1.8rem',
                height: '1.8rem',
            },
        },
        avatarStrokeAndFillColor: {
            stroke: theme.palette.colors.grey400,
            fill: panelAvatarFillColor,
        },
        avatarFillColor: {
            fill: panelAvatarDisabledColor,
        },
        avatarStrokeAndFillDisabledColor: {
            stroke: panelAvatarDisabledColor,
            fill: panelAvatarFillColor,
        },
        avatarFillDisabledColor: {
            fill: panelAvatarDisabledColor,
        },
        panelListIcon: {
            fill: theme.palette.colors.grey50,
            stroke: theme.palette.colors.grey50,
        },
        panelListIconPlaced: {
            fill: theme.palette.colors.grey400,
        },
        devicesHeaderContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '40px',
            width: '100%',
            padding: '0px',
            backgroundColor: theme.palette.brivoAliases.blockBackground,

            color: theme.palette.brivoAliases.blockTextColor,
            borderBottom: `0.1rem solid ${theme.palette.brivoAliases.blockListBackground}`,
        },
        devicesHeaderContainerWithOpenSearch: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '60px',
            marginRight: '1.2rem',
            color: theme.palette.brivoAliases.blockTextColor,
            borderBottom: `0.1rem solid ${theme.palette.brivoAliases.blockListBackground}`,
            backgroundColor: theme.palette.brivoAliases.blockListBackground,

            '& .MuiInputBase-root': {
                marginBottom: '0 !important',
            },
        },
        disableRipple: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        devicesAccordion: {
            display: 'flex',
            overflowX: 'auto',
        },
        devicesListContainer: {
            borderImage: 'none',
            borderTop: 0,
            padding: '0',
            backgroundColor: theme.palette.brivoAliases.blockListBackground,

            '& .MuiListSubheader-root': {
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '11px',
                fontWeight: 600,
                lineHeight: '33px',
                backgroundColor: devicesListSubheaderBg,

                '& .MuiButtonBase-root': {
                    marginRight: '-0.9rem',

                    '& .MuiButton-label': {
                        fontSize: '11px',
                    },
                },
            },
        },
        devicesCategoryItemsWrapper: {
            maxHeight: '12.8rem',
            overflowY: 'auto',
            overflowX: 'hidden',

            '& li': {
                borderBottom: `1px solid ${theme.palette.brivoAliases.dividerColor}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        devicesListContainerWithSearch: {
            borderImage: 'none',
            borderTop: 0,
            padding: '0',
            backgroundColor: theme.palette.brivoAliases.blockListBackground,

            '& ul': {
                height: 'calc(100vh - 380px)',
                overflowX: 'hidden',
                overflowY: 'auto',
            },

            '& li': {
                borderBottom: `1px solid ${theme.palette.brivoAliases.dividerColor}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        devicesListItem: {
            padding: '0.3rem 0rem 0.3rem 1rem',
            color: theme.palette.brivoAliases.blockTextColor,
            display: 'flex',
            alignItems: 'center',
            height: '2.5rem',
            cursor: 'default',
        },
        devicesListItemAvatar: {
            minWidth: 'auto !important',
            marginRight: '0.3rem',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.brivoAliases.newNavBackgroundHoverColor,

            '& .MuiSvgIcon-root': {
                width: '14px',
                height: '14px',
            },
        },
        devicesListItemAvatarPlaced: {
            '& .MuiSvgIcon-root': {
                fill: theme.palette.colors.grey400,
            },
        },
        devicesListItemText: {
            flex: 'none',
            maxWidth: '100%',

            '& .MuiTypography-root': {
                width: '100%',
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                wordBreak: 'break-all',
            },
            '&.disabled': {
                color: theme.palette.colors.grey400,
            },
        },
        devicesListItemTextPlaced: {
            color: theme.palette.colors.grey400,
        },
        devicesListItemAction: {
            margin: '0 0 0 0.3rem',
            position: 'static !important',
            transform: 'none !important',

            '& .MuiButtonBase-root': {
                padding: '11px',
            },
            '& .MuiSvgIcon-root': {
                width: '1.1rem',
                height: '1.1rem',
                color: theme.palette.brivoAliases.tertiaryIconButtonColor,
            },
            '& button[aria-label="add"] .MuiSvgIcon-root': {
                color: theme.palette.brivoAliases.tertiaryIconButtonColor,
            },
            '& button[aria-label="delete"] .MuiSvgIcon-root': {
                color: theme.palette.brivoAliases.primaryIconButtonColor,
            },
        },
        countChip: {
            borderRadius: '40%',
            height: '1.1rem',
            padding: '0.5rem 0.5rem',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            marginTop: '0.1rem',
            marginLeft: '0.5rem',
            backgroundColor: theme.palette.colors.grey1200,
            color: theme.palette.colors.grey0,
        },
        customMarker: {
            minWidth: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            backgroundColor: theme.palette.brivoAliases.newNavBackgroundHoverColor,
            padding: '0.3rem',

            '& > .MuiSvgIcon-root': {
                width: '2rem',
                height: '2rem',
            },
        },
        themeSwitchContainer: {
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0.3rem',

            '& .MuiFormControlLabel-label': {
                marginRight: '1rem',
                fontWeight: 'bold',
                fontSize: '1rem',
            },

            '&.dark .MuiFormControlLabel-label': {
                color: theme.palette.colors.grey0,
            },
            '&.light .MuiFormControlLabel-label': {
                color: theme.palette.colors.black,
            },
        },
        borderLine: {
            borderTop: `1px solid ${theme.palette.brivoAliases.dividerColor}`,
        },
        wrapper: {
            height: '282px',
            backgroundColor: 'transparent',
        },
        noResultContainer: {
            backgroundColor: theme.palette.brivoAliases.blockListBackground,
        },
        containerForOpenSearch: {
            backgroundColor: theme.palette.brivoAliases.blockListBackground,
            height: '100vh',
        },
        noResultFoundTitle: {
            styleName: 'Heading/H2',
            fontFamily: 'IBM Plex Sans',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '23px',
            letterSpacing: '0px',
            textAlign: 'center',
        },
        noResultFoundSubtitle: {
            styleName: 'Body/Default',
            fontFamily: 'IBM Plex Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0px',
            textAlign: 'center',
            color: theme.palette.colors.grey300,
        },
        eventsCount: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '38px',
            height: '38px',
            borderRadius: '50%',
            marginTop: '-10px',
            marginLeft: '-10px',
            zIndex: '999',
            fontFamily: 'IBM Plex Sans',
            fontSize: '14px',
            fontWeight: '600',
            letterSpacing: '0px',

            '&.dark': {
                backgroundColor: theme.palette.colors.grey0,
                color: theme.palette.colors.black,
            },
            '&.light': {
                backgroundColor: theme.palette.colors.grey700,
                color: theme.palette.colors.grey0,
            },
            '&.informational': {
                backgroundColor: theme.palette.colors.blue700,
                color: theme.palette.colors.grey0,
            },
            '&.warning': {
                backgroundColor: theme.palette.colors.yellow700,
                color: theme.palette.colors.black,
            },
            '&.critical': {
                backgroundColor: theme.palette.colors.red800,
                color: theme.palette.colors.grey0,
            },
            '&.dark.noEvents': {
                backgroundColor: theme.palette.colors.grey400,
                color: theme.palette.colors.grey0,
            },
            '&.light.noEvents': {
                backgroundColor: theme.palette.colors.grey400,
                color: theme.palette.colors.grey0,
            },
            '&.informational.noEvents': {
                backgroundColor: theme.palette.colors.blue100,
                color: theme.palette.colors.black,
            },
            '&.warning.noEvents': {
                backgroundColor: theme.palette.colors.yellowAltLight,
                color: theme.palette.colors.black,
            },
            '&.critical.noEvents': {
                backgroundColor: theme.palette.colors.redAltLight,
                color: theme.palette.colors.black,
            },
            '& sup': {
                verticalAlign: 'super',
                fontSize: 'smaller',
            },
        },
        criticalSymbol: {
            backgroundColor: theme.palette.colors.red800,
            boxShadow: `0px 0px 7px 1px ${theme.palette.colors.red800}`,
            borderRadius: '100%',
            zIndex: '1000',
        },
        warningSymbol: {
            backgroundColor: theme.palette.colors.yellow700,
            boxShadow: `0px 0px 7px 1px ${theme.palette.colors.yellow700}`,
            borderRadius: '100%',
            zIndex: '1000',
        },
        doorCircleColor: {
            fill: theme.palette.colors.grey1200,
        },
        doorPathColor: {
            fill: theme.palette.colors.grey0,
        },
        panelCircleColor: {
            fill: theme.palette.colors.grey1200,
        },
        panelPathColor: {
            fill: theme.palette.colors.grey0,
        },
        panelRectColor: {
            fill: theme.palette.colors.grey1200,
            stroke: theme.palette.colors.grey0,
        },
        cameraCircleColor: {
            fill: theme.palette.colors.grey1200,
        },
        cameraPathColor: {
            stroke: theme.palette.colors.grey0,
            fill: theme.palette.colors.grey1200,
        },
        loadingSpinnerContainer: {
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(52, 52, 52, 0.8)',
            zIndex: 999,
        },
    };
});
