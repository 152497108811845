import { useCallback, useContext } from 'react';
import { checkPermissions } from '@brivo/onairplus-services';
import { UserContext } from '../user/UserProvider';

export function usePermissions() {
    const { permissions: userPermissions } = useContext(UserContext);

    const hasPermissions = useCallback(
        (...necessaryPermissions) =>
            checkPermissions({
                userPermissions,
                necessaryPermissions,
            }),
        [userPermissions]
    );

    return { hasPermissions };
}
