import React, { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
const PropertyDetails = lazy(() => import('./PropertyDetails'));
const CreatePropertyDetails = lazy(() => import('./CreatePropertyDetails'));

const PropertyWrapper = (props) => {
    const { id } = useParams();
    const isCreateMode = id === 'create-property';
    return (
        <Suspense fallback={null}>
            {isCreateMode ? <CreatePropertyDetails {...props} /> : <PropertyDetails {...props} id={id} />}
        </Suspense>
    );
};
export default PropertyWrapper;
