import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles(() => {
    return {
        tablePaper: {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            paddingBottom: '1.5rem',
        },
        tableWrapper: {
            '& .MuiTableBody-root > div:first-child': {
                height: 'fit-content !important',
            },
        },
        tablePaperNoHeader: {
            paddingTop: '.5rem',
        },
        viewTablePaper: {
            paddingBottom: '1.5rem',
        },
        tableHeader: {
            paddingTop: '1.5rem',
            paddingBottom: '.5rem',
        },
        moreVertIcon: {
            float: 'right',
            marginTop: '1.5rem',
        },
        pageHeader: {
            display: 'flex',
            alignItems: 'center',
        },
        headerButtonsDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '1rem',
            flex: '1 0 0',
            marginTop: '12px',
        },
        headerWithIconMenuDiv: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        iconMenu: {
            float: 'right',
        },
        errorAlert: {
            marginBottom: '16px',
        },
        sectionTitleButton: {
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',

            marginBottom: '2.5rem',
            maxWidth: '80%',
            '& #Button__section-title-button': {
                backgroundColor: 'transparent !important',
                padding: '0',
            },
        },
        noVisualFoundWrapper: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        noVisualFoundTitle: {
            fontWeight: '600',
            fontSize: '18px',
        },
        noVisualFoundSubtitle: {
            fontWeight: '400',
            fontSize: '14px',
        },
        titleTooltipIcon: {
            display: 'inline-block',
            verticalAlign: 'middle',
            maxWidth: '100%',
        },
    };
});

export default useStyles;
