import { useTranslation } from 'react-i18next';
import { DropdownCaretDownIcon, DropdownCaretUpIcon, IconButton, Tooltip, CloseIcon } from '@brivo/react-components';
import { Box, Divider, Grid, MoreHoriz } from '@brivo/react-components';

import IconMenu from '../IconMenu';
import TrimmedText from '../TrimmedText';

import useStyles from './styles';

const TableHeader = ({
    headerKey,
    header,
    handleRemoveTable,
    isEditing,
    isPivotTable,
    isTableOpen,
    isDataExplorerTable,
    handleToggleTable,
    idx,
    isCaretDisabled,
    menuItems,
    uuid,
    myAssistantView = false,
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const headerComponent = (header) => {
        return (
            <TrimmedText
                content={header}
                variant="h5"
                data-testid={`${isPivotTable ? 'pivot-' : ''}table-header-${headerKey}`}
            />
        );
    };

    const formatTitle = (headerKey, header) => {
        if (headerKey) {
            const isolatedHeaderKey = `${headerKey}`.replace('Page.brivo-analytics.header.', '');
            const key = `Page.brivo-analytics.header.${isolatedHeaderKey}`;
            const headerTooltip = i18n.exists(key)
                ? t(`Page.brivo-analytics.header-tooltip.${isolatedHeaderKey}`)
                : null;
            const formattedHeader = i18n.exists(key) ? t(key) : header;
            return (
                <>
                    {headerTooltip ? (
                        <Tooltip className={classes.titleTooltipIcon} title={headerTooltip}>
                            {headerComponent(formattedHeader)}
                        </Tooltip>
                    ) : (
                        headerComponent(formattedHeader)
                    )}
                </>
            );
        } else return headerComponent(header);
    };

    const title = formatTitle(headerKey, header);

    if (!title) {
        return null;
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className={myAssistantView ? classes.noPaddingTableHeader : classes.tableHeader}
            >
                <Grid item xs={11}>
                    {title}
                </Grid>
                <Grid item xs={'auto'}>
                    {!isEditing ? (
                        <Box display="flex" alignItems="center">
                            <IconMenu
                                icon={<MoreHoriz id={'table-header-icon-menu'} />}
                                iconStyles={classes.iconMenu}
                                menuItems={menuItems}
                                id={`${headerKey}-icon-menu`}
                            />
                            {isDataExplorerTable &&
                                (isTableOpen ? (
                                    <IconButton
                                        onClick={() => handleToggleTable(false, idx)}
                                        icon={<DropdownCaretUpIcon />}
                                        className={isCaretDisabled && classes.iconButtonDisabled}
                                        disabled={isCaretDisabled}
                                    />
                                ) : (
                                    <IconButton
                                        onClick={() => handleToggleTable(true, idx)}
                                        icon={<DropdownCaretDownIcon />}
                                        className={isCaretDisabled && classes.iconButtonDisabled}
                                        disabled={isCaretDisabled}
                                    />
                                ))}
                        </Box>
                    ) : (
                        <IconButton
                            className={classes.iconMenu}
                            onClick={() => handleRemoveTable(uuid)}
                            icon={<CloseIcon />}
                        />
                    )}
                </Grid>
            </Grid>
            <Divider />
        </>
    );
};

export default TableHeader;
