// SECTIONS
export const SECTION_CONFIGURATION = 'configuration';
export const SECTION_GROUPS = 'groups';

export const OUTPUT_POINT = 'OUTPUT_POINT';

export const BEHAVIOR_OPTIONS = [{ id: 3, template: 'Pulse' }];

export const INPUT_STATE_OPTIONS = [
    { id: 0, template: 'Page.commands-select-switch-disengaged' },
    { id: 1, template: 'Page.commands-select-switch-engaged' },
];

export const TRIGGER_OPTIONS = [
    { id: 1, template: 'Page.commands-trigger-options-single' },
    { id: 2, template: 'Page.commands-trigger-options-double' },
];

export const FIELD_NAME = {
    commandName: 'commandName',
    commandDescription: 'commandDescription',
    schedule: 'scheduleId',
    trigger: 'trigger',
    site: 'site',
    panel: 'panel',
    swipeTimeout: 'swipeTimeout',
    delay: 'delay',
    accessPoints: 'accessPoints',
    input: 'input',
    inputState: 'inputState',
    board: 'board',
    relay: 'relay',
    behavior: 'behavior',
    groups: 'groups',
    boardTypeId: 'boardTypeId',
};

// Ordered as in the UI
export const FIELD_ID_TO_NAME_MAP = {
    1: FIELD_NAME.commandName,
    2: FIELD_NAME.commandDescription,
    3: FIELD_NAME.schedule,
    4: FIELD_NAME.trigger,
    11: FIELD_NAME.swipeTimeout,
    5: FIELD_NAME.site,
    6: FIELD_NAME.panel,
    7: FIELD_NAME.board,
    8: FIELD_NAME.accessPoints,
    9: FIELD_NAME.relay,
    10: FIELD_NAME.input,
    13: FIELD_NAME.inputState,
    14: FIELD_NAME.behavior,
    12: FIELD_NAME.delay,
    15: FIELD_NAME.groups,
};

export const INITIAL_VALUES = {
    [FIELD_NAME.commandName]: '',
    [FIELD_NAME.commandDescription]: '',
    [FIELD_NAME.schedule]: '',
    [FIELD_NAME.trigger]: '',
    [FIELD_NAME.swipeTimeout]: 0,
    [FIELD_NAME.site]: '',
    [FIELD_NAME.panel]: '',
    [FIELD_NAME.board]: '',
    [FIELD_NAME.accessPoints]: [],
    [FIELD_NAME.relay]: '',
    [FIELD_NAME.input]: '',
    [FIELD_NAME.inputState]: '',
    [FIELD_NAME.behavior]: 3,
    [FIELD_NAME.delay]: 0,
    [FIELD_NAME.groups]: [],
};

export const NAV_ITEMS = [
    {
        id: SECTION_CONFIGURATION,
        offset: -200,
    },
    {
        id: SECTION_GROUPS,
        offset: -200,
    },
];

export const VALID_PANEL_IDS_FOR_READER_COMMANDS = [28, 29, 30, 31, 33, 35, 37, 49, 50];

export const COMMANDS_PAGE_SIZE = 20;
