import gql from 'graphql-tag';

export const GET_HOLIDAYS_FOR_JOURNAL = gql`
    query holidays($name: String!, $rows: Int = 10) {
        getHolidays(limit: { offset: 0, rows: $rows }, filters: { name: $name }) {
            id
            name
        }
    }
`;
