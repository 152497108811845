import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => {
    return {
        timelineFiltersContainer: {
            marginTop: '5px',
        },
        timelineFiltersContainerJustified: {
            justifyContent: 'space-between',
            margin: theme.spacing(1, 0),
        },
        timelineContainer: {
            maxHeight: '130px',
        },
        jumpClipContainer: {
            display: 'flex',
            alignItems: 'center',
            paddingRight: theme.spacing(1),
            fontSize: '14px',
        },
        jumpClipLoadingText: {
            marginLeft: theme.spacing(2),
            alignItems: 'center',
        },
        jumpClipsToggle: {
            borderRadius: '3px',
            padding: theme.spacing(0.5),
            height: '20px',
            width: '20px',
            margin: theme.spacing(0, 0.5),
        },
        hidden: {
            visibility: 'hidden',
        },
        disabled: {
            '& .MuiSvgIcon-root': {
                opacity: 0.5,
            },
        },
        visibleEventCount: {
            margin: '7px auto',
            '& .MuiChip-root': {
                backgroundColor: `${
                    theme.palette.type === 'dark' ? theme.palette.colors.grey950 : theme.palette.colors.grey100
                }`,
            },
            '& .MuiTypography-root': {
                fontWeight: 600,
            },
        },
        timelineTooltip: {
            margin: '0 auto',
            marginTop: theme.spacing(1.5),
        },
        eventTooltipTitle: {
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: 600,
            color: theme.palette.brivoAliases.linkTextColor,
        },
        eventClassification: {
            padding: 0,
        },
        eventLegendTitle: {
            fontWeight: 600,
        },
        eventCircle: {
            paddingRight: theme.spacing(0.5),
        },
    };
});

export default styles;
