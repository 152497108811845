import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 9.00159H21.5L18.8 5.40159C18.611 5.14959 18.315 5.00159 18 5.00159H8C7.621 5.00159 7.275 5.21559 7.105 5.55459L5.382 9.00159H4C1.794 9.00159 0 10.7966 0 13.0016V15.0016C0 15.5536 0.448 16.0016 1 16.0016H3.185C3.599 17.1616 4.698 17.9996 6 17.9996C7.302 17.9996 8.401 17.1616 8.816 15.9996H16.185C16.599 17.1616 17.699 17.9996 19 17.9996C20.301 17.9996 21.401 17.1616 21.816 15.9996H23C23.552 15.9996 24 15.5526 24 14.9996V11.0016C24 9.89859 23.103 9.00159 22 9.00159ZM19 9.00159H15V7.00159H17.5L19 9.00159ZM8.618 7.00159H13V9.00159H7.618L8.618 7.00159ZM6 16.0016C5.449 16.0016 5 15.5516 5 15.0016C5 14.4496 5.449 14.0016 6 14.0016C6.551 14.0016 7 14.4496 7 15.0016C7 15.5516 6.551 16.0016 6 16.0016ZM19 16.0016C18.449 16.0016 18 15.5516 18 15.0016C18 14.4496 18.449 14.0016 19 14.0016C19.551 14.0016 20 14.4496 20 15.0016C20 15.5516 19.551 16.0016 19 16.0016ZM22 14.0016H21.816C21.401 12.8376 20.302 12.0016 19 12.0016C17.698 12.0016 16.599 12.8386 16.185 14.0016H8.816C8.401 12.8376 7.302 12.0016 6 12.0016C4.698 12.0016 3.599 12.8386 3.185 14.0016H2V13.0016C2 11.8966 2.897 11.0016 4 11.0016H22V14.0016Z"
                fill="#607185"
            />
        </svg>
    </React.Fragment>,
    'RecentActivityUsingLicensePlate'
);
