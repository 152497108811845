import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    section: {
        width: '100%',
        fontSize: '14px',
    },
    lockDownButton: {
        cursor: 'pointer',
        width: '184px',
        borderRadius: '0.25rem',
        fontWeight: '600',
        textDecoration: 'none',
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '2.25rem',
        padding: '0 5px',
    },
    inactiveLockdownButton: {
        border: theme.palette.brivoAliases.newNavActivateLockdownBorder,
        color: theme.palette.brivoAliases.newNavActivateLockdownTextColor,
        backgroundColor: theme.palette.brivoAliases.newNavActivateLockdownBackgroundColor,
        '&:hover': {
            backgroundColor: theme.palette.brivoAliases.newNavActivateLockdownBackgroundHoverColor,
        },
    },
    activeLockdown: {
        border: 'solid 1px rgba(219, 31, 35, 0.5)',
        backgroundColor: 'rgba(219, 31, 35, 0.3)',
        boxShadow: '0 0 1.5rem 0 rgba(219, 31, 35, 0.4)',
        color: '#F8292D',
        '&:hover': {
            backgroundColor: 'rgba(219, 31, 35, 0.3)',
        },
    },
    lockDownConfigButton: {
        width: '2.25rem',
        height: '2.25rem',
        borderRadius: '0.25rem',
        marginLeft: '0.75rem',

        color: theme.palette.brivoAliases.newNavLockdownConfigButtonColor,
        backgroundColor: theme.palette.brivoAliases.newNavLockdownConfigButtonBackgroundColor,
    },
    lockdownButtons: (mobileStyle) => ({
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: mobileStyle.lockdownButtonsPadding,
        alignItems: 'center',
    }),
    userAvatar: (mobileStyle) => ({
        padding: mobileStyle.userPadding,
    }),
}));

export default styles;
