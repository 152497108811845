export const INITIAL_SITE_VALUES = {
    id: 0,
    name: '',
    address: {
        id: 0,
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        timeZone: '',
    },
};
export const INITIAL_SITE_LIST_FILTER_VALUE = {
    name: '',
};
export const SITE_LIST_PAGE_SIZE = 20;
export const USER_LIST_PAGE_SIZE = 20;
export const SECTION_SITE_DETAILS = 'siteDetails';
export const SECTION_SITE_LIST = 'siteList';
export const SECTION_MAPPED_PROPERTY_DEVICES = 'mappedPropertyDevices';
export const SECTION_PROPERTY_DEVICES = 'propertyDevices';
export const SECTION_SITE_USERS = 'siteUsers';
export const SITE_INFO_FIELD_TO_ERROR_MAP = {
    name: {
        targetId: 'TextField__name',
        erroredFieldKey: 'Page.property-details.label.name',
    },
    streetAddress1: {
        targetId: 'TextField__streetAddress1',
        erroredFieldKey: 'Page.property-details.label.addressOne',
    },
    country: {
        targetId: 'TextField__country',
        erroredFieldKey: 'Page.property-details.label.country',
    },
    state: {
        targetId: 'TextField__state',
        erroredFieldKey: 'Page.property-details.label.state',
    },
    city: {
        targetId: 'TextField__city',
        erroredFieldKey: 'Page.property-details.label.city',
    },
    postalCode: {
        targetId: 'TextField__postalCode',
        erroredFieldKey: 'Page.property-details.label.postalCode',
    },
    timeZone: {
        targetId: 'timeZone',
        erroredFieldKey: 'Page.property-details.label.timeZone',
    },
};
