import { useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import {
    GenericDialog,
    SingleSelectField,
    Button,
    TextField,
    Grid,
    LoadingIndicator,
    Avatar,
    makeStyles,
} from '@brivo/react-components';
import DownloadIcon from '@common/components/Icons/DownloadIcon';

export const permissionOptions = [
    { id: 'PUBLIC', name: 'Public' },
    { id: 'PRIVATE', name: 'Private' },
    // commenting these options out until we work on roles in next phase.
    // { id: 'ADMIN', name: 'Admin' },
    // { id: 'SUPERVISOR', name: 'Supervisor' },
    // { id: 'MANAGER', name: 'Manager' },
];

const useStyles = makeStyles(() => ({
    modal: {
        width: '575px', // smaller than maxWidth to prevent scrollbar
    },
}));

const CreateDashboardModal = ({ isOpen, onClose, globalFilters, dashboards, layoutType, dashboardCreated }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { t } = useTranslation();
    const classes = useStyles();

    const handleFormSubmit = async (values, { validateForm, setErrors, setTouched }) => {
        const errors = await validateForm();
        if (!isEmpty(errors)) {
            setTouched({ name: true });
            setErrors(errors);
        } else {
            setIsSubmitting(true);

            await dashboardCreated('CREATE', false, values.name, values.sharingType, layoutType, globalFilters);

            setIsSubmitting(false);
            onClose(values);
        }
    };

    const dashboardNames = dashboards?.map((dashboard) => dashboard.name.trim().toLowerCase());

    const layoutValidationSchema = (t, dashboardNames) => {
        return Yup.object().shape({
            name: Yup.string()
                .required(t('Page.unified-dashboard.create-dashboard.modal.input.required-text'))
                .test(
                    'dashboard-name',
                    t('Page.unified-dashboard.create-dashboard.modal.input.dashboard.exists-message'),
                    (value) => value && !dashboardNames.includes(value.trim().toLowerCase().replace(/\s+/g, ''))
                ),
        });
    };

    const validationSchema = useMemo(() => layoutValidationSchema(t, dashboardNames), [t, dashboardNames]);

    return (
        <GenericDialog
            avatarIcon={
                <Avatar size="small">
                    <DownloadIcon />
                </Avatar>
            }
            open={isOpen}
            title={t('Page.unified-dashboard.create-dashboard.modal.title')}
            onClose={onClose}
            id="createDashboard"
            maxWidth="sm"
            className={classes.modal}
        >
            <Formik
                initialValues={{ name: '', sharingType: 'PUBLIC' }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, values, touched, errors, handleChange, setFieldValue }) => {
                    return (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        id="name"
                                        label={t('Page.unified-dashboard.create-dashboard.name.input.label')}
                                        placeholder={t('Page.unified-dashboard.create-dashboard.input.placeholder')}
                                        value={values.name}
                                        onChange={handleChange}
                                        required
                                        error={touched.name && Boolean(errors?.name)}
                                        helperText={touched.name && errors?.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SingleSelectField
                                        label={t('Page.unified-dashboard.create-dashboard.permissions.select.label')}
                                        selectedOption={values.sharingType}
                                        options={permissionOptions}
                                        handleChange={(event, permission) =>
                                            setFieldValue('sharingType', permission.props.value)
                                        }
                                        helperText={t(
                                            `Page.unified-dashboard.create-dashboard.permissions.select.helper-text.${
                                                values.sharingType === 'PRIVATE' ? 'private' : 'public'
                                            }`
                                        )}
                                    />
                                </Grid>
                                <Grid item container justifyContent="flex-end" spacing={1}>
                                    <Grid item>
                                        <Button
                                            type="secondary"
                                            text={t('Page.unified-dashboard.close-dashboard.text')}
                                            onClick={onClose}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="primary"
                                            text={t('Page.unified-dashboard.create-dashboard.text')}
                                            disabled={isSubmitting}
                                            startIcon={isSubmitting ? <LoadingIndicator size={20} padding={0} /> : null}
                                            onClick={handleSubmit}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </GenericDialog>
    );
};

CreateDashboardModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    globalFilters: PropTypes.array,
    layoutType: PropTypes.string,
    dashboards: PropTypes.array.isRequired,
    dashboardCreated: PropTypes.func.isRequired,
};

CreateDashboardModal.defaultProps = {
    globalFilters: {},
    layoutType: 'THREEBYTHREE',
};

export default CreateDashboardModal;
