import { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    Button,
    Grid,
    Typography,
    IconButton,
    CloseIcon,
    DraggableIcon,
    EditIcon,
    AddCircleOutline,
    DialogContentText,
    Box,
    ErrorDialog,
} from '@brivo/react-components';
import useStyles from './styles';
import DraggableSection from '../DashboardWidget/DraggableSection';
import RenameDialog from '../../../BrivoAnalytics/components/RenameDialog';
import { sectionHeaderConfiguration } from '../../../BrivoAnalytics/components/MyAnalytics/constants';
import { FULL_WIDTH } from '../../../BrivoAnalytics/components/RenameDialog/constants';

const DashboardLayout = ({ sections, setWidgetsToSave, getSection, isEditViewEnabled, setShowSaveActionBar }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const bottomOfGridElement = useRef(null);
    const { variant } = sectionHeaderConfiguration;

    const [isEditDialogOpen, setIsDialogOpen] = useState(false);
    const [showRemoveConfirmationModal, toggleShowRemoveConfirmationModal] = useState(false);
    const [editingSection, setEditingSection] = useState(null);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (draggableStyle) => ({
        userSelect: 'none',
        overflow: 'hidden',
        ...draggableStyle,
    });

    const handleShowConfirmationModalClick = () => {
        toggleShowRemoveConfirmationModal(true);
    };

    const handleLayoutChange = (layout) => {
        if (editingSection) {
            editingSection.sectionColumns = layout;
            editingSection.sectionDirection = layout === FULL_WIDTH ? 'vertical' : 'horizontal';
            let updatedItems = [...sections];
            updatedItems.find((item) => item.sectionId === editingSection.sectionId).sectionColumns = layout;
            setWidgetsToSave(updatedItems);
            setShowSaveActionBar(true);
        }
    };

    const handleRename = (name) => {
        if (editingSection) {
            editingSection.sectionName = name;
            let updatedItems = [...sections];
            updatedItems.find((item) => item.sectionId === editingSection.sectionId).sectionName = name;
            setWidgetsToSave(updatedItems);
            setShowSaveActionBar(true);
            setIsDialogOpen(false);
        }
    };

    const handleRemoveSectionClick = () => {
        if (editingSection) {
            const updatedItems = sections.filter((item) => item.sectionId !== editingSection.sectionId);
            setWidgetsToSave(updatedItems);
            setIsDialogOpen(false);
            toggleShowRemoveConfirmationModal(false);
            setShowSaveActionBar(true);
        }
    };

    const handleAddNewSectionClick = () => {
        const newSection = {
            sectionId: uuidv4(),
            sectionName: 'New Section',
            sectionColumns: FULL_WIDTH,
            sectionDirection: 'vertical',
            sectionOrder: sections.length,
            sectionWidgets: [],
        };

        setWidgetsToSave([...sections, newSection]);
        if (bottomOfGridElement) {
            bottomOfGridElement?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) {
                return;
            }

            const sourceIndex = result.source.index;
            const destIndex = result.destination.index;
            if (result.type === 'droppableItem') {
                let sectionsToSave = reorder(sections, sourceIndex, destIndex);
                sectionsToSave = sectionsToSave.map((item, index) => {
                    item.sectionOrder = index;
                    return item;
                });
                setWidgetsToSave(sectionsToSave);
            } else if (result.type === 'droppableSubItem') {
                const itemSubItemMap = sections.reduce((acc, item) => {
                    acc[item.sectionId] = item.sectionWidgets;
                    return acc;
                }, {});

                const sourceParentId = result.source.droppableId;
                const destParentId = result.destination.droppableId;

                const sourceSubItems = itemSubItemMap[sourceParentId];
                const destSubItems = itemSubItemMap[destParentId];

                let newItems = [...sections];

                /** In this case sectionWidgets are reOrdered inside same Parent */
                if (sourceParentId === destParentId) {
                    let reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);
                    reorderedSubItems = reorderedSubItems.map((item, index) => {
                        item.widgetOrder = index;
                        return item;
                    });

                    newItems = newItems.map((item, index) => {
                        if (item.sectionId === sourceParentId) {
                            item.sectionWidgets = reorderedSubItems;
                            item.sectionOrder = index;
                        }
                        return item;
                    });
                    setWidgetsToSave(newItems);
                } else {
                    let newSourceSubItems = [...sourceSubItems];
                    const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

                    let newDestSubItems = [...destSubItems];
                    newDestSubItems.splice(destIndex, 0, draggedItem);

                    newSourceSubItems = newSourceSubItems.map((item, index) => {
                        item.widgetOrder = index;
                        return item;
                    });

                    newDestSubItems = newDestSubItems.map((item, index) => {
                        item.widgetOrder = index;
                        return item;
                    });

                    newItems = newItems.map((item) => {
                        if (item.sectionId.toString() === sourceParentId) {
                            item.sectionWidgets = newSourceSubItems;
                        } else if (item.sectionId.toString() === destParentId) {
                            item.sectionWidgets = newDestSubItems;
                        }
                        return item;
                    });
                    setWidgetsToSave(newItems);
                }
            }
            setShowSaveActionBar(true);
        },
        [sections, setWidgetsToSave, setShowSaveActionBar]
    );

    return (
        <>
            <RenameDialog
                title={t('Page.brivo-analytics.my-analytics.edit-section.dialog.title')}
                onRenameChange={handleRename}
                isOpen={isEditDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                showEditLayoutButtons={true}
                onLayoutChange={handleLayoutChange}
                currentLayout={editingSection?.sectionColumns}
                nameInputProps={{
                    title: t('Page.brivo-analytics.my-analytics.edit-section.dialog.title'),
                    placeholder: t('Page.brivo-analytics.my-analytics.edit-section.dialog.placeholder'),
                    value: editingSection?.sectionName,
                }}
            />
            <ErrorDialog
                open={showRemoveConfirmationModal}
                title={t('Page.brivo-analytics.my-analytics.delete-section.dialog.title')}
                text={t('Page.brivo-analytics.my-analytics.delete-section.dialog.text')}
                children={
                    <DialogContentText>
                        {t('Page.brivo-analytics.my-analytics.delete-section.dialog.text2')}
                    </DialogContentText>
                }
                primaryActionText={t('Page.brivo-analytics.my-analytics.delete-section.dialog.primary-btn')}
                secondaryActionText={t('Page.brivo-analytics.my-analytics.delete-section.dialog.secondary-btn')}
                onPrimaryClick={handleRemoveSectionClick}
                onSecondaryClick={() => toggleShowRemoveConfirmationModal(false)}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" type="droppableItem">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {sections.map((item, index) => (
                                <Draggable
                                    key={item.sectionId}
                                    draggableId={item.sectionId}
                                    index={index}
                                    isDragDisabled={!isEditViewEnabled}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(provided.draggableProps.style)}
                                        >
                                            <span
                                                className={`${classes.sectionTitleButton} ${
                                                    !isEditViewEnabled ? classes.sectionTitleMargin : null
                                                }`}
                                            >
                                                {isEditViewEnabled && <DraggableIcon />}
                                                <Typography variant={variant} className={classes.sectionName}>
                                                    {item.sectionName ? item.sectionName : 'New Section'}
                                                </Typography>
                                                {isEditViewEnabled && (
                                                    <>
                                                        <IconButton
                                                            id="section-title-button"
                                                            type="tertiary"
                                                            icon={<EditIcon />}
                                                            onClick={() => {
                                                                setEditingSection(item);
                                                                setIsDialogOpen(true);
                                                            }}
                                                        />
                                                        <Box className={classes.removeSectionButton}>
                                                            <IconButton
                                                                id="section-title-button"
                                                                type="tertiary"
                                                                icon={<CloseIcon />}
                                                                onClick={() => {
                                                                    setEditingSection(item);
                                                                    handleShowConfirmationModalClick();
                                                                }}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                            </span>

                                            {item.sectionWidgets.length ? (
                                                <DraggableSection
                                                    direction={item.sectionDirection}
                                                    width={item.sectionColumns}
                                                    isEditViewEnabled={isEditViewEnabled}
                                                    sectionWidgets={item.sectionWidgets}
                                                    type={item.sectionId}
                                                    getSection={getSection}
                                                />
                                            ) : (
                                                <DraggableSection
                                                    direction={item.sectionDirection}
                                                    width={item.sectionColumns}
                                                    isEditViewEnabled={isEditViewEnabled}
                                                    sectionWidgets={[]}
                                                    type={item.sectionId}
                                                    getSection={getSection}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {isEditViewEnabled && (
                <Grid container direction="row" spacing={2}>
                    <Box className={classes.flex}>
                        <Typography variant="subtitle1" className={classes.addSectionTitle}>
                            {t('Page.brivo-analytics.my-analytics.section-header.add-section.title')}
                        </Typography>
                        <Box className={classes.divider}></Box>
                        <Box className={classes.addSectionButton}>
                            <Button
                                type="tertiary"
                                onClick={handleAddNewSectionClick}
                                startIcon={<AddCircleOutline />}
                                text={t('Page.brivo-analytics.my-analytics.section-header.add-section')}
                            />
                        </Box>
                    </Box>
                    <Box ref={bottomOfGridElement} className={classes.bottomOfGridElement} />
                </Grid>
            )}
        </>
    );
};

export default DashboardLayout;
