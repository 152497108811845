import gql from 'graphql-tag';

export const GET_INTEGRATIONS_INFO = gql`
    query getIntegrationInfo {
        getIntegrations {
            api {
                status {
                    show
                }
                applications {
                    id
                    accountId
                    name
                    clientId
                    secret
                    grantTypes
                }
            }
        }
        getIntegrationInfo {
            name
            displayName
            category
            shortDescription
            iconName
            path
            flagName
            enabled
        }
    }
`;

export const GET_INTEGRATIONS_INFO_BY_NAME = gql`
    query getIntegrationInfo($name: IntegrationName!) {
        getIntegrationInfo(name: $name) {
            name
            displayName
            category
            shortDescription
            iconName
            path
            flagName
            enabled
        }
    }
`;

export const SAVE_INTEGRATION_STATE = gql`
    mutation saveIntegrationInfoState($name: IntegrationName!, $state: Boolean!) {
        saveIntegrationInfoState(name: $name, state: $state) {
            name
            displayName
            category
            shortDescription
            iconName
            path
            flagName
            enabled
        }
    }
`;
