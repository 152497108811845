import { get, identity, memoize, negate, reverse } from 'lodash';
import { chunk, concat, filter, flow, head, map, orderBy, thru } from 'lodash/fp';
import { differenceInHours, endOfHour, isWithinInterval, startOfDay, startOfHour, toDate } from 'date-fns';

import { eachHourOfInterval, findMostRecentStatus, isStartOfDay } from '../../common/utils/Utils';
import {
    COMMAND_TRIGGERED_BY_USER,
    DEVICE_DISENGAGED,
    DEVICE_ENGAGED,
    userEvents,
} from '../../common/constants/Constants';

export const NUMBER_OF_EVENTS_BEFORE_SCROLL = 20;
export const MAX_RETRIES = 5;

export const defaultEventsTypeValues = [
    { key: 1, label: 'Critical' },
    { key: 2, label: 'Info' },
    { key: 3, label: 'Warning' },
];

const getNotesForEvent = (event, notes = []) => notes?.filter((note) => note.eventId === event.id);

export const getSiteForEvent = (event, sites = []) =>
    sites?.filter((site) => site && event && site.id === event.siteId);

export const buildUserColumn = (event) => (userEvents.includes(event.securityActionId) ? event.actorName : '-');

export const buildEventColumn = (event, translateEventName) =>
    (event.securityActionId === DEVICE_ENGAGED || event.securityActionId === DEVICE_DISENGAGED) &&
    event.userDefinedMessage
        ? event.userDefinedMessage
        : translateEventName(event.securityActionId, event.detail, event.objectName, event.deviceType);

export const categorizeEvents = (config, events) => {
    if (config) {
        return events?.map((event) => {
            let eventType;
            const { securityActionId } = event;

            if (config.critical.includes(securityActionId)) {
                eventType = 'Critical';
            } else if (config.warning.includes(securityActionId)) {
                eventType = 'Warning';
            } else {
                // treat anything else as info
                eventType = 'Info';
            }

            return { ...event, type: eventType };
        });
    }
    console.warn('Categorize Events was invoked before the config and event categories were available');
    return [];
};

export const splitHourlyEventsCountInCategories = (config, hourlyEventsArray) => {
    if (config && hourlyEventsArray) {
        const categories = {
            critical: [],
            warning: [],
            info: [],
        };

        hourlyEventsArray.forEach((hourCount) => {
            if (hourCount && hourCount.actions) {
                let hourlyCriticalEventCount = {
                    hour: hourCount.hour,
                    count: 0,
                };
                let hourlyWarningEventCount = {
                    hour: hourCount.hour,
                    count: 0,
                };
                let hourlyInfoEventCount = { hour: hourCount.hour, count: 0 };

                hourCount.actions.forEach((action) => {
                    if (config.critical.includes(action.securityActionId)) {
                        hourlyCriticalEventCount.count += action.count;
                    } else if (config.warning.includes(action.securityActionId)) {
                        hourlyWarningEventCount.count += action.count;
                    } else {
                        // treat anything else as info
                        hourlyInfoEventCount.count += action.count;
                    }
                });

                if (hourlyCriticalEventCount.count > 0) {
                    categories.critical.push(hourlyCriticalEventCount);
                }

                if (hourlyWarningEventCount.count > 0) {
                    categories.warning.push(hourlyWarningEventCount);
                }

                if (hourlyInfoEventCount.count > 0) {
                    categories.info.push(hourlyInfoEventCount);
                }
            }
        });

        return categories;
    }
};

const generateDefaultValues = () => new Array(48).fill(0);

export const getDefaultChartData = (theme) => [
    {
        name: 'Critical',
        type: 'column',
        color: theme.palette.error.main,
        data: generateDefaultValues(),
        visible: true,
    },
    {
        name: 'Warning',
        type: 'column',
        color: theme.palette.warning.main,
        data: generateDefaultValues(),
        visible: true,
    },
    {
        name: 'Info',
        type: 'column',
        color: theme.palette.info.main,
        data: generateDefaultValues(),
        visible: true,
    },
    {
        name: 'Last Week Critical',
        type: 'spline',
        dashStyle: 'shortdot',
        marker: {
            enabled: false,
        },
        color: theme.palette.error.main,
        shadow: true,
        data: generateDefaultValues(),
        visible: true,
    },
    {
        name: 'Last Week Warning',
        type: 'spline',
        dashStyle: 'shortdot',
        marker: {
            enabled: false,
        },
        color: theme.palette.warning.main,
        shadow: true,
        data: generateDefaultValues(),
        visible: true,
    },
    {
        name: 'Last Week Info',
        type: 'spline',
        dashStyle: 'shortdot',
        marker: {
            enabled: false,
        },
        color: theme.palette.info.main,
        shadow: true,
        data: generateDefaultValues(),
        visible: true,
    },
    {
        name: 'Last Week Info',
        type: 'spline',
        dashStyle: 'shortdash',
        marker: {
            enabled: false,
        },
        color: theme.palette.colors.grey200,
        shadow: true,
        data: generateDefaultValues(),
        visible: true,
    },
];

export const generateHoursTimeline = (selectedDate) => {
    const time = selectedDate ? toDate(selectedDate) : new Date();
    const xAxis = {};
    let key = startOfHour(time).getTime();
    xAxis[key] = 0;

    for (let i = 46; i >= 0; i--) {
        const lastInterval = Object.keys(xAxis).pop();
        time.setHours(time.getHours() - 1);
        let key = startOfHour(time).getTime();
        if (lastInterval === key.toString()) {
            time.setHours(time.getHours() - 2);
            const mimicSprinDSTKey = startOfHour(time).getTime();
            xAxis[mimicSprinDSTKey] = 0;
        } else {
            xAxis[key] = 0;
        }
    }
    return xAxis;
};

export const createTableRow = (event, notes, sites, translateEventName) => {
    const eventNotes = getNotesForEvent(event, notes);
    const site = getSiteForEvent(event, sites);
    return {
        id: event.id,
        type: event.type,
        user: buildUserColumn(event),
        event: buildEventColumn(event, translateEventName),
        doorOrDevice: event.securityActionId === COMMAND_TRIGGERED_BY_USER ? '-' : event.objectName,
        site: get(event, 'siteName') ? get(event, 'siteName') : '',
        siteId: event.siteId,
        timestamp: event.occurred,
        media:
            (event.deviceCameras !== undefined && event.deviceCameras.length > 0) ||
            event.enrichmentMetadata?.precachedClips?.length > 0,
        notes: eventNotes && eventNotes.length,
        timeZone: site && site[0] && site[0].address && site[0].address.timeZone,
        status: event.type !== 'Info' ? findMostRecentStatus(eventNotes) : null,
        anomaly: get(event, 'enrichmentMetadata.anomalyData')
            ? get(event, 'enrichmentMetadata.anomalyData')
            : get(event, 'enrichmentMetadata'),
    };
};

export const generateIntervals = (
    startDateTime,
    endDateTime,
    hoursInBetween = 24,
    startFromEnd = false,
    extraHours = () => false,
    ignoreHoursInBetween = false
) => {
    const hours = eachHourOfInterval({
        start: startDateTime,
        end: endDateTime,
    });

    const hoursChain = memoize(
        flow([
            thru((hours) => {
                if (startFromEnd) {
                    return reverse(hours);
                }
                return hours;
            }),
        ])
    );

    const extraHoursChain = flow([hoursChain, filter(extraHours)])(hours);

    const regularHoursChain = flow([
        hoursChain,
        ignoreHoursInBetween ? identity : chunk(hoursInBetween),
        map(head),
        // Deduplicate the extra tick (start of day or something else)
        filter(negate(extraHours)),
    ]);

    return flow([regularHoursChain, concat(extraHoursChain), orderBy(identity, startFromEnd ? 'desc' : 'asc')])(hours);
};

const getDifferenceInTimezoneOffsets = (date1, date2) => {
    if (!date1 || !date2) {
        return 0;
    }

    return (date1.getTimezoneOffset() - date2.getTimezoneOffset()) / 60;
};

export const generateLines = (
    startDateTime,
    endDateTime,
    hoursInBetween,
    firstTickPadding = 5,
    lastTickPadding = 8,
    formatDate,
    formatTime
) => {
    const generateLabel = (text) => ({
        text,
    });

    return (
        generateIntervals(
            startDateTime,
            endDateTime,
            hoursInBetween,
            true,
            (date) => {
                if (!date) {
                    return false;
                }

                const ratio =
                    (differenceInHours(date, startOfDay(date)) +
                        getDifferenceInTimezoneOffsets(startOfDay(date), date)) /
                    hoursInBetween;
                return isStartOfDay(date) || Math.floor(ratio) === Math.ceil(ratio);
            },
            true
        )
            ?.filter((date) => {
                const startDiff =
                    differenceInHours(date, startDateTime) + getDifferenceInTimezoneOffsets(startDateTime, date);
                const endDiff =
                    differenceInHours(endDateTime, date) + getDifferenceInTimezoneOffsets(date, endDateTime);
                return startDiff > firstTickPadding && endDiff >= lastTickPadding;
            })
            .map((date) => {
                const diff =
                    differenceInHours(date, startDateTime) + getDifferenceInTimezoneOffsets(startDateTime, date);
                const startOfDay = isStartOfDay(date);
                return {
                    value: diff - 0.5,
                    label: {
                        text: startOfDay ? formatDate(date) : formatTime(date),
                        style: {
                            fontWeight: startOfDay ? 'bold' : 'normal',
                        },
                    },
                };
            })
            // add the "now" tick
            .concat({
                value:
                    differenceInHours(endDateTime, startDateTime) -
                    0.5 +
                    getDifferenceInTimezoneOffsets(startDateTime, endDateTime),
                label: generateLabel(formatTime(endDateTime)),
            })
    );
};

export const isMessageWithinEventHour = (messageTime, eventTime) =>
    isWithinInterval(toDate(messageTime), {
        start: toDate(eventTime),
        end: endOfHour(toDate(eventTime)),
    });
