import * as _ from 'lodash';
import { lockdownApi } from '../../../common/webApis';
import { ALL_SCENARIOS_FILTER_ENABLED } from '../../../common/constants/Constants';

export const getEmergencyScenarios = async (
    pageSize,
    offset,
    sortField,
    direction,
    filters,
    showEgressScenarios,
    t
) => {
    let response = null;
    let modifiedData = null;
    let queryParams = {
        pageSize,
        offset,
    };

    if (!showEgressScenarios) {
        queryParams.filter = filters?.[0]?.value;

        response = await lockdownApi.getLockDownScenarios({ queryParams });
    } else {
        queryParams.filter = ALL_SCENARIOS_FILTER_ENABLED;

        response = await lockdownApi.getLockDownScenarios({ queryParams });

        const egressType = (item) =>
            item?.threatLevelTypeId === 3 ? t('Page.emergency-scenario-create.page.form.scenarioType.egress') : '';

        modifiedData = response?.data?.map((item) => {
            return {
                ...item,
                type:
                    item?.threatLevelTypeId === 2
                        ? t('Page.emergency-scenario-create.page.form.scenarioType.lockdown')
                        : egressType(item),
            };
        });
    }

    return {
        count: response?.count,
        totalCount: response?.count,
        lockdownScenarios: showEgressScenarios ? modifiedData : response?.data,
    };
};

export const orderListBy = (list, field, sort) => {
    return _.orderBy(list, [(scenario) => scenario[field].toLowerCase()], [sort]);
};

export const getDifferences = (object, base) => {
    const result = {};

    for (const [key, value] of Object.entries(object)) {
        if (_.isArray(value) && _.isArray(base[key])) {
            const sorted = value.sort();
            if (!_.isEqual(sorted, base[key].sort())) {
                result[key] = value;
            }
        } else {
            if (value !== base[key]) {
                result[key] = value;
            }
        }
    }
    return result;
};

export const getErrorMessages = (data, t, showEgressScenarios = false) => {
    const errors = {};
    // if lockdown does not have a name, or if it contains only spaces
    if (!data.name || !data.name?.replace(/\s/g, '').length) {
        errors.nameInput = t('Field.validation.required');
    }
    if (!data.selectedDoors || data.selectedDoors?.length === 0) {
        errors.selectedDoors = t('Field.validation.required');
    }

    if (showEgressScenarios && !data.scenarioType) {
        errors.scenarioType = t('Field.validation.required');
    }
    return errors;
};
export const initialFilters = {
    name: '',
    siteIds: [],
};

export const buildGqlFilters = (filters, scenarioTypeId) => {
    let localFilters = [];
    localFilters.push({
        field: 'name',
        type: 'field',
        value: filters?.name.toLowerCase(),
    });
    if (filters?.siteIds.length) {
        localFilters.push({
            field: 'siteId',
            type: 'field',
            value: filters?.siteIds?.map((site) => site.id).toString(),
        });
    }
    if (scenarioTypeId) {
        localFilters.push({
            field: 'scenarioTypeId',
            type: 'field',
            value: scenarioTypeId.toString(),
        });
    }
    return localFilters;
};

export const triggersMapper = (item) => ({
    id: item.triggerSourceOid,
    name: item.triggerSourceName,
    controlPanelName: item.triggerSourcePanelName,
    controlPanelId: item.triggerSourcePanelId,
    siteName: item.triggerSourceSiteName,
    siteId: item.triggerSourceSiteId,
});
