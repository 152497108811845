import { makeStyles } from '@brivo/react-components';

export const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        width: '100%',
        '& .MuiDialogContent-root': {
            overflowY: 'unset',
        },
    },
    dialogContent: {
        width: 912,
    },
    deleteButton: {
        '& .MuiButtonBase-root': {
            marginTop: 18,
        },
    },
    label: {
        marginBottom: 6,
    },
    tableLegendWrapper: {
        display: 'flex',
        justifyContent: 'left',
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60px',
    },
    validationInfo: {
        color: '#B7242A',
        fontSize: '.8rem',
        fontStyle: 'italic',
        lineHeight: '1.1rem',
        textAlign: 'center',
        marginTop: 18,
    },
    layoutFields: {
        marginBottom: 16,
        '& .MuiAutocomplete-listbox': {
            maxHeight: 207,
            overflow: 'hidden',
            overflowY: 'scroll',
        },
    },
    checkbox: {
        marginBottom: 16,
    },
    menuIcon: {
        fontSize: '0.75rem',
        height: '1.5rem',
        width: '1.5rem',
        justifyContent: 'right',
        color: theme.palette.colors.grey600,
    },
    closeDialog: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    noResult: {
        color: theme.palette.text.primary,
        textAlign: 'center',
        padding: '126px 35px 158px 35px',
    },

    sitesSelector: {
        '& .MuiAutocomplete-listbox': {
            maxHeight: 207,
            overflow: 'hidden',
            overflowY: 'scroll',
        },

        '& .MuiChip-root.MuiChip-deletable': {
            maxWidth: '85px',
        },
    },
    actionError: {
        color: theme.palette.type === 'dark' ? theme.palette.colors.red300 : theme.palette.colors.red800,
    },
}));
