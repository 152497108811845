export const JOURNAL_PAGE_SIZE = 20;
export const NUMBER_OF_TYPEAHEAD_OPTIONS = 10;

export const EVENT_TARGET = {
    Administrator: 'Administrator',
    User: 'User',
    Device: 'Device',
    Group: 'Group',
    Site: 'Site',
    Holiday: 'Holiday',
    Schedule: 'Schedule',
};

export const initialJournalFilterValues = {
    securityActionIds: [],
    eventActor: null,
    eventTarget: null,
    selectedDate: null,
};
