import React from 'react';
import { CountChip } from '@brivo/react-components';
import { Typography, Grid } from '@brivo/react-components';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const CategorySummary = ({ time, eventCount, isOpen }) => {
    const classes = styles({ isOpen });
    const { i18n } = useTranslation();

    const formattedTime = new Date(time).toLocaleString(i18n.language, { hour: 'numeric', minute: 'numeric' });
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.inlineDiv}>
                    <Typography className={classes.categoryTime} data-testid={`hour-bin-${Date.parse(time)}`}>
                        {formattedTime}
                    </Typography>
                </div>
                <div className={classes.inlineDiv}>
                    <CountChip
                        number={eventCount.toLocaleString()}
                        size="small"
                        className={classes.countChip}
                        type={isOpen ? 'primary' : 'secondary'}
                    ></CountChip>
                </div>
            </Grid>
        </Grid>
    );
};

export default CategorySummary;
