import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    LOCKDOWN_CLEAR_LOCKDOWN,
    LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
    LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
    LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
    LOCKDOWN_INITIATE_LOCKDOWN,
    ONAIR_GROUP_BASED_LOCKDOWN,
} from '@common/constants/Constants';
import { IconButton, MuiSettingsIcon } from '@brivo/react-components';
import { useFlagClient } from '@brivo/onairplus-services';

import styles from './styles';
import { INITIATE_LOCKDOWN_PERMISSION, CLEAR_LOCKDOWN_PERMISSION } from '@common/constants/Constants';

export default function NavigationBottomSection({
    userAvatar: UserAvatar,
    lockDownItems,
    activeLockdown,
    user,
    isSmallerThan600Width,
    checkPermissions,
    lockdownEnabled,
    groupLockdownItems,
    showNewActivationPopup,
}) {
    const mobileStyle = {
        padding: isSmallerThan600Width ? '0.75rem' : '1.375rem 1.5rem',
        lockdownButtonsPadding: isSmallerThan600Width ? '0.75rem' : '1.375rem',
        userPadding: isSmallerThan600Width ? '0.75rem' : '1.25rem',
    };
    const classes = styles(mobileStyle);
    const { t } = useTranslation();
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient.variation('emergency-scenarios', false);

    const lockdownInitiateItem = lockDownItems[0];

    const lockDownButtonText = activeLockdown
        ? t('Navigation.user-menu.lockdown.button.view')
        : t('Navigation.user-menu.lockdown.button.activate');

    const emergencyScenariosButtonText = t('Navigation.user-menu.emergency-scenarios.button.activate');

    const canInitiateOrClearLockdown =
        checkPermissions({
            userPermissions: user.permissions,
            anyOfPermissions: [
                INITIATE_LOCKDOWN_PERMISSION,
                CLEAR_LOCKDOWN_PERMISSION,
                LOCKDOWN_INITIATE_LOCKDOWN,
                LOCKDOWN_CLEAR_LOCKDOWN,
            ],
        }) && lockdownInitiateItem.isVisible;

    const canConfigureLockdown =
        checkPermissions({
            userPermissions: user.permissions,
            anyOfPermissions: [
                CLEAR_LOCKDOWN_PERMISSION,
                LOCKDOWN_CREATE_LOCKDOWN_SCENARIO,
                LOCKDOWN_EDIT_LOCKDOWN_SCENARIO,
                LOCKDOWN_DELETE_LOCKDOWN_SCENARIO,
            ],
        }) && lockdownInitiateItem.isVisible;

    const canActivateOrViewGroupLockdown = checkPermissions({
        userPermissions: user.permissions,
        anyOfPermissions: [ONAIR_GROUP_BASED_LOCKDOWN],
    });

    return (
        <nav className={clsx('NavigationBottomSection', classes.section)}>
            {(canInitiateOrClearLockdown || canConfigureLockdown) && (
                <div className={classes.lockdownButtons}>
                    {canInitiateOrClearLockdown && (
                        <a
                            id={lockDownItems[0].id}
                            className={clsx(
                                classes.lockDownButton,
                                activeLockdown ? classes.activeLockdown : classes.inactiveLockdownButton,
                                'u-trim'
                            )}
                            href={lockDownItems[0].url}
                            onClick={(e) => {
                                if (!lockDownItems[0].link) {
                                    e.preventDefault();
                                    lockDownItems[0].onClick();
                                }
                            }}
                        >
                            <span
                                title={showNewActivationPopup ? emergencyScenariosButtonText : lockDownButtonText}
                                className="u-trim"
                            >
                                {showNewActivationPopup ? emergencyScenariosButtonText : lockDownButtonText}
                            </span>
                        </a>
                    )}

                    {canConfigureLockdown && !emergencyScenariosFlag && (
                        <IconButton
                            id={lockDownItems[1].id}
                            className={classes.lockDownConfigButton}
                            onClick={(e) => {
                                if (!lockDownItems[1].link) {
                                    e.preventDefault();
                                    lockDownItems[1].onClick();
                                }
                            }}
                            icon={<MuiSettingsIcon />}
                            type="secondary"
                        />
                    )}
                </div>
            )}
            {!lockdownEnabled && canActivateOrViewGroupLockdown && (
                <div className={classes.lockdownButtons}>
                    <a
                        id={groupLockdownItems[0].id}
                        className={clsx(
                            classes.lockDownButton,
                            activeLockdown ? classes.activeLockdown : classes.inactiveLockdownButton,
                            'u-trim'
                        )}
                        href={groupLockdownItems[0].url}
                        onClick={(e) => {
                            if (!groupLockdownItems[0].link) {
                                e.preventDefault();
                                groupLockdownItems[0].onClick();
                            }
                        }}
                    >
                        <span title={lockDownButtonText} className="u-trim">
                            {lockDownButtonText}
                        </span>
                    </a>
                </div>
            )}
            <div className={classes.userAvatar}>
                <UserAvatar showName={true} newNavigationMobile={isSmallerThan600Width} />
            </div>
        </nav>
    );
}
