import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            d="M10.164 8.248l1.354 1.354 1.352-1.354c-.345-.354-.825-.566-1.352-.566-.529 0-1.009.212-1.354.566zM7.45 5.536l1.371 1.37c.716-.722 1.664-1.146 2.7-1.146 1.036 0 1.982.421 2.699 1.14L15.59 5.53c-1.075-1.078-2.515-1.689-4.07-1.689-1.53 0-2.997.626-4.07 1.696z"
            transform="translate(-507 -151) translate(328 127) translate(163) translate(16 24) translate(2.92 .92)"
        />
        <path
            d="M11.522 0C8.96 0 6.535 1.023 4.739 2.821L6.094 4.18c1.437-1.44 3.378-2.259 5.428-2.259s3.988.818 5.424 2.253l1.356-1.355C16.506 1.022 14.083 0 11.522 0z"
            transform="translate(-507 -151) translate(328 127) translate(163) translate(16 24) translate(2.92 .92)"
        />
        <path
            d="M9.6 16.32H1.92V8.64h4.8V6.72h-4.8V4.8h1.92V2.88H1.92C.861 2.88 0 3.74 0 4.8v15.36c0 1.06.861 1.92 1.92 1.92H9.6c1.059 0 1.92-.86 1.92-1.92v-9.6H9.6v5.76zm-7.68 3.84v-1.92H9.6v1.92H1.92z"
            transform="translate(-507 -151) translate(328 127) translate(163) translate(16 24) translate(2.92 .92)"
        />
    </React.Fragment>,
    'MobilePass'
);
