import { useTranslation } from 'react-i18next';

import { makeStyles } from '@brivo/react-components';

const useStyles = makeStyles(() => ({
    showingBox: {
        height: '60px',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
    },

    countText: {
        fontWeight: '600',
    },
    addTab: { marginRight: '.4rem' },
}));

const TableLegend = ({ x, y, filtered, label, isTableWithPagination }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (x < 0 || (isTableWithPagination && y < 0)) {
        return null;
    }

    return (
        <div className="TableLegend">
            <div className={classes.showingBox}>
                <span className={classes.addTab}>{t('Component.table-legend.showing')}</span>
                <span id="TableLegend__filtered-count" className={classes.countText + ' ' + classes.addTab}>
                    {x?.toLocaleString()}
                </span>
                {x > 0 || isTableWithPagination ? (
                    <span className={classes.addTab}>
                        <span className={classes.addTab}>{t('Component.table-legend.of')}</span>

                        <span id="TableLegend__total-count" className={classes.countText}>
                            {y?.toLocaleString()}
                        </span>
                    </span>
                ) : null}
                <span id="TableLegend__items-label">{label}</span>
                {filtered ? <span id="TableLegend__filtered"> ({t('Component.table-legend.filtered')})</span> : null}
            </div>
        </div>
    );
};
export default TableLegend;
