import { useState } from 'react';
import roverQueries from './kpiQueries';
import { cloneDeep } from 'lodash';

const deepSubstitute = (obj, substitutions) => {
    if (substitutions !== undefined && substitutions !== null && obj !== undefined && obj !== null) {
        if (obj?.source === 'ui_variables') {
            return cloneDeep(substitutions[obj.id]);
        }
        if (typeof obj === 'object') {
            const newObject = {};
            for (let [key, value] of Object.entries(obj)) {
                if (typeof value === 'object') {
                    newObject[key] = deepSubstitute(value, substitutions);
                }
            }
            Object.assign(obj, newObject);
        }
    }
    return obj;
};

const fetchNamedQueryByKey = (key) => {
    const actualKey = key !== undefined && key !== null ? key : null;
    if (typeof actualKey === 'string') {
        return roverQueries[actualKey];
    } else {
        return actualKey;
    }
};

const useRoverQueries = (key, variables) => {
    const queryObject = fetchNamedQueryByKey(key);
    const [queryState, setQueryState] = useState(deepSubstitute(queryObject, variables));
    const updateQuery = (newKey, variables) => {
        if (typeof newKey === 'function') {
            const queryContainer = {
                query: null,
            };
            setQueryState((currentQuery) => {
                const queryObject = fetchNamedQueryByKey(newKey(currentQuery));
                const queryAfterSubstitutions = deepSubstitute(queryObject, variables);
                queryContainer.query = queryAfterSubstitutions;
                return queryAfterSubstitutions;
            });
            return queryContainer.query;
        } else {
            const queryObject = fetchNamedQueryByKey(newKey);
            const queryAfterSubstitutions = deepSubstitute(queryObject, variables);
            setQueryState(queryAfterSubstitutions);
            return queryAfterSubstitutions;
        }
    };
    return [queryState, updateQuery];
};

export default useRoverQueries;
