import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33472 2.83734C3.33472 4.308 4.53072 5.504 6.00138 5.504C7.47205 5.504 8.66805 4.308 8.66805 2.83734C8.66805 1.36734 7.47205 0.17067 6.00138 0.17067C4.53072 0.17067 3.33472 1.36734 3.33472 2.83734ZM4.66805 2.83734C4.66805 2.10134 5.26605 1.504 6.00138 1.504C6.73672 1.504 7.33472 2.10134 7.33472 2.83734C7.33472 3.57267 6.73672 4.17067 6.00138 4.17067C5.26605 4.17067 4.66805 3.57267 4.66805 2.83734Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.66803 10.1707V10.8373H11.3347V10.1707C11.3347 7.81534 9.14136 6.17067 6.00136 6.17067C2.86136 6.17067 0.66803 7.81534 0.66803 10.1707ZM9.89603 9.504H2.10736C2.50603 8.29867 3.9967 7.504 6.00203 7.504C8.00736 7.504 9.49736 8.29867 9.89603 9.504Z"
        />
    </React.Fragment>,
    'NewUsersIcon'
);
