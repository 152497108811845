import { createAuth0Client } from '@auth0/auth0-spa-js';
import { get } from 'lodash';
import { getUrlQueryParam } from './Utils';
import {
    ALT_AUTH_TESTING,
    ALT_AUTHENTICATION_URL,
    GLOBAL_LOGIN_TESTING,
    GLOBAL_LOGIN_URL,
    APP_ROOT_URL,
    APPLICATION_DOMAIN,
    AUTH0_AUDIENCE,
    AUTH0_CALLBACK_URL,
    AUTH0_CLIENT_ID,
    AUTH0_CONNECTION,
    AUTH0_DOMAIN,
    IAMSVC_DOMAIN,
    MATCH_ACT_REQUESTS,
} from '../constants/Constants';
import { history, store } from '../../redux/Store';
import { SET_TOKEN } from '../../redux/types/Auth.types';
import { APP_SSO_LOGGED_OUT_URL } from '@common/constants/Constants';
import UserTracking from './UserTracking';
import { decodeJwt } from 'jose';
import { redirect } from '../constants/Constants';

//constant strings for token parts
const initalPartToken = 'https://brivo.com/';
const emailToken = initalPartToken + 'email';
const usernameToken = initalPartToken + 'username';
const subLevelToken = initalPartToken + 'subscription_level';
const accountIdToken = initalPartToken + 'account_id';
const onairOidToken = initalPartToken + 'onair_user_id';
const remoteSubToken = initalPartToken + 'remote_access_sub';
const subToken = 'sub';
const adminInfo = 'adminInfo.';
const infoEmail = adminInfo + 'email';
const infoUsername = adminInfo + 'username';
const accountId = 'account.accountId';
const id = 'id';

export const LS_ENTERPRISE_CONNECTION_KEY = 'enterpriseConnectionKey';
export const LS_ENTERPRISE_CONNECTION_REQUIRED = 'enterpriseConnectionRequired';
export const LS_REMOTE_CODE = 'remoteCode';
export const LS_ACCESS_TOKEN = 'accessToken';
export const LS_LOGGED_IN_ACCOUNT_ID = 'loggedInAccountId';
export const LS_ALT_AUTH_TOKEN_CREATED = 'altAuthTokenCreated';
export const LS_AUTH_ERROR = 'authError';
const DEALER_NAME = 'dealerName';

// constants for auth0 client cache
export const AUTH0_CACHE_SPA_PREFIX = '@@auth0spajs@@::';
export const AUTH0_CACHE_USER_SUFFIX = '@@user@@';
// this part can be used to identify the key that is used across both versions of the auth0 client
export const AUTH0_CACHE_COMMON_IDENTIFIER = 'https';

export class Auth {
    auth0 = null;
    accessToken = null;
    idToken = null;
    decodedToken = null;
    userPermissions = [];
    thirdPartySetupComplete = false;
    identified = false;
    gaSet = false;
    user = {};
    flagClient = null;
    remoteCode = null;
    logoutPath = '?loggingOut=true';
    ready = false;
    eventListeners = {
        ready: [],
    };

    async initialize() {
        await this.ensureAuth0ClientExists();

        if (this.isAltAuthOn()) {
            this.initAltAuth();
        } else {
            await this.initAuth0();
        }

        this.ready = true;
        this.eventListeners.ready.forEach((cb) => cb());
    }

    isReady() {
        return this.ready;
    }

    on(event, callback) {
        const eventNames = Object.keys(this.eventListeners);
        if (!eventNames.includes(event)) throw new Error(`"${event}" is not a valid Auth event.`);

        this.eventListeners[event].push(callback);
    }

    setFlagClient(client) {
        this.flagClient = client;
    }

    goToAltLogin() {
        UserTracking.track('redirecting user to alt login');
        if (this.getAuthError()) {
            const errorString = this.getAuthError();
            this.removeAuthError();
            UserTracking.track('redirecting user to alt login with error');
            redirect(ALT_AUTHENTICATION_URL + '&error=' + errorString);
        } else {
            redirect(ALT_AUTHENTICATION_URL);
        }
    }

    goToGlobalLogin() {
        if (this.getAuthError()) {
            const errorString = this.getAuthError();
            this.removeAuthError();
            UserTracking.track('redirecting user to global login with error');
            redirect(GLOBAL_LOGIN_URL + '&' + new URLSearchParams({ error: errorString }).toString());
        } else {
            UserTracking.track('redirecting user to global login');
            redirect(GLOBAL_LOGIN_URL);
        }
    }

    setAuthError(authError) {
        window.localStorage.setItem(LS_AUTH_ERROR, authError);
    }

    getAuthError() {
        const authError = window.localStorage.getItem(LS_AUTH_ERROR);
        return authError || null;
    }

    removeAuthError() {
        window.localStorage.removeItem(LS_AUTH_ERROR);
    }

    setRemoteCode(remoteCodeIn) {
        localStorage.setItem(LS_REMOTE_CODE, remoteCodeIn);
        this.remoteCode = remoteCodeIn;
    }
    getRemoteCode() {
        if (!this.remoteCode && localStorage.getItem(LS_REMOTE_CODE)) {
            this.setRemoteCode(localStorage.getItem(LS_REMOTE_CODE));
        }
        return this.remoteCode;
    }

    remoteAccessQueryParamExists() {
        return getUrlQueryParam('remote_access') === 'true';
    }

    customErrorPattern = /\[(.*)] - (.*)/;
    async handleAuthentication(requestUri, connectionKey, required) {
        UserTracking.track('Handling authentication');
        if (!this.isAltAuthOn()) {
            return this.auth0
                .handleRedirectCallback()
                .then(async () => {
                    UserTracking.track('Redirect Callback');
                    UserTracking.setNewUserHasPerformedLogIn();
                    this.removeAuthError();
                    await this.setSession();
                    if (connectionKey) {
                        window.localStorage.setItem(LS_ENTERPRISE_CONNECTION_KEY, connectionKey);
                        window.localStorage.setItem(LS_ENTERPRISE_CONNECTION_REQUIRED, required);
                    }
                })
                .catch((err) => {
                    UserTracking.track('AuthError detected');
                    UserTracking.error(err.error_description);
                    UserTracking.error(err.error);
                    window.localStorage.removeItem(LS_ENTERPRISE_CONNECTION_KEY);
                    window.localStorage.removeItem(LS_ENTERPRISE_CONNECTION_REQUIRED);
                    window.localStorage.removeItem(LS_REMOTE_CODE);
                    //Custom Auth0 Error handling https://brivosys.atlassian.net/wiki/spaces/UMT/pages/1828225088/Handling+Errors+from+the+ULP
                    const errorMatch = err.error_description && this.customErrorPattern.exec(err.error_description);
                    if (errorMatch) {
                        const errorCode = errorMatch[1];
                        errorCode && this.setAuthError(errorCode);
                    } else {
                        err.error && this.setAuthError(err.error);
                    }
                    this.logout();
                });
        } else {
            const queryParams = new URLSearchParams(requestUri.location.search);
            if (!queryParams.get('error') && queryParams.get('code')) {
                const token = queryParams.get('code');
                this.setSessionAltAuth(token);
                window.localStorage.setItem(LS_ENTERPRISE_CONNECTION_REQUIRED, false);
            } else {
                this.setAuthError(queryParams.get('error'));
                this.logout();
            }
        }
    }

    async login({ enterpriseDetails, accountId }) {
        UserTracking.track('login attempt');
        window.localStorage.removeItem(LS_LOGGED_IN_ACCOUNT_ID);

        const prevEnterpriseConnReq = window.localStorage.getItem(LS_ENTERPRISE_CONNECTION_REQUIRED);
        const prevEnterpriseConnKey = window.localStorage.getItem(LS_ENTERPRISE_CONNECTION_KEY);
        const dealerName = window.localStorage.getItem(DEALER_NAME);
        const globalLoginEmail = getUrlQueryParam('email');
        const windowUrl = new URL(window.location);
        this.checkForRemoteParam(windowUrl);
        const hasNotAnyInternalParamsSet = !globalLoginEmail && !enterpriseDetails && !accountId && !this.remoteCode;
        const referer = getUrlQueryParam('referer');
        const referrerUrl = referer ? referer : document.referrer;
        const matchActRequests = new RegExp(MATCH_ACT_REQUESTS, 'i');

        if (prevEnterpriseConnReq && prevEnterpriseConnKey && !enterpriseDetails) {
            UserTracking.track(
                'Previously logged in with SSO details, redirecting to ' +
                    'the correct SSO login and no new details provided'
            );
            history.push(`/auth/sso/${prevEnterpriseConnKey}`);
        } else {
            UserTracking.track('Previous SSO login details are invalid or not present');

            if (this.isAltAuthOn()) {
                this.goToAltLogin();
            } else if (this.isGlobalLoginOn() && hasNotAnyInternalParamsSet && !matchActRequests.test(referrerUrl)) {
                this.goToGlobalLogin();
            } else {
                window.localStorage.removeItem(LS_ENTERPRISE_CONNECTION_KEY);
                window.localStorage.removeItem(LS_ENTERPRISE_CONNECTION_REQUIRED);
                let connection = AUTH0_CONNECTION;
                let connectionLink;
                let required = false;
                if (enterpriseDetails) {
                    connection = enterpriseDetails.connectionName;
                    connectionLink = enterpriseDetails.connectionLinkName;
                    required = enterpriseDetails.required;
                }
                const authError = this.getAuthError()
                    ? {
                          error_type: this.getAuthError(),
                      }
                    : {};

                const options = {
                    connection: connection,
                    dealerName: dealerName,
                    redirect_uri: `${AUTH0_CALLBACK_URL}?request_uri=${history.location.pathname}${
                        connectionLink ? `&connectionKey=${connectionLink}&required=${required}` : ''
                    }`,
                    account_id: accountId,
                    ...authError,
                };

                if (globalLoginEmail) {
                    UserTracking.track('Global Login continued with email');
                    options.email = globalLoginEmail;
                }

                if (this.remoteCode) {
                    UserTracking.track('Remote login initiated');
                    options.remote_access = this.remoteCode;
                }

                return this.auth0.loginWithRedirect({
                    authorizationParams: options,
                });
            }
        }
    }

    checkForRemoteParam(windowUrl) {
        const requestUri = windowUrl.searchParams.get('request_uri');
        const remoteParamCheck = requestUri ? requestUri.location?.search : history.location?.search;
        if (remoteParamCheck) {
            const turnIntoParams = new URLSearchParams(remoteParamCheck);
            const remoteParam = turnIntoParams.get('remote_access');
            if (remoteParam) {
                this.setRemoteCode(remoteParam);
            }
        }
    }

    async changePassword() {
        UserTracking.track('Change Password request');
        try {
            const result = await fetch(`${IAMSVC_DOMAIN}/api/acctmgmt/administrators/resetPassword`, {
                method: 'POST',
                headers: new Headers({
                    'content-type': 'application/json',
                }),
                body: JSON.stringify({
                    client_id: AUTH0_CLIENT_ID,
                    email: this.getEmail(),
                }),
            });
            if (result) {
                if (result.status !== 201) {
                    throw new Error(result.statusText);
                }
                // Ideally, we'd flash a message to the user
                // letting them know there was some kind of success
                // then log the user out
                return Promise.resolve(true);
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    // Auth0 API Token
    async getAccessToken() {
        if (!this.isAltAuthOn()) {
            if (this.altAuthTokenExists()) {
                this.logout();
                return null;
            }

            const options = {
                authorizationParams: {
                    redirect_uri: `${AUTH0_CALLBACK_URL}?request_uri=${history.location.pathname}`,
                },
            };
            if (this.getAccountId()) {
                options.authorizationParams.account_id = this.getAccountId();
            }

            if (this.getRemoteCode()) {
                options.authorizationParams.remote_access = this.getRemoteCode();
            }

            try {
                this.accessToken = await this.auth0.getTokenSilently(options);
                return this.accessToken;
            } catch (err) {
                UserTracking.track('Failed to get Access Token');
                await this.renewSession();
            }
        } else {
            await this.renewSession();
            return this.accessToken;
        }
    }

    getAltToken() {
        return this.accessToken;
    }

    // Decoded JWT
    getDecodedToken() {
        return this.decodedToken;
    }

    getEmail = () => get(this.user, infoEmail, '') || get(this.decodedToken, emailToken, '');

    getUsername = () => get(this.user, infoUsername, '') || get(this.decodedToken, usernameToken, '');

    getSubscriptionLevel() {
        return this.getDecodedToken() ? this.getDecodedToken()[subLevelToken] : '';
    }

    getAccountId = () =>
        get(this.user, accountId, '') ||
        get(this.decodedToken, accountIdToken, null) ||
        this.getAccountIdFromLocalStorage();

    getAccountIdFromLocalStorage = () => {
        return this.accessTokenExists() ? window.localStorage.getItem(LS_LOGGED_IN_ACCOUNT_ID) : null;
    };

    getUserId = () => get(this.user, id, '') || get(this.decodedToken, onairOidToken, '');

    getSubject() {
        return this.getDecodedToken()[remoteSubToken] || this.getDecodedToken()[subToken] || '';
    }

    async setSession() {
        UserTracking.debugTrack('Setting session');
        const windowUrl = new URL(window.location);
        const redirectPath =
            windowUrl.searchParams.get('request_uri') ||
            history.location.pathname + history.location.search ||
            APP_ROOT_URL;
        this.checkForRemoteParam(windowUrl);

        const options = {
            authorizationParams: {
                redirect_uri: `${AUTH0_CALLBACK_URL}?request_uri=${redirectPath}`,
                timeoutInSeconds: 10,
            },
        };
        if (this.getAccountId()) {
            options.account_id = this.getAccountId();
        }
        const dealerName = windowUrl.searchParams.get(DEALER_NAME);
        if (this.getRemoteCode()) {
            this.auth0.useRefreshTokens = false;
            options.remote_access = this.getRemoteCode();
            const location = window.location.href.replace(window.location.pathname, '/');
            options.redirect_uri = location;
        } else if (dealerName) {
            window.localStorage.setItem(DEALER_NAME, encodeURIComponent(dealerName));
        }

        if (redirectPath.includes(this.logoutPath)) {
            UserTracking.track('In logout, do not get token silently');
            this.clearTokenState();
            throw new Error('In logout, throwing error so it will not loop');
        } else {
            this.accessToken = await this.auth0.getTokenSilently(options).catch(() => {
                UserTracking.track('Error getting auth0 token silently in set session');
                this.clearTokenState();
                throw new Error('Error refreshing token in setSession');
            });
        }

        if (!this.accessToken) {
            UserTracking.debugTrack('No access token when setting session');
            return;
        }

        [this.decodedToken, this.idTokenClaims] = await Promise.all([
            this.auth0.getUser(),
            this.auth0.getIdTokenClaims(),
        ]);
        store.dispatch({
            type: SET_TOKEN,
            payload: this.accessToken,
        });
        window.localStorage.setItem(LS_ACCESS_TOKEN, this.accessToken);
        window.localStorage.setItem(LS_LOGGED_IN_ACCOUNT_ID, this.getAccountId());

        history.replace(redirectPath.includes('sso') ? APP_ROOT_URL : redirectPath);
    }

    /**
     * Ensure that the auth0 client is in a valid state before we proceed
     */
    async errorOnInvalidAuthClientState() {
        if ((await this.getAccessToken()) && !(this.decodedToken && this.idTokenClaims)) {
            throw Error(
                'Invalid token state; the access token exists, but there is either no decoded token or no idTokenClaims'
            );
        }
    }

    setSessionAltAuth(token) {
        const windowUrl = new URL(window.location);
        const redirectPath = windowUrl.searchParams.get('request_uri') || history.location.pathname || APP_ROOT_URL;
        const dealerName = windowUrl.searchParams.get(DEALER_NAME);
        if (dealerName && !this.getRemoteCode()) {
            window.localStorage.setItem(DEALER_NAME, encodeURIComponent(dealerName));
        }
        try {
            this.decodedToken = decodeJwt(token);

            this.accessToken = token;
            store.dispatch({
                type: SET_TOKEN,
                payload: this.accessToken,
            });
            window.localStorage.setItem(LS_ACCESS_TOKEN, token);
            window.localStorage.setItem(LS_ALT_AUTH_TOKEN_CREATED, true);
            if (!this.logoutIfTokenExpired()) {
                history.replace(redirectPath.includes('callback') ? APP_ROOT_URL : redirectPath);
            }
        } catch (err) {
            console.error('failed to decode token!', err);
            const urlError = windowUrl.searchParams.get('error');
            this.setAuthError(urlError);
            this.logout();
        }
    }

    async renewSession() {
        if (!this.isAltAuthOn() && this.altAuthTokenExists()) {
            this.logout();
        } else if (!this.useAltAuthToken() || !this.accessToken) {
            if (!this.isRenewingSession) {
                this.isRenewingSession = true;
                return new Promise((resolve, reject) => {
                    this.setSession()
                        .then(() => {
                            UserTracking.track('Successfully finished set session call');
                            if (this.accessToken) {
                                UserTracking.track('Successfully renewed token');
                                resolve(true);
                            } else {
                                UserTracking.track('No access token after refresh');
                                this.logout();
                            }
                        })
                        .catch((err) => {
                            UserTracking.track('Renew Session Error', err);
                            this.login({}).then(() => reject(false));
                        })
                        .finally(() => {
                            this.isRenewingSession = false;
                        });
                });
            }
        } else if (this.isAltAuthOn()) {
            return this.logoutIfTokenExpired();
        }
    }

    logoutIfTokenExpired() {
        if (this.tokenExpired()) {
            UserTracking.track('Logging out because token has expired');
            console.warn('Auth0 has been down a while, and this token only lasts 2 hours');
            this.logout();
            return true;
        }
        return false;
    }

    tokenExpired() {
        const expires = get(this.decodedToken, 'exp', new Date());
        const expireDate = new Date(expires * 1000);
        const now = new Date();
        return expireDate <= now;
    }

    logout() {
        UserTracking.log('Direct call to logout');
        UserTracking.track('logout');
        const hadAltAuthToken = this.altAuthTokenExists();
        const hadAccessToken = this.accessTokenExists();
        const isRemoteAccessLogout = this.remoteAccessQueryParamExists() || !!this.getRemoteCode();
        this.clearTokenState();

        if (this.isAltAuthOn() && (hadAltAuthToken || !hadAccessToken)) {
            this.goToAltLogin();
        } else {
            const prevEnterpriseConnReq = window.localStorage.getItem(LS_ENTERPRISE_CONNECTION_REQUIRED);
            const prevEnterpriseConnKey = window.localStorage.getItem(LS_ENTERPRISE_CONNECTION_KEY);
            const dealerName = window.localStorage.getItem(DEALER_NAME);

            let logoutUrl = new URL(APPLICATION_DOMAIN);
            if (prevEnterpriseConnReq === 'true') {
                logoutUrl.pathname = APP_SSO_LOGGED_OUT_URL;
                logoutUrl.searchParams.append('connectionKey', prevEnterpriseConnKey);
            }
            if (dealerName != null) {
                logoutUrl.searchParams.append(DEALER_NAME, dealerName);
            }

            if (isRemoteAccessLogout) {
                UserTracking.track('Remote User logging out');
                this.removeAuthError();
                window.localStorage.removeItem(LS_REMOTE_CODE);
                this.auth0.logout({
                    openUrl: false,
                });
                setTimeout(window.close, 1000);
            } else {
                this.auth0.logout({
                    logoutParams: {
                        returnTo: logoutUrl.href + this.logoutPath,
                    },
                });
            }
        }
    }

    async isAuthenticated() {
        if (!this.accessTokenExists()) {
            return false;
        } else if (!this.isAltAuthOn() || this.useAuth0WithAltAuthFlag()) {
            return !!this.auth0 && (await this.auth0.isAuthenticated());
        } else if (this.useAltAuthToken()) {
            return true;
        } else {
            return false;
        }
    }

    useAuth0WithAltAuthFlag() {
        return this.isAltAuthOn() && !this.altAuthTokenExists();
    }

    getUserPermissions() {
        return this.userPermissions;
    }

    getIdentified() {
        return this.identified;
    }

    setIdentified = (identified) => (this.identified = identified);

    getThirdPartySetupComplete() {
        return this.thirdPartySetupComplete;
    }

    getUser() {
        return this.user;
    }

    setUser(value) {
        this.user = value;
    }

    setThirdPartySetupComplete(isSet) {
        this.thirdPartySetupComplete = isSet;
    }

    async initAuth0() {
        if (this.altAuthTokenExists()) {
            // the alt auth flag was just turned off, but its token still
            // exists; a logout is required so that the administrator can log
            // back in with auth0
            this.logout();
        } else {
            this.decodedToken = await this.auth0.getUser();
        }
    }

    initAltAuth() {
        const areWeInCallback = window.location.pathname.includes('/callback');
        if (!areWeInCallback) {
            const cachedAccessToken = localStorage.getItem(LS_ACCESS_TOKEN);
            if (!cachedAccessToken || cachedAccessToken == null) {
                this.goToAltLogin();
            } else if (this.altAuthTokenExists()) {
                this.setSessionAltAuth(cachedAccessToken);
            } else {
                // despite the alt authorization flag being on, we are still using the existing auth0 token;
                this.initAuth0();
            }
        } else {
            const href = window.location.href;
            const errorString = 'error=';
            const hasAuthError = href.indexOf(errorString) > -1;
            if (hasAuthError) {
                const altAuthError = href.substring(href.indexOf(errorString) + errorString.length);
                this.setAuthError(errorString + altAuthError);
            }
        }
    }

    isAltAuthOn() {
        return this.flagClient?.evaluate('alternative-authentication', false) || ALT_AUTH_TESTING === 'true';
    }

    useAltAuthToken() {
        return this.isAltAuthOn() && this.altAuthTokenExists();
    }

    altAuthTokenExists() {
        const altAuthTokenCreated = window.localStorage.getItem(LS_ALT_AUTH_TOKEN_CREATED) != null;
        return altAuthTokenCreated && this.accessTokenExists();
    }

    isGlobalLoginOn() {
        // TODO - remove useGlobalLogin query param
        //  it is added only as a way to test during development without having to enable the FF for all
        return (
            this.flagClient?.evaluate('use-global-login', false) ||
            GLOBAL_LOGIN_TESTING === 'true' ||
            getUrlQueryParam('useGlobalLogin') === 'true'
        );
    }

    accessTokenExists() {
        return (
            this.accessToken !== null ||
            (window.localStorage.getItem(LS_ACCESS_TOKEN) !== null &&
                window.localStorage.getItem(LS_ACCESS_TOKEN) !== 'null')
        );
    }

    async ensureAuth0ClientExists() {
        if (!this.auth0) {
            await this.createAuth0Client();
        }
    }

    async createAuth0Client() {
        const accountId = this.getAccountId();
        const accountIdArg = accountId ? { account_id: accountId } : {};
        const auth0Options = {
            domain: AUTH0_DOMAIN,
            clientId: AUTH0_CLIENT_ID,
            useRefreshTokens: true,
            scope: 'openid email profile',
            authorizeTimeoutInSeconds: 10,
            cacheLocation: 'localstorage', // valid values are: 'memory' or 'localstorage'
            authorizationParams: {
                audience: AUTH0_AUDIENCE,
                redirect_uri: `${AUTH0_CALLBACK_URL}?request_uri=${history.location.pathname}`,
                ...accountIdArg,
            },
        };
        this.auth0 = await createAuth0Client(auth0Options);
    }

    clearTokenState() {
        this.accessToken = null;
        this.decodedToken = null;
        this.idTokenClaims = null;
        window.localStorage.removeItem(LS_ACCESS_TOKEN);
        window.localStorage.removeItem(LS_ALT_AUTH_TOKEN_CREATED);
        window.localStorage.removeItem(DEALER_NAME);
        window.localStorage.removeItem(LS_LOGGED_IN_ACCOUNT_ID);
        this.delete_cookie('auth0.is.authenticated');
        this.delete_cookie('_legacy_auth0.is.authenticated');
    }
    delete_cookie = function (name) {
        window.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; max-age=1;';
    };
}

export const auth = new Auth();

// TODO -> auth can be used directly from import
// remove AuthContext.consumer, useContext(AuthContext) and withAuth() from application
