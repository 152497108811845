import { makeStyles } from '@brivo/react-components';

export const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.brivoAliases.newLabelColor,
    },

    disabledLabel: {
        color: theme.palette.brivoAliases.newLabelColor,
        marginRight: '5px',
    },

    triggersLabelContainer: {
        display: 'inherit',
    },

    triggersTooltip: {
        marginLeft: '3px',
        color: theme.palette.brivoAliases.tooltipNewBackground,
    },

    disabledPointer: {
        cursor: 'not-allowed',
    },

    scenarioTypeDropdown: {
        width: '35%',
    },

    scenarioTypeText: {
        color: theme.palette.brivoAliases.textFilterAutoFillBackground,

        '& .MuiSelect-selectMenu': {
            opacity: 0,
        },

        '& .MuiSelect-nativeInput': {
            backgroundColor: 'inherit',
            padding: '11px 13px',
            opacity: 1,
            fontSize: '14px',
            fontWeight: 500,

            '&::placeholder': {
                color: theme.palette.brivoAliases.newInputPlaceholderColor,
                fontSize: '12px',
                fontWeight: 200,
            },
        },
    },

    input: {
        width: '35%',

        '& .MuiInputBase-input.MuiOutlinedInput-input': {
            '&::placeholder': {
                color: theme.palette.type === 'light' ? theme.palette.colors.grey800 : theme.palette.colors.grey300,
                fontSize: '12px',
                fontWeight: 200,
                opacity: 1,
            },
        },
        '& .MuiFormLabel-root.MuiInputLabel-root': {
            color: theme.palette.brivoAliases.newLabelColor,
        },
    },

    inputError: {
        '& .MuiInputBase-root': {
            border: `1px solid ${
                theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300
            }`,
            borderRadius: 4,
        },
    },

    errorText: {
        '& .error-message': {
            marginTop: '.5rem',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            color: `${theme.palette.type === 'light' ? theme.palette.colors.red800 : theme.palette.colors.red300}`,

            '& span': {
                marginTop: '.1rem',
            },
        },
    },
    checkboxWrapper: {
        '& .MuiTypography-root.MuiFormControlLabel-label': {
            color: theme.palette.brivoAliases.newLabelColor,
        },
    },
    errorAlert: {
        position: 'sticky',
        top: '-40px',
        zIndex: 1,
    },
}));
