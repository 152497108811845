import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { cloneDeep, isEmpty } from 'lodash';
import { Grid, makeStyles } from '@brivo/react-components';
import KPITile from '../components/KPITile';
import { MAX_TILE_WIDTH } from '../../BrivoAnalytics/components/VisualTile/constants';
import { roverApi } from '../../../common/webApis/rover/roverApi';
import { cancellablePromise } from '../../../common/utils/promiseUtils';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import { ALL_KPI_METRICS } from './constants';

const useStyles = makeStyles((theme) => ({
    drawerTileContainer: {
        paddingTop: theme.spacing(1),
    },
    errorBoundary: {
        '& .u-fx.u-fx-column.u-fx-align-center.u-fx-justify-center': {
            margin: 'auto',
        },
    },
}));

const REFRESH_INTERVAL = 300000;

const MyAnalytics = ({
    selectedSites = [],
    setSelectedSites,
    currentDashboardLayout,
    selectedMetricTiles,
    setSelectedMetricTiles,
    isSubDrawerVariant = false,
    parentWidth = null,
    refreshTrigger,
}) => {
    const classes = useStyles();
    const [dashboardConf, setDashboardConf] = useState(null);
    const [reFetchKPIMetrics, setReFetchKPIMetrics] = useState(0);

    const intervalRef = useRef(null);
    const lastFetchTimeRef = useRef(0);

    const deepSearchForDatasource = (object, sourceName) => {
        return Object.keys(object).some((prop) => {
            if (prop === 'source') {
                if (object.source === sourceName && object.id) {
                    return true;
                }
            } else if (object[prop] && Array.isArray(object[prop])) {
                return object[prop].some((item) => deepSearchForDatasource(item, sourceName));
            } else if (object[prop] && typeof object[prop] === 'object') {
                return deepSearchForDatasource(object[prop], sourceName);
            }
            return false;
        });
    };

    const triggerRefresh = () => {
        const currentTime = Date.now();
        const timeSinceLastRefresh = currentTime - lastFetchTimeRef.current;

        if (timeSinceLastRefresh >= REFRESH_INTERVAL) {
            setReFetchKPIMetrics((prev) => prev + 1);
            lastFetchTimeRef.current = currentTime;
        }

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(triggerRefresh, 300000);
    };

    useEffect(() => {
        triggerRefresh();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTrigger]);

    const updateQuerySitesFilters = (query, sites) => {
        if (Array.isArray(query)) {
            query.forEach((queryItem) => updateQuerySitesFilters(queryItem, sites));
            return;
        }
        const updater = (query, sites, sourceName, filterSourceName, filterFieldName) => {
            if (query && deepSearchForDatasource(query, sourceName)) {
                const currentFilters = query.filters ?? [];
                currentFilters.push({
                    source: 'operation',
                    type: 'include',
                    value: {
                        source: filterSourceName,
                        id: filterFieldName,
                    },
                    test_values: sites.map((site) => ({
                        source: 'constant',
                        type: 'integer',
                        value: site.id,
                    })),
                });
                query.filters = currentFilters;
            }
        };

        const sourcesToBeUpdated = [
            {
                sourceName: 'sites',
                filterSourceName: 'sites',
                filterFieldName: 'site_object_id',
            },
            {
                sourceName: 'events',
                filterSourceName: 'events',
                filterFieldName: 'site_object_id',
            },
            {
                sourceName: 'devices',
                filterSourceName: 'sites',
                filterFieldName: 'site_object_id',
            },
            {
                sourceName: 'site_address',
                filterSourceName: 'site_address',
                filterFieldName: 'site_object_id',
            },
        ];

        for (const { sourceName, filterSourceName, filterFieldName } of sourcesToBeUpdated) {
            updater(query, sites, sourceName, filterSourceName, filterFieldName);
        }
    };

    const updateQueryObject = (query) => {
        if (query && !isEmpty(selectedSites)) {
            const updatedQuery = cloneDeep(query);

            if (updatedQuery.datasets?.length > 0) {
                updatedQuery.datasets.forEach((dataset) =>
                    updateQuerySitesFilters(dataset.source?.query, selectedSites)
                );
            }

            updateQuerySitesFilters(updatedQuery.query, selectedSites);

            return updatedQuery;
        }
        return cloneDeep(query);
    };

    const roverApiProxy = useMemo(
        () => ({
            getData: (query) => {
                const updatedQuery = updateQueryObject(query);
                return cancellablePromise(roverApi.getData(updatedQuery));
            },
            getData_V2: (query) => {
                const updatedQuery = updateQueryObject(query);
                return cancellablePromise(roverApi.getData_V2(updatedQuery));
            },
            getMetadata_V2: (query) => {
                const updatedQuery = updateQueryObject(query);
                return cancellablePromise(roverApi.getMetadata_V2(updatedQuery));
            },
            getSites: () => {
                return roverApi.getSites();
            },
            getReport: (query) => {
                const updatedQuery = updateQueryObject(query);
                return cancellablePromise(roverApi.getReport(updatedQuery));
            },
            getReport_V2: (query) => {
                const updatedQuery = updateQueryObject(query);
                return cancellablePromise(roverApi.getReport_V2(updatedQuery));
            },
            getColumns: () => {
                return roverApi.getColumns();
            },
            getDataAsync_V2: (query, rowIndexStart, rowIndexEnd) => {
                const updatedQuery = updateQueryObject(query);
                return roverApi.getDataAsync_V2(updatedQuery, rowIndexStart, rowIndexEnd);
            },
            fetchMoreDataAsync_V2: (queryList) => {
                return roverApi.fetchMoreDataAsync_V2(queryList);
            },
            getReportAsync_V2: (query) => {
                const updatedQuery = updateQueryObject(query);
                return roverApi.getReportAsync_V2(updatedQuery);
            },
            getUniqueValuesAsync_V2: (query, rowIndexStart, rowIndexEnd) => {
                const updatedQuery = updateQueryObject(query);
                return roverApi.getUniqueValuesAsync_V2(updatedQuery, rowIndexStart, rowIndexEnd);
            },
            getUniqueValuesResultsAsync_V2: (query, initialResponse, rowIndexStart, rowIndexEnd) => {
                const updatedQuery = updateQueryObject(query);
                return roverApi.getUniqueValuesResultsAsync_V2(
                    updatedQuery,
                    initialResponse,
                    rowIndexStart,
                    rowIndexEnd
                );
            },
            getUniqueValuesResultsDebounceAsync_V2: (query, initialResponse, rowIndexStart, rowIndexEnd) => {
                const updatedQuery = updateQueryObject(query);
                return roverApi.getUniqueValuesResultsAsyncDebounce_V2(
                    updatedQuery,
                    initialResponse,
                    rowIndexStart,
                    rowIndexEnd
                );
            },
            getUniqueValuesDebounceAsync_V2: (query, rowIndexStart, rowIndexEnd) => {
                const updatedQuery = updateQueryObject(query);
                return roverApi.getUniqueValuesAsyncDebounce_V2(updatedQuery, rowIndexStart, rowIndexEnd);
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSites, reFetchKPIMetrics]
    );

    const updateSelectedMetrics = (metric) => {
        const selectedMetric = selectedMetricTiles.find(({ headerKey }) => headerKey === metric.headerKey);

        if (selectedMetric) {
            const filteredMetrics = selectedMetricTiles.filter(
                (selection) => selection.headerKey !== selectedMetric.headerKey
            );
            setSelectedMetricTiles(filteredMetrics);
        } else {
            setSelectedMetricTiles([...selectedMetricTiles, metric]);
        }
    };

    const getTileWidth = useMemo(() => {
        const MIN_WIDTH = 3;
        const HALF_WIDTH = 6;

        if (isSubDrawerVariant) {
            return MAX_TILE_WIDTH;
        }

        if (selectedMetricTiles.length < 3) {
            return HALF_WIDTH;
        }

        const width = Math.floor(parentWidth / selectedMetricTiles.length);

        if (parentWidth === HALF_WIDTH) {
            return HALF_WIDTH;
        } else if (parentWidth === MAX_TILE_WIDTH) {
            return Math.min(HALF_WIDTH, Math.max(MIN_WIDTH, width));
        }

        return width;
    }, [selectedMetricTiles, parentWidth, isSubDrawerVariant]);

    const kpiTiles = useMemo(() => {
        return (isSubDrawerVariant ? ALL_KPI_METRICS : selectedMetricTiles).map((metric, index) => {
            return (
                <Grid item xs={getTileWidth} key={index}>
                    <KPITile
                        shouldDisplayViewData={false}
                        address="0"
                        configuration={metric}
                        width={MAX_TILE_WIDTH}
                        onToggleEditView={() => {}}
                        dashboardId={currentDashboardLayout?.name?.replace(/\s/g, '')}
                        roverApiProxy={roverApiProxy}
                        {...{ dashboardConf, setDashboardConf }}
                        isDrawerTile={isSubDrawerVariant}
                        updateSelectedMetrics={updateSelectedMetrics}
                        isSelectedDrawerMetric={
                            !!selectedMetricTiles.find((selectedTile) => selectedTile.headerKey === metric.headerKey)
                        }
                    />
                </Grid>
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMetricTiles, roverApiProxy, selectedSites, parentWidth]);

    useEffect(() => {
        setSelectedSites((currentSites) => {
            if (currentSites?.length > 0) {
                return [];
            } else {
                return currentSites;
            }
        });
    }, [setSelectedSites]);

    return (
        <Grid
            container
            direction="row"
            spacing={2}
            className={clsx(classes.errorBoundary, { [classes.drawerTileContainer]: isSubDrawerVariant })}
            data-testid="kpiTiles-container"
        >
            <ErrorBoundary sectionName="KPI Metrics">{kpiTiles}</ErrorBoundary>
        </Grid>
    );
};

export default MyAnalytics;
