import React, { Suspense, lazy } from 'react';
import NoAccess from '../../common/components/NoAccess/NoAccess';
import { useFlagClient } from '@brivo/onairplus-services';

const Lockdown = lazy(() => import('./Lockdown'));

const LockdownContainer = (props) => {
    const flagClient = useFlagClient();
    const emergencyScenariosFlag = flagClient?.variation('emergency-scenarios');

    return emergencyScenariosFlag ? (
        <NoAccess />
    ) : (
        <Suspense fallback={null}>
            <Lockdown {...props} />
        </Suspense>
    );
};

export default LockdownContainer;
