import React from 'react';
import { Button, ChevronRight, EditIcon, FloorPlansImageIcon, IconButton, Tooltip } from '@brivo/react-components';
import clsx from 'clsx';

import { eventSeverity } from '@pages/FloorPlan/FloorPlanContainer/constants';

const ViewButton = ({ buttonClickHandler, label, disabled, id }) => {
    return (
        <>
            <Button
                size="small"
                type="tertiary"
                onClick={(event) => {
                    if (buttonClickHandler) {
                        event.stopPropagation();
                        buttonClickHandler();
                    }
                }}
                endIcon={<ChevronRight />}
                text={label}
                disabled={disabled}
                id={`${label}__${id}`}
            />
        </>
    );
};

export const viewCell = (
    context,
    classes,
    label,
    onViewClick,
    onEditClick,
    siteId,
    canUpdateFloorPlans,
    canViewFloorPlans
) => {
    const viewClickHandler = () => onViewClick(context.row.original, siteId);
    const editClickHandler = () => onEditClick(context.row.original, siteId);
    return (
        <div className={classes.cell}>
            <EditButton buttonClickHandler={editClickHandler} disabled={!canUpdateFloorPlans} id={siteId} />
            <ViewButton buttonClickHandler={viewClickHandler} label={label} disabled={!canViewFloorPlans} id={siteId} />
        </div>
    );
};

const EditButton = ({ buttonClickHandler, disabled, id }) => {
    return (
        <>
            <IconButton
                size="small"
                type="update"
                onClick={(event) => {
                    if (buttonClickHandler) {
                        event.stopPropagation();
                        buttonClickHandler();
                    }
                }}
                icon={<EditIcon />}
                disabled={disabled}
                id={`Edit__${id}`}
            />
        </>
    );
};

export const displayImageIcon = (classes, context) => {
    const {
        row: {
            original: { floorPlanImage },
        },
    } = context;
    return (
        <div className={clsx(classes.imageContainer, 'u-trim')}>
            <FloorPlansImageIcon className={classes.imageColor} />
            <Tooltip title={floorPlanImage} placement={'top-start'}>
                <span className={classes.imageName}>{floorPlanImage}</span>
            </Tooltip>
        </div>
    );
};

export const onViewClick = (floorPlan, siteId) => {
    window.open(`/floor-plans/${siteId}/${floorPlan.floorPlanId}?view`);
};

export const onEditClick = (floorPlan, siteId) => {
    window.open(`/floor-plans/${siteId}/${floorPlan.floorPlanId}`);
};

export const mapFloorPlanToTableData = (floorPlans) => {
    if (floorPlans && floorPlans.length > 0) {
        return [
            {
                floorPlanId: floorPlans[0].floorPlanId,
                floorPlanName: floorPlans[0].name,
                floorPlanImage: floorPlans[0].filename,
            },
        ];
    }
    return [];
};

export const displayDeviceCountBySeverity = (device, selectedSeverity) => {
    if (device) {
        const { count, informationalCount, warningCount, criticalCount } = device;
        switch (selectedSeverity?.id) {
            case eventSeverity.INFO.id:
                return informationalCount;
            case eventSeverity.WARNING.id:
                return warningCount;
            case eventSeverity.CRITICAL.id:
                return criticalCount;
            default:
                return count;
        }
    }
};

export const getMostSeverEvent = (oldSeverity, newSeverity) => {
    const severityHierarchy = {
        informational: 1,
        warning: 2,
        critical: 3,
    };
    if (severityHierarchy[oldSeverity] < severityHierarchy[newSeverity]) {
        return newSeverity;
    }
    return oldSeverity;
};

export const getSeverityOfEvent = (eventSecurityActionId, infoList, warningList, criticalList) => {
    if (infoList?.includes(eventSecurityActionId)) return eventSeverity.INFO.label;
    else if (warningList?.includes(eventSecurityActionId)) return eventSeverity.WARNING.label;
    else if (criticalList?.includes(eventSecurityActionId)) return eventSeverity.CRITICAL.label;
};
