import { APP_ACCESS_DENIED_URL } from '../constants/Constants';
import { RestError } from './RestError';
import { createUrl, isJson, readResponseBody, redirect, getRequestDetails } from './utils';

class RestClient {
    makeRequest = async ({
        method = 'GET',
        url,
        body,
        urlParams,
        queryParams,
        additionalHeaders,
        additionalOptions,
    }) => {
        const urlWithParams = createUrl(url, urlParams, queryParams);
        // TODO -> what if the object instance was passed all the options as properties?
        // then requestDetails.options could be replaced by requestDetails
        // Q: will have the class methods change the enumerable properties in any way? My guess is no...
        const requestDetails = await getRequestDetails({ method, body, additionalHeaders, additionalOptions });

        const response = await fetch(urlWithParams, requestDetails.options);
        const { status } = response;

        if (status === 204) {
            return null;
        }

        if (status === 200 || status === 201) {
            if (isJson(response)) {
                const body = await readResponseBody(response);
                return body || true; // return true if body is null or empty string
            }

            return await response.text();
        }

        if (!response.ok) {
            if (status === 403) {
                redirect(APP_ACCESS_DENIED_URL);
            }

            const err = new RestError(response.statusText, { status });

            if (status === 401) {
                // TODO -> Solve bulk requests
                // There is an issue where making bulk requests using Promise.allSettled not throw
                // and the special err object is lost
                // Q: Where is this type of error caught?
                // A: it appears to be the ErrorBoundry, but my impression is that it will NOT actually work
                // TODO -> check if this "requiresLogout" addition works
                // TODO -> IDEA -> create a custom AuthError class that extends Error
                //                 instead of mutating an Error instance
                err.requiresLogout = true;
            }

            const body = await readResponseBody(response);
            err.setApiResponse(body);
            throw err;
        }

        throw new Error(status);
    };
}

export const restClient = new RestClient();
