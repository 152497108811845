import React from 'react';
import createSvgIcon from '../../utils/createSvgIcon';

export default createSvgIcon(
    <React.Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2.5C0 1.11929 1.11929 0 2.5 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H2.5C2.22386 2 2 2.22386 2 2.5V5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5V2.5ZM17.5 0C18.8807 0 20 1.11929 20 2.5V5C20 5.55228 19.5523 6 19 6C18.4477 6 18 5.55228 18 5V2.5C18 2.22386 17.7761 2 17.5 2H15C14.4477 2 14 1.55228 14 1C14 0.447715 14.4477 0 15 0H17.5ZM0 17.5C0 18.8807 1.11929 20 2.5 20H5C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18H2.5C2.22386 18 2 17.7761 2 17.5V15C2 14.4477 1.55228 14 1 14C0.447715 14 0 14.4477 0 15V17.5ZM17.5 20C18.8807 20 20 18.8807 20 17.5V15C20 14.4477 19.5523 14 19 14C18.4477 14 18 14.4477 18 15V17.5C18 17.7761 17.7761 18 17.5 18H15C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20H17.5ZM6 8.02861V7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7V8.02861C14.5717 8.15208 15 8.66063 15 9.26923V12C15 14.7614 12.7614 17 10 17C7.23858 17 5 14.7614 5 12V9.26923C5 8.66064 5.42834 8.15208 6 8.02861ZM12 8H8V7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7V8ZM7 10V12C7 13.6569 8.34315 15 10 15C11.6569 15 13 13.6569 13 12V10H7Z"
            fill="#485769"
        />
    </React.Fragment>,
    'VDRSection'
);
