import { makeStyles } from '@brivo/react-components';

const styles = makeStyles((theme) => ({
    separator: {
        margin: '0 0.5rem',
        color: theme.palette.colors.grey500,
    },
    root: {
        color: theme.palette.type === 'dark' ? theme.palette.colors.grey0 : null,
        ' & .MuiTypography-body1': {
            fontSize: '12px',
        },
    },

    listItem: {
        padding: '0px',
    },
    listItemText: {
        marginTop: '0px',
        marginBottom: '0px',
    },
    menu: {
        padding: '0px',
        ' & .MuiMenu-list': {
            padding: 8,
        },
    },
}));

export default styles;
